
import {throwError as observableThrowError, Observable, of} from 'rxjs';
import {AUTHORIZED_STATE} from '../security/auth/provider';
import {
    BankIdAuthProvider, BankIdAuthResponseOld, BankIdSessionResponse
} from '../security/auth/bankIdProvider';

import {BankIdWorkflow, BankIdWorkflowStrategy} from './bankId';
import {mergeMap} from 'rxjs/operators';
import {AuthCredentials} from '@red/data';


export class SignWorkflow extends BankIdWorkflow {

    constructor(
        authProvider: BankIdAuthProvider,
        strategy?: BankIdWorkflowStrategy
    ) {
        super(authProvider, strategy);

    }

    start(credentials: AuthCredentials): Observable<BankIdAuthResponseOld> {
        return this._authProvider.authenticate(credentials);
    }

    waitForBankId(response: BankIdAuthResponseOld): Observable<BankIdSessionResponse> {
        return this._poll(response)
            .pipe(
                mergeMap((resp: BankIdSessionResponse) => {
                    if (resp.state === AUTHORIZED_STATE.AUTHORIZED) {
                        return of(resp);
                    }

                    return observableThrowError(resp);
                })
            );
    }
}
