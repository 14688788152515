import {Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {
    ONBOARDING_APPLICATION_BOARD_MEMBER_ROLE,
    ONBOARDING_APPLICATION_STATUS,
    OnboardingApplication,
    OnboardingApplicationBoardMember,
    OnboardingApplicationDataLlcNewBlvBoardForm
} from '@red/onboarding-service-api';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Actions, Store} from '@ngxs/store';
import {Address, COUNTRY, CUSTOMER_TYPE, CustomerSearchResult, Me} from '@red/data';
import {NationalIdentifierValidator} from '@red/validators';
import {CustomerServiceClient} from '../../../../lab/service-client/customer-service-client';
import {RedNotification} from '@red/components';
import {OnboardingApplicationBaseDirective} from '../onboarding-application-base';
import {OnboardingManager} from '../../../../managers/onboarding/onboarding-manager';
import {RoaringManager} from '../../../../managers/roaring/roaring-manager';
import {BOARD_VALIDATION_ERROR_TYPE, BoardValidationError, ValidateBoard} from './validate-board/validate-board';

@Component({
    selector: 'app-onboarding-application-llc-new-blv-board-form',
    styleUrls: ['../onboarding-applications.sass'],
    templateUrl: 'onboarding-application-llc-new-blv-board-form.tpl.html',
    providers: [
        {
            provide: OnboardingApplicationBaseDirective,
            useExisting: forwardRef(() => OnboardingApplicationLlcNewBlvBoardFormComponent)
        }
    ]
})
export class OnboardingApplicationLlcNewBlvBoardFormComponent extends OnboardingApplicationBaseDirective implements OnInit, OnChanges {
    @Input() me: Me;

    get form(): FormGroup { return this._form; }
    get formDisabled(): boolean { return this._form && this._form.disabled; }
    get boardMembersFormArray(): FormArray { return this._boardMembersFormArray; }
    get roles(): ONBOARDING_APPLICATION_BOARD_MEMBER_ROLE[] {return this._roles; }
    get roleCeo(): ONBOARDING_APPLICATION_BOARD_MEMBER_ROLE { return ONBOARDING_APPLICATION_BOARD_MEMBER_ROLE.CEO; }
    get roleBoardMember(): ONBOARDING_APPLICATION_BOARD_MEMBER_ROLE { return ONBOARDING_APPLICATION_BOARD_MEMBER_ROLE.BOARD_MEMBER; }
    get roleAlternateMember(): ONBOARDING_APPLICATION_BOARD_MEMBER_ROLE { return ONBOARDING_APPLICATION_BOARD_MEMBER_ROLE.ALTERNATE_MEMBER; }
    get roleChairman(): ONBOARDING_APPLICATION_BOARD_MEMBER_ROLE { return ONBOARDING_APPLICATION_BOARD_MEMBER_ROLE.CHAIRMAN; }
    get formErrors(): any {
        const errorArray = Object.keys(this._form.errors || {}).map((key) => {
            return {
                type: key,
                message: this._form.errors[key]
            };
        });

        return this._form && this._form.errors && errorArray;
    }
    get boardHasCeoRole(): boolean { return this._boardHasCeoRole; }
    get showBoardSummary(): boolean { return this._boardMembersFormArray.length > 1; }
    get showErrorList(): boolean {
        return !!(this.application.status === ONBOARDING_APPLICATION_STATUS.DRAFT
            && this._form
            && this._form.invalid);
    }

    private _formBuilder: FormBuilder;
    private _roles: ONBOARDING_APPLICATION_BOARD_MEMBER_ROLE[] = [
        ONBOARDING_APPLICATION_BOARD_MEMBER_ROLE.BOARD_MEMBER,
        ONBOARDING_APPLICATION_BOARD_MEMBER_ROLE.ALTERNATE_MEMBER,
        ONBOARDING_APPLICATION_BOARD_MEMBER_ROLE.CEO
    ];
    private _customerServiceClient: CustomerServiceClient;
    private _notification: RedNotification;
    private _boardHasCeoRole = false;
    private _roaringManager: RoaringManager;
    private _boardMembersFormArray: FormArray;
    private _storeUniqueIdentifier = {};
    private _errorMessages = [
        {
            type: BOARD_VALIDATION_ERROR_TYPE.SOME_MEMEBERS_UNDER_AGE,
            message: $localize`:Board validation|Board members under age message:Board members under age message`
        },
        {
            type: BOARD_VALIDATION_ERROR_TYPE.MINIMUM_BOARD,
            message: $localize`:Board validation|Minimum board message:Minimum board message`
        },
        {
            type: BOARD_VALIDATION_ERROR_TYPE.MISSING_CHAIRMAN,
            message: $localize`:Board validation|Must have chairman message:Must have chairman message`
        },
        {
            type: BOARD_VALIDATION_ERROR_TYPE.MORE_THAN_ONE_CHAIRMAN,
            message: $localize`:Board validation|Board only one chairman message:Board only one chairman message`
        },
        {
            type: BOARD_VALIDATION_ERROR_TYPE.MORE_THAN_ONE_CEO,
            message: $localize`:Board validation|Board only one ceo message:Board only one ceo message`
        },
        {
            type: BOARD_VALIDATION_ERROR_TYPE.CHAIRMAN_MUST_BE_MEMBER,
            message: $localize`:Board validation|Chairman must be member message:Chairman must be member message`
        }
    ]

    constructor(
        formBuilder: FormBuilder,
        actions: Actions,
        store: Store,
        customerServiceClient: CustomerServiceClient,
        notification: RedNotification,
        onboardingManager: OnboardingManager,
        roaringManager: RoaringManager
    ) {
        super(actions, store, onboardingManager);
        this._formBuilder = formBuilder;
        this._customerServiceClient = customerServiceClient;
        this._notification = notification;
        this._roaringManager = roaringManager;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this._form) {
            this._toggleFormDisabled();

            this._form.updateValueAndValidity();
        }

        if (this.me) {
            this._setDefaultFormValues();
        }
    }

    ngOnInit() {
        super.ngOnInit();
        const data = (this.application.data as OnboardingApplicationDataLlcNewBlvBoardForm);

        this._createForm(data);
        this._setFormValues(data);
        this.checkBoardRoles();
        this._toggleFormDisabled();
    }

    boardMemberAddress(i: number): FormGroup {
        return this._boardMembersFormArray.at(i).get('address') as FormGroup;
    }

    getUserInformation(index: number) {
        const boardMember = (this._form.get('boardMembers') as FormArray).at(index);
        const identifier = boardMember.get('identifier').value;
        const formattedIdentification = NationalIdentifierValidator.format(identifier, COUNTRY.SWEDEN);

        this._roaringManager.getIndividualDetails(formattedIdentification)
            .subscribe(
                (response: CustomerSearchResult) => {
                    if (response.data && response.type === CUSTOMER_TYPE.INDIVIDUAL) {
                        const name = response.data.name.split(' ');
                        const firstName = name.slice(0, name.length - 1).join(' ').trim();
                        const lastName = name.slice().pop();

                        boardMember.patchValue({
                            firstName: firstName,
                            lastName: lastName,
                            address: response.data.address
                        });
                    }
                },
                () => {
                    const msg = $localize`:RedNotification|err message when search for person:Person not found`;
                    this._notification.open(msg);
                }
            );
    }

    addBoardMember() {
        this._boardMembersFormArray.push(this._emptyBoardMemberForm());
    }

    removeBoardMember(index: number) {
        this._boardMembersFormArray.removeAt(index);

        if (this._boardMembersFormArray.length === 0) {
            this.addBoardMember();
        }
    }

    save($event: Event) {
        $event.stopPropagation();
        const application = this._applicationFromForm();
        this._updateApplication(application);
    }

    saveAndSend($event: Event) {
        $event.stopPropagation();
        const application = this._applicationFromForm();
        this._updateApplicationAndSend(application);
    }

    checkBoardRoles() {
        const form = this._form.getRawValue();

        this._boardHasCeoRole = !!form.boardMembers.filter(member => {
            return member.role === ONBOARDING_APPLICATION_BOARD_MEMBER_ROLE.CEO;
        }).length;

        form.boardMembers.forEach((member, index) => {
            if (this._boardHasCeoRole) {
                this._boardMembersFormArray.at(index).get('isCeo').setValue(false);
            }

            if (member.role !== ONBOARDING_APPLICATION_BOARD_MEMBER_ROLE.BOARD_MEMBER) {
                this._boardMembersFormArray.at(index).get('isChairman').setValue(false);
            }
        });
    }

    canBeChairman(index: number): boolean {
        return this.hasMoreThanOneBoardMember() &&
            this._boardMembersFormArray.at(index).get('role').value === ONBOARDING_APPLICATION_BOARD_MEMBER_ROLE.BOARD_MEMBER;
    }

    hasMoreThanOneBoardMember(): boolean {
        const boardMembers = this._form.getRawValue().boardMembers.filter(member => {
            return member.role === ONBOARDING_APPLICATION_BOARD_MEMBER_ROLE.BOARD_MEMBER;
        });

        if (boardMembers.length === 1) {
            this._form.getRawValue().boardMembers.forEach((member, index) => {
                this._boardMembersFormArray.at(index).get('isChairman').setValue(false);
            });
        }

        return (boardMembers.length > 1);
    }

    radio(index: number, propName: string) {
        const boardArray = this._boardMembersFormArray.getRawValue();

        boardArray.forEach((boardMember, indx) => {
            this._boardMembersFormArray.at(indx).get(propName).setValue(indx === index);
        });
    }

    getMemberSummary(index: number): string {
        const boardArray = this._boardMembersFormArray.getRawValue();
        const roleI18n = this.getTranslatedRole(boardArray[index].role);
        const chairmanI18n = this.getTranslatedRole(ONBOARDING_APPLICATION_BOARD_MEMBER_ROLE.CHAIRMAN);
        const ceoi18n = this.getTranslatedRole(ONBOARDING_APPLICATION_BOARD_MEMBER_ROLE.CEO);

        const role = boardArray[index].role
            ? `, ${roleI18n}`
            : ``;

        const chairman = boardArray[index].isChairman
            ? `, ${chairmanI18n}`
            : ``;

        const ceo = boardArray[index].isCeo
            ? `, ${ceoi18n}`
            : ``;

        const roleDescription = `${role}${chairman}${ceo}`.replace(/^,\s/, ': ');

        return `${boardArray[index].firstName} ${boardArray[index].lastName}, ${boardArray[index].identifier}${roleDescription}`;
    }

    getTranslatedRole(role: ONBOARDING_APPLICATION_BOARD_MEMBER_ROLE): string {
        switch (role) {
            case ONBOARDING_APPLICATION_BOARD_MEMBER_ROLE.CEO:
                return $localize`:Onboarding|LLC New blv board form:CEO`;
            case ONBOARDING_APPLICATION_BOARD_MEMBER_ROLE.BOARD_MEMBER:
                return $localize`:Onboarding|LLC New blv board form:Board member`;
            case ONBOARDING_APPLICATION_BOARD_MEMBER_ROLE.ALTERNATE_MEMBER:
                return $localize`:Onboarding|LLC New blv board form:Alternate member`;
            case ONBOARDING_APPLICATION_BOARD_MEMBER_ROLE.CHAIRMAN:
                return $localize`:Onboarding|LLC New blv board form:Chairman`;
            default:
               return '';
        }
    }

    getBoolean(propertyName: string, index: number): boolean {
        return this._boardMembersFormArray.at(index).get(propertyName).value;
    }

    validateBoard(board = [] as OnboardingApplicationBoardMember[]): BoardValidationError[] {
        const boardMembers = (board || []).filter(member => member);
        const errors: BoardValidationError[] = this._validateBoard(boardMembers);

        return errors;
    }

    duplicatedIdentifier(i: number) {
        return this._storeUniqueIdentifier[i];
    }

    showRemoveButton(index: number, formDisabled: boolean): boolean {
        return (index > 0 && !formDisabled);
    }

    getBoardMemberAddress(i: number): Address {
        return this._getBoardMember(i).address as Address;
    }

    getBoardMemberName(i: number): string {
        const member = this._getBoardMember(i);

        return `${member.firstName} ${member.lastName}`;
    }

    getBoardMemberIdentifier(i: number): string {
        const member = this._getBoardMember(i);

        return member.identifier;
    }

    private _validateBoard(board: OnboardingApplicationBoardMember[]): BoardValidationError[] {
        const validationErrors: BoardValidationError[] = [];

        const boardValidation = ValidateBoard.validate(board);

        if (boardValidation.isValid === false) {
            const errorMessage = this._getErrorMessageForErrorType(boardValidation.errorType);

            validationErrors.push({
                type: this._getErrorType(boardValidation.errorType),
                message: errorMessage
            });
        }

        return validationErrors;
    }

    private _getErrorType(type: BOARD_VALIDATION_ERROR_TYPE): BOARD_VALIDATION_ERROR_TYPE {
        switch (type) {
            case BOARD_VALIDATION_ERROR_TYPE.MORE_THAN_ONE_CHAIRMAN:
                return BOARD_VALIDATION_ERROR_TYPE.THERE_CAN_BE_ONLY_ONE;
            case BOARD_VALIDATION_ERROR_TYPE.MORE_THAN_ONE_CEO:
                return BOARD_VALIDATION_ERROR_TYPE.THERE_CAN_BE_ONLY_ONE;
            default:
                return type;
        }
    }

    private _getErrorMessageForErrorType(type: BOARD_VALIDATION_ERROR_TYPE): string {
        return this._errorMessages.slice().filter(err => err.type === type).shift().message;
    }

    private _createForm(data: OnboardingApplicationDataLlcNewBlvBoardForm) {
        let boardMembers = [this._emptyBoardMemberForm()];

        if (data && data.boardMembers && data.boardMembers.length > 0) {
            boardMembers = data.boardMembers.map(() => this._emptyBoardMemberForm());
        }

        this._form = this._formBuilder.group({
            boardMembers: this._formBuilder.array(boardMembers)
        });

        this._boardMembersFormArray = (this._form.get('boardMembers') as FormArray);

        this._form.valueChanges.subscribe((formValues = {}) => {
            const boardMembers = formValues.boardMembers || [];

            boardMembers.forEach((boardMember, index) => {
                const identifierCtrl =  this._form.get(`boardMembers.${index}.identifier`);
                const identifierErrors = identifierCtrl.errors;

                const identifier = identifierCtrl.value;
                const isValid = NationalIdentifierValidator.validate(identifier, COUNTRY.SWEDEN);
                const formattedIdentifier = isValid && NationalIdentifierValidator.format(identifier, COUNTRY.SWEDEN) || identifier;

                identifierCtrl.setValue(formattedIdentifier, {
                    emitEvent: false
                });

                if (identifierErrors && identifierErrors['identifierAlreadyOnBoard']) {
                    this._storeUniqueIdentifier[index] = identifierCtrl.value;
                } else {
                    delete this._storeUniqueIdentifier[index];
                }
            });
        });

        this._form.setValidators([(ctrl) => {
            const boardMembers = ctrl.value && ctrl.value.boardMembers || {
                boardMembers: []
            };
            const boardValidationErrors = this.validateBoard(boardMembers) ;

            const errors = {};
            boardValidationErrors.forEach((error) => {
                errors[error.type] = error.message;
            });

            return boardValidationErrors.length > 0
                ? errors
                : null
        }]);
    }

    private _applicationFromForm() {
        const application = new OnboardingApplication(this.application);
        application.data = new OnboardingApplicationDataLlcNewBlvBoardForm(this._form.getRawValue());
        return application;
    }

    private _nationalIdentifierValidator(ctrl) {
        const val = ctrl && ctrl.value || '';

        return !NationalIdentifierValidator.validate(val, COUNTRY.SWEDEN)
            ? {
                invalidNationalIdentifier: true
            }
            : null
    }

    private _getIdentifiers(form: FormGroup): string[] {
        const boardMembers = form.getRawValue().boardMembers || [];

        return boardMembers.map((boardMember) => {
            return boardMember.identifier;
        });
    }

    private _uniqueIdentifier(ctrl, form: FormGroup) {
        const val = ctrl && ctrl.value || '';
        const formatVal = NationalIdentifierValidator.format(val, COUNTRY.SWEDEN);

        const identifiers = this._getIdentifiers(form);
        const moreThanOne = identifiers.filter((id) => {
            const formatId = NationalIdentifierValidator.format(id, COUNTRY.SWEDEN);

            return formatId === formatVal;
        }).length > 1;

        // console.log(`moreThanOne = ${moreThanOne}, identifiers = ${JSON.stringify(identifiers, null, 4)}`);

        return val && moreThanOne
            ? {
                identifierAlreadyOnBoard: true
            }
            : null
    }

    private _emptyBoardMemberForm() {
        const form = this._form || {
            getRawValue: () => {
                return {};
            }
        } as FormGroup;

        return this._formBuilder.group({
            identifier: ['', [Validators.required, this._nationalIdentifierValidator, (ctrl) => {
                return this._uniqueIdentifier(ctrl, form);
            }]],
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            role: ['', Validators.required],
            isCeo: [false],
            isChairman: [false],
            address: this._formBuilder.group({
                careOf: [''],
                lines: this._formBuilder.array([['', Validators.required]]),
                postalCode: ['', Validators.required],
                postalTown: ['', Validators.required],
                countryCode: COUNTRY.SWEDEN
            }),
        });
    }

    private _setFormValues(data) {
        this._form.patchValue(data);
    }

    private _setDefaultFormValues() {
        const data = (this.application.data as OnboardingApplicationDataLlcNewBlvBoardForm);
        const userDetails = this.me.user.details;

        if (Object.keys(data).length === 0) {
            this._setFormValues({
                boardMembers: [
                    {
                        identifier: userDetails.identification,
                        firstName: userDetails.firstName,
                        lastName: userDetails.lastName,
                        isCeo: false,
                        isChairman: false,
                        address: userDetails.addresses.current()
                    }
                ]
            });
        }
    }

    private _getBoardMember(i: number): OnboardingApplicationBoardMember {
        return this._boardMembersFormArray.at(i).value;
    }
}
