<mat-expansion-panel class="mat-expansion-panel {{statusClassName}}" [ngClass]="{'disabled': (enabled$ | async) === false}" [disabled]="(enabled$ | async) === false">
    <mat-expansion-panel-header>
        <mat-panel-title i18n="Onboarding|Panel title for Share Capital">
            Share Capital
        </mat-panel-title>
        <mat-panel-description></mat-panel-description>
    </mat-expansion-panel-header>
    <section class="info-section">
        <div class="info-text">
            <p>
                Sätt in aktiekapitalet på ditt aktiekapitalkonto hos Bokio.
                Kontot finns hos Svenska Handelsbanken och är skyddat av insättningsgarantin.
            </p>
            <p>När aktiekapitalet är mottaget färdigställer vi din ansökan till Bolagsverket.</p>
            <p>När registreringen är klar kommer du aktivera ditt företagskonto hos Bokio.</p>
            <p>
                <a
                    href="https://www.bokio.se/hjalp/bokio-foretagskonto-bank/bokio-foretagskonto/vanliga-fragor-om-bokio-foretagskonto/"
                    target="_blank"
                >Läs mer om ditt nya företagskonto hos Bokio.</a>
            </p>
        </div>
    </section>

    <section class="info-section" *ngIf="createdBeforeOrSameAsBgNrChange">
        <p class="info-text tmp-info">
            {{bgNrChangeDate}} bytte vi bankgiro från 649-5634 till 5551-0770.
            Har du redan betalat till det gamla bankgirot så kommer insättningen att hamna rätt men om du ska göra en inbetalning nu så ska du använda det nya bankgirot.
        </p>
    </section>
    <dl>
        <dt i18n="Onboarding|Bankgiro">Bankgiro:</dt>
        <dd><red-copy-value
            [text]="'5551-0770'"
        ></red-copy-value></dd>
        <dt i18n="Onboarding|Ocr">Ocr number:</dt>
        <dd><red-copy-value [text]="data.ocr"></red-copy-value></dd>
        <ng-container *ngIf="data.receivedShareHolderCapital; else transferAmount">
            <dt i18n="Onboarding|Amount to receive">Received Amount</dt>
            <dd [innerHTML]="data.receivedShareHolderCapital | amount"></dd>
        </ng-container>
        <ng-template #transferAmount>
            <dt i18n="Onboarding|Amount to transfer">Amount to transfer</dt>
            <dd [innerHTML]="data.shareHolderCapital | amount"></dd>
        </ng-template>
    </dl>
</mat-expansion-panel>
