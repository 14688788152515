import {Action, Selector, State, StateContext, StateToken} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {CollectResponseModel, SignatureModel, SignRequestResponseModel} from './signature.model';
import {SignatureManager} from '../../../managers/signature/signature-manager';
import {CollectBankIdSignature, SignatureStatusUpdate, SignPayloadWithBankIdSignature} from './signature.actions';
import {catchError, tap} from 'rxjs/operators';
import {COLLECT_RESPONSE_STATUS, Signature} from '@red/data';
import {Subject, throwError} from 'rxjs';

export const SIGNATURE_STATE_TOKEN = new StateToken<SignatureModel>('signature');

@State<SignatureModel>({
    name: SIGNATURE_STATE_TOKEN,
    defaults: {
        collectId: null,
        autostartToken: null,
        currentSignature: null,
        currentSignatureStatus: null
    }
})
@Injectable()
export class SignatureState {
    private _signatureManager: SignatureManager;

    constructor(
        signatureManager: SignatureManager
    ) {
        this._signatureManager = signatureManager;
    }

    @Selector()
    static collectId(state: SignatureModel): string {
        return state.collectId;
    }

    @Selector()
    static autostartToken(state: SignatureModel): string {
        return state.autostartToken;
    }

    @Selector()
    static currentSignature(state: SignatureModel): Signature {
        return new Signature(state.currentSignature);
    }

    @Selector()
    static currentSignatureStatus(state: SignatureModel): COLLECT_RESPONSE_STATUS {
        return state.currentSignatureStatus;
    }

    @Action(SignPayloadWithBankIdSignature)
    signPayload(ctx: StateContext<SignatureModel>, action: SignPayloadWithBankIdSignature) {
        const {payloadBase64, userIdentification, summary} = action.payload;
        ctx.dispatch(new SignatureStatusUpdate({status: COLLECT_RESPONSE_STATUS.PENDING}));
        return this._signatureManager.signPayload(payloadBase64, userIdentification, summary)
            .pipe(
                tap((response: SignRequestResponseModel) => {
                    const {collectId, autostartToken} = response;
                    ctx.patchState({
                        collectId,
                        autostartToken
                    });
                }),
                catchError(err => {
                    ctx.dispatch(new SignatureStatusUpdate({status: COLLECT_RESPONSE_STATUS.FAILED}));
                    return throwError(err);
                })
            );
    }

    @Action(CollectBankIdSignature)
    collectSignature(ctx: StateContext<SignatureModel>, action: CollectBankIdSignature) {
        const responseEmitter = this._createResponseEmitter(ctx);
        ctx.dispatch(new SignatureStatusUpdate({status: COLLECT_RESPONSE_STATUS.PENDING}));
        return this._signatureManager.collect(action.payload.collectId, responseEmitter)
            .pipe(
                tap((response: CollectResponseModel) => {
                    const {signature} = response;
                    ctx.patchState({
                        currentSignature: signature,
                    });
                }),
                catchError(err => {
                    ctx.dispatch(new SignatureStatusUpdate({status: COLLECT_RESPONSE_STATUS.FAILED}));
                    return throwError(err);
                })
            );
    }

    @Action(SignatureStatusUpdate)
    setStatus(ctx: StateContext<SignatureModel>, action: SignatureStatusUpdate) {
        ctx.patchState({
            currentSignatureStatus: action.payload.status,
        });
    }

    private _createResponseEmitter(ctx: StateContext<SignatureModel>) {
        const emitter = new Subject<CollectResponseModel>();
        emitter.subscribe((response: CollectResponseModel) => {
            ctx.dispatch(new SignatureStatusUpdate({status: response.status}));
        });

        return emitter;
    }
}
