import {Observable} from 'rxjs';
import {Company, CompanyFiscalInformation, CompanyFiscalYear} from '@red/data';
import {map} from 'rxjs/operators';
import * as moment from 'moment';
import {DirectoryServiceClient} from '../../lab/service-client/directory-service-client';

export class CompanyManager {
    private _directoryServiceClient: DirectoryServiceClient;

    constructor (
        directoryServiceClient: DirectoryServiceClient,
    ) {
        this._directoryServiceClient = directoryServiceClient;
    }

    static create(
        directoryServiceClient: DirectoryServiceClient
    ) {
        return new CompanyManager(directoryServiceClient);
    }

    getCompany(companyId: string): Observable<Company> {
        return this._directoryServiceClient.getCompanyById(companyId);
    }

    getFiscalInformation(companyId: string): Observable<CompanyFiscalInformation> {
        return this.getCompany(companyId)
            .pipe(map((company: Company) => company.details.fiscalInformation));
    }

    getFirstHistoricalFiscalYear(companyFiscalInformation: CompanyFiscalInformation) {
        const firstHistoricalFiscalYear = this._getHistoricalFiscalYear(companyFiscalInformation.historicalFiscalYears, 0);

        return firstHistoricalFiscalYear && this._formatFiscalYear(firstHistoricalFiscalYear);
    }

    getSecondHistoricalFiscalYear(companyFiscalInformation: CompanyFiscalInformation): string {
        const secondHistoricalFiscalYear = this._getHistoricalFiscalYear(companyFiscalInformation.historicalFiscalYears, 1);

        return secondHistoricalFiscalYear && this._formatFiscalYear(secondHistoricalFiscalYear);
    }

    getFirstFiscalYearStartDate(company: Company): string {
        return company.details.fiscalInformation.historicalFiscalYears.length
            ? company.details.fiscalInformation.historicalFiscalYears.slice().map((fiscalYear) => {
                return fiscalYear.start;
            }).sort((a, b) => {
                return moment(a).isAfter(moment(b))
                    ? 1
                    : -1;
            }).shift()
            : company.details.fiscalInformation.currentFiscalYear.start;
    }

    private _formatFiscalYear(companyFiscalYear: CompanyFiscalYear, format = 'YYYY'): string {
        const start = companyFiscalYear.start && moment(companyFiscalYear.start).format(format);
        const end = companyFiscalYear.end && moment(companyFiscalYear.end).format(format);

        return (end === start)
            ? start
            : `${start}-${end}`;
    }

    private _getHistoricalFiscalYear(historicalFiscalYears: CompanyFiscalYear[], arrayPosition: number): CompanyFiscalYear {
        const sortedHistoricalFiscalYears = this._sortHistoricFiscalYears(historicalFiscalYears);

        return sortedHistoricalFiscalYears.slice(arrayPosition, sortedHistoricalFiscalYears.length).shift();
    }

    private _sortHistoricFiscalYears(historicalFiscalYears: CompanyFiscalYear[]): CompanyFiscalYear[] {
        return historicalFiscalYears.slice().sort((a, b) => {
            if (a.start > b.start) {
                return -1;
            }

            if (a.start < b.start) {
                return 1;
            }

            return 0;
        });
    }
}
