export class BankIdManager {
    static canOpenOnSameDevice(): boolean {
        return window.matchMedia('only screen and (max-width: 760px)').matches
            && !(BankIdManager.isStandaloneApp() && BankIdManager.isSafari());
    }

    static isStandaloneApp(): boolean {
        return (window.matchMedia('(display-mode: standalone)').matches);
    }

    static isSafari(): boolean {
        const userAgent = window.navigator.userAgent;

        return !!(userAgent.match(/iPad/i) || userAgent.match(/iPhone/i));
    }
}
