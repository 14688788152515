import {WebServiceClient} from '@red/browser';
import {HttpFetch} from '@red/common';
import {Observable} from 'rxjs';
import {
    AssetsUploadDetails,
} from '@red/data';
import {AppConfig} from '../../config/appConfig';

export class OffboardingServiceClient extends WebServiceClient {
    static create(httpFetch: HttpFetch, config: AppConfig) {
        return new OffboardingServiceClient(httpFetch, config);
    }

    checkUploadOfAssetsToGoogleDrive(companyId: string, headers?: object): Observable<AssetsUploadDetails> {
        const opt = headers ? {headers} : {};
        const url = `${this._getBaseUrl()}/api/offboarding/assets-upload-status/company/${companyId}`;

        return this._get<AssetsUploadDetails>(url, opt, AssetsUploadDetails);
    }
}
