import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {DIALOG_RESULT} from '@red/components';
import {Amount} from '@red/data';

@Component({
    styleUrls: ['./confirm.sass'],
    templateUrl: './confirm.tpl.html'
})

export class DividendsPaymentConfirmComponent {
    get numberOfShareholders(): number {
        return this._numberOfShareholders;
    }
    get dividends(): Amount {
        return this._dividends;
    }
    private _dialogRef: MatDialogRef<DividendsPaymentConfirmComponent>;
    private _dividends: Amount;
    private _numberOfShareholders: number;

    constructor (
        dialogRef: MatDialogRef<DividendsPaymentConfirmComponent>,
        @Inject(MAT_DIALOG_DATA) data: any
    ) {
        this._dialogRef = dialogRef;
        this._dividends = data.dividends;
        this._numberOfShareholders = data.numberOfShareholders;
    }

    cancel() {
        this._dialogRef.close(DIALOG_RESULT.CANCEL);
    }

    confirm() {
        this._dialogRef.close(DIALOG_RESULT.OK);
    }
}
