import {Routes} from '@angular/router';
import {SettingsViewComponent} from './settings';
import {ValidSessionGuard} from '../../security/route/session';
import {UserViewComponent} from './user/user';
import {UserCardViewComponent} from './card/card';
import {ConsentGuard} from '../../security/route/consent';
import {SettingsCardRouteResolve} from './card/settings-card-route-resolve';

export const SETTINGS_ROUTES: Routes = [
    {path: 'settings', component: SettingsViewComponent, canActivate: [ValidSessionGuard, ConsentGuard],
        children: [
            {path: '', redirectTo: 'user', pathMatch: 'full'},
            {path: 'user', component: UserViewComponent}
        ]}
];
