import {Component, OnInit} from '@angular/core';
import {Select} from '@ngxs/store';
import {OnboardingState} from '../../../shared/state/onboarding/onboarding.state';
import {Observable} from 'rxjs';

@Component({
    selector: 'app-onboarding-container',
    styleUrls: ['./onboarding-container.sass'],
    templateUrl: 'onboarding-container.tpl.html'
})

export class OnboardingContainerComponent {
    @Select(OnboardingState.hasSelectedTrack) hasSelectedTrack$: Observable<boolean>;
}
