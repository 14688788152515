import {Component, HostBinding, Inject, LOCALE_ID, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CardTransaction, PAYMENT_TYPE, Receipt, REFERENCE_TYPE, Transaction, TRANSACTION_STATUS,
    TRANSACTION_SUB_TYPE
} from '@red/data';
import {RedNotification} from '@red/components';
import {AppConfig} from '../../../config/appConfig';
import {EventSource} from '../../../common/event/source';
import {CardTransactionUpdatedEvent} from '../../../common/event/readers/cardTransaction';
import {ReceiptCreatedEvent} from '../../../common/event/readers/receipt';
import {Analytics, ANALYTICS_ACTION, ANALYTICS_CATEGORY} from '../../../common/analytics/analytics';
import {Observable} from 'rxjs';
import {CardTransactionServiceClient} from '../../../lab/service-client/card-transaction-service-client';
import {AuthState} from '../../../shared/state/auth/auth.state';
import {Store} from '@ngxs/store';
import {KafkaLogger} from '../../../common/error/global';
import { ListDataSource, QueryFilter } from '@red/browser';
import {ReceiptServiceClient} from '../../../lab/service-client/receipt-service-client';
import {TransactionManager} from '../../../managers/transaction/transaction.manager';

@Component({
    selector: 'app-receipt-create',
    styleUrls: ['./create.sass'],
    templateUrl: 'create.tpl.html',
})
export class ReceiptCreateComponent implements OnInit {
    @HostBinding('class.receipt-create-view') cssClass = true;

    get canSave(): boolean { return (this._cardTransaction === null || !!this._cardTransaction) && !this._uploading; }
    get cardTransaction(): Transaction {return this._cardTransaction; }
    get cardTransactions(): ListDataSource<Transaction> { return this._cardTransactions; }
    get cardTransactionRefundMarkdown(): string { return this._cardTransactionRefundMarkdown; }
    get refundCardTransaction(): Transaction { return this._refundCardTransaction; }
    get isCredit(): boolean { return this._isCredit; }
    get isReceipt(): boolean { return this._isReceipt; }
    get receiptImage(): File { return this._receiptImage; }
    get receiptUploadMarkdown(): string { return this._receiptUploadMarkdown; }
    get showRefundView(): boolean { return this._showRefundView; }
    get uploading(): boolean { return this._uploading; }
    private get _companyId(): string { return this._store.selectSnapshot(AuthState.companyId); }

    private _analytics: Analytics;
    private _appConfig: AppConfig;
    private _cardTransaction: Transaction;
    private _cardTransactions: ListDataSource<Transaction>;
    private _cardTransactionRefundMarkdown: string;
    private _cardTransactionServiceClient: CardTransactionServiceClient;
    private _refundCardTransaction: Transaction;
    private _dialogRef: MatDialogRef<ReceiptCreateComponent>;
    private _eventSource: EventSource;
    private _isCredit = false;
    private _isReceipt: boolean;
    private _localeId: string;
    private _notification: RedNotification;
    private _receiptImage: File;
    private _receiptServiceClient: ReceiptServiceClient;
    private _transactionManager: TransactionManager;
    private _receiptUploadMarkdown: string;
    private _router: Router;
    private _store: Store;
    private _showRefundView = false;
    private _uploading = false;
    private _kafkaLogger: KafkaLogger;

    constructor (
        analytics: Analytics,
        appConfig: AppConfig,
        cardTransactionServiceClient: CardTransactionServiceClient,
        eventSource: EventSource,
        dialogRef: MatDialogRef<ReceiptCreateComponent>,
        notification: RedNotification,
        receiptServiceClient: ReceiptServiceClient,
        transactionManager: TransactionManager,
        router: Router,
        store: Store,
        kafkaLogger: KafkaLogger,
        @Inject(LOCALE_ID) localeId: string,
        @Inject(MAT_DIALOG_DATA) data: any
    ) {
        this._analytics = analytics;
        this._appConfig = appConfig;
        this._cardTransactionServiceClient = cardTransactionServiceClient;
        this._eventSource = eventSource;
        this._dialogRef = dialogRef;
        this._localeId = localeId;
        this._notification = notification;
        this._receiptServiceClient = receiptServiceClient;
        this._transactionManager = transactionManager;
        this._router = router;
        this._store = store;
        this._kafkaLogger = kafkaLogger;

        this._cardTransactions = new ListDataSource<Transaction>((filter: QueryFilter) => {
            return this._transactionManager.queryToQueryResponse(this._companyId, filter);
        });
        this._cardTransactionRefundMarkdown = this._appConfig.get(`content.card-transaction.refund.${this._localeId}`);
        this._receiptUploadMarkdown = this._appConfig.get(`content.receipt.asset_upload.${this._localeId}`);

        this._receiptImage = data.file;
        if (data.transaction) {
            this._isCredit = data.transaction.direction ? data.transaction.direction === PAYMENT_TYPE.CREDIT : false;
            if (this._isCredit) {
                this._refundCardTransaction = data.transaction;
            } else {
                this._cardTransactions.items
                    .subscribe((cardTransactions: Transaction[]) => {
                        const preSelected = cardTransactions.filter((cardTransaction: Transaction) => cardTransaction.reference.id === data.transaction.reference.id).shift();
                        if (preSelected) {
                            this.selectTransaction(preSelected);
                        }
                    });
            }
        }
    }

    close() {
        this._dialogRef.close();
    }

    ngOnInit(): void {
        this._listCardTransactions();
    }

    credit() {
        this._uploading = true;

        this._resolveCardTransaction()
            .subscribe((resp: CardTransaction) => {
                this._eventSource.emit(new CardTransactionUpdatedEvent());
                this._creditCardTransaction(resp)
                    .subscribe(() => {
                        this._eventSource.emit(new CardTransactionUpdatedEvent());
                        this._dialogRef.close();
                    }, (err) => {
                        this._dialogRef.close();
                    });
            }, (err) => {
                this._onResolveCardTransactionError(err);
            });

    }

    goBack() {
        this._showRefundView = false;
    }

    loadMore(dataSource: ListDataSource<Transaction>) {
        dataSource.next().subscribe(() => {});
    }

    isSelected(cardTransaction: Transaction) {
        if (this._isReceipt) {
            return false;
        }

        return this._cardTransaction && this._cardTransaction.reference.id === cardTransaction.reference.id;
    }

    hideCardTransaction(transaction: Transaction) {
        return transaction.direction === PAYMENT_TYPE.CREDIT;
    }

    selectReceipt() {
        this._cardTransaction = null;
        this._isReceipt = true;
    }

    selectTransaction(cardTransaction: Transaction) {
        if (this._isCredit) {
            this._showRefundView = true;
        } else {
            this._isReceipt = false;
        }
        this._cardTransaction = cardTransaction;
    }

    save() {
        this._uploading = true;

        if (this._cardTransaction) {
            this._resolveCardTransaction()
                .subscribe((resp: CardTransaction) => {
                    this._onResolveCardTransactionSuccess(resp);
                }, (err) => {
                    this._onResolveCardTransactionError(err);
                });
        } else {
            this._uploadReceipt();
        }
    }

    private _creditCardTransaction(cardTransaction: CardTransaction): Observable<CardTransaction> {
        const originalTransaction = new CardTransaction ({'id': this._cardTransaction.reference.id});
        return this._cardTransactionServiceClient.creditTransaction(originalTransaction.id, cardTransaction.id);
    }

    private _listCardTransactions() {
        const filter = new QueryFilter()
            .equal('type', [REFERENCE_TYPE.CARD_TRANSACTION])
            .equal('orderBy', 'CREATED_AT');
        if (this._isCredit) {
            filter
                .equal('status', [TRANSACTION_STATUS.PENDING, TRANSACTION_STATUS.IN_REVIEW, TRANSACTION_STATUS.BOOKED])
                .length(20);
        } else {
            filter
                .equal('status', [TRANSACTION_STATUS.PENDING])
                .equal('subType', [TRANSACTION_SUB_TYPE.PENDING_RESOLUTION])
                .length(100);
        }
        this._cardTransactions.flush();
        this._cardTransactions.next(filter).subscribe(() => {});
    }

    private _onResolveCardTransactionSuccess(cardTransaction: CardTransaction) {
        this._eventSource.emit(new CardTransactionUpdatedEvent());
        this._dialogRef.close();
        this._router.navigate(['receipt', cardTransaction.id, 'transaction']);
    }

    private _onResolveCardTransactionError(err) {
        this._uploading = false;

        const msg = $localize`:RedNotification|Failed to upload image.:Failed to upload a image as a card transaction`;

        this._notification.errorWithCode(err, msg);

        this._kafkaLogger.debug(err, {action: '_onResolveCardTransactionError', companyId: this._companyId, userId: this._store.selectSnapshot(AuthState.userId), statusCode: err.statusCode, error: err.error});
    }

    private _resolveCardTransaction(): Observable<CardTransaction> {
        const formData = new FormData();
        let cardTransaction;

        if (this._isCredit) {
            cardTransaction = new CardTransaction({'id': this._refundCardTransaction.reference.id});
        } else {
            cardTransaction = new CardTransaction({'id': this._cardTransaction.reference.id});
        }

        formData.append('file', this._receiptImage, this._receiptImage.name);
        return this._cardTransactionServiceClient.resolveWithAsset(cardTransaction.id, formData);
    }

    private _uploadReceipt() {
        const formData = new FormData();

        formData.append('file', this._receiptImage);
        formData.append('companyId', this._companyId);

        this._receiptServiceClient.create(formData)
            .subscribe((updatedReceipt: Receipt) => {
                this._eventSource.emit(new ReceiptCreatedEvent());
                this._analytics.track(ANALYTICS_CATEGORY.RECEIPT, ANALYTICS_ACTION.CREATE);
                this._dialogRef.close(updatedReceipt);
                this._router.navigate(['receipt', updatedReceipt.id, 'edit']);
            }, (err) => {
                this._uploading = false;

                const msg = $localize`:RedNotification|Failed to upload image.:Failed to upload a image as a receipt`;

                this._notification.errorWithCode(err, msg);

                this._kafkaLogger.debug(err, {action: '_uploadReceipt', companyId: this._companyId, userId: this._store.selectSnapshot(AuthState.userId), statusCode: err.statusCode, error: err.error});
            });
    }
}
