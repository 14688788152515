import {Component, Input, ViewEncapsulation} from '@angular/core';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'app-blank-slate-2020',
    styleUrls: ['./blank-slate.sass'],
    templateUrl: './blank-slate.tpl.html'
})
export class RedBlankSlate2020Component {
    @Input() typeClass: string;
}
