<div class="alert-content" mat-dialog-content>
    <ng-container [ngSwitch]="state">
        <form class="reauthorize-form" red-form [formGroup]="loginForm" *ngSwitchCase="'IDLE'">
            <p i18n="Reauthorize|You have been logged out">You have been logged out, please login again.</p>
            <fieldset>
                <label class="form-row identity-label">
                    <mat-form-field>
                        <input
                            matInput
                            i18n-placeholder="Login|input placeholder|Identity placeholder"
                            placeholder="Identity"
                            formControlName="identifier"
                            required
                            autocomplete="off"
                        />
                    </mat-form-field>
                </label>
                <div class="reauthorize-form-actions">
                    <button
                        i18n="Reauthorize|Logout button"
                        mat-flat-button
                        (click)="logout()"
                    >Logout</button>

                    <button
                        i18n="Login|button|Submit sign in"
                        [disabled]="loginForm.invalid"
                        mat-flat-button
                        color="primary"
                        type="button"
                        (click)="login($event)"
                    >Submit</button>
                </div>
            </fieldset>
        </form>
        <div class="loader-container" *ngSwitchCase="'WAITING_FOR_BANKID'">
            <div class="waiting-for-bankid">
                <red-atom-loader></red-atom-loader>
                <p class="waiting-for-bankid-label" i18n="Login|Please open you bankId application">Please open you bankId application</p>
                <a [href]="bankIdUrl" *ngIf="canOpenOnSameDevice && bankIdUrl" mat-flat-button color="primary" i18n="Login|Open BankId app">
                    Open BankId app
                </a>
            </div>
        </div>
        <div class="loader-container" *ngSwitchCase="'USER_SIGN'">
            <div class="waiting-for-bankid">
                <red-atom-loader></red-atom-loader>
                <p class="waiting-for-bankid-label" i18n="Login|Please enter your code in the app">Please enter your code in the app</p>
            </div>
        </div>
    </ng-container>
</div>
