import * as moment from 'moment';
import {
    Amount,
    COUNTRY,
    CreateCreditInvoiceRequest,
    CURRENCY,
    Customer,
    CUSTOMER_TYPE,
    CustomerDefaults,
    Delivery,
    DeliveryAddress,
    Invoice,
    INVOICE_DELIVERY_METHOD,
    INVOICE_STATUS,
    InvoiceItem,
    PAYMENT_STATUS,
    PAYMENT_TYPE,
    UpdateInvoiceRequest,
    VAT_AREA,
    VAT_TYPE
} from '@red/data';
import {Observable, Subject} from 'rxjs';
import {mergeMap, map} from 'rxjs/operators';
import {EventSource} from '../../common/event/source';
import {InvoiceCreatedEvent, InvoiceUpdatedEvent} from '../../common/event/readers/invocie';
import {InvoiceServiceClient} from '../../lab/service-client/invoice-service-client';
import {Vat} from '@red/common';
import {clone} from '../../common/utils/clone';
import {PopulateMode} from '@red/core';

export class InvoiceManager {
    get assetId(): string { return  this._invoice.resourceUrl && this.getAssetId(this._invoice.resourceUrl); }
    get invoice(): Invoice { return this._invoice; }
    get hasCustomer(): boolean { return !!this._invoice.customer.id; }
    get hasInvoiceItems(): boolean { return this._invoice.items && this._invoice.items.length > 0; }
    get hasInvoiceNumber(): boolean { return this.invoice.number && this.invoice.number !== '0'; }
    get hasMultiplePayments(): boolean { return this._invoice.payments.length > 1; }
    get hasPayments(): boolean { return this._invoice.paymentStatus === PAYMENT_STATUS.FULL || this._invoice.paymentStatus === PAYMENT_STATUS.OVERPAID || this._invoice.paymentStatus === PAYMENT_STATUS.PARTIAL || this._invoice.paymentStatus === PAYMENT_STATUS.ABANDONED; }
    get hasPDFInvoice(): boolean { return !!this._invoice.resourceUrl; }
    get isCredit(): boolean { return this._invoice.type === PAYMENT_TYPE.CREDIT; }
    get isDebit(): boolean { return this._invoice.type === PAYMENT_TYPE.DEBIT; }
    get isInReview(): boolean { return this._invoice.status === INVOICE_STATUS.IN_REVIEW; }
    get isDraft(): boolean { return (this._invoice.status === INVOICE_STATUS.DRAFT || this._invoice.status === INVOICE_STATUS.NEW); }
    get isInternationalInvoice(): boolean { return this._invoice.deliveryAddress.address.countryCode !== COUNTRY.SWEDEN; }
    get isFullPaid(): boolean { return this._invoice.paymentStatus === PAYMENT_STATUS.FULL || this._invoice.paymentStatus === PAYMENT_STATUS.ABANDONED; }
    get isNew(): boolean { return (this._invoice.status === INVOICE_STATUS.NEW && !this._invoice.id); }
    get isOverPaid(): boolean { return this._invoice.paymentStatus === PAYMENT_STATUS.OVERPAID; }
    get isPartiallyPaid(): boolean { return this._invoice.paymentStatus === PAYMENT_STATUS.PARTIAL; }
    get isSent(): boolean { return (this._invoice.status === INVOICE_STATUS.COMPLETE || this._invoice.status === INVOICE_STATUS.IN_REVIEW || this._invoice.status === INVOICE_STATUS.BOOKED); }
    get isSentAsLetter(): boolean { return this.isSent && this._invoice.deliveryOption.method === INVOICE_DELIVERY_METHOD.LETTER; }
    get isSentAsPDF(): boolean { return this.isSent && this._invoice.deliveryOption.method === INVOICE_DELIVERY_METHOD.DOWNLOAD; }
    get vatList(): VAT_TYPE[] { return this._vatList; }
    get valueChanges(): Subject<Invoice> { return this._valueChanges; }
    get terms(): number[] {return this._terms; }
    get invoicePartiallyPaidLink(): string {
        return `http://support.redflag.se/sv/articles/3585957-hantering-av-underbetalda-fakturor`;
    }

    private _invoice: Invoice;
    private _eventSource: EventSource;
    private _dateFormat = 'YYYY-MM-DD';
    private _invoiceServiceClient: InvoiceServiceClient;
    private _valueChanges: Subject<Invoice>;
    private _terms: number [] = [30, 20, 10];
    private _vatList: VAT_TYPE[];

    constructor (
        eventSource: EventSource,
        invoice: Invoice,
        invoiceServiceClient: InvoiceServiceClient
    ) {
        this._invoice = invoice;
        this._eventSource = eventSource;
        this._invoiceServiceClient = invoiceServiceClient;

        this._updateVatList();

        if (invoice && !(this._invoice instanceof Invoice)) {
            this._invoice = new Invoice(invoice);
        }

        if (!invoice) {
            this._invoice = this._createEmptyInvoice(CURRENCY.SEK);
            this._updateCurrency(CURRENCY.SEK);
        } else if (!this._invoice.currency) {
            this._updateCurrency(CURRENCY.SEK);
        }

        this._valueChanges = new Subject<Invoice>();
        this._valueChanges
            .subscribe(() => {
                this._invoice = this.updateTotals(this._invoice);
            });

        this._atLeastOneItem();
    }

    static create(
        eventSource: EventSource,
        invoice: Invoice,
        invoiceServiceClient: InvoiceServiceClient
    ): InvoiceManager {
        return new InvoiceManager(eventSource, invoice, invoiceServiceClient);
    }

    addEmptyInvoiceItem(): InvoiceItem {
        const invoiceItem = this._newInvoiceItem(this._invoice.currency);
        this._invoice.items.push(invoiceItem);
        this._emitValueChanges();

        return invoiceItem;
    }

    changeCustomer(companyId: string, customerId: string) {
        return this._invoiceServiceClient.updateCustomer(companyId, this._invoice.id, customerId)
            .pipe(map((invoice: Invoice) => {
                this._invoice = invoice;
                return invoice;
        }));
    }

    getCreateCreditInvoiceRequestFromInvoice(invoice: Invoice): CreateCreditInvoiceRequest {
        const req = new CreateCreditInvoiceRequest();
        req.date = invoice.date;

        req.deliveryOption = new Delivery();
        req.deliveryOption.method = invoice.deliveryOption.method;

        return req;
    }

    credit(companyId: string): Observable<Invoice> {
        const req = this.getCreateCreditInvoiceRequestFromInvoice(this._invoice);

        return this._invoiceServiceClient.creditInvoice(companyId, this._invoice.id, req);
    }

    getPercentageForVatType(vat: VAT_TYPE): number {
        return Vat.percentageForVatType(vat);
    }

    getAssetId(resourceUrl: string): string {
        return resourceUrl.replace(/\/asset\//, '');
    }

    refreshInvoice(value: any, emitChanges?: boolean) {
        this._transformDates(value);
        this._ensureRequiredValues(value);
        this._invoice.populate(value);

        if (emitChanges) {
            this._emitValueChanges();
        }
    }

    removeInvoiceItemAt(index: number) {
        this._invoice.items.splice(index, 1);
        this._emitValueChanges();
    }

    abandon() {
        return this._invoiceServiceClient
            .abandonInvoice(this._invoice.id)
            .pipe(
                map((invoice: Invoice) => {
                    this._eventSource.emit(new InvoiceUpdatedEvent());
                    this._invoice = invoice;
                    return invoice;
                })
            );
    }

    saveOrUpdate(companyId: string): Observable<Invoice> {
        let request;

        if (this._invoice.status === INVOICE_STATUS.NEW && !this._invoice.id) {
            request = this._invoiceServiceClient.createInvoice(companyId, this._invoice.customer.id).pipe(
                mergeMap((invoice: Invoice) => {
                    return this._invoiceServiceClient.updateInvoice(companyId, invoice.id, new UpdateInvoiceRequest(this._invoice));
                })),
                map((invoice: Invoice) => {
                    this._eventSource.emit(new InvoiceCreatedEvent());
                    return invoice;
                });
        } else {
            request = this._invoiceServiceClient.updateCustomer(companyId, this._invoice.id, this._invoice.customer.id).pipe(
                mergeMap((invoice: Invoice) => this._invoiceServiceClient.updateInvoice(companyId, invoice.id, new UpdateInvoiceRequest(this._invoice))),
                map((invoice: Invoice) => {
                    this._eventSource.emit(new InvoiceUpdatedEvent());
                    return invoice;
                })
            );
        }

        return request.pipe(map((invoice: Invoice) => {
            this._invoice = invoice;
            return invoice;
        }));
    }

    send(companyId: string): Observable<Invoice> {
        return this._invoiceServiceClient.sendInvoice(companyId, this._invoice.id)
            .pipe(map((invoice: Invoice) => {
                this._eventSource.emit(new InvoiceUpdatedEvent());
                this._invoice = invoice;
                return invoice;
            }));
    }

    updateCustomer(customer: Customer) {
        this._invoice.customer = new Customer(JSON.parse(JSON.stringify(customer)));

        if (!this._invoice.customer.defaults) {
            this._invoice.customer.defaults = new CustomerDefaults();
        }

        this._invoice.buyerContact = this._invoice.customer.defaults.buyerContact || null;

        this._invoice.deliveryAddress = this._invoice.customer.defaults.deliveryAddress
            || new DeliveryAddress(this._invoice.customer.data.address);

        this._updateCurrency(this._invoice.customer.defaults.currency || CURRENCY.SEK);
        this._updateVatForItems();
        this._emitValueChanges();
    }

    updateDeliveryMethod(method: INVOICE_DELIVERY_METHOD) {
        this._invoice.deliveryOption.method = method;
        this._emitValueChanges();
    }

    updateInvoiceDate(date: string) {
        this._invoice.date = date;
        this._emitValueChanges();
    }

    updateInvoiceItemAt(invoiceItem: InvoiceItem, index: number) {
        invoiceItem.netPrice.amount = isNaN(invoiceItem.item.price.amount)
            ? 0
            : invoiceItem.item.price.amount * invoiceItem.count;

        if (!invoiceItem.item.unit) {
            invoiceItem.item.unit = 'PIECE';
        }

        this._invoice.items[index] = invoiceItem;
        this._emitValueChanges();
    }

    setTerms(days: number) {
        this._invoice.expires = moment(this._invoice.date).add(days, 'days').format(this._dateFormat);
        this._emitValueChanges();
    }

    updateTotals(invoice: Invoice): Invoice {
        invoice = this._setTotalAmountDefaults(invoice);

        invoice.totalNet.amount = invoice.items.reduce((acc, item) => acc + item.netPrice.amount, 0);
        invoice.totalVAT.amount = (invoice.type === PAYMENT_TYPE.CREDIT)
            ? invoice.totalVAT.amount
            : invoice.items.reduce((acc, item) => acc + (item.netPrice.amount * item.item.VAT), 0);
        invoice.totalGross = Amount.sum(invoice.totalNet, invoice.totalVAT);

        return invoice;
    }

    private _setTotalAmountDefaults(invoice: Invoice): Invoice {
        if (!invoice.totalNet) {
            invoice.totalNet = Amount.ZERO(invoice.currency);
        }
        if (!invoice.totalVAT) {
            invoice.totalVAT = Amount.ZERO(invoice.currency);
        }
        if (!invoice.totalGross) {
            invoice.totalGross = Amount.ZERO(invoice.currency);
        }

        return invoice;
    }

    private _atLeastOneItem() {
        if (this._invoice && this._invoice.items.length === 0) {
            this.addEmptyInvoiceItem();
        }
    }

    private _createEmptyInvoice(currency: CURRENCY): Invoice {
        const addrs = {
            'careOf': null,
            'lines': [''],
            'postalTown': null,
            'postalCode': null,
            'countryCode': COUNTRY.SWEDEN
        };
        const invoice = {} as Invoice;

        invoice.customer = new Customer({
            'data': {
                'name': null,
                'country': COUNTRY.SWEDEN,
                'address': clone(addrs)
            }
        });
        invoice.buyerContact = null;
        invoice.currency = currency;
        invoice.date = moment().format(this._dateFormat);
        invoice.expires = moment().add(30, 'days').format(this._dateFormat);
        invoice.status = INVOICE_STATUS.NEW;
        invoice.type = PAYMENT_TYPE.DEBIT;
        invoice.items = [this._newInvoiceItem(currency)];
        invoice.totalDue = new Amount({ 'amount': 0, 'currency': currency });
        invoice.totalNet = new Amount({ 'amount': 0, 'currency': currency });
        invoice.totalVAT = new Amount({ 'amount': 0, 'currency': currency });
        invoice.totalGross = new Amount({ 'amount': 0, 'currency': currency });
        invoice.deliveryAddress = new DeliveryAddress({
            address: clone(addrs)
        });

        return new Invoice(invoice);
    }

    private _emitValueChanges() {
        this._valueChanges.next(this._invoice);
    }

    private _ensureRequiredValues(invoice: any): void {
        if (!invoice.customer.defaults) {
            invoice.customer.defaults = new CustomerDefaults();
        }

        if (!invoice.customer.defaults.sellerContact) {
            invoice.customer.defaults.sellerContact = '';
        }

        if (!invoice.customer.data.phone) {
            invoice.customer.data.phone = '';
        }
    }

    private _isDomesticOrEuVatArea(vatArea: VAT_AREA): boolean { return (vatArea === VAT_AREA.DOMESTIC_VAT || vatArea === VAT_AREA.EU); }

    private _newInvoiceItem(currency: CURRENCY): InvoiceItem {
        const percentage = this._defaultVatPercentage();

        return new InvoiceItem({
            'item': {
                'name': '',
                'description': '',
                'price': {
                    'amount': null,
                    'currency': currency
                },
                'VAT': percentage,
                'unit': 'PIECE'
            },
            'discount': 0,
            'count': null,
            'netPrice': { 'amount': 0, 'currency': currency}
        });
    }

    private _defaultVatPercentage() {
        const vat = this._vatList[0];
        let percentage = this.getPercentageForVatType(vat);

        const customer = this._invoice && this._invoice.customer;
        const vatArea = customer && customer.data.country ? Vat.vatArea(COUNTRY.SWEDEN, customer.data.vatIdentifier || customer.data.country.toString()) : null;

        if (customer && vatArea && !this._isDomesticOrEuVatArea(vatArea) && customer.type !== CUSTOMER_TYPE.INDIVIDUAL) {
            percentage = this.getPercentageForVatType(VAT_TYPE.SE_00);
        }

        return percentage;
    }

    private _transformDates(invoice: any) {
        invoice.date = moment(invoice.date).format(this._dateFormat);
        invoice.expires = moment(invoice.expires).format(this._dateFormat);
    }

    private _updateCurrency(currency: CURRENCY) {
        this._updateVatList();

        if (this._invoice.currency !== currency && currency) {
            this._invoice.currency = currency;

            this._updateCurrencyOnLines();
            this._updateCurrencyOnTotals();
        }
    }

    private _updateCurrencyOnLines() {
        this._invoice.items.forEach(item => {
            item.netPrice.currency = this._invoice.currency;
            item.item.price.currency = this._invoice.currency;
        });
    }

    private _updateCurrencyOnTotals() {
        this._invoice.totalNet.currency = this._invoice.currency;
        this._invoice.totalVAT.currency = this._invoice.currency;
        this._invoice.totalGross.currency = this._invoice.currency;
    }

    private _updateVatList() {
        const vatCountry = this._invoice ? this._invoice.customer.data.country.toString() : 'SE';
        const customerType = this._invoice ? this._invoice.customer.type : CUSTOMER_TYPE.COMPANY;

        this._vatList = Vat.vatTypes(COUNTRY.SWEDEN, vatCountry, customerType);
    }

    private _updateVatForItems() {
        const percentage = this._defaultVatPercentage();
        this._invoice.items.forEach(item => {
            item.item.VAT = percentage;
        });
    }
}
