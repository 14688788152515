import {Action, Selector, State, StateContext, StateToken} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {RemembermeStateModel} from './remember-me.model';
import {AuthLogin} from '../auth/auth.actions';

export const REMEMBER_ME_STATE_TOKEN = new StateToken<RemembermeStateModel>('remember_me');

@State<RemembermeStateModel>({
    name: REMEMBER_ME_STATE_TOKEN,
    defaults: {
        identifier: null,
        rememberMe: false
    }
})
@Injectable()
export class RememberMeState {
    @Selector()
    static rememberMe(state: RemembermeStateModel): boolean {
        return state.rememberMe;
    }

    @Selector()
    static identifier(state: RemembermeStateModel): string {
        return state.identifier;
    }

    @Action(AuthLogin)
    login(ctx: StateContext<RemembermeStateModel>, action: AuthLogin) {
        ctx.setState({
            identifier: action.payload.rememberMe ? action.payload.identifier : null,
            rememberMe: action.payload.rememberMe
        });
    }
}
