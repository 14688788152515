import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import {CommonModule} from '@angular/common';
import {
    RedAssetModule,
    RedDialogModule,
    RedLoaderModule,
    RedFormModule,
    RedInfoBoxModule,
    RedMarkdownModule,
    RedTableRowModule,
    RedDisplayModule
} from '@red/components';
import {InvoiceEditComponent} from './edit/edit';
import {InvoiceCreditConfirmComponent} from './confirm/credit/credit';
import {InvoiceDeleteConfirmComponent} from './confirm/delete/delete';
import {InvoiceSendConfirmComponent} from './confirm/send/send';
import {InvoiceWarningComponent} from './confirm/warning/warning';
import {CustomerModule} from '../customer/customer.module';
import {InvoiceAbandonConfirmComponent} from './confirm/abandon/abandon';
import {RedPipeModule} from '@red/ng';
import {FormMapper} from '../../../tmp-red-web/src/ng/helpers/formMapper';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatFormFieldModule} from '@angular/material/form-field';

@NgModule({
    declarations: [
        InvoiceEditComponent,
        InvoiceCreditConfirmComponent,
        InvoiceDeleteConfirmComponent,
        InvoiceSendConfirmComponent,
        InvoiceWarningComponent,
        InvoiceAbandonConfirmComponent
    ],
    exports: [],
    imports: [
        BrowserAnimationsModule,
        CommonModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatDialogModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatRadioModule,
        MatSelectModule,
        RedAssetModule,
        RedDialogModule,
        RedFormModule,
        RedInfoBoxModule,
        RedLoaderModule,
        RedMarkdownModule,
        RedPipeModule,
        RedTableRowModule,
        CustomerModule,
        RedDisplayModule
    ],
    providers: [
        FormMapper
    ]
})
export class InvoiceModule {}
