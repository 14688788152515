import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext, StateToken} from '@ngxs/store';
import {OnboardingModel} from './onboarding.model';
import {OnboardingManager} from '../../../managers/onboarding/onboarding-manager';
import {
    OnboardingCancelTrack,
    OnboardingCheckStatus,
    OnboardingListApplications,
    OnboardingListApplicationsSuccessful,
    OnboardingSelectTrack,
    OnboardingSetStatus,
    OnboardingStart,
    OnboardingUpdateApplication,
    OnboardingUpdateApplicationAndStatus,
    OnboardingUpdateApplicationAndStatusSuccessful,
    OnboardingUpdateApplicationError,
    OnboardingUpdateApplicationSuccessful
} from './onboarding.actions';
import {Observable, of} from 'rxjs';
import {catchError, delay, mergeMap, tap} from 'rxjs/operators';
import {
    Onboarding,
    ONBOARDING_APPLICATION_STATUS,
    ONBOARDING_TRACK,
    OnboardingApplication,
    OnboardingListResponse
} from '@red/onboarding-service-api';

export const ONBOARDING_STATE_TOKEN = new StateToken<OnboardingModel>('onboarding');

@State<OnboardingModel>({
    name: ONBOARDING_STATE_TOKEN,
    defaults: {
        hasOnboarding: false,
        onboardingId: null,
        userId: null,
        track: null,
        statusMap: null
    }
})
@Injectable()
export class OnboardingState {
    private _onboardingManager: OnboardingManager;

    constructor(
        onboardingManager: OnboardingManager
    ) {
        this._onboardingManager = onboardingManager;
    }

    @Selector()
    static hasOnboarding(state: OnboardingModel): boolean {
        return state.hasOnboarding;
    }

    @Selector()
    static hasSelectedTrack(state: OnboardingModel): boolean {
        return !!state.track && state.track !== ONBOARDING_TRACK.NONE;
    }

    @Selector()
    static track(state: OnboardingModel): ONBOARDING_TRACK {
        return state.track;
    }

    @Selector()
    static onboardingId(state: OnboardingModel): string {
        return state.onboardingId;
    }

    @Selector()
    static userId(state: OnboardingModel): string {
        return state.userId;
    }

    @Selector()
    static applicationStatusMap(state: OnboardingModel): {[type: string]: ONBOARDING_APPLICATION_STATUS} {
        return state.statusMap;
    }

    @Action(OnboardingCheckStatus)
    checkStatus(ctx: StateContext<OnboardingModel>, action: OnboardingCheckStatus): Observable<any> {
        return this._onboardingManager.hasOnboarding()
            .pipe(
                tap((resp: OnboardingListResponse) => {
                    const onboarding = resp.length > 0 ? resp.data[0] : null;
                    ctx.patchState({
                        hasOnboarding: !!onboarding,
                        onboardingId: onboarding ? onboarding.id : null,
                        userId: onboarding ? onboarding.userId : null,
                        track: onboarding ? onboarding.track : null
                    });
                })
            );
    }

    @Action(OnboardingStart)
    start(ctx: StateContext<OnboardingModel>, action: OnboardingStart): Observable<any> {
        return this._onboardingManager.create(action.payload.email, action.payload.phone)
            .pipe(
                tap((onboarding: Onboarding) => {
                    ctx.patchState({
                        hasOnboarding: !!onboarding,
                        onboardingId: onboarding ? onboarding.id : null,
                        userId: onboarding ? onboarding.userId : null,
                        track: onboarding ? onboarding.track : null
                    });
                })
            );
    }

    @Action(OnboardingListApplications)
    listApplications(ctx: StateContext<OnboardingModel>, action: OnboardingListApplications): Observable<any> {
        return this._onboardingManager.listApplications(ctx.getState().onboardingId)
            .pipe(
                tap((applications: OnboardingApplication[]) => {
                    const statusMap = {};
                    applications.forEach(application => {
                        statusMap[application.type] = application.status;
                    });
                    ctx.patchState({
                        statusMap: statusMap
                    });
                }),
                mergeMap((applications) => ctx.dispatch(new OnboardingListApplicationsSuccessful({applications})))
            );
    }

    @Action(OnboardingSelectTrack)
    selectTrack(ctx: StateContext<OnboardingModel>, action: OnboardingSelectTrack): Observable<any> {
        return this._onboardingManager.setTrack(ctx.getState().onboardingId, action.payload.track)
            .pipe(
                tap((resp: Onboarding) => {
                    ctx.patchState({
                        track: resp.track
                    });
                }),
                delay(250),
                mergeMap(() => ctx.dispatch(new OnboardingCheckStatus()))
            );
    }

    @Action(OnboardingCancelTrack)
    cancelTrack(ctx: StateContext<OnboardingModel>, action: OnboardingCancelTrack): Observable<any> {
        return this._onboardingManager.cancelTrack(ctx.getState().onboardingId)
            .pipe(
                tap((resp: Onboarding) => {
                    ctx.patchState({
                        track: resp.track
                    });
                }),
                mergeMap(() => ctx.dispatch(new OnboardingCheckStatus()))
            );
    }

    @Action(OnboardingSetStatus)
    setStatus(ctx: StateContext<OnboardingModel>, action: OnboardingSetStatus): Observable<any> {
        return this._onboardingManager.setStatus(ctx.getState().onboardingId, action.payload.status)
            .pipe(
                mergeMap(() => ctx.dispatch(new OnboardingCheckStatus()))
            );
    }

    @Action(OnboardingUpdateApplication)
    updateApplication(ctx: StateContext<OnboardingModel>, action: OnboardingUpdateApplication): Observable<any> {
        return this._onboardingManager.updateApplication(action.payload.application)
            .pipe(
                mergeMap((application: OnboardingApplication) => {
                   return ctx.dispatch(new OnboardingUpdateApplicationSuccessful({application}));
                }),
                catchError((error) => {
                    ctx.dispatch(new OnboardingUpdateApplicationError({error}));
                    return of(error);
                })
            );
    }

    @Action(OnboardingUpdateApplicationAndStatus)
    updateApplicationStatus(ctx: StateContext<OnboardingModel>, action: OnboardingUpdateApplicationAndStatus): Observable<any> {
        return this._onboardingManager.updateApplication(action.payload.application)
            .pipe(
                delay(500),
                mergeMap(_ => this._onboardingManager.updateApplicationStatus(action.payload.application.id, action.payload.status)),
                tap((application) => {
                    const statusMap = ctx.getState().statusMap ? Object.assign({}, ctx.getState().statusMap) : {};
                    statusMap[application.type] = application.status;
                    ctx.patchState({
                        statusMap: statusMap
                    });
                }),
                mergeMap((application: OnboardingApplication) => {
                    return ctx.dispatch(new OnboardingUpdateApplicationAndStatusSuccessful({application}));
                }),
                catchError((error) => {
                    ctx.dispatch(new OnboardingUpdateApplicationError({error}));
                    return of(error);
                })
            );
    }
}
