import {Observable, of, throwError as observableThrowError} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ERROR_CODE} from '@red/common';
import {Store} from '@ngxs/store';
import {AuthState} from '../../shared/state/auth/auth.state';
import { ReauthorizeWorkflow } from '../../components/reauthorize/reauthorize-workflow';
import {DIALOG_RESULT} from '@red/components';
import {RemoteErrorException} from '../../common/error/remote-error-exception';

@Injectable()
export class UnauthorizedHook implements HttpInterceptor {
    private _reauthorizeWorkflow: ReauthorizeWorkflow;
    private _store: Store;

    constructor(
        reauthorizeWorkflow: ReauthorizeWorkflow,
        store: Store
    ) {
        this._reauthorizeWorkflow = reauthorizeWorkflow;
        this._store = store;
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req)
            .pipe(
                catchError((err: any) => {
                    if (err instanceof HttpErrorResponse) {
                        err = RemoteErrorException.fromHttpErrorResponse(err);
                    }

                    if (!(err instanceof RemoteErrorException)) {
                        return observableThrowError(err);
                    }

                    const shouldReauthorize = (err.errorCode === ERROR_CODE.FGW_INVALID_AUTH_HEADER || err.errorCode === ERROR_CODE.SESSION_INVALID) && this._store.selectSnapshot(AuthState.isAuthenticated);

                    if (shouldReauthorize) {
                        return this._reauthorizeWorkflow.start()
                            .pipe(
                                mergeMap((result: DIALOG_RESULT) => {
                                    if (result === DIALOG_RESULT.CANCEL) {
                                        return of(null);
                                    }
                                    return next.handle(req);
                                })
                            );
                    }

                    return observableThrowError(err);
                })
            );
    }
}
