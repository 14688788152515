import {Component, EventEmitter, HostBinding, Inject, Input, LOCALE_ID, OnInit, Output} from '@angular/core';
import {
    Address,
    COUNTRY,
    CUSTOMER_TYPE,
    CustomerSearchResult,
    Relation,
    RelationListResponse,
    ROLE,
    User, UserDetails
} from '@red/data';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {PostalCode, RedNotification} from '@red/components';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {UserManager} from '../../../managers/user/user.manager';
import {BankAccountValidator, NationalIdentifierValidator} from '@red/validators';
import {FormUtils} from '../../../common/form-utils/form-utils';
import {CustomerServiceClient} from '../../../lab/service-client/customer-service-client';
import {Store} from '@ngxs/store';
import {AuthState} from '../../../shared/state/auth/auth.state';
import {CustomerData} from '@red/data/src/customer/customer';

@Component({
    selector: 'app-user-admin-form',
    styleUrls: ['./user-admin-form.sass'],
    templateUrl: 'user-admin-form.tpl.html'
})

export class UserAdminFormComponent implements OnInit {
    @HostBinding('class.user-admin-form') cssClass = true;

    @Input() isFirstEmployee: boolean;
    @Input() user: User;
    @Input() userRole: ROLE;
    @Input() canEdit = true;

    @Output() emitSubmit = new EventEmitter();
    @Output() emitFormChange = new EventEmitter();

    get userForm() { return this._userForm; }
    get isOwner(): boolean { return this._isOwner; }
    get fullName(): string { return this._fullName; }
    get address(): Address { return this._address; }

    private _customerServiceClient: CustomerServiceClient;
    private _userForm: FormGroup;
    private _firstTry = true;
    private _formBuilder: FormBuilder;
    private _notification: RedNotification;
    private _isOwner: boolean;
    private _userManager: UserManager;
    private _store: Store;
    private _fullName: string;
    private _address: Address;

    constructor (
        store: Store,
        customerServiceClient: CustomerServiceClient,
        userManager: UserManager,
        notification: RedNotification,
        formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) data: any,
        @Inject(LOCALE_ID) localeId: string
    ) {
        this._store = store;
        this._customerServiceClient = customerServiceClient;
        this._formBuilder = formBuilder;
        this._userManager = userManager;
        this._notification = notification;
    }

    ngOnInit() {
        this._userForm = this._formBuilder.group({
            details: this._formBuilder.group({
                identification: ['', [
                    (input: FormControl) => {
                        return NationalIdentifierValidator.validate(input.value, COUNTRY.SWEDEN) ? null : { 'nationalIdentifierError': true };
                    }]
                ],
                firstName: [''],
                lastName: [''],
                email: [''],
                phone: [''],
                address: this._formBuilder.group({
                    lines: this._formBuilder.array(['']),
                    careOf: [''],
                    postalCode: ['', [(ctrl: AbstractControl) => PostalCode.ngValidate(ctrl, COUNTRY.SWEDEN)]],
                    postalTown: ['']
                }),
                mainAccount: this._formBuilder.group({
                    clearingNo: [''],
                    accountNo: ['']
                }),
                firstEmployeeSupport: [false],
                previousSupport: [false],
                listOfSupports: ['']
            })
        });

        this._userChecks();

        if (!this.canEdit) {
            this._userForm.disable();
        }
    }

    getUserInformation() {
        this._firstTry = false;

        const identification = this._userForm.get('details.identification').value;
        const formattedIdentification = NationalIdentifierValidator.format(identification, COUNTRY.SWEDEN);

        this._customerServiceClient.searchIndividual(formattedIdentification)
            .subscribe(
                (response: CustomerSearchResult) => {
                    if (response.data && response.type === CUSTOMER_TYPE.INDIVIDUAL) {
                        this._updateUserAdminForm(response.data);
                    }
                },
                () => {
                    const msg = $localize`:Retrive User Error|err message when search for person:Person not found`;
                    this._notification.open(msg);
                }
            );
    }

    hasError(errorCode: string, path: string[]): boolean {
        return !this._firstTry && this._userForm.hasError('nationalIdentifierError', path);
    }

    isControlInvalid(path: string): boolean {
        return !this._firstTry;
    }

    hasFormErrors() {
        return this._userForm.invalid && !this._firstTry;
    }

    clearingNumberPlaceholder(): string {
        const bankName = BankAccountValidator.validateClearingNumber(this._userForm.get('details.mainAccount.clearingNo').value);

        return bankName || $localize`:Bank account|:Clearing number`;
    }

    setPreviousSupportValue() {
        if (!this._userForm.get('details.firstEmployeeSupport').value) {
            this._userForm.get('details.previousSupport').setValue(false);
            this._userForm.get('details.listOfSupports').setValue('');
        }
    }

    onlyNumbersInput(event: KeyboardEvent) {
        FormUtils.onlyNumbersInput(event);
    }

    onlyNumbersPaste(event: ClipboardEvent) {
        FormUtils.onlyNumbersPaste(event);
    }

    submit($event) {
        $event.preventDefault();

        this._firstTry = false;

        if (this._userForm.valid) {
            const id = NationalIdentifierValidator.format(this._userForm.get('details.identification').value, COUNTRY.SWEDEN);
            const details = this._userForm.get('details').value;

            details.identification = id;
            details.addresses = {
                'PREFERRED_POSTAL_ADDRESS': details.address
            };

            this.emitSubmit.emit(details);
        }
    }

    private _userChecks() {
        if (this.user) {
            // console.log(`UserAdminFormComponent._userChecks: this.user = ${JSON.stringify(this.user, null, 4)}`);

            this._checkRelations(this.user.id);
            this._populateFormWithUser(this.user.details);
            this._disableIdentification();
        }

        if (this.userRole !== ROLE.OWNER) {
            this._userForm.get('details.mainAccount.clearingNo').setValidators((input: FormControl) => this._validateClearingNumber(input));
            this._userForm.get('details.mainAccount.accountNo').setValidators((input: FormControl) => this._validateClearingAndAccountNumber(input));
        }
    }

    private _checkRelations(userId): void {
        this._userManager.getUserRelation(userId)
            .subscribe((relationListResponse: RelationListResponse) => {
                this._isOwner = !!relationListResponse.data.filter((relation: Relation) => {
                    return relation.company.id === this._store.selectSnapshot(AuthState.companyId)
                        && relation.role === ROLE.OWNER;
                }).shift();

                this.canEdit = !relationListResponse.data.filter((relation: Relation) => relation.role === ROLE.OWNER).length;
            });
    }

    private _populateFormWithUser(userDetails: UserDetails) {
        const address = userDetails.addresses.current();

        this._userForm.get('details').patchValue({
            identification: userDetails.identification,
            firstName: userDetails.firstName,
            lastName: userDetails.lastName,
            email: userDetails.email,
            phone: userDetails.phone,
            address: {
                lines: address.lines || [],
                careOf: address.careOf || '',
                postalCode: address.postalCode || '',
                postalTown: address.postalTown || ''
            },
            mainAccount: {
                clearingNo: userDetails.mainAccount ? userDetails.mainAccount.clearingNo : '',
                accountNo: userDetails.mainAccount ? userDetails.mainAccount.accountNo : ''
            }
        });

        this._fullName = `${userDetails.firstName} ${userDetails.lastName}`;
        this._address = address;
    }

    private _updateAddressLines(data: any): void {
        const lines = <FormArray>this._userForm.get('details.address.lines');

        if (lines.length < data.address.lines.length) {
            for (let i = 0; i < data.address.lines.length - lines.length; i += 1) {
                lines.push(this._formBuilder.control(['']));
            }
        } else if (lines.length > data.address.lines.length) {
            for (let i = (data.address.lines.length - 1); i < lines.length; i += 1) {
                if (lines.length > 1) {
                    lines.removeAt(i);
                }
            }
        }
    }

    private _updateUserAdminForm(data: CustomerData): void {
        this.emitFormChange.emit();
        this._updateAddressLines(data);

        const firstName = data.name.replace(/^\S+/, '').trim();
        const lastName = data.name.split(' ')[0].trim();

        this._userForm.get('details').patchValue({
            firstName: firstName,
            lastName: lastName,
            address: {
                lines: data.address.lines,
                careOf: data.address.careOf || '',
                postalCode: data.address.postalCode,
                postalTown: data.address.postalTown
            }
        });

        this._userManager.getUserByIdentifier(data.identifier)
            .subscribe((user: User) => {
                this.user = user;

                this._userChecks();
            });
    }

    private _validateClearingAndAccountNumber(input: FormControl) {
        const clearingNbr = this._userForm ? this._userForm.get('details.mainAccount.clearingNo').value : '';
        const clearingAndAccountNbr = clearingNbr + input.value;

        return (BankAccountValidator.validate(clearingAndAccountNbr) || clearingAndAccountNbr === '') ? null : {'inValidClearingAndAccountNumber': true};
    }

    private _validateClearingNumber(input: FormControl) {
        const accountNoCtrl = this._userForm ? this._userForm.get('details.mainAccount.accountNo') : null;
        const accountNo = accountNoCtrl ? accountNoCtrl.value : '';
        const clearingAndAccountNbr = input.value + accountNo;

        if (!(BankAccountValidator.validate(clearingAndAccountNbr) || clearingAndAccountNbr === '')) {
            this._userForm.get('details.mainAccount.accountNo').setErrors({'inValidClearingAndAccountNumber': true});
        } else if (accountNoCtrl) {
            this._userForm.get('details.mainAccount.accountNo').setErrors(null);
        }

        return BankAccountValidator.validateClearingNumber(input.value) ? null : {'inValidClearingNumber': true};
    }

    private _disableIdentification() {
        this._userForm.get('details.identification').disable();
    }
}
