import {NgModule} from '@angular/core';

import {GraphCanvasComponent} from './graph-canvas';
import {GraphComponent} from './graph';
import {CommonModule} from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    declarations: [
        GraphComponent,
        GraphCanvasComponent
    ],
    exports: [
        GraphComponent,
        GraphCanvasComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule
    ]
})
export class GraphModule {}
