import { LocalDate, DateTimeFormatter } from '@js-joda/core';
import {Locale} from '@js-joda/locale_sv';
import '@js-joda/locale_sv';

const dateFormatter = DateTimeFormatter
    .ofPattern('yyyy-MM-dd')
    .withLocale(Locale.SWEDEN)


export class TimeManager {
    static create() {
        return new TimeManager();
    }

    static today() {
        return LocalDate.now().format(dateFormatter)
    }

    static isBefore(date1: string, date2: string) {
        const d1 = LocalDate.parse(date1.split('T')[0]);
        const d2 = LocalDate.parse(date2.split('T')[0]);

        return d1.isBefore(d2);
    }

    static isSameOrAfter(date1: string, date2: string) {
        const d1 = LocalDate.parse(date1.split('T')[0]);
        const d2 = LocalDate.parse(date2.split('T')[0]);

        return d1.isEqual(d2) || d1.isAfter(d2);
    }
}
