import {Routes} from '@angular/router';
import {RoyaltyStatementViewComponent} from './royalty-statement';
import {ValidSessionGuard} from '../../security/route/session';
import {IncomeStreamListViewComponent} from './list/list';
import {RoyaltyStatementEditViewComponent} from './edit/edit';
import {RoyaltyStatementRouteResolve} from './royalty-statement.route.resolve';

export const ROYALTY_STATEMENT_ROUTES: Routes = [
    {
        path: 'income-stream',
        component: RoyaltyStatementViewComponent,
        canActivate: [ValidSessionGuard],
        children: [
            {
                'path': '',
                'component': IncomeStreamListViewComponent,
                'children': [
                    {'path': ':id/edit', 'component': RoyaltyStatementEditViewComponent, resolve: {'royaltyStatement': RoyaltyStatementRouteResolve} },
                ]
            }
        ]
    }
];
