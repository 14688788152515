<div class="app" [ngClass]="{'onboarding': hasOnboarding && isOnboarding}">
    <app-new-version></app-new-version>
    <app-announcement></app-announcement>
    <red-top-level-menu-container
        [sections]="sections"
        [showMenu]="showMenu">
        <div class="red-top-level-menu-header" *ngIf="showMenu">
            <span class="title">{{companyName}}</span>
            <div *ngIf="canSwitchCompanies | async">
                <mat-menu #menu="matMenu" [overlapTrigger]="true">
                    <button mat-menu-item *ngFor="let relation of relations | async" (click)="selectRelation(relation)" [ngClass]="{'current-company': isCurrentCompany(relation)}">{{relation.company.name}}</button>
                </mat-menu>
                <button mat-icon-button [matMenuTriggerFor]="menu" class="select-relation">
                    <mat-icon svgIcon="ui-context-menu"></mat-icon>
                </button>
            </div>
        </div>
        <button class="red-top-level-menu-logout" mat-flat-button color="primary" (click)="logout()" i18n="Logout">Logout</button>
        <router-outlet></router-outlet>
    </red-top-level-menu-container>
</div>
