import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {EndOfYearManager} from '../../../../managers/end-of-year/end-of-year.manager';
import {EndOfYearDialogComponent} from '../../end-of-year.dialog';

@Component({
    selector: 'app-end-of-year-step-started',
    styleUrls: ['../../end-of-year.dialog.sass'],
    templateUrl: 'end-of-year.step.started.tpl.html'
})

export class EndOfYearStepStartedComponent {
    @Input() endOfYearManager: EndOfYearManager;
    @Input() metadataForm: FormGroup;

    @Output() metadataFormOutput: EventEmitter<any> = new EventEmitter();

    get year(): string { return this._endOfYearDialog.year; }
    get endOfYearStart(): string { return this._endOfYearManager.endOfYearStart; }
    get endOfYearBloggLink(): string { return this._endOfYearDialog.endOfYearBloggLink; }
    get isSoleTrader(): boolean { return this._endOfYearDialog.isSoleTrader; }
    get isLLC(): boolean { return this._endOfYearDialog.isLLC;  }

    private _endOfYearManager: EndOfYearManager;
    private _endOfYearDialog: EndOfYearDialogComponent;

    constructor(
        endOfYearManager: EndOfYearManager,
        endOfYearDialog: EndOfYearDialogComponent
    ) {
        this._endOfYearManager = endOfYearManager;
        this._endOfYearDialog = endOfYearDialog;
    }

    saveMetaData($event) {
        this.metadataFormOutput.emit($event);
    }
}
