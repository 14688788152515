import {Routes} from '@angular/router';
import {ValidSessionGuard} from '../../security/route/session';
import {OnboardingViewComponent} from './onboarding';
import {OnboardingRouteResolve} from './onboarding-route-resolve';

export const ONBOARDING_ROUTES: Routes = [
    {
        path: 'onboarding',
        component: OnboardingViewComponent,
        canActivate: [ValidSessionGuard],
        resolve: {hasOnboarding: OnboardingRouteResolve}
    }
];
