import {NgModule} from '@angular/core';

import {RedAutocompleteComponent} from './autocomplete';
import {RedAutocompleteNoResultItemComponent} from './noResult/autocompleteNoResult';
import {RedAutocompleteItemComponent} from './item/autocompleteItem';
import {CommonModule} from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import {ReactiveFormsModule} from '@angular/forms';

@NgModule({
    declarations: [
        RedAutocompleteComponent,
        RedAutocompleteItemComponent,
        RedAutocompleteNoResultItemComponent
    ],
    exports: [
        RedAutocompleteComponent,
        RedAutocompleteItemComponent,
        RedAutocompleteNoResultItemComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule
    ],
    providers: []
})
export class RedAutocompleteModule {}
