<app-view-card>
    <red-top-toolbar [addClass]="'bokio-top-toolbar'">
        <div class="page-title">
            <span redTopToolbarTitle i18n="Onboarding|Main title for Onboarding">Onboarding</span>
            <div class="switch-company-menu" *ngIf="canSwitchCompanies | async">
                <mat-menu #menu="matMenu" [overlapTrigger]="true">
                    <button mat-menu-item *ngFor="let relation of relations | async" (click)="selectRelation(relation)" [ngClass]="{'current-company': isCurrentCompany(relation)}">{{relation.company.name}}</button>
                </mat-menu>
                <button mat-icon-button [matMenuTriggerFor]="menu" class="select-relation">
                    <mat-icon svgIcon="ui-context-menu"></mat-icon>
                </button>
            </div>
        </div>
        <div class="top-toolbar-onboarding-buttons">
            <button mat-flat-button color="primary" redTopToolbarAction (click)="logout()" class="button-logout">Logga ut</button>
        </div>
    </red-top-toolbar>
    <red-info-box redInfoBoxList *ngIf="canSwitchCompanies | async" class="red-info-multiple-companies">
        Byt mellan dina företag med de tre prickarna här ovan <mat-icon svgIcon="ui-context-menu"></mat-icon>
    </red-info-box>

    <ng-container [ngSwitch]="hasOnboarding$ | async">
        <red-blank-slate *ngSwitchCase="false">
            <p i18n="Onboarding|No onboarding process found">No onboarding process found</p>
        </red-blank-slate>
        <ng-container *ngSwitchCase="true">
            <div class="onboarding-intro" *ngIf="showOnboardingIntro">
                <p>Vad kul att du vill starta aktiebolag med oss!</p>
                <p>Nedan fyller du i den information som krävs för att registrera ditt aktiebolag med oss.</p>
                <p><a href="https://www.bokio.se/starta-eget-foretag/aktiebolag/" target="_blank">Mer information om processen hittar du här</a>.</p>
                <p>Kontakta gärna oss om du har några frågor! <red-copy-value [text]="contactEmail"></red-copy-value></p>
            </div>
            <app-onboarding-container></app-onboarding-container>
            <div class="onboarding-contact" *ngIf="showContactInfo">
                <p><a href="https://www.bokio.se/starta-eget-foretag/aktiebolag/" target="_blank">
                    Läs mer om processen här
                </a></p>
                <red-copy-value [text]="contactEmail"></red-copy-value>
            </div>
        </ng-container>
    </ng-container>
</app-view-card>
