import {Component, HostBinding, OnInit} from '@angular/core';
import {CreateUserComponent} from '../../user-admin/create-user/create-user';
import {ROLE} from '@red/data';

@Component({
    selector: 'app-create-shareholder',
    styleUrls: ['./create-shareholder.sass'],
    templateUrl: 'create-shareholder.tpl.html'
})

export class CreateShareholderComponent extends CreateUserComponent<CreateShareholderComponent> implements OnInit {
    @HostBinding('class.create-shareholder') cssClass = true;

    ngOnInit(): void {
        this.setRole(ROLE.SHARE_HOLDER);
        this.initShareholder();
    }
}
