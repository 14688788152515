import {Component, HostBinding, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {DIALOG_RESULT, RedDialog} from '@red/components';
import {RoyaltyStatementCreateComponent} from '../../components/royalty-statement/create/create';
import {first} from 'rxjs/operators';
import {RedFlagShutDownManager} from '../../managers/red-flag-shut-down/red-flag-shut-down.manager';

@Component({
    selector: 'app-royalty-statement-view',
    templateUrl: './royalty-statement.tpl.html'
})
export class RoyaltyStatementViewComponent implements OnInit {
    @HostBinding('class.scroll-view') cssClass = true;

    get notReadOnlyState(): boolean { return this._notReadOnlyState; }

    private _router: Router;
    private _redDialog: RedDialog;
    private _redFlagShutDownManager: RedFlagShutDownManager;
    private _notReadOnlyState: boolean;

    constructor(
        redDialog: RedDialog,
        router: Router,
        redFlagShutDownManager: RedFlagShutDownManager
    ) {
        this._redDialog = redDialog;
        this._router = router;
        this._redFlagShutDownManager = redFlagShutDownManager;
    }

    ngOnInit(): void {
        const today = this._redFlagShutDownManager.today;
        this._notReadOnlyState = this._redFlagShutDownManager.notReadOnlyState(today);
    }

    createNewRoyaltyStatement($event: File) {
       this._redDialog
           .open(RoyaltyStatementCreateComponent, {'data': {'file': $event}})
           .afterClosed().pipe(first())
           .subscribe((result: DIALOG_RESULT) => {
               if (result === DIALOG_RESULT.OK) {
                   this._router.navigate(['royalty-statement']);
               }
           });
    }
}
