import {CurrencyConversionRequest, CurrencyConversionResponse, CurrencyListResponse} from '@red/data';
import {Observable} from 'rxjs';
import {WebServiceClient} from '@red/browser';
import {HttpFetch} from '@red/common';
import {AppConfig} from '../../config/appConfig';

export class ForexServiceClient extends WebServiceClient {
    static create(transport: HttpFetch, config: AppConfig) {
        return new ForexServiceClient(transport, config);
    }

    convert(request: CurrencyConversionRequest): Observable<CurrencyConversionResponse> {
        return this._post<CurrencyConversionResponse>(`${this._getBaseUrl()}/api/forex/convert`, request, {}, CurrencyConversionResponse);
    }

    currencies(): Observable<CurrencyListResponse> {
        return this._get<CurrencyListResponse>(`${this._getBaseUrl()}/api/forex/currencies`, {}, CurrencyListResponse);
    }
}
