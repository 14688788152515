import {Observable} from 'rxjs';
import {
    AddSignatoryRequest,
    AnnualReport,
    AnnualReportCollectResponse, AnnualReportListResponse, AnnualReportSignRequest, AnnualReportSignResponse,
    AnnualReportUpdateRequest, DividendsDecisionRequest,
    DividendsPaymentRequest, DividendsPaymentResponse,
    PossibleDividends
} from '@red/data';
import {QueryFilter} from '@red/browser';
import {WebServiceClient} from './web-service-client';
import {HttpFetch} from '@red/common';
import {AppConfig} from '../../config/appConfig';
import {QueryParam} from '../../common/query-param';

export class AnnualReportServiceClient extends WebServiceClient {
    static create(transport: HttpFetch, config: AppConfig) {
        return new AnnualReportServiceClient(transport, config);
    }

    getAnnualReport(annualReportId: string): Observable<AnnualReport> {
        const url = `${this._getBaseUrl()}/api/annual-report/${annualReportId}`;
        return this._get<AnnualReport>(url, {}, AnnualReport);
    }

    getPossibleDividends(annualReportId: string): Observable<PossibleDividends> {
        const url = `${this._getBaseUrl()}/api/annual-report/${annualReportId}/dividends`;
        return this._get<PossibleDividends>(url, {}, PossibleDividends);
    }

    addSignatory(annualReportId: string, request: AddSignatoryRequest): Observable<AnnualReport> {
        const url = `${this._getBaseUrl()}/api/annual-report/${annualReportId}/signatory`;
        return this._post<AnnualReport>(url, request, {}, AnnualReport);
    }

    removeSignatory(annualReportId: string, identifier: string): Observable<AnnualReport> {
        const url = `${this._getBaseUrl()}/api/annual-report/${annualReportId}/remove/signatory/${identifier}`;
        return this._delete<AnnualReport>(url, {}, AnnualReport);
    }

    collect(annualReportId: string, token: string): Observable<AnnualReportCollectResponse> {
        const url = `${this._getBaseUrl()}/api/annual-report/${annualReportId}/collect/${token}`;
        return this._get<AnnualReportCollectResponse>(url, {}, AnnualReportCollectResponse);

    }

    dividendsPayout(annualReportId: string, request: DividendsPaymentRequest, query?: QueryFilter): Observable<DividendsPaymentResponse> {
        let url = `${this._getBaseUrl()}/api/annual-report/payout/${annualReportId}`;

        if (query) {
            url += '?' + QueryParam.asString(query);
        }

        return this._post<DividendsPaymentResponse>(url, request, {}, DividendsPaymentResponse);
    }

    list(companyId: string): Observable<AnnualReportListResponse> {
        const url = `${this._getBaseUrl()}/api/annual-report/company/${companyId}/list`;
        return this._get<AnnualReportListResponse>(url, {}, AnnualReportListResponse);
    }

    makeDividendsDecision(request: DividendsDecisionRequest): Observable<AnnualReport> {
        const url = `${this._getBaseUrl()}/api/annual-report/dividends/decision`;
        return this._post<AnnualReport>(url, request, {}, AnnualReport);
    }

    sign(request: AnnualReportSignRequest): Observable<AnnualReportSignResponse> {
        const url = `${this._getBaseUrl()}/api/annual-report/sign`;
        return this._post<AnnualReportSignResponse>(url, request, {}, AnnualReportSignResponse);
    }

    updateAnnualReport(annualReportId: string, request: AnnualReportUpdateRequest): Observable<AnnualReport> {
        const url = `${this._getBaseUrl()}/api/annual-report/${annualReportId}`;
        return this._put<AnnualReport>(url, request, {}, AnnualReport);
    }
}
