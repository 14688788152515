import {NgModule} from '@angular/core';
import {DividendsCalculationComponent} from './calculation/calculation';
import {CommonModule} from '@angular/common';
import {
    RedDialogModule,
    RedInfoBoxModule,
    RedListModule,
    RedLoaderModule,
    RedTableRowModule
} from '@red/components';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {DividendsCreateComponent} from './create/create';
import {ReactiveFormsModule} from '@angular/forms';
import {DividendsFormComponent} from './form/form';
import {DividendsPaymentConfirmComponent} from './confirm/confirm';
import { RedPipeModule } from '@red/ng';
import {MatFormFieldModule} from '@angular/material/form-field';

@NgModule({
    declarations: [
        DividendsCalculationComponent,
        DividendsCreateComponent,
        DividendsFormComponent,
        DividendsPaymentConfirmComponent
    ],
    exports: [
        DividendsCalculationComponent,
        DividendsCreateComponent,
        DividendsFormComponent,
        DividendsPaymentConfirmComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        MatInputModule,
        MatFormFieldModule,
        MatListModule,
        MatProgressBarModule,
        RedDialogModule,
        RedInfoBoxModule,
        RedListModule,
        RedLoaderModule,
        RedPipeModule,
        RedTableRowModule
    ]
})
export class DividendsModule {}
