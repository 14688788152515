import {Injectable} from '@angular/core';
import {EventReader, OnEvent} from '../reader';
import {Subject} from 'rxjs';
import {BaseEvent} from '../event';
import {EventSource} from '../source';

export class RoyaltyStatementCreatedEvent extends BaseEvent {}
export class RoyaltyStatementUpdatedEvent extends BaseEvent {}

@Injectable()
export class RoyaltyStatementEventReader extends EventReader {
    private _onCreatedStream: Subject<RoyaltyStatementCreatedEvent>;
    private _onUpdateStream: Subject<RoyaltyStatementUpdatedEvent>;

    constructor (source: EventSource) {
        super(source);

        this._onCreatedStream = new Subject<RoyaltyStatementCreatedEvent>();
        this._onUpdateStream = new Subject<RoyaltyStatementUpdatedEvent>();
    }

    onCreated(fn: (evt: RoyaltyStatementCreatedEvent) => void) {
        this._onCreatedStream.subscribe(fn);
    }

    onUpdated(fn: (evt: RoyaltyStatementUpdatedEvent) => void) {
        this._onUpdateStream.subscribe(fn);
    }

    @OnEvent(RoyaltyStatementCreatedEvent)
    private _handleRoyaltyStatementCreatedEvent(evt: RoyaltyStatementCreatedEvent) {
        this._onCreatedStream.next(evt);
    }

    @OnEvent(RoyaltyStatementUpdatedEvent)
    private _handleRoyaltyStatementUpdatedEvent(evt: RoyaltyStatementUpdatedEvent) {
        this._onUpdateStream.next(evt);
    }
}
