import {AppConfig} from '../../config/appConfig';
import {CreateCommentRequest, REFERENCE_TYPE, Comment, CommentListResponse, EditCommentRequest} from '@red/data';
import {Observable} from 'rxjs';
import {QueryFilter} from '@red/browser';
import {WebServiceClient} from './web-service-client';
import {HttpFetch} from '@red/common';
import {QueryParam} from '../../common/query-param';

export class CommentServiceClient extends WebServiceClient {
    static create(transport: HttpFetch, config: AppConfig) {
        return new CommentServiceClient(transport, config);
    }

    createComment(type: REFERENCE_TYPE, id: string, request: CreateCommentRequest): Observable<Comment> {
        const url = `${this._getBaseUrl()}/api/comment/${type}/${id}`;
        return this._post<Comment>(url, request, {}, Comment);
    }

    editComment(type: REFERENCE_TYPE, entityId: string, commentId: string, request: EditCommentRequest): Observable<Comment> {
        const url = `${this._getBaseUrl()}/api/comment/${type}/${entityId}/${commentId}`;
        return this._put<Comment>(url, request, {}, Comment);
    }

    getComments(type: REFERENCE_TYPE, id: string, filter?: QueryFilter): Observable<CommentListResponse> {
        let url = `${this._getBaseUrl()}/api/comment/${type}/${id}`;
        if (filter) {
            url += '?' + QueryParam.asString(filter);
        }

        return this._get<CommentListResponse>(url, {}, CommentListResponse);
    }
}
