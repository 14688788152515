import {AppConfig} from '../../config/appConfig';
import {Observable} from 'rxjs';
import {CardTransaction} from '@red/data';
import {WebServiceClient} from './web-service-client';
import {HttpFetch} from '@red/common';

export class CardTransactionServiceClient extends WebServiceClient {
    static create(transport: HttpFetch, config: AppConfig) {
        return new CardTransactionServiceClient(transport, config);
    }

    creditTransaction(transactionId: string, creditTransactionId: string): Observable<CardTransaction> {
        const url = `${this._getBaseUrl()}/api/card-transaction/${transactionId}/creditor/${creditTransactionId}`;
        return this._put<CardTransaction>(url, null, {}, CardTransaction);
    }

    getCardTransaction(transactionId: string): Observable<CardTransaction> {
        const url = `${this._getBaseUrl()}/api/card-transaction/${transactionId}`;
        return this._get<CardTransaction>(url, {}, CardTransaction);
    }

    resolveAsDebt(transactionId: string): Observable<CardTransaction> {
        const url = `${this._getBaseUrl()}/api/card-transaction/${transactionId}/resolve/debt`;
        return this._put<CardTransaction>(url, null, {}, CardTransaction);
    }

    resolveWithAsset(transactionId: string, data: FormData): Observable<CardTransaction> {
        const url = `${this._getBaseUrl()}/api/card-transaction/${transactionId}/resolve/asset`;
        return this._put<CardTransaction>(url, data, {'headers': {'content-type': 'multipart/form-data'}}, CardTransaction);
    }
}
