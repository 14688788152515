import {AppConfig} from '../../config/appConfig';
import {
    CreateCustomerRequest,
    Customer,
    CustomerListResponse,
    CustomerSearchResult,
    CustomerSearchResultListResponse
} from '@red/data';
import {Observable, of} from 'rxjs';
import {QueryFilter} from '@red/browser';
import {WebServiceClient} from './web-service-client';
import {HttpFetch} from '@red/common';
import {QueryParam} from '../../common/query-param';
import {MockDTO} from '../../../testing/mocks/dto';

export class CustomerServiceClient extends WebServiceClient {
    static create(transport: HttpFetch, config: AppConfig) {
        return new CustomerServiceClient(transport, config);
    }

    createCustomer(companyId: string, request: CreateCustomerRequest): Observable<Customer> {
        const url = `${this._getBaseUrl()}/api/${companyId}/customer`;
        return this._post<Customer>(url, request, {}, Customer);
    }

    getCustomer(companyId: string, customerId: string): Observable<Customer> {
        const url = `${this._getBaseUrl()}/api/${companyId}/customer/${customerId}`;
        return this._get<Customer>(url, {}, Customer);
    }

    listCustomers(companyId: string, filter?: QueryFilter): Observable<CustomerListResponse> {
        let url = `${this._getBaseUrl()}/api/${companyId}/customer`;
        if (filter) {
            url += '?' + QueryParam.asString(filter);
        }

        return this._get<CustomerListResponse>(url, {}, CustomerListResponse);
    }

    resolveCustomer(token: string): Observable<CustomerSearchResult> {
        const url = `${this._getBaseUrl()}/api/customer/resolve?token=${token}`;

        return this._get<CustomerSearchResult>(url, {}, CustomerSearchResult);
    }

    search(query: string): Observable<CustomerSearchResultListResponse> {
        const url = `${this._getBaseUrl()}/api/customer/search?query=${query}`;
        return this._get<CustomerSearchResultListResponse>(url, {}, CustomerSearchResultListResponse);
    }

    searchIndividual(identifier: string): Observable<CustomerSearchResult> {
        const url = `${this._getBaseUrl()}/api/customer/search/individual?identifier=${identifier}`;
        return this._get<CustomerSearchResult>(url, {}, CustomerSearchResult);
    }

    updateCustomer(companyId: string, customerId: string, request: Customer): Observable<Customer> {
        const url = `${this._getBaseUrl()}/api/${companyId}/customer/${customerId}`;
        return this._put<Customer>(url, request, {}, Customer);
    }
}
