import {Component, OnDestroy, OnInit} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {CardTransactionEditComponent} from '../../../components/card-transaction/edit/edit';
import {DIALOG_RESULT, RedDialog} from '@red/components';
import {ActivatedRoute, Router} from '@angular/router';
import {CardTransaction} from '@red/data';

@Component({
    selector: 'app-card-transaction-edit-view',
    templateUrl: './edit.tpl.html'
})

export class CardTransactionEditViewComponent implements OnDestroy, OnInit {
    private _dialogRef: MatDialogRef<CardTransactionEditComponent, DIALOG_RESULT>;
    private _redDialog: RedDialog;
    private _route: ActivatedRoute;
    private _router: Router;

    constructor(
        redDialog: RedDialog,
        route: ActivatedRoute,
        router: Router
    ) {
        this._redDialog = redDialog;
        this._route = route;
        this._router = router;
    }

    ngOnDestroy(): void {
        this._dialogRef.close(DIALOG_RESULT.CANCEL);
    }

    ngOnInit(): void {
        this._route.data.subscribe((data: {cardTransaction: CardTransaction}) => {
            if (data.cardTransaction) {
               this._open(data.cardTransaction);
            }
        });
    }

    private _open(cardTransaction: CardTransaction) {
        setTimeout(() => {
            this._dialogRef = this._redDialog.open(CardTransactionEditComponent, {
                'data': {'cardTransaction': cardTransaction}
            });

            this._dialogRef
                .afterClosed()
                .subscribe((result: DIALOG_RESULT) => {
                    if (result === DIALOG_RESULT.OK) {
                        this._router.navigate(['../../'], {relativeTo: this._route});
                    }
                });
        });
    }
}
