import {Component} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {DIALOG_RESULT} from '@red/components';

@Component ({
    templateUrl: './delete.tpl.html'
})

export class RoyaltyStatementDeleteConfirmComponent {
    private _dialogRef: MatDialogRef<RoyaltyStatementDeleteConfirmComponent>;

    constructor (
        dialogRef: MatDialogRef<RoyaltyStatementDeleteConfirmComponent>
    ) {
        this._dialogRef = dialogRef;
    }

    cancel() {
        this._dialogRef.close(DIALOG_RESULT.CANCEL);
    }

    confirm() {
        this._dialogRef.close(DIALOG_RESULT.OK);
    }
}
