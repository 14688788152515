<form [formGroup]="userForm" tabindex="0" class="user-admin-form" red-form>
    <div formGroupName="details">
        <div class="red-row">
            <mat-form-field>
                <input
                    matInput
                    required
                    autocomplete="off"
                    i18n-placeholder="Personal number"
                    placeholder="Personal number"
                    formControlName="identification" />
            </mat-form-field>
            <div class="red-col identification-button-col" *ngIf="!user">
                <button mat-flat-button color="primary" i18n="Add employee|Button|retrieve personal data"
                        [disabled]="hasError('nationalIdentifierError', ['details','identification'])"
                        (click)="getUserInformation()">Retrieve personal data</button>
            </div>
        </div>
        <red-info-box class="user-admin-form-info" *ngIf="isFirstEmployee">
            <span i18n="Info box| information about deduction for first employee">If this is your first employee you can have a support.
                <a href="https://www.skatteverket.se/foretagochorganisationer/arbetsgivare/arbetsgivaravgifterochskatteavdrag/vaxastodtillfalligtsanktarbetsgivaravgift.4.361dc8c15312eff6fd37447.html" target="_blank">Read more at Tax agency</a> if you can get this support.
            </span>
            <mat-checkbox color="primary" formControlName="firstEmployeeSupport" (change)="setPreviousSupportValue()">
                <span class="info-checkbox-label" i18n="Label|I read and understand information about tax-deduction">I read and understand and I want to have support for my first employee</span>
            </mat-checkbox>
            <mat-checkbox *ngIf="userForm.get('details.firstEmployeeSupport').value" color="primary" formControlName="previousSupport">
                <span class="info-checkbox-label" i18n="Label|I have previous deductions and want to list them">I have previous supports and want to list them</span>
            </mat-checkbox>
            <mat-form-field *ngIf="userForm.get('details.previousSupport').value">
                <textarea matInput required formControlName="listOfSupports" i18n-placeholder="Label| list of previous supports" placeholder="List supports you get from EU"></textarea>
            </mat-form-field>
        </red-info-box>

        <div class="red-row">
            <mat-form-field>
                <input
                    matInput
                    required
                    autocomplete="off"
                    i18n-placeholder="First name"
                    placeholder="First name"
                    formControlName="firstName" />
            </mat-form-field>
            <mat-form-field>
                <input
                    matInput
                    required
                    autocomplete="off"
                    i18n-placeholder="Last name"
                    placeholder="Last name"
                    formControlName="lastName" />
            </mat-form-field>
        </div>
        <div formGroupName="address">
            <div class="red-row">
                <mat-form-field class="full-width">
                    <input
                        matInput
                        autocomplete="off"
                        i18n-placeholder="care of for new customer form" placeholder="C/O(optional)"
                        formControlName="careOf"
                    />
                </mat-form-field>
            </div>
            <div formArrayName="lines">
                <div class="red-row" *ngFor="let line of userForm.get('details.address.lines')['controls']; let i = index;">
                    <mat-form-field class="full-width">
                        <input
                            matInput
                            required
                            autocomplete="off"
                            i18n-placeholder="Post address for creating new customer" placeholder="Post address"
                            [formControlName]="i" />
                    </mat-form-field>
                </div>
            </div>
            <div class="red-row">
                <mat-form-field>
                    <input
                        matInput
                        required
                        autocomplete="off"
                        (keypress)="onlyNumbersInput($event)"
                        (paste)="onlyNumbersPaste($event)"
                        i18n-placeholder="Zip code for creating new customer" placeholder="Zip Code"
                        formControlName="postalCode"
                    />
                </mat-form-field>
                <mat-form-field>
                    <input
                        matInput
                        required
                        autocomplete="off"
                        i18n-placeholder="City for creating new customer" placeholder="City"
                        formControlName="postalTown"
                    />
                </mat-form-field>
            </div>
        </div>

        <div>
            <div class="red-row">
                <mat-form-field>
                    <input
                        matInput
                        type="email"
                        required
                        autocomplete="off"
                        i18n-placeholder="Email"
                        placeholder="Email"
                        formControlName="email" email />
                </mat-form-field>
                <mat-form-field>
                    <input
                        matInput
                        (keypress)="onlyNumbersInput($event)"
                        (paste)="onlyNumbersPaste($event)"
                        autocomplete="off"
                        i18n-placeholder="telephone"
                        placeholder="Phone"
                        formControlName="phone" />
                </mat-form-field>
            </div>

            <div formGroupName="mainAccount" class="red-row user-account" *ngIf="canEdit">
                <mat-form-field class="clearing-number">
                    <input
                        matInput
                        required
                        (keypress)="onlyNumbersInput($event)"
                        (paste)="onlyNumbersPaste($event)"
                        type="text"
                        autocomplete="off"
                        [placeholder]="clearingNumberPlaceholder()"
                        formControlName="clearingNo" />
                </mat-form-field>
                <mat-form-field class="account-number">
                    <input
                        matInput
                        required
                        (keypress)="onlyNumbersInput($event)"
                        (paste)="onlyNumbersPaste($event)"
                        type="text"
                        autocomplete="off"
                        i18n-placeholder="user account number" placeholder="Account number"
                        formControlName="accountNo" />
                </mat-form-field>
            </div>
        </div>
    </div>
</form>
