import {DownloadableReportResponse} from '@red/data';
import {Observable} from 'rxjs';
import {WebServiceClient} from '@red/browser';
import {HttpFetch} from '@red/common';
import {AppConfig} from '../../config/appConfig';

export class ReportServiceClient extends WebServiceClient {
    static create(transport: HttpFetch, config: AppConfig) {
        return new ReportServiceClient(transport, config);
    }

    downlodableReport(companyId: string, type: string, startDate: string, endDate: string): Observable<DownloadableReportResponse> {
        const reportType = type.toLowerCase().replace(/_/g, '-').replace(/\-report$/, '');
        const url = `${this._getBaseUrl()}/api/financial-report/${reportType}/company/${companyId}/fiscal-year-start-date/${startDate}/period-end/${endDate}`;

        return this._post<DownloadableReportResponse>(url, null, {}, DownloadableReportResponse);
    }
}
