import {Component, HostBinding, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {DIALOG_RESULT} from '@red/components';

@Component ({
    styleUrls: ['../onboarding-applications/onboarding-applications.sass'],
    templateUrl: './onboarding-cancel-track-confirm.tpl.html'
})
export class OnboardingCancelTrackConfirmComponent {
    @HostBinding('class.cancel-track-confirm-dialog') cssClass = true;

    private _dialogRef: MatDialogRef<OnboardingCancelTrackConfirmComponent>;

    constructor (
        dialogRef: MatDialogRef<OnboardingCancelTrackConfirmComponent>,
        @Inject(MAT_DIALOG_DATA) data: any
    ) {
        this._dialogRef = dialogRef;
    }

    cancel() {
        this._dialogRef.close(DIALOG_RESULT.CANCEL);
    }

    confirm() {
        this._dialogRef.close(DIALOG_RESULT.OK);
    }
}
