import {ReportServiceClient} from '../../lab/service-client/report-service-client';
import {DownloadableReportResponse} from '@red/data';
import {Observable} from 'rxjs';
import * as moment from 'moment';
import {HttpClient} from '@angular/common/http';
import {AppConfig} from '../../config/appConfig';
import {timeout} from 'rxjs/internal/operators';

export enum START_OR_END_OF {
    START = 'START',
    END = 'END'
}

export class ReportManager {
    private _reportServiceClient: ReportServiceClient;
    private _httpClient: HttpClient;
    private _config: AppConfig;

    constructor (
        reportServiceClient: ReportServiceClient,
        httpClient: HttpClient,
        config: AppConfig,
    ) {
        this._reportServiceClient = reportServiceClient;
        this._httpClient = httpClient;
        this._config = config;
    }

    static create(
        reportServiceClient: ReportServiceClient,
        httpClient: HttpClient,
        config: AppConfig,
    ) {
        return new ReportManager(reportServiceClient, httpClient, config);
    }

    downloadReport(companyId: string, type: string, startDate: string, endDate: string): Observable<DownloadableReportResponse> {
        return this._reportServiceClient.downlodableReport(companyId, type, startDate, endDate);
    }

    formatDate(date: string, type: START_OR_END_OF = START_OR_END_OF.START): string {
        const momentDate = moment(date);
        const format = 'YYYY-MM-DDTHH:mm:ss.SSS';

        return type === START_OR_END_OF.START
            ? momentDate.startOf('day').format(format) + 'Z'
            : momentDate.endOf('day').format(format) + 'Z';
    }

    downloadExcel(companyId: string, startDate?: string, endDate?: string): Observable<Blob> {
        const startDateQuery = startDate ? `&startDate=${this.formatDate(startDate, START_OR_END_OF.START)}` : '';
        const endDateQuery = endDate ? `&endDate=${this.formatDate(endDate, START_OR_END_OF.END)}` : '';
        const query = `${startDateQuery}${endDateQuery}&format=EXCEL`.replace(/^&/, '');

        const url = `${this._config.get('baseApiUrl')}/api/account/company/${companyId}/statement?${query}`;

        return this._httpClient.get(url, { 'responseType': 'blob' })
            .pipe(
                timeout(300000),
            );
    }
}
