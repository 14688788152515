import {Component, HostBinding, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {DIALOG_RESULT} from '@red/components';
import {FormBuilder, FormGroup} from '@angular/forms';

const storage = window.localStorage;

export enum HAS_READ {
    TRUE = 'TRUE',
    FALSE = 'FALSE'
}

@Component({
    selector: 'app-red-flag-shut-down-dialog',
    styleUrls: ['./red-flag-shut-down-dialog.sass'],
    templateUrl: 'red-flag-shut-down-dialog.tpl.html'
})
export class RedFlagShutDownDialogComponent implements OnInit {
    @HostBinding('class.red-flag-shut-down-message') cssClass = true;

    get formGroup(): FormGroup { return this._formGroup; }

    private _dialogRef: MatDialogRef<RedFlagShutDownDialogComponent>;
    private _formGroup: FormGroup;
    private _formBuilder: FormBuilder;

    constructor(
        dialogRef: MatDialogRef<RedFlagShutDownDialogComponent>,
        formBuilder: FormBuilder
    ) {
        this._dialogRef = dialogRef;
        this._formBuilder = formBuilder;
    }

    ngOnInit(): void {
        this._formGroup = this._formBuilder.group({
            ok: [false]
        });

        this._setNote();
    }

    close() {
        this._dialogRef.close(DIALOG_RESULT.CANCEL);
    }

    private _setNote() {
        const current = storage.getItem('haveReadRedFlagShutDownNote') === HAS_READ.TRUE
            ? HAS_READ.TRUE
            : HAS_READ.FALSE;

        storage.setItem('haveReadRedFlagShutDownNote', current);

        this._formGroup.get('ok').valueChanges
            .subscribe((val: boolean) => {
                const newVal = val ? HAS_READ.TRUE : HAS_READ.FALSE;

                storage.setItem('haveReadRedFlagShutDownNote', newVal);
            });
    }
}
