import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import {RedBlankSlate2020Component} from './blank-slate';
import {RouterModule} from '@angular/router';
import {ONBOARDING_ROUTES} from '../../views/onboarding/onboarding.routes';
import {OnboardingAlertModule} from '../onboarding-alert/onboarding-alert.module';


@NgModule({
    declarations: [
        RedBlankSlate2020Component
    ],
    exports: [
        RedBlankSlate2020Component
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        // RouterModule.forRoot(ONBOARDING_ROUTES),
        OnboardingAlertModule,
    ],
    providers: []
})
export class BlankSlateModule {}
