
import {filter} from 'rxjs/operators';
import {BaseEvent} from './event';
import {ReplaySubject, Observable} from 'rxjs';


export class EventSource {
    private _events: ReplaySubject<BaseEvent>;

    constructor () {
        this._events = new ReplaySubject<BaseEvent>();
    }

    get events(): ReplaySubject<BaseEvent> { return this._events; }

    by(type: typeof BaseEvent): Observable<BaseEvent> {
        return this._events.pipe(
            filter(evt => evt instanceof type));
    }

    emit(event: BaseEvent) {
        this._events.next(event);
    }
}
