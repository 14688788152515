import {Component, HostBinding, Inject, OnInit} from '@angular/core';
import {
    Amount,
    AnnualReport,
    CURRENCY,
    DividendsPaymentRequest,
    DividendsPaymentResponse
} from '@red/data';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {DIALOG_RESULT, RedDialog, RedNotification} from '@red/components';
import {FormGroup} from '@angular/forms';
import {DividendsPaymentConfirmComponent} from '../confirm/confirm';
import {EventSource} from '../../../common/event/source';
import {DividendsCreatedEvent} from '../../../common/event/readers/dividends';
import {ActivatedRoute} from '@angular/router';
import {AnnualReportManager} from '../../../managers/annual-report/annual-report.manager';
import { QueryFilter } from '@red/browser';
import {Store} from '@ngxs/store';
import {FinancialStatementState} from '../../../shared/state/financial-statement/financial-statement.state';

@Component({
    selector: 'app-dividends-create-dialog',
    styleUrls: ['./create.sass'],
    templateUrl: './create.tpl.html'
})
export class DividendsCreateComponent implements OnInit {
    @HostBinding('class.dividends-create') cssClass = true;

    get annualReport(): AnnualReport {
        return this._annualReport;
    }
    get canPayDividends(): boolean {
        return this._canPayDividends;
    }
    get isCalculating(): boolean {
        return this._isCalculating;
    }
    get payments(): DividendsPaymentResponse {
        return this._payments;
    }
    get limit(): Amount {
        return this._limit;
    }
    get showCalculatedPayouts(): boolean {
        return this._showCalculatedPayouts;
    }

    private _annualReport: AnnualReport;
    private _annualReportId: string;
    private _annualReportManager: AnnualReportManager;
    private _canPayDividends = false;
    private _dialog: RedDialog;
    private _dialogRef: MatDialogRef<DividendsCreateComponent>;
    private _dividends: number;
    private _eventSource: EventSource;
    private _isCalculating = false;
    private _notification: RedNotification;
    private _payments: DividendsPaymentResponse;
    private _route: ActivatedRoute;
    private _showCalculatedPayouts = false;
    private _store: Store;
    private _limit: Amount;

    constructor (
        annualReportManager: AnnualReportManager,
        dialog: RedDialog,
        dialogRef: MatDialogRef<DividendsCreateComponent>,
        eventSource: EventSource,
        store: Store,
        notification: RedNotification,
        route: ActivatedRoute,
        @Inject(MAT_DIALOG_DATA) data: any
    ) {
        this._annualReportManager = annualReportManager;
        this._dialog = dialog;
        this._dialogRef = dialogRef;
        this._eventSource = eventSource;
        this._store = store;
        this._notification = notification;
        this._route = route;
        this._annualReportId = data.reportId;
    }

    ngOnInit(): void {
        this._limit = this._store.selectSnapshot(FinancialStatementState.limit);
        this._annualReportManager.getAnnualReport(this._annualReportId)
            .subscribe((response: AnnualReport) => {
                this._annualReport = response;
            });
    }

    calculatePayouts(form: FormGroup) {
        const dividendsFormValue = form.get('dividends').value;
        const dividends = parseFloat(dividendsFormValue);
        if (isNaN(dividends)) {
            this._showCalculatedPayouts = false;
            this._isCalculating = false;
        } else {
            this._dividends = dividends;
            const request = this._createDivdidendsPaymentRequest(this._dividends);
            this._isCalculating = true;

            this._annualReportManager.dividendsPayout(this._annualReport.id, request)
                .subscribe((payments: DividendsPaymentResponse) => {
                    this._showCalculatedPayouts = true;
                    this._isCalculating = false;
                    this._payments = payments;
                    this._canPayDividends = form.pristine && this._canPayDividendsCondition();
                }, err => {
                    this._showCalculatedPayouts = false;
                    this._isCalculating = false;
                    const msg = $localize`:RedNotification|Dividends should be less or equal to sts or remaining dividends:Your dividends should be less or equal to safe to spend or remaining dividends`;

                    this._notification.open(msg, null,  {'duration': 6000});
                });
        }
    }

    close() {
        this._dialogRef.close(DIALOG_RESULT.OK);
    }

    pay() {
        const request = this._createDivdidendsPaymentRequest(this._dividends);
        const query = new QueryFilter()
            .equal('dryRun', false);

        this._annualReportManager.dividendsPayout(this._annualReport.id, request, query)
            .subscribe((resp: DividendsPaymentResponse) => {
                this._eventSource.emit(new DividendsCreatedEvent());
                const msg = $localize`:RedNotification|Successful dividends payout:You paid dividends`;
                this._notification.open(msg);
                this._dialogRef.close(DIALOG_RESULT.OK);

            }, err => {
                const msg = $localize`:RedNotification|Failed to pay dividends:Failed to pay dividends`;
                this._notification.errorWithCode(err, msg);
            });
    }

    onDividendsFormChange(form: FormGroup) {
        this._dividends = form.get('dividends').value;
        this._canPayDividends = form.pristine && this._canPayDividendsCondition();
    }

    onDividendsPay() {
        const dividends = new Amount({'amount': this._dividends, 'currency': CURRENCY.SEK});
        const numberOfShareholders = this._payments.payments.length;

        this._dialog.confirm(DividendsPaymentConfirmComponent, {
            'data': {
                'dividends': dividends,
                'numberOfShareholders': numberOfShareholders
            }
        })
            .afterClosed()
            .subscribe((result: DIALOG_RESULT) => {
                if (result === DIALOG_RESULT.OK) {
                    this.pay();
                }
            });
    }

    private _canPayDividendsCondition() {
        return (this._dividends > 0 && this._dividends <= this._limit.amount && this._dividends <= this._annualReport.remainingDividends.amount);
    }

    private _createDivdidendsPaymentRequest(dividends: number) {
        return new DividendsPaymentRequest({
            'amount': new Amount({
                'amount': dividends,
                'currency': CURRENCY.SEK
            })
        });
    }
}
