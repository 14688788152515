import {Component, HostBinding, Input} from '@angular/core';
import {Receipt, Transaction} from '@red/data';

@Component({
    selector: 'app-receipt-list-preview',
    styleUrls: ['./list-preview.sass'],
    templateUrl: './list-preview.tpl.html'
})
export class ReceiptListPreviewComponent {
    @HostBinding('class.receipt-list-view') cssClass = true;

    @Input() receipt: Receipt|Transaction;

    resourceUrl(): string {
        return this.receipt.assetId ? this.receipt.assetId : null;
    }
}
