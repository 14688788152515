<div class="red-dialog-title" matDialogTitle>
    <div class="close-back-btn" [ngSwitch]="state">
        <button mat-button (click)="showInvoiceForm($event)" class="icon-back" *ngSwitchCase="stateSelectCustomer">
            <mat-icon svgIcon="ui-keyboard-backspace-white"></mat-icon>
        </button>
         <button *ngSwitchDefault mat-button (click)="close()" tabindex="-1">
            <mat-icon svgIcon="ui-close-white"></mat-icon>
        </button>
    </div>
    <span class="red-dialog-title-text">
        <span *ngIf="isNew" i18n="Invoice|Header for new invoice">New invoice</span>
        <span *ngIf="!isNew && isDebit" i18n="Invoice|Header for edit invoice">Invoice</span>
        <span *ngIf="!isNew && isCredit" i18n="Invoice|Header for credit invoice">Credit invoice</span>
    </span>
    <red-context-menu *ngIf="showContextMenu">
        <button
            *ngIf="showContextMenuSave"
            [disabled]="disableContextMenuSave || hasFormErrors()"
            mat-menu-item
            redContextMenuItem
            tabindex="-1"
            class="save-button"
            (click)="onMenuSave()"
            i18n="Save|Save in context menu for invoice"
        >Save</button>
        <button *ngIf="showContextMenuSaved" [disabled]="true" mat-menu-item redContextMenuItem tabindex="-1" i18n="Saved|Saved in context menu for invoice">Saved</button>
        <button *ngIf="showContextMenuDelete" [disabled]="disableContextMenuDelete" mat-menu-item redContextMenuItem tabindex="-1" (click)="onMenuDelete()" i18n="Delete|Delete in context menu for invoice">Delete</button>
        <button *ngIf="showContextMenuCredit" mat-menu-item redContextMenuItem tabindex="-1" (click)="onMenuCredit()" i18n="Credit|Credit in context menu for invoice">Credit</button>
        <button mat-menu-item *ngIf="hasPDF">
            <red-asset-download-button [assetId]="assetId" [isContextMenuItem]="true" (click)="downloadPDF($event)">
                <span i18n="Context menu | download invoice">Download invoice</span>
            </red-asset-download-button>
        </button>
    </red-context-menu>
</div>
<div class="red-dialog-content" #dialogContent matDialogContent tabindex="0" [ngClass]="{'loading': isLoading}">
    <div class="customer-view" [ngSwitch]="state">
        <red-loader-overlay *ngSwitchCase="stateLoading"><red-atom-loader></red-atom-loader></red-loader-overlay>
        <div *ngSwitchCase="stateNoRegistrationGrantedDate">
            <p class="warning-text no-registration-granted-date-message" i18n="Invoice|No registration granted date message">No registration granted date message</p>
        </div>
        <div *ngSwitchCase="stateSelectCustomer">
            <customer-recent
                [countries]="localCountries"
                [recentCustomers]="customers"
                (onCustomerSelect)="onCustomerSelect($event)"
            ></customer-recent>
            <customer-new [ngClass]="{'active': true, 'error-underline': !firstTry}" (onAddCustomer)="onAddCustomer($event)"></customer-new>
        </div>
        <div *ngSwitchDefault>
            <red-info-box *ngIf="showDownloadInfo">
                <mat-icon svgIcon="ui-alert-red"></mat-icon>
                <red-markdown-content [markdownPath]="invoiceDownloadMarkdown"></red-markdown-content>
            </red-info-box>
            <div *ngIf="isInReview || invoiceToReview">
                <div class="credit-row">
                    <span i18n="Invoice preview|information about invoices that needs to be checked by support">Your invoice is in review, we'll contact you when you can download and send it to your customer</span>
                </div>
            </div>
            <div *ngIf="isWaitingForPDF() && !invoiceToReview">
                <div class="credit-row">
                    <span i18n="Download invoice as PDF">Download invoice as PDF</span>
                    <button mat-button disabled i18n="Waiting for PDF to be created|Button">Waiting for PDF...</button>
                </div>
            </div>
            <div *ngIf="hasPDF && !hasPayments">
                <div class="credit-row">
                    <span i18n="Download Invoice as PDF">Download Invoice as PDF</span>
                    <red-asset-download-button [assetId]="assetId">
                        <span i18n="Download">Download</span>
                    </red-asset-download-button>
                </div>
            </div>
            <div *ngIf="isPartiallyPaid">
                <red-table-row class="invoice-partially-paid-row">
                    <span i18n="Underpaid invoice|left to pay">Left to pay</span>
                    <span redTableAmount [innerHTML]="dueAmount | amount"></span>
                </red-table-row>
                <div class="invoice-partially-paid-row" *ngIf="notReadOnlyState">
                    <span i18n="Partially paid invoice actions|The invoice is not yet fully paid">
                        The invoice is not yet fully paid. If you will not receive more payments, you can either accept the loss or sub-credit the invoice.
                        <a href="{{invoicePartiallyPaidLink}}" target="_blank">read all about it</a>
                    </span>
                </div>
                <div class="invoice-partially-paid-row invoice-partially-paid-info-actions" *ngIf="notReadOnlyState">
                    <button mat-flat-button color="primary" class="invoice-partially-paid-info-button" (click)="onMenuCredit()" i18n="Partially credit button|Invoice">Partially credit</button>
                    <button mat-flat-button color="primary" class="invoice-partially-paid-info-button" (click)="abandon()" i18n="Abandon invoice button|Invoice">Abandon invoice</button>
                </div>
            </div>
            <div *ngIf="isOverPaid">
                <red-table-row class="warning-background">
                    <span i18n="Overpaid invoice|Was overpaid by">Overpaid</span>
                    <span redTableAmount [innerHTML]="dueAmount | amount"></span>
                </red-table-row>
            </div>
            <div *ngIf="showPaymentInfo">
                <ng-container *ngFor="let payment of paymentDetails">
                    <red-table-row *ngIf="shouldDisplayPayment(payment)">
                        <span *ngIf="isCorrection(payment)" i18n="Invoice: Correction date">Correction {{payment.received | moment}}</span>
                        <span i18n="Invoice was paid at date" *ngIf="!isCorrection(payment)">Was paid {{payment.received | moment}}</span>
                        <span redTableAmount [innerHTML]="getPaymentAmount(payment) | amount"></span>
                    </red-table-row>
                </ng-container>
            </div>
            <div *ngIf="showTotalPaymentAmount">
                <red-table-row redTableBold class="total-background">
                    <span i18n="invoice total paid">Total paid</span>
                    <span redTableAmount [innerHTML]="calculatedTotalPaymentAmount() | amount"></span>
                </red-table-row>
            </div>
            <div *ngIf="hasCredit">
                <div class="credit-row">
                    <span i18n="This invoice has been credited">This invoice has been credited</span>
                    <button type="button" mat-button  color="primary" (click)="navigateToInvoiceById(creditedBy)" i18n="Show">Show</button>
                </div>
            </div>
            <div *ngIf="isCredit">
                <div class="credit-row">
                    <span i18n="This invoice is a credit for">This invoice is a credit for</span>
                    <button type="button" mat-button color="primary" (click)="navigateToInvoiceById(crediting)" i18n="Show">Show</button>
                </div>
            </div>
            <div *ngIf="isSentAsLetter">
                <div class="credit-row">
                    <span i18n="Reminder that invoice was sent as letter">Invoice was sent as letter to:</span>
                </div>
            </div>
            <div *ngIf="!isInvoiceDisabled && isNoCustomer" class="show-customer-btn">
                <button mat-flat-button color="primary" (click)="showSelectCustomer($event)" i18n="Select a customer for a invoice">Select customer</button>
            </div>
            <div *ngIf="editInvoiceForm">
                <form [formGroup]="editInvoiceForm" class="edit-invoice" #invoiceForm tabindex="0" red-form>
                    <div formGroupName="customer" class="customer-wrapper" [ngClass]="{'disabled-input': isInvoiceDisabled}">
                        <div class="customer" formGroupName="data">
                            <mat-form-field *ngIf="!isNoCustomer">
                                <input
                                    matInput
                                    required
                                    i18n-placeholder="Customer" placeholder="Customer"
                                    formControlName="name" />
                            </mat-form-field>
                        </div>
                        <div *ngIf="!isNoCustomer"><button type="button" mat-button color="primary" tabindex="-1" class="search-new-customer" (click)="showSelectCustomer($event)" *ngIf="isDraft" i18n="search new customer button">SEARCH NEW CUSTOMER</button></div>
                    </div>

                    <red-form-address
                        *ngIf="!isNoCustomer"

                        [addressFormGroup]="customerAddressForm"
                        [addRemoveAddressLines]="false"
                        [nestedForm]="true"
                        [showCountryCode]="!isNoCustomer && canSendInternationalInvoices"
                        [customCountries]="allowedCountries"
                    >
                        <mat-form-field *ngIf="!isNoCustomer && canSendInternationalInvoices">
                            <mat-select ngDefaultControl
                                formControlName="currency"
                                i18n-placeholder="Default currency for customer form" placeholder="Currency">
                                <mat-option *ngFor="let currency of currencies.data" [value]="currency">{{currency}}</mat-option>
                            </mat-select>
                            <mat-hint *ngIf="isControlInvalid('currency')" i18n="New customer currency is required">Currency is required</mat-hint>
                        </mat-form-field>
                    </red-form-address>

                    <div class="red-row">
                        <mat-form-field>
                            <input
                                matInput
                                i18n-placeholder="Reference" placeholder="Reference"
                                autocomplete="off"
                                required
                                formControlName="buyerContact"
                            />
                            <mat-hint *ngIf="isControlInvalid('buyerContact')" align="start" i18n="Customer reference is required">Customre reference is required</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="payment-conditions red-row">
                        <mat-form-field>
                            <input
                                matInput
                                i18n-placeholder="Date"
                                placeholder="Date"
                                formControlName="date"
                                readonly="readonly"
                                [min]="minInvoiceDate"
                                [max]="today"
                                (click)="openInvoiceDatepicker($event)"
                                [matDatepicker]="invoiceDatepicker"
                            />
                            <mat-datepicker
                                #invoiceDatepicker
                                [touchUi]="isMobileDevice"
                            ></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field class="expires-date">
                            <input
                                matInput
                                required
                                i18n-placeholder="Conditions"
                                placeholder="Conditions"
                                formControlName="expires"
                                readonly="readonly"
                                [min]="minExpiresDate"
                                [matDatepicker]="invoiceExpireDatepicker"
                                (click)="openInvoiceExpireDatepicker($event)"
                                #inputInvoiceExpire
                            />
                            <mat-datepicker
                                #invoiceExpireDatepicker
                                [touchUi]="isMobileDevice"
                                [startAt]="invoiceExpireDate"
                            ></mat-datepicker>
                            <mat-hint *ngIf="isControlInvalid('expires')" align="start" i18n="Invalid expire date format">Invalid expire date format, should be YYYY-MM-DD</mat-hint>
                        </mat-form-field>
                        <button type="button" mat-button class="condition-select-btn" [matMenuTriggerFor]="menu"><div class="select-arrow"></div></button>
                        <mat-menu #menu="matMenu" xPosition="before">
                            <button mat-menu-item *ngFor="let days of terms" i18n="[days] terms of payment for invoice" (click)="updateConditions(days)">{{days}} days</button>
                        </mat-menu>
                    </div>
                    <div class="edit-invoice-form-row red-row x2" *ngIf="isInvoiceDisabled" [ngClass]="{'disabled-input': isInvoiceDisabled}">
                        <mat-form-field>
                            <input
                                *ngIf="hasInvoiceNumber"
                                matInput
                                i18n-placeholder="Invoice number"
                                placeholder="Invoice number"
                                formControlName="number"
                            />
                            <input
                                *ngIf="!hasInvoiceNumber"
                                matInput
                                disabled
                                i18n-placeholder="Invoice number"
                                placeholder="Invoice number"
                                i18n-value="Invoice is pending|Label displayed when a invoice doesnt have a number yet."
                                value="Invoice is pending"
                            />
                        </mat-form-field>
                        <mat-form-field>
                            <input
                                matInput
                                i18n-placeholder="OCR number" placeholder="OCR"
                                formControlName="reference"
                            />
                        </mat-form-field>
                    </div>
                    <div class="line-items">
                        <ol formArrayName="items" [ngClass]="{'disabled-input': isInvoiceDisabled}" *ngIf="isDebit">
                            <li *ngFor="let lineItem of editInvoiceForm.controls['items']['controls']; let i = index; let isLast = last;" [formGroupName]="i">
                                <div class="item-description" formGroupName="item">
                                    <mat-form-field>
                                        <input
                                            matInput
                                            i18n-placeholder="Description" placeholder="Description"
                                            formControlName="name"
                                            autocomplete="off"
                                            (blur)="updateLine(lineItem.value, i)"
                                        />
                                        <mat-hint *ngIf="isItemsControlValid(i, 'item.name')" align="start" i18n="Description is required">Description is required</mat-hint>
                                    </mat-form-field>
                                </div>
                                <div class="line-items-info-wrapper">
                                    <div class="line-item-count">
                                        <mat-form-field>
                                            <input
                                                matInput
                                                i18n-placeholder="Amount" placeholder="Amount"
                                                formControlName="count"
                                                type="number"
                                                autocomplete="off"
                                                (blur)="updateLine(lineItem.value, i)"/>
                                            <mat-hint *ngIf="isItemsControlValid(i, 'count')" align="start" i18n="Amount is required">Amount is required</mat-hint>
                                        </mat-form-field>
                                    </div>
                                    <div formGroupName="item" class="line-item">
                                        <div formGroupName="price" class="line-item-info-price">
                                            <mat-form-field>
                                                <input
                                                    matInput
                                                    i18n-placeholder="Price excl vat" placeholder="Price excl vat"
                                                    formControlName="amount"
                                                    type="number"
                                                    autocomplete="off"
                                                    (blur)="updateLine(lineItem.value, i)"/>
                                                <mat-hint *ngIf="isItemsControlValid(i, 'item.price')" align="start" i18n="Price is required">Price is required</mat-hint>
                                            </mat-form-field>
                                        </div>
                                        <div class="line-item-VAT">
                                            <mat-form-field>
                                                <mat-select
                                                    i18n-placeholder="Vat label for select" placeholder="VAT"
                                                    formControlName="VAT"
                                                    (selectionChange)="updateLine(lineItem.value, i)"
                                                >
                                                    <mat-option
                                                        [value]="getPercentageForVatType(vat)"
                                                        *ngFor="let vat of vatList">{{getPercentageLabelForVatType(vat)}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                                <div class="line-item-total-wrapper">
                                    <span i18n="line-item-total-netto" class="line-item-total-text">Total excl VAT</span>
                                    <span class="amount" [innerHTML]="lineItem.controls.netPrice.value | amount"></span>
                                </div>
                                <div class="button-wrapper"><button type="button" i18n="Delete invoice line button" mat-button *ngIf="isDraft"  class="deleteItem" (click)="removeLine(i)" tabindex="-1">DELETE</button>
                                    <button type="button" i18n="add new line button" class="addItem" mat-button *ngIf="isDraft && isLast" (click)="newLineItem()" tabindex="-1">NEW LINE</button>
                                </div>
                            </li>
                        </ol>
                        <div formArrayName="items" [ngClass]="{'disabled-input': isInvoiceDisabled}" *ngIf="isCredit">
                        </div>
                        <red-table-row>
                            <span i18n="Total net">Total</span>
                            <span redTableAmount [innerHTML]="editInvoiceForm.controls['totalNet'].value | amount"></span>
                        </red-table-row>
                        <red-table-row>
                            <span i18n="Total vat ">VAT</span>
                            <span redTableAmount [innerHTML]="editInvoiceForm.controls['totalVAT'].value | amount"></span>
                        </red-table-row>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<mat-dialog-actions class="red-dialog-actions" *ngIf="showActions">
    <div class="total"><h4 i18n="invoice total">Total </h4> <h4 class="total-amount" [innerHTML]="totalGross | amount"></h4></div>
    <button mat-flat-button *ngIf="isDraft" [disabled]="hasFormErrors()" (click)="saveAndSend()" i18n="Invoice|button|Continue" tabindex="-1">Continue</button>
</mat-dialog-actions>
<mat-dialog-actions class="red-dialog-actions" *ngIf="stateIsStateNoRegistrationGrantedDate">
    <button mat-flat-button i18n="OK" (click)="close()" tabindex="-1">OK</button>
</mat-dialog-actions>

