import {NgModule} from '@angular/core';

import {TermViewComponent} from './term/term-view';
import {MarkdownLoader} from '../markdown/markdown-loader';
import {BrowserModule} from '@angular/platform-browser';

@NgModule({
    declarations: [
        TermViewComponent
    ],
    exports: [
        TermViewComponent
    ],
    imports: [],
    providers: [
        BrowserModule,
        MarkdownLoader
    ],
})
export class ConsentModule {
}
