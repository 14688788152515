import {Injectable} from '@angular/core';
import {RedDialog} from '@red/components';
import {ReauthorizeDialogComponent} from './reauthorize-dialog.component';
import {tap} from 'rxjs/operators';
import {MatDialogRef} from '@angular/material/dialog';

@Injectable()
export class ReauthorizeWorkflow {
    get isOpen(): boolean { return this._isOpen; }

    private _dialog: RedDialog;
    private _isOpen: boolean;
    private _dialogRef: MatDialogRef<ReauthorizeDialogComponent>;

    constructor(
        dialog: RedDialog
    ) {
        this._dialog = dialog;
    }

    start() {
        if (this._isOpen && this._dialogRef) {
            return this._dialogRef.afterClosed();
        }

        this._isOpen = true;
        this._dialogRef = this._dialog.alert(ReauthorizeDialogComponent, {
            'disableClose': true
        });

        return this._dialogRef.afterClosed()
            .pipe(
                tap(() => {
                    this._isOpen = false;
                })
            );
    }
}
