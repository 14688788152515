<div class="amount">
    <span *ngIf="hasAmount; else noAmount"
          class="amount-text"
          [ngClass]="{'cancelled': cancelled, 'negative': isNegative, 'amount-undefined': preliminaryAmount}"
          [innerHTML]="amount | amount"></span>
    <ng-template #noAmount>
        <span class="amount-undefined">...kr</span>
    </ng-template>
    <ng-content></ng-content>
</div>
