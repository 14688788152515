import {EventReader, OnEvent} from '../reader';
import {BaseEvent} from '../event';
import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {EventSource} from '../source';

export class CardTransactionUpdatedEvent extends BaseEvent {}

@Injectable()
export class CardTransactionEventReader extends EventReader {
    private _onUpdateStream: Subject<CardTransactionUpdatedEvent>;

    constructor (
        source: EventSource
    ) {
        super(source);

        this._onUpdateStream = new Subject<CardTransactionUpdatedEvent>();
    }

    onUpdate(fn: (evt: CardTransactionUpdatedEvent) => void) {
        this._onUpdateStream.subscribe(fn);
    }

    @OnEvent(CardTransactionUpdatedEvent)
    private _handleCardTransactionUpdatedEvent(evt: CardTransactionUpdatedEvent) {
        this._onUpdateStream.next(evt);
    }
}
