import {Component, Inject, Input} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {DIALOG_RESULT} from '@red/components';

@Component ({
    templateUrl: './warning.tpl.html'
})
export class InvoiceWarningComponent {
    private _dialogRef: MatDialogRef<InvoiceWarningComponent>;

    constructor (
        dialogRef: MatDialogRef<InvoiceWarningComponent>,
        @Inject(MAT_DIALOG_DATA) data: any
    ) {
        this._dialogRef = dialogRef;
    }

    cancel() {
        this._dialogRef.close(DIALOG_RESULT.CANCEL);
    }

    confirm() {
        this._dialogRef.close(DIALOG_RESULT.OK);
    }

    decline() {
        this._dialogRef.close(DIALOG_RESULT.REJECT);
    }
}
