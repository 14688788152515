<mat-expansion-panel class="mat-expansion-panel {{statusClassName}}">
    <mat-expansion-panel-header>
        <mat-panel-title i18n="Onboarding|Panel title for BLV Board Form">
            BLV Board Form
        </mat-panel-title>
        <mat-panel-description></mat-panel-description>
    </mat-expansion-panel-header>
    <form [formGroup]="form" red-form>
        <section class="info-section">
            <div class="info-text main-info-text">
                Här lägger du in styrelsemedlemmar och eventuell VD.
            </div>

            <app-read-more-less>
                <span class="short-text">
                    Den minsta styrelsen du kan välja är en styrelseledamot och en styrelsesuppleant.
                </span>
                <div class="long-text">
                    <p>Den minsta styrelsen du kan välja är en styrelseledamot och en styrelsesuppleant.</p>
                    <p>Om styrelsen har färre än tre styrelseledamöter ska det finnas minst en styrelsesuppleant.</p>
                    <p>
                        Om styrelsen består av två eller fler styrelseledamöter behöver du utse en av dem som styrelseordförande.
                    </p>
                    <p>
                        <a target="_blank" href="https://bolagsverket.se/ff/foretagsformer/aktiebolag/starta/styrelse/vd-1.3172">Läs mer om hur en styrelse kan se ut på bolagsverket.se</a>
                    </p>
                </div>
            </app-read-more-less>

            <div class="info-text">
                I privata aktiebolag är det valfritt om man vill utse en verkställande direktör (VD) eller inte.
            </div>
            <app-read-more-less>
                <span class="short-text">
                    Om VD inte är styrelsemedlem välj "Extern VD" i drop-listan för "Roll"
                </span>
                <div class="long-text">
                    <p>Om VD inte är styrelsemedlem: välj "Extern VD" i drop-listan för "Roll"</p>
                    <p>
                        Om VD är styrelsemedlem: Välj "VD" med radioknappen till höger om drop-listan för "Roll"
                    </p>
                    <p>
                        <a target="_blank" href="https://bolagsverket.se/ff/foretagsformer/aktiebolag/starta/styrelse/vd-1.3172">Läs mer om hur en styrelse kan se ut på bolagsverket.se</a>
                    </p>
                </div>
            </app-read-more-less>
            <div class="info-text">
                Alla i styrelsen måste vara skrivna i Sverige.
            </div>
        </section>

        <ng-container formGroupName="boardMembers">
            <ng-container *ngFor="let alias of boardMembersFormArray.controls; let i=index">
                <fieldset [formGroupName]="i">
                    <legend>
                        <span i18n="Onboarding|LLC New blv board form|Board member {{i + 1}}">Board member {{i + 1}}</span>
                        <span
                            class="remove-board-member"
                            *ngIf="showRemoveButton(i, this.formDisabled)"
                            (click)="removeBoardMember(i)"
                            i18n="Onboarding|LLC New blv board form|Remove board member"
                        >Remove board member</span>
                    </legend>

                    <ng-template #boardMemberForm>
                        <div class="red-row identifier-row">
                            <mat-form-field>
                                <input
                                    matInput
                                    i18n-placeholder="Onboarding|LLC New blv board form|Personal identifier"
                                    placeholder="Personal identifier"
                                    formControlName="identifier"
                                    autocomplete="off"
                                />
                                <mat-hint class="error" *ngIf="duplicatedIdentifier(i)">
                                    Det finns redan en person i styrelsen med personnummer "{{duplicatedIdentifier(i)}}"
                                </mat-hint>
                            </mat-form-field>
                            <div class="red-col button-col" *ngIf="!formDisabled">
                                <button
                                    mat-flat-button
                                    color="primary"
                                    data-type="getuserInformationButton"
                                    [disabled]="formDisabled || duplicatedIdentifier(i)"
                                    (click)="getUserInformation(i)"
                                    i18n="Onboarding|LLC New blv board form|Get user information"
                                >Get user information</button>
                            </div>
                        </div>
                        <div class="red-row">
                            <mat-form-field>
                                <input
                                    matInput
                                    i18n-placeholder="Onboarding|LLC New blv board form|First name"
                                    placeholder="First name"
                                    formControlName="firstName"
                                    autocomplete="off"
                                />
                            </mat-form-field>
                            <mat-form-field>
                                <input
                                    matInput
                                    i18n-placeholder="Onboarding|LLC New blv board form|Last name"
                                    placeholder="Last name"
                                    formControlName="lastName"
                                    autocomplete="off"
                                />
                            </mat-form-field>
                        </div>
                        <div class="red-row">
                            <mat-form-field>
                                <mat-label i18n="Onboarding|LLC New blv board form|Select board member role">Select board member role</mat-label>
                                <mat-select formControlName="role" (selectionChange)="checkBoardRoles()">
                                    <mat-option [value]="'BOARD_MEMBER'">Styrelseledamot</mat-option>
                                    <mat-option [value]="'ALTERNATE_MEMBER'">Suppleant</mat-option>
                                    <mat-option [value]="'CEO'">Extern VD</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div class="red-col">
                                <mat-radio-button
                                    *ngIf="canBeChairman(i)"
                                    [checked]="getBoolean('isChairman', i)"
                                    [value]="i"
                                    (change)="radio(i, 'isChairman')"
                                    name="isChairman"
                                    i18n="Onboarding|LLC New blv board form|chairman"
                                    [disabled]="formDisabled"
                                >Chairman</mat-radio-button>
                                <mat-radio-button
                                    *ngIf="!boardHasCeoRole"
                                    [checked]="getBoolean('isCeo', i)"
                                    [value]="i"
                                    (change)="radio(i, 'isCeo')"
                                    name="isCeo"
                                    i18n="Onboarding|LLC New blv board form|ceo"
                                    [disabled]="formDisabled"
                                >Ceo</mat-radio-button>
                            </div>
                        </div>
                        <red-form-address
                            [addressFormGroup]="boardMemberAddress(i)"
                            [addRemoveAddressLines]="false"
                            [showCountryCode]="false"
                        ></red-form-address>
                    </ng-template>

                    <ng-container *ngIf="i === 0; else boardMemberForm">
                        <div class="red-row">
                            <div class="red-col board-member-text">
                                <p>{{getBoardMemberName(0)}}, {{getBoardMemberIdentifier(0)}}</p>
                            </div>
                        </div>
                        <div class="red-row">
                            <mat-form-field>
                                <mat-label i18n="Onboarding|LLC New blv board form|Select board member role">Select board member role</mat-label>
                                <mat-select formControlName="role" (selectionChange)="checkBoardRoles()">
                                    <mat-option [value]="'BOARD_MEMBER'">Styrelseledamot</mat-option>
                                    <mat-option [value]="'ALTERNATE_MEMBER'">Suppleant</mat-option>
                                    <mat-option [value]="'CEO'">Extern VD</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div class="red-col">
                                <mat-radio-button
                                    *ngIf="canBeChairman(i)"
                                    [checked]="getBoolean('isChairman', i)"
                                    [value]="i"
                                    (change)="radio(i, 'isChairman')"
                                    name="isChairman"
                                    i18n="Onboarding|LLC New blv board form|chairman"
                                    [disabled]="formDisabled"
                                >Chairman</mat-radio-button>
                                <mat-radio-button
                                    *ngIf="!boardHasCeoRole"
                                    [checked]="getBoolean('isCeo', i)"
                                    [value]="i"
                                    (change)="radio(i, 'isCeo')"
                                    name="isCeo"
                                    i18n="Onboarding|LLC New blv board form|ceo"
                                    [disabled]="formDisabled"
                                >Ceo</mat-radio-button>
                            </div>
                        </div>
                        <div class="red-row">
                            <red-display-address
                                [address]="getBoardMemberAddress(i)"
                                [showHeader]="false"
                                [showCountry]="false"
                            ></red-display-address>
                        </div>
                    </ng-container>
                </fieldset>
            </ng-container>
            <div class="actions board-members-actions" *ngIf="!formDisabled">
                <button mat-flat-button type="button" class="add-button" (click)="addBoardMember()" i18n="Onboarding|LLC New blv board form|Add board member">Add board member</button>
            </div>
        </ng-container>
        <ul class="form-errors-list" [ngClass]="{'form-touched-not-valid': form && form.touched && !form.valid}" *ngIf="showErrorList && formErrors">
            <li *ngFor="let error of formErrors">
                {{error.message}}
            </li>
        </ul>
        <section *ngIf="showBoardSummary" class="board-summary" red-grid>
            <h3 class="section-header" i18n="Onboarding|LLC New blv board form|Board summary">Board summary</h3>
            <ul>
                <li *ngFor="let alias of boardMembersFormArray.controls; let i=index">
                    {{getMemberSummary(i)}}
                </li>
            </ul>
        </section>
        <div class="actions" *ngIf="!formDisabled">
            <button mat-flat-button color="primary" type="button" (click)="save($event)" i18n="Onboarding|Save button">Save</button>
            <button mat-flat-button color="primary" data-type="save-and-send" [disabled]="form.invalid" type="button" (click)="saveAndSend($event)" i18n="Onboarding|Save & Send button">Save & Send</button>
        </div>
    </form>
</mat-expansion-panel>
