import {Component, Input, OnInit} from '@angular/core';
import {EndOfYearManager} from '../../../managers/end-of-year/end-of-year.manager';
import {COMPANY_TYPE, YEAR_END_REPORT_STATUS, YEAR_END_REPORT_TYPE} from '@red/data';

@Component({
    selector: 'app-end-of-year-reports',
    styleUrls: ['../end-of-year.dialog.sass', './end-of-year-reports.sass'],
    templateUrl: 'end-of-year-reports.tpl.html'
})

export class EndOfYearReportsComponent implements OnInit {
    @Input() isDialogView: boolean;

    get ink2AssetId(): string { return this._ink2AssetId; }
    get annualReportAssetId(): string { return this._annualReportAssetId; }
    get protocolAssetId(): string { return this._protocolAssetId; }
    get balanceReportAssetId(): string { return this._balanceReportAssetId; }
    get profitlossReportAssetId(): string { return this._profitlossReportAssetId; }
    get neReportAssetId(): string { return this._neReportAssetId; }
    get simplifiedAnnualAccountsReportAssetId(): string { return this._simplifiedAnnualAccountsReportAssetId; }

    get year() { return this._endOfYearManager.currentFiscalYear(); }

    private _balanceReportAssetId: string;
    private _profitlossReportAssetId: string;
    private _neReportAssetId: string;
    private _simplifiedAnnualAccountsReportAssetId: string;
    private _protocolAssetId: string;
    private _annualReportAssetId: string;
    private _ink2AssetId: string;

    private _endOfYearManager: EndOfYearManager;

    constructor(
        endOfYearManager: EndOfYearManager
    ) {
        this._endOfYearManager = endOfYearManager;
    }

    ngOnInit(): void {
        this._setReportAssetId();
    }

    private _setReportAssetId() {
        this._balanceReportAssetId = this._getReportAssetId(YEAR_END_REPORT_TYPE.BALANCE);
        this._profitlossReportAssetId = this._getReportAssetId(YEAR_END_REPORT_TYPE.PROFITLOSS);

        if (this._endOfYearManager.currentCompanyType === COMPANY_TYPE.SOLE_TRADER) {
            this._setReportsForSole();
        }

        if (this._endOfYearManager.currentCompanyType === COMPANY_TYPE.LIMITED_COMPANY) {
            this._setReportsForLLC();
        }
    }

    private _setReportsForSole() {
        this._neReportAssetId = this._getReportAssetId(YEAR_END_REPORT_TYPE.NE);
        this._simplifiedAnnualAccountsReportAssetId = this._getReportAssetId(YEAR_END_REPORT_TYPE.SIMPLIFIED_ANNUAL_ACCOUNTS);
    }

    private _setReportsForLLC() {
        this._protocolAssetId = this._getReportAssetId(YEAR_END_REPORT_TYPE.AGM_PROTOCOL);
        this._annualReportAssetId = this._getReportAssetId(YEAR_END_REPORT_TYPE.ANNUAL_REPORT);
        this._ink2AssetId = this._getReportAssetId(YEAR_END_REPORT_TYPE.INK_2);
    }

    private _getReportAssetId(reportType: YEAR_END_REPORT_TYPE) {
        const report = this._endOfYearManager.getReportByType(reportType);

        return (report && report.assetId)
            ? report.assetId
            : null;
    }
}
