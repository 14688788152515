<red-list [ngClass]="{'red-list-dialog': isDialogView}">
    <span redListHeader i18n="End of year|reports header">Documents</span>
    <mat-list-item *ngIf="balanceReportAssetId">
        <span mat-line i18n="End of year| balance report">Balance report {{year}}</span>
        <red-asset-download-button class="report-download-btn" align="end" [assetId]="balanceReportAssetId">
            <span i18n="download report">Download</span>
        </red-asset-download-button>
    </mat-list-item>
    <mat-list-item *ngIf="profitlossReportAssetId">
        <span mat-line i18n="End of year| profitloss">Profitloss report {{year}}</span>
        <red-asset-download-button class="report-download-btn" align="end" [assetId]="profitlossReportAssetId">
            <span i18n="download report">Download</span>
        </red-asset-download-button>
    </mat-list-item>
    <mat-list-item *ngIf="neReportAssetId">
        <span mat-line i18n="End of year| ne report">Ne report {{year}}</span>
        <red-asset-download-button class="report-download-btn" align="end" [assetId]="neReportAssetId">
            <span i18n="download report">Download</span>
        </red-asset-download-button>
    </mat-list-item>
    <mat-list-item *ngIf="simplifiedAnnualAccountsReportAssetId">
        <span mat-line i18n="End of year| simplified annual accounts report">Simplified annual accounts report report {{year}}</span>
        <red-asset-download-button class="report-download-btn" align="end" [assetId]="simplifiedAnnualAccountsReportAssetId">
            <span i18n="download report">Download</span>
        </red-asset-download-button>
    </mat-list-item>
    <mat-list-item *ngIf="annualReportAssetId">
        <span mat-line i18n="End of year| annual report document">Annual report {{year}}</span>
        <red-asset-download-button class="report-download-btn" align="end" [assetId]="annualReportAssetId">
            <span i18n="download report">Download</span>
        </red-asset-download-button>
    </mat-list-item>
    <mat-list-item *ngIf="protocolAssetId">
        <span mat-line i18n="End of year| protocol document">Annual meeting minutes {{year}}</span>
        <red-asset-download-button class="report-download-btn" align="end" [assetId]="protocolAssetId">
            <span i18n="download report">Download</span>
        </red-asset-download-button>
    </mat-list-item>
    <mat-list-item *ngIf="ink2AssetId">
        <span mat-line i18n="End of year| ink2 document">Ink2 {{year}}</span>
        <red-asset-download-button class="report-download-btn" align="end" [assetId]="ink2AssetId">
            <span i18n="download report">Download</span>
        </red-asset-download-button>
    </mat-list-item>
</red-list>
