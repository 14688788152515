import {Component, Input} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {DIALOG_RESULT} from '@red/components';

@Component ({
    templateUrl: './delete.tpl.html'
})

export class InvoiceDeleteConfirmComponent {
    private _dialogRef: MatDialogRef<InvoiceDeleteConfirmComponent>;

    constructor (
        dialogRef: MatDialogRef<InvoiceDeleteConfirmComponent>
    ) {
        this._dialogRef = dialogRef;
    }

    cancel() {
        this._dialogRef.close(DIALOG_RESULT.CANCEL);
    }

    confirm() {
        this._dialogRef.close(DIALOG_RESULT.OK);
    }
}
