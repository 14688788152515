import {Component, HostBinding, OnInit} from '@angular/core';
import {OnboardingManager} from '../../managers/onboarding/onboarding-manager';
import { OnboardingListResponse } from '@red/onboarding-service-api';

@Component({
    selector: 'app-onboarding-alert',
    styleUrls: ['./onboarding-alert.sass'],
    templateUrl: 'onboarding-alert.tpl.html'
})

export class OnboardingAlertComponent implements OnInit {
    @HostBinding('class.has-onboarding') cssClass = false;

    private _hasOnboarding = false;
    private _onboardingManager: OnboardingManager;

    get hasOnboarding(): boolean { return this._hasOnboarding; }

    constructor(
        onboardingManager: OnboardingManager
    ) {
        this._onboardingManager = onboardingManager;
    }

    ngOnInit(): void {
        this._onboardingManager.hasOnboarding()
            .subscribe((listResp: OnboardingListResponse) => {
                this._hasOnboarding = !!listResp.data.length;
                this.cssClass = this._hasOnboarding;
            });
    }
}
