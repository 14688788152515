import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {DIALOG_RESULT} from '@red/components';

@Component ({
    templateUrl: './cantMarkAsDebt.tpl.html'
})

export class CardTransactionCantMarkAsDebtConfirmComponent {
    get minNeededAmount(): string {return this._minNeededAmount; }

    private _dialogRef: MatDialogRef<CardTransactionCantMarkAsDebtConfirmComponent>;
    private _minNeededAmount: string;

    constructor(
        dialogRef: MatDialogRef<CardTransactionCantMarkAsDebtConfirmComponent>,
        @Inject(MAT_DIALOG_DATA) data: any
    ) {
        this._dialogRef = dialogRef;
        this._minNeededAmount = data.minNeededAmount;
    }

    cancel() {
        this._dialogRef.close(DIALOG_RESULT.CANCEL);
    }
}
