import {Routes} from '@angular/router';
import {ReportViewComponent} from './report';
import {ValidSessionGuard} from '../../security/route/session';
import {ConsentGuard} from '../../security/route/consent';

export const REPORT_ROUTES: Routes = [
    {
        path: 'reports',
        component: ReportViewComponent,
        canActivate: [ValidSessionGuard, ConsentGuard]
    }
];
