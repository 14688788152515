import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RedFlagShutDownDialogComponent} from './red-flag-shut-down-dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {NoopAnimationsModule} from '@angular/platform-browser/animations';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {ReactiveFormsModule} from '@angular/forms';

@NgModule({
    declarations: [
        RedFlagShutDownDialogComponent
    ],
    exports: [
        RedFlagShutDownDialogComponent
    ],
    imports: [
        NoopAnimationsModule,
        CommonModule,
        MatIconModule,
        MatDialogModule,
        MatButtonModule,
        MatCheckboxModule,
        ReactiveFormsModule
    ]
})
export class RedFlagShutDownDialogModule {}
