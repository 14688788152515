export class ErrorResponse {
    get errorCode(): number { return this._errorCode; }
    get httpCode(): number { return this._httpCode; }
    get message(): string { return this._message; }
    get traceId(): string { return this._traceId; }

    private _errorCode: number;
    private _httpCode: number;
    private _message: string;
    private _traceId: string;

    constructor (
        message: string,
        httpCode?: number,
        errorCode?: number,
        traceId?: string
    ) {
        this._message = message;
        this._httpCode = httpCode;
        this._errorCode = errorCode;
        this._traceId = traceId;
    }
}
