<mat-expansion-panel class="mat-expansion-panel {{statusClassName}}">
    <mat-expansion-panel-header>
        <mat-panel-title>Företagsbeskrivning</mat-panel-title>
        <mat-panel-description></mat-panel-description>
    </mat-expansion-panel-header>
    <form [formGroup]="form" red-form>
        <fieldset>
            <section class="info-section">
                <div class="info-text main-info-text">
                    Beskriv vilken typ av verksamhet företaget ska ägna sig åt, beskrivningen får inte vara alltför allmän.
                    Det räcker till exempel inte att skriva att företaget ska bedriva konsultverksamhet utan man behöver specificera vilken typ av konsultverksamhet.
                </div>
                <app-read-more-less>
                    <span class="short-text">
                        <h4>Exempel:</h4>
                        <span class="italic">Bolaget ska bedriva verksamhet inom digital marknadsföring och webbproduktion samt därmed förenlig verksamhet.</span>
                    </span>
                    <div class="long-text">
                        <h4>Exempel:</h4>
                        <ol>
                            <li>
                                Bolaget ska bedriva verksamhet inom digital marknadsföring och webbproduktion samt därmed förenlig verksamhet.
                            </li>
                            <li>
                                Företagets primära verksamhet är att bedriva konsultverksamhet i form av projektledning riktad mot utvecklingsarbete
                                av konsumentprodukt och kommunikation för sport och friluftsliv.
                                Utöver den primära konsultverksamheten kommer företaget även bedriva utveckling, tillverkning och försäljning av egna produkter inom sport,
                                friluftsliv och heminredning samt därmed förenlig verksamhet.
                            </li>
                            <li>
                                Bolaget bedriver konsultverksamhet inom läkemedelsbranchen samt inom träning och hälsa.
                                Bolaget erbjuder fysisk träning till företag online eller på plats, samt därmed förenlig verksamhet.
                                Dessutom erbjuder bolaget konsultuthyrning inom apotek/läkemedelsbranschen som farmaceut,
                                försäljningschef, apotekschef, teamleader, produktspecialist, samt därmed förenlig verksamhet.
                            </li>
                        </ol>

                        <p>
                            Förkortningar som exempelvis bl.a. och m.m. får inte finnas med.
                        </p>

                        <p>
                            <a target="_blank" href="https://bolagsverket.se/ff/foretagsformer/namn/verksamhet-1.2576">Läs mer på bolagsverket.se</a>
                        </p>
                    </div>
                </app-read-more-less>
            </section>
            <div class="red-row">
                <mat-form-field>
                    <textarea
                        matInput
                        i18n-placeholder="Onboarding|Company description"
                        placeholder="Company description"
                        formControlName="companyDescription"
                        autocomplete="off"
                    ></textarea>
                </mat-form-field>
            </div>
        </fieldset>
        <div class="actions" *ngIf="!form.disabled">
            <button mat-flat-button color="primary" [disabled]="form.invalid" (click)="save()" i18n="Onboarding|Save button">Save</button>
            <button mat-flat-button color="primary" [disabled]="form.invalid" (click)="saveAndSend()" i18n="Onboarding|Save & Send button">Save & Send</button>
        </div>
    </form>
</mat-expansion-panel>
