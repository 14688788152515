import {Routes} from '@angular/router';
import {LOGIN_ROUTES} from './login/login.routes';
import {DASHBOARD_ROUTES} from './dashboard/dashboard.routes';
import {INVOICE_ROUTES} from './invoice/invoice.routes';
import {RECEIPT_ROUTES} from './receipt/receipt.routes';
import {CARD_TRANSACTION_ROUTES} from './card-transaction/card-transaction.routes';
import {SUPPORT_ROUTES} from './support/support.routes';
import {SETTINGS_ROUTES} from './settings/settings.routes';
import {ERROR_ROUTES} from './error/error.routes';
import {ROYALTY_STATEMENT_ROUTES} from './royalty-statement/royalty-statement.routes';
import {REPORT_ROUTES} from './report/report.routes';
import {DIVIDENDS_ROUTES} from './dividends/dividends.routes';
import { DOWNLOADABLE_REPORTS_ROUTES } from './downloadable-reports/downloadable-reports.routes';
import {RedFlagShutDownManager} from '../managers/red-flag-shut-down/red-flag-shut-down.manager';
import {OffboardingServiceClient} from '../lab/service-client/offboarding-service-client';

export let VIEW_ROUTES: Routes = [
    ...CARD_TRANSACTION_ROUTES,
    ...DASHBOARD_ROUTES,
    ...DIVIDENDS_ROUTES,
    ...DOWNLOADABLE_REPORTS_ROUTES,
    ...ERROR_ROUTES,
    ...REPORT_ROUTES,
    ...ROYALTY_STATEMENT_ROUTES,
    ...INVOICE_ROUTES,
    ...LOGIN_ROUTES,
    ...RECEIPT_ROUTES,
    ...SETTINGS_ROUTES,
    ...SUPPORT_ROUTES
];

const offboardingServiceClient = OffboardingServiceClient.create(null, null);
const redFlagShutDownManager = RedFlagShutDownManager.create(offboardingServiceClient);
const today = redFlagShutDownManager.today;

if (redFlagShutDownManager.readOnlyState(today)) {
    VIEW_ROUTES = redFlagShutDownManager.setRoutes(VIEW_ROUTES);
}
