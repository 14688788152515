<div [ngSwitch]="state">
    <red-skeleton-loader *ngSwitchCase="stateLoading" [rows]="2"></red-skeleton-loader>
    <div *ngSwitchCase="stateNoResult" class="no-result">
        <app-blank-slate-2020
            [typeClass]="'receipt'"
        >
            <header i18n="Receipt|blank slate|header">Receipt</header>
            <p i18n="Receipt|blank slate|info text">No receipts has been uploaded.</p>
        </app-blank-slate-2020>
    </div>
    <div *ngSwitchCase="stateDone">
        <red-info-box redInfoBoxList>
            <mat-icon svgIcon="ui-info-slate"></mat-icon>
            <red-markdown-content [markdownPath]="receiptInfoMarkdown"></red-markdown-content>
        </red-info-box>
        <red-list *ngIf="cardTransactions.hasResults && notReadOnly">
            <span redListHeader i18n="Receipt view|list|header|card transactions in receipt">Todo: upload receipts</span> <!-- Att göra: Ladda upp kvitton -->
            <mat-list-item *ngFor="let transaction of cardTransactions.items | async" redListLink redAssetUploadTrigger (fileSelect)="createNewExpense($event,transaction)">
                <red-list-icon mat-list-avatar [item]="transaction"></red-list-icon>
                <h4 mat-line>{{transaction.description}}</h4>
                <time mat-line>{{transaction.createdAt | moment }}</time>
                <app-display-amount-text *ngIf="transaction.total" [amount]="transaction.total" type="CARD_TRANSACTION" [direction]="transaction.direction"></app-display-amount-text>
                <mat-icon *ngIf="isDebit(transaction)" class="mark-as-debt-icon" svgIcon="ui-card-mark-debt" (click)="markAsDebt($event, transaction)"></mat-icon>
            </mat-list-item>
        </red-list>
        <button *ngIf="!cardTransactions.complete" mat-button (click)="loadMore(cardTransactions)" class="invoice-load-more-btn" i18n="Load more card transactions">Load more...</button>

        <red-list *ngIf="receiptsRejected.hasResults">
            <span redListHeader i18n="List header rejected receipts">Need actions</span>
            <mat-list-item *ngFor="let transaction of receiptsRejected.items | async" redListLink [routerLink]="[routerLink(transaction)]">
                <red-list-icon mat-list-avatar [item]="transaction"></red-list-icon>
                <h4 mat-line>{{transaction.description}}</h4>
                <time mat-line>{{transaction.createdAt | moment }}</time>
                <app-display-amount-text *ngIf="transaction.total" [amount]="transaction.total" [preliminaryAmount]="true"></app-display-amount-text>
            </mat-list-item>
        </red-list>
        <button *ngIf="!receiptsRejected.complete" mat-button (click)="loadMore(receiptsRejected)" class="invoice-load-more-btn" i18n="Load more receipts">Load more...</button>

        <red-list *ngIf="receiptsPending.hasResults">
            <span redListHeader i18n="List header for pending receipts">Pending</span>
            <div class="receipt-list-pending">
                <mat-list-item class="receipt-list-item-pending" *ngFor="let transaction of receiptsPending.items | async" [routerLink]="[routerLink(transaction)]">
                    <app-receipt-list-preview [receipt]="transaction"></app-receipt-list-preview>
                </mat-list-item>
            </div>
        </red-list>
        <button *ngIf="!receiptsPending.complete" mat-button (click)="loadMore(receiptsPending)" class="invoice-load-more-btn" i18n="Load more receipts">Load more...</button>

        <red-list *ngIf="receiptsBooked.hasResults">
            <span redListHeader i18n="List header for approved receipts">Done</span>
            <a redListHeaderLink [routerLink]="['/history']" [queryParams]="{ type: 'card_transaction,receipt' }" i18n="See all button link to history">SEE ALL</a>
            <mat-list-item *ngFor="let transaction of receiptsBooked.items | async" redListLink [routerLink]="[routerLink(transaction)]">
                <red-list-icon mat-list-avatar [item]="transaction"></red-list-icon>
                <h4 mat-line>{{ transaction.description}}</h4>
                <time mat-line>{{transaction.createdAt | moment }}</time>
                <app-display-amount-text *ngIf="transaction.total" [amount]="transaction.total"></app-display-amount-text>
            </mat-list-item>
        </red-list>
    </div>
</div>
<router-outlet></router-outlet>
