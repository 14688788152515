import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import {COMPANY_FEATURES} from '@red/data';
import {Store} from '@ngxs/store';
import {CompanyConfigurationManager} from '../../managers/company-configuration/company-configuration-manager';
import {AuthState} from '../../shared/state/auth/auth.state';

@Injectable()
export class EmployeeGuard implements CanActivate {
    private _router: Router;
    private _store: Store;
    private _companyConfigurationManager: CompanyConfigurationManager;

    constructor(
        router: Router,
        store: Store,
        companyConfigurationManager: CompanyConfigurationManager
    ) {
        this._router = router;
        this._store = store;
        this._companyConfigurationManager = companyConfigurationManager;
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const configuration = this._companyConfigurationManager.getConfigurationByCompanyId(this._store.selectSnapshot(AuthState.companyId));
        const employeeFeature = configuration && configuration.getFeature(COMPANY_FEATURES.EMPLOYEES);
        if (employeeFeature && employeeFeature.enabled) {
            return of(true);
        } else {
            this._router.navigate(['/dashboard']);
            return of(false);
        }
    }
}
