<div class="red-dialog-title" matDialogTitle>
    <button mat-button (click)="close()" tabindex="-1">
        <mat-icon svgIcon="ui-close-white"></mat-icon>
    </button>
    <span class="red-dialog-title-text">Red Flag flyttar till Bokio!</span>
</div>
<div class="red-dialog-content" matDialogContent>
    <p>
        Det närmar sig den
        <a href="http://support.redflag.se/sv/articles/5682391-nu-slar-vi-ihop-red-flag-bokio" target="_blank">
            1 december och flytt till Bokio för dig och alla våra Red Flag-kunder
        </a>.
    </p>
    <p>
        Den 1 december flyttar vi all din data till ditt nya Bokiokonto med
        <a href="https://www.bokio.se/prisplaner/detaljerad-prislista" target="_blank">Businessplan</a>.
        Vi startar upp kontot åt dig. Din bokföringsdata och dina pengar kommer att flyttas över till Bokio där pengarna hamnar på ett Bokio Företagskonto,
        liknande det du har i Red Flag. Du får dina inloggningsuppgifter av oss,
        sedan genomför du vår enkla onboardingprocess för att få tillgång till ditt konto och dina pengar.
    </p>

    <h3>Vad är viktigt att du gör och tänker på innan flytten till Bokio 1 december?</h3>
    <p>
        Vi har arbetat fram en lista kring allt som är viktigt att du gör och tänker på innan flytten till Bokio.
        Nedan kan du läsa mer om allt från bokföring, företagskonto, skatt, till kommande inbetalningar och mycket mer.
        <a href="http://support.redflag.se/sv/articles/5699035-vad-du-behover-tanka-pa-innan-flytten-till-bokio-den-1-december" target="_blank">Se hela listan här!</a>
    </p>

    <h3>Vill du få hjälp av en redovisningskonsult?</h3>
    <p>Vi har tagit fram ett specialerbjudande till dig som Red Flag-kund i samarbete med en av våra redovisningspartners, One Economy. Du har tidigare fått ett mejl med mer information och prisförslag.</p>
    <p>
        <a href="https://www.bokio.se/red-flag-erbjudande" target="_blank">Här kan du läsa mer om erbjudandet.</a>
    </p>
<!--    <p>-->
<!--        <a href="http://support.redflag.se/sv/articles/5650145-hej-da-red-flag-hej-bokio" target="_blank">Read all about it</a>-->
<!--    </p>-->
    <form [formGroup]="formGroup">
        <mat-checkbox formControlName="ok">Jag har läst och förstått</mat-checkbox>
    </form>
</div>
<mat-dialog-actions class="red-dialog-actions">
    <button type="button" mat-flat-button (click)="close()">Stäng</button>
</mat-dialog-actions>
