import {Component, HostBinding, OnInit} from '@angular/core';
import {Actions, ofActionSuccessful} from '@ngxs/store';
import {AppInfoNewVersionAvailable} from '../../shared/state/app-info/app-info.actions';

@Component({
    selector: 'app-new-version',
    styleUrls: ['./new-version.sass'],
    templateUrl: 'new-version.tpl.html'
})

export class NewVersionComponent implements OnInit {
    @HostBinding('class.new-version-open')
    get show(): boolean { return this._show; }
    get updateUrl(): string { return this._updateUrl; }

    private _updateUrl: string;
    private _show: boolean;
    private _actions: Actions;

    constructor (
        actions: Actions
    ) {
        this._actions = actions;
    }

    ngOnInit() {
        this._actions.pipe(
                ofActionSuccessful(AppInfoNewVersionAvailable)
            )
            .subscribe(() => {
                this._showNewVersionAvailable();
            });

        this._updateUrl = `/?r=` + Math.random();
    }

    private _showNewVersionAvailable() {
        if (window.location.hostname !== 'localhost') {
            this._show = true;
        }
    }
}
