import {BaseEvent} from '../event';
import {EventSource} from '../source';
import {Subject} from 'rxjs';
import {EventReader, OnEvent} from '../reader';
import {Injectable} from '@angular/core';

export class OwnerWithdrawalCreatedEvent extends BaseEvent {}

@Injectable()
export class FinancialMovementEventReader extends EventReader {
    private _onOwnerWithdrawalCreatedStream: Subject<OwnerWithdrawalCreatedEvent>;

    constructor (
        source: EventSource
    ) {
        super(source);

        this._onOwnerWithdrawalCreatedStream = new Subject<OwnerWithdrawalCreatedEvent>();
    }

    onCreated(fn: (evt: OwnerWithdrawalCreatedEvent) => void) {
        this._onOwnerWithdrawalCreatedStream.subscribe(fn);
    }

    @OnEvent(OwnerWithdrawalCreatedEvent)
    private _handleReceiptCreatedEvent(evt: OwnerWithdrawalCreatedEvent) {
        this._onOwnerWithdrawalCreatedStream.next(evt);
    }
}
