import {QueryFilter, WebServiceClient} from '@red/browser';
import {HttpFetch} from '@red/common';
import {
    Receipt,
    RECEIPT_STATUS,
    ReceiptBookkeepingUpdateRequest,
    ReceiptListResponse,
    ReceiptUpdateRequest
} from '@red/data';
import {Observable} from 'rxjs';
import {QueryParam} from '../../common/query-param';
import {AppConfig} from '../../config/appConfig';

export class ReceiptServiceClient extends WebServiceClient {
    static create(transport: HttpFetch, config: AppConfig) {
        return new ReceiptServiceClient(transport, config);
    }

    setStatus(receiptId: string, status: RECEIPT_STATUS): Observable<Receipt> {
        return this._put<Receipt>(`${this._getBaseUrl()}/api/receipt/${receiptId}/status/${status}`, null, {}, Receipt);
    }

    updateReceipt(receiptId: string, request: ReceiptUpdateRequest): Observable<Receipt> {
        return this._put<Receipt>(`${this._getBaseUrl()}/api/receipt/${receiptId}`, request, {}, Receipt);
    }

    updateReceiptBookkeepingHints(receiptId: string, request: ReceiptBookkeepingUpdateRequest): Observable<Receipt> {
        return this._put<Receipt>(`${this._getBaseUrl()}/api/receipt/${receiptId}/bookkeeping`, request, {}, Receipt);
    }

    revokeReceipt(receiptId: string, companyId: string): Observable<any> {
        return this._put(`${this._getBaseUrl()}/api/receipt/${receiptId}/company/${companyId}/revoke`, null, {});
    }

    getById(receiptId: string) {
        return this._get<Receipt>(`${this._getBaseUrl()}/api/receipt/${receiptId}`, {}, Receipt);
    }

    query(filter: QueryFilter) {
        return this._get<ReceiptListResponse>(`${this._getBaseUrl()}/api/receipt?${QueryParam.asString(filter)}`, {}, ReceiptListResponse);
    }

    create(data: FormData): Observable<Receipt> {
        const options = {
            headers: {'content-type': 'multipart/form-data'}
        };

        return this._post(`${this._getBaseUrl()}/api/receipt`, data, options, Receipt);
    }

    replaceAsset(receiptId: string, data: FormData): Observable<Receipt> {
        const options = {
            headers: {'content-type': 'multipart/form-data'}
        };

        return this._put<Receipt>(`${this._getBaseUrl()}/api/receipt/${receiptId}/asset`, data, options, Receipt);
    }

    delete(receiptId: string): Observable<Receipt> {
        return this._delete<Receipt>(`${this._getBaseUrl()}/api/receipt/${receiptId}`, {}, Receipt);
    }
}
