import {Component, OnDestroy, OnInit, QueryList, ViewChildren, ViewEncapsulation} from '@angular/core';
import {
    ONBOARDING_APPLICATION_STATUS,
    OnboardingApplication
} from '@red/onboarding-service-api';
import {Actions, ofActionSuccessful, Store} from '@ngxs/store';
import {
    OnboardingListApplications,
    OnboardingListApplicationsSuccessful,
    OnboardingUpdateApplicationError,
    OnboardingUpdateApplicationAndStatusSuccessful,
    OnboardingUpdateApplicationSuccessful, OnboardingCancelTrack
} from '../../../shared/state/onboarding/onboarding.actions';
import {Observable, Subscription} from 'rxjs';
import {delay, map, tap} from 'rxjs/operators';
import {AuthManager} from '../../../managers/auth/auth-manager';
import {Me} from '@red/data';
import {DIALOG_RESULT, RedDialog, RedNotification} from '@red/components';
import {OnboardingApplicationBaseDirective} from './onboarding-application-base';
import {OnboardingCancelTrackConfirmComponent} from '../onboarding-cancel-track-confirm/onboarding-cancel-track-confirm';
import {OnboardingState} from '../../../shared/state/onboarding/onboarding.state';
import {OnboardingManager} from '../../../managers/onboarding/onboarding-manager';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'app-onboarding-applications',
    styleUrls: ['./onboarding-applications.sass'],
    templateUrl: 'onboarding-applications.tpl.html'
})

export class OnboardingApplicationsComponent implements OnInit, OnDestroy {
    @ViewChildren(OnboardingApplicationBaseDirective) panels: QueryList<OnboardingApplicationBaseDirective>;

    get applications(): Observable<OnboardingApplication[]> { return this._applications; }
    get me(): Observable<Me> { return this._me; }
    get cancelEnabled$(): Observable<boolean> { return this._cancelEnabled$; }

    private _cancelEnabled$: Observable<boolean>;
    private _me: Observable<Me>;
    private _applications: Observable<OnboardingApplication[]>;
    private _actions: Actions;
    private _authManager: AuthManager;
    private _statusSubscription: Subscription;
    private _updateSubscription: Subscription;
    private _errorSubscription: Subscription;
    private _store: Store;
    private _notification: RedNotification;
    private _dialog: RedDialog;
    private _onboardingManager: OnboardingManager;

    constructor(
        actions: Actions,
        authManager: AuthManager,
        store: Store,
        notification: RedNotification,
        dialog: RedDialog,
        onboardingManager: OnboardingManager
    ) {
        this._authManager = authManager;
        this._actions = actions;
        this._store = store;
        this._notification = notification;
        this._dialog = dialog;
        this._onboardingManager = onboardingManager;
    }

    ngOnInit() {
        this._applications = this._actions
            .pipe(
                ofActionSuccessful(OnboardingListApplicationsSuccessful),
                map((action: OnboardingListApplicationsSuccessful) => action.payload.applications),
                tap(() => this._togglePanels()),
            );

        this._statusSubscription = this._actions
            .pipe(
                ofActionSuccessful(OnboardingUpdateApplicationAndStatusSuccessful),
                delay(500)
            )
            .subscribe((r) => {
                const msg = $localize`:RedNotofication|When a user send a onboarding application.:Onboarding was sent.`;
                this._notification.open(msg);
                this._store.dispatch(new OnboardingListApplications());
            });

        this._updateSubscription = this._actions
            .pipe(
                ofActionSuccessful(OnboardingUpdateApplicationSuccessful)
            )
            .subscribe(() => {
                const msg = $localize`:RedNotofication|When a user save a onboarding application.:Onboarding application was saved.`;
                this._notification.open(msg);
            });

        this._errorSubscription = this._actions
            .pipe(
                ofActionSuccessful(OnboardingUpdateApplicationError)
            )
            .subscribe((action: OnboardingUpdateApplicationError) => {
                const msg = $localize`:RedNotofication|When a user save a onboarding application and it fails.:Failed to save onboarding application.`;
                this._notification.errorWithCode(action.payload.error, msg);
            });

        this._me = this._authManager.me();
        this._store.dispatch(new OnboardingListApplications());

        this._cancelEnabled$ = this._store.select(OnboardingState.applicationStatusMap)
            .pipe(
                map((statusMap) => this._onboardingManager.canCancelTrack(this._store.selectSnapshot(OnboardingState.track), statusMap))
            );
    }

    ngOnDestroy(): void {
        if (this._statusSubscription) {
            this._statusSubscription.unsubscribe();
        }

        if (this._updateSubscription) {
            this._updateSubscription.unsubscribe();
        }

        if (this._errorSubscription) {
            this._errorSubscription.unsubscribe();
        }
    }

    trackByItems(index: number, application: OnboardingApplication): string {
        return application.id;
    }

    cancelTrack() {
        this._dialog.confirm(OnboardingCancelTrackConfirmComponent, {
            panelClass: 'cancel-track-confirm-dialog'
        })
            .afterClosed()
            .subscribe((result: DIALOG_RESULT) => {
                if (result === DIALOG_RESULT.OK) {
                    this._cancelTrack();
                }
            });
    }

    private _cancelTrack() {
        this._store.dispatch(new OnboardingCancelTrack());
    }

    private _togglePanels() {
        const draft = this.panels.filter(item => item.application.status === ONBOARDING_APPLICATION_STATUS.DRAFT).shift();

        if (draft) {
            draft.expansionPanel.open();
        }
    }
}
