import {of as observableOf} from 'rxjs';
import {catchError} from 'rxjs/operators';
import * as moment from 'moment';
import {Analytics} from './common/analytics/analytics';
import {appConfig} from '../assets/appConfig';
import {Store} from '@ngxs/store';
import {AuthState} from './shared/state/auth/auth.state';
import {AuthMe} from './shared/state/auth/auth.actions';
import {RedIconRegistry} from '@red/components';
import {AppConfigService, BaseConfig} from '@red/browser';

export class AppInit {
    static bootstrap(
        appConfigServiceExt: AppConfigService,
        iconRegistry: RedIconRegistry,
        locale: string,
        analytics: Analytics,
        store: Store
    ): () => Promise<BaseConfig> {
        const envConfig = '/assets/appConfig.env.json';

        return () => {
            return appConfigServiceExt
                .load(appConfig, envConfig)
                .then(config => AppInit._registerIcons(config, iconRegistry))
                .then(config => AppInit._setMomentLocale(config, locale))
                .then(config => AppInit._initAnalytics(config, analytics))
                .then(config => AppInit._checkSessionState(config, store))
                .then(config => AppInit._loadConsent(config));
        };
    }

    private static _checkSessionState(config: BaseConfig, store: Store): Promise<BaseConfig> {
        if (store.selectSnapshot(AuthState.sessionToken)) {
            return AppInit._loadUser(config, store);
        } else {
            return Promise.resolve(config);
        }
    }

    private static _loadConsent(config: BaseConfig): Promise<BaseConfig> {
        return observableOf(config).toPromise();
    }

    private static _loadUser(config: BaseConfig, store: Store): Promise<BaseConfig> {
        return new Promise<BaseConfig>(resolve => {
            store.dispatch(new AuthMe())
                .pipe(
                    catchError(err => observableOf(config))
                )
                .subscribe(() => {
                    resolve(config);
                });
        });
    }

    private static _registerIcons(config: BaseConfig, iconRegistry: RedIconRegistry): BaseConfig {
        if (config.get('icons')) {
            iconRegistry.registerIcons(config.get('icons'));
        }
        return config;
    }

    private static _setMomentLocale(config: BaseConfig, locale: string): BaseConfig {
        moment.locale(locale);
        return config;
    }

    private static _initAnalytics(config: BaseConfig, analytics: Analytics) {
        analytics.init();

        return config;
    }
}
