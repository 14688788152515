import {forkJoin, Observable, of, Subscription, throwError as observableThrowError} from 'rxjs';
import {delay, first, mergeMap, map, retryWhen} from 'rxjs/operators';
import {Component, ElementRef, HostBinding, Inject, LOCALE_ID, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {MatDatepicker} from '@angular/material/datepicker';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {
    Address,
    Amount,
    Company,
    COUNTRY,
    CountryData,
    CURRENCY,
    CurrencyListResponse,
    Customer,
    CUSTOMER_TYPE,
    CustomerListResponse,
    Invoice,
    INVOICE_DELIVERY_METHOD,
    INVOICE_STATUS,
    InvoiceItem,
    InvoiceListResponse,
    InvoicePayment, LocalCountry,
    REFERENCE_TYPE,
    VAT_TYPE
} from '@red/data';
import {DIALOG_RESULT, PostalCode, RedContextMenuComponent, RedDialog, RedNotification} from '@red/components';
import {InvoiceDeleteConfirmComponent} from '../confirm/delete/delete';
import {InvoiceWarningComponent} from '../confirm/warning/warning';
import {InvoiceManager} from '../manager';
import {InvoiceSendConfirmComponent} from '../confirm/send/send';
import {AppCapabilities} from '../../../common/appCapabilities';
import {AppConfig} from '../../../config/appConfig';
import {EventSource} from '../../../common/event/source';
import {Analytics, ANALYTICS_ACTION, ANALYTICS_CATEGORY} from '../../../common/analytics/analytics';
import {CustomerServiceClient} from '../../../lab/service-client/customer-service-client';
import {InvoiceServiceClient} from '../../../lab/service-client/invoice-service-client';
import {InvoiceAbandonConfirmComponent} from '../confirm/abandon/abandon';
import {AuthState} from '../../../shared/state/auth/auth.state';
import {Store} from '@ngxs/store';
import {QueryFilter} from '@red/browser';
import {FormMapper} from '../../../../tmp-red-web/src/ng/helpers/formMapper';
import * as moment from 'moment';
import {DirectoryServiceClient} from '../../../lab/service-client/directory-service-client';
import {ForexServiceClient} from '../../../lab/service-client/forex-service-client';
import {PaymentManager} from '../../../managers/payment/payment.manager';
import {Vat} from '@red/common';
import {EndOfYearManager} from '../../../managers/end-of-year/end-of-year.manager';
import {RedFlagShutDownManager} from '../../../managers/red-flag-shut-down/red-flag-shut-down.manager';

const VALIDATOR_FOR_INVOICE_ITEM_COUNT = Validators.pattern('[0-9.,]+$');

enum INVOICE_EDIT_STATE {
    EDIT,
    LOADING,
    SELECT_CUSTOMER,
    NO_REGISTRATION_GRANTED_DATE
}

@Component({
    selector: 'app-invoice-edit-view',
    styleUrls: ['./edit.sass'],
    templateUrl: './edit.tpl.html'
})
export class InvoiceEditComponent implements OnInit {
    @HostBinding('class.invoice-edit-view') cssClass = true;

    @ViewChild(RedContextMenuComponent) contextMenu: RedContextMenuComponent;
    @ViewChild('dialogContent') content: ElementRef;
    @ViewChild('invoiceForm') invoiceForm: ElementRef;
    @ViewChild('inputInvoiceDate') inputInvoiceDate: ElementRef;
    @ViewChild('inputInvoiceExpire') inputInvoiceExpire: ElementRef;
    @ViewChild('invoiceDatepicker') invoiceDatepicker: MatDatepicker<Date>;
    @ViewChild('invoiceExpireDatepicker') invoiceExpireDatepicker: MatDatepicker<Date>;

    get canSendInternationalInvoices(): boolean { return this._canSendInternationalInvoices; }

    get allowedCountries(): COUNTRY[] { return this._allowedCountries; }
    get countries(): CountryData[] { return this._countries; }
    get localCountries(): LocalCountry[] { return this._localCountries; }

    get creditedBy(): string { return this._invoiceManager.invoice.creditedBy; }
    get crediting(): string { return this._invoiceManager.invoice.crediting; }
    get currencies(): CurrencyListResponse { return this._currencies; }
    get customers(): Customer[] { return this._customers; }
    get disableContextMenuDelete(): boolean { return this._invoiceManager && this._invoiceManager.isNew; }
    get disableContextMenuSave(): boolean { return this._invoiceManager && this._editInvoiceForm && this._editInvoiceForm.pristine; }
    get dueAmount(): Amount { return this._dueAmount; }
    get editInvoiceForm(): FormGroup { return this._editInvoiceForm; }
    get firstTry(): boolean { return this._firstTry; }
    get hasCredit(): boolean { return typeof this._invoiceManager.invoice.creditedBy === 'string' && this._invoiceManager.invoice.creditedBy.length > 0; }
    get hasPayments(): boolean { return this._invoiceManager.hasPayments; }
    get hasPDF(): boolean { return !!this._invoiceManager.invoice.resourceUrl; }
    get hasInvoiceNumber(): boolean { return this._invoiceManager.hasInvoiceNumber; }
    get invoiceDownloadMarkdown(): string { return this._invoiceDownloadMarkdown; }
    get isCredit(): boolean { return this._invoiceManager.isCredit; }
    get isDebit(): boolean { return this._invoiceManager.isDebit; }
    get isDraft(): boolean { return this._invoiceManager.isDraft; }
    get isInReview(): boolean { return this._invoiceManager.isInReview; }
    get isFullPaid(): boolean { return this._invoiceManager.isFullPaid; }
    get isLoading(): boolean { return this._state === INVOICE_EDIT_STATE.LOADING; }
    get isInvoiceDisabled(): boolean { return this._isInvoiceDisabled(); }
    get isMobileDevice(): boolean { return this._isMobileDevice; }
    get isNew(): boolean { return this._invoiceManager.isNew; }
    get isNoCustomer(): boolean { return !this._invoiceManager.hasCustomer; }
    get isOverPaid(): boolean { return this._invoiceManager.isOverPaid; }
    get isPartiallyPaid(): boolean { return this._invoiceManager.isPartiallyPaid; }
    get isSentAsLetter(): boolean { return this._invoiceManager.isSentAsLetter; }
    get invoiceDate(): any { return moment(this._invoiceManager.invoice.date).toDate(); }
    get invoiceExpireDate(): any { return moment(this._invoiceManager.invoice.expires).toDate(); }
    get invoiceToReview(): boolean { return (this._invoiceManager.invoice.status === INVOICE_STATUS.COMPLETE) && (this._invoiceManager.invoice.customer.data.country === COUNTRY.CHINA || this._invoiceManager.invoice.customer.data.country === COUNTRY.ISRAEL || this._invoiceManager.invoice.customer.type === CUSTOMER_TYPE.INDIVIDUAL && this._invoiceManager.isInternationalInvoice); }
    // temporary solution while we put them in review for support
    get minDate(): string { return this._minDate; }
    get paymentDetails(): InvoicePayment[] { return this._invoiceManager.invoice.payments; }
    get assetId(): string { return this._invoiceManager.assetId; }
    get showActions(): boolean { return this._state === INVOICE_EDIT_STATE.EDIT; }
    get showContextMenu(): boolean { return this.showContextMenuDelete || this.showContextMenuSave || this.showContextMenuSaved || this.showContextMenuCredit || this.hasPDF; }
    get showContextMenuCredit(): boolean {
        return this._invoiceManager
            && (this._invoiceManager.invoice.status === INVOICE_STATUS.BOOKED && !this._invoiceManager.hasPayments)
            && this._notReadOnlyState;
    }
    get showContextMenuDelete(): boolean { return this._invoiceManager && this._invoiceManager.isDraft; }
    get showContextMenuSave(): boolean { return this._invoiceManager && this._editInvoiceForm && ((this._invoiceManager.isDraft && !this._editInvoiceForm.pristine) || this.isNew); }
    get showContextMenuSaved(): boolean { return this._invoiceManager && this._editInvoiceForm && this._invoiceManager.isDraft && !this.isNew && this._editInvoiceForm.pristine; }
    get showDownloadInfo(): boolean { return this._invoiceManager.isSentAsPDF && !this._invoiceManager.hasPayments; }
    get showPaymentInfo(): boolean { return this._invoiceManager.hasPayments; }
    get showTotalPaymentAmount(): boolean { return this._invoiceManager.hasPayments && (this._invoiceManager.hasMultiplePayments && this.isFullPaid || this._invoiceManager.isInternationalInvoice) && (this.isDebit && !this.hasCredit); }
    get state(): INVOICE_EDIT_STATE { return this._state; }
    get stateSelectCustomer(): INVOICE_EDIT_STATE { return INVOICE_EDIT_STATE.SELECT_CUSTOMER; }
    get stateLoading(): INVOICE_EDIT_STATE { return INVOICE_EDIT_STATE.LOADING; }
    get stateNoRegistrationGrantedDate(): INVOICE_EDIT_STATE { return INVOICE_EDIT_STATE.NO_REGISTRATION_GRANTED_DATE; }
    get stateIsStateNoRegistrationGrantedDate(): boolean { return this._state === INVOICE_EDIT_STATE.NO_REGISTRATION_GRANTED_DATE; }
    get terms(): number [] { return this._invoiceManager.terms; }
    get totalGross(): Amount { return this._invoiceManager.invoice.totalGross; }
    get today(): any { return this._today; }
    get vatList(): VAT_TYPE[] { return this._invoiceManager.vatList; }
    get invoicePartiallyPaidLink(): string { return this._invoicePartiallyPaidLink; }
    get customerAddressForm(): FormGroup { return this._customerAddressForm; }
    get minInvoiceDate(): any { return this._minInvoiceDate; }
    get minExpiresDate(): string { return this._minExpiresDate; }
    get notReadOnlyState(): boolean { return this._notReadOnlyState; }

    private get _companyId(): string { return this._store.selectSnapshot(AuthState.companyId); }

    private _analytics: Analytics;
    private _appConfig: AppConfig;
    private _allowedCountries: COUNTRY[];
    private _canSendInternationalInvoices: boolean;
    private _company: Company;
    private _countries: CountryData[];
    private _currencies: CurrencyListResponse;
    private _customers: Customer[] = [];
    private _customerServiceClient: CustomerServiceClient;
    private _dialog: RedDialog;
    private _dialogRef: MatDialogRef<InvoiceEditComponent>;
    private _directoryServiceClient: DirectoryServiceClient;
    private _dueAmount: Amount;
    private _eventSource: EventSource;
    private _forexServiceClient: ForexServiceClient;
    private _formMapper: FormMapper;
    private _formBuilder: FormBuilder;
    private _formSubscription: Subscription;
    private _addressFormSubscription: Subscription;
    private _editInvoiceForm: FormGroup;
    private _invoice: Invoice;
    private _invoiceDownloadMarkdown: string;
    private _invoiceManager: InvoiceManager;
    private _invoiceServiceClient: InvoiceServiceClient;
    private _invoiceSubscription: Subscription;
    private _isMobileDevice: boolean;
    private _isWaitingForPDF = false;
    private _lastInvoiceDate: any;
    private _localeId: string;
    private _minDate: string;
    private _router: Router;
    private _notification: RedNotification;
    private _firstTry = true;
    private _state: INVOICE_EDIT_STATE = INVOICE_EDIT_STATE.LOADING;
    private _store: Store;
    private _invoicePartiallyPaidLink = ``;
    private _customerAddressForm: FormGroup;
    private _minInvoiceDate: any;
    private _disableInvoiceDate: boolean;
    private _paymentManager: PaymentManager;
    private _minExpiresDate: string;
    private _today = moment().format('YYYY-MM-DD');
    private _endOfYearManager: EndOfYearManager;
    private _localCountries: LocalCountry[];
    private _redFlagShutDownManager: RedFlagShutDownManager;
    private _notReadOnlyState: boolean;

    constructor(
        analytics: Analytics,
        appConfig: AppConfig,
        customerServiceClient: CustomerServiceClient,
        dialog: RedDialog,
        dialogRef: MatDialogRef<InvoiceEditComponent>,
        directoryServiceClient: DirectoryServiceClient,
        eventSource: EventSource,
        forexServiceClient: ForexServiceClient,
        formMapper: FormMapper,
        invoiceServiceClient: InvoiceServiceClient,
        store: Store,
        router: Router,
        notification: RedNotification,
        formBuilder: FormBuilder,
        paymentManager: PaymentManager,
        endOfYearManager: EndOfYearManager,
        redFlagShutDownManager: RedFlagShutDownManager,
        @Inject(MAT_DIALOG_DATA) data: any,
        @Inject(LOCALE_ID) localeId: string
    ) {
        this._analytics = analytics;
        this._appConfig = appConfig;
        this._customerServiceClient = customerServiceClient;
        this._dialog = dialog;
        this._dialogRef = dialogRef;
        this._directoryServiceClient = directoryServiceClient;
        this._eventSource = eventSource;
        this._forexServiceClient = forexServiceClient;
        this._formMapper = formMapper;
        this._formBuilder = formBuilder;
        this._invoice = data && data.invoice;
        this._invoiceServiceClient = invoiceServiceClient;
        this._store = store;
        this._router = router;
        this._notification = notification;
        this._localeId = localeId;
        this._paymentManager = paymentManager;
        this._endOfYearManager = endOfYearManager;
        this._redFlagShutDownManager = redFlagShutDownManager;

        const ua = (window.navigator.userAgent || window.navigator.vendor || window['opera']);
        this._isMobileDevice = AppCapabilities.isMobileDevice(ua);

        this._canSendInternationalInvoices = true;

        this._invoiceDownloadMarkdown = this._appConfig.get(`content.invoice.download.${localeId}`);
    }

    ngOnInit(): void {

        const today = this._redFlagShutDownManager.today;
        this._notReadOnlyState = this._redFlagShutDownManager.notReadOnlyState(today);

        this._createOrPatchForms();

        this._invoiceManager = InvoiceManager.create(this._eventSource, this._invoice, this._invoiceServiceClient);
        this._invoicePartiallyPaidLink = this._invoiceManager.invoicePartiallyPaidLink;

        this._setCountryData();

        if (!this.isFullPaid) {
            const amount = Math.abs(this._invoiceManager.invoice.totalDue.amount);
            this._dueAmount = new Amount({'amount': amount, 'currency': this._invoiceManager.invoice.currency});
        }

        forkJoin([
            this._directoryServiceClient.getCompanyById(this._companyId),
            this._getInvoices(),
            this._getRecentCustomers(),
            this._forexServiceClient.currencies()
        ])
            .subscribe(([company, invoices, customers, currencies]: [Company, InvoiceListResponse, Customer[], CurrencyListResponse]) => {
                this._company = company;
                this._customers = customers;
                this._currencies = currencies;

                const lastInvoice = [].concat(invoices.data).shift();

                this._lastInvoiceDate = invoices.data.length > 0 ? lastInvoice.date : '';
                this._minInvoiceDate = this._company.details.registration.granted && this._getMinInvoiceDate();
                this._minExpiresDate = this._company.details.registration.granted && this._getMinExpiresDate();

                const fiscalEndDate = this._company.details.fiscalInformation.historicalFiscalYears.length
                    ? this._company.details.fiscalInformation.historicalFiscalYears[0].end
                    : this._company.details.fiscalInformation.currentFiscalYear.end;
                const filter = new QueryFilter().equal('fiscalEndDate', fiscalEndDate);

                this._endOfYearManager.dateIsInClosedPeriod(this._companyId, this._minInvoiceDate, filter)
                    .subscribe((dateIsInClosedPeriod: boolean) => {
                        if (dateIsInClosedPeriod) {
                            this._minInvoiceDate = this._company.details.fiscalInformation.currentFiscalYear.start;
                        }

                        this._createOrPatchForms();
                        this._setFormValidators();

                        this._disableInvoiceDate = this._minInvoiceDate && (this._minInvoiceDate === this._today);

                        if (this._disableInvoiceDate) {
                            this._editInvoiceForm.get('date').disable();
                        }

                        if (!this._invoiceManager.isSent) {
                            this._formSubscription = this._editInvoiceForm.valueChanges.subscribe(() => this._onFormChange());
                            this._addressFormSubscription = this._customerAddressForm.valueChanges.subscribe(() => this._onFormChange());
                        }

                        this._invoiceManager
                            .valueChanges
                            .subscribe((invoice: Invoice) => this._onInvoiceManagerChange(invoice));

                        this._checkForPDF();
                        this._disableFields();
                        this._hideLoader();
                    });


            }, () => {
                const msg = $localize`:RedNotification|Something wrong with company, need to contact support:Please contact support`;
                this._notification.open(msg);
            });
    }

    abandon() {
        this._dialog.confirm(InvoiceAbandonConfirmComponent)
            .afterClosed()
            .subscribe((result: DIALOG_RESULT) => {
                if (result === DIALOG_RESULT.OK) {
                    this._abandon();
                }
            });
    }

    calculatedTotalPaymentAmount(): Amount {
        const accumulator = 0; // acc === accumulator
        const total = this._invoiceManager.invoice.payments.reduce((acc: number, payment: InvoicePayment) => {
            return this._calculatedTotalPaymentAmount(acc, payment);
        }, accumulator);

        return new Amount({'amount': total, 'currency': CURRENCY.SEK});
    }

    close() {
        this._dialogClose(DIALOG_RESULT.OK);
    }

    deleteInvoice() {
        this._invoiceServiceClient.deleteInvoice(this._companyId, this._invoiceManager.invoice.id)
            .subscribe(() => {
                this._onDeleteSuccess();
                this._dialogClose(DIALOG_RESULT.OK);
            }, () => this._onDeleteError());
    }

    downloadPDF($event) {
        $event.stopPropagation();
        setTimeout(() => this.contextMenu.close(), 100);
    }

    getPercentageForVatType(vat: VAT_TYPE): number { return this._invoiceManager.getPercentageForVatType(vat); }

    getPercentageLabelForVatType(vat: VAT_TYPE): string {
        const percentage = this._invoiceManager.getPercentageForVatType(vat) * 100;

        return `${parseInt(percentage + '', 10)} %`;
    }

    getPaymentAmount(payment: InvoicePayment): Amount {
        return (payment.source.type === REFERENCE_TYPE.INVOICE)
            ? new Amount({'amount': -payment.amount.amount, 'currency': payment.amount.currency})
            : payment.amount;
    }

    hasFormErrors(): boolean {
        const editInVoiceFormInvalid = !this._editInvoiceForm || this._editInvoiceForm.invalid;
        const customerAddressFormInvalid = !this._customerAddressForm || this._customerAddressForm.invalid;

        return (editInVoiceFormInvalid || customerAddressFormInvalid) && !this._firstTry;
    }

    hasError(errorCode: string, path: string) { return errorCode.split(' ').some((err) => this._editInvoiceForm.hasError(err, [path])) && !this._firstTry; }

    hasOneError(err: string, path: string) {
        let hasError;

        switch (err) {
            case 'format':
                hasError = this.hasError(err, path);
                break;
            case 'noRegistration':
                hasError = this.hasError(err, path) && !this.hasError('format', path);
                break;
            case 'lastInvoiceDate':
                hasError = this.hasError(err, path) && !this.hasError('noRegistration', path) && !this.hasError('format', path);
                break;
            case 'registrationStartDate':
                hasError = this.hasError(err, path) && !this.hasError('noRegistration', path) && !this.hasError('lastInvoiceDate', path) && !this.hasError('format', path);
                break;
            default:
                break;
        }

        return hasError;

    }

    isAddressControlInvalid(index: number) { return (<FormArray>this._editInvoiceForm.get('customer.data.address.lines')).at(index).invalid && !this._firstTry; }

    isControlInvalid(path: string): boolean {
        return this._editInvoiceForm.get(path) && this._editInvoiceForm.get(path).invalid && !this._firstTry;
    }

    isFieldDisabledAndEmpty(path: string): boolean {
        const formControl = this._editInvoiceForm && this._editInvoiceForm.get(path);

        return !!(formControl && formControl.disabled && formControl.value === '');
    }

    isItemsControlValid(index: number, path: string): boolean {
        return (<FormArray>this._editInvoiceForm.get('items')).at(index).get(path).invalid && !this._firstTry;
    }

    shouldDisplayPayment(payment: InvoicePayment) {
        return !((this.isCredit || this.hasCredit) && payment.source.type === REFERENCE_TYPE.INVOICE);
    }

    isCorrection(payment: InvoicePayment): boolean {
        return (payment.source.type === REFERENCE_TYPE.INVOICE && payment.amount.amount > 0);
    }

    isWaitingForPDF(): boolean { return this._isWaitingForPDF; }

    navigateToInvoiceById(invoiceId: string) {
        this._navigateToInvoice(invoiceId);
        if (this._invoiceSubscription) {
            this._invoiceSubscription.unsubscribe();
        }
    }

    newLineItem() {
        const lineItemFormGroup = this._formBuilder.group({
            'item': this._formBuilder.group({
                'name': '',
                'description': '',
                'price': this._formBuilder.group({
                    'amount': null,
                    'currency': this._invoice.currency
                }),
                'VAT': 0.25,
                'unit': 'PIECE'
            }),
            'discount': 0,
            'count': null,
            'netPrice': this._formBuilder.group({
                'amount': 0,
                'currency': this._invoice.currency
            })
        });
        (<FormArray>this._editInvoiceForm.get('items')).push(lineItemFormGroup);

        this._firstTry = true;
        this._editInvoiceForm.markAsDirty();
        this._setInvoiceItemValidators();
    }

    onAddCustomer($event: Observable<Customer>) {
        this._showLoader();

        $event
            .subscribe((customer) => {
                this._updateCustomer(customer);
            }, (err) => {
                this._notification.open(err.message);
                this._state = INVOICE_EDIT_STATE.SELECT_CUSTOMER;
            });
    }

    onCustomerSelect($event: Customer) {
        const tempSub = this._customerAddressForm.get('countryCode')
            .valueChanges
            .subscribe(() => {
                const pc = this._customerAddressForm.get('postalCode');
                const cc = this._customerAddressForm.get('countryCode');
                const postalCodeIsValid = PostalCode.validate(pc.value, cc.value);

                if (postalCodeIsValid.error) {
                    pc.setErrors(postalCodeIsValid);
                    pc.markAsTouched();
                    pc.markAsDirty();

                    pc.updateValueAndValidity();
                }

                tempSub.unsubscribe();
            });

        this._updateCustomer($event);
        this._setCustomerTypeRelatedValidation($event);
    }

    onMenuCredit() {
        this._setMinDate();
        const title = $localize`:Credit invoice|Title for send invoice confirm dialog:Credit invoice`;
        const invoice = this._invoiceManager.invoice;
        const customer = invoice.customer.data;
        const part = invoice.totalDue.amount / invoice.totalGross.amount;
        const creditNet = (invoice.totalNet.amount * part).toFixed(2);
        const creditVat = (invoice.totalVAT.amount * part).toFixed(2);
        const amount = `- ${invoice.totalDue.amount} ${invoice.totalDue.currency}`;
        const netAmount = `- ${creditNet} ${invoice.totalNet.currency}`;
        const vat = `- ${creditVat} ${invoice.totalVAT.currency}`;

        this._dialog.confirm(InvoiceSendConfirmComponent, {
            'data': {
                'title': title,
                'amount': amount,
                'customerName': customer.name,
                'address': customer.address,
                'netAmount': netAmount,
                'vat': vat,
                'invoiceManager': this._invoiceManager,
                'isCreditInvoice': true,
                'minDate': this._minDate
            }
        })
            .afterClosed()
            .subscribe((result: any) => {
                if (result.result === DIALOG_RESULT.OK) {
                    this._showLoader();
                    this._invoiceManager.updateDeliveryMethod(result.deliveryMethod);
                    this._invoiceManager.updateInvoiceDate(result.date);
                    this._editInvoiceForm.markAsPristine();
                    this._credit();

                    if (this._invoiceSubscription) {
                        this._invoiceSubscription.unsubscribe();
                    }
                }
            });
    }

    onMenuDelete() {
        this._dialog.confirm(InvoiceDeleteConfirmComponent)
            .afterClosed()
            .subscribe((result: DIALOG_RESULT) => {
                if (result === DIALOG_RESULT.OK) {
                    this.deleteInvoice();
                }
            });
    }

    onMenuSave() {
        if (this._canSendForm()) {
            this._showLoader();
            this._save()
                .subscribe((invoice: Invoice) => {
                        this._onSaveSuccess();
                        if (this._router.url === '/invoice/create') {
                            this._navigateToInvoice(invoice.id);
                        }
                    },
                    (err) => {
                        this._onSaveError(err);
                        this._hideLoader();
                    },
                    () => this._hideLoader());
        }
    }

    openInvoiceDatepicker($event?: MouseEvent) {
        $event.preventDefault();
        this.invoiceDatepicker.open();
    }


    openInvoiceExpireDatepicker($event?: MouseEvent) {
        $event.preventDefault();
        this.invoiceExpireDatepicker.open();
    }

    removeLine(index) {
        this._invoiceManager.removeInvoiceItemAt(index);
        (<FormArray>this._editInvoiceForm.get('items')).removeAt(index);

        if (!this._invoiceManager.hasInvoiceItems) {
            this.newLineItem();
        }

        this._editInvoiceForm.markAsDirty();
        this._firstTry = true;
    }

    saveAndSend() {
        if (this._canSendForm()) {
            this._showLoader();

            const title = $localize`:Invoice|Title for send invoice alert dialog:Invoice`;
            const customer = this._invoiceManager.invoice.customer.data;
            const amount = `${this._invoiceManager.invoice.totalGross.amount} ${this._invoiceManager.invoice.totalGross.currency}`;
            const netAmount = `${this._invoiceManager.invoice.totalNet.amount} ${this._invoiceManager.invoice.totalNet.currency}`;
            const vat = `${this._invoiceManager.invoice.totalVAT.amount} ${this._invoiceManager.invoice.totalVAT.currency}`;

            this._save()
                .subscribe((invoice: Invoice) => {
                    this._invoiceManager.refreshInvoice(invoice);
                    this._hideLoader();

                    this._dialog.confirm(InvoiceSendConfirmComponent, {
                        'data': {
                            'amount': amount,
                            'customerName': customer.name,
                            'address': customer.address,
                            'netAmount': netAmount,
                            'vat': vat,
                            'invoiceManager': this._invoiceManager,
                            'title': title
                        }
                    })
                        .afterClosed()
                        .subscribe((result: any) => {
                            if (result.result === DIALOG_RESULT.OK) {
                                this._showLoader();
                                this._invoiceManager.updateDeliveryMethod(result.deliveryMethod);

                                this._save()
                                    .pipe(mergeMap(() => this._send()))
                                    .subscribe(
                                        () => {
                                            this._unsubscribeFormChanges();
                                            this._onSendSuccess();
                                        },
                                        () => this._onSendError(),
                                        () => this._hideLoader());
                            }
                        });
                }, (err) => {
                    this._onSaveError(err);
                    this._hideLoader();
                });

        }
    }

    showInvoiceForm($event) {
        $event.preventDefault();
        this._state = INVOICE_EDIT_STATE.EDIT;
    }

    showSelectCustomer($event) {
        $event.preventDefault();
        this._state = INVOICE_EDIT_STATE.SELECT_CUSTOMER;
    }

    updateConditions(days) {
        this._invoiceManager.setTerms(days);
    }

    updateLine(invoiceItem: InvoiceItem, index: number) {
        this._invoiceManager.updateInvoiceItemAt(invoiceItem, index);
    }

    private _getMinInvoiceDate() {
        const registrationGrantedDate = moment(this._company.details.registration.granted);
        const firstPossibleDayInTheory = this._company.details.fiscalInformation.historicalFiscalYears.length
            ? moment(this._today).startOf('month').subtract(1, 'month')
            : moment(this._company.details.fiscalInformation.currentFiscalYear.start);

        const firstPossibleDay = firstPossibleDayInTheory.isSameOrAfter(registrationGrantedDate)
            ? firstPossibleDayInTheory
            : registrationGrantedDate;

        const firstDayOfPreviousMonth = moment(this._today).startOf('month').subtract(1, 'day').startOf('month').format('YYYY-MM-DD');

        let invoiceMinDate = firstDayOfPreviousMonth;

        if (firstPossibleDay.isAfter(moment(firstDayOfPreviousMonth))) {
            invoiceMinDate = firstPossibleDay.format('YYYY-MM-DD');
        }

        if (this._lastInvoiceDate && moment(this._lastInvoiceDate).isBefore(moment(firstDayOfPreviousMonth))) {
            this._lastInvoiceDate = moment(firstDayOfPreviousMonth);
        } else if (this._lastInvoiceDate && moment(this._lastInvoiceDate).isBefore(moment(this._today).subtract(1, 'day'))) {
            this._lastInvoiceDate = moment(this._lastInvoiceDate).add(1, 'day').format('YYYY-MM-DD');
        }

        return this._lastInvoiceDate
            ? moment(this._lastInvoiceDate).format('YYYY-MM-DD')
            : invoiceMinDate;
    }

    private _getMinExpiresDate() {
        return moment(this._today).format('YYYY-MM-DD');
    }

    private _abandon() {
        this._showLoader();
        this._invoiceManager
            .abandon()
            .subscribe(
                () => this._onAbandonSuccess(),
                (err) => this._onAbandonError(err),
                () => this._hideLoader());
    }

    private _onAbandonError(err) {
        const msg = $localize`:RedNotification|A error occurred while a user tried to abandon the invoice.:Failed to abandon invoice`;
        this._notification.errorWithCode(err, msg);
    }

    private _onAbandonSuccess() {
        const msg = $localize`:RedNotification|A user has abandoned a invoice.:Invoice has been abandoned`;
        this._notification.open(msg);
    }

    private _isInvoiceDisabled(): boolean {
        return !!(
            this._editInvoiceForm
            && this._controlExistsAndIsDisabled(`customer`)
            && this._controlExistsAndIsDisabled(`date`)
            && this._controlExistsAndIsDisabled(`expires`)
            && this._controlExistsAndIsDisabled(`items`)
        );
    }

    private _controlExistsAndIsDisabled(controlName: string): boolean {
        return this._editInvoiceForm.get(controlName)
            && this._editInvoiceForm.get(controlName).disabled;
    }

    private _getPaymentAmount(payment: InvoicePayment): number {
        return this._invoiceManager.isInternationalInvoice && payment.forexAmount
            ? payment.forexAmount.counterValue.amount
            : payment.amount.amount;
    }

    private _getNegativeOrPositiveAmountBasedOnReferenceType(payment: InvoicePayment): number {
        const itemAmount = this._getPaymentAmount(payment);

        return (payment.source.type === REFERENCE_TYPE.INVOICE)
            ? -itemAmount
            : itemAmount;
    }

    private _calculatedTotalPaymentAmount(acc: number, payment: InvoicePayment) {
        const amount = this._getNegativeOrPositiveAmountBasedOnReferenceType(payment);

        return acc + amount;
    }

    private _updateCustomer(customer: Customer) {
        this._invoiceManager.updateCustomer(customer);
        this.content.nativeElement.scrollTop = 0;
        this._hideLoader();
    }

    private _canSendForm(): boolean {
        this._firstTry = false;
        return (
            this._editInvoiceForm.valid
            && (this._customerAddressForm.valid || this.customerAddressForm.disabled)
        );
    }

    private _checkForPDF() {
        if (this._invoiceManager.hasPDFInvoice || (this._invoiceManager.invoice.status !== INVOICE_STATUS.BOOKED && this._invoiceManager.invoice.status !== INVOICE_STATUS.COMPLETE)) { return;
        }
        this._isWaitingForPDF = true;
        this._invoiceSubscription = this._pollInvoice()
            .subscribe((resp: Invoice) => {
                this._invoiceManager.refreshInvoice(resp, true);
                this._disableFields();
                this._editInvoiceForm.markAsPristine();
                this._isWaitingForPDF = false;
            });
    }

    private _amountToFormGroup(amount: Amount): FormGroup {
        const amt = amount || Amount.SEK(0);

        return this._formBuilder.group({
            amount: [amt.amount],
            currency: [amt.currency]
        });
    }

    private _getCurrentInvoice(): Invoice {
        return this._invoiceManager && this._invoiceManager.invoice || {
            customer: {
                data: {
                    name: '',
                    country: COUNTRY.SWEDEN,
                    address: {
                        careOf: '',
                        lines: [''],
                        postalCode: '',
                        postalTown: '',
                        countryCode: COUNTRY.SWEDEN
                    } as Address
                }
            },
            currency: CURRENCY.SEK,
            buyerContact: null,
            date: null,
            expires: null,
            number: null,
            reference: null,
            totalNet: null,
            totalVAT: null,
            items: [
                {
                    count: null,
                    netPrice: null,
                    item: {
                        name: null,
                        price: null,
                        unit: 'PIECE',
                        VAT: 0.25
                    }
                }
            ],
            status: INVOICE_STATUS.NEW
        } as Invoice;
    }

    private _createOrPatchForms() {
        const invoice = this._getCurrentInvoice();

        this._setPatchEditInvoiceForm(invoice);
        this._setPatchCustomerAddressForm(invoice.customer.data.address, invoice.status);

        if (this._company && !this._company.details.registration.granted) {
            this._editInvoiceForm.disable();
            this._customerAddressForm.disable();
        }
    }

    private _invoiceItemFormGroup(invoiceItem: InvoiceItem): FormGroup {
        return this._formBuilder.group({
            count: [invoiceItem.count],
            item: this._formBuilder.group({
                name: [invoiceItem.item.name],
                price: this._amountToFormGroup(invoiceItem.item.price),
                unit: ['PIECE'],
                VAT: [invoiceItem.item.VAT],
            }),
            netPrice: this._amountToFormGroup(invoiceItem.netPrice),
        });
    }

    private _itemsToFormArray(invoiceItems: InvoiceItem[]): FormArray {
        const items = this._formBuilder.array([]);

        (invoiceItems || [new InvoiceItem()]).forEach((invoiceItem: InvoiceItem) => {
            items.push(this._invoiceItemFormGroup(invoiceItem));
        });

        return items;
    }

    private _createEditInvoiceForm(invoice: Invoice) {
        this._editInvoiceForm = this._formBuilder.group({
            customer: this._formBuilder.group({
                data: this._formBuilder.group({
                    name: [invoice.customer.data.name, Validators.required],
                    country: [invoice.customer.data.country, Validators.required]
                })
            }),
            currency: [invoice.currency],
            buyerContact: [invoice.buyerContact, Validators.required],
            date: [invoice.date],
            expires: [invoice.expires, Validators.required],
            number: [invoice.number],
            reference: [invoice.reference],
            items: this._itemsToFormArray(invoice.items),
            totalNet: [this._amountToFormGroup(invoice.totalNet)],
            totalVAT: [this._amountToFormGroup(invoice.totalVAT)]
        });

        this._editInvoiceForm.get(`date`)
            .valueChanges
            .subscribe((value) => {
                this._minExpiresDate = moment(value).startOf('day').toISOString(true);
            });
    }

    private _setPatchEditInvoiceForm(invoice: Invoice) {
        if (!this._editInvoiceForm) {
            this._createEditInvoiceForm(invoice);
        } else {
            this._editInvoiceForm.patchValue(JSON.parse(JSON.stringify(invoice)));

            invoice.items.forEach((invoiceItem: InvoiceItem, index: number) => {
                if (!(this._editInvoiceForm.get('items') as FormArray).at(index)) {
                    (this._editInvoiceForm.get('items') as FormArray).push(this._invoiceItemFormGroup(invoiceItem));
                }
            });
        }

        this._editInvoiceForm.markAsPristine();
    }

    private _createAddressForm(address: Address = new Address()): void {
        if (!address.lines || !address.lines.length) {
            address.lines = [''];
        }

        const addressForm = this._formBuilder.group({
            careOf: [address.careOf || ''],
            lines: this._formBuilder.array(address.lines),
            postalCode: [address.postalCode, Validators.required],
            postalTown: [address.postalTown, Validators.required],
            countryCode: [address.countryCode, Validators.required]
        });

        addressForm.get('countryCode').disable();
        this._customerAddressForm = addressForm;

        const customer = this._invoice && this._invoice.customer;
        this._setCustomerTypeRelatedValidation(customer);
    }

    private _setPatchCustomerAddressForm(address: Address = new Address(), status: INVOICE_STATUS): void {
        if (!this._customerAddressForm) {
            this._createAddressForm(address);
        } else {
            this._customerAddressForm.patchValue(address);
        }

        if (status !== INVOICE_STATUS.NEW && status !== INVOICE_STATUS.DRAFT) {
            this._customerAddressForm.disable();
        }

        this._customerAddressForm.markAsPristine();
    }

    private _credit() {
        this._invoiceManager.credit(this._companyId)
            .subscribe((invoice: Invoice) => {
                this._onCreditSuccess();
                if (this._invoiceManager.isSentAsPDF) {
                    this._navigateToInvoice(invoice.id);
                } else {
                    this._dialogClose(DIALOG_RESULT.OK);
                }
            }, () => {
                this._hideLoader();
                this._onCreditError();
            });
    }

    private _dateValidator(control: AbstractControl) {
        const currentValue = control.value;

        if (!moment(currentValue, 'YYYY-MM-DD', true).isValid()) {
            return {format: true};
        }

        return null;
    }

    private _dialogClose(action: DIALOG_RESULT) {
        if (this._invoiceSubscription) {
            this._invoiceSubscription.unsubscribe();
        }

        if (this._editInvoiceForm.dirty || this._customerAddressForm.dirty) {
            this._dialog.confirm(InvoiceWarningComponent)
                .afterClosed()
                .subscribe(result => {
                    this._firstTry = false;

                    this._editInvoiceForm.markAsPristine();
                    this._customerAddressForm.markAsPristine();

                    this._onWarningDialogResults(result);
                });
        } else {
            this._dialogRef.close(action);
        }
    }

    private _disableFields() {
        switch (this._invoiceManager.invoice.status) {
            case INVOICE_STATUS.NEW:
            case INVOICE_STATUS.DRAFT:
                this._editInvoiceForm.get('customer.data.name').disable();
                this._editInvoiceForm.get('customer.data.country').disable();
                this._editInvoiceForm.get('currency').disable();
                break;
            case INVOICE_STATUS.COMPLETE:
            case INVOICE_STATUS.IN_REVIEW:
            case INVOICE_STATUS.BOOKED:
                this._editInvoiceForm.get('customer').disable();
                this._editInvoiceForm.get('date').disable();
                this._editInvoiceForm.get('expires').disable();
                this._editInvoiceForm.get('items').disable();
                this._editInvoiceForm.get('number').disable();
                this._editInvoiceForm.get('reference').disable();
                this._editInvoiceForm.get('buyerContact').disable();
                this._editInvoiceForm.get('currency').disable();
                break;
        }
    }

    private _getInvoices(): Observable<InvoiceListResponse> {
        const filter = new QueryFilter()
            .equal('status', [INVOICE_STATUS.COMPLETE, INVOICE_STATUS.IN_REVIEW, INVOICE_STATUS.BOOKED])
            .length(500);

        return this._invoiceServiceClient.listInvoices(this._companyId, filter);
    }

    private _getRecentCustomers(): Observable<Customer[]> {
        const filter = new QueryFilter()
            .offset(0)
            .length(10000);

        return this._customerServiceClient.listCustomers(this._companyId, filter)
            .pipe(map((resp: CustomerListResponse) => resp.data.reverse()));
    }

    private _hideLoader() {
        this._state = (this._company && !this._company.details.registration.granted)
            ? INVOICE_EDIT_STATE.NO_REGISTRATION_GRANTED_DATE
            : INVOICE_EDIT_STATE.EDIT;
    }

    private _navigateToInvoice(invoiceId: string) {
        this._dialogRef
            .afterClosed().pipe(
            first())
            .subscribe(() => {
                this._router.navigate(['invoice', invoiceId, 'edit']);
            });

        this._dialogClose(DIALOG_RESULT.CANCEL);
    }

    private _onCreditError() {
        const msg = $localize`:RedNotification|A error occurred while a user tried to credit an invoice.:Failed to credit invoice`;
        this._notification.open(msg);
    }

    private _onCreditSuccess() {
        const msg = $localize`:RedNotification|A user has credited a invoice:Invoice has been credited`;
        this._notification.open(msg);
        this._analytics.track(ANALYTICS_CATEGORY.INVOICE, ANALYTICS_ACTION.CREDIT);
    }

    private _onDeleteError() {
        const msg = $localize`:RedNotification|A user failed to deleted a invoice.:Failed to deleted invoice`;
        this._notification.open(msg);
    }

    private _onDeleteSuccess() {
        const msg = $localize`:RedNotification|A user has deleted a invoice.:Invoice has been deleted`;
        this._notification.open(msg);
        this._analytics.track(ANALYTICS_CATEGORY.INVOICE, ANALYTICS_ACTION.DELETE);
    }

    private _onFormChange() {
        const editInvoiceFormValue = this._editInvoiceForm.getRawValue();
        const addressFormValue = this._customerAddressForm.getRawValue();

        editInvoiceFormValue.customer.data.address = addressFormValue;

        if (!editInvoiceFormValue.deliveryAddress) {
            editInvoiceFormValue.deliveryAddress = {};
        }

        editInvoiceFormValue.deliveryAddress.address = addressFormValue;
        this._invoiceManager.refreshInvoice(editInvoiceFormValue);
    }

    private _onInvoiceManagerChange(invoice: Invoice) {
        this._invoice = invoice;
        const rawInvoice = JSON.parse(JSON.stringify(invoice));

        this._editInvoiceForm.patchValue(rawInvoice, {onlySelf: true, emitEvent: false});
        this._customerAddressForm.patchValue(rawInvoice.customer.data.address, {onlySelf: true, emitEvent: false});

        this._setPatchCustomerAddressForm(rawInvoice.customer.data.address, invoice.status);

        this._editInvoiceForm.markAsDirty();
    }

    private _onSaveError(err) {
        const msg = $localize`:RedNotification|A error occured while a user tried to save the invoice.:Failed to save invoice`;
        this._notification.errorWithCode(err, msg);
    }

    private _onSaveSuccess() {
        const msg = $localize`:RedNotification|A user has saved a invoice.:Invoice has been saved`;
        this._notification.open(msg);
        this._analytics.track(ANALYTICS_CATEGORY.INVOICE, ANALYTICS_ACTION.SAVE);
    }

    private _onSendError() {
        const msg = $localize`:RedNotification|A error occured while a user tried to save the invoice.:Failed to send your invoice`;
        this._notification.open(msg);
    }

    private _getSEndSuccessMessage() {
        switch (this._invoiceManager.invoice.deliveryOption.method) {
            case INVOICE_DELIVERY_METHOD.LETTER:
                return $localize`:RedNotification|A sent a invoice.:Sent`;
            case INVOICE_DELIVERY_METHOD.DOWNLOAD:
                return $localize`:RedNotification|User choose to download invoice.:Invoice has been created`;
            default:
                return;
        }
    }

    private _onSendSuccess() {
        const msg = this._getSEndSuccessMessage();

        this._notification.open(msg);
        this._analytics.track(ANALYTICS_CATEGORY.INVOICE, ANALYTICS_ACTION.SEND);

        if (this._invoiceManager.isSentAsLetter) {
            this._dialogClose(DIALOG_RESULT.OK);
        } else {
            if (this._router.url === '/invoice/create') {
                this._navigateToInvoice(this._invoiceManager.invoice.id);
            } else {
                this._checkForPDF();
                this._disableFields();
            }
        }
    }

    private _onWarningDialogResults(result) {
        switch (result) {
            case DIALOG_RESULT.OK:
                if (this._editInvoiceForm.valid && this._customerAddressForm.valid) {
                    this._saveAndClose();
                } else {
                    const msg = $localize`:RedNotification|Invoice.:The invoice could not be saved`;
                    this._notification.open(msg);
                }
                break;
            case DIALOG_RESULT.REJECT:
                this._dialogRef.close(DIALOG_RESULT.REJECT);
                this._router.navigate( ['invoice']);
                break;
            default:
                break;
        }
    }

    private _pollInvoice(): Observable<Invoice> {
        let attempts = 0;
        return this._invoiceServiceClient.getInvoice(this._invoiceManager.invoice.id).pipe(
            mergeMap((response: Invoice) => {
                if (!response.resourceUrl) { return observableThrowError(response);
                }
                return of(response);
            }),
            retryWhen<Invoice>(errors => {
                return errors.pipe(
                    mergeMap(() => {
                        attempts += 1;
                        if (attempts === 59) {
                            const msg = $localize`:RedNotification|An error occurred while creating PDF:Failed to create PDF`;
                            this._notification.open(msg);
                            return observableThrowError(`Failed to render PDF for invoice ${this._invoiceManager.invoice.id}`);
                        }
                        return of(attempts);
                    }),
                    delay(1000)
                );
            })
        );
    }

    private _save(): Observable<Invoice> {
        return this._customerServiceClient.getCustomer(this._companyId, this._invoiceManager.invoice.customer.id)
            .pipe(
                mergeMap((customer: Customer) => {

                    const editForm = this._editInvoiceForm.getRawValue();
                    const address = this._customerAddressForm.getRawValue();

                    const cstmr = JSON.parse(JSON.stringify(this._invoiceManager.invoice)).customer;
                    cstmr.data.address = address;
                    cstmr.defaults.deliveryAddress = {
                        address: address
                    };
                    cstmr.defaults.buyerContact = editForm.buyerContact;
                    cstmr.vatArea = Vat.vatArea(COUNTRY.SWEDEN, customer.data.vatIdentifier || customer.data.country.toString());

                    const newCustomer = new Customer(customer);
                    newCustomer.populate(cstmr);

                    return this._customerServiceClient.updateCustomer(this._companyId, newCustomer.id, newCustomer);
                }),
                mergeMap(() => this._invoiceManager.saveOrUpdate(this._companyId)),
                map((invoice: Invoice) => {
                    this._editInvoiceForm.markAsPristine();
                    return invoice;
                })
            );
    }

    private _saveAndClose() {
        this._save()
            .subscribe(() => {
                this._onSaveSuccess();
                this._dialogClose(DIALOG_RESULT.OK);
            }, (err) => {
                this._onSaveError(err);
            });
    }

    private _send() { return this._invoiceManager.send(this._companyId); }

    private _setFormValidators() {
        if (this.isDebit) {
            this._setInvoiceItemValidators();
        }

        this._setInvoiceDateValidator();
    }

    private _setInvoiceDateValidator() {
        this._editInvoiceForm.get('date').setValidators([Validators.required,
            (ctrl: AbstractControl) => this._dateValidator(ctrl),
            (_: AbstractControl) => {
                return !this._company.registration.granted ? {noRegistration: true} : null;
            },
            (ctrl: AbstractControl) => {
                return moment(ctrl.value).isBefore(this._company.registration.granted) ? {registrationStartDate: true} : null;
            },
            (ctrl: AbstractControl) => {
                return moment(ctrl.value).isBefore(this._lastInvoiceDate) ? {lastInvoiceDate: true} : null;
            }]);
    }

    private _setMinDate() {
        this._minDate = moment(this._company.registration.granted).isSameOrBefore(this._lastInvoiceDate)
            ? moment(this._lastInvoiceDate).format('YYYY-MM-DD')
            : moment(this._company.registration.granted).format('YYYY-MM-DD');
    }

    private _setInvoiceItemValidators() {
        const items = <FormArray>this._editInvoiceForm.get('items');
        let ctrl, i;

        for (i = 0; i < items.length; i += 1) {
            ctrl = items.at(i);
            ctrl.get('count').setValidators([Validators.required, VALIDATOR_FOR_INVOICE_ITEM_COUNT]);
            ctrl.get('item.name').setValidators([Validators.required]);
            ctrl.get('item.price.amount').setValidators([Validators.required]);
        }
    }

    private _setCountryData() {
        this._localCountries = CountryData.getLocalCountriesForLocaleId(this._localeId);

        this._paymentManager.getAllowedCountries()
            .subscribe((countries: COUNTRY[]) => {
                this._allowedCountries = countries;

                this._countries = CountryData.getLocalCountriesForLocaleId(this._localeId)
                    .filter((localCountry: LocalCountry) => {
                        return this._allowedCountries.includes(localCountry.code as COUNTRY);
                    });
            });
    }

    private _showLoader() {
        this._state = INVOICE_EDIT_STATE.LOADING;
    }

    private _unsubscribeFormChanges() {
        this._formSubscription.unsubscribe();
        this._addressFormSubscription.unsubscribe();
    }

    private _setCustomerTypeRelatedValidation($event: Customer) {
        const customer = $event && JSON.parse(JSON.stringify($event));

        if (customer && customer.type === CUSTOMER_TYPE.INDIVIDUAL) {
            (this._customerAddressForm.get('lines') as FormArray).at(0).setValidators(Validators.required);
        } else {
            (this._customerAddressForm.get('lines') as FormArray).at(0).setValidators(null);
        }
    }
}
