import {Observable} from 'rxjs';
import {Shareholder, ShareholderListResponse, Stock, UpdateShareholdersRequest} from '@red/data';
import {map} from 'rxjs/operators';
import * as moment from 'moment';
import {QueryFilter} from '@red/browser';
import {ShareholderServiceClient} from '../../lab/service-client/shareholder-service-client';

export class ShareholderManager {
    private _shareholderServiceClient: ShareholderServiceClient;

    constructor (
        shareholderServiceClient: ShareholderServiceClient
    ) {
        this._shareholderServiceClient = shareholderServiceClient;
    }

    static create(shareholderServiceClient: ShareholderServiceClient) {
        return new ShareholderManager(shareholderServiceClient);
    }

    getShareholders(companyId: string): Observable<ShareholderListResponse> {
        return this._shareholderServiceClient.getShareholders(companyId)
            .pipe(map((shareholderListResponse: ShareholderListResponse) => {
                return shareholderListResponse;
            }));
    }

    addShareholder(shareholder: Shareholder): Observable<Shareholder> {
        return this._shareholderServiceClient.addShareholder(shareholder)
            .pipe(map((newShareholder: Shareholder) => newShareholder));
    }

    updateShareholders(updateShareholdersRequest: UpdateShareholdersRequest): Observable<ShareholderListResponse> {
        return this._shareholderServiceClient.updateShareholders(updateShareholdersRequest)
            .pipe(map((shareholderListResponse: ShareholderListResponse) => shareholderListResponse));
    }

    getStock(companyId: string, date?: string): Observable<Stock> {
        const filter = this._getStockFilter(date);

        return this._shareholderServiceClient.getStock(companyId, filter)
            .pipe(map((stock: Stock) => stock));
    }

    addStock(stock: Stock): Observable<Stock> {
        return this._shareholderServiceClient.addStock(stock)
            .pipe(map((addedStock: Stock) => addedStock));
    }

    private _getCompanyIdFromSharholdersArray(shareholders: Shareholder[]): string {
        return shareholders
            .map(shareholder => shareholder.companyId)
            .filter(companyId => !!companyId)
            .shift();
    }

    private _getStockFilter(date?: string): QueryFilter {
        const today = moment().format('YYYY-MM-DD');

        return new QueryFilter({
            date: date || today
        });
    }
}
