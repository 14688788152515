<div class="red-dialog-title" matDialogTitle>
    <button mat-button (click)="close()" tabindex="-1">
        <mat-icon svgIcon="ui-close-white"></mat-icon>
    </button>
    <span class="red-dialog-title-text" i18n="Dialog title|add new shareholder">New Shareholder</span>
</div>
<div class="red-dialog-content" matDialogContent>
    <app-user-admin-form
        [userRole]="userRole"
        (emitSubmit)="addUserForShareholder($event)"
        #shareholderForm
    ></app-user-admin-form>
</div>
<mat-dialog-actions class="red-dialog-actions">
    <button
        mat-flat-button
        tabindex="-1"
        [disabled]="shareholderForm.hasFormErrors()"
        i18n="Save"
        (click)="shareholderForm.submit($event)"
    >Save</button>
</mat-dialog-actions>

