<div mat-dialog-title class="end-of-year-confirm-dialog-message">
    <ng-container *ngIf="isMoveToCustomerDone">
        <p i18n="End Of Year|confirm|move to customer done">I'm done with {{year}}</p>
    </ng-container>
    <ng-container *ngIf="isBankIdConfirm">
        <ng-container *ngIf="isSignPreliminary">
            <p i18n="End Of Year|Confirm bankId|sign preliminary|dialog text" *ngIf="isSoleTrader">Confirm preliminary BankId dialog</p>
            <p i18n="End Of Year|Confirm bankId|llc|sign preliminary|dialog text" *ngIf="isLLC">LLC: Confirm preliminary BankId dialog</p>
        </ng-container>
        <ng-container *ngIf="isSignFinal">
            <p i18n="End Of Year|Confirm bankId|sign final|dialog text" *ngIf="isSoleTrader">Confirm final BankId dialog</p>
            <p i18n="End Of Year|Confirm bankId|sign final|llc|dialog text" *ngIf="isLLC">LLC: Confirm final BankId dialog</p>
        </ng-container>
        <ng-container *ngIf="isGoToSendToBolagsverket">
            <p i18n="End Of Year|confirm bankId|Send to bolagsverket">Confirm send to bolagsverket</p>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="isUserHelp">
        <p i18n="End Of Year| close the books help text">Close the books help text</p>
    </ng-container>
</div>
<div mat-dialog-content class="end-of-year-confirm-dialog-warning-text" *ngIf="showWarning" i18n="End of Year|confirm|warning text">
    You didn't fill information about paid taxes!
</div>
<mat-dialog-actions class="button-wrapper">
    <ng-container *ngIf="isMoveToCustomerDone">
        <button mat-button class="mat-flat-button cancel" i18n="cancel" (click)="close()">Cancel</button>
        <button mat-button class="mat-flat-button right action" i18n="Ready" (click)="moveToCustomerDone()">Ready</button>
    </ng-container>
    <ng-container *ngIf="isBankIdConfirm">
        <button mat-button class="mat-flat-button cancel" i18n="cancel" (click)="cancelBankId()">Cancel</button>
        <button mat-button class="mat-flat-button right bank-id-button" (click)="openBankId()" i18n="End of year|Button|Open bankId">Open BankId</button>
    </ng-container>
    <ng-container *ngIf="isUserHelp">
        <button mat-button class="mat-flat-button cancel" i18n="cancel" (click)="close()">Cancel</button>
        <button mat-button class="mat-flat-button right action intercom" i18n="Open" (click)="close()">Open</button>
    </ng-container>
</mat-dialog-actions>
