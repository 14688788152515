<div class="alert-content" mat-dialog-content>
    <div class="dividends-confirm-title" i18n="Dividends|Confirm dialog|Title">Pay dividends</div>
    <div class="dividends-confirm-content">
        <p i18n="Dividends|Confirm dialog|Main text">You are about to pay <span [innerHTML]="dividends | amount"></span> to {{numberOfShareholders}} shareholder.</p>
    </div>
</div>
<mat-dialog-actions class="red-alert-actions dividends-confirm-actions">
    <button mat-flat-button color="primary" tabindex="-1" (click)="cancel()" i18n="Cancel button label">Cancel</button>
    <button mat-flat-button color="primary" tabindex="-1" (click)="confirm()" i18n="Pay button label">Pay</button>
</mat-dialog-actions>

