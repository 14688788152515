import {Component, OnInit} from '@angular/core';
import {
    Amount,
    AnnualReport,
    PAYMENT_STATUS,
    REFERENCE_TYPE,
    Transaction,
    TRANSACTION_SUB_TYPE
} from '@red/data';
import {forkJoin} from 'rxjs';
import {QueryFilter, QueryResponse, LIST_STATUS, ListDataSource} from '@red/browser';
import {DividendsEventReader} from '../../../common/event/readers/dividends';
import {ActivatedRoute} from '@angular/router';
import {AuthState} from '../../../shared/state/auth/auth.state';
import {Store} from '@ngxs/store';
import {AnnualReportManager} from '../../../managers/annual-report/annual-report.manager';
import { TransactionServiceClient } from '../../../lab/service-client/transaction-service-client';
import {Observable} from 'rxjs';
import {FinancialStatementState} from '../../../shared/state/financial-statement/financial-statement.state';
import {FinancialStatementLoadProjection} from '../../../shared/state/financial-statement/financial-statement.actions';
import {MockDTO} from '../../../../testing/mocks/dto';
import {A} from '@angular/cdk/keycodes';
import {TransactionManager} from '../../../managers/transaction/transaction.manager';

@Component({
    selector: 'app-dividends-list-view',
    styleUrls: ['./list.sass'],
    templateUrl: './list.tpl.html'
})
export class DividendsListViewComponent implements OnInit {
    get annualReport(): AnnualReport { return this._annualReport; }
    get confirmedDividendsList(): ListDataSource<Transaction> { return this._confirmedDividends; }
    get pendingDividendsList(): ListDataSource<Transaction> { return this._pendingDividends; }
    get limit$(): Observable<Amount> { return this._limit$; }
    get state(): LIST_STATUS { return this._state; }
    get stateDone(): LIST_STATUS { return LIST_STATUS.DONE; }
    get stateLoading(): LIST_STATUS { return LIST_STATUS.LOADING; }
    get stateNoResult(): LIST_STATUS { return LIST_STATUS.NO_RESULT; }

    private _annualReport: AnnualReport;
    private _annualReportManager: AnnualReportManager;
    private _confirmedDividends: ListDataSource<Transaction>;
    private _dividendsEventReader: DividendsEventReader;
    private _pendingDividends: ListDataSource<Transaction>;
    private _limit$: Observable<Amount>;
    private _route: ActivatedRoute;
    private _store: Store;
    private _state: LIST_STATUS = LIST_STATUS.LOADING;
    private _transactionManager: TransactionManager;

    constructor(
        annualReportManager: AnnualReportManager,
        dividendsEventReader: DividendsEventReader,
        route: ActivatedRoute,
        store: Store,
        transactionManager: TransactionManager
    ) {
        this._annualReportManager = annualReportManager;
        this._dividendsEventReader = dividendsEventReader;
        this._route = route;
        this._store = store;
        this._transactionManager = transactionManager;

        this._confirmedDividends = new ListDataSource<Transaction>((filter: QueryFilter) => {
            return this._transactionManager.queryToQueryResponse(this._store.selectSnapshot(AuthState.companyId), filter);
        });

        this._pendingDividends = new ListDataSource<Transaction>((filter: QueryFilter) => {
            return this._transactionManager.queryToQueryResponse(this._store.selectSnapshot(AuthState.companyId), filter);
        });
    }

    ngOnInit(): void {
        this._limit$ = this._store.select(FinancialStatementState.limit);

        this._route.parent.data.subscribe((data: {annualReport: AnnualReport}) => {
            this._annualReport = data.annualReport;
        });

        this._loadTransactions();

        this._dividendsEventReader.onCreated(() => {
            this._getAnnualReport(this._annualReport.id);

            const companyId = this._store.selectSnapshot(AuthState.companyId);

            this._store.dispatch(new FinancialStatementLoadProjection({companyId}));
            this._loadTransactions();
        });
    }

    loadMore(dataSource: ListDataSource<Transaction>) {
        dataSource.next().subscribe(() => {});
    }

    private _getAnnualReport(id: string) {
        this._annualReportManager.getAnnualReport(id)
            .subscribe((response: AnnualReport) => {
                this._annualReport = response;
            });
    }

    private _loadTransactions() {
        this._state = LIST_STATUS.LOADING;

        const confirmedFilter = new QueryFilter()
            .equal('paymentStatus', [PAYMENT_STATUS.CONFIRMED])
            .equal('type', [REFERENCE_TYPE.FINANCIAL_PAYMENT])
            .equal('subType', [TRANSACTION_SUB_TYPE.DIVIDENDS])
            .equal('orderBy', 'CREATED_AT')
            .offset(0)
            .length(3);

        const pendingFilter = new QueryFilter()
            .equal('paymentStatus', [PAYMENT_STATUS.PENDING, PAYMENT_STATUS.DELIVERED, PAYMENT_STATUS.SCHEDULED])
            .equal('type', [REFERENCE_TYPE.FINANCIAL_PAYMENT])
            .equal('subType', [TRANSACTION_SUB_TYPE.DIVIDENDS])
            .equal('orderBy', 'CREATED_AT')
            .offset(0)
            .length(14);

        this._confirmedDividends.flush();
        this._pendingDividends.flush();

        forkJoin([
                this._confirmedDividends.next(confirmedFilter),
                this._pendingDividends.next(pendingFilter)
            ])
            .subscribe((response: QueryResponse<Transaction>[]) => {
                const hasResults = response.reduce((acc, resp) => acc + resp.total, 0);
                this._state = hasResults ? LIST_STATUS.DONE : LIST_STATUS.NO_RESULT;
            });
    }
}
