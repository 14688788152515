<div class="red-blank-slate-2020" [ngClass]="typeClass">
    <div class="image-container">
        <div class="image"></div>
    </div>
    <h3>
        <span class="red-css-icon" [ngClass]="typeClass">
            <ng-content select="header"></ng-content>
        </span>
    </h3>
    <div class="info-text">
        <ng-content select="p"></ng-content>
    </div>
    <app-onboarding-alert></app-onboarding-alert>
</div>
