import {Component, HostBinding, Inject, LOCALE_ID, OnInit} from '@angular/core';
import {
    Amount,
    CARD_TRANSACTION_RESOLUTION_TYPE,
    CARD_TRANSACTION_STATUS,
    CardTransaction,
    CardTransactionResolveRequest,
    Comment,
    CommentListResponse,
    CreditReference,
    PAYMENT_TYPE,
    REFERENCE_TYPE
} from '@red/data';
import {first} from 'rxjs/operators';
import {SafeResourceUrl} from '@angular/platform-browser';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DIALOG_RESULT, RedNotification, RedDialog} from '@red/components';
import {Router} from '@angular/router';
import {CardTransactionCantMarkAsDebtConfirmComponent} from '../confirm/cantMarkAsDebt/cantMarkAsDebt';
import {CardTransactionMarkAsDebtConfirmComponent} from '../confirm/markAsDebt/markAsDebt';
import {CardTransactionUpdatedEvent} from '../../../common/event/readers/cardTransaction';
import {EventSource} from '../../../common/event/source';
import {AppConfig} from '../../../config/appConfig';
import {CardTransactionServiceClient} from '../../../lab/service-client/card-transaction-service-client';
import {FormGroup} from '@angular/forms';
import {CommentFormHelper} from '../../../common/form/comment-form-helper';
import {CommentManager} from '../../../managers/comment/comment';
import {Store} from '@ngxs/store';
import {AuthState} from '../../../shared/state/auth/auth.state';
import {FinancialStatementState} from '../../../shared/state/financial-statement/financial-statement.state';
import { RedFlagShutDownManager } from '../../../managers/red-flag-shut-down/red-flag-shut-down.manager';

@Component({
    styleUrls: ['./edit.sass'],
    templateUrl: './edit.tpl.html'
})
export class CardTransactionEditComponent implements OnInit {
    @HostBinding('class.card-transaction-edit-view') cssClass = true;

    get cardTransaction(): CardTransaction {
        return this._cardTransaction;
    }

    get cardTransactionRejectMarkdown(): string {
        return this._cardTransactionRejectMarkdown;
    }

    get commentForm(): FormGroup {
        return this._commentForm;
    }

    get creditedBy(): CreditReference[] {
        return this._cardTransaction.creditedBy;
    }

    get creditingCardTransaction(): CardTransaction {
        return this._creditingCardTransaction;
    }

    get isCreditCardTransaction(): boolean {
        return this._cardTransaction.type === PAYMENT_TYPE.CREDIT;
    }

    get isPending(): boolean {
        return this._cardTransaction.status === CARD_TRANSACTION_STATUS.PENDING || this._cardTransaction.status === CARD_TRANSACTION_STATUS.IN_REVIEW;
    }

    get isRejected(): boolean {
        return this._cardTransaction.status === CARD_TRANSACTION_STATUS.REJECTED;
    }

    get hasCredit(): boolean {
        return this._cardTransaction.creditedBy.length > 0;
    }

    get resourceUrl(): SafeResourceUrl {
        return this._cardTransaction.resourceUrl;
    }

    get showCommentForm(): boolean {
        return (this.isPending && !!this._commentForm) || (!this.isPending && !!this._userComment);
    }

    get showTransactionAmount(): boolean {
        return this._cardTransaction.amount.amount !== this._cardTransaction.transactionAmount.amount || this._cardTransaction.amount.currency !== this._cardTransaction.transactionAmount.currency;
    }

    get uploading(): boolean {
        return this._uploading;
    }

    get notReadOnlyState(): boolean {
        return this._notReadOnlyState;
    }

    private _cardTransaction: CardTransaction;
    private _cardTransactionRejectMarkdown: string;
    private _cardTransactionServiceClient: CardTransactionServiceClient;
    private _commentForm: FormGroup;
    private _commentFormHelper: CommentFormHelper;
    private _commentManager: CommentManager;
    private _comments: CommentListResponse;
    private _creditingCardTransaction: CardTransaction;
    private _dialog: RedDialog;
    private _dialogRef: MatDialogRef<CardTransactionEditComponent>;
    private _eventSource: EventSource;
    private _notification: RedNotification;
    private _owed: Amount;
    private _router: Router;
    private _store: Store;
    private _uploading = false;
    private _userComment: Comment;
    private _redFlagShutDownManager: RedFlagShutDownManager;
    private _notReadOnlyState: boolean;

    constructor(
        appConfig: AppConfig,
        cardTransactionServiceClient: CardTransactionServiceClient,
        commentFormHelper: CommentFormHelper,
        commentManager: CommentManager,
        dialog: RedDialog,
        dialogRef: MatDialogRef<CardTransactionEditComponent>,
        eventSource: EventSource,
        notification: RedNotification,
        router: Router,
        store: Store,
        redFlagShutDownManager: RedFlagShutDownManager,
        @Inject(LOCALE_ID) localeId: string,
        @Inject(MAT_DIALOG_DATA) data: any
    ) {
        this._cardTransactionServiceClient = cardTransactionServiceClient;
        this._commentFormHelper = commentFormHelper;
        this._commentManager = commentManager;
        this._dialog = dialog;
        this._dialogRef = dialogRef;
        this._eventSource = eventSource;
        this._notification = notification;
        this._router = router;
        this._store = store;
        this._redFlagShutDownManager = redFlagShutDownManager;

        this._cardTransaction = data.cardTransaction;
        this._cardTransactionRejectMarkdown = appConfig.get(`content.receipt.reject.${localeId}`);
    }

    ngOnInit() {
        this._owed = this._store.selectSnapshot(FinancialStatementState.companyOwed);


        const today = this._redFlagShutDownManager.today;
        this._notReadOnlyState = this._redFlagShutDownManager.notReadOnlyState(today);

        if (this.isCreditCardTransaction && this._cardTransaction.crediting) {
            this._getCreditingCardTransaction();
        }

        this._commentManager.getComments(REFERENCE_TYPE.CARD_TRANSACTION, this._cardTransaction.id)
            .subscribe((response: CommentListResponse) => {
                this._comments = response;
                this._userComment = this._comments.data.filter(comment => comment.author.entity.id === this._store.selectSnapshot(AuthState.userId)).shift();
                this._createForm();
                if (this._userComment) {
                    this._updateForm(this._userComment);
                }
                if (!this.isPending) {
                    this._commentForm.disable();
                }
            });
    }

    close() {
        if (this.isPending && this.canSaveComment()) {
            this.saveComment();
        }

        this._dialogRef.close(DIALOG_RESULT.OK);
    }

    canEdit(): boolean {
        return this._userComment && (this._userComment.author.entity.id === this._store.selectSnapshot(AuthState.userId)) || !this._userComment;
    }

    canSaveComment(): boolean {
        return this.canEdit() && this._commentForm.valid && this._commentForm.dirty;
    }

    createComment() {
        const message = this._commentForm.get('message').value;
        this._commentManager.createComment(REFERENCE_TYPE.CARD_TRANSACTION, this._cardTransaction.id, message)
            .subscribe((response: CommentListResponse) => {
                this._comments = response;
                this._userComment = this._comments.data.filter(comment => comment.author.entity.id === this._store.selectSnapshot(AuthState.userId)).shift();
                this._commentForm.markAsPristine();
            });
    }

    editComment(comment: Comment) {
        const message = this._commentForm.get('message').value;
        this._commentManager.editComment(REFERENCE_TYPE.CARD_TRANSACTION, this._cardTransaction.id, comment.id, message)
            .subscribe((response: CommentListResponse) => {
                this._comments = response;
                this._commentForm.markAsPristine();
            });
    }

    saveComment() {
        if (this._userComment) {
            this.editComment(this._userComment);
        } else {
            this.createComment();
        }
    }


    markAsDebt($event) {
        $event.stopPropagation();

        if (this._cardTransaction.amount.lessThenOrEqual(this._owed)) {
            this._dialog.confirm(CardTransactionMarkAsDebtConfirmComponent, {'data': {'amount': this._cardTransaction.amount}})
                .afterClosed()
                .subscribe((result: DIALOG_RESULT) => {
                    if (result === DIALOG_RESULT.OK) {
                        this._resolveTransactionAsDebt();
                    }
                });
        } else {
            const neededAmount = (this._cardTransaction.amount.amount - this._owed.amount).toFixed(2);
            this._dialog.confirm(CardTransactionCantMarkAsDebtConfirmComponent, {'data': {'minNeededAmount': neededAmount}});
        }
    }

    navigateToCardTransaction(id: string) {
        this._dialogRef
            .afterClosed().pipe(first())
            .subscribe(() => {
                this._router.navigate(['card-transaction', id, 'edit']);
            });

        this._dialogRef.close();
    }

    uploadNewImage($event: File) {
        this._uploading = true;
        this._resolveCardTransaction($event);
    }

    private _createForm() {
        this._commentForm = this._commentFormHelper.createCommentForm();
    }

    private _updateForm(comment: Comment) {
        this._commentForm.patchValue(this._commentFormHelper.mapCommentToForm(comment));
    }

    private _getCreditingCardTransaction() {
        const cardTransaction = new CardTransaction({'id': this._cardTransaction.crediting});
        this._cardTransactionServiceClient.getCardTransaction(cardTransaction.id)
            .subscribe((resp) => {
                this._creditingCardTransaction = resp;
            });
    }

    private _resolveCardTransaction(file) {
        const formData = new FormData();

        formData.append('file', file, file.name);
        this._cardTransactionServiceClient.resolveWithAsset(this._cardTransaction.id, formData)
            .subscribe((cardTransaction: CardTransaction) => {
                this._eventSource.emit(new CardTransactionUpdatedEvent());
                this._dialogRef.close();
            }, (err) => {
                this._uploading = false;

                const msg = $localize`:RedNotification|Failed to upload image:Failed to upload a image as a card transaction`;
                this._notification.errorWithCode(err, msg);
            });
    }

    private _resolveTransactionAsDebt() {
        const req = new CardTransactionResolveRequest({'type': CARD_TRANSACTION_RESOLUTION_TYPE.CARD_HOLDER_DEBT});

        this._cardTransactionServiceClient.resolveAsDebt(this._cardTransaction.id)
            .subscribe(
                () => {
                    this._eventSource.emit(new CardTransactionUpdatedEvent());
                    this.close();
                },
                (err) => {
                    this._uploading = false;

                    const msg = $localize`:RedNotification|Failed to mark card transaction as debt.:Failed to mark as debt`;
                    this._notification.errorWithCode(err, msg);
                });
    }
}
