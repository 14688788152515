import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router} from '@angular/router';
import {RoyaltyStatement} from '@red/data';
import {Observable, of} from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import {RoyaltyServiceClient} from '../../lab/service-client/royalty-service-client';

@Injectable()
export class RoyaltyStatementRouteResolve implements Resolve<RoyaltyStatement> {
    private _royaltyServiceClient: RoyaltyServiceClient;
    private _router: Router;

    constructor (
        royaltyServiceClient: RoyaltyServiceClient,
        router: Router
    ) {
        this._royaltyServiceClient = royaltyServiceClient;
        this._router = router;
    }

    resolve(route: ActivatedRouteSnapshot): Observable<RoyaltyStatement> {
        const royaltyId = route.params['id'];

        return this._royaltyServiceClient.getRoyaltyStatement(royaltyId)
            .pipe(
                map(royaltyStatement => {
                    return royaltyStatement;
                }), catchError(() => {
                    this._router.navigate(['/404']);
                    return of(null);
                })
        );
    }
}
