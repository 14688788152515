<div class="login-view">
    <div class="scroll">
        <ng-container [ngSwitch]="state">
            <ng-container *ngSwitchCase="'IDLE'">
                <div class="login-container hooks-bottom">
                    <mat-card class="login-card holes-bottom">
                        <div class="bokio-logo-container">
                            <div class="bokio-logo"></div>
                        </div>
                        <form class="login-form" red-form [formGroup]="loginForm">
                            <fieldset>
                                <label class="form-row identity-label">
                                    <mat-form-field>
                                        <input
                                            matInput
                                            i18n-placeholder="Login|input placeholder|Identity placeholder"
                                            placeholder="Identity"
                                            formControlName="identifier"
                                            required
                                            autocomplete="off"
                                        />
                                    </mat-form-field>
                                </label>
                                <div class="login-form-actions">
                                    <mat-slide-toggle
                                        formControlName="rememberMe"
                                        i18n="Login|slider label|Remember identity"
                                    >Remember me</mat-slide-toggle>
                                    <button
                                        i18n="Login|button|Submit sign in"
                                        [disabled]="loginForm.invalid"
                                        mat-flat-button
                                        color="primary"
                                        type="button"
                                        (click)="login($event)"
                                    >Submit</button>
                                </div>
                            </fieldset>
                        </form>
                    </mat-card>
                </div>

                <div class="sign-up-container hooks-bottom">
                    <div class="holes-bottom">
                    <mat-card class="sign-up-card holes-top">
                        <img class="sign-up-img" src="/assets/tmp/illu-papers.png" alt="Red Flag" />
                        <p>Har du inte påbörjat någon företagsregistrering? Logga in med två enkla steg och se direkt hur enkelt det är att starta företag med Bokio.</p>
                        <a mat-flat-button [routerLink]="['/', 'sign-up']">Starta företag med Bokio</a>
                    </mat-card>
                    </div>
                </div>

                <div class="contact-container">
                    <div class="holes-top">
                        <mat-card class="signup-card policy-card mat-card">
                            <div class="policy-links">
                                <a i18n="Terms and conditions" href="https://app.bokio.se/content/legal/swe/eula_v5_20210422.html" target="_blank">Terms and Conditions</a>
                                <a i18n="Privacy policy" href="https://www.bokio.se/gdpr/integritetspolicy/" target="_blank">Privacy policy</a>
                            </div>
                        </mat-card>
                    </div>
                </div>
            </ng-container>
            <div class="loader-container" *ngSwitchCase="'WAITING_FOR_BANKID'">
                <mat-card>
                    <div class="waiting-for-bankid">
                        <red-atom-loader></red-atom-loader>
                        <p class="waiting-for-bankid-label" i18n="Login|Please open you bankId application">Please open you bankId application</p>
                        <a [href]="bankIdUrl" *ngIf="canOpenOnSameDevice && bankIdUrl" mat-flat-button color="primary" i18n="Login|Open BankId app">
                            Open BankId app
                        </a>
                    </div>
                </mat-card>
            </div>
            <div class="loader-container" *ngSwitchCase="'USER_SIGN'">
                <mat-card>
                    <div class="waiting-for-bankid">
                        <red-atom-loader></red-atom-loader>
                        <p class="waiting-for-bankid-label" i18n="Login|Please enter your code in the app">Please enter your code in the app</p>
                    </div>
                </mat-card>
            </div>
        </ng-container>
    </div>
</div>
