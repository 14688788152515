import { PaymentServiceClient } from '../../lab/service-client/payment-service-client';
import {COUNTRY, PaymentAllowedCountriesResponse} from '@red/data';
import { map } from 'rxjs/operators';
import {Observable} from 'rxjs';

export class PaymentManager {
    private _paymentServiceClient: PaymentServiceClient;

    constructor (
        paymentServiceClient: PaymentServiceClient
    ) {
        this._paymentServiceClient = paymentServiceClient;
    }

    static create(
        paymentServiceClient: PaymentServiceClient
    ) {
        return new PaymentManager(paymentServiceClient);
    }

    getAllowedCountries(): Observable<COUNTRY[]> {
        return this._paymentServiceClient.getAllowedCountries()
            .pipe(map((paymentAllowedCountriesResponse: PaymentAllowedCountriesResponse) => {
                return paymentAllowedCountriesResponse.list;
            }));
    }
}
