import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AppConfig} from '../../config/appConfig';

const CLIENT_HEADER_NAME = 'x-redflag-client';

@Injectable()
export class ClientHeaderHook implements HttpInterceptor {
    private _clientStr: string;
    private _config: AppConfig;

    constructor (
        config: AppConfig
    ) {
        this._config = config;
        this._clientStr = '';
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const newReq = req.headers.get(CLIENT_HEADER_NAME) ? req : req.clone({'headers': req.headers.set(CLIENT_HEADER_NAME, this._getClientString())});
        return next.handle(newReq);
    }

    private _getClientString(): string {
        const appInfo = this._config.get('app');
        if (!this._clientStr && appInfo) {
            const navigator = window.navigator.userAgent;
            this._clientStr = `${appInfo.name};${appInfo.version}(${appInfo.build});${appInfo.platform},${navigator}`;
        }

        return this._clientStr;
    }
}
