import {Component, HostBinding, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NationalIdentifierValidator} from '@red/validators';
import {COUNTRY, ROLE} from '@red/data';
import {AuthLogin, AuthLoginStatus} from '../../shared/state/auth/auth.actions';
import {Actions, ofActionDispatched, Store} from '@ngxs/store';
import {AUTH_LOGIN_STATUS} from '../../shared/state/auth/auth.model';
import {AuthState} from '../../shared/state/auth/auth.state';
import {RedNotification} from '@red/components';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {BankIdManager} from '../../managers/bankid/bank-id-manager';
import {RememberMeState} from '../../shared/state/remember-me/remember-me.state';
import {KafkaLogger} from '../../common/error/global';
import {RemoteErrorException} from '../../common/error/remote-error-exception';

export enum LOGIN_STATE {
    IDLE = `IDLE`,
    WAITING_FOR_BANKID = `WAITING_FOR_BANKID`,
    USER_SIGN = `USER_SIGN`
}

@Component({
    selector: 'app-login-view',
    styleUrls: ['./login.sass'],
    templateUrl: 'login.tpl.html'
})

export class LoginViewComponent implements OnInit {
    @HostBinding('class.scroll-view') cssClass = true;

    get bankIdUrl(): SafeUrl { return this._bankIdUrl; }
    get state(): LOGIN_STATE { return this._state; }
    get loginForm(): FormGroup { return this._loginForm; }
    get canOpenOnSameDevice(): boolean { return BankIdManager.canOpenOnSameDevice(); }

    private _loginForm: FormGroup;
    private _formBuilder: FormBuilder;
    private _state = LOGIN_STATE.IDLE;
    private _store: Store;
    private _actions: Actions;
    private _notification: RedNotification;
    private _bankIdUrl: SafeUrl;
    private _sanitizer: DomSanitizer;
    private _kafkaLogger: KafkaLogger;

    constructor(
        formBuilder: FormBuilder,
        store: Store,
        actions: Actions,
        notification: RedNotification,
        sanitizer: DomSanitizer,
        kafkaLogger: KafkaLogger
    ) {
        this._formBuilder = formBuilder;
        this._store = store;
        this._actions = actions;
        this._notification = notification;
        this._sanitizer = sanitizer;
        this._kafkaLogger = kafkaLogger;
    }

    ngOnInit(): void {
        this._setForm();

        this._actions
            .pipe(ofActionDispatched(AuthLoginStatus))
            .subscribe((action: AuthLoginStatus) => {
                this._setLoginState(action);
            });
    }

    login($event: Event) {
        $event.stopPropagation();

        this._state = LOGIN_STATE.WAITING_FOR_BANKID;

        const {identifier, rememberMe} = this._loginForm.value;

        this._store.dispatch(new AuthLogin({
            identifier: identifier,
            scope: ROLE.OWNER,
            rememberMe: !!rememberMe
        }));
    }

    private _setForm() {
        const rememberMe = !!this._store.selectSnapshot(RememberMeState.rememberMe);
        let identifier = '';

        if (rememberMe) {
            identifier = this._store.selectSnapshot(RememberMeState.identifier) || '';
        }

        this._loginForm = this._formBuilder.group({
            identifier: [identifier],
            rememberMe: [rememberMe]
        });

        this._setValidators();
    }

    private _setValidators() {
        this._loginForm.get('identifier').setValidators([
            Validators.required,
            (ctrl: AbstractControl) => {
                return NationalIdentifierValidator.validate(ctrl.value, COUNTRY.SWEDEN)
                    ? null
                    : { 'nationalIdentifierError': true };
            }
        ]);
    }

    private _setBankIdUrl(autostartToken: string) {
        this._bankIdUrl = this._sanitizer.bypassSecurityTrustUrl(`bankid:///?autostarttoken=${autostartToken}&redirect=null`);
    }

    private _displayErrorRedNotification(error: RemoteErrorException) {
        let msg = $localize`:Login|Login error (fallback):Unknown error!`;
        if (error && error.errorCode === 1270001) {
            msg = $localize`:Error when logging in without a active company|User cant\'t login due to now companies. :There\'s no account matching you identifier.`;
        }

        this._notification.errorWithCode(error, msg);
        this._state = LOGIN_STATE.IDLE;

        error.message = `Login failed with error: ${error.message}`;
        const loginData = this._loginForm.getRawValue();
        loginData.isStandalone = BankIdManager.isStandaloneApp();
        loginData.canOpenOnSameDevice = BankIdManager.canOpenOnSameDevice();
        this._kafkaLogger.debug(error, loginData);
    }

    private _setLoginState(action: AuthLoginStatus) {
        switch (action.status) {
            case AUTH_LOGIN_STATUS.STARTED:
                const autostartToken = this._store.selectSnapshot(AuthState.autostartToken);

                this._setBankIdUrl(autostartToken);
                break;
            case AUTH_LOGIN_STATUS.COLLECTING:
                this._state = LOGIN_STATE.WAITING_FOR_BANKID;
                if (action.bankdIdStatus === 'USER_SIGN') {
                    this._state = LOGIN_STATE.USER_SIGN;
                }
                break;
            case AUTH_LOGIN_STATUS.ERROR:
                this._displayErrorRedNotification(action.error);
                break;
            case AUTH_LOGIN_STATUS.ONBOARDING:
                this._state = LOGIN_STATE.WAITING_FOR_BANKID;
                break;
            default:
                this._state = LOGIN_STATE.IDLE;
        }
    }
}

