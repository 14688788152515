export interface CookieOptionsArgs {
    path?: string;
    domain?: string;
    expires?: string|Date;
    secure?: boolean;
    sameSite?: boolean;
}

export class CookieOptions {
    path: string;
    domain: string;
    expires: string|Date;
    secure: boolean;
    sameSite: boolean;

    constructor({path, domain, expires, secure, sameSite}: CookieOptionsArgs = {}) {
        this.path = this._isPresent(path) ? path : null;
        this.domain = this._isPresent(domain) ? domain : null;
        this.expires = this._isPresent(expires) ? expires : null;
        this.secure = this._isPresent(secure) ? secure : false;
        this.sameSite = this._isPresent(sameSite) ? sameSite : false;
    }

    private _isPresent(obj: any): boolean {
        return obj !== undefined && obj !== null;
    }
}

