<div class="form-field other-income">

    <ng-container *ngIf="showForm">
        <h3 class="form-section-header" i18n="End of year|header|Income from other employers">Income from other employers</h3>
        <div class="info-text read-more">
            <p i18n="End of year|other income text" [title]="readMoreLess(0)" (click)="toggleReadMoreLess(0)">About other income</p>
            <p i18n="End of year|other income text long">About other income long <a
                target="_blank"
                href="http://support.redflag.se/sv/articles/4657336-annan-inkomst-under-aret-och-inbetald-skatt"
            >read all about it</a></p>
        </div>
        <div class="form-row full-width-single-element-row">
            <mat-form-field class="right" [formGroup]="otherIncome">
                <input
                    matInput
                    i18n-placeholder="End of year|other income input placeholder"
                    placeholder="other income"
                    formControlName="amount"
                    class="right"
                    type="number" />
                <span matSuffix class="currency">kr</span>
            </mat-form-field>
        </div>

        <div class="info-text read-more">
            <p i18n="End of year|other income nonDeductibleIncome text" [title]="readMoreLess(1)" (click)="toggleReadMoreLess(1)">About non deductible income</p>
            <p i18n="End of year|other income nonDeductibleIncome text long">About non deductible income long <a
                target="_blank"
                href="http://support.redflag.se/sv/articles/4657336-annan-inkomst-under-aret-och-inbetald-skatt"
            >Read all about it</a></p>
        </div>
        <div class="form-row full-width-single-element-row">
            <mat-form-field class="right" [formGroup]="nonDeductibleIncome">
                <input
                    matInput
                    i18n-placeholder="End of year|other income nonDeductibleIncome input placeholer"
                    placeholder="non deductible income"
                    formControlName="amount"
                    class="right"
                    type="number" />
                <span matSuffix class="currency">kr</span>
            </mat-form-field>
        </div>

        <div class="info-text read-more">
            <p i18n="End of year|other income insuranceIncome text" [title]="readMoreLess(2)" (click)="toggleReadMoreLess(2)">About insurance income</p>
            <p i18n="End of year|other income insuranceIncome text long">About insurance income long</p>
        </div>
        <div class="form-row full-width-single-element-row">
            <mat-form-field class="right" [formGroup]="insuranceIncome">
                <input
                    matInput
                    i18n-placeholder="End of year|other income insuranceIncome input placeholer"
                    placeholder="Insurance income"
                    formControlName="amount"
                    class="right"
                    type="number" />
                <span matSuffix class="currency">kr</span>
            </mat-form-field>
        </div>

        <div class="info-text" i18n="End of year|other income payed tax for other income">About payed tax for other income</div>
        <div class="form-row full-width-single-element-row">
            <mat-form-field class="right" [formGroup]="otherTax">
                <input
                    matInput
                    i18n-placeholder="End of year|other income payed tax for other income input placeholer"
                    placeholder="payed tax for other income"
                    formControlName="amount"
                    class="right"
                    type="number" />
                <span matSuffix class="currency">kr</span>
            </mat-form-field>
        </div>
    </ng-container>
    <ng-container *ngIf="!showForm">
        <h3 class="form-section-header" i18n="End of year|other income income from other employers header">Income from other employers</h3>
        <dl class="dl-list">
            <dt i18n="Other income list|label|Other income">Other income</dt>
            <dd [innerHTML]="otherIncomeAmount | amount"></dd>
            <dt i18n="Other income list|label|Non deductible income">Non deductible income</dt>
            <dd [innerHTML]="nonDeductibleIncomeAmount | amount"></dd>
            <dt i18n="Other income list|label|Insurance income">Insurance income</dt>
            <dd [innerHTML]="insuranceIncomeAmount | amount"></dd>
            <dt i18n="Other income list|label|Other tax">Other tax</dt>
            <dd [innerHTML]="otherTaxAmount | amount"></dd>
        </dl>

        <ng-container *ngIf="isSoleTrader">
            <h3 class="form-section-header" i18n="End of year|metadata form header|Benefits">Benefits</h3>
            <dl class="dl-list">
                <dt i18n="End of year|list label|deductible-mileage">Deductible-mileage</dt>
                <dd>{{getMetadataValue('deductibleMileage')}}</dd>
            </dl>
        </ng-container>

        <app-end-of-year-reports
            *ngIf="!hasCloseYearFlag"
            [isDialogView]="true"
        ></app-end-of-year-reports>

    </ng-container>

</div>
<div *ngIf="showWarning()" class="warning-text" i18n="End Of Year|warning about|missing paid tax information">Hej, you forget to fill information about paid taxes!</div>
