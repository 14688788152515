import {AppConfig} from '../../config/appConfig';
import {Observable} from 'rxjs';
import {SieValidationResponse} from '@red/data';
import {WebServiceClient} from './web-service-client';
import {HttpFetch} from '@red/common';

export class SieServiceClient extends WebServiceClient {
    static create(transport: HttpFetch, config: AppConfig) {
        return new SieServiceClient(transport, config);
    }

    validate(assetId: string): Observable<SieValidationResponse> {
        const url = `${this._getBaseUrl()}/api/sie/validate/${assetId}`;
        return this._get<SieValidationResponse>(url, {}, SieValidationResponse);
    }
}
