import {Component, forwardRef, OnInit} from '@angular/core';
import {OnboardingApplicationDataLlcNewShareCapital} from '@red/onboarding-service-api';
import {Actions, Store} from '@ngxs/store';
import {OnboardingManager} from '../../../../managers/onboarding/onboarding-manager';
import {OnboardingApplicationBaseDirective} from '../onboarding-application-base';
import * as moment from 'moment';

@Component({
    selector: 'app-onboarding-application-llc-new-share-capital',
    styleUrls: ['../onboarding-applications.sass'],
    templateUrl: 'onboarding-application-llc-new-share-capital.tpl.html',
    providers: [
        {
            provide: OnboardingApplicationBaseDirective,
            useExisting: forwardRef(() => OnboardingApplicationLlcNewShareCapitalComponent)
        }
    ]
})
export class OnboardingApplicationLlcNewShareCapitalComponent extends OnboardingApplicationBaseDirective implements OnInit {
    get createdBeforeOrSameAsBgNrChange(): boolean {
        return this._createdBeforeOrSameAsBgNrChange;
    }

    get bgNrChangeDate(): string {
        return this._bgNrChangeDate;
    }

    get data(): OnboardingApplicationDataLlcNewShareCapital {
        return this._data;
    }

    private _data: OnboardingApplicationDataLlcNewShareCapital;

    private _createdBeforeOrSameAsBgNrChange: boolean;
    private _bgNrChangeDate = '2022-04-11';

    constructor(
        actions: Actions,
        store: Store,
        onboardingManager: OnboardingManager
    ) {
        super(actions, store, onboardingManager);
    }

    ngOnInit() {
        super.ngOnInit();

        this._data = (this.application && this.application.data || {}) as OnboardingApplicationDataLlcNewShareCapital;

        this._setCreatedBeforeOrSameAsBgNrChange();
    }

    private _setCreatedBeforeOrSameAsBgNrChange(): void {
        const createdDateTime = moment(this.application.created);
        const bgChangedDate = moment(this._bgNrChangeDate);

        this._createdBeforeOrSameAsBgNrChange = createdDateTime.isSameOrBefore(bgChangedDate);
    }
}
