import {Injectable} from '@angular/core';
import {Converter} from 'showdown';
import {RedMarkdownLoader} from '@red/components';
import {HttpClient} from '@angular/common/http';
import {DomSanitizer} from '@angular/platform-browser';

@Injectable()
export class MarkdownLoader extends RedMarkdownLoader {

    constructor (
        http: HttpClient,
        sanitizer: DomSanitizer
    ) {
        super(http, sanitizer);
    }

    public parseMarkdown(markdown: string) {
        const converter = new Converter();
        return converter.makeHtml(markdown);
    }
}
