import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppConfig} from '../../config/appConfig';
import {Subscription} from 'rxjs';
import {ClientInfoResponse} from '@red/data';
import {Store} from '@ngxs/store';
import {AppInfoUpdate} from '../../shared/state/app-info/app-info.actions';
import {ANNOUNCEMENT_TYPE} from '../../shared/state/app-info/app-info.model';

@Injectable()
export class VersionChecker {
    private _http: HttpClient;
    private _appConfig: AppConfig;
    private _interval: any;
    private _intervalSubscription: Subscription;
    private _store: Store;

    constructor (
        appConfig: AppConfig,
        http: HttpClient,
        store: Store
    ) {
        this._appConfig = appConfig;
        this._http = http;
        this._store = store;
    }

    start() {
        if (this._interval) {
            this.stop();
        }

        this._getClientInfo();
        this._interval = setInterval(() => this._getClientInfo(), 30000);
    }

    stop() {
        if (this._intervalSubscription) {
            this._intervalSubscription.unsubscribe();
        }

        if (this._interval) {
            clearInterval(this._interval);
        }
    }

    private _getClientInfo() {
        const url = this._appConfig.get('versionChecker.url');
        this._intervalSubscription = this._http.get(url)
            .subscribe((resp: object) => {
                this._handleClientInfoResponse(new ClientInfoResponse(resp));
            },  () => {
                this.stop();
            });
    }

    private _handleClientInfoResponse(resp: ClientInfoResponse) {
        const appInfoUpdate = new AppInfoUpdate({
            appVersion: {
                build: resp.build,
                version: resp.version,
                newVersionAvailable: resp.newVersionAvailable
            },
            announcements: [
                {
                    id: 'wirecard-info-resolved',
                    title: 'Ditt Red Flag Mastercard är nu återaktiverat',
                    message: '<p>Ditt Red Flag Mastercard är nu återaktiverat och fungerar igen. Avbrottet berodde på ett problem hos en av våra underleverantörer som nu är avhjälpt. Vi beklagar givetvis det inträffade och hoppas att det inte medförde några bekymmer för dig som kund hos Red Flag. Du är alltid välkommen att höra av dig på <a href="mailto:support@redflag.se">support@redflag.se</a> om du har några frågor.</p>',
                    type: ANNOUNCEMENT_TYPE.INFO,
                    expires: '2020-07-10'
                },
                {
                    id: 'name-change-bankid-info',
                    title: 'Information om BankId inloggning',
                    message: '<p>Från och med nu kommer det att stå Bokio AB när du loggar in och signerar med BankID när du använder Red Flag!</p>',
                    type: ANNOUNCEMENT_TYPE.INFO,
                    expires: '2020-10-16'
                },
            ]
        });

        this._store.dispatch(appInfoUpdate);
    }
}
