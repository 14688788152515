import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OnboardingAlertComponent} from './onboarding-alert';
import { MatButtonModule } from '@angular/material/button';
import {RouterModule} from '@angular/router';

@NgModule({
    declarations: [
        OnboardingAlertComponent
    ],
    exports: [
        OnboardingAlertComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        RouterModule.forRoot([], { relativeLinkResolution: 'legacy' }),
    ]
})
export class OnboardingAlertModule {}
