import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConfirmDialogComponent} from './confirm-dialog';
import {MatDialogModule} from '@angular/material/dialog';
import {RedDialogModule} from '@red/components';
import {MatButtonModule} from '@angular/material/button';

@NgModule({
    declarations: [
        ConfirmDialogComponent
    ],
    exports: [
        ConfirmDialogComponent
    ],
    imports: [
        CommonModule,
        RedDialogModule,
        MatButtonModule,
        MatDialogModule
    ]
})
export class ConfirmDialogModule {}
