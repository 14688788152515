import {Component, OnDestroy, OnInit} from '@angular/core';
import {DIALOG_RESULT, RedDialog} from '@red/components';
import { MatDialogRef } from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {DividendsCreateComponent} from '../../../components/dividends/create/create';
import {DividendsEventReader} from '../../../common/event/readers/dividends';

@Component({
    selector: 'app-dividends-create',
    templateUrl: 'create.tpl.html'
})
export class DividendsCreateViewComponent implements OnDestroy, OnInit {
    private _dialog: RedDialog;
    private _dialogRef: MatDialogRef<DividendsCreateComponent>;
    private _dividendsEventReader: DividendsEventReader;
    private _route: ActivatedRoute;
    private _router: Router;

    constructor (
        dialog: RedDialog,
        dividendsEventReader: DividendsEventReader,
        route: ActivatedRoute,
        router: Router
    ) {
        this._dialog = dialog;
        this._dividendsEventReader = dividendsEventReader;
        this._route = route;
        this._router = router;
    }

    ngOnDestroy(): void {
        this._dialogRef.close(DIALOG_RESULT.CANCEL);
    }

    ngOnInit(): void {
        this._route.parent.parent.data.subscribe((data: {annualReport}) => {
            if (data.annualReport) {
                setTimeout(() => {
                    this._createDividends(data.annualReport.id);
                }, 0);
            }
        });
    }

    private _createDividends(annualReportId: string) {
        this._dialogRef = this._dialog.open(DividendsCreateComponent, {'data': {'reportId': annualReportId}});
        this._dialogRef.afterClosed()
            .subscribe((result: DIALOG_RESULT) => {
                if (result === DIALOG_RESULT.OK) {
                    this._router.navigate(['../'], {relativeTo: this._route});
                }
            });
    }
}
