import {Resolve, Router, ActivatedRouteSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {Receipt} from '@red/data';
import {Observable, of} from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import { ReceiptServiceClient } from '../../lab/service-client/receipt-service-client';

@Injectable()
export class ReceiptRouteResolve implements Resolve<Receipt> {
    private _receiptServiceClient: ReceiptServiceClient;
    private _router: Router;

    constructor(
        receiptServiceClient: ReceiptServiceClient,
        router: Router,
    ) {
        this._receiptServiceClient = receiptServiceClient;
        this._router = router;
    }

    resolve(route: ActivatedRouteSnapshot): Observable<Receipt> {
        return this._receiptServiceClient.getById(route.params['id']).pipe(map(receipt => {
                return receipt;
            })
            , catchError(() => {
                this._router.navigate(['/not-found']);
                return of(null);
            }));
    }
}
