import {EventSource} from './source';
import {BaseEvent} from './event';

export function OnEvent(type: typeof BaseEvent) {
    return function (target: EventReader, methodName: string) {
        if (!target['_handlers']) {
            target['_handlers'] = [];
        }

        target['_handlers'].push([type, methodName]);
    };
}

export class EventReader {
    protected _source: EventSource;
    protected _handlers;

    constructor (
        store: EventSource
    ) {
        this._source = store;

        if (this._handlers) {
            this._handlers.forEach(([type, methodName]: [typeof BaseEvent, string]) => this._subscribeToSource(type, methodName));
        }
    }

    private _subscribeToSource(type: typeof BaseEvent, methodName: string) {
        this._source
            .by(type)
            .subscribe((evt) => {
                this[methodName].apply(this, [evt]);
            });
    }
}
