<red-info-box redInfoBoxList>
    <red-markdown-content [markdownPath]="cardStolenInfoMarkdown"></red-markdown-content>
</red-info-box>
<div [ngSwitch]="state">
    <red-skeleton-loader *ngSwitchCase="stateLoading" [rows]="2"></red-skeleton-loader>
    <div *ngSwitchCase="stateNoResult" class="card-transaction-no-result">
        <red-blank-slate icon="list-card">
            <ng-template #noCard>
                <span i18n="Blank slate|User has no card transactions and didnt active card">Activate your new Red Flag-card at <a [routerLink]="['/settings/card']">Settings</a></span>
            </ng-template>
            <span *ngIf="isActivatedCard$ | async; else noCard" i18n="Blank slate|No card transactions heading">No card transactions to display</span>
        </red-blank-slate>
    </div>
    <div *ngSwitchCase="stateDone">
        <red-list *ngIf="reservedCardTransactions.hasResults">
            <span redListHeader i18n="List header for reserved card transactions">Reserved</span>
            <mat-list-item *ngFor="let transaction of reservedCardTransactions.items | async" [routerLink]="['/card-transaction', transaction.reference.id, 'edit']" redListLink>
                <red-list-icon mat-list-avatar [item]="transaction"></red-list-icon>
                <h4 mat-line>{{transaction.description}}</h4>
                <time mat-line>{{transaction.createdAt | moment}}</time>
                <app-display-amount-text [amount]="transaction.total" type="CARD_TRANSACTION" [direction]="transaction.direction" [preliminaryAmount]="true">
                    <span *ngIf="showTransactionAmount(transaction)" class="forex-amount" [innerHTML]="transaction.forexAmount | amount"></span>
                </app-display-amount-text>
            </mat-list-item>
        </red-list>
        <div *ngIf="cardTransactions.hasResults">
            <red-list *ngFor="let month of cardTransactionsByMonth | async">
                <span redListHeader>{{month.date | moment: 'MMMM'}}</span>
                <mat-list-item *ngFor="let transaction of month.transactions" [routerLink]="['/card-transaction', transaction.reference.id, 'edit']" redListLink>
                    <red-list-icon mat-list-avatar [item]="transaction"></red-list-icon>
                    <h4 mat-line>{{transaction.description}}</h4>
                    <time mat-line>{{transaction.createdAt | moment}}</time>
                    <app-display-amount-text [amount]="transaction.total" type="CARD_TRANSACTION" [direction]="transaction.direction"></app-display-amount-text>
                </mat-list-item>
            </red-list>
            <button *ngIf="!cardTransactions.complete" mat-button (click)="loadMore(cardTransactions)" i18n="load more card transactions">Load more...</button>

        </div>
    </div>
</div>
<router-outlet></router-outlet>
