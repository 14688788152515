<app-view-card>
    <red-top-toolbar>
        <span redTopToolbarTitle i18n="Support|Main title for Support View">Support</span>
        <button mat-flat-button color="primary" redTopToolbarAction class="intercom">
            <span i18n="Contact|Action button for Support view">Contact</span>
        </button>
    </red-top-toolbar>
    <app-view-card-infobar class="view-card-infobar">
        <app-red-flag-shut-down-banner></app-red-flag-shut-down-banner>
    </app-view-card-infobar>
    <red-info-box redInfoBoxList>
        <mat-icon svgIcon="ui-info-slate"></mat-icon>
<!--        <span i18n="InfoBox|Support| short text about where to find information and how to contact support">You can find answers to many questions in our helpcenter. You can also contact us directly at-->
<!--        <a href="mailto:support@redflag.se">support@redflag.se</a> we will try to answer you as soon as possible, during office hours</span>-->
        <span>
            Du kan hitta svar på många frågor i vårt hjälpcenter.
            Du kan även kontakta oss direkt i chatten, ringa oss på <a href="tel:0850254086">08-50254086</a>
            eller maila på <a href="mailto:support@redflag.se">support@redflag.se</a> där vi brukar svara snabbt, särskilt under kontorstider.
        </span>
    </red-info-box>
    <div class="start-chat">
        <a mat-flat-button color="primary" href="https://intercom.help/redflag" target="_blank" i18n="SupportView|Button| Help center">Help center</a>
        <button mat-flat-button color="primary" class="action intercom" i18n="SupportView|Button| contact support">Contact support</button>
        <img src="/assets/tmp/illu-search.png" alt="Red Flag" />
    </div>
</app-view-card>


