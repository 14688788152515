import {AppConfig} from '../../config/appConfig';
import {BaseServiceClient, HttpFetch} from '@red/common';
import {QueryFilter} from '@red/browser';
import {QueryParam} from '../../common/query-param';

export class WebServiceClient extends BaseServiceClient {
    protected _appConfig: AppConfig;

    constructor(
        transport: HttpFetch,
        appConfig: AppConfig
    ) {
        super(transport);
        this._appConfig = appConfig;
    }

    protected _getBaseUrl() { return this._appConfig.get('baseApiUrl') || ''; }

    protected _urlWithFilter(url: string, filter?: QueryFilter): string {
        if (filter) {
            url += `?${QueryParam.asString(filter)}`;
        }

        return url;
    }
}
