import {NgModule} from '@angular/core';
import {CustomerNewComponent} from './new/new';
import {CustomerRecentComponent} from './recent/recent';
import {RedListModule, RedInfoBoxModule, RedFormModule} from '@red/components';
import {CustomerSearchComponent} from './search/search';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import {FormMapper} from '../../../tmp-red-web/src/ng/helpers/formMapper';
import {RedAutocompleteModule} from '../../../tmp-red-web/src/ng/components/autocomplete';

@NgModule({
    declarations: [
        CustomerNewComponent,
        CustomerRecentComponent,
        CustomerSearchComponent
    ],
    exports: [
        CustomerNewComponent,
        CustomerRecentComponent,
        CustomerSearchComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatSelectModule,
        RedAutocompleteModule,
        RedInfoBoxModule,
        RedListModule,
        RedFormModule
    ],
    providers: [
        FormMapper
    ]
})
export class CustomerModule {}
