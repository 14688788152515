import {AppConfig} from '../../config/appConfig';
import {ActivateCardRequestV2, ActivationSignaturePayloadRequest, UpdateCardRequest} from '@red/data';
import {Observable} from 'rxjs';
import {HttpFetch, HttpFetchResponse} from '@red/common';
import {WebServiceClient} from './web-service-client';

export class CardServiceClient extends WebServiceClient {
    static create(transport: HttpFetch, config: AppConfig) {
        return new CardServiceClient(transport, config);
    }

    getActivateCardPayload(cardId: string, request: ActivationSignaturePayloadRequest): Observable<HttpFetchResponse> {
        const url = `${this._getBaseUrl()}/api/card/v2/${cardId}/activate/signature/payload`;
        return this._post(url, request, {});
    }

    activateCard(cardId: string, request: ActivateCardRequestV2): Observable<HttpFetchResponse> {
        const url = `${this._getBaseUrl()}/api/card/v2/${cardId}/activate`;
        return this._put(url, request, {});
    }

    listCards(companyId: string): Observable<HttpFetchResponse> {
        const url = `${this._getBaseUrl()}/api/card/company/${companyId}`;
        return this._get(url, {});
    }

    updateCard(cardId: string, request: UpdateCardRequest): Observable<HttpFetchResponse> {
        return this._put(`${this._getBaseUrl()}/api/card/v2/${cardId}`, request, {});
    }
}
