import {Component} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {DIALOG_RESULT} from '@red/components';

@Component ({
    templateUrl: './wait-for-settlement.tpl.html'
})

export class CardTransactionWaitForSettlementCofirmComponent {
    private _dialogRef: MatDialogRef<CardTransactionWaitForSettlementCofirmComponent>;

    constructor (dialogRef: MatDialogRef<CardTransactionWaitForSettlementCofirmComponent>) {
        this._dialogRef = dialogRef;
    }

    confirm() {
        this._dialogRef.close(DIALOG_RESULT.OK);
    }
}
