import {Component, HostBinding} from '@angular/core';

@Component({
    selector: 'app-view-card',
    styleUrls: ['./view-card.sass'],
    templateUrl: './view-card.tpl.html'
})

export class ViewCardComponent {
    @HostBinding('attr.class') cssClass = 'app-view-card';
}
