import {NgModule} from '@angular/core';
import {OnboardingModule} from '../../components/onboarding/onboarding.module';
import {BrowserModule} from '@angular/platform-browser';
import {CommonModule} from '@angular/common';
import {OnboardingViewComponent} from './onboarding';
import {RouterModule} from '@angular/router';
import {ONBOARDING_ROUTES} from './onboarding.routes';
import {ViewCardModule} from '../../components/view-card/view-card.module';
import {RedBlankSlateModule, RedDisplayModule, RedInfoBoxModule, RedTopToolbarModule} from '@red/components';
import {OnboardingRouteResolve} from './onboarding-route-resolve';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';

@NgModule({
    declarations: [
        OnboardingViewComponent
    ],
    exports: [
        OnboardingViewComponent
    ],
    imports: [
        BrowserModule,
        CommonModule,
        RouterModule.forRoot(ONBOARDING_ROUTES, {relativeLinkResolution: 'legacy'}),
        RedTopToolbarModule,
        ViewCardModule,
        OnboardingModule,
        RedInfoBoxModule,
        MatIconModule,
        RedBlankSlateModule,
        MatButtonModule,
        MatMenuModule,
        RedDisplayModule
    ],
    providers: [
        OnboardingRouteResolve
    ]
})
export class OnboardingViewModule {}
