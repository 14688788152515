import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RedFlagShutDownTriggerComponent} from './red-flag-shut-down-trigger';
import {RedDialogModule} from '@red/components';

@NgModule({
    declarations: [
        RedFlagShutDownTriggerComponent
    ],
    exports: [
        RedFlagShutDownTriggerComponent
    ],
    imports: [
        CommonModule,
        RedDialogModule
    ]
})
export class RedFlagShutDownTriggerModule {}
