import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {
    APP_INITIALIZER,
    ErrorHandler,
    Injector,
    LOCALE_ID,
    NgModule
} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import {CommonModule} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppComponent} from './app.component';
import {AppInit} from './app.init';
import {AppConfig} from './config/appConfig';
import {APP_ROUTING} from './app.routes';
import {ViewsModule} from './views/views.module';
import {AppCapabilities} from './common/appCapabilities';
import {ClientHeaderHook} from './transport/hooks/clientHeaderHook';
import {AuthTokenHook} from './transport/hooks/authTokenHook';
import {EventSource} from './common/event/source';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {CardTransactionEventReader} from './common/event/readers/cardTransaction';
import {ReceiptEventReader} from './common/event/readers/receipt';
import {BillEventReader} from './common/event/readers/bill';
import {MarkdownLoader} from './components/markdown/markdown-loader';
import {FinancialMovementEventReader} from './common/event/readers/financialMovment';
import {UnauthorizedHook} from './transport/hooks/unauthorizedHook';
import {GlobalErrorHandler, KafkaLogger} from './common/error/global';
import {ErrorI18n} from './common/error/error-i18n';
import {InvoiceEventReader} from './common/event/readers/invocie';
import {ServerErrorHook} from './transport/hooks/server-error-hook';
import {Analytics} from './common/analytics/analytics';
import {RoyaltyStatementEventReader} from './common/event/readers/royalty-statement';
import {EmployeeEventReader} from './common/event/readers/employee';
import {VersionChecker} from './common/client/version';
import {NewVersionModule} from './components/new-version/new-version.module';
import {EndOfYearManager} from './managers/end-of-year/end-of-year.manager';
import {DividendsEventReader} from './common/event/readers/dividends';
import {UserManager} from './managers/user/user.manager';
import {ShareholderManager} from './managers/shareholder/shareholder.manager';
import {AnnualReportManager} from './managers/annual-report/annual-report.manager';
import {CompanyManager} from './managers/company/company.manager';
import {CardTransactionServiceClient} from './lab/service-client/card-transaction-service-client';
import {CommentServiceClient} from './lab/service-client/comment-service-client';
import {CommentManager} from './managers/comment/comment';
import {CustomerServiceClient} from './lab/service-client/customer-service-client';
import {InvoiceServiceClient} from './lab/service-client/invoice-service-client';
import {CardServiceClient} from './lab/service-client/card-service-client';
import {WithdrawalServiceClient} from './lab/service-client/withdrawal-service-client';
import {SieServiceClient} from './lab/service-client/sie-service-client';
import {BenefitEventReader} from './common/event/readers/benefit';
import {YearEndServiceClient} from './lab/service-client/year-end-service-client';
import {OnboardingServiceClient} from './lab/service-client/onboarding-service-client';
import {OnboardingManager} from './managers/onboarding/onboarding-manager';
import {environment} from '../environments/environment';
import {OnboardingState} from './shared/state/onboarding/onboarding.state';
import {AuthManager} from './managers/auth/auth-manager';
import {AuthState} from './shared/state/auth/auth.state';
import {
    NGXS_STORAGE_PLUGIN_OPTIONS,
    NgxsStoragePluginModule,
    NgxsStoragePluginOptions,
    STORAGE_ENGINE
} from '@ngxs/storage-plugin';
import {CookieStorageEngine} from './shared/storage/cookie-storage-engine';
import {LoginViewModule} from './views/login/login-view.module';
import {CompanyConfigurationManager} from './managers/company-configuration/company-configuration-manager';
import {AuthStateRouteDispatcher} from './shared/dispatcher/auth-state-route-dispatcher';
import {OnboardingViewModule} from './views/onboarding/onboarding-view.module';
import {AssetServiceClient} from './lab/service-client/asset-service-client';
import {OnboardingSignUpModule} from './views/onboarding-sign-up/onboarding-sign-up.module';
import {ReauthorizeWorkflow} from './components/reauthorize/reauthorize-workflow';
import {ReauthorizeModule} from './components/reauthorize/reauthorize.module';
import {RememberMeState} from './shared/state/remember-me/remember-me.state';
import {CookieOptions} from './shared/storage/cookie-storage-options';
import {AnnualReportServiceClient} from './lab/service-client/annual-report-service-client';
import {AnnouncementModule} from './components/announcement/announcement.module';
import {AppInfoState} from './shared/state/app-info/app-info.state';
import {
    RedAssetManager,
    RedDialogModule,
    RedIconRegistry,
    RedMarkdownLoader,
    RedMarkdownModule,
    RedNotification,
    RedTopLevelMenuModule
} from '@red/components';
import {MOMENT_DEFAULT_FORMAT, NgHttpFetch} from '@red/ng';
import {AppConfigService, AuthorizationServiceClient, StoredSession} from '@red/browser';
import {HttpTransport} from '../tmp-red-web/src/ng/transport/http';
import { BankIdAuthProvider } from '../tmp-red-client/security/auth/bankIdProvider';
import {SignWorkflow} from '../tmp-red-client/workflow/sign';
import {NgxsModule, Store} from '@ngxs/store';
import {DirectoryServiceClient} from './lab/service-client/directory-service-client';
import {ForexServiceClient} from './lab/service-client/forex-service-client';
import {ReceiptServiceClient} from './lab/service-client/receipt-service-client';
import {SafeSpendServiceClient} from './lab/service-client/safe-spend-service-client';
import {RoyaltyServiceClient} from './lab/service-client/royalty-service-client';
import {ShareholderServiceClient} from './lab/service-client/shareholder-service-client';
import {TransactionServiceClient} from './lab/service-client/transaction-service-client';
import {PaymentManager} from './managers/payment/payment.manager';
import {PaymentServiceClient} from './lab/service-client/payment-service-client';
import {FinancialStatementManager} from './managers/financial-statement/financial-statement-manager';
import {FinancialStatementState} from './shared/state/financial-statement/financial-statement.state';
import {CardManager} from './managers/card/card-manager';
import {CardState} from './shared/state/card/card.state';
import {SignatureState} from './shared/state/signature/signature.state';
import {SignatureManager} from './managers/signature/signature-manager';
import {SignatureServiceClient} from './lab/service-client/signature-service-client';
import { ConfirmDialogModule } from './components/confirm-dialog/confirm-dialog.module';
import {RoaringServiceClient} from './lab/service-client/roaring-service-client';
import {RoaringManager} from './managers/roaring/roaring-manager';
import {TransactionManager} from './managers/transaction/transaction.manager';
import {ReportServiceClient} from './lab/service-client/report-service-client';
import {ReportManager} from './managers/report/report.manager';
import { RedFlagShutDownManager } from './managers/red-flag-shut-down/red-flag-shut-down.manager';
import {OffboardingServiceClient} from './lab/service-client/offboarding-service-client';

declare const require; // Use the require method provided by webpack

export function appConfigFactory() {
    return new AppConfig();
}

export function AssetManagerFactory(http: HttpClient, config: AppConfig) {
    return new RedAssetManager(http, config.get('baseApiUrl'));
}

export function appCapabilitiesFactory(config: AppConfig) {
    return new AppCapabilities(config);
}

export function bankIdAuthProviderFactory(http: HttpTransport, config: AppConfig) {
    return new BankIdAuthProvider(http, config.get('resources.auth'));
}

export function globalErrorHandlerFactory(http: HttpClient, injector: Injector, config: AppConfig) {
    return new GlobalErrorHandler(http, injector, config);
}

export function eventSourceFactory() {
    return new EventSource();
}

export function httpTransportFactory(http: HttpClient) {
    return new HttpTransport(http);
}

export function signWorkflowFactory(bankIdAuthProvider: BankIdAuthProvider) {
    return new SignWorkflow(bankIdAuthProvider);
}

export function momentDefaultFormat(conf: AppConfig) {
    return conf.get('moment.defaultFormat');
}

export function notificationFactory(snackBar: MatSnackBar) {
    const mapper = new ErrorI18n();
    return new RedNotification(mapper, snackBar);
}

export function translationsFactory(locale: string) {
    locale = locale || 'en'; // default to english if no locale provided
    return require(`raw-loader!../locale/messages.sv.xlf`);
}

export function cookieStorageEngineFactory(storageOptions: NgxsStoragePluginOptions) {
    return CookieStorageEngine.create(storageOptions, {
        'remember_me': new CookieOptions({secure: window.location.protocol === 'https:', path: '/', expires: 'Fri, 31 Dec 9999 23:59:59 GMT', sameSite: true})
    });
}

const DATE_FORMATS: MatDateFormats = {
    parse: {
        dateInput: 'YYYY-MM-DD'
    },
    display: {
        dateInput: 'YYYY-MM-DD',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'YYYY-MM-DD',
        monthYearA11yLabel: 'MMMM YYYY'
    }
};

export const APP_PROVIDERS = [
    Analytics,
    RedIconRegistry,
    KafkaLogger,
    VersionChecker,
    AuthStateRouteDispatcher,
    ReauthorizeWorkflow,
    // EventReaders
    BenefitEventReader,
    BillEventReader,
    CardTransactionEventReader,
    FinancialMovementEventReader,
    InvoiceEventReader,
    ReceiptEventReader,
    RoyaltyStatementEventReader,
    EmployeeEventReader,
    DividendsEventReader,
    // General
    {'provide': APP_INITIALIZER, 'useFactory': AppInit.bootstrap, 'deps': [AppConfigService, RedIconRegistry, LOCALE_ID, Analytics, Store], multi: true},
    {'provide': HTTP_INTERCEPTORS, useClass: UnauthorizedHook, multi: true },
    {'provide': HTTP_INTERCEPTORS, useClass: AuthTokenHook, multi: true },
    {'provide': HTTP_INTERCEPTORS, useClass: ClientHeaderHook, multi: true },
    {'provide': HTTP_INTERCEPTORS, useClass: ServerErrorHook, multi: true },
    {'provide': LOCALE_ID, 'useValue': 'sv'},
    {'provide': MAT_DATE_FORMATS, useValue: DATE_FORMATS},
    {'provide': MOMENT_DEFAULT_FORMAT, 'useFactory': momentDefaultFormat, 'deps': [AppConfig] },
    {'provide': STORAGE_ENGINE, useFactory: cookieStorageEngineFactory, deps: [NGXS_STORAGE_PLUGIN_OPTIONS]},
    {'provide': AppConfig, 'useFactory': appConfigFactory},
    {'provide': AppConfigService, 'useFactory': AppConfigService.create, 'deps': [NgHttpFetch, AppConfig]},
    {'provide': AppCapabilities, 'useFactory': appCapabilitiesFactory, 'deps': [AppConfig]},
    {'provide': BankIdAuthProvider, 'useFactory': bankIdAuthProviderFactory, 'deps': [HttpTransport, AppConfig]},
    {'provide': ErrorHandler, 'useFactory': globalErrorHandlerFactory, 'deps': [HttpClient, Injector, AppConfig]},
    {'provide': EventSource, 'useFactory': eventSourceFactory},
    {'provide': HttpTransport, 'useFactory': httpTransportFactory, 'deps': [HttpClient]},
    {'provide': SignWorkflow, 'useFactory': signWorkflowFactory, 'deps': [BankIdAuthProvider]},
    {'provide': RedMarkdownLoader, 'useClass': MarkdownLoader },
    {'provide': RedNotification, 'useFactory': notificationFactory, 'deps': [MatSnackBar] },
    {'provide': RedAssetManager, 'useClass': AssetManagerFactory, 'deps': [HttpClient, AppConfig] },
    {'provide': StoredSession, 'useValue': null, 'deps': []},
    // managers
    {'provide': AnnualReportManager, 'useFactory': AnnualReportManager.create, 'deps': [AnnualReportServiceClient]},
    {'provide': AuthManager, 'useFactory': AuthManager.create, 'deps': [AuthorizationServiceClient]},
    {'provide': CompanyConfigurationManager, 'useFactory': CompanyConfigurationManager.create, 'deps': []},
    {'provide': CommentManager, 'useFactory': CommentManager.create, 'deps': [CommentServiceClient, Store]},
    {'provide': CompanyManager, 'useFactory': CompanyManager.create, 'deps': [DirectoryServiceClient]},
    {'provide': EndOfYearManager, 'useFactory': EndOfYearManager.create, 'deps': [DirectoryServiceClient, Store, SignWorkflow, YearEndServiceClient, TransactionManager]},
    {'provide': OnboardingManager, 'useFactory': OnboardingManager.create, 'deps': [OnboardingServiceClient, Store]},
    {'provide': PaymentManager, 'useFactory': PaymentManager.create, 'deps': [PaymentServiceClient]},
    {'provide': ReportManager, 'useFactory': ReportManager.create, 'deps': [ReportServiceClient, HttpClient, AppConfig]},
    {'provide': RedFlagShutDownManager, 'useFactory': RedFlagShutDownManager.create, 'deps': [OffboardingServiceClient]},
    {'provide': RoaringManager, 'useFactory': RoaringManager.create, 'deps': [RoaringServiceClient]},
    {'provide': ShareholderManager, 'useFactory': ShareholderManager.create, 'deps': [ShareholderServiceClient]},
    {'provide': UserManager, 'useFactory': UserManager.create, 'deps': [Store, DirectoryServiceClient]},
    {'provide': FinancialStatementManager, 'useFactory': FinancialStatementManager.create, 'deps': [SafeSpendServiceClient, WithdrawalServiceClient]},
    {'provide': CardManager, 'useFactory': CardManager.create, 'deps': [CardServiceClient]},
    {'provide': SignatureManager, 'useFactory': SignatureManager.create, 'deps': [SignatureServiceClient]},
    {'provide': TransactionManager, 'useFactory': TransactionManager.create, 'deps': [TransactionServiceClient]},
    // Lab
    {'provide': NgHttpFetch, 'useFactory': NgHttpFetch.create, 'deps': [HttpClient]},
    {'provide': AnnualReportServiceClient, 'useFactory': AnnualReportServiceClient.create, 'deps': [NgHttpFetch, AppConfig]},
    {'provide': AssetServiceClient, 'useFactory': AssetServiceClient.create, 'deps': [NgHttpFetch, AppConfig]},
    {'provide': AuthorizationServiceClient, 'useFactory': AuthorizationServiceClient.create, 'deps': [NgHttpFetch, AppConfig]},
    {'provide': CardServiceClient, 'useFactory': CardServiceClient.create, 'deps': [NgHttpFetch, AppConfig]},
    {'provide': CardTransactionServiceClient, 'useFactory': CardTransactionServiceClient.create, 'deps': [NgHttpFetch, AppConfig]},
    {'provide': CommentServiceClient, 'useFactory': CommentServiceClient.create, 'deps': [NgHttpFetch, AppConfig]},
    {'provide': CustomerServiceClient, 'useFactory': CustomerServiceClient.create, 'deps': [NgHttpFetch, AppConfig]},
    {'provide': DirectoryServiceClient, 'useFactory': DirectoryServiceClient.create, 'deps': [NgHttpFetch, AppConfig]},
    {'provide': ForexServiceClient, 'useFactory': ForexServiceClient.create, 'deps': [NgHttpFetch, AppConfig]},
    {'provide': InvoiceServiceClient, 'useFactory': InvoiceServiceClient.create, 'deps': [NgHttpFetch, AppConfig]},
    {'provide': OnboardingServiceClient, 'useFactory': OnboardingServiceClient.create, 'deps': [NgHttpFetch, AppConfig]},
    {'provide': OffboardingServiceClient, 'useFactory': OffboardingServiceClient.create, 'deps': [NgHttpFetch, AppConfig]},
    {'provide': PaymentServiceClient, 'useFactory': PaymentServiceClient.create, 'deps': [NgHttpFetch, AppConfig]},
    {'provide': ReceiptServiceClient, 'useFactory': ReceiptServiceClient.create, 'deps': [NgHttpFetch, AppConfig]},
    {'provide': ReportServiceClient, 'useFactory': ReportServiceClient.create, 'deps': [NgHttpFetch, AppConfig]},
    {'provide': RoaringServiceClient, 'useFactory': RoaringServiceClient.create, 'deps': [NgHttpFetch, AppConfig]},
    {'provide': RoyaltyServiceClient, 'useFactory': RoyaltyServiceClient.create, 'deps': [NgHttpFetch, AppConfig]},
    {'provide': SafeSpendServiceClient, 'useFactory': SafeSpendServiceClient.create, 'deps': [NgHttpFetch, AppConfig]},
    {'provide': ShareholderServiceClient, 'useFactory': ShareholderServiceClient.create, 'deps': [NgHttpFetch, AppConfig]},
    {'provide': SieServiceClient, 'useFactory': SieServiceClient.create, 'deps': [NgHttpFetch, AppConfig]},
    {'provide': TransactionServiceClient, 'useFactory': TransactionServiceClient.create, 'deps': [NgHttpFetch, AppConfig]},
    {'provide': WithdrawalServiceClient, 'useFactory': WithdrawalServiceClient.create, 'deps': [NgHttpFetch, AppConfig]},
    {'provide': YearEndServiceClient, 'useFactory': YearEndServiceClient.create, 'deps': [NgHttpFetch, AppConfig]},
    {'provide': SignatureServiceClient, 'useFactory': SignatureServiceClient.create, 'deps': [NgHttpFetch, AppConfig]},
];

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        HttpClientModule,
        MatMomentDateModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        MatMenuModule,
        MatSnackBarModule,
        RedDialogModule,
        ConfirmDialogModule,
        RedTopLevelMenuModule,
        ReauthorizeModule,
        AnnouncementModule,
        NewVersionModule,
        ViewsModule,
        LoginViewModule,
        OnboardingViewModule,
        OnboardingSignUpModule,
        RedMarkdownModule,
        RedDialogModule,
        NgxsModule.forRoot(
            [AuthState, OnboardingState, RememberMeState, AppInfoState, FinancialStatementState, CardState, SignatureState],
            { developmentMode: !environment.production }),
        NgxsStoragePluginModule.forRoot({
            key: ['auth', 'remember_me', 'app_info']
        }),
        APP_ROUTING
    ],
    providers: [
        ...APP_PROVIDERS
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
