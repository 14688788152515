import {COMPANY_TYPE, ROLE} from '@red/data';
import {AUTH_LOGIN_STATUS} from './auth.model';
import {RemoteErrorException} from '../../../common/error/remote-error-exception';

export class AuthLogin {
    static readonly type = '[Auth] Login';
    constructor(public payload: { identifier: string; scope: ROLE, rememberMe?: boolean }) {}
}
export class AuthSignUp {
    static readonly type = '[Auth] SingUp';
    constructor(public payload: { identifier: string; }) {}
}

export class AuthCollect {
    static readonly type = '[Auth] Collect';
    constructor(public payload: {autostartToken?: string, authReference: string }) {}
}

export class AuthSignUpCollect {
    static readonly type = '[Auth] SignUpCollect';
    constructor(public payload: {autostartToken?: string, authReference: string }) {}
}

export class AuthMe {
    static readonly type = '[Auth] Me';
    constructor() {}
}

export class AuthSignUpMe {
    static readonly type = '[Auth] SignUpMe';
    constructor() {}
}

export class AuthSignConsentCount {
    static readonly type = '[Auth] Sign consent count';
    constructor(public payload: {count: number}) {}
}

export class AuthLogout {
    static readonly type = '[Auth] Logout';
}

export class AuthLoginStatus {
    static readonly type = '[Auth] Login Status';
    constructor(public status: AUTH_LOGIN_STATUS, public bankdIdStatus?: string, public error?: RemoteErrorException) {}
}

export class AuthSetActiveCompany {
    static readonly type = '[Auth] Switch Company';
    constructor(public payload: {companyName: string, companyId: string, companyType: COMPANY_TYPE}) {}
}
