import {Component, forwardRef, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {
    OnboardingApplication, OnboardingApplicationDataLlcNewSkvForm
} from '@red/onboarding-service-api';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Actions, Store} from '@ngxs/store';
import {OnboardingApplicationBaseDirective} from '../onboarding-application-base';
import {SniHelpers} from '../../../sni-codes/sni-helpers';
import {OnboardingManager} from '../../../../managers/onboarding/onboarding-manager';

@Component({
    selector: 'app-onboarding-application-llc-new-skv-form',
    styleUrls: ['../onboarding-applications.sass'],
    templateUrl: 'onboarding-application-llc-new-skv-form.tpl.html',
    providers: [
        {provide: OnboardingApplicationBaseDirective, useExisting: forwardRef(() => OnboardingApplicationLlcNewSkvFormComponent )}
    ]
})
export class OnboardingApplicationLlcNewSkvFormComponent extends OnboardingApplicationBaseDirective implements OnInit, OnChanges {
    get form(): FormGroup { return this._form; }

    private _formBuilder: FormBuilder;
    private _sniHelpers: SniHelpers;

    constructor(
        formBuilder: FormBuilder,
        actions: Actions,
        store: Store,
        sniHelpers: SniHelpers,
        onboardingManager: OnboardingManager
    ) {
        super(actions, store, onboardingManager);
        this._formBuilder = formBuilder;
        this._sniHelpers = sniHelpers;
    }

    ngOnInit() {
        super.ngOnInit();
        const data = (this.application.data as OnboardingApplicationDataLlcNewSkvForm);

        this._createForm(data);
        this._form.patchValue(data);
        this._toggleFormDisabled();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this._form) {
            this._toggleFormDisabled();
        }
    }

    save() {
        const application = this._applicationFromForm();
        this._updateApplication(application);
    }

    saveAndSend() {
        const application = this._applicationFromForm();
        this._updateApplicationAndSend(application);
    }

    private _createForm(data: OnboardingApplicationDataLlcNewSkvForm) {
        let businessOperationCodes = [this._sniHelpers.emptySniCode()];

        if (data && data.businessOperationCodes && data.businessOperationCodes.length > 0) {
            businessOperationCodes = data.businessOperationCodes.map(() => this._sniHelpers.emptySniCode());
        }

        this._form = this._formBuilder.group({
            companyDescription: ['', Validators.required],
            businessOperationCodes: this._formBuilder.array(businessOperationCodes)
        });
    }

    private _applicationFromForm() {
        const application = new OnboardingApplication(this.application);
        application.data = new OnboardingApplicationDataLlcNewSkvForm(this._form.getRawValue());

        return application;
    }
}
