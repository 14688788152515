import {Routes} from '@angular/router';
import {CardTransactionViewComponent} from './card-transaction';
import {ValidSessionGuard} from '../../security/route/session';
import {CardTransactionListViewComponent} from './list/list';
import {CardTransactionEditViewComponent} from './edit/edit';
import {CardTransactionRouteResolve} from './card-transaction.route.resolve';
import {ConsentGuard} from '../../security/route/consent';
import {CardGuard} from '../../security/route/card';
import {FinancialStatementCompanyOwedRouteResolve} from '../../shared/route/financial-statement-company-owed-route-resolve';

export const CARD_TRANSACTION_ROUTES: Routes = [
    {
        path: 'card-transaction',
        component: CardTransactionViewComponent,
        canActivate: [ValidSessionGuard, ConsentGuard, CardGuard],
        children: [
            {
                'path': '',
                'component': CardTransactionListViewComponent,
                'children': [
                    {'path': ':id/edit', 'component': CardTransactionEditViewComponent, resolve: {'cardTransaction': CardTransactionRouteResolve, owed: FinancialStatementCompanyOwedRouteResolve} },
                ]
            }
        ]
    }
];
