import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Actions, ofActionDispatched, Store} from '@ngxs/store';
import {DIALOG_RESULT, RedNotification} from '@red/components';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {AuthLogin, AuthLoginStatus, AuthLogout} from '../../shared/state/auth/auth.actions';
import {COUNTRY, ROLE} from '@red/data';
import {AuthState} from '../../shared/state/auth/auth.state';
import {NationalIdentifierValidator} from '@red/validators';
import {AUTH_LOGIN_STATUS} from '../../shared/state/auth/auth.model';
import {BankIdManager} from '../../managers/bankid/bank-id-manager';
import {RememberMeState} from '../../shared/state/remember-me/remember-me.state';
import {RemoteErrorException} from '../../common/error/remote-error-exception';

enum REAUTHORIZE_STATE {
    IDLE = <any>`IDLE`,
    WAITING_FOR_BANKID = <any>`WAITING_FOR_BANKID`,
    USER_SIGN = <any>`USER_SIGN`
}

@Component({
    selector: 'app-reauthorize-dialog',
    styleUrls: ['reauthorize-dialog.sass'],
    templateUrl: 'reauthorize-dialog.tpl.html'
})
export class ReauthorizeDialogComponent implements OnInit {
    get state(): REAUTHORIZE_STATE { return this._state; }
    get loginForm(): FormGroup { return this._loginForm; }
    get bankIdUrl(): SafeUrl { return this._bankIdUrl; }
    get canOpenOnSameDevice(): boolean { return BankIdManager.canOpenOnSameDevice(); }

    private _loginForm: FormGroup;
    private _formBuilder: FormBuilder;
    private _state = REAUTHORIZE_STATE.IDLE;
    private _store: Store;
    private _actions: Actions;
    private _notification: RedNotification;
    private _bankIdUrl: SafeUrl;
    private _sanitizer: DomSanitizer;
    private _dialogRef: MatDialogRef<ReauthorizeDialogComponent>;

    constructor(
        formBuilder: FormBuilder,
        store: Store,
        actions: Actions,
        notification: RedNotification,
        sanitizer: DomSanitizer,
        dialogRef: MatDialogRef<ReauthorizeDialogComponent>
    ) {
        this._formBuilder = formBuilder;
        this._store = store;
        this._actions = actions;
        this._notification = notification;
        this._sanitizer = sanitizer;
        this._dialogRef = dialogRef;
    }

    ngOnInit(): void {
        this._setForm();

        this._actions
            .pipe(ofActionDispatched(AuthLoginStatus))
            .subscribe((action: AuthLoginStatus) => {
                this._setLoginState(action);
            });
    }

    login($event: Event) {
        $event.stopPropagation();

        this._state = REAUTHORIZE_STATE.WAITING_FOR_BANKID;

        const {identifier} = this._loginForm.value;

        this._store.dispatch(new AuthLogin({
            identifier: identifier,
            scope: ROLE.OWNER
        }));
    }

    logout() {
        this._store.dispatch(new AuthLogout());
        this._dialogRef.close(DIALOG_RESULT.CANCEL);
    }

    private _setForm() {
        const rememberMe = !!this._store.selectSnapshot(RememberMeState.rememberMe);
        let identifier = '';

        if (rememberMe) {
            identifier = this._store.selectSnapshot(RememberMeState.identifier) || '';
        }

        this._loginForm = this._formBuilder.group({
            identifier: [identifier]
        });

        this._setValidators();
    }

    private _setValidators() {
        this._loginForm.get('identifier').setValidators([
            Validators.required,
            (ctrl: AbstractControl) => {
                return NationalIdentifierValidator.validate(ctrl.value, COUNTRY.SWEDEN)
                    ? null
                    : { 'nationalIdentifierError': true };
            }
        ]);
    }

    private _setBankIdUrl(autostartToken: string) {
        this._bankIdUrl = this._sanitizer.bypassSecurityTrustUrl(`bankid:///?autostarttoken=${autostartToken}&redirect=null`);
    }

    private _displayErrorRedNotification(error: RemoteErrorException) {
        let msg = $localize`:Login|Login error (fallback):Unknown error!`;
        if (error && error.errorCode === 1270001) {
            msg = $localize`:Error when logging in without a active company|User cant\'t login due to no companies.:There\'s no account matching you identifier.`;
        }

        this._notification.errorWithCode(error, msg);
        this._state = REAUTHORIZE_STATE.IDLE;
    }

    private _setLoginState(action: AuthLoginStatus) {
        switch (action.status) {
            case AUTH_LOGIN_STATUS.STARTED:
                const autostartToken = this._store.selectSnapshot(AuthState.autostartToken);

                this._setBankIdUrl(autostartToken);
                break;
            case AUTH_LOGIN_STATUS.COLLECTING:
                this._state = REAUTHORIZE_STATE.WAITING_FOR_BANKID;
                if (action.bankdIdStatus === 'USER_SIGN') {
                    this._state = REAUTHORIZE_STATE.USER_SIGN;
                }
                break;
            case AUTH_LOGIN_STATUS.ERROR:
                this._displayErrorRedNotification(action.error);
                break;
            case AUTH_LOGIN_STATUS.ONBOARDING:
            case AUTH_LOGIN_STATUS.COMPLETE:
                this._close();
                break;
            default:
                this._state = REAUTHORIZE_STATE.IDLE;
        }
    }

    private _close() {
        this._dialogRef.close(DIALOG_RESULT.OK);
    }
}
