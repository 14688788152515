import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {FINANCIAL_STATEMENT_STATE_TOKEN} from './financial-statement.token';
import {FINANCIAL_STATEMENT_STATUS, FinancialStatementModel} from './financial-statement.model';
import {Amount, Projection} from '@red/data';
import {Observable} from 'rxjs';
import {FinancialStatementLoadCompanyOwed, FinancialStatementLoadProjection} from './financial-statement.actions';
import {FinancialStatementManager} from '../../../managers/financial-statement/financial-statement-manager';
import {catchError, tap} from 'rxjs/operators';
import {of} from 'rxjs';


@State<FinancialStatementModel>({
    name: FINANCIAL_STATEMENT_STATE_TOKEN,
    defaults: {
        balance: null,
        startingBalance: null,
        reservedBalance: null,
        vatBalance: null,
        shareholderCapital: null,
        accruedTax: null,
        limit: null,
        companyOwed: null,
        projectionStatus: FINANCIAL_STATEMENT_STATUS.INIT,
        companyOwedStatus: FINANCIAL_STATEMENT_STATUS.INIT,
    }
})
@Injectable()
export class FinancialStatementState {
    private _financialStatementManager: FinancialStatementManager;

    constructor(
        financialStatementManager: FinancialStatementManager
    ) {
        this._financialStatementManager = financialStatementManager;
    }

    @Selector()
    static balance(state: FinancialStatementModel): Amount | null {
        return state.balance;
    }

    @Selector()
    static startingBalance(state: FinancialStatementModel): Amount | null {
        return state.startingBalance;
    }

    @Selector()
    static reservedBalance(state: FinancialStatementModel): Amount | null {
        return state.reservedBalance;
    }

    @Selector()
    static vatBalance(state: FinancialStatementModel): Amount | null {
        return state.vatBalance;
    }

    @Selector()
    static shareholderCapital(state: FinancialStatementModel): Amount | null {
        return state.shareholderCapital;
    }

    @Selector()
    static accruedTax(state: FinancialStatementModel): Amount | null {
        return state.accruedTax;
    }

    @Selector()
    static limit(state: FinancialStatementModel): Amount | null {
        return state.limit;
    }

    @Selector()
    static companyOwed(state: FinancialStatementModel): Amount | null {
        return state.companyOwed;
    }

    @Selector()
    static projectionStatus(state: FinancialStatementModel): FINANCIAL_STATEMENT_STATUS {
        return state.projectionStatus;
    }

    @Selector()
    static companyOwedStatus(state: FinancialStatementModel): FINANCIAL_STATEMENT_STATUS {
        return state.companyOwedStatus;
    }

    @Action(FinancialStatementLoadProjection)
    loadProjection(ctx: StateContext<FinancialStatementModel>, action: FinancialStatementLoadProjection): Observable<any> {
        ctx.patchState({
            projectionStatus: FINANCIAL_STATEMENT_STATUS.STARTED
        });

        return this._financialStatementManager.loadProjection(action.payload.companyId)
            .pipe(
                tap((projection: Projection) => {
                    ctx.patchState({
                        balance: projection.summary.balance,
                        startingBalance: projection.summary.startingBalance,
                        reservedBalance: projection.summary.reservedBalance,
                        vatBalance: projection.summary.vatBalance,
                        shareholderCapital: projection.summary.shareholderCapital,
                        accruedTax: projection.summary.accruedTax,
                        limit: projection.limit,
                        projectionStatus: FINANCIAL_STATEMENT_STATUS.COMPLETE
                    });
                }),
                catchError((err) => {
                    ctx.patchState({
                        balance: null,
                        startingBalance: null,
                        reservedBalance: null,
                        vatBalance: null,
                        shareholderCapital: null,
                        accruedTax: null,
                        limit: null,
                        projectionStatus: FINANCIAL_STATEMENT_STATUS.ERROR
                    });

                    return of(null);
                })
            );
    }

    @Action(FinancialStatementLoadCompanyOwed)
    getCompanyOwed(ctx: StateContext<FinancialStatementModel>, action: FinancialStatementLoadCompanyOwed): Observable<any> {
        ctx.patchState({
            companyOwedStatus: FINANCIAL_STATEMENT_STATUS.STARTED
        });

        return this._financialStatementManager.getCompanyOwedAmount(action.payload.companyId)
            .pipe(
                tap((amount: Amount) => {
                    ctx.patchState({
                        companyOwed: amount,
                        companyOwedStatus: FINANCIAL_STATEMENT_STATUS.COMPLETE
                    });
                }),
                catchError((err) => {
                    ctx.patchState({
                        companyOwed: null,
                        companyOwedStatus: FINANCIAL_STATEMENT_STATUS.ERROR
                    });

                    return of(null);
                })
            );
    }
}
