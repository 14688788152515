// This is a file generated by the system. It can (and will) be overwritten or changed at any time.

export const appConfig = {
    'debug': true,
    'app': {
        'name': 'company',
        'version': '3.0.0',
        'build': '278',
        'platform': 'web'
    },
    'adWords': {},
    'analytics': {
        'id': 'UA-86800234-6'
    },
    'icons': {
        'list-alert': '/assets/icon/iconlistalert.svg',
        'list-card': '/assets/icon/iconlistcard.svg',
        'list-card-upload': '/assets/icon/iconlistcardupload.svg',
        'list-dividends': '/assets/icon/iconlistloanpay.svg',
        'list-upload-camera': '/assets/icon/iconlistuploadcamera.svg',
        'list-invoice': '/assets/icon/iconlistinvoice.svg',
        'list-invoice-envelope': '/assets/icon/envelope.svg',
        'list-loan': '/assets/icon/iconlistwithdraw.svg',
        'list-owner_withdrawal': '/assets/icon/iconlistloanpay.svg',
        'list-loan-pay': '/assets/icon/iconlistloanpay.svg',
        'list-salary': '/assets/icon/iconlistsalary.svg',
        'list-bill': '/assets/icon/iconlistpayment.svg',
        'list-payment': '/assets/icon/iconlistpayment.svg',
        'list-owner_deposit': '/assets/icon/iconlistloan.svg',
        'list-receipt': '/assets/icon/iconlistreceipt.svg',
        'list-receipt-alert': '/assets/icon/iconlistreceiptalert.svg',
        'list-receipt-add': '/assets/icon/iconlistreceiptadd.svg',
        'list-report': '/assets/icon/iconlistreports.svg',
        'list-search-company': '/assets/icon/iconCustomerCompany.svg',
        'list-search-individual': '/assets/icon/iconCustomerPrivate.svg',
        'list-card-unmatched': '/assets/icon/iconlistcardunmatched.svg',
        'list-value-code': '/assets/icon/iconlistvaluecode.svg',
        'list-value-code-inactive': '/assets/icon/iconlistvaluecodeinactive.svg',
        'list-tax-payment': '/assets/icon/icontaxpayment.svg',
        'list-history': '/assets/icon/iconlisthistory.svg',
        'list-employee': '/assets/icon/iconlistemployee.svg',
        'list-income-stream': '/assets/icon/iconlistrevenuestream.svg',
        'list-bank-id-not-signed': '/assets/icon/iconlistbankidnotsigned.svg',
        'list-bank-id-signed': '/assets/icon/iconlistbankidsigned.svg',
        'list-car-benefit': '/assets/icon/iconCarBenefit.svg ',
        'menu-dashboard': '/assets/icon/iconmenudashboard.svg',
        'menu-dividends': '/assets/icon/iconmenuwithdrawal.svg',
        'menu-history': '/assets/icon/iconmenuhistory.svg',
        'menu-inventory': '/assets/icon/iconmenuinventory.svg',
        'menu-invoices': '/assets/icon/iconmenuinvoices.svg',
        'menu-invoices-alt': '/assets/icon/iconmenuinvoicesalt.svg',
        'menu-loans': '/assets/icon/iconmenuloans.svg',
        'menu-loanspay': '/assets/icon/iconmenuloanspay.svg',
        'menu-payments': '/assets/icon/iconmenupayments.svg',
        'menu-payment': '/assets/icon/iconmenupayments.svg',
        'menu-receipts': '/assets/icon/iconmenureceipts.svg',
        'menu-receipt': '/assets/icon/iconmenureceipts.svg',
        'menu-report': '/assets/icon/iconmenureports4.svg',
        'menu-safe': '/assets/icon/iconmenusafe.svg',
        'menu-salary': '/assets/icon/iconmenusalary.svg',
        'menu-settings': '/assets/icon/iconmenusettings.svg',
        'menu-support': '/assets/icon/iconmenusupport.svg',
        'menu-card': '/assets/icon/iconmenucard.svg',
        'menu-logout': '/assets/icon/iconmenulogout.svg',
        'menu-income-stream': '/assets/icon/iconmenurevenuestreams.svg',
        'menu-employee': '/assets/icon/iconmenuemployee.svg',
        'menu-withdrawal': '/assets/icon/iconmenuwithdrawal.svg',
        'menu-car-benefit': '/assets/icon/iconMenuCarBenefit.svg',
        'ui-add': '/assets/icon/iconuiadd.svg',
        'ui-alert-red': '/assets/icon/iconlistalertred.svg',
        'ui-card-mark-debt': '/assets/icon/iconcardmarkdebt.svg',
        'ui-chevron-left': '/assets/icon/iconuichevronleft.svg',
        'ui-chevron-right': '/assets/icon/iconuichevronright.svg',
        'ui-close': '/assets/icon/iconuiclose.svg',
        'ui-close-white': '/assets/icon/iconuiclosewhite.svg',
        'ui-context-menu': '/assets/icon/iconuicontextmenu.svg',
        'ui-download': '/assets/icon/icondownload.svg',
        'ui-download-white': '/assets/icon/icondownloadwhite.svg',
        'ui-info': '/assets/icon/iconuiinfo.svg',
        'ui-info-slate': '/assets/icon/iconinfo.svg',
        'ui-flag': '/assets/icon/iconuiflag.svg',
        'ui-flag-slate': '/assets/icon/iconuiflagslate.svg',
        'ui-happy': '/assets/icon/happy.svg',
        'ui-help-flag': '/assets/icon/helpflag.svg',
        'ui-help-flag-raised': '/assets/icon/helpflag-raised.svg',
        'ui-keyboard-backspace': '/assets/icon/iconuikeyboardbackspace.svg',
        'ui-keyboard-backspace-white': '/assets/icon/backwhite.svg',
        'ui-pdf': '/assets/icon/iconpdf.svg',
        'ui-pdf-transparent': '/assets/icon/iconpdf-transparent.svg',
        'ui-problem': '/assets/icon/iconuiproblem.svg',
        'ui-problem-white': '/assets/icon/iconuiproblemwhite.svg',
        'ui-search': '/assets/icon/iconuisearch.svg',
        'ui-search-white': '/assets/icon/iconuisearchwhite.svg',
        'ui-warning': '/assets/icon/iconuiwarning.svg',
        'ui-upload-img': '/assets/icon/iconuploadimg.svg',
        'ui-zoom': '/assets/icon/iconzoom.svg',
        'bankid': '/assets/icon/bankid.svg',
        'info': '/assets/icon/Info.svg'
    },
    'intercom': {
        'appId': 'edzfabsf'
    },
    'moment': {
        'defaultFormat': 'D MMM'
    },
    'notification': {
        'duration': 5000
    },
    'versionChecker': {
        'url': 'https://api2.test.redflag.se/api/client/info'
    },
    'baseApiUrl': 'https://api2.test.redflag.se',
    'resources': {
        'auth': {
            'baseUrl': 'https://api2.test.redflag.se',
            'endpoints': {
                'authenticate': '/api/authenticate',
                'sessionToken': '/api/authenticate/{authReference}'
            }
        }
    },
    'topLevelMenu': {
        'sections': [
            {
                'items': [
                    {
                        'name': 'Starta Nytt Företag',
                        'route': 'onboarding',
                        'icon': 'menu-dashboard'
                    },
                    {
                        'name': 'Översikt',
                        'route': 'dashboard',
                        'icon': 'menu-dashboard'
                    },
                    {
                        'name': 'Rapporter',
                        'route': 'downloadable-reports',
                        'icon': 'menu-report'
                    },
                    {
                        'name': 'Årsbokslut',
                        'route': 'reports',
                        'icon': 'menu-report'
                    },
                    {
                        'name': 'Inställningar',
                        'route': 'settings',
                        'icon': 'menu-settings'
                    }
                ]
            }
        ]
    },
    'content': {
        'card': {
            'asset_upload': {
                'sv': '/assets/content/card/card.asset.upload.sv.md',
                'en': '/assets/content/card/card.asset.upload.sv.md'
            },
            'pin_info': {
                'sv': '/assets/content/card/card.pin.info.sv.md',
                'en': '/assets/content/card/card.pin.info.sv.md'
            },
            'send_info': {
                'sv': '/assets/content/card/card.send.info.sv.md',
                'en': '/assets/content/card/card.send.info.sv.md'
            },
            'stolen_info': {
                'sv': '/assets/content/card/card.stolen.info.sv.md',
                'en': '/assets/content/card/card.stolen.info.sv.md'
            }
        },
        'faq': {
            'sv': '/assets/content/faq/faq.sv.md',
            'en': '/assets/content/faq/faq.sv.md'
        },
        'bill': {
            'asset_upload': {
                'sv': '/assets/content/bill/bill.asset.upload.sv.md',
                'en': '/assets/content/bill/bill.asset.upload.sv.md'
            },
            'info': {
                'sv': '/assets/content/bill/bill.info.sv.md',
                'en': '/assets/content/bill/bill.info.sv.md'
            }
        },
        'invoice': {
            'download': {
                'sv': '/assets/content/invoice/invoice.download.sv.md',
                'en': '/assets/content/invoice/invoice.download.sv.md'
            }
        },
        'receipt': {
            'asset_upload': {
                'sv': '/assets/content/receipt/receipt.asset.upload.sv.md',
                'en': '/assets/content/receipt/receipt.asset.upload.sv.md'
            },
            'info': {
                'sv': '/assets/content/receipt/receipt.info.sv.md',
                'en': '/assets/content/receipt/receipt.info.sv.md'
            },
            'reject': {
                'sv': '/assets/content/receipt/receipt.reject.sv.md',
                'en': '/assets/content/receipt/receipt.reject.sv.md'
            }
        },
        'welcome': {
            'verify_user': {
                'sv': '/assets/content/welcome/welcome.sv.md',
                'en': '/assets/content/welcome/welcome.sv.md'
            },
            'initial_checks': {
                'sv': '/assets/content/welcome/welcome.sv.md',
                'en': '/assets/content/welcome/welcome.sv.md'
            },
            'initial_settlement_and_asset_upload': {
                'sv': '/assets/content/welcome/welcome.initial.settlement.sv.md',
                'en': '/assets/content/welcome/welcome.initial.settlement.sv.md'
            },
            'asset_uploading_done': {
                'sv': '/assets/content/welcome/welcome.asset.uploading.sv.md',
                'en': '/assets/content/welcome/welcome.asset.uploading.sv.md'
            },
            'final_settlement': {
                'sv': '/assets/content/welcome/welcome.final.settlement.sv.md',
                'en': '/assets/content/welcome/welcome.final.settlement.sv.md'
            },
            'ready_to_launch': {
                'sv': '/assets/content/welcome/welcome.ready.to.launch.sv.md',
                'en': '/assets/content/welcome/welcome.ready.to.launch.sv.md'
            }
        },
        'withdrawal': {
            'vatBalance': {
                'sv': '/assets/content/withdrawal/vatBalance.sv.md',
                'en': '/assets/content/withdrawal/vatBalance.sv.md'
            },
            'payrollTax': {
                'sv': '/assets/content/withdrawal/payrollTax.sv.md',
                'en': '/assets/content/withdrawal/payrollTax.sv.md'
            },
            'incomeTax': {
                'sv': '/assets/content/withdrawal/incomeTax.sv.md',
                'en': '/assets/content/withdrawal/incomeTax.sv.md'
            },
            'selfEmploymentContribution': {
                'sv': '/assets/content/withdrawal/selfEmploymentContribution.sv.md',
                'en': '/assets/content/withdrawal/selfEmploymentContribution.sv.md'
            }
        },
        'card-transaction': {
            'refund': {
                'sv': '/assets/content/card-transaction/refund.sv.md',
                'en': '/assets/content/card-transaction/refund.sv.md'
            }
        }
    },
    'features': {
        'multipleCompanies': true,
        'signUp': true,
        'firstEmployeeTaxRelief': true,
        'royaltyStatement': true
    }
};
