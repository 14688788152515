import {Component, HostBinding, ViewEncapsulation} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'app-terms-dialog',
    templateUrl: './terms-dialog.component.html',
    styleUrls: ['./terms-dialog.component.sass']
})
export class TermsDialogComponent {
    @HostBinding('class.terms-dialog') cssClass = true;

    private _dialogRef: MatDialogRef<TermsDialogComponent>;

    constructor(
        dialogRef: MatDialogRef<TermsDialogComponent>,
    ) {
        this._dialogRef = dialogRef;
    }

    close() {
        this._dialogRef.close();
    }
}
