import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {TransportOptions, HTTP_METHODS, Transport} from '../../../../tmp-red-client/transport/base';

export interface HttpClientOptions {
    methods?: string;
    body?: any;
    headers?: HttpHeaders;
    responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
    reportProgress?: boolean;
    withCredentials?: boolean;
    observe?: 'body' | 'response' | 'events';
}

export class HttpTransportOptions extends TransportOptions {
    get reportProgress(): boolean { return this._reportProgress; }
    get responseType() { return this._responseType; }
    get withCredentials(): boolean { return this._withCredentials; }
    get observe() { return this._observe; }

    private _responseType: 'arraybuffer' | 'blob' | 'json' | 'text';
    private _reportProgress: boolean;
    private _withCredentials: boolean;
    private _observe: 'body' | 'response' | 'events';

    constructor(
        method: HTTP_METHODS,
        url: string,
        body?: any,
        headers?: any,
        options?: HttpClientOptions
    ) {
        super(method, url, body, headers);

        if (options) {
            this._responseType = options.responseType;
            this._reportProgress = options.reportProgress;
            this._withCredentials = options.withCredentials;
            this._observe = options.observe;
        }
    }
}

export class HttpTransport extends Transport<Object> {
    private _http: HttpClient;

    constructor (
        http: HttpClient
    ) {
        super();
        this._http = http;
    }

    delete(url: string, options?: HttpTransportOptions): Observable<Object> {
        const opt = options || new HttpTransportOptions(HTTP_METHODS.Delete, url);
        return this.request(opt);
    }

    get(url: string, options?: HttpTransportOptions): Observable<Object> {
        const opt = options || new HttpTransportOptions(HTTP_METHODS.Get, url);

        return this.request(opt);
    }

    head(url: string, options?: HttpTransportOptions): Observable<Object> {
        const opt = options || new HttpTransportOptions(HTTP_METHODS.Head, url);
        return this.request(opt);
    }

    post(url: string, body: any, options?: HttpTransportOptions): Observable<Object> {
        const opt = options || new HttpTransportOptions(HTTP_METHODS.Post, url, body);
        return this.request(opt);
    }

    put(url: string, body?: any, options?: HttpTransportOptions): Observable<Object> {
        const opt = options || new HttpTransportOptions(HTTP_METHODS.Put, url, body);
        return this.request(opt);
    }

    request(options: HttpTransportOptions): Observable<Object> {
        const headers = new HttpHeaders(options.headers);
        const opt: HttpClientOptions = {'body': options.body, 'headers': headers};
        const method = this._methodAsString(options.method);

        this._setHeaders(opt);
        this._setOptions(opt, options);

        return this._http.request(method, options.url, opt);
    }

    private _setHeaders(opt) {
        if (!opt.headers.get('Content-Type')) {
            opt.headers = opt.headers.set('Content-Type', 'application/json; charset=utf-8');
        }

        if (opt.headers.get('Content-Type') === 'multipart/form-data') {
            opt.headers = opt.headers.delete('Content-Type');
        }
    }

    private _setOptions(opt, options) {
        const opts = ['reportProgress', 'responseType', 'withCredentials', 'observe'];

        opts.forEach(optionName => {
            if (options[optionName]) {
                opt[optionName] = options[optionName];
            }
        });
    }

    private _methodAsString(method: HTTP_METHODS): string {
        let m = 'GET';

        switch (method) {
            case HTTP_METHODS.Delete:
                m = 'DELETE';
                break;
            case HTTP_METHODS.Get:
                m = 'GET';
                break;
            case HTTP_METHODS.Head:
                m = 'HEAD';
                break;
            case HTTP_METHODS.Options:
                m = 'OPTIONS';
                break;
            case HTTP_METHODS.Patch:
                m = 'PATCH';
                break;
            case HTTP_METHODS.Post:
                m = 'POST';
                break;
            case HTTP_METHODS.Put:
                m = 'PUT';
                break;
            default:
                break;
        }

        return m;
    }
}
