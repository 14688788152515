import {WebServiceClient} from './web-service-client';
import {HttpFetch} from '@red/common';
import {AppConfig} from '../../config/appConfig';
import {Observable} from 'rxjs';
import {
    CustomerSearchResult,
    CustomerSearchResultListResponse,
} from '@red/data';

export class RoaringServiceClient extends WebServiceClient {
    static create(transport: HttpFetch, config: AppConfig) {
        return new RoaringServiceClient(transport, config);
    }

    search(query: string): Observable<CustomerSearchResultListResponse> {
        const url = `${this._getBaseUrl()}/api/roaring/company/search?query=${encodeURIComponent(query)}`;

        return this._get(url, {}, CustomerSearchResultListResponse);
    }

    getCompanyDetails(roaringCompanyId: string): Observable<CustomerSearchResult> {
        const url = `${this._getBaseUrl()}/api/roaring/company/${roaringCompanyId}`;

        return this._get(url, {}, CustomerSearchResult);
    }

    getIndividualDetails(personnummer: string): Observable<CustomerSearchResult> {
        const url = `${this._getBaseUrl()}/api/roaring/individual/${personnummer}`;

        return this._get(url, {}, CustomerSearchResult);
    }

}
