import {AppConfig} from '../../config/appConfig';
import {Observable} from 'rxjs';
import {CreateCreditInvoiceRequest, Invoice, InvoiceListResponse, UpdateInvoiceRequest} from '@red/data';
import {QueryFilter} from '@red/browser';
import {WebServiceClient} from './web-service-client';
import {HttpFetch} from '@red/common';
import {QueryParam} from '../../common/query-param';

export class InvoiceServiceClient extends WebServiceClient {
    static create(httpFetch: HttpFetch, config: AppConfig) {
        return new InvoiceServiceClient(httpFetch, config);
    }

    abandonInvoice(invoiceId: string): Observable<Invoice> {
        const url = `${this._getBaseUrl()}/api/invoice/${invoiceId}/payment/abandon`;
        return this._put<Invoice>(url, null, {}, Invoice);
    }

    createInvoice(companyId: string, customerId: string): Observable<Invoice> {
        const url = `${this._getBaseUrl()}/api/${companyId}/invoice?customerId=${customerId}`;
        return this._post<Invoice>(url, null, {}, Invoice);
    }

    creditInvoice(companyId: string, invoiceId: string, request: CreateCreditInvoiceRequest): Observable<Invoice> {
        const url = `${this._getBaseUrl()}/api/${companyId}/invoice/${invoiceId}/credit`;
        return this._post<Invoice>(url, request, {}, Invoice);
    }

    deleteInvoice(companyId: string, invoiceId: string) {
        const url = `${this._getBaseUrl()}/api/${companyId}/invoice/${invoiceId}`;
        return this._delete(url, {});
    }

    getInvoice(invoiceId: string): Observable<Invoice> {
        const url = `${this._getBaseUrl()}/api/invoice/${invoiceId}`;
        return this._get<Invoice>(url, {}, Invoice);
    }

    listInvoices(companyId: string, filter?: QueryFilter): Observable<InvoiceListResponse> {
        let url = `${this._getBaseUrl()}/api/${companyId}/invoice`;

        if (filter) {
            url += '?' + QueryParam.asString(filter);
        }

        return this._get<InvoiceListResponse>(url, {}, InvoiceListResponse);
    }

    sendInvoice(companyId: string, invoiceId: string): Observable<Invoice> {
        const url = `${this._getBaseUrl()}/api/${companyId}/invoice/${invoiceId}/send`;
        return this._put<Invoice>(url, null, {}, Invoice);
    }

    updateCustomer(companyId: string, invoiceId: string, customerId: string): Observable<Invoice> {
        const url = `${this._getBaseUrl()}/api/${companyId}/invoice/${invoiceId}/customer/${customerId}`;
        return this._put<Invoice>(url, null, {}, Invoice);
    }

    updateInvoice(companyId: string, invoiceId: string, request: UpdateInvoiceRequest): Observable<Invoice> {
        const url = `${this._getBaseUrl()}/api/${companyId}/invoice/${invoiceId}`;
        return this._put<Invoice>(url, request, {}, Invoice);
    }
}
