import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DIALOG_RESULT} from '@red/components';

export interface AnnouncementDialogData {
    title: string;
    message: string;
}

@Component({
    selector: 'app-announcement-dialog',
    styleUrls: ['announcement-dialog.sass'],
    templateUrl: 'announcement-dialog.tpl.html'
})
export class AnnouncementDialogComponent {
    get message(): string { return this._message; }
    get title(): string { return this._title; }

    private _dialogRef: MatDialogRef<AnnouncementDialogComponent, DIALOG_RESULT>;
    private _title: string;
    private _message: string;

    constructor(
        dialogRef: MatDialogRef<AnnouncementDialogComponent, DIALOG_RESULT>,
        @Inject(MAT_DIALOG_DATA) data: AnnouncementDialogData
    ) {
        this._dialogRef = dialogRef;
        this._title = data.title;
        this._message = data.message;
    }

    cancel() {
        this._dialogRef.close(DIALOG_RESULT.CANCEL);
    }

    dismiss() {
        this._dialogRef.close(DIALOG_RESULT.OK);
    }
}
