<form [formGroup]="metadataFormInput" red-form>
    <div class="form-field">
        <h3 class="form-section-header" i18n="End of year|metadata form header">Todo</h3>
        <div class="form-row">
            <div class="form-col" i18n="End of year|form label|Card purchases to report">Card purchases to report</div>
            <div class="form-col right">{{nbrOfReceiptsToUpload}}</div>
        </div>
        <div class="form-row" [ngClass]="{'end-of-year-row-disabled': isWarmup || hasReceiptsToUpload}">
            <div class="form-col" i18n="End of year|form label|I have submitted all expenses">I have submitted all expenses for {{year}}</div>
            <div class="form-col right">
                <mat-checkbox
                    formControlName="doneUploadingReceipts"
                ></mat-checkbox>
            </div>
        </div>
    </div>

    <ng-container *ngIf="isSoleTrader">
        <app-end-of-year-other-income-form
            [metadataForm]="metadataFormInput"
        ></app-end-of-year-other-income-form>
    </ng-container>

    <div class="form-field" *ngIf="isSoleTrader">
        <h3 class="form-section-header" i18n="End of year|metadata form header|Benefits">Benefits</h3>

        <div class="info-text" i18n="End of year|about|Deductible mileage">Deductible mileage</div>
        <div class="form-row full-width-single-element-row">
            <mat-form-field class="right">
                <input
                    matInput
                    i18n-placeholder="End of year|input placeholder|deductibleMileage"
                    placeholder="deductibleMileage"
                    formControlName="deductibleMileage"
                    class="right"
                    type="number" />
            </mat-form-field>
        </div>
    </div>

    <div class="form-field">
        <h3 class="form-section-header" i18n="End of year|metadata form accrued income">Accrued Income</h3>
        <div class="info-text" i18n="End of year|info-text|Accrued income info text">
            Expenses and income that do not belong to the financial year must be accrued in the annual accounts if they exceed SEK 5,000.
        </div>
        <div class="accrued-income">
            <div class="form-row">
                <div class="form-col" i18n="End of year|form label|Accrued income invoice service not rendered">Have you sent an invoice during the financial year that you are closing for now, for services that have not been performed yet?</div>
                <div class="form-col right">
                    <mat-radio-group formControlName="accruedIncomeInvoiceServiceNotRendered" class="accrued-income-radio-group" labelPosition="before">
                        <mat-radio-button [value]="false" i18n="End of year|radio-button|metadata form accrued income">No</mat-radio-button>
                        <mat-radio-button [value]="true" i18n="End of year|radio-button|metadata form accrued income">Yes</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <div class="form-row full-width-single-element-row" *ngIf="isCheckedTrue('accruedIncomeInvoiceServiceNotRendered')">
                <mat-form-field formGroupName="accruedIncomeInvoiceServiceNotRenderedAmount">
                    <input
                        matInput
                        i18n-placeholder="End of year|form label|Accrued income invoice service not rendered amount"
                        placeholder="Amount excluding VAT"
                        formControlName="amount"
                        class="right"
                        type="number" />
                    <span matSuffix class="currency">kr</span>
                </mat-form-field>
            </div>
            <div class="form-row full-width-single-element-row" *ngIf="isCheckedTrue('accruedIncomeInvoiceServiceNotRendered')">
                <mat-form-field>
                    <input
                        matInput
                        i18n-placeholder="End of year|form label|Accrued income invoice service not rendered invoice numbers"
                        placeholder="Invoice numbers (comma-separated)"
                        formControlName="accruedIncomeInvoiceServiceNotRenderedInvoices"
                        class="right" />
                </mat-form-field>
            </div>
        </div>
        <div class="accrued-income">
        <div class="form-row">
                <div class="form-col" i18n="End of year|form label|Accrued income service rendered not invoiced">Have you performed any services during the financial year that you are closing for now, which you have invoiced for the next financial year?</div>
                <div class="form-col right">
                    <mat-radio-group formControlName="accruedIncomeServiceRenderedNotInvoiced" class="accrued-income-radio-group" labelPosition="before">
                        <mat-radio-button [value]="false" i18n="End of year|radio-button|metadata form accrued income">No</mat-radio-button>
                        <mat-radio-button [value]="true" i18n="End of year|radio-button|metadata form accrued income">Yes</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <div class="form-row full-width-single-element-row" *ngIf="isCheckedTrue('accruedIncomeServiceRenderedNotInvoiced')">
                <mat-form-field formGroupName="accruedIncomeServiceRenderedNotInvoicedAmount">
                    <input
                        matInput
                        i18n-placeholder="End of year|form label|Accrued income amount"
                        placeholder="Amount excluding VAT"
                        formControlName="amount"
                        class="right"
                        type="number" />
                    <span matSuffix class="currency">kr</span>
                </mat-form-field>
            </div>
            <div class="form-row full-width-single-element-row" *ngIf="isCheckedTrue('accruedIncomeServiceRenderedNotInvoiced')">
                <mat-form-field>
                    <input
                        matInput
                        i18n-placeholder="End of year|form label|Accrued income invoice numbers"
                        placeholder="Invoice numbers (comma-separated)"
                        formControlName="accruedIncomeServiceRenderedNotInvoicedInvoices"
                        class="right" />
                </mat-form-field>
            </div>
        </div>
        <div class="accrued-income">
            <div class="form-row">
                <div class="form-col" i18n="End of year|form label|Accrued income supplier service not rendered">Have you uploaded a receipt during the financial year that you are closing for now, on goods or services that have not been performed yet?</div>
                <div class="form-col right">
                    <mat-radio-group formControlName="accruedIncomeSupplierServiceNotRendered" class="accrued-income-radio-group" labelPosition="before">
                        <mat-radio-button [value]="false" i18n="End of year|radio-button|metadata form accrued income">No</mat-radio-button>
                        <mat-radio-button [value]="true" i18n="End of year|radio-button|metadata form accrued income">Yes</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <div class="form-row" *ngIf="isCheckedTrue('accruedIncomeSupplierServiceNotRendered') && !isSoleTrader">
                <div class="form-col" i18n="End of year|form label|Accrued income supplier service not rendered recurring">Is the amount a recurring annual cost such as insurance?</div>
                <div class="form-col right">
                    <mat-radio-group formControlName="accruedIncomeSupplierServiceNotRenderedRecurring" class="accrued-income-radio-group" labelPosition="before">
                        <mat-radio-button [value]="false" i18n="End of year|radio-button|metadata form accrued income">No</mat-radio-button>
                        <mat-radio-button [value]="true" i18n="End of year|radio-button|metadata form accrued income">Yes</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <div class="form-row full-width-single-element-row" *ngIf="showSupplierServiceNotRenderedAmount()">
                <mat-form-field formGroupName="accruedIncomeSupplierServiceNotRenderedAmount">
                    <input
                        matInput
                        i18n-placeholder="End of year|form label|Accrued income amount"
                        placeholder="Amount excluding VAT"
                        formControlName="amount"
                        class="right"
                        type="number" />
                    <span matSuffix class="currency">kr</span>
                </mat-form-field>
            </div>
            <div class="form-row full-width-single-element-row" *ngIf="showSupplierServiceNotRenderedAmount()">
                <mat-form-field>
                    <input
                        matInput
                        i18n-placeholder="End of year|form label|Accrued income supplier"
                        placeholder="Suppliers (comma-separated)"
                        formControlName="accruedIncomeSupplierServiceNotRenderedSupplier"
                        class="right" />
                </mat-form-field>
            </div>
        </div>
        <div class="accrued-income">
            <div class="form-row">
                <div class="form-col" i18n="End of year|form label|Accrued income supplier service rendered not invoiced">Have you received deliveries of goods or services during the financial year for which you are preparing financial statements for now, for which you have not yet received an invoice / receipt or which have an invoice date for the new year?</div>
                <div class="form-col right">
                    <mat-radio-group formControlName="accruedIncomeSupplierServiceRenderedNotInvoiced" class="accrued-income-radio-group" labelPosition="before">
                        <mat-radio-button [value]="false" i18n="End of year|radio-button|metadata form accrued income">No</mat-radio-button>
                        <mat-radio-button [value]="true" i18n="End of year|radio-button|metadata form accrued income">Yes</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
            <div class="form-row full-width-single-element-row" *ngIf="isCheckedTrue('accruedIncomeSupplierServiceRenderedNotInvoiced')">
                <mat-form-field formGroupName="accruedIncomeSupplierServiceRenderedNotInvoicedAmount">
                    <input
                        matInput
                        i18n-placeholder="End of year|form label|Accrued income amount"
                        placeholder="Amount excluding VAT"
                        formControlName="amount"
                        class="right"
                        type="number" />
                    <span matSuffix class="currency">kr</span>
                </mat-form-field>
            </div>
            <div class="form-row full-width-single-element-row" *ngIf="isCheckedTrue('accruedIncomeSupplierServiceRenderedNotInvoiced')">
                <mat-form-field>
                    <input
                        matInput
                        i18n-placeholder="End of year|form label|Accrued income supplier"
                        placeholder="Suppliers (comma-separated)"
                        formControlName="accruedIncomeSupplierServiceRenderedNotInvoicedSupplier"
                        class="right" />
                </mat-form-field>
            </div>
        </div>
    </div>
</form>

<div class="button-wrapper" *ngIf="(isWarmup || isStarted)">
    <button type="button" mat-button class="mat-flat-button mat-primary" [disabled]="disableSaveButton" i18n="Save" (click)="saveMetaData()">Save</button>
</div>
