<div class="alert-content" mat-dialog-content>
    <div class="red-alert-title" i18n="Mark card transaction as debt?">Mark as debt?</div>
    <p i18n="You make a private purchase using company card. Now you can reduce your company debt to you. But you don't have enough debt to yourself. Please add [minNeededAmount] kr to your account">
        You make a private purchase using company card. Now you can reduce your company debt to you. But you don't have enough debt to yourself. Please add {{minNeededAmount}} kr to your account.
    </p>
</div>
<mat-dialog-actions>
    <a mat-button class="mat-primary" tabindex="-1" href="https://intercom.help/redflag/foretag-med-red-flag/for-dig-som-ar-kund/insattning-pa-ditt-foretagskonto" target="_blank" i18n="Read more">Read more</a>
    <button mat-button class="mat-primary" tabindex="-1" (click)="cancel()">Ok</button>
</mat-dialog-actions>
