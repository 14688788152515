<ng-content select="red-top-toolbar"></ng-content>
<ng-content select="app-view-card-infobar"></ng-content>

<div class="view-card-container">
    <div class="view-card-content">
        <mat-card>
            <ng-content></ng-content>
        </mat-card>
    </div>
</div>
