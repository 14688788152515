import {NgModule} from '@angular/core';

import {NewVersionComponent} from './new-version';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import {CommonModule} from '@angular/common';

@NgModule({
    declarations: [
        NewVersionComponent
    ],
    exports: [
        NewVersionComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatToolbarModule
    ]
})
export class NewVersionModule {
}
