import {SignatureServiceClient} from '../../lab/service-client/signature-service-client';
import {COLLECT_RESPONSE_STATUS, SignPayloadRequest} from '@red/data';
import {delay, map, mergeMap, retryWhen} from 'rxjs/operators';
import {Observable, of, Subject, throwError} from 'rxjs';
import {CollectResponseModel, SignRequestResponseModel} from '../../shared/state/signature/signature.model';

export class SignatureManager {
    private _signatureServiceClient: SignatureServiceClient;
    private _retries: number;
    private _retryDelay: number;

    constructor(
        signatureServiceClient: SignatureServiceClient
    ) {
        this._signatureServiceClient = signatureServiceClient;
        this._retries = 80;
        this._retryDelay = 500;
    }

    static create(signatureServiceClient: SignatureServiceClient) {
        return new SignatureManager(signatureServiceClient);
    }

    collect(collectId: string, responseEmitter?: Subject<CollectResponseModel>): Observable<CollectResponseModel> {
        return this._poll(collectId, responseEmitter);
    }

    signPayload(payloadBase64: string, userIdentification: string, summary: string): Observable<SignRequestResponseModel> {
        const request = new SignPayloadRequest({payloadBase64, userIdentification, summary});
        return this._signatureServiceClient.signPayload(request)
            .pipe(
                map(response => response.body)
            );
    }

    private _poll(collectId: string, responseEmitter?: Subject<CollectResponseModel>): Observable<CollectResponseModel> {
        let retries = 0;
        return this._signatureServiceClient.collect(collectId)
            .pipe(
                map((response) => response.body),
                mergeMap((response: CollectResponseModel) => {
                    if (responseEmitter) {
                        responseEmitter.next(response);
                    }

                    if (response.status !== COLLECT_RESPONSE_STATUS.COMPLETE) {
                        return throwError(response);
                    }

                    if (responseEmitter) {
                        responseEmitter.complete();
                    }

                    return of(response);
                }),
                retryWhen<CollectResponseModel>(errors => {
                    return errors.pipe(
                        mergeMap((err) => {
                            retries += 1;
                            if (this._retries + 1 === retries) {
                                return throwError(new Error(`Polling failed after ${this._retries} attempts`));
                            }

                            if (this._shouldThrowError(err)) {
                                if (responseEmitter) {
                                    responseEmitter.complete();
                                }
                                return throwError(err);
                            }

                            return of(retries)
                                .pipe(
                                    delay(this._retryDelay)
                                );
                        })
                    );
                })
            );
    }

    private _shouldThrowError(resp: CollectResponseModel): boolean {
        return resp && resp.status === COLLECT_RESPONSE_STATUS.FAILED;
    }
}
