import {WebServiceClient} from '@red/browser';
import {HttpFetch} from '@red/common';
import {Projection} from '@red/data';
import {Observable} from 'rxjs';
import {AppConfig} from '../../config/appConfig';

export class SafeSpendServiceClient extends WebServiceClient {
    static create(transport: HttpFetch, config: AppConfig) {
        return new SafeSpendServiceClient(transport, config);
    }

    projection(companyId: string): Observable<Projection> {
        return this._get<Projection>(`${this._getBaseUrl()}/api/safe-spend/${companyId}`, {}, Projection);
    }
}
