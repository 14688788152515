<red-list class="calculation-list">
    <mat-list-item>
        <h4 mat-line i18n="Dividends left to pay">Dividends left to pay</h4>
        <span align="end" class="amount" [innerHTML]="remainingDividends | amount"></span>
    </mat-list-item>
    <mat-list-item>
        <h4 mat-line i18n="Safe to spend">Safe to spend</h4>
        <span align="end" class="amount" [innerHTML]="limit | amount"></span>
    </mat-list-item>
</red-list>
