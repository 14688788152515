
// https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener
import {FormControl} from '@angular/forms';

interface EventOptions {
    capture?: boolean;
    once?: boolean;
    passive?: boolean;
}

class PrivateFormUtils {
    static addEvent(target: EventTarget, name: string, func: EventListener, options?: EventOptions) {
        target.addEventListener(name, func, options);
    }

    static removeEvent(target: EventTarget, name: string, func: EventListener, options?: EventOptions) {
        target.removeEventListener(name, func, options);
    }

    static removeNonNumbers = (event: Event | KeyboardEvent | MouseEvent | ClipboardEvent) => {
        const inputElem = <HTMLInputElement>event.target;
        const onlyNumbers = inputElem.value.replace(/\D+/g, '');

        inputElem.value = onlyNumbers.trim();
    }
}

export class FormUtils {
    static onlyNumbersInput(event: Event | KeyboardEvent | MouseEvent | ClipboardEvent): void {
        const inputValue = event['key'] || '';

        if (inputValue.match(/^\D+$/)) {
            event.preventDefault();
        }

        PrivateFormUtils.addEvent(event.target, 'input', PrivateFormUtils.removeNonNumbers, {
            once: true,
            capture: true
        });
    }

    static onlyNumbersPaste(event: Event | KeyboardEvent | MouseEvent | ClipboardEvent): void {
        this.onlyNumbersInput(event);
    }
}
