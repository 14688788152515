import {Selector, State, StateContext, StateToken, Action} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {Announcement, AppInfoModel, AppVersion} from './app-info.model';
import {
    AppInfoDismissAnnouncement,
    AppInfoNewAnnouncementAvailable,
    AppInfoNewVersionAvailable,
    AppInfoNoAnnouncementAvailable,
    AppInfoUpdate
} from './app-info.actions';
import * as moment from 'moment';

export const APP_INFO_TOKEN = new StateToken<AppInfoModel>('app_info');

@State<AppInfoModel>({
    name: APP_INFO_TOKEN,
    defaults: {
        appVersion: {
            build: null,
            version: null,
            newVersionAvailable: false
        },
        announcements: [],
    }
})
@Injectable()
export class AppInfoState {
    @Selector()
    static appVersion(state: AppInfoModel): AppVersion {
        return state.appVersion;
    }

    @Selector()
    static announcements(state: AppInfoModel): Announcement[] {
        return state.announcements;
    }

    @Action(AppInfoUpdate)
    appInfoUpdate(ctx: StateContext<AppInfoModel>, action: AppInfoUpdate) {
        ctx.patchState({
            appVersion: action.payload.appVersion
        });

        const state = ctx.getState();
        this._broadcastNewVersionAvailable(state, ctx);

        const activeAnnouncements = this._getActiveAnnouncements(action.payload.announcements);
        const announcements = this._setDismissedOnAnnouncementFromAction(activeAnnouncements, state);

        if (Array.isArray(announcements)) {
            ctx.patchState({announcements});
        }

        this._broadcastAnnouncementAvailability(ctx, ctx.getState());
    }

    @Action(AppInfoDismissAnnouncement)
    dismissAnnouncement(ctx: StateContext<AppInfoModel>, action: AppInfoDismissAnnouncement) {
        const state = ctx.getState();
        const announcements = state.announcements.map(a => Object.assign({}, a));
        const announcement = this._getAnnouncementById(announcements, action.payload.announcement.id);
        announcement.dismissed = true;

        ctx.patchState({
            announcements: announcements
        });

        this._broadcastAnnouncementAvailability(ctx, ctx.getState());
    }

    private _broadcastAnnouncementAvailability(ctx: StateContext<AppInfoModel>, state: AppInfoModel) {
        const announcement = this._getNotDismissedAnnouncement(state);

        if (announcement) {
            ctx.dispatch(new AppInfoNewAnnouncementAvailable({announcement}));
        } else {
            ctx.dispatch(new AppInfoNoAnnouncementAvailable());
        }
    }

    private _broadcastNewVersionAvailable(state: AppInfoModel, ctx: StateContext<AppInfoModel>) {
        if (state.appVersion && state.appVersion.newVersionAvailable) {
            ctx.dispatch(new AppInfoNewVersionAvailable());
        }
    }

    private _setDismissedOnAnnouncementFromAction(announcements: Announcement[], state: AppInfoModel): Announcement[] {
        return announcements.map(announcement => {
            const storedAnnouncement = this._getAnnouncementById(state.announcements, announcement.id);
            if (storedAnnouncement && storedAnnouncement.dismissed === true) {
                announcement.dismissed = true;
            }
            return announcement;
        });
    }

    private _getNotDismissedAnnouncement(state: AppInfoModel): Announcement {
        return state.announcements.filter(announcement => {
            return !announcement.dismissed;
        }).shift();
    }

    private _getAnnouncementById(announcements: Announcement[], announcementId: string) {
        return announcements.filter(a => {
            return a.id === announcementId;
        }).shift();
    }

    private _getActiveAnnouncements(announcements: Announcement[]) {
        return announcements.filter(announcement => {
            return !announcement.expires || moment().isBefore(moment(announcement.expires));
        });
    }
}
