import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {EndOfYearManager} from '../../../../managers/end-of-year/end-of-year.manager';
import {COMPANY_TYPE, YEAR_END_STEP} from '@red/data';
import {Store} from '@ngxs/store';
import {AuthState} from '../../../../shared/state/auth/auth.state';

@Component({
    selector: 'app-end-of-year-metadata-form',
    styleUrls: ['../../end-of-year.dialog.sass'],
    templateUrl: 'end-of-year-metadata-form.tpl.html'
})

export class MetadataFormComponent implements OnInit {
    @Input() metadataFormInput: FormGroup;

    @Output() metadataFormOutput: EventEmitter<any> = new EventEmitter();

    get isWarmup(): boolean { return this._endOfYearManager.isInEndOfYearWarmUp(); }
    get isStarted(): boolean { return this._endOfYearManager.endOfYearStep.step === YEAR_END_STEP.STARTED; }
    get isSoleTrader(): boolean { return this._isSoleTrader; }
    get nbrOfReceiptsToUpload(): number { return this._nbrOfReceiptsToUpload; }
    get year() { return this._endOfYearManager.currentFiscalYear(); }
    get formDirty(): boolean { return this.metadataFormInput.dirty; }
    get formValid(): boolean { return this.metadataFormInput.valid; }
    get hasReceiptsToUpload(): boolean { return this._nbrOfReceiptsToUpload > 0; }
    get disableSaveButton(): boolean { return !this.metadataFormInput.dirty; }

    private _endOfYearManager: EndOfYearManager;
    private _formBuild: FormBuilder;
    private _isSoleTrader: boolean;
    private _nbrOfReceiptsToUpload: number;
    private _store: Store;

    constructor(
        endOfYearManager: EndOfYearManager,
        formBuild: FormBuilder,
        store: Store
    ) {
        this._endOfYearManager = endOfYearManager;
        this._formBuild = formBuild;
        this._store = store;
    }

    ngOnInit() {
        this._isSoleTrader = (this._endOfYearManager.endOfYearStep.companyType === COMPANY_TYPE.SOLE_TRADER);

        this.metadataFormInput.get('doneUploadingReceipts').disable();

        const companyId = this._store.selectSnapshot(AuthState.companyId);
        this._endOfYearManager
            .getNumberOfReceiptsToUpload(companyId)
            .subscribe(nbrOfReceiptsToUpload => {
                this._nbrOfReceiptsToUpload = nbrOfReceiptsToUpload || 0;

                if (this._nbrOfReceiptsToUpload > 0) {
                    this.metadataFormInput.get('doneUploadingReceipts').setValue(false);
                }

                if (this._nbrOfReceiptsToUpload === 0 && this._endOfYearManager.endOfYearStep.step === YEAR_END_STEP.STARTED) {
                    this.metadataFormInput.get('doneUploadingReceipts').enable();
                }
            });
    }

    saveMetaData() {
        this.metadataFormInput.markAsPristine();

        this.metadataFormOutput.emit(this.metadataFormInput.getRawValue());
    }

    isCheckedTrue(controlName: string) {
        return this.metadataFormInput.get(controlName).value === true;
    }

    isCheckedFalse(controlName: string) {
        return this.metadataFormInput.get(controlName).value === false;
    }

    showSupplierServiceNotRenderedAmount() {
        if (this.isSoleTrader) {
            return this.isCheckedTrue('accruedIncomeSupplierServiceNotRendered');
        } else {
            return this.isCheckedTrue('accruedIncomeSupplierServiceNotRendered')
                && this.isCheckedFalse('accruedIncomeSupplierServiceNotRenderedRecurring');
        }
    }
}
