import {HttpFetch} from '@red/common';
import {AppConfig} from '../../config/appConfig';
import {Observable} from 'rxjs';
import {Asset} from '@red/data';
import {WebServiceClient} from './web-service-client';

export class AssetServiceClient extends WebServiceClient {
    static create(transport: HttpFetch, config: AppConfig) {
        return new AssetServiceClient(transport, config);
    }

    upload(formData: FormData): Observable<Asset> {
        return this._post<Asset>(`${this._getBaseUrl()}/api/asset`, formData, {headers: {'content-type': 'multipart/form-data'}}, Asset);
    }
}
