import {OnboardingServiceClient} from '../../lab/service-client/onboarding-service-client';
import {QueryFilter} from '@red/browser';
import {
    Onboarding,
    ONBOARDING_APPLICATION_STATUS,
    ONBOARDING_APPLICATION_TYPE,
    ONBOARDING_STATUS,
    ONBOARDING_TRACK,
    OnboardingApplication,
    OnboardingApplicationListResponse,
    OnboardingApplicationUpdateRequest,
    OnboardingCreateRequest
} from '@red/onboarding-service-api';
import {map} from 'rxjs/operators';
import {Store} from '@ngxs/store';
import {AuthState} from '../../shared/state/auth/auth.state';
import {Observable} from 'rxjs';

export class OnboardingManager {
    private _onboardingServiceClient: OnboardingServiceClient;
    private _store: Store;

    constructor(
        onboardingServiceClient: OnboardingServiceClient,
        store: Store
    ) {
        this._onboardingServiceClient = onboardingServiceClient;
        this._store = store;
    }

    static create(onboardingServiceClient: OnboardingServiceClient, store: Store) {
        return new OnboardingManager(onboardingServiceClient, store);
    }

    hasOnboarding() {
        const userId = this._store.selectSnapshot(AuthState.userId);
        const status = ONBOARDING_STATUS.OPEN;
        const filter = new QueryFilter({userId, status});
        return this._onboardingServiceClient.list(filter);
    }

    create(email: string, phone: string) {
        const onboardingCreateRequest = new OnboardingCreateRequest();
        onboardingCreateRequest.userId = this._store.selectSnapshot(AuthState.userId);
        onboardingCreateRequest.email = email;
        onboardingCreateRequest.phone = phone;

        return this._onboardingServiceClient.create(onboardingCreateRequest);
    }

    setTrack(onboardingId: string, track: ONBOARDING_TRACK) {
        return this._onboardingServiceClient.setTrack(onboardingId, track);
    }

    setStatus(onboardingId: string, status: ONBOARDING_STATUS) {
        return this._onboardingServiceClient.setStatus(onboardingId, status);
    }

    listApplications(onboardingId: string): Observable<any> {
        const queryFilter = new QueryFilter();
        queryFilter.equal('onboardingId', onboardingId);
        return this._onboardingServiceClient.listOnboardingApplications(queryFilter)
            .pipe(
                map((resp: OnboardingApplicationListResponse) => resp.data)
            );
    }

    updateApplication(application: OnboardingApplication) {
        const request = new OnboardingApplicationUpdateRequest();
        request.data = application.data;
        return this._onboardingServiceClient.updateOnboardingApplication(application.id, request);
    }

    updateApplicationStatus(onboardingApplicationId: string, status: ONBOARDING_APPLICATION_STATUS) {
        return this._onboardingServiceClient.updateOnboardingApplicationStatus(onboardingApplicationId, status);
    }

    cancelTrack(onboardingId: string) {
        return this._onboardingServiceClient.cancelTrack(onboardingId);
    }

    canCancelTrack(track: ONBOARDING_TRACK, statusMap: {[type: string]: ONBOARDING_APPLICATION_STATUS}): boolean {
        let enabled;
        let required;

        if (!statusMap) {
            statusMap = {};
        }

        switch (track) {
            case ONBOARDING_TRACK.LIMITED_COMPANY_NEW:
                required = [
                    ONBOARDING_APPLICATION_TYPE.LIMITED_COMPANY_NEW_BLV_FORM,
                    ONBOARDING_APPLICATION_TYPE.LIMITED_COMPANY_NEW_BLV_BOARD_FORM,
                    ONBOARDING_APPLICATION_TYPE.LIMITED_COMPANY_NEW_BLV_SHARES_FORM,
                    ONBOARDING_APPLICATION_TYPE.LIMITED_COMPANY_NEW_SKV_FORM,
                    ONBOARDING_APPLICATION_TYPE.TERMS_OF_SERVICE
                ];

                enabled = !this._requiredAreSentOrDone(required, statusMap);
                break;
            case ONBOARDING_TRACK.LIMITED_COMPANY_EXISTING:
                required = [
                    ONBOARDING_APPLICATION_TYPE.LIMITED_COMPANY_EXISTING_COMPANY_INFO,
                    ONBOARDING_APPLICATION_TYPE.LIMITED_COMPANY_EXISTING_DOCUMENTS,
                    ONBOARDING_APPLICATION_TYPE.LIMITED_COMPANY_EXISTING_SIE,
                    ONBOARDING_APPLICATION_TYPE.TERMS_OF_SERVICE
                ];

                enabled = !this._requiredAreSentOrDone(required, statusMap);
                break;
            case ONBOARDING_TRACK.SOLE_TRADER_EXISTING:
                required = [
                    ONBOARDING_APPLICATION_TYPE.SOLE_TRADER_EXISTING_COMPANY_INFO,
                    ONBOARDING_APPLICATION_TYPE.SOLE_TRADER_EXISTING_DOCUMENTS,
                    ONBOARDING_APPLICATION_TYPE.SOLE_TRADER_EXISTING_SIE,
                    ONBOARDING_APPLICATION_TYPE.TERMS_OF_SERVICE
                ];

                enabled = !this._requiredAreSentOrDone(required, statusMap);
                break;
            case ONBOARDING_TRACK.SOLE_TRADER_NEW:
                required = [
                    ONBOARDING_APPLICATION_TYPE.SOLE_TRADER_NEW_SKV_FORM,
                    ONBOARDING_APPLICATION_TYPE.TERMS_OF_SERVICE
                ];

                enabled = !this._requiredAreSentOrDone(required, statusMap);
                break;
            default:
                enabled = false;
                break;
        }

        return enabled;
    }

    shouldApplicationBeEnabled(type: ONBOARDING_APPLICATION_TYPE, statusMap: {[type: string]: ONBOARDING_APPLICATION_STATUS}): boolean {
        let enabled;
        let required;

        if (!statusMap) {
            statusMap = {};
        }

        switch (type) {
            case ONBOARDING_APPLICATION_TYPE.LIMITED_COMPANY_EXISTING_CREATE_COMPANY:
                required = [
                    ONBOARDING_APPLICATION_TYPE.LIMITED_COMPANY_EXISTING_COMPANY_INFO,
                    ONBOARDING_APPLICATION_TYPE.LIMITED_COMPANY_EXISTING_DOCUMENTS,
                    ONBOARDING_APPLICATION_TYPE.LIMITED_COMPANY_EXISTING_SIE,
                    ONBOARDING_APPLICATION_TYPE.TERMS_OF_SERVICE
                ];

                enabled = this._requiredAreSentOrDone(required, statusMap);
                break;
            case ONBOARDING_APPLICATION_TYPE.LIMITED_COMPANY_EXISTING_SETTLEMENT:
                required = [
                    ONBOARDING_APPLICATION_TYPE.LIMITED_COMPANY_EXISTING_CREATE_COMPANY
                ];

                enabled = this._requiredAreDone(required, statusMap);
                break;
            case ONBOARDING_APPLICATION_TYPE.LIMITED_COMPANY_EXISTING_ACTIVATE:
                required = [
                    ONBOARDING_APPLICATION_TYPE.LIMITED_COMPANY_EXISTING_SETTLEMENT
                ];

                enabled = this._requiredAreDone(required, statusMap);
                break;
            case ONBOARDING_APPLICATION_TYPE.SOLE_TRADER_EXISTING_CREATE_COMPANY:
                required = [
                    ONBOARDING_APPLICATION_TYPE.SOLE_TRADER_EXISTING_COMPANY_INFO,
                    ONBOARDING_APPLICATION_TYPE.SOLE_TRADER_EXISTING_DOCUMENTS,
                    ONBOARDING_APPLICATION_TYPE.SOLE_TRADER_EXISTING_SIE,
                    ONBOARDING_APPLICATION_TYPE.TERMS_OF_SERVICE
                ];

                enabled = this._requiredAreSentOrDone(required, statusMap);
                break;
            case ONBOARDING_APPLICATION_TYPE.SOLE_TRADER_EXISTING_SETTLEMENT:
                required = [
                    ONBOARDING_APPLICATION_TYPE.SOLE_TRADER_EXISTING_CREATE_COMPANY
                ];

                enabled = this._requiredAreDone(required, statusMap);
                break;
            case ONBOARDING_APPLICATION_TYPE.SOLE_TRADER_EXISTING_ACTIVATE:
                required = [
                    ONBOARDING_APPLICATION_TYPE.SOLE_TRADER_EXISTING_CREATE_COMPANY,
                    ONBOARDING_APPLICATION_TYPE.SOLE_TRADER_EXISTING_SETTLEMENT,
                    ONBOARDING_APPLICATION_TYPE.TERMS_OF_SERVICE
                ];

                enabled = this._requiredAreDone(required, statusMap);
                break;
            case ONBOARDING_APPLICATION_TYPE.LIMITED_COMPANY_NEW_CREATE_COMPANY:
                required = [
                    ONBOARDING_APPLICATION_TYPE.LIMITED_COMPANY_NEW_BLV_FORM,
                    ONBOARDING_APPLICATION_TYPE.LIMITED_COMPANY_NEW_BLV_BOARD_FORM,
                    ONBOARDING_APPLICATION_TYPE.LIMITED_COMPANY_NEW_BLV_SHARES_FORM,
                    ONBOARDING_APPLICATION_TYPE.LIMITED_COMPANY_NEW_SKV_FORM,
                    ONBOARDING_APPLICATION_TYPE.TERMS_OF_SERVICE
                ];

                enabled = this._requiredAreSentOrDone(required, statusMap);
                break;
            case ONBOARDING_APPLICATION_TYPE.LIMITED_COMPANY_NEW_SHARE_CAPITAL:
                required = [
                    ONBOARDING_APPLICATION_TYPE.LIMITED_COMPANY_NEW_CREATE_COMPANY
                ];

                enabled = this._requiredAreDone(required, statusMap);
                break;
            case ONBOARDING_APPLICATION_TYPE.LIMITED_COMPANY_NEW_APPLICATION_BLV:
                required = [
                    ONBOARDING_APPLICATION_TYPE.LIMITED_COMPANY_NEW_SHARE_CAPITAL
                ];

                enabled = this._requiredAreDone(required, statusMap);
                break;
            case ONBOARDING_APPLICATION_TYPE.LIMITED_COMPANY_NEW_APPLICATION_SKV:
                required = [
                    ONBOARDING_APPLICATION_TYPE.LIMITED_COMPANY_NEW_SHARE_CAPITAL
                ];

                enabled = this._requiredAreDone(required, statusMap);
                break;
            case ONBOARDING_APPLICATION_TYPE.LIMITED_COMPANY_NEW_ACTIVATE:
                required = [
                    ONBOARDING_APPLICATION_TYPE.LIMITED_COMPANY_NEW_APPLICATION_BLV,
                    ONBOARDING_APPLICATION_TYPE.LIMITED_COMPANY_NEW_APPLICATION_SKV
                ];

                enabled = this._requiredAreDone(required, statusMap);
                break;
            case ONBOARDING_APPLICATION_TYPE.SOLE_TRADER_NEW_CREATE_COMPANY:
                required = [
                    ONBOARDING_APPLICATION_TYPE.SOLE_TRADER_NEW_SKV_FORM,
                    ONBOARDING_APPLICATION_TYPE.TERMS_OF_SERVICE
                ];

                enabled = this._requiredAreSentOrDone(required, statusMap);
                break;
            case ONBOARDING_APPLICATION_TYPE.SOLE_TRADER_NEW_APPLICATION_SKV:
                required = [
                    ONBOARDING_APPLICATION_TYPE.SOLE_TRADER_NEW_CREATE_COMPANY
                ];

                enabled = this._requiredAreDone(required, statusMap);
                break;
            case ONBOARDING_APPLICATION_TYPE.SOLE_TRADER_NEW_ACTIVATE:
                required = [
                    ONBOARDING_APPLICATION_TYPE.SOLE_TRADER_NEW_APPLICATION_SKV
                ];

                enabled = this._requiredAreDone(required, statusMap);
                break;
            default:
                enabled = true;
                break;
        }

        return enabled;
    }

    getPreviousSteps(type: ONBOARDING_APPLICATION_TYPE, steps: OnboardingApplication[]): OnboardingApplication[] {
        const applications = steps.slice();
        const typeIndex = applications.findIndex(application => application.type === type);

        return applications.slice(0, typeIndex);
    }

    onboardingById(onboardingId: string): Observable<Onboarding> {
        return this._onboardingServiceClient.getById(onboardingId);
    }

    private _requiredAreSentOrDone(required: ONBOARDING_APPLICATION_TYPE[], statusMap: {[type: string]: ONBOARDING_APPLICATION_STATUS}): boolean {
        const match = required.filter((t) => statusMap[t] === ONBOARDING_APPLICATION_STATUS.SENT || statusMap[t] === ONBOARDING_APPLICATION_STATUS.DONE);
        return  match.length === required.length;
    }

    private _requiredAreDone(required: ONBOARDING_APPLICATION_TYPE[], statusMap: {[type: string]: ONBOARDING_APPLICATION_STATUS}): boolean {
        const match = required.filter((t) => statusMap[t] === ONBOARDING_APPLICATION_STATUS.DONE);
        return  match.length === required.length;
    }
}
