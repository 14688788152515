import {Component, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AnnouncementDialogComponent, AnnouncementDialogData} from './dialog/announcement-dialog';
import {Actions, ofActionSuccessful, Store} from '@ngxs/store';
import {
    AppInfoDismissAnnouncement,
    AppInfoNewAnnouncementAvailable,
    AppInfoNoAnnouncementAvailable
} from '../../shared/state/app-info/app-info.actions';
import {Announcement, ANNOUNCEMENT_TYPE} from '../../shared/state/app-info/app-info.model';
import { DIALOG_RESULT } from '@red/components';

@Component({
    selector: 'app-announcement',
    styleUrls: ['announcement.sass'],
    templateUrl: 'announcement.tpl.html'
})

export class AnnouncementComponent implements OnInit {
    get displayWarningIcon(): boolean { return this._displayWarningIcon; }
    get title(): string { return this._announcement.title; }
    get show(): boolean { return this._show; }

    private _announcement: Announcement;
    private _displayWarningIcon: boolean;
    private _show = false;
    private _dialog: MatDialog;
    private _dialogRef: MatDialogRef<AnnouncementDialogComponent, DIALOG_RESULT>;
    private _actions: Actions;
    private _store: Store;

    constructor(
        dialog: MatDialog,
        actions: Actions,
        store: Store
    ) {
        this._dialog = dialog;
        this._actions = actions;
        this._store = store;
    }

    ngOnInit() {
        this._actions
            .pipe(
                ofActionSuccessful(AppInfoNewAnnouncementAvailable)
            )
            .subscribe((action: AppInfoNewAnnouncementAvailable) => {
                this._announcement = action.payload.announcement;
                this._displayWarningIcon = action.payload.announcement.type === ANNOUNCEMENT_TYPE.WARN || !action.payload.announcement.type;
                this._show = true;
            });

        this._actions
            .pipe(
                ofActionSuccessful(AppInfoNoAnnouncementAvailable)
            )
            .subscribe(() => {
                this._show = false;
            });
    }

    readMore() {
        this._dialogRef = this._dialog.open<AnnouncementDialogComponent, AnnouncementDialogData, DIALOG_RESULT>(AnnouncementDialogComponent, {
            data: {
                title: this._announcement.title,
                message: this._announcement.message
            }
        });

        this._dialogRef.afterClosed().subscribe((result: DIALOG_RESULT) => {
            if (result === DIALOG_RESULT.OK) {
                this._store.dispatch(new AppInfoDismissAnnouncement({announcement: this._announcement}));
            }
        });
    }
}
