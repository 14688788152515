import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AnnualReportManager} from '../../../../managers/annual-report/annual-report.manager';
import {
    Amount,
    AnnualReport,
    CompanyYearEndStep,
    CURRENCY,
    DividendsDecisionRequest,
    PossibleDividends
} from '@red/data';
import {EndOfYearManager} from '../../../../managers/end-of-year/end-of-year.manager';
import {Observable} from 'rxjs';
import {mergeMap as mergeMap} from 'rxjs/operators';
import {RedNotification} from '@red/components';
import {Store} from '@ngxs/store';
import {AuthState} from '../../../../shared/state/auth/auth.state';
import * as moment from 'moment';

@Component({
    selector: 'app-end-of-year-step-dividends',
    styleUrls: [ './end-of-year-step-dividends.sass', '../../end-of-year.dialog.sass'],
    templateUrl: 'end-of-year-step-dividends.tpl.html'
})

export class EndOfYearStepDividendsComponent implements OnInit {
    @Output() dividendsFormOutput: EventEmitter<boolean> = new EventEmitter();

    get shareholderContributionPayback(): Amount { return this._shareholderContributionPayback; }
    get dividendsForm(): FormGroup { return this._dividendsForm; }
    get possibleDividends() { return this._possibleDividends; }
    get disableDividendsForm(): boolean { return this._disableDividendsForm; }
    get endOfYearDividendsAndAnnualReportLink(): string { return this._endOfYearDividendsAndAnnualReportLink; }
    get januaryFirstCurrentYear(): string { return this._januaryFirstCurrentYear; }
    get simplificationRuleAmount(): Amount { return this._simplificationRuleAmount; }

    private _annualReportManager: AnnualReportManager;
    private _currentStep: CompanyYearEndStep;
    private _dividendsDecisionAmount: Amount;
    private _formBuild: FormBuilder;
    private _possibleDividends: Amount;
    private _shareholderContributionPayback: Amount;
    private _dividendsForm: FormGroup;
    private _store: Store;
    private _companyId: string;
    private _disableDividendsForm: boolean;
    private _endOfYearManager: EndOfYearManager;
    private _endOfYearDividendsAndAnnualReportLink: string;
    private _annualReport: AnnualReport;
    private _notification: RedNotification;
    private _januaryFirstCurrentYear: string;
    private _simplificationRuleAmount: Amount;

    constructor (
        formBuild: FormBuilder,
        store: Store,
        annualReportManager: AnnualReportManager,
        endOfYearManager: EndOfYearManager,
        notification: RedNotification
    ) {
        this._formBuild = formBuild;
        this._store = store;
        this._annualReportManager = annualReportManager;
        this._endOfYearManager = endOfYearManager;
        this._notification = notification;

        this._disableDividendsForm = true;
        this._possibleDividends = Amount.ZERO(CURRENCY.SEK);
        this._dividendsDecisionAmount = Amount.ZERO(CURRENCY.SEK);

        this._setDividendForm();
    }

    ngOnInit(): void {
        if (moment.locale() !== 'sv') {
            moment.locale('sv');
        }

        this._januaryFirstCurrentYear = moment().startOf(`year`).format(`ll`);
        this._simplificationRuleAmount = this._endOfYearManager.simplificationRuleAmount;

        this._companyId = this._store.selectSnapshot(AuthState.companyId);
        this._currentStep = this._endOfYearManager.endOfYearStep;
        this._endOfYearDividendsAndAnnualReportLink = this._endOfYearManager.endOfYearDividendsAndAnnualReportLink;

        this._setAnnualReport(this._currentStep.metadata.annualReportId);
    }

    saveDividendsDecision() {
        const annualReportId = this._currentStep.metadata.annualReportId;
        const dividendsDecisionRequest = new DividendsDecisionRequest({
            companyId: this._companyId,
            reportId: annualReportId,
            dividendsDecision: Amount.SEK(this._dividendsForm.get('dividend').value),
            shareholderContributionPayback: Amount.SEK(this._dividendsForm.get('shareholderContributionPayback').value)
        });

        this._annualReportManager.makeDividendsDecision(dividendsDecisionRequest)
            .subscribe(() => {
                this._dividendsForm.markAsPristine();
                this._checkDisableDividendsForm();

                setTimeout(() => {
                    this._emitFormChanges();
                }, 100);
            });
    }

    private _getPossibleDividends(annualReportId: string): Observable<PossibleDividends> {
        return this._annualReportManager.getPossibleDividends(annualReportId);
    }

    private _setAnnualReport(annualReportId: string) {
        this._annualReportManager.getAnnualReport(annualReportId)
            .pipe(
                mergeMap((annualReport: AnnualReport) => {
                    this._annualReport = annualReport;

                    return this._getPossibleDividends(annualReport.id);
                })
            )
            .subscribe(
                (possibleDividends: PossibleDividends) => {
                    if (this._annualReport) {
                        this._possibleDividends = possibleDividends.dividends;
                        this._shareholderContributionPayback = possibleDividends.shareholderContributionPayback;
                        this._dividendsDecisionAmount = this._annualReport.dividendsDecision && this._annualReport.dividendsDecision.amount;
                        this._dividendsDecisionAmount = this._annualReport.dividendsDecision && this._annualReport.dividendsDecision.amount;
                        const shareholderContributionPaybackAmount = this._annualReport.dividendsDecision && this._annualReport.dividendsDecision.shareholderContributionPaybackAmount;

                        this._dividendsForm.get('dividend').setValue(this._dividendsDecisionAmount && this._dividendsDecisionAmount.amount);
                        this._dividendsForm.get('shareholderContributionPayback').setValue(shareholderContributionPaybackAmount && shareholderContributionPaybackAmount.amount);
                    }

                    this._initValidation();
                },
                (err: Error) => {
                    this._handleError(err);
                }
            );
    }

    private _handleError(err: Error) {
        const msg = $localize`:Error code returned from server|Failed to move step in year end flow:Ops, something went wrong.`;
        this._notification.errorWithCode(err, msg);
    }

    private _initValidation() {
        this._dividendsForm.updateValueAndValidity();

        setTimeout(() => {
            this._emitFormChanges();
        }, 100);
    }

    private _emitFormChanges() {
        this.dividendsFormOutput.emit(this._dividendsForm.valid && this.dividendsForm.pristine);
    }

    private _setDividendForm() {
        this._dividendsForm = this._formBuild.group({
            dividend: [null, [
                Validators.required,
                (formControl: FormControl) => this._validateDividends(formControl)
            ]],
            shareholderContributionPayback: [null, [
                Validators.required,
                (formControl: FormControl) => this._validateShareholderPayback(formControl)
            ]]
        }, {
            validators: (formControl: FormControl) => this._validateTotal(formControl)
        });

        this._dividendsForm.valueChanges
            .subscribe(() => {
                this._checkDisableDividendsForm();

                this._emitFormChanges();
            });
    }

    private _checkDisableDividendsForm(): void {
        this._disableDividendsForm = (this._dividendsForm.pristine || this._dividendsForm.invalid);
    }

    private _validateDividends(formControl: FormControl) {
        const dividends = Number(formControl.value);

        if (!this._possibleDividends || isNaN(dividends) || dividends === 0) {
            return null;
        }

        return Amount.SEK(dividends).lessThenOrEqual(this._possibleDividends)
            ? null
            : {
                dividendsExceedsMax: true
            };
    }

    private _validateShareholderPayback(formControl: FormControl) {
        const shareholderContributionPayback = Number(formControl.value);

        if (!this._shareholderContributionPayback || isNaN(shareholderContributionPayback) || shareholderContributionPayback === 0) {
            return null;
        }

        return Amount.SEK(shareholderContributionPayback).lessThenOrEqual(this._shareholderContributionPayback)
            ? null
            : {
                shareholderPaybackExceedsMax: true
            };
    }

    private _validateTotal(formControl: FormControl) {
        if (!formControl || !this._possibleDividends) {
            return null;
        }

        const dividends = Number(formControl.get('dividend').value);
        const shareholderContributionPayback = Number(formControl.get('shareholderContributionPayback').value);

        const total = Amount.sum(
            (isNaN(dividends) ? Amount.SEK(0) : Amount.SEK(dividends)),
            (isNaN(shareholderContributionPayback) ? Amount.SEK(0) : Amount.SEK(shareholderContributionPayback))
        );

        if (total.amount === 0) {
            return null;
        }

        return total.lessThenOrEqual(this._possibleDividends)
            ? null
            : {
                totalExceedsMax: true
            };
    }
}
