import {Component, HostBinding, Inject, LOCALE_ID, OnInit} from '@angular/core';
import {Comment, CommentListResponse, Receipt, RECEIPT_STATUS, REFERENCE_TYPE} from '@red/data';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Router} from '@angular/router';
import {DIALOG_RESULT, RedNotification, RedDialog} from '@red/components';
import {ReceiptUpdatedEvent} from '../../../common/event/readers/receipt';
import {EventSource} from '../../../common/event/source';
import {AppConfig} from '../../../config/appConfig';
import {Analytics, ANALYTICS_ACTION, ANALYTICS_CATEGORY} from '../../../common/analytics/analytics';
import {FormGroup} from '@angular/forms';
import {CommentFormHelper} from '../../../common/form/comment-form-helper';
import {CommentManager} from '../../../managers/comment/comment';
import {AuthState} from '../../../shared/state/auth/auth.state';
import {Store} from '@ngxs/store';
import {ReceiptServiceClient} from '../../../lab/service-client/receipt-service-client';
import {ConfirmDialogComponent} from '../../confirm-dialog/confirm-dialog';

@Component({
    styleUrls: ['./edit.sass'],
    templateUrl: './edit.tpl.html'
})
export class ReceiptEditComponent implements OnInit {
    @HostBinding('class.receipt-edit-view') cssClass = true;

    private _commentForm: FormGroup;
    private _commentFormHelper: CommentFormHelper;
    private _commentManager: CommentManager;
    private _comments: CommentListResponse;
    private _dialog: RedDialog;
    private _dialogRef: MatDialogRef<ReceiptEditComponent>;
    private _eventSource: EventSource;
    private _notification: RedNotification;
    private _receipt: Receipt;
    private _receiptServiceClient: ReceiptServiceClient;
    private _receiptRejectMarkdown: string;
    private _router: Router;
    private _store: Store;
    private _uploading = false;
    private _analytics: Analytics;
    private _userComment: Comment;

    constructor (
        analytics: Analytics,
        appConfig: AppConfig,
        commentFormHelper: CommentFormHelper,
        commentManager: CommentManager,
        dialog: RedDialog,
        dialogRef: MatDialogRef<ReceiptEditComponent>,
        eventSource: EventSource,
        notification: RedNotification,
        receiptServiceClient: ReceiptServiceClient,
        router: Router,
        store: Store,
        @Inject(LOCALE_ID) localeId: string,
        @Inject(MAT_DIALOG_DATA) data: any
    ) {
        this._analytics = analytics;
        this._commentFormHelper = commentFormHelper;
        this._commentManager = commentManager;
        this._dialog = dialog;
        this._dialogRef = dialogRef;
        this._eventSource = eventSource;
        this._notification = notification;
        this._receipt = data.receipt;
        this._receiptServiceClient = receiptServiceClient;
        this._router = router;
        this._store = store;

        this._receiptRejectMarkdown = appConfig.get(`content.receipt.reject.${localeId}`);
    }


    get canDelete(): boolean { return this.isPending || this.isRejected; }
    get commentForm(): FormGroup { return this._commentForm; }
    get receipt(): Receipt { return this._receipt; }
    get assetId(): string { return this._receipt.assetId; }
    get isPending(): boolean { return this.receipt.status === RECEIPT_STATUS.PENDING || this.receipt.status === RECEIPT_STATUS.IN_REVIEW; }
    get isRejected(): boolean { return this.receipt.status === RECEIPT_STATUS.REJECTED; }
    get receiptRejectMarkdown(): string { return this._receiptRejectMarkdown; }
    get showCommentForm(): boolean { return (this.isPending && !!this._commentForm) || (!this.isPending && !!this._userComment); }
    get showContextMenu(): boolean {return this.canDelete || !!this.assetId; }
    get uploading(): boolean { return this._uploading; }

    private get _companyId(): string { return this._store.selectSnapshot(AuthState.companyId); }

    ngOnInit(): void {
        this._commentManager.getComments(REFERENCE_TYPE.RECEIPT, this._receipt.id)
            .subscribe((response: CommentListResponse) => {
                this._comments = response;
                this._userComment = this._comments.data.filter(comment => comment.author.entity.id === this._store.selectSnapshot(AuthState.userId)).shift();
                this._createForm();
                if (this._userComment) {
                    this._updateForm(this._userComment);
                }
                if (!this.isPending) {
                    this._commentForm.disable();
                }
            });
    }

    close() {
        if (this.isPending && this.canSaveComment()) {
            this.saveComment();
        }

        this._dialogRef.close(DIALOG_RESULT.OK);
    }

    canEdit(): boolean {
        return this._userComment && (this._userComment.author.entity.id === this._store.selectSnapshot(AuthState.userId)) || !this._userComment;
    }

    canSaveComment(): boolean {
        return this.canEdit() && this._commentForm && this._commentForm.valid && this._commentForm.dirty;
    }

    createComment() {
        const message = this._commentForm.get('message').value;
        this._commentManager.createComment(REFERENCE_TYPE.RECEIPT, this._receipt.id, message)
            .subscribe((response: CommentListResponse) => {
                this._comments = response;
                this._userComment = this._comments.data.filter(comment => comment.author.entity.id === this._store.selectSnapshot(AuthState.userId)).shift();
                this._commentForm.markAsPristine();
            });
    }

    editComment(comment: Comment) {
        const message = this._commentForm.get('message').value;
        this._commentManager.editComment(REFERENCE_TYPE.RECEIPT, this._receipt.id, comment.id, message)
            .subscribe((response: CommentListResponse) => {
                this._comments = response;
                this._commentForm.markAsPristine();
            });
    }

    saveComment() {
        if (this._userComment) {
            this.editComment(this._userComment);
        } else {
            this.createComment();
        }
    }


    onMenuDelete() {
        const confirmTitle = $localize`:Confirm title|Delete Receipt:Delete Receipt`;
        const confirmText = $localize`:Confirm text|Delete Receipt:Do you want to delete this receipt?`;

        this._dialog.confirm(ConfirmDialogComponent, {
            data: {
                title: confirmTitle,
                text: confirmText
            }
        })
            .afterClosed()
            .subscribe((result: DIALOG_RESULT) => {
                if (result === DIALOG_RESULT.OK) {
                    this._deleteReceipt();
                }
            });
    }

    uploadNewImage($event: File) {
        this._uploading = true;
        this._uploadReceipt($event);
    }

    private _deleteReceipt() {
        this._receiptServiceClient.delete(this._receipt.id)
            .subscribe(() => {
                this._eventSource.emit(new ReceiptUpdatedEvent());
                this.close();
                const msg = $localize`:RedNotification|Receipt was deleted by user.:Receipt was deleted`;
                this._notification.error(msg);
            }, (err) => {
                const msg = $localize`:RedNotification|Failed to delete receipt.:Failed to delete receipt`;
                this._notification.error(msg);
            });
    }

    private _uploadReceipt(receiptImage: File) {
        const formData = new FormData();

        formData.append('file', receiptImage);

        this._receiptServiceClient.replaceAsset(this._receipt.id, formData)
            .subscribe((updatedReceipt: Receipt) => {
                this._eventSource.emit(new ReceiptUpdatedEvent());
                this._analytics.track(ANALYTICS_CATEGORY.RECEIPT, ANALYTICS_ACTION.CREATE);
                this._dialogRef.close(DIALOG_RESULT.OK);
            }, (err) => {
                this._uploading = false;

                const msg = $localize`:RedNotification|Failed to upload image.:Failed to upload a image as a receipt`;
                this._notification.errorWithCode(err, msg);
            });
    }

    private _createForm() {
        this._commentForm = this._commentFormHelper.createCommentForm();
    }

    private _updateForm(comment: Comment) {
        this._commentForm.patchValue(this._commentFormHelper.mapCommentToForm(comment));
    }
}
