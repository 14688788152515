import {SafeSpendServiceClient} from '../../lab/service-client/safe-spend-service-client';
import {Observable, of} from 'rxjs';
import {Amount, FutureEvent, Projection} from '@red/data';
import {map} from 'rxjs/operators';
import {WithdrawalServiceClient} from '../../lab/service-client/withdrawal-service-client';
import {MockDTO} from '../../../testing/mocks/dto';

export class FinancialStatementManager {
    private _safeSpendServiceClient: SafeSpendServiceClient;
    private _withdrawalServiceClient: WithdrawalServiceClient;

    constructor (
        safeSpendServiceClient: SafeSpendServiceClient,
        withdrawalServiceClient: WithdrawalServiceClient
    ) {
        this._safeSpendServiceClient = safeSpendServiceClient;
        this._withdrawalServiceClient = withdrawalServiceClient;
    }

    static create(safeSpendServiceClient: SafeSpendServiceClient, withdrawalServiceClient: WithdrawalServiceClient) {
        return new FinancialStatementManager(safeSpendServiceClient, withdrawalServiceClient);
    }

    loadProjection(companyId: string): Observable<Projection> {
        // return this._safeSpendServiceClient.projection(companyId);
        return of(new Projection());
    }

    getFutureEvents(companyId: string): Observable<FutureEvent[]> {
        return this.loadProjection(companyId)
            .pipe(
                map((projection: Projection) => projection.futureEvents)
            );
    }

    getCompanyOwedAmount(companyId: string): Observable<Amount> {
        return this._withdrawalServiceClient.available(companyId);
    }
}
