import {Resolve, Router, ActivatedRouteSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {Invoice} from '@red/data';
import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {InvoiceServiceClient} from '../../lab/service-client/invoice-service-client';

@Injectable()
export class InvoiceRouteResolve implements Resolve<Invoice> {
    private _invoiceServiceClient: InvoiceServiceClient;
    private _router: Router;

    constructor(
        invoiceServiceClient: InvoiceServiceClient,
        router: Router,
    ) {
        this._invoiceServiceClient = invoiceServiceClient;
        this._router = router;
    }

    resolve(route: ActivatedRouteSnapshot): Observable<Invoice> {
        return this._invoiceServiceClient.getInvoice(route.params['id'])
            .pipe(catchError(() => {
                this._router.navigate(['/not-found']);
                return of(null);
            }));
    }
}

