import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {Store} from '@ngxs/store';
import {AuthState} from '../../shared/state/auth/auth.state';

@Injectable()
export class InvalidSessionGuard implements CanActivate {
    private _router: Router;
    private _store: Store;

    constructor(
        router: Router,
        store: Store
    ) {
        this._router = router;
        this._store = store;
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        if (!this._store.selectSnapshot(AuthState.isAuthenticated)) {
            return of(true);
        } else {
            this._router.navigate(['/']);
            return of(false);
        }
    }
}
