<div class="topbar-wrapper">
    <div class="view-topbar its-time-for-the-closing-of-the-books">
        <p i18n="End-of-year|dashboard text|Time for closing of the books" class="text" *ngIf="flowStateWarmup">1 {{endOfYearStart}} it's time for the closing of the books</p>

        <ng-container *ngIf="flowStateInProcess" [ngSwitch]="currentStep">
            <p *ngSwitchCase="'CUSTOMER_DONE'" class="text" i18n="End-of-year dashboard text|CUSTOMER_DONE">CUSTOMER_DONE</p>
            <p *ngSwitchCase="'WAITING_FOR_CUSTOMER_TO_SIGN'" class="text" i18n="End-of-year dashboard text|WAITING_FOR_CUSTOMER_TO_SIGN">WAITING_FOR_CUSTOMER_TO_SIGN</p>
            <p *ngSwitchCase="'CREATING_DOCUMENTS'" class="text" i18n="End-of-year dashboard text|CREATING_DOCUMENTS">CREATING_DOCUMENTS</p>
            <p *ngSwitchCase="'WAITING_FOR_FINAL_SIGNATURE'" class="text" i18n="End-of-year dashboard text|WAITING_FOR_FINAL_SIGNATURE">WAITING_FOR_FINAL_SIGNATURE</p>
            <p i18n="End-of-year dashboard text|started" class="text" *ngSwitchDefault>Ready to close the books</p>
        </ng-container>

        <p i18n="End-of-year dashboard text|Books closed" class="text" *ngIf="flowStateSoleDone">{{year}} Books closed </p>

        <p i18n="End-of-year dashboard text|dividends step" *ngIf="flowStateInDividendsProcess">Dividends step</p>
        <p i18n="End-of-year dashboard text|LLC Done" *ngIf="flowStateLLCDone">{{year}} LLC done</p>

        <p><button mat-flat-button i18n="Open" (click)="openEndOfYearDialog()">Open</button></p>
    </div>
</div>

