import {BaseEvent} from '../event';
import {EventSource} from '../source';
import {Subject} from 'rxjs';
import {EventReader, OnEvent} from '../reader';
import {Injectable} from '@angular/core';

export class EmployeeCreatedEvent extends BaseEvent {}
export class EmployeeUpdatedEvent extends BaseEvent {}

@Injectable()
export class EmployeeEventReader extends EventReader {
    private _onCreatedStream: Subject<EmployeeCreatedEvent>;
    private _onUpdatedStream: Subject<EmployeeUpdatedEvent>;


    constructor (
        source: EventSource
    ) {
        super(source);

        this._onCreatedStream = new Subject<EmployeeCreatedEvent>();
        this._onUpdatedStream = new Subject<EmployeeUpdatedEvent>();
    }

    onCreated(fn: (evt: EmployeeCreatedEvent) => void) {
        this._onCreatedStream.subscribe(fn);
    }

    onUpdate(fn: (evt: EmployeeCreatedEvent) => void) {
        this._onUpdatedStream.subscribe(fn);
    }

    @OnEvent(EmployeeCreatedEvent)
    private _handleEmployeeCreatedEvent(evt: EmployeeCreatedEvent) {
        this._onCreatedStream.next(evt);
    }

    @OnEvent(EmployeeUpdatedEvent)
    private _handleEmployeeUpdatedEvent(evt: EmployeeUpdatedEvent) {
        this._onUpdatedStream.next(evt);
    }
}
