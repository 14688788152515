import {NgModule} from '@angular/core';
import {OnboardingSelectTrackComponent} from './onboarding-select-track/onboarding-select-track';
import {OnboardingContainerComponent} from './onboarding-container/onboarding-container.component';
import {BrowserModule} from '@angular/platform-browser';
import {CommonModule} from '@angular/common';
import {OnboardingApplicationsComponent} from './onboarding-applications/onboarding-applications';
import {OnboardingApplicationExistingCompanyInfoComponent} from './onboarding-applications/onboarding-application-existing-company-info/onboarding-application-existing-company-info';
import {ReactiveFormsModule} from '@angular/forms';
import {
    RedAssetModule,
    RedDialogModule,
    RedDisplayModule,
    RedFormModule,
    RedGridModule,
    RedInfoBoxModule
} from '@red/components';
import {OnboardingApplicationLlcNewApplicationBlvComponent} from './onboarding-applications/onboarding-application-llc-new-application-blv/onboarding-application-llc-new-application-blv';
import {OnboardingApplicationLlcNewApplicationSkvComponent} from './onboarding-applications/onboarding-application-llc-new-application-skv/onboarding-application-llc-new-application-skv';
import {OnboardingApplicationLlcNewBlvBoardFormComponent} from './onboarding-applications/onboarding-application-llc-new-blv-board-form/onboarding-application-llc-new-blv-board-form';
import {OnboardingApplicationLlcNewBlvFormComponent} from './onboarding-applications/onboarding-application-llc-new-blv-form/onboarding-application-llc-new-blv-form';
import {OnboardingApplicationLlcNewBlvSharesFormComponent} from './onboarding-applications/onboarding-application-llc-new-blv-shares-form/onboarding-application-llc-new-blv-shares-form';
import {OnboardingApplicationLlcNewCreateCompanyComponent} from './onboarding-applications/onboarding-application-llc-new-create-company/onboarding-application-llc-new-create-company';
import {OnboardingApplicationLlcNewSkvFormComponent} from './onboarding-applications/onboarding-application-llc-new-skv-form/onboarding-application-llc-new-skv-form';
import {MatExpansionModule} from '@angular/material/expansion';
import {OnboardingApplicationLlcNewShareCapitalComponent} from './onboarding-applications/onboarding-application-llc-new-share-capital/onboarding-application-llc-new-share-capital';
import {MatSelectModule} from '@angular/material/select';
import {AmountModule} from '../amount/amount.module';
import {OnboardingApplicationLlcNewActivateComponent} from './onboarding-applications/onboarding-application-llc-new-activate/onboarding-application-llc-new-activate';
import {OnboardingApplicationLlcExistingDocumentsComponent} from './onboarding-applications/onboarding-application-llc-existing-documents/onboarding-application-llc-existing-documents';
import {OnboardingApplicationExistingSieComponent} from './onboarding-applications/onboarding-application-existing-sie/onboarding-application-existing-sie';
import {OnboardingApplicationExistingActivateComponent} from './onboarding-applications/onboarding-application-existing-activate/onboarding-application-existing-activate';
import {OnboardingApplicationExistingCreateCompanyComponent} from './onboarding-applications/onboarding-application-existing-create-company/onboarding-application-existing-create-company';
import {OnboardingApplicationExistingSettlementComponent} from './onboarding-applications/onboarding-application-existing-settlement/onboarding-application-existing-settlement';
import {OnboardingApplicationSoleExistingDocumentsComponent} from './onboarding-applications/onboarding-application-sole-existing-documents/onboarding-application-sole-existing-documents';
import {OnboardingApplicationSoleNewSkvFormComponent} from './onboarding-applications/onboarding-application-sole-new-skv-form/onboarding-application-sole-new-skv-form';
import {OnboardingApplicationSoleNewActivateComponent} from './onboarding-applications/onboarding-application-sole-new-activate/onboarding-application-sole-new-activate';
import {OnboardingApplicationSoleNewApplicationSkvComponent} from './onboarding-applications/onboarding-application-sole-new-application-skv/onboarding-application-sole-new-application-skv';
import {OnboardingApplicationSoleNewCreateCompanyComponent} from './onboarding-applications/onboarding-application-sole-new-create-company/onboarding-application-sole-new-create-company';
import {OnboardingApplicationTermsOfServiceComponent} from './onboarding-applications/onboarding-application-terms-of-service/onboarding-application-terms-of-service';
import {OnboardingCancelTrackConfirmComponent} from './onboarding-cancel-track-confirm/onboarding-cancel-track-confirm';
import {SniCodesModule} from '../sni-codes/sni-codes.module';
import { RedPipeModule } from '@red/ng';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatIconModule} from '@angular/material/icon';
import {MatRadioModule} from '@angular/material/radio';
import {MatDialogModule} from '@angular/material/dialog';
import {OnboardingShareDistributionConfirmDialogComponent} from './onboarding-share-distribution-confirm/onboarding-share-distribution-confirm';
import {ReadMoreLessModule} from '../read-more-less/read-more-less.module';
import {
    TermsDialogContentComponent
} from './onboarding-applications/onboarding-application-terms-of-service/terms-dialog-content/terms-dialog-content.component';
import {
    TermsDialogComponent
} from './onboarding-applications/onboarding-application-terms-of-service/terms-dialog/terms-dialog.component';

@NgModule({
    declarations: [
        OnboardingSelectTrackComponent,
        OnboardingContainerComponent,
        OnboardingApplicationsComponent,
        OnboardingApplicationExistingCompanyInfoComponent,
        OnboardingApplicationLlcExistingDocumentsComponent,
        OnboardingApplicationExistingSieComponent,
        OnboardingApplicationExistingCreateCompanyComponent,
        OnboardingApplicationExistingSettlementComponent,
        OnboardingApplicationExistingActivateComponent,
        OnboardingApplicationLlcNewApplicationBlvComponent,
        OnboardingApplicationLlcNewApplicationSkvComponent,
        OnboardingApplicationLlcNewBlvBoardFormComponent,
        OnboardingApplicationLlcNewBlvFormComponent,
        OnboardingApplicationLlcNewBlvSharesFormComponent,
        OnboardingApplicationLlcNewCreateCompanyComponent,
        OnboardingApplicationLlcNewSkvFormComponent,
        OnboardingApplicationLlcNewShareCapitalComponent,
        OnboardingApplicationLlcNewActivateComponent,
        OnboardingApplicationSoleExistingDocumentsComponent,
        OnboardingApplicationSoleNewSkvFormComponent,
        OnboardingApplicationSoleNewActivateComponent,
        OnboardingApplicationSoleNewApplicationSkvComponent,
        OnboardingApplicationSoleNewCreateCompanyComponent,
        OnboardingApplicationTermsOfServiceComponent,
        OnboardingCancelTrackConfirmComponent,
        OnboardingShareDistributionConfirmDialogComponent,
        TermsDialogContentComponent,
        TermsDialogComponent
    ],
    exports: [
        OnboardingContainerComponent
    ],
    imports: [
        BrowserModule,
        CommonModule,
        MatButtonModule,
        MatExpansionModule,
        ReactiveFormsModule,
        RedFormModule,
        RedAssetModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatCheckboxModule,
        MatSelectModule,
        AmountModule,
        MatIconModule,
        RedInfoBoxModule,
        RedPipeModule,
        RedGridModule,
        MatRadioModule,
        MatDialogModule,
        SniCodesModule,
        ReadMoreLessModule,
        RedDialogModule,
        RedDisplayModule
    ],
    providers: []
})
export class OnboardingModule {}
