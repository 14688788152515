import {OnInit, Component, OnDestroy} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {RedDialog, DIALOG_RESULT} from '@red/components';
import {ActivatedRoute, Router} from '@angular/router';
import {Receipt} from '@red/data';
import {ReceiptEditComponent} from '../../../components/receipt/edit/edit';

@Component({
    selector: 'app-receipt-edit-view',
    templateUrl: './edit.tpl.html'
})
export class ReceiptEditViewComponent implements OnDestroy, OnInit {
    private _dialogRef: MatDialogRef<ReceiptEditComponent, DIALOG_RESULT>;
    private _redDialog: RedDialog;
    private _route: ActivatedRoute;
    private _router: Router;

    constructor (
        redDialog: RedDialog,
        route: ActivatedRoute,
        router: Router
    ) {
        this._redDialog = redDialog;
        this._route = route;
        this._router = router;
    }

    ngOnDestroy(): void {
        this._dialogRef.close(DIALOG_RESULT.CANCEL);
    }

    ngOnInit(): void {
        this._route.data.subscribe((data: { receipt: Receipt }) => {
            if (data.receipt) {
                this._open(data.receipt);
            }
        });
    }

    private _open(receipt: Receipt) {
        setTimeout(() => {
            this._dialogRef = this._redDialog.open(ReceiptEditComponent, {
                'data': {'receipt': receipt }
            });

            this._dialogRef
                .afterClosed()
                .subscribe((result: DIALOG_RESULT) => {
                    if (result === DIALOG_RESULT.OK) {
                        this._router.navigate(['../../'], {relativeTo: this._route});
                    }
                });
        });
    }
}
