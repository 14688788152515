
import {AppConfig} from '../config/appConfig';
import {COMPANY_STATUS} from '@red/data';
import { Capabilities } from './capabilities';

export enum CAPABILITIES {
    INTERNATIONAL_INVOICING = <any>'internationalInvoicing',
    MULTIPLE_COMPANIES = <any>'multipleCompanies',
    SIGN_UP = <any>'signUp',
    FIRST_EMPLOYEE_TAX_RELIEF = <any>'firstEmployeeTaxRelief',
    ROYALTY_STATEMENT = <any>'royaltyStatement'
}

export class AppCapabilities extends Capabilities {
    constructor (
        config: AppConfig
    ) {
        super(config);
    }

    hasCompanyStatus(statues: COMPANY_STATUS|COMPANY_STATUS[]): boolean {
        if (!Array.isArray(statues)) {
            statues = [statues];
        }

        // if (!this._session.currentRelation) {
        //     return false;
        // }

        return statues.indexOf(COMPANY_STATUS.ACTIVE) !== -1;
    }
}
