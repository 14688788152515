import {AUTH_LOGIN_STATUS, AuthStateModel} from './auth.model';
import {Action, NgxsOnInit, Selector, State, StateContext, StateToken} from '@ngxs/store';
import {catchError, mergeMap, tap} from 'rxjs/operators';
import {
    AuthCollect,
    AuthLogin,
    AuthLoginStatus,
    AuthLogout,
    AuthMe,
    AuthSetActiveCompany,
    AuthSignConsentCount, AuthSignUp, AuthSignUpCollect, AuthSignUpMe
} from './auth.actions';
import {Observable, of, Subject, throwError} from 'rxjs';
import {Injectable} from '@angular/core';
import {AuthManager} from '../../../managers/auth/auth-manager';
import {COMPANY_TYPE, COUNTRY, Me, ROLE} from '@red/data';
import {CompanyConfigurationManager} from '../../../managers/company-configuration/company-configuration-manager';
import {NationalIdentifierValidator} from '@red/validators';
import {ONBOARDING_STATUS, OnboardingListResponse} from '@red/onboarding-service-api';
import {OnboardingServiceClient} from '../../../lab/service-client/onboarding-service-client';
import {BankIdAuthRequest, BankIdAuthResponse, BankIdCollectResponse, QueryFilter} from '@red/browser';
import {RemoteErrorException} from '../../../common/error/remote-error-exception';
import {ErrorResponse} from '../../../common/error/error-response';

export const AUTH_STATE_TOKEN = new StateToken<AuthStateModel>('auth');

@State<AuthStateModel>({
    name: AUTH_STATE_TOKEN,
    defaults: {
        userId: null,
        userName: null,
        identifier: null,
        companyId: null,
        companyName: null,
        companyType: null,
        autostartToken: null,
        sessionToken: null,
        consentsToSign: false,
        rememberMe: false
    }
})
@Injectable()
export class AuthState implements NgxsOnInit {
    private _authManager: AuthManager;
    private _onboardingServiceClient: OnboardingServiceClient;
    private _companyConfigurationManager: CompanyConfigurationManager;

    constructor(
        authManager: AuthManager,
        onboardingServiceClient: OnboardingServiceClient,
        companyConfigurationManager: CompanyConfigurationManager
    ) {
        this._authManager = authManager;
        this._onboardingServiceClient = onboardingServiceClient;
        this._companyConfigurationManager = companyConfigurationManager;
    }

    @Selector()
    static identifier(state: AuthStateModel): string | null {
        return state.identifier;
    }

    @Selector()
    static sessionToken(state: AuthStateModel): string | null {
        return state.sessionToken;
    }

    @Selector()
    static autostartToken(state: AuthStateModel): string | null {
        return state.autostartToken;
    }

    @Selector()
    static isAuthenticated(state: AuthStateModel): boolean {
        return (!!state.sessionToken && !!state.userId);
    }

    @Selector()
    static companyName(state: AuthStateModel): string | null {
        return state.companyName;
    }

    @Selector()
    static companyType(state: AuthStateModel): COMPANY_TYPE | null {
        return state.companyType;
    }

    @Selector()
    static companyId(state: AuthStateModel): string | null {
        return state.companyId;
    }

    @Selector()
    static userId(state: AuthStateModel): string | null {
        return state.userId;
    }

    @Selector()
    static userName(state: AuthStateModel): string | null {
        return state.userName;
    }

    @Selector()
    static consentsToSign(state: AuthStateModel): boolean | null {
        return state.consentsToSign;
    }

    @Selector()
    static rememberMe(state: AuthStateModel): boolean {
        return state.rememberMe;
    }

    ngxsOnInit(ctx: StateContext<AuthStateModel>): void | any {
        ctx.patchState({
            userId: null
        });
    }

    @Action(AuthLogin)
    login(ctx: StateContext<AuthStateModel>, action: AuthLogin): Observable<any> {
        const bankIdAuthRequest = new BankIdAuthRequest({
            identifier: NationalIdentifierValidator.format(action.payload.identifier, COUNTRY.SWEDEN),
            scope: ROLE.OWNER
        });

        return this._login(ctx, bankIdAuthRequest, action.payload.rememberMe)
            .pipe(
                mergeMap((response: BankIdAuthResponse) => {
                    return ctx.dispatch(new AuthCollect({
                        autostartToken: response.autostartToken,
                        authReference: response.authReference
                    }));
                }),
                catchError((err) => {
                    ctx.dispatch(new AuthLoginStatus(AUTH_LOGIN_STATUS.ERROR, null, err));

                    return of(true);
                })
            );
    }

    @Action(AuthSignUp)
    signUp(ctx: StateContext<AuthStateModel>, action: AuthLogin): Observable<any> {
        const bankIdAuthRequest = new BankIdAuthRequest({
            identifier: NationalIdentifierValidator.format(action.payload.identifier, COUNTRY.SWEDEN)
        });

        return this._login(ctx, bankIdAuthRequest, false)
            .pipe(
                mergeMap((response: BankIdAuthResponse) => {
                    return ctx.dispatch(new AuthSignUpCollect({
                        autostartToken: response.autostartToken,
                        authReference: response.authReference
                    }));
                }),
                catchError((err) => {
                    ctx.dispatch(new AuthLoginStatus(AUTH_LOGIN_STATUS.ERROR, null, err));

                    return of(true);
                })
            );
    }

    @Action(AuthCollect)
    collect(ctx: StateContext<AuthStateModel>, action: AuthCollect): Observable<any> {
        return this._collect(ctx, action)
            .pipe(
                mergeMap((response: BankIdCollectResponse) => {
                    return ctx.dispatch(new AuthMe());
                }),
                catchError((err: RemoteErrorException) => {
                    ctx.dispatch(new AuthLoginStatus(AUTH_LOGIN_STATUS.ERROR, null, err));
                    return of(true);
                })
            );
    }

    @Action(AuthSignUpCollect)
    signUpCollect(ctx: StateContext<AuthStateModel>, action: AuthCollect): Observable<any> {
        return this._collect(ctx, action)
            .pipe(
                mergeMap((response: BankIdCollectResponse) => {
                    return ctx.dispatch(new AuthSignUpMe());
                }),
                catchError((err) => {
                    ctx.dispatch(new AuthLoginStatus(AUTH_LOGIN_STATUS.ERROR, null, err));
                    return of(true);
                })
            );
    }

    @Action(AuthMe)
    me(ctx: StateContext<AuthStateModel>, action: AuthMe): Observable<any> {
        return this._me(ctx)
            .pipe(
                mergeMap((response: Me) => {
                    // TODO: We should really move this somewhere else.
                    this._companyConfigurationManager.setConfigurations(response.configurations);
                    return this._handleMeResponse(ctx, response);
                }),
                catchError((err) => {
                    ctx.dispatch(new AuthLoginStatus(AUTH_LOGIN_STATUS.ERROR, null, err));
                    ctx.patchState({
                        sessionToken: null
                    });
                    return of(true);
                })
            );
    }

    @Action(AuthSignUpMe)
    signUpMe(ctx: StateContext<AuthStateModel>, action: AuthMe): Observable<any> {
        return this._me(ctx)
            .pipe(
                mergeMap((response: Me) => {
                    return ctx.dispatch(new AuthLoginStatus(AUTH_LOGIN_STATUS.SIGN_UP_COMPLETE));
                }),
                catchError((err) => {
                    ctx.dispatch(new AuthLoginStatus(AUTH_LOGIN_STATUS.ERROR, null, err));
                    ctx.patchState({
                        sessionToken: null
                    });
                    return of(true);
                })
            );
    }

    @Action(AuthSignConsentCount)
    needToSignConsent(ctx: StateContext<AuthStateModel>, action: AuthSignConsentCount) {
        ctx.patchState({
            consentsToSign: action.payload.count > 0
        });
    }

    @Action(AuthLogout)
    logout(ctx: StateContext<AuthStateModel>) {
        ctx.patchState({
            userId: null,
            userName: null,
            companyId: null,
            companyName: null,
            companyType: null,
            autostartToken: null,
            sessionToken: null,
            consentsToSign: false
        });
    }

    @Action(AuthSetActiveCompany)
    switchCompany(ctx: StateContext<AuthStateModel>, action: AuthSetActiveCompany) {
        return this._authManager.setActiveCompany(action.payload.companyId)
            .pipe(
                mergeMap((response: BankIdCollectResponse) => {
                    ctx.patchState({
                        companyName: action.payload.companyName,
                        companyId: action.payload.companyId,
                        companyType: action.payload.companyType,
                        sessionToken: response.sessionToken
                    });

                    return ctx.dispatch(new AuthLoginStatus(AUTH_LOGIN_STATUS.COMPLETE));
                })
            );
    }

    private _collect(ctx: StateContext<AuthStateModel>, action: AuthCollect) {
        const responseEmitter = this._createResponseEmitter(ctx);

        return this._authManager.collect(action.payload.authReference, responseEmitter)
            .pipe(
                tap((response: BankIdCollectResponse) => {
                    ctx.patchState({
                        companyName: null,
                        companyId: null,
                        companyType: null,
                        sessionToken: response.sessionToken
                    });
                })
            );
    }

    private _createResponseEmitter(ctx: StateContext<AuthStateModel>) {
        const emitter = new Subject<BankIdCollectResponse>();
        emitter.subscribe((response: BankIdCollectResponse) => {
            ctx.dispatch(new AuthLoginStatus(AUTH_LOGIN_STATUS.COLLECTING, response.status));
        });

        return emitter;
    }

    private _me(ctx: StateContext<AuthStateModel>) {
        return this._authManager.me()
            .pipe(
                tap((response: Me) => {
                    ctx.patchState({
                        identifier: response.user.details.identification,
                        userName: response.user.details.firstName,
                        userId: response.user.id,
                        autostartToken: null,
                    });
                })
            );
    }

    private _handleMeResponse(ctx: StateContext<AuthStateModel>, response: Me) {
        const state = ctx.getState();
        const currentRelation = this._authManager.getCurrentRelation(response.relations, state.companyId);

        if (!currentRelation) {
            const userId = ctx.getState().userId;
            const status = ONBOARDING_STATUS.OPEN;
            const filter = new QueryFilter({userId, status});
            return this._onboardingServiceClient.list(filter)
                .pipe(
                    mergeMap((resp: OnboardingListResponse) => {
                        if (resp.length !== 0) {
                            return ctx.dispatch(new AuthLoginStatus(AUTH_LOGIN_STATUS.ONBOARDING));
                        }

                        const errorResponse = new ErrorResponse('No onboarding', null, 1270001);
                        return throwError(RemoteErrorException.fromErrorResponse(errorResponse));
                    })
                )
            ;
        }

        return ctx.dispatch(new AuthSetActiveCompany({
            companyId: currentRelation.company.id,
            companyName: currentRelation.company.name,
            companyType: currentRelation.company.type
        }));
    }

    private _login(ctx: StateContext<AuthStateModel>, bankIdAuthRequest: BankIdAuthRequest, rememberMe: boolean) {
        return this._authManager.login(bankIdAuthRequest)
            .pipe(
                tap((response: BankIdAuthResponse) => {
                    ctx.patchState({
                        autostartToken: response.autostartToken,
                        rememberMe: rememberMe
                    });

                    ctx.dispatch(new AuthLoginStatus(AUTH_LOGIN_STATUS.STARTED));
                })
            );
    }
}
