import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
    RedAssetModule,
    RedDialogModule,
    RedListModule,
    RedLoaderModule,
    RedMarkdownModule,
    RedInfoBoxModule,
    RedFormModule
} from '@red/components';
import {ReceiptCreateComponent} from './create/create';
import {ReceiptEditComponent} from './edit/edit';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import {CardTransactionModule} from '../card-transaction/card-transaction.module';
import {AmountModule} from '../amount/amount.module';
import {ReceiptListPreviewComponent} from './list-preview/list-preview';
import {CommentFormHelper} from '../../common/form/comment-form-helper';
import {ReactiveFormsModule} from '@angular/forms';
import {RedPipeModule} from '@red/ng';
import {ConfirmDialogModule} from '../confirm-dialog/confirm-dialog.module';


@NgModule({
    declarations: [
        ReceiptCreateComponent,
        ReceiptEditComponent,
        ReceiptListPreviewComponent,
    ],
    exports: [
        ReceiptListPreviewComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        RedAssetModule,
        RedDialogModule,
        RedInfoBoxModule,
        RedFormModule,
        RedListModule,
        RedLoaderModule,
        RedMarkdownModule,
        RedPipeModule,
        AmountModule,
        CardTransactionModule,
        ConfirmDialogModule
    ],
    providers: [
        CommentFormHelper
    ]
})
export class ReceiptModule {}
