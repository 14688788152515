<app-end-of-year-info-texts></app-end-of-year-info-texts>

<h3 class="form-section-header" i18n="End of year|header|dividends">Dividends</h3>
<div class="info-text" i18n="End of year|info text about dividends">
    About dividends <span [innerHTML]="simplificationRuleAmount | amount : 0"></span> {{januaryFirstCurrentYear}}. <a href="{{endOfYearDividendsAndAnnualReportLink}}" target="_blank">Read about it</a>
</div>

<table class="end-of-year-table dividends-table">
    <tbody>
    <tr>
        <th class="dividends-th" i18n="End of year|dividends|possible dividends">Possible dividends</th>
        <td class="amount" [innerHTML]="possibleDividends | amount"></td>
    </tr>    <tr>
        <th class="dividends-th" i18n="End of year|dividends|possible shareholderContributionPayback">Possible shareholder contribution payback</th>
        <td class="amount" [innerHTML]="shareholderContributionPayback | amount"></td>
    </tr>
    </tbody>
</table>

<form [formGroup]="dividendsForm">
    <div class="form-field">
        <div class="form-row full-width-single-element-row">
            <mat-form-field class="right">
                <input
                    matInput
                    required
                    i18n-placeholder="End of year|input placeholer|dividend"
                    placeholder="dividend"
                    formControlName="dividend"
                    class="right"
                    type="number"
                    autocomplete="off"
                />
                <span matSuffix class="currency">kr</span>
                <mat-error *ngIf="dividendsForm.get('dividend').hasError('dividendsExceedsMax')" i18n="End of year|Dividends exceed error">
                    Dividends can't exceed max possible dividends
                </mat-error>
            </mat-form-field>
        </div>
    </div>

    <div class="form-field">
        <div class="form-row full-width-single-element-row">
            <mat-form-field class="right">
                <input
                    matInput
                    required
                    i18n-placeholder="End of year|input placeholer|shareholderContributionPayback"
                    placeholder="Shareholder contribution payback"
                    formControlName="shareholderContributionPayback"
                    class="right"
                    type="number"
                    autocomplete="off"
                />
                <span matSuffix class="currency">kr</span>
                <mat-error *ngIf="dividendsForm.get('shareholderContributionPayback').hasError('shareholderPaybackExceedsMax')" i18n="End of year|Shareholder contribution exceed error">
                    Shareholder contribution payback can't exceed max possible shareholder contribution payback
                </mat-error>
            </mat-form-field>
        </div>
    </div>

    <mat-error *ngIf="dividendsForm.hasError('totalExceedsMax')" class="dividends-form-total-error" i18n="End of year|Total exceeds possible dividends error">
        Dividends and shareholder contribution payback can't exceed max possible dividends
    </mat-error>

    <div class="warning-text" i18n="End of year|warning text|About tax and dividends">
        About tax and dividends
    </div>

    <div class="button-wrapper">
        <button mat-flat-button class="mat-primary" i18n="save" [disabled]="disableDividendsForm" (click)="saveDividendsDecision()">Save</button>
    </div>
</form>
