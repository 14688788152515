import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DIALOG_RESULT} from '@red/components';

export interface ConfirmDialogConfig {
    title: string;
    text: string;
    noButtonText?: string;
    yesButtonText?: string;
}

@Component({
    selector: 'app-confirm-dialog',
    styleUrls: ['./confirm-dialog.sass'],
    templateUrl: 'confirm-dialog.tpl.html'
})

export class ConfirmDialogComponent {
    get text(): string { return this._text; }
    get title(): string { return this._title; }
    get noButtonText(): string { return this._noButtonText; }
    get yesButtonText(): string { return this._yesButtonText; }

    private _dialogRef: MatDialogRef<ConfirmDialogComponent>;
    private _data: ConfirmDialogConfig;
    private _title: string;
    private _text: string;
    private _yesButtonText: string;
    private _noButtonText: string;

    constructor (
        dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: ConfirmDialogConfig
    ) {
        this._dialogRef = dialogRef;
        this._data = data;

        this._title = data.title;
        this._text = data.text;

        const defaultNo = $localize`:Common|No:No`;
        const defaultYes = $localize`:Common|Yes:Yes`;

        this._noButtonText = data.noButtonText || defaultNo;
        this._yesButtonText = data.yesButtonText || defaultYes;
    }

    cancel() {
        this._dialogRef.close(DIALOG_RESULT.CANCEL);
    }

    confirm() {
        this._dialogRef.close(DIALOG_RESULT.OK);
    }
}
