import {NgModule} from '@angular/core';
import {CardTransactionIconComponent} from './icon/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import {CardTransactionEditComponent} from './edit/edit';
import {CommonModule} from '@angular/common';
import {
    RedAssetModule,
    RedDialogModule,
    RedListModule,
    RedInfoBoxModule,
    RedLoaderModule,
    RedMarkdownModule,
    RedFormModule
} from '@red/components';
import {AmountModule} from '../amount/amount.module';
import {CardTransactionMarkAsDebtConfirmComponent} from './confirm/markAsDebt/markAsDebt';
import {CardTransactionCantMarkAsDebtConfirmComponent} from './confirm/cantMarkAsDebt/cantMarkAsDebt';
import {CardTransactionRefundComponent} from './refund/refund';
import {CardTransactionWaitForSettlementCofirmComponent} from './confirm/wait-for-settlement/wait-for-settlement';
import {ReactiveFormsModule} from '@angular/forms';
import {CommentFormHelper} from '../../common/form/comment-form-helper';
import {RedPipeModule} from '@red/ng';

@NgModule({
    declarations: [
        CardTransactionEditComponent,
        CardTransactionIconComponent,
        CardTransactionCantMarkAsDebtConfirmComponent,
        CardTransactionMarkAsDebtConfirmComponent,
        CardTransactionRefundComponent,
        CardTransactionWaitForSettlementCofirmComponent
    ],
    exports: [
        CardTransactionEditComponent,
        CardTransactionIconComponent,
        CardTransactionCantMarkAsDebtConfirmComponent,
        CardTransactionMarkAsDebtConfirmComponent,
        CardTransactionRefundComponent,
        CardTransactionWaitForSettlementCofirmComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatDialogModule,
        MatDividerModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        RedAssetModule,
        RedDialogModule,
        RedInfoBoxModule,
        RedFormModule,
        RedListModule,
        RedLoaderModule,
        RedMarkdownModule,
        RedPipeModule,
        AmountModule
    ],
    providers: [
        CommentFormHelper
    ]
})
export class CardTransactionModule {}

