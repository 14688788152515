<app-view-card>
    <red-top-toolbar>
        <span redTopToolbarTitle i18n="Top Toolbar|Main title for Error 404 View">Not found</span>
    </red-top-toolbar>
    <div class="error-404">
        <div class="sloth"><img src="/assets/tmp/empty.gif" /></div>
        <p i18n="Error message|Content text for 404 error message">
            Oops, the thing you're looking for couldn't be found. Go back and try again. If the error occurs again, please <a [routerLink]="['/support']">contact support</a>.
        </p>
    </div>
</app-view-card>
