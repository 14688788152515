<div class="announcement-dialog-content confirm-content" mat-dialog-content>
    <div class="red-alert-title">{{title}}</div>
    <div class="confirm-dialog-content" [innerHTML]="message">

    </div>
</div>
<mat-dialog-actions class="red-alert-actions announcement-dialog-actions">
    <button mat-button class="mat-primary" tabindex="-1" (click)="cancel()" i18n="Announcement dialog|Cancel button label">Close</button>
    <button mat-flat-button class="mat-primary" tabindex="-1" (click)="dismiss()" i18n="Announcement dialog|Dismiss button label">Don't show again</button>
</mat-dialog-actions>
