import {MatDialogModule} from '@angular/material/dialog';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {ReauthorizeWorkflow} from './reauthorize-workflow';
import {RedDialogModule, RedFormModule, RedLoaderModule} from '@red/components';
import {ReauthorizeDialogComponent} from './reauthorize-dialog.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';

@NgModule({
    declarations: [
        ReauthorizeDialogComponent
    ],
    exports: [
        ReauthorizeDialogComponent
    ],
    imports: [
        BrowserModule,
        MatDialogModule,
        RedDialogModule,
        ReactiveFormsModule,
        RedFormModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        RedLoaderModule
    ],
    providers: [
        ReauthorizeWorkflow
    ]
})
export class ReauthorizeModule {}
