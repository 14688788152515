import {NgModule} from '@angular/core';

import {ViewCardComponent} from './view-card';
import { MatCardModule } from '@angular/material/card';
import {ViewCardInfobarComponent} from './view-card-infobar/view-card-infobar';

@NgModule({
    declarations: [
        ViewCardComponent,
        ViewCardInfobarComponent
    ],
    exports: [
        ViewCardComponent,
        ViewCardInfobarComponent
    ],
    imports: [
        MatCardModule
    ]
})
export class ViewCardModule {}
