import {Component, HostBinding, Inject, LOCALE_ID} from '@angular/core';
import {
    Company,
    CompanyUser,
    CreateUserRequest,
    Relation,
    ROLE,
    Stock,
    User,
    UserDetails
} from '@red/data';
import {Analytics} from '../../../common/analytics/analytics';
import {AppCapabilities, CAPABILITIES} from '../../../common/appCapabilities';
import {DIALOG_RESULT, RedNotification, RedDialog} from '@red/components';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {EventSource} from '../../../common/event/source';
import {mergeMap} from 'rxjs/operators';
import {EmployeeCreatedEvent} from '../../../common/event/readers/employee';
import {Observable} from 'rxjs';
import {UserManager} from '../../../managers/user/user.manager';
import {ShareholderManager} from '../../../managers/shareholder/shareholder.manager';
import {Store} from '@ngxs/store';
import {AuthState} from '../../../shared/state/auth/auth.state';
import {RemoteErrorException} from '../../../common/error/remote-error-exception';

@Component({
    selector: 'app-create-user',
    styleUrls: ['./create-user.sass'],
    templateUrl: 'create-user.tpl.html'
})

export class CreateUserComponent<T> {
    @HostBinding('class.create-user') cssClass = true;

    get canAddEmployee(): boolean { return this._canAddEmployee; }
    get isFirstEmployee(): boolean { return this._isFirstEmployee; }
    get userRole(): ROLE { return this._userRole; }
    get canEdit(): boolean { return this._canEdit; }

    private _analytics: Analytics;
    private _capabilities: AppCapabilities;
    private _canAddEmployee = true;
    private _store: Store;
    private _dialog: RedDialog;
    private _dialogRef: MatDialogRef<T>;
    private _employees: CompanyUser[];
    private _isFirstEmployee = false;
    private _notification: RedNotification;
    private _eventSource: EventSource;
    private _userRole: ROLE;
    private _userManager: UserManager;
    private _shareholderManager: ShareholderManager;
    private _currentStock: Stock;
    private _canEdit = true;

    constructor (
        analytics: Analytics,
        capabilities: AppCapabilities,
        store: Store,
        dialog: RedDialog,
        dialogRef: MatDialogRef<T>,
        eventSource: EventSource,
        notification: RedNotification,
        userManager: UserManager,
        shareholderManager: ShareholderManager,
        @Inject(MAT_DIALOG_DATA) data: any,
        @Inject(LOCALE_ID) localeId: string
    ) {
        this._analytics = analytics;
        this._capabilities = capabilities;
        this._dialog = dialog;
        this._dialogRef = dialogRef;
        this._store = store;
        this._notification = notification;
        this._eventSource = eventSource;
        this._userManager = userManager;
        this._shareholderManager = shareholderManager;
    }

    initEmployee() {
        this._userManager.getCompanyAndCompanyUsers(this._store.selectSnapshot(AuthState.companyId))
            .subscribe(([company, companyUsers]: [Company, CompanyUser[]]) => {
                const employees = this._filterForEmployees(companyUsers);
                const taxationData = company.details.taxationData;

                const hasUsedTaxRelief = taxationData && taxationData.firstEmployeePermit && taxationData.firstEmployeePermit.employeeId;
                this._isFirstEmployee = employees.length === 0 && !hasUsedTaxRelief && this._capabilities.hasAppCapability(CAPABILITIES.FIRST_EMPLOYEE_TAX_RELIEF.toString());
            });
    }

    initShareholder() {
        this._shareholderManager.getStock(this._store.selectSnapshot(AuthState.companyId))
            .subscribe((currentStock: Stock) => {
                this._currentStock = currentStock;
            });
    }

    setRole(role: ROLE) {
        this._userRole = role;
    }

    close() {
        this._dialogRef.close(DIALOG_RESULT.OK);
    }

    createEmployee(details: UserDetails) {
        if (this._isEmployeeExist(details.identification)) {
            this._canAddEmployee = false;
            const msg = details.identification + $localize`:RedNotification|err message when user try to add employee again: already added`;
            this._notification.open(msg);
        } else {
            this._createEmployee(details);
        }
    }

    addUserForShareholder(details: UserDetails) {
        const userRequest = this._userManager.getCreateUserRequest(details, ROLE.SHARE_HOLDER);

        this._userManager.createUser(userRequest)
            .subscribe((user: User) => {
                this._dialogRef.close(user);
            },
            (err: RemoteErrorException) => this._createUserError(err, userRequest, details, ROLE.SHARE_HOLDER)
        );
    }

    resetCanAddEmployee() {
        this._canAddEmployee = true;
    }

    private _getExistingUserForShareholder(details: UserDetails) {
        this._userManager.getUserByIdentifier(details.identification)
            .subscribe((user: User) => {
                this._dialogRef.close(user);
            });
    }

    private _handleIsShareholderError(err: RemoteErrorException, done) {
        console.log('_handleIsShareholderError: err = ', err);

        done();
    }

    private _createUserError(err: RemoteErrorException, userRequest: CreateUserRequest, details: UserDetails, role: ROLE) {
        if (err.errorCode === 44003) {
            this._handleUserExists(userRequest, details, role);
        } else {
            this._onUserCreationError(err, role);
        }
    }

    private _createEmployee(details: UserDetails) {
        const userRequest = this._userManager.getCreateUserRequest(details, ROLE.EMPLOYEE);

        this._userManager.createUser(userRequest)
            .pipe(mergeMap(usr => this._firstEmployeePermit(usr, details)))
            .subscribe(
                () => {
                    this._onUserCreationSuccess();
                },
                (err: RemoteErrorException) => this._createUserError(err, userRequest, details, ROLE.EMPLOYEE)
            );
    }

    private _filterForEmployees(companyUsers: CompanyUser[]) {
        return companyUsers.filter(user => {
            return user.relations.find((relation: Relation) => relation.role === ROLE.EMPLOYEE);
        });
    }

    private _addUserAsEmployee(userRequest: CreateUserRequest, details: object) {
        this._userManager.createRelation(userRequest)
            .pipe(mergeMap(user => this._firstEmployeePermit(user, details)))
            .subscribe(
                user => {
                    this._onUserCreationSuccess();
                },
                (err: RemoteErrorException) => {
                    this._onUserCreationError(err, ROLE.EMPLOYEE);
                }
            );
    }

    private _isEmployeeExist(identification): boolean {
        return !!(this._employees && this._employees.filter(user => user.user.details.identification === identification).length > 0);
    }

    private _onUserCreationError(err: RemoteErrorException, role: ROLE) {
        let msg;

        if (err.errorCode === 44001) {
            msg = $localize`:RedNotification|A user with the given email already exists:Email already exists`;
        } else {
            msg = $localize`:RedNotification|A user failed to be created notification:Failed to create`;
        }

        this._notification.errorWithCode(err, msg);
    }

    private _handleUserExists(userRequest: CreateUserRequest, details: any, role: ROLE) {
        switch (role) {
            case ROLE.SHARE_HOLDER:
                this._getExistingUserForShareholder(details);
        }
    }

    private _getCreationSuccessMessage() {
        const msg = $localize`:RedNotification|'A new employee was created notification:A user was created`;

        return msg;
    }

    private _onUserCreationSuccess(): void {
        const msg = this._getCreationSuccessMessage();

        this._notification.open(msg);
        this._eventSource.emit(new EmployeeCreatedEvent());
        this.close();
    }

    private _firstEmployeePermit(user: User, details: any): Observable<User> {
        return this._userManager.firstEmployeePermit(user, details);
    }
}
