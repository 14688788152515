import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RedFlagShutDownBannerComponent} from './red-flag-shut-down-banner';
import {ViewCardModule} from '../view-card/view-card.module';

@NgModule({
    declarations: [
        RedFlagShutDownBannerComponent
    ],
    exports: [
        RedFlagShutDownBannerComponent
    ],
    imports: [
        CommonModule,
        ViewCardModule
    ]
})
export class RedFlagShutDownBannerModule {}
