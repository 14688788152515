<span *ngIf="annualReport">
    <red-info-box class="dividends-warning-info-box" redInfoBoxList>
        <span i18n="Dividends payout|List view|information text">Text about dividends taxes</span>
    </red-info-box>
    <app-dividends-calculation
        [annualReport]="annualReport"
        [limit]="limit$ | async">
    </app-dividends-calculation>
</span>
<div [ngSwitch]="state">
    <red-skeleton-loader *ngSwitchCase="stateLoading" [rows]="2"></red-skeleton-loader>
    <div *ngSwitchCase="stateNoResult" class="no-result">
        <red-blank-slate icon="list-dividends">
            <span *ngIf="annualReport" i18n="Dividends|blank slate">No dividends has been paid</span>
            <span *ngIf="!annualReport" i18n="Dividends|blank slate with no report">You can not pay dividends as you have not done your annual report yet</span>
        </red-blank-slate>
    </div>
    <div *ngSwitchCase="stateDone">
        <red-list *ngIf="pendingDividendsList.hasResults" class="pending-dividends-list">
            <span redListHeader i18n="List header for pending dividends">Pending dividends</span>
            <mat-list-item *ngFor="let transaction of pendingDividendsList.items | async">
                <red-list-icon mat-list-avatar [item]="transaction"></red-list-icon>
                <h4 mat-line>{{ transaction.description }}</h4>
                <time mat-line>{{transaction.createdAt | moment }}</time>
                <app-display-amount-text [amount]="transaction.total" type="SALARY"></app-display-amount-text>
            </mat-list-item>
        </red-list>
        <button *ngIf="!pendingDividendsList.complete" mat-button class="invoice-load-more-btn" i18n="Load more bills" (click)="loadMore(pendingDividendsList)">Load more...</button>

        <red-list *ngIf="confirmedDividendsList.hasResults" class="confirmed-dividends-list">
            <span redListHeader i18n="List header for confirmed employees salaries">Confirmed salaries</span>
            <a redListHeaderLink [routerLink]="['/history']" [queryParams]="{ type: 'financial_payment' }" i18n="see all button link to history">SEE ALL</a>
            <mat-list-item *ngFor="let transaction of confirmedDividendsList.items | async">
                <red-list-icon mat-list-avatar [item]="transaction"></red-list-icon>
                <h4 mat-line>{{ transaction.description }}</h4>
                <time mat-line>{{transaction.createdAt | moment }}</time>
                <app-display-amount-text [amount]="transaction.total" type="SALARY"></app-display-amount-text>
            </mat-list-item>
        </red-list>
    </div>
</div>
<router-outlet></router-outlet>
