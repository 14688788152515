
import {throwError as observableThrowError, Observable, Subject, of} from 'rxjs';
import {
    BankIdAuthProvider, BankIdAuthResponseOld, BankIdSessionResponse,
    BANKID_RETRY_STRATEGY
} from '../security/auth/bankIdProvider';

import {BANKID_RETRY_STATE, BANKID_ERROR_STATE} from '@red/data';
import {AuthErrorResponse} from '../security/auth/provider';
import {delay, mergeMap, retryWhen} from 'rxjs/operators';
import {ServerError} from '@red/common';


export interface BankIdWorkflowStrategy {
    retries: number;
    retryStrategy: BANKID_RETRY_STRATEGY;
}

export abstract class BankIdWorkflow {
    protected _authProvider: BankIdAuthProvider;
    protected _strategy: BankIdWorkflowStrategy;
    private _responseEmitter: Subject<BankIdSessionResponse>;

    constructor(
        authProvider: BankIdAuthProvider,
        strategy: BankIdWorkflowStrategy = {'retries': 40, 'retryStrategy': BANKID_RETRY_STRATEGY.BACKOFF}
    ) {
        this._authProvider = authProvider;
        this._strategy = strategy;

        this._responseEmitter = new Subject<BankIdSessionResponse>();
    }

    get onResponse(): Subject<BankIdSessionResponse> { return this._responseEmitter; }

    protected _poll(resp: BankIdAuthResponseOld): Observable<BankIdSessionResponse> {
        let retries = 0;
        return this._authProvider.exchangeAuthReferenceForSession(resp.result.authReference)
            .pipe(
                mergeMap((response: BankIdSessionResponse) => {
                    this._responseEmitter.next(response);
                    if (this._shouldRetry(response.result.status)) {
                        return observableThrowError(response);
                    }

                    this._responseEmitter.complete();
                    return of(response);
                }),
                retryWhen<BankIdSessionResponse>(errors => {
                    retries += 1;
                    return errors.pipe(
                        mergeMap((err) => {
                            if (this._strategy.retries + 1 === retries) {
                                return observableThrowError(new Error(`Polling failed after ${this._strategy.retries} attempts`));
                            }

                            if (this._shouldThrowError(err)) {
                                return observableThrowError(err);
                            }

                            return of(retries);
                        }),
                        delay(this._nextRetry(retries))
                    );
                })
            );
    }

    private _nextRetry(retries: number) {
        let n;

        switch (this._strategy.retryStrategy) {
            case BANKID_RETRY_STRATEGY.BACKOFF:
                n = (Math.floor(retries / 10) * 250) + 500;
                break;
            case BANKID_RETRY_STRATEGY.SEQUENTIAL:
                n = 500;
                break;
            default:
                throw new Error(`Unknown retry strategy ${this._strategy.retryStrategy}`);
        }

        return n;
    }

    private _shouldRetry(state: string): boolean {
        return BANKID_RETRY_STATE[state] !== undefined;
    }

    private _shouldThrowError(resp: AuthErrorResponse|any): boolean {
        if (resp instanceof AuthErrorResponse) {
            return (!(resp.error instanceof ServerError && resp.error.errorCode === 0));
        }

        if (resp instanceof BankIdSessionResponse) {
            return resp.result && BANKID_ERROR_STATE[resp.result.status] !== undefined;
        }

        return !!resp;
    }
}
