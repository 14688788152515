import {Routes} from '@angular/router';
import {DividendsViewComponent} from './dividends';
import {ValidSessionGuard} from '../../security/route/session';
import {DividendsListViewComponent} from './list/list';
import {DividendsCreateViewComponent} from './create/create';
import {DividendsRouteResolve} from './dividends.route.resolve';
import {FinancialStatementProjectionRouteResolve} from '../../shared/route/financial-statement-projection-route-resolve';

export const DIVIDENDS_ROUTES: Routes = [
    {
        path: 'dividends',
        component: DividendsViewComponent,
        canActivate: [ValidSessionGuard],
        resolve: {
            annualReport: DividendsRouteResolve,
            projection: FinancialStatementProjectionRouteResolve
        },
        children: [
            {
                path: '',
                component: DividendsListViewComponent,
                children: [
                    { path: 'create', component: DividendsCreateViewComponent }
                ]
            }
        ]
    }
];
