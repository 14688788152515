<mat-expansion-panel class="mat-expansion-panel {{statusClassName}}" [ngClass]="{'disabled': (enabled$ | async) === false}" [disabled]="(enabled$ | async) === false">
    <mat-expansion-panel-header>
        <mat-panel-title>
            Ditt aktiekapitalkonto hos Bokio
        </mat-panel-title>
        <mat-panel-description></mat-panel-description>
    </mat-expansion-panel-header>
    <section class="info-section">
        <ng-container [ngSwitch]="application.status">
            <div class="info-text" *ngSwitchCase="'DONE'">
                Nu öppnar vi ditt aktiekapitalkonto hos Bokio för att kunna ta emot aktiekapitalet.
            </div>
            <div class="info-text" *ngSwitchDefault>
                <p>Vi kommer nu gå igenom dina företagsuppgifter och förbereda din ansökan till Bolagsverket samt öppna ditt aktiekapitalkonto i Bokio.</p>
                <p>När det är klart kontaktar vi dig och då kommer du kunna logga in och se dina uppgifter till överföringen av ditt aktiekapital.</p>
            </div>
        </ng-container>
    </section>
</mat-expansion-panel>
