import {Component, HostBinding, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {OnboardingState} from '../../shared/state/onboarding/onboarding.state';
import {Observable, Subscription} from 'rxjs';
import {Actions, ofActionSuccessful, Select, Store} from '@ngxs/store';
import {COMPANY_STATUS, Me, Relation, ROLE, User} from '@red/data';
import {AuthManager} from '../../managers/auth/auth-manager';
import {AppConfig} from '../../config/appConfig';
import {ONBOARDING_APPLICATION_STATUS, ONBOARDING_TRACK, OnboardingApplication} from '@red/onboarding-service-api';
import {AuthLogout, AuthSetActiveCompany} from '../../shared/state/auth/auth.actions';
import {map} from 'rxjs/operators';
import {AuthErrorResponse} from '../../../tmp-red-client/security/auth/provider';
import {AuthState} from '../../shared/state/auth/auth.state';
import {
    OnboardingCancelTrack,
    OnboardingListApplicationsSuccessful
} from '../../shared/state/onboarding/onboarding.actions';

@Component({
    selector: 'app-onboarding-view',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./onboarding.sass'],
    templateUrl: 'onboarding.tpl.html'
})
export class OnboardingViewComponent implements OnInit, OnChanges, OnDestroy {
    @HostBinding('class.scroll-view') cssClass = true;
    @Select(OnboardingState.hasOnboarding) hasOnboarding$: Observable<boolean>;

    get track$(): Observable<ONBOARDING_TRACK> { return this._track$; }
    get canSwitchCompanies(): Observable<boolean> { return this._canSwitchCompanies; }
    get relations(): Observable<Relation[]> { return this._relations$; }
    get showContactInfo(): boolean {
        return this._showContactInfo;
    }
    get contactEmail(): string {
        return this._contactEmail;
    }
    get applications(): Observable<OnboardingApplication[]> {
        return this._applications;
    }
    get showOnboardingIntro(): boolean {
        return this._showOnboardingIntro;
    }

    private _authManager: AuthManager;
    private _appConfig: AppConfig;
    private _store: Store;
    private _track$: Observable<ONBOARDING_TRACK>;
    private _relations$: Observable<Relation[]>;
    private _canSwitchCompanies: Observable<boolean>;
    private _currentCompanyId: string;
    private _showContactInfo = false;
    private _showOnboardingIntro = false;
    private _contactEmail = 'startaforetag@bokio.se';
    private _actions: Actions;
    private _applications: Observable<OnboardingApplication[]>;
    private _appSub: Subscription;
    private _cancelTrack: Observable<OnboardingCancelTrack>;
    private _cancelTrackSub: Subscription;

    constructor(
        authManager: AuthManager,
        appConfig: AppConfig,
        store: Store,
        actions: Actions
    ) {
        this._authManager = authManager;
        this._appConfig = appConfig;
        this._store = store;
        this._actions = actions;
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log(`changes = ${JSON.stringify(changes, null, 4)}`);
    }

    ngOnInit(): void {
        this._authManager.me()
            .subscribe((me: Me) => {
                this._startIntercom(me.user);
            });

        this._track$ = this._store.select(OnboardingState.track);

        this._currentCompanyId = this._store.selectSnapshot(AuthState.companyId);
        this._setRelations();

        this._applications = this._actions
            .pipe(
                ofActionSuccessful(OnboardingListApplicationsSuccessful),
                map((action: OnboardingListApplicationsSuccessful) => action.payload.applications),
            );

        this._appSub = this._applications.subscribe((apps) =>  {
            const firstFiveNotDraft = apps.slice(0, 5).filter(app => {
                return Object.keys(app.data).length;
            });

            this._showOnboardingIntro = firstFiveNotDraft.length === 0;
            this._showContactInfo = apps && apps.length && !this._showOnboardingIntro;
        });

        this._cancelTrack = this._actions
            .pipe(
                ofActionSuccessful(OnboardingCancelTrack),
                map((action: OnboardingCancelTrack) => action),
            );

        this._cancelTrackSub = this._cancelTrack.subscribe((woot) => {
            this._showOnboardingIntro = false;
            this._showContactInfo = false;
        });
    }

    ngOnDestroy() {
        this._appSub.unsubscribe();
        this._cancelTrackSub.unsubscribe();
    }

    logout() {
        this._store.dispatch(new AuthLogout());
    }

    isCurrentCompany(relation: Relation): boolean {
        return this._currentCompanyId && relation.company.id === this._currentCompanyId;
    }

    selectRelation(relation: Relation) {
        this._store.dispatch(new AuthSetActiveCompany({companyType: relation.company.type, companyId: relation.company.id, companyName: relation.company.name}))
            .subscribe(() => {
                window.location.href = '/';
            }, (err: AuthErrorResponse) => {
                const error = err.error;

                if (error.errorCode > 40000 && error.errorCode < 40400) {
                    this.logout();
                }

                const msg = $localize`:RedNotification|Error message presented to user when failed to switching company:An error occurred while switching companies. Please try again.`;

                // this._notification.errorWithCode(error, msg);
            });
    }

    private _setRelations() {
        this._relations$ = this._authManager.me()
            .pipe(
                map(me => {
                    return me.relations
                        .filter(relation => relation.role === ROLE.OWNER)
                        .filter(relation => relation.company.status === COMPANY_STATUS.ACTIVE);
                })
            );

        this._canSwitchCompanies = this._relations$
            .pipe(
                map((relations) => relations.length > 1)
            );
    }

    private _startIntercom(user: User) {
        const appId = this._appConfig.get(`intercom.appId`);
        if (appId) {
            window['Intercom']('boot', {
                'app_id': appId,
                'name': `${user.details.firstName} ${user.details.lastName}`,
                'email': user.details.email,
                'created_at': Math.floor(Date.now() / 1000),
                'custom_launcher_selector': '.intercom',
                'hide_default_launcher': true
            });
        }
    }
}
