import {Component, HostBinding, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {EventSource} from '../../../common/event/source';
import {RedNotification} from '@red/components';
import {RoyaltyStatementCreatedEvent} from '../../../common/event/readers/royalty-statement';
import {AuthState} from '../../../shared/state/auth/auth.state';
import {Store} from '@ngxs/store';
import { RoyaltyServiceClient } from '../../../lab/service-client/royalty-service-client';

@Component({
    selector: 'app-royalty-statement-create',
    styleUrls: ['./create.sass'],
    templateUrl: './create.tpl.html'
})

export class RoyaltyStatementCreateComponent {
    @HostBinding('class.royalty-statement-view') cssClass = true;

    get royaltyStatementImage(): File { return this._royaltyStatementImage; }
    get uploading(): boolean { return this._uploading; }

    private _dialogRef: MatDialogRef<RoyaltyStatementCreateComponent>;
    private _eventSource: EventSource;
    private _notification: RedNotification;
    private _royaltyStatementImage: File;
    private _royaltyServiceClient: RoyaltyServiceClient;
    private _store: Store;
    private _uploading = false;

    constructor (
        dialogRef: MatDialogRef<RoyaltyStatementCreateComponent>,
        eventSource: EventSource,
        royaltyServiceClient: RoyaltyServiceClient,
        notification: RedNotification,
        store: Store,
        @Inject(MAT_DIALOG_DATA) data: any
    ) {
        this._dialogRef = dialogRef;
        this._eventSource = eventSource;
        this._notification = notification;
        this._royaltyServiceClient = royaltyServiceClient;
        this._store = store;

        this._royaltyStatementImage = data.file;
    }

    close() {
        this._dialogRef.close();
    }

    save() {
        this._upload();
    }

    private _upload() {
        this._uploading = true;
        const formData = new FormData();
        formData.append('file', this._royaltyStatementImage);
        formData.append('companyId', this._store.selectSnapshot(AuthState.companyId));
        this._royaltyServiceClient.create(formData)
            .subscribe(() => {
                this._eventSource.emit(new RoyaltyStatementCreatedEvent());
                this._dialogRef.close();
            }, (err) => {
                this._uploading = false;

                const msg = $localize`:Error message|Failed to upload image:Failed to upload an image as an income stream`;
                this._notification.errorWithCode(err, msg);
            });
    }

}
