import {AfterContentInit, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {
    Amount, AnnualReport,
    CURRENCY, Me, Reference, REFERENCE_TYPE,
    Relation,
    ROLE,
    Shareholder, ShareholderDetails,
    ShareholderListResponse,
    Stock, UpdateShareholdersRequest,
    User
} from '@red/data';
import {CreateShareholderComponent} from '../../../shareholder/create-shareholder/create-shareholder';
import {RedDialog} from '@red/components';
import { MatDialogRef } from '@angular/material/dialog';
import {UserManager} from '../../../../managers/user/user.manager';
import {ShareholderManager} from '../../../../managers/shareholder/shareholder.manager';
import * as moment from 'moment';
import {AnnualReportManager} from '../../../../managers/annual-report/annual-report.manager';
import {EndOfYearManager} from '../../../../managers/end-of-year/end-of-year.manager';
import {Store} from '@ngxs/store';
import {AuthState} from '../../../../shared/state/auth/auth.state';
import {AuthManager} from '../../../../managers/auth/auth-manager';

@Component({
    selector: 'app-end-of-year-step-shareholder-information',
    styleUrls: ['../../end-of-year.dialog.sass', 'end-of-year-step-shareholder-information.sass'],
    templateUrl: 'end-of-year-step-shareholder-information.tpl.html'
})

export class EndOfYearStepShareholderInformationComponent implements OnInit, AfterContentInit {
    @Output() allTheShareholderFormsAreValid: EventEmitter<boolean> = new EventEmitter();

    get selectedDividend(): Amount { return this._selectedDividend; }
    get nonDistributedShares(): number { return this._nonDistributedShares; }
    get shareholdersForm(): FormGroup { return this._shareholdersForm; }
    get shareholders(): Shareholder[] { return this._shareholders; }
    get valuePerShare(): Amount { return this._valuePerShare; }
    get shareholderInformationForm(): FormGroup { return this._shareholderInformationForm; }
    get showShareholdersForm(): boolean { return this._shareholdersIsVisible || this._showShareholdersForm; }
    get shareholdersHasNotChanged(): boolean { return this._shareholdersForm.pristine && this._shareholdersForm.untouched; }
    get hasMoneyAndSharesAndIsPristine(): boolean { return this._hasMoneyAndSharesAndIsPristine; }
    get ownerIsShareholder(): boolean { return this._ownerIsShareholder; }
    get hasShareholders(): boolean { return !!this._shareholders.length; }

    private _companyId: string;
    private _dialog: RedDialog;
    private _authManager: AuthManager;
    private _dialogRef: MatDialogRef<CreateShareholderComponent>;
    private _formBuild: FormBuilder;
    private _shareholderInformationForm: FormGroup;
    private _shareholdersForm: FormGroup;
    private _valuePerShare: Amount;
    private _nonDistributedShares: number;
    private _distributedShares: number;
    private _selectedDividend: Amount;
    private _shareholders: Shareholder[] = [];
    private _userManager: UserManager;
    private _shareholderManager: ShareholderManager;
    private _totalNbrOfShares: number;
    private _store: Store;
    private _hasMoneyAndSharesAndIsPristine: boolean;
    private _showShareholdersForm: boolean;
    private _today: string;
    private _shareholdersIsVisible = false;
    private _ownerIsShareholder = false;

    private _requiredNumberValidators = [
        Validators.required,
        Validators.pattern('^\\d+$'),
        (formControl: FormControl) => {
            return (formControl.value >= 0)
                ? null
                : {
                    message: 'No'
                };
        }
    ];
    private _annualReportManager: AnnualReportManager;
    private _endOfYearManager: EndOfYearManager;

    constructor (
        formBuild: FormBuilder,
        authManager: AuthManager,
        dialog: RedDialog,
        userManager: UserManager,
        shareholderManager: ShareholderManager,
        store: Store,
        annualReportManager: AnnualReportManager,
        endOfYearManager: EndOfYearManager
    ) {
        this._formBuild = formBuild;
        this._authManager = authManager;
        this._dialog = dialog;
        this._userManager = userManager;
        this._shareholderManager = shareholderManager;
        this._store = store;
        this._annualReportManager = annualReportManager;
        this._endOfYearManager = endOfYearManager;

        this._valuePerShare = Amount.SEK(0);
        this._selectedDividend = Amount.SEK(0);

        this._hasMoneyAndSharesAndIsPristine = false;
        this._showShareholdersForm = false;
    }

    ngOnInit(): void {
        this._today = moment().format('YYYY-MM-DD');
        this._companyId = this._store.selectSnapshot(AuthState.companyId);
        this._shareholdersForm = this._formBuild.group({});

        const annualReportId = this._endOfYearManager.endOfYearStep.metadata.annualReportId;

        this._annualReportManager.getAnnualReport(annualReportId)
            .subscribe((annualReport: AnnualReport) => {
                this._selectedDividend = annualReport.dividendsDecision.amount;
            });

        this._shareholderInformationFormSetup();
        this._shareholderManager.getShareholders(this._companyId)
            .subscribe((shareholderListResponse: ShareholderListResponse) => {
                this._shareholdersFormSetup(shareholderListResponse.data);
            });
    }

    ngAfterContentInit(): void {
        if (this._shareholders && this._shareholders.length === 0) {
            setTimeout(() => {
                const summingRow = document.querySelector('.summing-row') as HTMLButtonElement;

                if (summingRow) {
                    summingRow.scrollIntoView({
                        behavior: 'smooth'
                    });

                    setTimeout(() => {
                        const infoText = document.querySelector('.red-dialog-content .info-text') as HTMLDivElement;

                        infoText.scrollIntoView({
                            behavior: 'smooth'
                        });
                    }, 1000);
                }

            }, 1000);
        }
    }

    getCompanyRelation(shareholder: Shareholder) {
        return new Relation({
            role: ROLE.SHARE_HOLDER
        });
    }

    addUserForShareholder() {
        this._dialogRef = this._dialog.open(CreateShareholderComponent);

        this._dialogRef.afterClosed()
            .subscribe((user: User) => {
                if (user instanceof User) {
                    const newShareholder = this._userToShareholder(user);
                    this._addShareholderToList(newShareholder);
                }
            });
    }

    addOwnerAsShareholder() {
        this._authManager.me()
            .subscribe((me: Me) => {
                const newShareholder = this._userToShareholder(me.user);
                newShareholder.numberOfShares = this._nonDistributedShares;
                this._addShareholderToList(newShareholder);
                this._shareholdersForm.markAsDirty();
                this._triggerAllTheShareholderFormsAreValid();
            });
    }

    saveNbrShares() {
        const addStock = new Stock({
            date: this._today,
            companyId: this._companyId,
            totalNumberOfShares: this._shareholderInformationForm.get('totalNumberOfShares').value || 0,
            totalShareholderCapital: {
                'currency': CURRENCY.SEK,
                'amount': this._shareholderInformationForm.get('totalShareCapital').value || 0
            }
        });

        this._shareholderManager.addStock(addStock)
            .subscribe((addedStock: Stock) => {
                this._showShareholdersForm = true;
                this._shareholderInformationFormSetup();
            });
    }

    saveShareholdersShares() {
        const ctrls = Object.assign({}, this._shareholdersForm.controls);
        const shareholdersShares = Object.keys(ctrls).slice().map(key => {
            const id = key.replace(/^shareholder-/, '');
            const shareholder = Object.assign({}, this._shareholders.slice().filter((sh: Shareholder) => {
                 return sh.details.identifier === id;
            }).shift());

            shareholder.numberOfShares = ctrls[key].value;
            delete shareholder.details;

            return JSON.parse(JSON.stringify(shareholder));
        });

        const updateShareholderRequest = new UpdateShareholdersRequest({
            date: this._today,
            companyId: this._companyId,
            shareholders: shareholdersShares
        });

        this._shareholderManager.updateShareholders(updateShareholderRequest)
            .subscribe(() => this.ngOnInit());
    }

    private _addShareholderToList(newShareholder: Shareholder) {
        const shareholders = this._shareholders.slice();

        shareholders.push(newShareholder);

        this._shareholdersFormSetup(shareholders);
    }

    private _userToShareholder(user: User): Shareholder {
        return new Shareholder({
            date: this._today,
            companyId: this._companyId,
            shareholder: new Reference({
                id: user.id,
                type: REFERENCE_TYPE.USER
            }),
            numberOfShares: 0,
            details: new ShareholderDetails({
                identifier: user.details.identification,
                name: `${user.details.firstName} ${user.details.lastName}`
            })
        });
    }

    private _shareholderInformationFormSetup () {
        this._shareholderInformationForm = this._formBuild.group({
            totalShareCapital: [0, this._requiredNumberValidators],
            totalNumberOfShares: [0, this._requiredNumberValidators]
        });

        this._shareholderManager.getStock(this._companyId)
            .subscribe((currentStock: Stock) => {
                const shareholderCapital = currentStock.totalShareholderCapital
                    ? currentStock.totalShareholderCapital.amount
                    : 0;

                this._shareholderInformationForm = this._formBuild.group({
                    totalShareCapital: [shareholderCapital || 0, this._requiredNumberValidators],
                    totalNumberOfShares: [currentStock.totalNumberOfShares || 0, this._requiredNumberValidators]
                });

                if (!this._shareholdersIsVisible) {
                    this._shareholdersIsVisible = !!(parseInt(this._shareholderInformationForm.get('totalShareCapital').value, 10)
                        && parseInt(this._shareholderInformationForm.get('totalNumberOfShares').value, 10));
                }

                this._shareholderInformationForm.markAsUntouched();

                this._totalNbrOfShares = currentStock.totalNumberOfShares;

                this._validateShareholderForms();

                this._shareholderInformationForm.valueChanges
                    .subscribe(() => this._validateShareholderForms());
            });
    }

    private _validateShareholderForms() {
        this._checkHasMoneyAndShares();
        this._updateNonDistributedShares();
        this._triggerAllTheShareholderFormsAreValid();
    }

    private _checkHasMoneyAndShares() {
        const siForm = this._shareholderInformationForm;
        const capital = parseInt(siForm.get('totalShareCapital').value, 10) || 0;
        const shares = parseInt(siForm.get('totalNumberOfShares').value, 10) || 0;

        this._hasMoneyAndSharesAndIsPristine = (capital > 0) && (shares > 0) && siForm.pristine;

        this._valuePerShare = (capital > 0 && shares > 0)
            ? Amount.SEK(capital / shares)
            : Amount.SEK(0);
    }

    private _shareholdersFormSetup(shareholders: Shareholder[]) {
        this._shareholders = shareholders.sort(this._sortAlphabeticByLastname);
        this._distributedShares = this._getDistributedSharesFromShareholders(this._shareholders);

        if (!this._shareholdersForm) {
            this._createShareHoldersForm();
        } else {
            this._updateShareHoldersForm();
        }

        this._updateNonDistributedShares();
        this._triggerAllTheShareholderFormsAreValid();
        this._checkIfOwnerIsShareholder();
    }

    private _updateShareHoldersForm() {
        const shareholdersFormConfig = this._getShareholdersFormConfig();
        this._shareholdersForm = this._formBuild.group(shareholdersFormConfig);
    }

    private _createShareHoldersForm() {
        const shareholdersFormConfig = this._getShareholdersFormConfig();
        this._shareholdersForm = this._formBuild.group(shareholdersFormConfig);
        this._setShareholdersFormValueChanges();
    }

    private _sortAlphabeticByLastname(a, b): 1|-1|0 {
        const aName = a.details.name.split(' ').pop().toUpperCase();
        const bName = b.details.name.split(' ').pop().toUpperCase();

        if (aName < bName) {
            return -1;
        }

        if (aName > bName) {
            return 1;
        }

        return 0;
    }

    private _setShareholdersFormValueChanges() {
        this._shareholdersForm.valueChanges
            .subscribe((controls) => {
                this._distributedShares = this._getDistributedSharesFromControls(controls);
                this._updateNonDistributedShares();
            });
    }

    private _getDistributedSharesFromControls(controls): number {
        let distShares = 0;

        Object.keys(controls).forEach(key => {
            distShares += parseInt(this._shareholdersForm.get(key).value, 10) || 0;
        });

        return distShares;
    }

    private _getDistributedSharesFromShareholders(shareholders: Shareholder[]): number {
        let distShares = 0;

        shareholders.forEach(shareholder => {
            distShares += shareholder.numberOfShares;
        });

        return distShares;
    }

    private _triggerAllTheShareholderFormsAreValid() {
        this.allTheShareholderFormsAreValid.emit(this._shareholderInformationForm.valid
            && this._shareholderInformationForm.pristine
            && this._shareholdersForm.valid
            && this._shareholdersForm.pristine
            && this._nonDistributedShares === 0
            && !!this._valuePerShare
        );
    }

    private _updateNonDistributedShares() {
        this._nonDistributedShares = (this._totalNbrOfShares - this._distributedShares);
    }

    private _getShareholdersFormConfig() {
        const shareholdersFormConfig = {};

        this._shareholders.forEach(shareholder => {
            shareholdersFormConfig[`shareholder-${shareholder.details.identifier}`] = [shareholder.numberOfShares || 0, this._requiredNumberValidators];
        });

        return shareholdersFormConfig;
    }

    private _checkIfOwnerIsShareholder() {
        const owner = this._shareholders.filter(shareholder => shareholder.details.identifier === this._store.selectSnapshot(AuthState.identifier)).shift();

        this._ownerIsShareholder = !!owner;
    }
}
