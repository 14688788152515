import { Input, OnDestroy, OnInit, ViewChild, Directive } from '@angular/core';
import {
    Onboarding,
    ONBOARDING_APPLICATION_STATUS,
    ONBOARDING_TRACK,
    OnboardingApplication
} from '@red/onboarding-service-api';
import {
    OnboardingUpdateApplication,
    OnboardingUpdateApplicationAndStatus,
    OnboardingUpdateApplicationAndStatusSuccessful
} from '../../../shared/state/onboarding/onboarding.actions';
import {Actions, ofActionSuccessful, Store} from '@ngxs/store';
import {FormGroup} from '@angular/forms';
import {Observable, of, Subscription} from 'rxjs';
import {COMPANY_TYPE} from '@red/data';
import {OnboardingManager} from '../../../managers/onboarding/onboarding-manager';
import {MatExpansionPanel} from '@angular/material/expansion';
import {OnboardingState} from '../../../shared/state/onboarding/onboarding.state';
import {map} from 'rxjs/operators';

@Directive()
export abstract class OnboardingApplicationBaseDirective implements OnInit, OnDestroy {
    @ViewChild(MatExpansionPanel) expansionPanel: MatExpansionPanel;
    @Input() application: OnboardingApplication;

    get enabled$(): Observable<boolean> { return this._enabled$; }
    get statusClassName(): string {
        return this.application.status.toString().toLowerCase();
    }
    get skatteverketLink(): string {
        return 'https://www.skatteverket.se/foretagochorganisationer/startaochdrivaforetag/startaochregistrera/registerutdrag.4.2132aba31199fa6713e800010366.html';
    }
    get companyType(): COMPANY_TYPE {
        return this._track && ((/^LIMITED_COMPANY/).test(this._track.toString()))
            ? COMPANY_TYPE.LIMITED_COMPANY
            : COMPANY_TYPE.SOLE_TRADER;
    }

    private _enabled$: Observable<boolean> = of(false);

    protected _actions: Actions;
    protected _store: Store;
    protected _form: FormGroup;
    protected _onboardingManager: OnboardingManager;
    protected _track: ONBOARDING_TRACK;
    private _updateApplicationAndStatusSubscription: Subscription;

    constructor(
        actions: Actions,
        store: Store,
        onboardingManager: OnboardingManager
    ) {
        this._actions = actions;
        this._store = store;
        this._onboardingManager = onboardingManager;
    }

    ngOnInit(): void {
        this._updateApplicationAndStatusSubscription = this._actions
            .pipe(
                ofActionSuccessful(OnboardingUpdateApplicationAndStatusSuccessful)
            )
            .subscribe((action: OnboardingUpdateApplicationAndStatusSuccessful) => {
                this._onboardingManager.onboardingById(action.payload.application.onboardingId)
                    .subscribe((onboarding: Onboarding) => {
                        this._track = onboarding.track;
                    });

                if (action.payload.application.id === this.application.id) {
                    this.application.status = ONBOARDING_APPLICATION_STATUS.SENT;
                    this.expansionPanel.close();
                }
            });

        this._enabled$ = this._store.select(OnboardingState.applicationStatusMap)
            .pipe(
                map((statusMap) => this._onboardingManager.shouldApplicationBeEnabled(this.application.type, statusMap))
            );
    }

    ngOnDestroy(): void {
        if (this._updateApplicationAndStatusSubscription) {
            this._updateApplicationAndStatusSubscription.unsubscribe();
        }
    }

    protected _updateApplication(application: OnboardingApplication) {
        this._store.dispatch(new OnboardingUpdateApplication({application}));
    }

    protected _updateApplicationAndSend(application: OnboardingApplication) {
        this._store.dispatch(new OnboardingUpdateApplicationAndStatus({application: application, status: ONBOARDING_APPLICATION_STATUS.SENT}));
    }

    protected _toggleFormDisabled() {
        if (this.application.status === ONBOARDING_APPLICATION_STATUS.DRAFT && this._form.disabled) {
            this._form.enable();
        } else if (this.application.status !== ONBOARDING_APPLICATION_STATUS.DRAFT && this._form.enabled) {
            this._form.disable();
        }
    }
}
