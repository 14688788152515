import {NgModule} from '@angular/core';

import {AnnouncementComponent} from './announcement';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {AnnouncementDialogComponent} from './dialog/announcement-dialog';
import {MatDialogModule} from '@angular/material/dialog';

@NgModule({
    imports: [
        CommonModule,
        MatToolbarModule,
        MatButtonModule,
        MatIconModule,
        MatDialogModule
    ],
    exports: [
        AnnouncementComponent
    ],
    declarations: [
        AnnouncementComponent,
        AnnouncementDialogComponent
    ]
})
export class AnnouncementModule {
}
