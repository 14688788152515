<app-end-of-year-info-texts></app-end-of-year-info-texts>

<h3 class="form-section-header" i18n="End of year|header|Number of shares in the company">Number of shares in the company</h3>

<form [formGroup]="shareholderInformationForm" data-type="shareholder-information-form">
    <div class="form-field">
        <div class="form-row full-width-single-element-row">
            <mat-form-field class="right">
                <input
                    matInput
                    i18n-placeholder="End of year|input placeholder|Total share capital"
                    placeholder="Total share capital"
                    formControlName="totalShareCapital"
                    class="right"
                    type="number" />
                <span matSuffix class="currency">kr</span>
            </mat-form-field>
        </div>
        <div class="form-row full-width-single-element-row">
            <mat-form-field class="right">
                <input
                    matInput
                    i18n-placeholder="End of year|input placeholder|Total number of shares"
                    placeholder="Total number of shares"
                    formControlName="totalNumberOfShares"
                    class="right"
                    type="number" />
            </mat-form-field>
        </div>
        <div class="form-summing-row">
            <span class="form-summing-header" i18n="End of year|info-label|Value per share">Value per share</span>
            <span *ngIf="valuePerShare; else noShareValue" class="amount" [innerHTML]="valuePerShare | amount : 0 : 0"></span>
            <ng-template #noShareValue>
                <span class="amount">-</span>
            </ng-template>
        </div>
    </div>
    <div class="button-wrapper">
        <button mat-flat-button class="mat-primary" (click)="saveNbrShares()" [disabled]="hasMoneyAndSharesAndIsPristine" i18n="Save">Save</button>
    </div>
</form>

<div data-type="shareholders" *ngIf="showShareholdersForm">
    <h3 class="form-section-header" i18n="End of year|header|shareholders">Shareholders</h3>
    <form class="shareholders-form" [formGroup]="shareholdersForm">
        <ul class="shareholders-list" *ngIf="hasShareholders; else noShareholders">
            <li *ngFor="let shareholder of shareholders">
                <red-list-icon mat-list-avatar class="list-icon" [item]="getCompanyRelation(shareholder)"></red-list-icon>

                <div class="list-text">
                    <h4>{{shareholder.details.name}}</h4>
                    <span class="identification">{{shareholder.details.identifier}}</span>
                </div>

                <div class="list-input">
                    <mat-form-field class="nbr-shares right">
                        <input
                            matInput
                            i18n-placeholder="End of year|input placeholer|Number of shares"
                            placeholder="Number of shares"
                            formControlName="shareholder-{{shareholder.details.identifier}}"
                            class="right"
                            type="number" />
                    </mat-form-field>
                </div>
            </li>
        </ul>
        <ng-template #noShareholders>
            <div i18n="End of year|list-info|No shareholders" *ngIf="!shareholders.length" class="no-data-list-message">The company does not have any shareholders</div>
        </ng-template>

        <div class="button-wrapper">
            <button mat-flat-button data-type="add-shareholder" class="mat-primary left" (click)="addUserForShareholder()" i18n="End of year|button|Add new shareholder">Add new shareholder</button>
            <button mat-flat-button data-type="add-yourself" class="mat-primary left" *ngIf="!ownerIsShareholder" (click)="addOwnerAsShareholder()" i18n="End of year|button|Add yourself">Add yourself</button>
            <button mat-flat-button data-type="save" class="mat-primary" (click)="saveShareholdersShares()" [disabled]="shareholdersHasNotChanged" i18n="End of year|button|Save shareholders shares">Save</button>
        </div>

        <div class="form-summing-row" *ngIf="nonDistributedShares">
            <span class="form-summing-header" i18n="End of year|info-label|Non-distributed shares">Non-distributed shares</span>
            <span class="form-summing-sum right">{{nonDistributedShares}}</span>
        </div>
    </form>

    <h3 class="form-section-header" i18n="End of year|header|Summation">Summation</h3>

    <div class="summing-row">
        <span class="summing-header" i18n="End of year|info-label|Selected dividend">Selected dividend</span>
        <span class="summing-sum right" [innerHTML]="selectedDividend | amount : 0 : 0"></span>
    </div>
</div>

