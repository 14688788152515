import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Store} from '@ngxs/store';
import {ListCardForCompany} from '../../../shared/state/card/card.actions';
import {AuthState} from '../../../shared/state/auth/auth.state';

@Injectable()
export class SettingsCardRouteResolve implements Resolve<any> {

    private _router: Router;
    private _store: Store;

    constructor(
        router: Router,
        store: Store
    ) {
        this._router = router;
        this._store = store;
    }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return this._store.dispatch(new ListCardForCompany({companyId: this._store.selectSnapshot(AuthState.companyId)}))
            .pipe(catchError(() => {
                return this._router.navigate(['/not-found']);
            }));
    }
}
