import {COMPANY_STATUS, Me, Relation, ROLE} from '@red/data';
import {AUTH_STRATEGY, BANKID_ERROR_STATE, BANKID_RETRY_STATE} from '@red/data';
import {catchError, delay, mergeMap, retryWhen} from 'rxjs/operators';
import {throwError as observableThrowError, Observable, of, Subject} from 'rxjs';
import {RemoteErrorException} from '../../common/error/remote-error-exception';
import {AuthorizationServiceClient, BankIdAuthRequest, BankIdAuthResponse, BankIdCollectResponse} from '@red/browser';

export enum BANKID_RETRY_TYPE {
    BACKOFF,
    SEQUENTIAL
}

export interface BankIdRetryStrategy {
    retries: number;
    retryType: BANKID_RETRY_TYPE;
}


export class AuthManager {
    private _authorizationServiceClient: AuthorizationServiceClient;
    private _retryStrategy: BankIdRetryStrategy;

    constructor(
        authorizationServiceClient: AuthorizationServiceClient
    ) {
        this._authorizationServiceClient = authorizationServiceClient;
        this._retryStrategy = {retries: 40, retryType: BANKID_RETRY_TYPE.BACKOFF};
    }

    static create(authorizationServiceClient: AuthorizationServiceClient) {
        return new AuthManager(authorizationServiceClient);
    }

    collect(authReference: string, responseEmitter?: Subject<BankIdCollectResponse>): Observable<any> {
        return this._poll(authReference, responseEmitter);
    }

    getCurrentRelation(relations: Relation[], companyId?: string) {
        let currentRelation;
        if (companyId) {
            currentRelation = relations.filter(relation => relation.role === ROLE.OWNER && relation.company.id === companyId).shift();
        }

        if (!currentRelation && Array.isArray(relations)) {
            currentRelation = this._getRelationByPriority(relations);
        }

        return currentRelation;
    }

    login(credentials: BankIdAuthRequest): Observable<BankIdAuthResponse> {
        credentials.strategy = AUTH_STRATEGY.BANK_ID;

        return this._authorizationServiceClient.authenticate(credentials);
    }

    me(): Observable<Me> {
        return this._authorizationServiceClient.me();
    }

    setActiveCompany(companyId: string): Observable<BankIdCollectResponse> {
        return this._authorizationServiceClient.setActiveCompany(companyId);
    }

    private _getRelationByPriority(relations: Relation[]) {
        const owner = relations
            .filter((relation: Relation) => relation.role === ROLE.OWNER);

        return [
            ...owner.filter((relation: Relation) => relation.company && relation.company.status === COMPANY_STATUS.ACTIVE)
        ].shift();
    }

    protected _poll(authReference: string, responseEmitter?: Subject<BankIdCollectResponse>): Observable<BankIdCollectResponse> {
        let retries = 0;
        return this._authorizationServiceClient.collect(authReference)
            .pipe(
                mergeMap((response: BankIdCollectResponse) => {
                    if (responseEmitter) {
                        responseEmitter.next(response);
                    }

                    if (this._shouldRetry(response.status)) {
                        return observableThrowError(response);
                    }

                    if (responseEmitter) {
                        responseEmitter.complete();
                    }

                    return of(response);
                }),
                retryWhen<BankIdCollectResponse>(errors => {
                    return errors.pipe(
                        mergeMap((err) => {
                            retries += 1;
                            if (this._retryStrategy.retries + 1 === retries) {
                                return observableThrowError(new Error(`Polling failed after ${this._retryStrategy.retries} attempts`));
                            }

                            if (this._shouldThrowError(err)) {
                                return observableThrowError(err);
                            }

                            return of(retries)
                                .pipe(
                                    delay(this._nextRetry(retries))
                                );
                        })
                    );
                })
            );
    }

    private _nextRetry(retries: number) {
        let n;

        switch (this._retryStrategy.retryType) {
            case BANKID_RETRY_TYPE.BACKOFF:
                n = (Math.floor(retries / 10) * 250) + 500;
                break;
            case BANKID_RETRY_TYPE.SEQUENTIAL:
                n = 500;
                break;
            default:
                throw new Error(`Unknown retry strategy ${this._retryStrategy.retryType}`);
        }

        return n;
    }

    private _shouldRetry(state: string): boolean {
        return BANKID_RETRY_STATE[state] !== undefined;
    }

    private _shouldThrowError(resp: RemoteErrorException): boolean {
        return resp && (BANKID_ERROR_STATE[resp.message] !== undefined || resp.message === 'INVALID_PARAMETERS' || resp.message === 'userCancel');
    }
}
