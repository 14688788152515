import {EventReader, OnEvent} from '../reader';
import {BaseEvent} from '../event';
import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {EventSource} from '../source';

export class ReceiptCreatedEvent extends BaseEvent {}
export class ReceiptUpdatedEvent extends BaseEvent {}

@Injectable()
export class ReceiptEventReader extends EventReader {
    private _onCreatedStream: Subject<ReceiptCreatedEvent>;
    private _onUpdateStream: Subject<ReceiptUpdatedEvent>;

    constructor (
        source: EventSource
    ) {
        super(source);

        this._onCreatedStream = new Subject<ReceiptCreatedEvent>();
        this._onUpdateStream = new Subject<ReceiptUpdatedEvent>();
    }

    onCreated(fn: (evt: ReceiptCreatedEvent) => void) {
        this._onCreatedStream.subscribe(fn);
    }

    onUpdate(fn: (evt: ReceiptUpdatedEvent) => void) {
        this._onUpdateStream.subscribe(fn);
    }

    @OnEvent(ReceiptCreatedEvent)
    private _handleReceiptCreatedEvent(evt: ReceiptCreatedEvent) {
        this._onCreatedStream.next(evt);
    }

    @OnEvent(ReceiptUpdatedEvent)
    private _handleReceiptUpdatedEvent(evt: ReceiptUpdatedEvent) {
        this._onUpdateStream.next(evt);
    }
}
