import {Component, HostBinding, Inject, OnInit, SimpleChanges} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Analytics} from '../../common/analytics/analytics';
import {AppConfig} from '../../config/appConfig';
import {
    Amount,
    ANNUAL_REPORT_SUBMIT_STATE,
    COMPANY_TYPE,
    CompanyYearEndStep,
    CURRENCY,
    LLCTaxCalculation,
    SoleTraderTaxCalculation,
    YEAR_END_STEP,
    YearEndMetaData
} from '@red/data';
import {
    END_OF_YEAR_FLOW_STATE,
    END_OF_YEAR_STATE,
    EndOfYearManager,
    TAX_CALCULATION_STATE
} from '../../managers/end-of-year/end-of-year.manager';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
    CONFIRM_DIALOG_TYPE,
    EndOfYearConfirmDialogComponent
} from './end-of-year-confirm-dialog/end-of-year-confirm-dialog';
import {DIALOG_RESULT, RedNotification, RedDialog} from '@red/components';
import {mergeMap, map} from 'rxjs/operators';
import {Store} from '@ngxs/store';
import {AuthState} from '../../shared/state/auth/auth.state';
import {QueryFilter} from '@red/browser';
import {YearEndMetaDataFormHelper} from './year-end-metadata-form-helper';

@Component({
    selector: 'app-end-of-year-dialog',
    styleUrls: ['./end-of-year.dialog.sass'],
    templateUrl: './end-of-year.dialog.tpl.html'
})

export class EndOfYearDialogComponent implements OnInit {
    @HostBinding('class.end-of-year-dialog') cssClass = true;

    get annualReportStoryFromIsValid(): boolean { return this._annualReportStoryFromIsValid; }
    get allTheShareholderFormsAreValid(): boolean { return this._allTheShareholderFormsAreValid; }
    get currentEndOfYearStepType(): YEAR_END_STEP { return this._currentEndOfYearStepType; }
    get endOfYearManager(): EndOfYearManager { return this._endOfYearManager; }
    get waitingForBankId(): boolean { return this._endOfYearManagesState === END_OF_YEAR_STATE.WAITING_FOR_BANKID_AUTHORIZED; }
    get movingToNextStep(): boolean { return this._endOfYearManagesState === END_OF_YEAR_STATE.MOVING_TO_NEXT_STEP; }
    get annualReportSubmitStatusFailed(): boolean { return this._endOfYearManager.endOfYearStep.metadata.annualReportSubmitState === ANNUAL_REPORT_SUBMIT_STATE.FAILED; }
    get metadataForm(): FormGroup { return this._metadataForm; }
    get year(): string { return this._endOfYearManager.currentFiscalYear(); }
    get taxCalcData(): any { return this._taxCalcData; }
    get metadataFormIsNotValid(): boolean { return this._metadataForm.invalid; }
    get hasSignatories(): boolean { return this._hasSignatories; }
    get signedByEverybody(): boolean { return this._signedByEverybody; }
    get startedNotDone(): boolean { return this._metadataForm.invalid || this._receiptsToUpload > 0; }

    get warmup(): YEAR_END_STEP { return YEAR_END_STEP.WARMUP; }
    get started(): YEAR_END_STEP { return YEAR_END_STEP.STARTED; }
    get customerDone(): YEAR_END_STEP { return YEAR_END_STEP.CUSTOMER_DONE; }
    get waitingForCustomerToSign(): YEAR_END_STEP { return YEAR_END_STEP.WAITING_FOR_CUSTOMER_TO_SIGN; }
    get creatingDocuments(): YEAR_END_STEP { return YEAR_END_STEP.CREATING_DOCUMENTS; }
    get waitingForFinalSignature(): YEAR_END_STEP { return YEAR_END_STEP.WAITING_FOR_FINAL_SIGNATURE; }
    get booksClosed(): YEAR_END_STEP { return YEAR_END_STEP.BOOKS_CLOSED; }
    get dividends(): YEAR_END_STEP { return YEAR_END_STEP.DIVIDENDS; }
    get shareholderInformation(): YEAR_END_STEP { return YEAR_END_STEP.SHAREHOLDER_INFORMATION; }
    get annualReportStory(): YEAR_END_STEP { return YEAR_END_STEP.ANNUAL_REPORT_STORY; }
    get boardMemberInformation(): YEAR_END_STEP { return YEAR_END_STEP.BOARD_MEMBER_INFORMATION; }
    get generatingAnnualReport(): YEAR_END_STEP { return YEAR_END_STEP.GENERATING_ANNUAL_REPORT; }
    get annualReportDocuments(): YEAR_END_STEP { return YEAR_END_STEP.ANNUAL_REPORT_DOCUMENTS; }
    get submittingAnnualReport(): YEAR_END_STEP { return YEAR_END_STEP.SUBMITTING_ANNUAL_REPORT; }
    get waitingForBolagsverket(): YEAR_END_STEP { return YEAR_END_STEP.WAITING_FOR_BOLAGSVERKET; }
    get annualReportDone(): YEAR_END_STEP { return YEAR_END_STEP.ANNUAL_REPORT_DONE; }
    get exit(): YEAR_END_STEP { return YEAR_END_STEP.EXIT; }

    get dividendsStep(): boolean { return this._dividendsStep; }
    get dividendsFormIsValid(): boolean { return this._dividendsFormIsValid; }

    get endOfYearBloggLink(): string { return this._endOfYearManager.endOfYearBloggLink; }
    get endOfYearResultAndTaxLink(): string { return this._endOfYearManager.endOfYearResultAndTaxLink; }
    get isSoleTrader(): boolean { return (this._endOfYearManager.currentCompanyType === COMPANY_TYPE.SOLE_TRADER); }
    get isLLC(): boolean { return (this._endOfYearManager.currentCompanyType === COMPANY_TYPE.LIMITED_COMPANY); }

    private get _companyId() { return this._currendEndOfYearStep.companyId; }

    private _allTheShareholderFormsAreValid = false;
    private _moveToCustomerDoneConfirmDialog: MatDialogRef<EndOfYearConfirmDialogComponent, DIALOG_RESULT>;
    private _analytics: Analytics;
    private _notification: RedNotification;
    private _appConfig: AppConfig;
    private _bankIdDialogRef: MatDialogRef<EndOfYearConfirmDialogComponent, DIALOG_RESULT>;
    private _currendEndOfYearStep: CompanyYearEndStep;
    private _currentEndOfYearStepType: YEAR_END_STEP;
    private _dialog: RedDialog;
    private _dialogRef: MatDialogRef<EndOfYearDialogComponent>;
    private _endOfYearManager: EndOfYearManager;
    private _endOfYearManagesState: END_OF_YEAR_STATE;
    private _formBuild: FormBuilder;
    private _helpConfirmDialog: MatDialogRef<EndOfYearConfirmDialogComponent, DIALOG_RESULT>;
    private _metadataForm: FormGroup;
    private _store: Store;
    private _taxCalcData: LLCTaxCalculation | SoleTraderTaxCalculation;
    private _validMetaDataSaved = false;
    private _dividendsStep = false;
    private _dividendsFormIsValid = false;
    private _annualReportStoryFromIsValid = false;
    private _hasSignatories = false;
    private _signedByEverybody = false;
    private _isSoleTrader: boolean;
    private _yearEndMetaDataFormHelper: YearEndMetaDataFormHelper;
    private _receiptsToUpload: number;

    constructor (
        analytics: Analytics,
        appConfig: AppConfig,
        endOfYearManager: EndOfYearManager,
        dialogRef: MatDialogRef<EndOfYearDialogComponent>,
        store: Store,
        dialog: RedDialog,
        formBuild: FormBuilder,
        notification: RedNotification,
        yearEndMetaDataFormHelper: YearEndMetaDataFormHelper,
        @Inject(MAT_DIALOG_DATA) data: any
    ) {
        this._analytics = analytics;
        this._appConfig = appConfig;
        this._dialog = dialog;
        this._dialogRef = dialogRef;
        this._store = store;
        this._formBuild = formBuild;
        this._notification = notification;

        this._yearEndMetaDataFormHelper = yearEndMetaDataFormHelper;

        this._endOfYearManager = endOfYearManager;

        const companyId = this._store.selectSnapshot(AuthState.companyId);
        this._endOfYearManager.intYearEndForCompany(companyId);
    }

    ngOnInit(): void {
        this._setCurrentEndOfYearStep(this._endOfYearManager.endOfYearStep);

        this._isSoleTrader = (this._endOfYearManager.endOfYearStep.companyType === COMPANY_TYPE.SOLE_TRADER);

        this._metadataForm = this._yearEndMetaDataFormHelper.setupMetadataForm(this._currendEndOfYearStep.metadata);
        this._validMetaDataSaved = this.metadataForm.valid;
        this._dividendsStep = this.isDividendsStep(this._endOfYearManager.endOfYearStep.step);
        this._receiptsToUpload = this._currendEndOfYearStep.metadata.receiptsToUpload;

        this._endOfYearManager
            .onStateChange
            .subscribe((endOfYearManagerState: END_OF_YEAR_STATE) => {
                this._endOfYearManagesState = endOfYearManagerState;
            });

        this._endOfYearManager
            .onStepChange
            .subscribe((endOfYearStep) => this._setCurrentEndOfYearStep(endOfYearStep));

        if (this._currentEndOfYearStepType === YEAR_END_STEP.WAITING_FOR_CUSTOMER_TO_SIGN) {
            this._endOfYearManager.taxCalcState.next(TAX_CALCULATION_STATE.INIT);
            this.initTaxCalculationTable();
        }
    }

    close() {
        this._dialogRef.close();
        this._endOfYearManager.cancelOpenBankId();
    }

    saveMetaData(formValues) {
        const yearEndMetadata = new YearEndMetaData(formValues);

        this._updateMetaData(yearEndMetadata)
            .subscribe(
                () => {},
                err => this._handleError(err)
            );
    }

    updateTaxCalculationTable(formValues) {
        const yearEndMetadata = new YearEndMetaData(formValues);

        this._endOfYearManager.taxCalcState.next(TAX_CALCULATION_STATE.LOADING);

        this._updateMetaData(yearEndMetadata)
            .pipe(mergeMap(_ => {
                return this._endOfYearManager.getTaxCalculation(this._companyId);
            }))
            .subscribe(
                taxCalc => {
                    this._taxCalcData = taxCalc;
                    this._endOfYearManager.taxCalcState.next(TAX_CALCULATION_STATE.DONE);
                },
                () => this._handleTaxCalcError()
            );
    }

    initTaxCalculationTable() {
        this._endOfYearManager.getTaxCalculation(this._companyId)
            .subscribe(
                taxCalc => {
                    this._taxCalcData = taxCalc;
                    this._endOfYearManager.taxCalcState.next(TAX_CALCULATION_STATE.DONE);
                },
                () => this._handleTaxCalcError()
            );
    }

    help($event) {
        $event.preventDefault();

        const configDialogData = {
            'confirmDialogType': CONFIRM_DIALOG_TYPE.USER_HELP
        };

        this._helpConfirmDialog = this._confirmDialog(configDialogData);
    }

    bankIdAuthorizeForNextStep() {
        const configDialogData = {
            'endOfYearManager': this._endOfYearManager,
            'confirmDialogType': CONFIRM_DIALOG_TYPE.BANKID_CONFIRM
        };

        this._bankIdDialogRef = this._confirmDialog(configDialogData);
    }

    updateMetadata() {
        const yearEndMetadata = new YearEndMetaData(this._metadataForm.getRawValue());

        return this._updateMetaData(yearEndMetadata);
    }

    saveMetadataAndMoveToNextStep() {
        this.updateMetadata()
            .subscribe(
                () => {
                    this.moveToNextStep();
                },
                err => this._handleError(err)
            );
    }

    confirmCustomerDone() {
        const configDialogData = {
            'confirmDialogType': CONFIRM_DIALOG_TYPE.MOVE_TO_CUSTOMER_DONE,
            'endOfYearDialog': this
        };

        this._moveToCustomerDoneConfirmDialog = this._confirmDialog(configDialogData);

        this.updateMetadata()
            .subscribe(
                () => {},
                err => this._handleError(err)
            );
    }

    moveToNextStep() {
        this._endOfYearManager
            .moveToNextStep()
            .subscribe(
                () => {},
                err => this._handleError(err)
            );
    }

    handleDividendsForm($event: boolean) {
        this._dividendsFormIsValid = $event;
    }

    handleShareholderForm($event: boolean) {
        this._allTheShareholderFormsAreValid = $event;
    }

    handleAnnualReportStoryForm($event: boolean) {
        this._annualReportStoryFromIsValid = $event;
    }

    handleHasSignatories($event: boolean) {
        this._hasSignatories = $event;
    }

    handleSignedByEverybody($event) {
        this._signedByEverybody = $event;
    }

    private _handleError(err: Error) {
        const msg = $localize`:Error code returned from server|ailed to move step in year end flow:Ops, something went wrong.`;
        this._notification.errorWithCode(err, msg);
    }

    private isDividendsStep(step: YEAR_END_STEP): boolean {
        return this._endOfYearManager.endOfYearFlowState(step) === END_OF_YEAR_FLOW_STATE.IN_DIVIDENDS_PROCESS
            || this._endOfYearManager.endOfYearFlowState(step) === END_OF_YEAR_FLOW_STATE.LLC_DONE;
    }

    private _handleTaxCalcError() {
        this._endOfYearManager.taxCalcState.next(TAX_CALCULATION_STATE.DONE);
    }

    private _updateMetaData(yearEndMetadata: YearEndMetaData) {
        const filter = new QueryFilter()
            .equal('fiscalEndDate', this._currendEndOfYearStep.fiscalEndDate);

        return this._endOfYearManager.updateMetaData(yearEndMetadata, filter)
            .pipe(map((companyYearEndStep: CompanyYearEndStep) => {
                this._setMetadata(companyYearEndStep.metadata);
                this._updateMetadataForm(companyYearEndStep.metadata);
            }));
    }

    private _setMetadata(metadata: YearEndMetaData) {
        this._currendEndOfYearStep.metadata = metadata;

        this._receiptsToUpload = metadata.receiptsToUpload;
    }

    private _updateMetadataForm(metadata: YearEndMetaData) {
        const meta = {};

        Object.keys(metadata).forEach(key => {
            this._removeUndefinedProperties(key, metadata, meta);
        });

        this._metadataForm.patchValue(meta);
    }

    private _removeUndefinedProperties(key: string, obj, newObj) {
        if (obj[key] !== undefined) {
            newObj[key] = obj[key];
        }
    }

    private _confirmDialog(configDialogData: any): MatDialogRef<EndOfYearConfirmDialogComponent> {
        return this._dialog.confirm(EndOfYearConfirmDialogComponent, {
            'width': '400px',
            'panelClass': 'end-of-year-confirm-dialog',
            'data': configDialogData
        });
    }

    private _setCurrentEndOfYearStep(endOfYearStep: CompanyYearEndStep) {
        this._currendEndOfYearStep = endOfYearStep;
        this._currentEndOfYearStepType = this._currendEndOfYearStep.step;

        this._dividendsStep = this.isDividendsStep(endOfYearStep.step);

        if (this._bankIdDialogRef) {
            this._bankIdDialogRef.close();
        }
    }
}
