import {CompanyConfiguration} from '@red/data';

export class CompanyConfigurationManager {
    private _configurations: CompanyConfiguration[] = [];

    static create() {
        return new CompanyConfigurationManager();
    }

    setConfigurations(configurations: CompanyConfiguration[]) {
        this._configurations = Array.isArray(configurations) ? configurations : [];
    }

    getConfigurationByCompanyId(companyId: string): CompanyConfiguration {
        return this._configurations.filter((config: CompanyConfiguration) => config.companyId === companyId).shift();
    }
}
