import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginViewComponent} from './login';
import {RedFormModule, RedLoaderModule} from '@red/components';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {ReactiveFormsModule} from '@angular/forms';
import {LOGIN_ROUTES} from './login.routes';
import {RouterModule} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';

@NgModule({
    declarations: [
        LoginViewComponent
    ],
    exports: [
        LoginViewComponent
    ],
    imports: [
        CommonModule,
        RouterModule.forRoot(LOGIN_ROUTES, { relativeLinkResolution: 'legacy' }),
        ReactiveFormsModule,
        RedFormModule,
        MatButtonModule,
        MatCardModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSlideToggleModule,
        RedLoaderModule
    ]
})
export class LoginViewModule {}
