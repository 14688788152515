<mat-accordion>
    <ng-container *ngFor="let application of applications | async; trackBy:trackByItems">
        <ng-container [ngSwitch]="application.type">
            <app-onboarding-application-llc-new-blv-form
                *ngSwitchCase="'LIMITED_COMPANY_NEW_BLV_FORM'"
                [application]="application"
                [me]="me | async">
            </app-onboarding-application-llc-new-blv-form>
            <app-onboarding-application-llc-new-blv-board-form
                *ngSwitchCase="'LIMITED_COMPANY_NEW_BLV_BOARD_FORM'"
                [application]="application"
                [me]="me | async">
            </app-onboarding-application-llc-new-blv-board-form>
            <app-onboarding-application-llc-new-blc-shares-form
                *ngSwitchCase="'LIMITED_COMPANY_NEW_BLV_SHARES_FORM'"
                [application]="application"
                [me]="me | async">
            </app-onboarding-application-llc-new-blc-shares-form>
            <app-onboarding-application-llc-new-skv-form
                *ngSwitchCase="'LIMITED_COMPANY_NEW_SKV_FORM'"
                [application]="application">
            </app-onboarding-application-llc-new-skv-form>
            <app-onboarding-application-terms-of-service
                *ngSwitchCase="'TERMS_OF_SERVICE'"
                [application]="application">
            </app-onboarding-application-terms-of-service>
            <app-onboarding-application-llc-new-create-company
                *ngSwitchCase="'LIMITED_COMPANY_NEW_CREATE_COMPANY'"
                [application]="application">
            </app-onboarding-application-llc-new-create-company>
            <app-onboarding-application-llc-new-share-capital
                *ngSwitchCase="'LIMITED_COMPANY_NEW_SHARE_CAPITAL'"
                [application]="application">
            </app-onboarding-application-llc-new-share-capital>
            <app-onboarding-application-llc-new-application-blv
                *ngSwitchCase="'LIMITED_COMPANY_NEW_APPLICATION_BLV'"
                [application]="application">
            </app-onboarding-application-llc-new-application-blv>

<!--            <app-onboarding-application-llc-new-application-skv-->
<!--                *ngSwitchCase="'LIMITED_COMPANY_NEW_APPLICATION_SKV'"-->
<!--                [application]="application">-->
<!--            </app-onboarding-application-llc-new-application-skv>-->
<!--            <app-onboarding-application-llc-new-activate-->
<!--                *ngSwitchCase="'LIMITED_COMPANY_NEW_ACTIVATE'"-->
<!--                [application]="application">-->
<!--            </app-onboarding-application-llc-new-activate>-->
        </ng-container>
    </ng-container>
</mat-accordion>
<div class="cancel-track" *ngIf="cancelEnabled$ | async">
    <button mat-button (click)="cancelTrack()" i18n="Onboarding|Cancel track">Cancel track</button>
</div>
