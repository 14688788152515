import {Component, OnDestroy, OnInit} from '@angular/core';
import {
    INVOICE_DELIVERY_METHOD,
    PAYMENT_STATUS,
    REFERENCE_TYPE,
    Transaction,
    TRANSACTION_ORDER_BY,
    TRANSACTION_SORTING_DIRECTION,
    TRANSACTION_STATUS,
    TRANSACTION_SUB_TYPE
} from '@red/data';
import {QueryFilter, QueryResponse, LIST_STATUS, ListDataSource} from '@red/browser';
import {forkJoin, Subscription} from 'rxjs';
import {NavigationEnd, Router, RouterEvent} from '@angular/router';
import {AuthState} from '../../../shared/state/auth/auth.state';
import {Store} from '@ngxs/store';
import { TransactionServiceClient } from '../../../lab/service-client/transaction-service-client';
import {TransactionManager} from '../../../managers/transaction/transaction.manager';
import {RedFlagShutDownManager} from '../../../managers/red-flag-shut-down/red-flag-shut-down.manager';
import * as moment from 'moment';

@Component({
    selector: 'app-invoice-list-view',
    templateUrl: './list.tpl.html'
})
export class InvoiceListViewComponent implements OnDestroy, OnInit {
    get invoicesDraft(): ListDataSource<Transaction> {
        return this._invoicesDraft;
    }

    get invoicesPaid(): ListDataSource<Transaction> {
        return this._invoicesPaid;
    }

    get invoicesPartiallyPaid(): ListDataSource<Transaction> {
        return this._invoicesPartiallyPaid;
    }

    get invoicesPending(): ListDataSource<Transaction> {
        return this._invoicesPending;
    }

    get state(): LIST_STATUS {
        return this._state;
    }

    get stateDone(): LIST_STATUS {
        return LIST_STATUS.DONE;
    }

    get stateLoading(): LIST_STATUS {
        return LIST_STATUS.LOADING;
    }

    get stateNoResult(): LIST_STATUS {
        return LIST_STATUS.NO_RESULT;
    }

    get notReadOnly(): boolean {
        return this._notReadOnly;
    }

    private _companyId: string;
    private _hasCompany: boolean;
    private _invoicesDraft: ListDataSource<Transaction>;
    private _invoicesPaid: ListDataSource<Transaction>;
    private _invoicesPartiallyPaid: ListDataSource<Transaction>;
    private _invoicesPending: ListDataSource<Transaction>;
    private _router: Router;
    private _routerSubscription: Subscription;
    private _store: Store;
    private _state: LIST_STATUS = LIST_STATUS.LOADING;
    private _transactionManager: TransactionManager;
    private _redFlagShutDownManager: RedFlagShutDownManager;
    private _notReadOnly: boolean;

    constructor(
        router: Router,
        store: Store,
        transactionServiceClient: TransactionServiceClient,
        transactionManager: TransactionManager,
        redFlagShutDownManager: RedFlagShutDownManager
    ) {
        this._router = router;
        this._store = store;
        this._transactionManager = transactionManager;
        this._redFlagShutDownManager = redFlagShutDownManager;

        if (window && !window['moment']) {
            window['moment'] = moment;
        }
    }

    ngOnDestroy(): void {
        if (this._routerSubscription) {
            this._routerSubscription.unsubscribe();
        }
    }

    ngOnInit(): void {
        this._companyId = this._store.selectSnapshot(AuthState.companyId);
        this._hasCompany = !!this._companyId;

        const today = this._redFlagShutDownManager.today;
        this._notReadOnly = this._redFlagShutDownManager.notReadOnlyState(today);

        if (this._hasCompany) {
            this._invoicesDraft = new ListDataSource<Transaction>((filter: QueryFilter) => {
                return this._transactionManager.queryToQueryResponse(this._companyId, filter);
            });

            this._invoicesPaid = new ListDataSource<Transaction>((filter: QueryFilter) => {
                return this._transactionManager.queryToQueryResponse(this._companyId, filter);
            });

            this._invoicesPartiallyPaid = new ListDataSource<Transaction>((filter: QueryFilter) => {
                return this._transactionManager.queryToQueryResponse(this._companyId, filter);
            });

            this._invoicesPending = new ListDataSource<Transaction>((filter: QueryFilter) => {
                return this._transactionManager.queryToQueryResponse(this._companyId, filter);
            });

            this._invoicesPending.items
                .subscribe((transaction: Transaction[]) => {
                    this._sortByExpriesDate(transaction);
                });

            this._invoicesPartiallyPaid.items
                .subscribe((transaction: Transaction[]) => {
                    this._sortByExpriesDate(transaction);
                });

            this._loadInvoices();
            this._routerSubscription = this._router.events.subscribe((event: RouterEvent) => {
                if (event instanceof NavigationEnd && event.url === '/invoice') {
                    this._loadInvoices();
                }
            });
        } else {
            this._state = LIST_STATUS.NO_RESULT;
        }
    }

    isCredited(transaction: Transaction) {
        return transaction.references && transaction.references.length > 0;
    }

    isDueDatePass(transaction) {
        const today = moment().format('YYYY-MM-DD');
        return transaction.attributes && moment(transaction.attributes['expires']).isBefore(today);
    }

    isSentByPost(transaction: Transaction) {
        return transaction.attributes['deliveryMethod'] === INVOICE_DELIVERY_METHOD.LETTER;
    }

    loadMore(dataSource: ListDataSource<Transaction>) {
        dataSource.next().subscribe(() => {});
    }

    private _loadInvoices() {
        this._state = LIST_STATUS.LOADING;

        const draftFilter = new QueryFilter()
            .equal('status', [TRANSACTION_STATUS.DRAFT])
            .equal('type', [REFERENCE_TYPE.INVOICE])
            .equal('orderBy', 'MODIFIED_AT')
            .equal('resolved', false)
            .offset(0)
            .length(14);

        const paidFilter = new QueryFilter()
            .equal('status', [TRANSACTION_STATUS.BOOKED, TRANSACTION_STATUS.COMPLETE])
            .equal('paymentStatus', [PAYMENT_STATUS.OVERPAID, PAYMENT_STATUS.FULL, PAYMENT_STATUS.ABANDONED])
            .equal('type', [REFERENCE_TYPE.INVOICE])
            .equal('subType', [TRANSACTION_SUB_TYPE.DEBIT])
            .equal('resolved', true)
            .offset(0)
            .length(3);

        const partiallyPaidFilter = new QueryFilter()
            .equal('status', [TRANSACTION_STATUS.BOOKED, TRANSACTION_STATUS.COMPLETE])
            .equal('paymentStatus', [PAYMENT_STATUS.PARTIAL])
            .equal('type', [REFERENCE_TYPE.INVOICE])
            .equal('subType', [TRANSACTION_SUB_TYPE.DEBIT])
            .equal('sort', [TRANSACTION_SORTING_DIRECTION.DESC])
            .equal('orderBy', [TRANSACTION_ORDER_BY.CREATED_AT])
            .equal('resolved', true)
            .offset(0)
            .length(14);

        const pendingFilter = new QueryFilter()
            .equal('status', [TRANSACTION_STATUS.BOOKED, TRANSACTION_STATUS.COMPLETE, TRANSACTION_STATUS.IN_REVIEW])
            .equal('paymentStatus', [PAYMENT_STATUS.PENDING])
            .equal('type', [REFERENCE_TYPE.INVOICE])
            .equal('sort', [TRANSACTION_SORTING_DIRECTION.DESC])
            .equal('orderBy', [TRANSACTION_ORDER_BY.CREATED_AT])
            .equal('resolved', false)
            .offset(0)
            .length(14);

        this._invoicesDraft.flush();
        this._invoicesPaid.flush();
        this._invoicesPartiallyPaid.flush();
        this._invoicesPending.flush();

        forkJoin([
            this._invoicesDraft.next(draftFilter),
            this._invoicesPaid.next(paidFilter),
            this._invoicesPartiallyPaid.next(partiallyPaidFilter),
            this._invoicesPending.next(pendingFilter)
        ])
        .subscribe((responses: QueryResponse<Transaction>[]) => {
            const hasResults = responses.reduce((acc, resp) => acc + resp.total, 0);

            this._state = hasResults
                ? LIST_STATUS.DONE
                : LIST_STATUS.NO_RESULT;
        });
    }

    private _sortByExpriesDate(transaction: Transaction[]) {
        return transaction.sort((a, b) => (a.attributes['expires'] < b.attributes['expires'] ? 1 : a.attributes['expires'] > b.attributes['expires'] ? -1 : 0));
    }
}
