import {BaseEvent} from '../event';
import {EventSource} from '../source';
import {Subject} from 'rxjs';
import {EventReader, OnEvent} from '../reader';
import {Injectable} from '@angular/core';

export class BillCreatedEvent extends BaseEvent {}
export class BillUpdatedEvent extends BaseEvent {}

@Injectable()
export class BillEventReader extends EventReader {
    private _onCreatedStream: Subject<BillCreatedEvent>;
    private _onUpdatedStream: Subject<BillUpdatedEvent>;

    constructor (
        source: EventSource
    ) {
        super(source);

        this._onCreatedStream = new Subject<BillCreatedEvent>();
        this._onUpdatedStream = new Subject<BillUpdatedEvent>();
    }

    onCreated(fn: (evt: BillCreatedEvent) => void) {
        this._onCreatedStream.subscribe(fn);
    }

    onUpdate(fn: (evt: BillUpdatedEvent) => void) {
        this._onUpdatedStream.subscribe(fn);
    }

    @OnEvent(BillCreatedEvent)
    private _handleReceiptCreatedEvent(evt: BillCreatedEvent) {
        this._onCreatedStream.next(evt);
    }

    @OnEvent(BillUpdatedEvent)
    private _handleBillUpdatedEvent(evt: BillUpdatedEvent) {
        this._onUpdatedStream.next(evt);
    }
}
