<ng-container *ngIf="showShortText">
    <ng-content select=".short-text"></ng-content> <span class="read-more" (click)="readMore()">Läs mer</span>
</ng-container>
<ng-container *ngIf="showLongText">
    <ng-content select=".long-text"></ng-content> <span class="read-less" (click)="readLess()">Läs mindre</span>
</ng-container>




