import {Injectable} from '@angular/core';
import {AppConfig} from '../../config/appConfig';

export enum ANALYTICS_ACTION {
    CREATE = <any>'create',
    CREDIT = <any>'credit',
    DELETE = <any>'delete',
    EXISTS = <any>'exists',
    FAILED = <any>'failed',
    START = <any>'start',
    SAVE = <any>'save',
    SEND = <any>'send',
    SUCCESS = <any>'success',
}

export enum ANALYTICS_REGISTRATION_ACTION {
    COMPLETED_STEP_1 = 'completed_step_1',
    COMPLETED_STEP_2 = 'completed_step_2',
    CLAMIED_REGISTRATION = 'claimed_registration'
}

export enum ANALYTICS_CATEGORY {
    BILL = <any>'bill',
    INVOICE = <any>'invoice',
    LOAN = <any>'loan',
    LOGIN = <any>'login',
    RECEIPT = <any>'receipt',
    SALARY = <any>'salary',
    REGISTRATION = <any>'registration',
}

export enum ANALYTICS_FIELD {
    USER_STATUS = 'dimension1',
    PAGE = 'page'
}

@Injectable()
export class Analytics {
    private _appConfig: AppConfig;

    constructor (
        appConfig: AppConfig
    ) {
        this._appConfig = appConfig;
    }

    init() {
        window['ga']('create', this._appConfig.get('analytics.id'), 'auto', {'allowLinker': true});
        window['ga']('require', 'linker');
    }

    set(fieldName: ANALYTICS_FIELD, value: string) {
        window['ga']('set', fieldName, value);
    }

    send(hitType: string) {
        window['ga']('send', hitType);
    }

    track(category: ANALYTICS_CATEGORY, action: ANALYTICS_ACTION|ANALYTICS_REGISTRATION_ACTION, label?: string) {
        const event = {
            hitType: 'event',
            eventCategory: category,
            eventAction: action,
        };

        if (label) {
            event['eventLabel'] = label;
        }

        window['ga']('send', event);
    }
}
