import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {DIALOG_RESULT} from '@red/components';

@Component ({
    templateUrl: './abandon.tpl.html'
})
export class InvoiceAbandonConfirmComponent {
    private _dialogRef: MatDialogRef<InvoiceAbandonConfirmComponent>;

    constructor (
        dialogRef: MatDialogRef<InvoiceAbandonConfirmComponent>,
        @Inject(MAT_DIALOG_DATA) data: any
    ) {
        this._dialogRef = dialogRef;
    }

    cancel() {
        this._dialogRef.close(DIALOG_RESULT.CANCEL);
    }

    confirm() {
        this._dialogRef.close(DIALOG_RESULT.OK);
    }
}
