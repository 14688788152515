import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Store} from '@ngxs/store';
import {AuthState} from '../../shared/state/auth/auth.state';

const SESSION_TOKEN_NAME = 'x-redflag-session-token';

@Injectable()
export class AuthTokenHook implements HttpInterceptor {
    private _store: Store;

    constructor (
        store: Store
    ) {
        this._store = store;
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const isAsset = /^\/assets/.test(req.url);
        const sessionToken = this._store.selectSnapshot(AuthState.sessionToken);
        if (sessionToken && !isAsset && !req.headers.get(SESSION_TOKEN_NAME)) {
            return next.handle(req.clone({headers: req.headers.set(SESSION_TOKEN_NAME, sessionToken)}));
        }

        return next.handle(req);
    }
}
