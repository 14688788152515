import {Component, OnInit} from '@angular/core';
import {Amount, COMPANY_TYPE, YearEndMetaData} from '@red/data';
import {EndOfYearManager} from '../../../managers/end-of-year/end-of-year.manager';
import {Store} from '@ngxs/store';
import {AuthState} from '../../../shared/state/auth/auth.state';

@Component({
    selector: 'app-end-of-year-accumulated-salary',
    styleUrls: [
        '../end-of-year.dialog.sass',
        '../end-of-year-steps/end-of-year-step-waiting-for-customer-to-sign/end-of-year-waiting-for-customer-to-sign.sass'
    ],
    templateUrl: 'end-of-year-accumulated-salary.tpl.html'
})

export class EndOfYearAccumulatedSalaryComponent implements OnInit {

    get isLLC(): boolean { return this._companyType === COMPANY_TYPE.LIMITED_COMPANY; }
    get ownerSalary(): Amount { return this._metadata.ownerSalaries || Amount.SEK(0); }
    get totalSalary(): Amount {
        const ownerSalaries = this._metadata.ownerSalaries && this._metadata.ownerSalaries.amount || 0;
        const employeeSalaries = this._metadata.employeeSalaries && this._metadata.employeeSalaries.amount || 0;

        return Amount.SEK(ownerSalaries + employeeSalaries);
    }

    private _companyType: COMPANY_TYPE;
    private _endOfYearManager: EndOfYearManager;
    private _store: Store;
    private _metadata: YearEndMetaData;

    constructor(
        endOfYearManager: EndOfYearManager,
        store: Store
    ) {
        this._endOfYearManager = endOfYearManager;
        this._store = store;
    }

    ngOnInit(): void {
        this._companyType = this._store.selectSnapshot(AuthState.companyType);
        this._metadata = this._endOfYearManager.stepMetadata;
    }
}
