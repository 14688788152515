import {
    Action,
    Actions,
    createSelector, ofActionCompleted,
    ofActionSuccessful,
    Selector,
    State,
    StateContext,
    StateToken,
    Store
} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {CardModel, CardStateModel} from './card.model';
import {Card} from '@red/data';
import {Observable} from 'rxjs';
import {ActivateCard, ListCardForCompany, UpdateCardStatus} from './card.actions';
import {mergeMap, take, tap} from 'rxjs/operators';
import {CardManager} from '../../../managers/card/card-manager';
import {
    CollectBankIdSignature,
    SignatureStatusUpdate,
    SignPayloadWithBankIdSignature
} from '../signature/signature.actions';
import {SignatureState} from '../signature/signature.state';

export const CARD_STATE_TOKEN = new StateToken<CardStateModel>('card');

@State<CardStateModel>({
    name: CARD_STATE_TOKEN,
    defaults: {
        cards: []
    }
})
@Injectable()
export class CardState {
    private _cardManager: CardManager;
    private _store: Store;
    private _actions: Actions;

    constructor(
        cardManager: CardManager,
        store: Store,
        actions: Actions
    ) {
        this._cardManager = cardManager;
        this._store = store;
        this._actions = actions;
    }

    @Selector()
    static cards(state: CardStateModel): Card[] {
        return state.cards.map(value => new Card(value));
    }

    static cardsForCompanyId(companyId: string) {
        return createSelector([CardState], (state: CardStateModel) => {
            const cards = CardState._filterByCompanyId(state, companyId);
            return cards.map(value => new Card(value));
        });
    }

    private static _filterByCompanyId(state: CardStateModel, companyId: string): CardModel[] {
        return state.cards.filter(c => c.companyId === companyId);
    }

    @Action(ListCardForCompany)
    listCardForCompany(ctx: StateContext<CardStateModel>, action: ListCardForCompany): Observable<any> {
        return this._cardManager.listCards(action.payload.companyId)
            .pipe(
                tap((cards: CardModel[]) => {
                    ctx.patchState({
                        cards
                    });
                })
            );
    }

    @Action(UpdateCardStatus)
    updateCardStatus(ctx: StateContext<CardStateModel>, action: UpdateCardStatus): Observable<any> {
        const {cardId, status} = action.payload;
        return this._cardManager.setStatus(cardId, status)
            .pipe(
                tap((card: CardModel) => this._updateCardInList(ctx, card))
            );
    }

    @Action(ActivateCard)
    activateCard(ctx: StateContext<CardStateModel>, action: ActivateCard) {
        const {cardId, lastFourDigits, userIdentification, summary} = action.payload;
        return this._cardManager.getSignaturePayload(cardId, lastFourDigits)
            .pipe(
                mergeMap((resp) => {
                    const payloadAction = new SignPayloadWithBankIdSignature({payloadBase64: resp.payloadBase64, summary, userIdentification});
                    return this._store.dispatch(payloadAction);
                }),
                mergeMap((resp) => {
                    const collectId = this._store.selectSnapshot(SignatureState.collectId);
                    const collectAction = new CollectBankIdSignature({collectId});
                    return this._store.dispatch(collectAction);
                }),
                mergeMap((err) => {
                    const signature = this._store.selectSnapshot(SignatureState.currentSignature);
                    return this._cardManager.activateCard(cardId, signature);
                }),
                tap((card: CardModel) => this._updateCardInList(ctx, card))
            );
    }

    private _findIndexOfCard(cardId: string, cards: CardModel[]) {
        return cards.findIndex((card) => {
            return card.cardId === cardId;
        });
    }

    private _updateCardInList(ctx: StateContext<CardStateModel>, card: CardModel) {
        const cards = [...ctx.getState().cards];
        const index = this._findIndexOfCard(card.cardId, cards);

        if (index !== -1) {
            cards.splice(index, 1, card);
        } else {
            cards.push(card);
        }

        ctx.patchState({
            cards
        });
    }
}
