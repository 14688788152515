<div red-grid>
    <ng-container *ngIf="hasCompany">
        <h3 class="section-header" i18n="Settings|Header|your company">Your company</h3>
        <red-info-box redInfoBoxSettings>
            <span i18n="Settings|information text about company settings">This is data that written on your invoices</span>
        </red-info-box>
        <div class="user-card-column" *ngIf="company">
            <span>{{company.details.name}}</span>
            <red-display-address
                [showHeader]="false"
                [address]="company.details.address"
                [showCountry]="false"
            >
            </red-display-address>
        </div>
        <form class="user-settings-form" [formGroup]="companyDetailsForm" *ngIf="companyDetailsForm" (submit)="saveCompanyDetails()">
            <mat-form-field>
                <input
                    matInput
                    email
                    formControlName="email"
                    i18n-placeholder="user e-mail"
                    placeholder="E-mail"
                    autocomplete="off"
                />
            </mat-form-field>
            <div class="user-action-button" *ngIf="notReadOnly">
                <button mat-flat-button color="primary" [disabled]="companyDetailsForm.pristine" i18n="Save settings">Save</button>
            </div>
        </form>
    </ng-container>
    <h3 class="section-header" i18n="Settings|Header|your personal information">Your personal details</h3>
    <red-info-box redInfoBoxSettings>
        <span i18n="Settings|information text about user settings">This is information that we use when we need to contact you</span>
    </red-info-box>
    <form class="user-settings-form" [formGroup]="userSettingsForm" (submit)="save()">
        <div formGroupName="details">
            <mat-form-field>
                <input
                    matInput
                    email
                    autocomplete="off"
                    i18n-placeholder="user e-mail"
                    placeholder="E-mail"
                    formControlName="email"
                />
            </mat-form-field>
            <mat-form-field>
                <input
                    matInput
                    autocomplete="off"
                    (keypress)="onlyNumbersInput($event)"
                    (paste)="onlyNumbersPaste($event)"
                    i18n-placeholder="user phone number" placeholder="Phone number"
                    formControlName="phone" />
            </mat-form-field>
            <red-info-box redInfoBoxSettings class="user-settings-form-info-box">
                <span i18n="Settings|information text about accounts">This is your private bank-account for paying salary</span>
            </red-info-box>
            <div formGroupName="mainAccount" class="user-account red-row">
                <mat-form-field class="clearing-number">
                    <input
                        matInput
                        (keypress)="onlyNumbersInput($event)"
                        (paste)="onlyNumbersPaste($event)"
                        type="text"
                        autocomplete="off"
                        [placeholder]="clearingNumberPlaceholder()"
                        formControlName="clearingNo" />
                </mat-form-field>
                <mat-form-field class="account-number">
                    <input
                        matInput
                        (keypress)="onlyNumbersInput($event)"
                        (paste)="onlyNumbersPaste($event)"
                        type="text"
                        autocomplete="off"
                        i18n-placeholder="user account number" placeholder="Account number"
                        formControlName="accountNo" />
                </mat-form-field>
            </div>
        </div>
        <div class="user-action-button">
            <button mat-flat-button color="primary" [disabled]="!enableSaveButton()" i18n="Save settings">Save</button>
        </div>
    </form>

<!--    <ng-container *ngIf="hasCompany && notReadOnly">-->
<!--        <h3 class="section-header" i18n="User|settings|header|voucher link">voucher link</h3>-->
<!--        <red-info-box redInfoBoxSettings i18n="User|settings|voucher link explanation">voucher link explanation</red-info-box>-->
<!--    </ng-container>-->
</div>
