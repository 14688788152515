import {Component, OnInit} from '@angular/core';
import {EndOfYearManager} from '../../../managers/end-of-year/end-of-year.manager';
import {AnnualReportManager} from '../../../managers/annual-report/annual-report.manager';
import {CompanyYearEndStep, YEAR_END_STEP} from '@red/data';

@Component({
    selector: 'app-end-of-year-info-texts',
    styleUrls: ['../end-of-year.dialog.sass', './end-of-year-info-texts.sass'],
    templateUrl: 'end-of-year-info-texts.tpl.html'
})

export class EndOfYearInfoTextsComponent implements OnInit {
    get showFullText(): boolean { return this._showFullText; }
    get endOfYearDividendsAndAnnualReportLink(): string { return this._endOfYearDividendsAndAnnualReportLink; }
    get stepIsBoardMemberInformation(): boolean { return this._stepIsBoardMemberInformation; }

    private _endOfYearDividendsAndAnnualReportLink: string;
    private _endOfYearManager: EndOfYearManager;
    private _annualReportManager: AnnualReportManager;
    private _step: CompanyYearEndStep;
    private _showFullText: boolean;
    private _stepIsBoardMemberInformation: boolean;

    constructor (
        endOfYearManager: EndOfYearManager,
        annualReportManager: AnnualReportManager
    ) {
        this._endOfYearManager = endOfYearManager;
        this._annualReportManager = annualReportManager;
    }

    ngOnInit(): void {
        this._step = this._endOfYearManager.endOfYearStep;

        this._showFullText = (this._step.step === YEAR_END_STEP.DIVIDENDS);
        this._stepIsBoardMemberInformation = (this._step.step === YEAR_END_STEP.BOARD_MEMBER_INFORMATION);

        this._endOfYearDividendsAndAnnualReportLink = this._endOfYearManager.endOfYearDividendsAndAnnualReportLink;
    }
}
