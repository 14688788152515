<red-info-box redInfoBoxList>
    <mat-icon svgIcon="ui-info-slate"></mat-icon>
    <span i18n="Info box|Income stream|general information">Information about income streams</span>
</red-info-box>
<div [ngSwitch]="state">
    <red-skeleton-loader *ngSwitchCase="stateLoading" [rows]="2"></red-skeleton-loader>
    <div *ngSwitchCase="stateNoResult">
        <red-blank-slate icon="list-income-stream">
            <span i18n="Blank slate|No income streams">You have no income streams</span>
        </red-blank-slate>
    </div>
    <div *ngSwitchCase="stateDone">
        <red-list *ngIf="royaltyStatementsWaitingForPayment.hasResults">
            <span redListHeader i18n="Income streams|List|header for waiting for payment">Waiting for payment</span>
            <mat-list-item *ngFor="let transaction of royaltyStatementsWaitingForPayment.items | async" redListLink [routerLink]="['/income-stream', transaction.reference.id, 'edit']">
                <red-list-icon mat-list-avatar [item]="transaction"></red-list-icon>
                <h4 mat-line>{{ getDescription(transaction ) }}</h4>
                <time mat-line>{{transaction.createdAt | moment}}</time>
                <app-display-amount-text *ngIf="transaction.total" [amount]="transaction.total" [preliminaryAmount]="true" type="ROYALTY_STATEMENT">
                </app-display-amount-text>
            </mat-list-item>
        </red-list>
        <button *ngIf="!royaltyStatementsWaitingForPayment.complete" mat-button (click)="loadMore(royaltyStatementsWaitingForPayment)" class="invoice-load-more-btn" i18n="Income streams|List|load more btn">Load more...</button>

        <red-list *ngIf="royaltyStatementsRejected.hasResults">
            <span redListHeader i18n="Income streams|List|header for rejected">Need actions</span>
            <mat-list-item *ngFor="let transaction of royaltyStatementsRejected.items | async" redListLink [routerLink]="['/income-stream', transaction.reference.id, 'edit']">
                <red-list-icon mat-list-avatar [item]="transaction"></red-list-icon>
                <h4 mat-line>{{ getDescription(transaction ) }}</h4>
                <time mat-line>{{transaction.createdAt | moment}}</time>
                <app-display-amount-text *ngIf="transaction.total" [amount]="transaction.total" [preliminaryAmount]="true" type="ROYALTY_STATEMENT">
                </app-display-amount-text>
            </mat-list-item>
        </red-list>
        <button *ngIf="!royaltyStatementsRejected.complete" mat-button (click)="loadMore(royaltyStatementsRejected)" class="invoice-load-more-btn" i18n="Income streams|List|load more btn">Load more...</button>

        <red-list *ngIf="royaltyStatementsPaid.hasResults">
            <span redListHeader i18n="Income streams|List|header for paid">Paid</span>
            <!--<a redListHeaderLink i18n="Income streams|List|see all btn">SEE ALL</a>-->
            <mat-list-item *ngFor="let transaction of royaltyStatementsPaid.items | async" redListLink [routerLink]="['/income-stream', transaction.reference.id, 'edit']">
                <red-list-icon mat-list-avatar [item]="transaction"></red-list-icon>
                <h4 mat-line>{{ getDescription(transaction ) }}</h4>
                <time mat-line>{{transaction.createdAt | moment }}</time>
                <app-display-amount-text *ngIf="transaction.total" [amount]="transaction.total" type="ROYALTY_STATEMENT">
                    <span *ngIf="transaction.forexAmount" class="forex-amount" [innerHTML]="transaction.forexAmount | amount"></span>
                </app-display-amount-text>
            </mat-list-item>
        </red-list>
        <button *ngIf="!royaltyStatementsPaid.complete" mat-button (click)="loadMore(royaltyStatementsPaid)" class="invoice-load-more-btn" i18n="Income streams|List|load more btn">Load more...</button>
    </div>
</div>
<router-outlet></router-outlet>
