import {Component, ViewEncapsulation} from '@angular/core';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'app-view-card-infobar',
    styleUrls: ['./view-card-infobar.sass'],
    templateUrl: './view-card-infobar.tpl.html'
})

export class ViewCardInfobarComponent {
}
