import {Injectable} from '@angular/core';
import {ANNUAL_REPORT_STATE, AnnualReport, AnnualReportListResponse} from '@red/data';
import {Resolve} from '@angular/router';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {of} from 'rxjs';
import {AuthState} from '../../shared/state/auth/auth.state';
import {Store} from '@ngxs/store';
import {AnnualReportManager} from '../../managers/annual-report/annual-report.manager';

@Injectable()
export class DividendsRouteResolve implements Resolve<AnnualReport> {
    private _annualReportManager: AnnualReportManager;
    private _store: Store;

    constructor(
        annualReportManager: AnnualReportManager,
        store: Store
    ) {
        this._annualReportManager = annualReportManager;
        this._store = store;
    }

    resolve(): Observable<AnnualReport> {
        return this._annualReportManager.list(this._store.selectSnapshot(AuthState.companyId))
            .pipe(
                map((resp: AnnualReportListResponse) => {
                    return resp.data.sort((a, b) => {
                        return a.fiscalYearEndDate < b.fiscalYearEndDate ? 1 : -1;
                    }).filter(report => report.state === ANNUAL_REPORT_STATE.ACCEPTED || report.state === ANNUAL_REPORT_STATE.REGISTERED).shift();
                }),
                catchError(() => {
                    return of(null);
                })
            );
    }
}
