import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {
    RedAssetModule,
    RedTopToolbarModule,
    RedLoaderModule,
    RedListModule,
    RedMarkdownModule,
    RedDialogModule,
    RedInfoBoxModule,
    RedFormModule,
    RedGridModule,
    RedBlankSlateModule,
    RedTableRowModule,
    RedDisplayModule, RedDownloadableReportsModule
} from '@red/components';
import {VIEW_ROUTES} from './views.routes';
import {BrowserModule} from '@angular/platform-browser';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import {DashboardViewComponent} from './dashboard/dashboard';
import {ValidSessionGuard} from '../security/route/session';
import {CommonModule} from '@angular/common';
import {ViewCardModule} from '../components/view-card/view-card.module';
import {GraphModule} from '../components/graph/graph.module';
import {AmountModule} from '../components/amount/amount.module';
import {InvoiceViewComponent} from './invoice/invoice';
import {InvoiceListViewComponent} from './invoice/list/list';
import {InvoiceCreateViewComponent} from './invoice/create/create';
import {InvoiceModule} from '../components/invoice/invoice.module';
import {InvoiceRouteResolve} from './invoice/invoice.route.resolve';
import {InvoiceEditViewComponent} from './invoice/edit/edit';
import {ReceiptListViewComponent} from './receipt/list/list';
import {ReceiptViewComponent} from './receipt/receipt';
import {ReceiptRouteResolve} from './receipt/receipt.route.resolve';
import {ReceiptModule} from '../components/receipt/receipt.module';
import {ReceiptEditViewComponent} from './receipt/edit/edit';
import {CardTransactionViewComponent} from './card-transaction/card-transaction';
import {CardTransactionListViewComponent} from './card-transaction/list/list';
import {CardTransactionEditViewComponent} from './card-transaction/edit/edit';
import {CardTransactionRouteResolve} from './card-transaction/card-transaction.route.resolve';
import {CardTransactionModule} from '../components/card-transaction/card-transaction.module';
import {SupportViewComponent} from './support/support';
import {ReactiveFormsModule} from '@angular/forms';
import {SettingsViewComponent} from './settings/settings';
import {UserViewComponent} from './settings/user/user';
import {UserCardViewComponent} from './settings/card/card';
import {CardModule} from '../components/card/card.module';
import {InvalidSessionGuard} from '../security/route/invalidSession';
import {Error404ViewComponent} from './error/404/404';
import {RoyaltyStatementViewComponent} from './royalty-statement/royalty-statement';
import {IncomeStreamListViewComponent} from './royalty-statement/list/list';
import {RoyaltyStatementModule} from '../components/royalty-statement/royalty-statement.module';
import {RoyaltyStatementEditViewComponent} from './royalty-statement/edit/edit';
import {RoyaltyStatementRouteResolve} from './royalty-statement/royalty-statement.route.resolve';
import {ConsentModule} from '../components/consent/consent.module';
import {ConsentGuard} from '../security/route/consent';
import {CardGuard} from '../security/route/card';
import {EmployeeGuard} from '../security/route/employee';
import {LimitedCompanyGuard} from '../security/route/limtedCompany';
import {SoleTraderGuard} from '../security/route/soleTrader';
import {EmployeeSalaryGuard} from '../security/route/employeeSalary';
import {EndOfYearModule} from '../components/end-of-year/end-of-year.module';
import {UserAdminModule} from '../components/user-admin/user-admin.module';
import {ShareholderModule} from '../components/shareholder/shareholder.module';
import {ReportViewComponent} from './report/report';
import {DividendsViewComponent} from './dividends/dividends';
import {DividendsListViewComponent} from './dividends/list/list';
import {DividendsModule} from '../components/dividends/dividends.module';
import {DividendsCreateViewComponent} from './dividends/create/create';
import {DividendsRouteResolve} from './dividends/dividends.route.resolve';
import {CarBenefitGuard} from '../security/route/car-benefit';
import {CompanyGuard} from '../security/route/company-guard';
import {BlankSlateModule} from '../components/blank-slate/blank-slate.module';
import {OnboardingAlertModule} from '../components/onboarding-alert/onboarding-alert.module';
import { RedPipeModule } from '@red/ng';
import {RedLoginModule} from '../../tmp-red-web/src/ng/components/login';
import {FinancialStatementProjectionRouteResolve} from '../shared/route/financial-statement-projection-route-resolve';
import {FinancialStatementCompanyOwedRouteResolve} from '../shared/route/financial-statement-company-owed-route-resolve';
import {SettingsCardRouteResolve} from './settings/card/settings-card-route-resolve';
import {SignatureModule} from '../components/signature/signature.module';
import {DownloadableReportsViewComponent} from './downloadable-reports/downloadable-reports';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {RedFlagShutDownDialogModule} from '../components/red-flag-shut-down-dialog/red-flag-shut-down-dialog.module';
import {RedFlagShutDownBannerModule} from '../components/red-flag-shut-down-banner/red-flag-shut-down-banner.module';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {RedFlagShutDownTriggerModule} from '../components/red-flag-shut-down-trigger/red-flag-shut-down-trigger.module';

@NgModule({
    declarations: [
        CardTransactionViewComponent,
        CardTransactionListViewComponent,
        CardTransactionEditViewComponent,
        DashboardViewComponent,
        DividendsViewComponent,
        DividendsListViewComponent,
        DividendsCreateViewComponent,
        DownloadableReportsViewComponent,
        Error404ViewComponent,
        RoyaltyStatementViewComponent,
        RoyaltyStatementEditViewComponent,
        IncomeStreamListViewComponent,
        InvoiceViewComponent,
        InvoiceCreateViewComponent,
        InvoiceEditViewComponent,
        InvoiceListViewComponent,
        ReceiptViewComponent,
        ReceiptEditViewComponent,
        ReceiptListViewComponent,
        ReportViewComponent,
        SettingsViewComponent,
        SupportViewComponent,
        UserCardViewComponent,
        UserViewComponent
    ],
    imports: [
        BrowserModule,
        CommonModule,
        ReactiveFormsModule,
        RouterModule.forRoot(VIEW_ROUTES, {relativeLinkResolution: 'legacy'}),
        ReactiveFormsModule,
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MatDialogModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatSelectModule,
        MatTabsModule,
        RedAssetModule,
        RedBlankSlateModule,
        RedDialogModule,
        RedFormModule,
        RedInfoBoxModule,
        RedListModule,
        RedLoaderModule,
        RedLoginModule,
        RedMarkdownModule,
        RedPipeModule,
        RedTableRowModule,
        RedTopToolbarModule,
        AmountModule,
        CardModule,
        CardTransactionModule,
        ConsentModule,
        DividendsModule,
        EndOfYearModule,
        GraphModule,
        RoyaltyStatementModule,
        InvoiceModule,
        ReceiptModule,
        ViewCardModule,
        UserAdminModule,
        ShareholderModule,
        RedGridModule,
        BlankSlateModule,
        OnboardingAlertModule,
        RedDisplayModule,
        SignatureModule,
        MatDatepickerModule,
        RedDownloadableReportsModule,
        RedGridModule,
        RedFlagShutDownDialogModule,
        RedFlagShutDownBannerModule,
        RedFlagShutDownTriggerModule,
        MatProgressSpinnerModule
    ],
    providers: [
        // Route Guards
        CarBenefitGuard,
        CardGuard,
        ConsentGuard,
        EmployeeGuard,
        EmployeeSalaryGuard,
        InvalidSessionGuard,
        ValidSessionGuard,
        CompanyGuard,
        LimitedCompanyGuard,
        SoleTraderGuard,
        // Route Resolve
        DividendsRouteResolve,
        RoyaltyStatementRouteResolve,
        InvoiceRouteResolve,
        ReceiptRouteResolve,
        CardTransactionRouteResolve,
        InvoiceRouteResolve,
        ReceiptRouteResolve,
        FinancialStatementProjectionRouteResolve,
        FinancialStatementCompanyOwedRouteResolve,
        SettingsCardRouteResolve
    ]
})
export class ViewsModule {}
