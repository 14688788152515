import {NgModule} from '@angular/core';
import {DisplayAmountTextComponent} from './amount-text';
import {CommonModule} from '@angular/common';
import {RedPipeModule} from '@red/ng';

@NgModule({
    exports: [
        DisplayAmountTextComponent
    ],
    declarations: [
        DisplayAmountTextComponent
    ],
    imports: [
        CommonModule,
        RedPipeModule
    ]
})
export class AmountModule {}
