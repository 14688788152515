<red-info-box>
    <span i18n="End of year|dialog banner|soletrader|started" *ngIf="isSoleTrader">End of year dialog banner: started <a href="{{endOfYearBloggLink}}" target="_blank">read more</a></span>
    <span i18n="End of year|dialog banner|llc|started" *ngIf="isLLC">LLC TEXT: End of year dialog banner: started <a href="{{endOfYearBloggLink}}" target="_blank">read more</a></span>
</red-info-box>
<div class="end-of-year-dialog-content">
    <app-end-of-year-metadata-form
        [metadataFormInput]="metadataForm"
        (metadataFormOutput)="saveMetaData($event)"
    ></app-end-of-year-metadata-form>
</div>
