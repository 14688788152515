import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Amount, CURRENCY} from '@red/data';

@Component({
    selector: 'app-dividends-form',
    styleUrls: ['./form.sass'],
    templateUrl: './form.tpl.html'
})

export class DividendsFormComponent implements OnInit {
    @Input() public remainingDividends: Amount;
    @Output() public emitDividendsFormChange = new EventEmitter<FormGroup>();
    @Output() public emitDividendsFormSubmit = new EventEmitter<FormGroup>();

    get canCalculate(): boolean {
        return this._dividendsForm && this._dividendsForm.pristine && !this._isFirstTry;
    }
    get dividendsForm(): FormGroup {
        return this._dividendsForm;
    }
    get leftDividends(): Amount {
        return this._leftDividends;
    }

    private _dividendsForm: FormGroup;
    private _isFirstTry = true;
    private _formBuilder: FormBuilder;
    private _leftDividends: Amount;

    constructor (
        formBuilder: FormBuilder
    ) {
        this._formBuilder = formBuilder;
    }

    ngOnInit(): void {
        this._leftDividends = this.remainingDividends;
        this._dividendsForm = this._formBuilder.group({
            'dividends': [0]
        });


        this._dividendsForm.valueChanges
            .pipe(
                debounceTime(900),
                distinctUntilChanged()
            )
            .subscribe(() => {
                this._calculateLeftDividends();
                this.emitDividendsFormChange.emit(this._dividendsForm);
            });
    }

    isValid() {
        const {dividends} = this._dividendsForm.getRawValue();
        return /^[0-9.,]+$/.test(dividends);
    }

    submit($event) {
        $event.preventDefault();
        this._isFirstTry = false;

        this._dividendsForm.markAsPristine();
        this.emitDividendsFormSubmit.emit(this._dividendsForm);
    }


    private _calculateLeftDividends() {
        const dividends = this._dividendsForm.get('dividends').value;
        this._leftDividends = new Amount({
            'amount': parseFloat((this.remainingDividends.amount - dividends).toFixed(2)),
            'currency': CURRENCY.SEK
        });
    }
}
