import {Component, Inject, LOCALE_ID, OnInit} from '@angular/core';
import {DIALOG_RESULT, RedDialog, RedNotification} from '@red/components';
import {
    Amount, CARD_TRANSACTION_RESERVATION_STATUS, CardTransaction, PAYMENT_TYPE,
    REFERENCE_TYPE,
    Transaction, TRANSACTION_STATUS,
    TRANSACTION_SUB_TYPE
} from '@red/data';
import {AppConfig} from '../../../config/appConfig';
import {
    QueryFilter, QueryResponse, LIST_STATUS, ListDataSource
} from '@red/browser';
import {ReceiptCreateComponent} from '../../../components/receipt/create/create';
import {forkJoin} from 'rxjs';
import {map, first} from 'rxjs/operators';
import {CardTransactionEventReader, CardTransactionUpdatedEvent} from '../../../common/event/readers/cardTransaction';
import {ReceiptEventReader} from '../../../common/event/readers/receipt';
import {CardTransactionMarkAsDebtConfirmComponent} from '../../../components/card-transaction/confirm/markAsDebt/markAsDebt';
import {CardTransactionCantMarkAsDebtConfirmComponent} from '../../../components/card-transaction/confirm/cantMarkAsDebt/cantMarkAsDebt';
import {EventSource} from '../../../common/event/source';
import {CardTransactionWaitForSettlementCofirmComponent} from '../../../components/card-transaction/confirm/wait-for-settlement/wait-for-settlement';
import {CardTransactionServiceClient} from '../../../lab/service-client/card-transaction-service-client';
import {AuthState} from '../../../shared/state/auth/auth.state';
import {Store} from '@ngxs/store';
import {FinancialStatementState} from '../../../shared/state/financial-statement/financial-statement.state';
import {TransactionManager} from '../../../managers/transaction/transaction.manager';
import {RedFlagShutDownManager} from '../../../managers/red-flag-shut-down/red-flag-shut-down.manager';

@Component({
    selector: 'app-receipt-list',
    templateUrl: './list.tpl.html'
})

export class ReceiptListViewComponent implements OnInit {
    get cardTransactions(): ListDataSource<Transaction> { return this._cardTransactions; }
    get receiptInfoMarkdown(): string { return this._receiptInfoMarkdown; }
    get receiptsBooked(): ListDataSource<Transaction> { return this._receiptsBooked; }
    get receiptsPending(): ListDataSource<Transaction> { return this._receiptsPending; }
    get receiptsRejected(): ListDataSource<Transaction> { return this._receiptsRejected; }
    get state(): LIST_STATUS { return this._state; }
    get stateDone(): LIST_STATUS { return LIST_STATUS.DONE; }
    get stateLoading(): LIST_STATUS { return LIST_STATUS.LOADING; }
    get stateNoResult(): LIST_STATUS { return LIST_STATUS.NO_RESULT; }
    get notReadOnly(): boolean { return this._notReadOnly; }

    private _appConfig: AppConfig;
    private _cardTransactions: ListDataSource<Transaction>;
    private _cardTransactionEventReader: CardTransactionEventReader;
    private _cardTransactionServiceClient: CardTransactionServiceClient;
    private _eventSource: EventSource;
    private _notification: RedNotification;
    private _companyId: string;
    private _hasCompany: boolean;
    private _owed: Amount;
    private _receiptEventReader: ReceiptEventReader;
    private _receiptsBooked: ListDataSource<Transaction>;
    private _receiptsPending: ListDataSource<Transaction>;
    private _receiptsRejected: ListDataSource<Transaction>;
    private _receiptInfoMarkdown: string;
    private _redDialog: RedDialog;
    private _store: Store;
    private _state: LIST_STATUS = LIST_STATUS.LOADING;
    private _transactionManager: TransactionManager;
    private _redFlagShutDownManager: RedFlagShutDownManager;
    private _notReadOnly: boolean;

    constructor(
        appConfig: AppConfig,
        cardTransactionEventReader: CardTransactionEventReader,
        cardTransactionServiceClient: CardTransactionServiceClient,
        eventSource: EventSource,
        notification: RedNotification,
        receiptEventReader: ReceiptEventReader,
        redDialog: RedDialog,
        store: Store,
        transactionManager: TransactionManager,
        redFlagShutDownManager: RedFlagShutDownManager,
        @Inject(LOCALE_ID) localeId: string
    ) {
        this._appConfig = appConfig;
        this._cardTransactionEventReader = cardTransactionEventReader;
        this._cardTransactionServiceClient = cardTransactionServiceClient;
        this._eventSource = eventSource;
        this._notification = notification;
        this._receiptEventReader = receiptEventReader;
        this._redDialog = redDialog;
        this._store = store;
        this._transactionManager = transactionManager;
        this._redFlagShutDownManager = redFlagShutDownManager;

        this._receiptInfoMarkdown = this._appConfig.get(`content.receipt.info.${localeId}`);
    }

    ngOnInit() {
        const today = this._redFlagShutDownManager.today;
        this._notReadOnly = this._redFlagShutDownManager.notReadOnlyState(today);

        this._owed = this._store.selectSnapshot(FinancialStatementState.companyOwed);
        this._companyId = this._store.selectSnapshot(AuthState.companyId);
        this._hasCompany = !!this._companyId;

        if (this._hasCompany) {
            this._cardTransactions = new ListDataSource<Transaction>((filter: QueryFilter) => {
                return this._transactionManager.queryToQueryResponse(this._store.selectSnapshot(AuthState.companyId), filter);
            });

            this._receiptsBooked = new ListDataSource<Transaction>((filter: QueryFilter) => {
                return this._transactionManager.queryToQueryResponse(this._store.selectSnapshot(AuthState.companyId), filter).pipe(map((resp: QueryResponse<Transaction>) => {
                    resp.results.forEach((transaction: Transaction) => {
                        transaction.description = this._getDescriptionForTransaction(transaction);
                    });

                    return resp;
                }));
            });

            this._receiptsPending = new ListDataSource<Transaction>((filter: QueryFilter) => {
                return this._transactionManager.queryToQueryResponse(this._store.selectSnapshot(AuthState.companyId), filter);
            });

            this._receiptsRejected = new ListDataSource<Transaction>((filter: QueryFilter) => {
                return this._transactionManager.queryToQueryResponse(this._store.selectSnapshot(AuthState.companyId), filter).pipe(map((resp: QueryResponse<Transaction>) => {
                    resp.results.forEach((transaction: Transaction) => {
                        transaction.description = this._getDescriptionForTransaction(transaction);
                    });

                    return resp;
                }));
            });

            this._loadTransactions();

            this._cardTransactionEventReader.onUpdate(() => {
                this._loadTransactions();
            });

            this._receiptEventReader.onCreated(() => {
                this._loadTransactions();
            });

            this._receiptEventReader.onUpdate(() => {
                this._loadTransactions();
            });
        } else {
            this._state = LIST_STATUS.NO_RESULT;
        }
    }

    createNewExpense($event: File, transaction: Transaction) {
        this._redDialog
            .open(ReceiptCreateComponent, {'data': {'transaction': transaction, 'file': $event}})
            .afterClosed().pipe(first())
            .subscribe((result: DIALOG_RESULT) => {
                if (result === DIALOG_RESULT.OK) {
                    this._loadTransactions();
                }
            });
    }

    isDebit(transaction: Transaction): boolean {
        return transaction.direction === PAYMENT_TYPE.DEBIT;
    }

    markAsDebt($event, transaction: Transaction) {
        $event.stopPropagation();
        this._cardTransactionServiceClient.getCardTransaction(transaction.reference.id)
            .subscribe((resp: CardTransaction) => {
                if (resp.reservation.status === CARD_TRANSACTION_RESERVATION_STATUS.RESERVED) {
                    this._redDialog.confirm(CardTransactionWaitForSettlementCofirmComponent);
                } else {
                    if (transaction.total.amount <= this._owed.amount) {
                        this._redDialog.confirm(CardTransactionMarkAsDebtConfirmComponent, {'data': {'amount': transaction.total}})
                            .afterClosed()
                            .subscribe((result: DIALOG_RESULT) => {
                                if (result === DIALOG_RESULT.OK) {
                                    this._resolveTransactionAsDebt(transaction);
                                }
                            });
                    } else {
                        const neededAmount = ((transaction.total.amount - this._owed.amount) * 100) / 100;
                        this._redDialog.confirm(CardTransactionCantMarkAsDebtConfirmComponent, {'data': {'minNeededAmount': neededAmount}});
                    }
                }
            });
    }

    loadMore(dataSource: ListDataSource<Transaction>) {
        dataSource.next().subscribe(() => {
        });
    }

    routerLink(transaction: Transaction): string {
        if (transaction.reference.type === REFERENCE_TYPE.CARD_TRANSACTION) {
            return `${transaction.reference.id}/transaction`;
        } else {
            return `${transaction.reference.id}/edit`;
        }
    }

    private _getDescriptionForTransaction(transaction: Transaction): string {
        const fallback = $localize`:List label|:Receipt`;

        return transaction.description || fallback;
    }

    private _loadTransactions() {
        this._state = LIST_STATUS.LOADING;

        const cardTransactionFilter = new QueryFilter()
            .equal('status', [TRANSACTION_STATUS.PENDING])
            .equal('type', [REFERENCE_TYPE.CARD_TRANSACTION])
            .equal('subType', [TRANSACTION_SUB_TYPE.PENDING_RESOLUTION])
            .equal('orderBy', 'CREATED_AT')
            .offset(0)
            .length(14);

        const receiptBookedFilter = new QueryFilter()
            .equal('status', [TRANSACTION_STATUS.BOOKED])
            .equal('type', [REFERENCE_TYPE.RECEIPT, REFERENCE_TYPE.CARD_TRANSACTION])
            .equal('orderBy', 'CREATED_AT')
            .equal('resolved', true)
            .offset(0)
            .length(3);

        const receiptPendingFilter = new QueryFilter()
            .equal('status', [TRANSACTION_STATUS.PENDING, TRANSACTION_STATUS.IN_REVIEW])
            .equal('type', [REFERENCE_TYPE.RECEIPT, REFERENCE_TYPE.CARD_TRANSACTION])
            .equal('subType', [TRANSACTION_SUB_TYPE.CARD_TRANSACTION])
            .equal('orderBy', 'CREATED_AT')
            .offset(0)
            .length(14);

        const receiptRejectedFilter = new QueryFilter()
            .equal('status', [TRANSACTION_STATUS.REJECTED])
            .equal('type', [REFERENCE_TYPE.RECEIPT, REFERENCE_TYPE.CARD_TRANSACTION])
            .equal('orderBy', 'CREATED_AT')
            .offset(0)
            .length(14);

        this._cardTransactions.flush();
        this._receiptsBooked.flush();
        this._receiptsPending.flush();
        this._receiptsRejected.flush();

        forkJoin([
                this._cardTransactions.next(cardTransactionFilter),
                this._receiptsBooked.next(receiptBookedFilter),
                this._receiptsPending.next(receiptPendingFilter),
                this._receiptsRejected.next(receiptRejectedFilter)
            ]
        )
            .subscribe((responses: QueryResponse<Transaction>[]) => {
                const hasResults = responses.reduce((acc, resp) => acc + resp.total, 0);
                this._state = hasResults ? LIST_STATUS.DONE : LIST_STATUS.NO_RESULT;
            });
    }

    private _resolveTransactionAsDebt(transaction: Transaction) {
        this._cardTransactionServiceClient.resolveAsDebt(transaction.reference.id)
            .subscribe(() => {
                this._eventSource.emit(new CardTransactionUpdatedEvent());
            }, (err) => {
                const msg = $localize`:RedNotification|Failed to mark card transaction as debt.:Failed to mark as debt`;
                this._notification.errorWithCode(err, msg);
            });
    }
}
