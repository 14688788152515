import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Actions, ofActionDispatched, Store} from '@ngxs/store';
import {RedNotification} from '@red/components';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {AuthState} from '../../shared/state/auth/auth.state';
import {NationalIdentifierValidator} from '@red/validators';
import {COUNTRY} from '@red/data';
import {AuthLoginStatus, AuthSignUp} from '../../shared/state/auth/auth.actions';
import {AUTH_LOGIN_STATUS} from '../../shared/state/auth/auth.model';
import {OnboardingStart} from '../../shared/state/onboarding/onboarding.actions';
import {OnboardingState} from '../../shared/state/onboarding/onboarding.state';
import {Router} from '@angular/router';
import {BankIdManager} from '../../managers/bankid/bank-id-manager';
import {RemoteErrorException} from '../../common/error/remote-error-exception';
import {Subscription} from 'rxjs';

export enum ONBOARDING_SIGN_UP_STATE {
    IDLE = 'IDLE',
    WAITING_FOR_BANKID = 'WAITING_FOR_BANKID',
    USER_SIGN = 'USER_SIGN',
    ONBOARDING = 'ONBOARDING'
}

@Component({
    selector: 'app-onboarding-sign-up-view',
    styleUrls: ['./onboarding-sign-up.sass'],
    templateUrl: 'onboarding-sign-up.tpl.html'
})
export class OnboardingSignUpViewComponent implements OnInit, OnDestroy {
    @HostBinding('class.scroll-view') cssClass = true;

    get state(): ONBOARDING_SIGN_UP_STATE { return this._state; }
    get onboardingForm(): FormGroup { return this._onboardingForm; }
    get bankIdForm(): FormGroup { return this._bankIdForm; }
    get bankIdUrl(): SafeUrl { return this._bankIdUrl; }
    get canOpenOnSameDevice(): boolean { return BankIdManager.canOpenOnSameDevice(); }
    get supportPhoneNumber(): string { return this._supportPhoneNumber; }
    get supportPhoneNumberDigits(): string { return this._supportPhoneNumberDigits; }

    private _bankIdForm: FormGroup;
    private _onboardingForm: FormGroup;
    private _formBuilder: FormBuilder;
    private _state = ONBOARDING_SIGN_UP_STATE.IDLE;
    private _store: Store;
    private _actions: Actions;
    private _notification: RedNotification;
    private _bankIdUrl: SafeUrl;
    private _sanitizer: DomSanitizer;
    private _router: Router;
    private _supportPhoneNumber = '08-502 540 86';
    private _supportPhoneNumberDigits = this._supportPhoneNumber.replace(/\D+/g, '');
    private _authLoginStatusSubscription: Subscription;
    private _onboardingCheckStatusSubscription: Subscription;

    constructor (
        formBuilder: FormBuilder,
        store: Store,
        actions: Actions,
        notification: RedNotification,
        sanitizer: DomSanitizer,
        router: Router
    ) {
        this._formBuilder = formBuilder;
        this._store = store;
        this._actions = actions;
        this._notification = notification;
        this._sanitizer = sanitizer;
        this._router = router;
    }

    ngOnInit(): void {
        this._createForms();

        this._authLoginStatusSubscription = this._actions
            .pipe(ofActionDispatched(AuthLoginStatus))
            .subscribe((action: AuthLoginStatus) => {
                this._setLoginState(action);
                if (this._store.selectSnapshot(OnboardingState.hasOnboarding) && this._store.selectSnapshot(AuthState.isAuthenticated)) {
                    this._router.navigate(['/onboarding']);
                }
            });

        this._onboardingCheckStatusSubscription = this._store.select(OnboardingState.hasOnboarding)
            .subscribe((hasOnboarding) => {
                if (hasOnboarding && this._store.selectSnapshot(AuthState.isAuthenticated)) {
                    this._router.navigate(['/onboarding']);
                }
            });
    }

    ngOnDestroy() {
        this._authLoginStatusSubscription.unsubscribe();
        this._onboardingCheckStatusSubscription.unsubscribe();
    }

    login($event: Event) {
        $event.stopPropagation();
        this._state = ONBOARDING_SIGN_UP_STATE.WAITING_FOR_BANKID;

        const {identifier} = this._bankIdForm.value;

        this._store.dispatch(new AuthSignUp({
            identifier: identifier
        }));
    }

    startOnboarding($event: Event) {
        $event.stopPropagation();

        const {email, phone} = this._onboardingForm.value;
        this._store.dispatch(new OnboardingStart({email, phone}))
            .subscribe(
                () => {},
                (err) => {
                    const msg = $localize`:Onboarding|:Failed to start onboarding`;
                    this._notification.errorWithCode(err, msg);
                }
            );
    }

    private _createForms() {
        this._bankIdForm = this._formBuilder.group({
            identifier: ['', [Validators.required, this._identifierValidator()]]
        });

        this._onboardingForm = this._formBuilder.group({
            email: ['', Validators.email],
            phone: ['', Validators.required],
        });
    }

    private _identifierValidator() {
        return (ctrl: AbstractControl) => {
            return NationalIdentifierValidator.validate(ctrl.value, COUNTRY.SWEDEN)
                ? null
                : { 'nationalIdentifierError': true };
        };
    }

    private _setBankIdUrl(autostartToken: string) {
        this._bankIdUrl = this._sanitizer.bypassSecurityTrustUrl(`bankid:///?autostarttoken=${autostartToken}&redirect=null`);
    }

    private _displayErrorRedNotification(error: RemoteErrorException) {
        const msg = $localize`:Login|Login error (fallback):Unknown error!`;
        this._notification.errorWithCode(error, msg);

        this._state = ONBOARDING_SIGN_UP_STATE.IDLE;
    }

    private _setLoginState(action: AuthLoginStatus) {
        switch (action.status) {
            case AUTH_LOGIN_STATUS.STARTED:
                const autostartToken = this._store.selectSnapshot(AuthState.autostartToken);

                this._setBankIdUrl(autostartToken);
                break;
            case AUTH_LOGIN_STATUS.COLLECTING:
                this._state = ONBOARDING_SIGN_UP_STATE.WAITING_FOR_BANKID;
                if (action.bankdIdStatus === 'USER_SIGN') {
                    this._state = ONBOARDING_SIGN_UP_STATE.USER_SIGN;
                }
                break;
            case AUTH_LOGIN_STATUS.ERROR:
                this._displayErrorRedNotification(action.error);
                break;
            case AUTH_LOGIN_STATUS.SIGN_UP_COMPLETE:
                this._state = ONBOARDING_SIGN_UP_STATE.ONBOARDING;
                break;
            default:
                this._state = ONBOARDING_SIGN_UP_STATE.IDLE;
        }
    }
}
