import {Routes} from '@angular/router';
import {DashboardViewComponent} from './dashboard';
import {ValidSessionGuard} from '../../security/route/session';
import {ConsentGuard} from '../../security/route/consent';
import {CompanyGuard} from '../../security/route/company-guard';
import {FinancialStatementProjectionRouteResolve} from '../../shared/route/financial-statement-projection-route-resolve';

export const DASHBOARD_ROUTES: Routes = [
    {
        'path': 'dashboard',
        'component': DashboardViewComponent,
        'canActivate': [ValidSessionGuard, ConsentGuard, CompanyGuard],
        resolve: {projection: FinancialStatementProjectionRouteResolve}
    }
];
