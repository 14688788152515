<div [ngSwitch]="state">
    <red-skeleton-loader *ngSwitchCase="stateLoading" [rows]="2"></red-skeleton-loader>
    <div *ngSwitchCase="stateNoResult" class="no-result">
        <app-blank-slate-2020
            [typeClass]="'invoice'"
        >
            <header i18n="Invoice|blank slate|header">Invoices</header>
            <p i18n="Invoice|blank slate|info text">No invoices has been sent.</p>
        </app-blank-slate-2020>
    </div>

    <div *ngSwitchCase="stateDone">
        <red-list *ngIf="invoicesDraft.hasResults && notReadOnly">
            <span redListHeader i18n="List header for draft invoices">Drafts</span>
            <mat-list-item *ngFor="let transaction of invoicesDraft.items | async" [routerLink]="['/invoice', transaction.reference.id, 'edit']" redListLink>
                <red-list-icon mat-list-avatar [item]="transaction"></red-list-icon>
                <h4 mat-line>{{transaction.description}}</h4>
                <time mat-line>{{transaction.modifiedAt | moment }}</time>
                <app-display-amount-text [amount]="transaction.total" type="INVOICE"></app-display-amount-text>
            </mat-list-item>
        </red-list>
        <button *ngIf="!invoicesDraft.complete" mat-button (click)="loadMore(invoicesDraft)" i18n="Load more invoices">Load more...</button>

        <red-list *ngIf="invoicesPartiallyPaid.hasResults">
            <span redListHeader i18n="List header for partially paid invoices">Partially Paid</span>
            <mat-list-item *ngFor="let transaction of invoicesPartiallyPaid.items | async" [routerLink]="['/invoice', transaction.reference.id, 'edit']" redListLink>
                <red-list-icon mat-list-avatar [item]="transaction"></red-list-icon>
                <h4 mat-line>{{transaction.description}}</h4>
                <div redListLine mat-line>
                    <time [ngClass]="{'expired-date': isDueDatePass(transaction)}">{{transaction.attributes['expires'] | moment }}</time>
                    <mat-icon *ngIf="isSentByPost(transaction)" svgIcon="list-invoice-envelope"></mat-icon>
                </div>
                <app-display-amount-text [amount]="transaction.total" [cancelled]="isCredited(transaction)" type="INVOICE">
                    <span class="forex-amount" *ngIf="transaction.forexAmount" [innerHTML]="transaction.forexAmount | amount"></span>
                </app-display-amount-text>
            </mat-list-item>
        </red-list>
        <button *ngIf="!invoicesPartiallyPaid.complete" mat-button (click)="loadMore(invoicesPartiallyPaid)" i18n="Load more invoices">Load more...
        </button>

        <red-list *ngIf="invoicesPending.hasResults">
            <span redListHeader i18n="List header for to be paid invoices">Waiting for payment</span>
            <mat-list-item *ngFor="let transaction of invoicesPending.items | async" [routerLink]="['/invoice', transaction.reference.id, 'edit']" redListLink>
                <red-list-icon mat-list-avatar [item]="transaction"></red-list-icon>
                <h4 mat-line>{{transaction.description}}</h4>
                <div redListLine mat-line>
                    <time [ngClass]="{'expired-date': isDueDatePass(transaction)}">{{transaction.attributes['expires'] | moment }}</time>
                    <mat-icon *ngIf="isSentByPost(transaction)" svgIcon="list-invoice-envelope"></mat-icon>
                </div>
                <app-display-amount-text [amount]="transaction.total" [cancelled]="isCredited(transaction)" type="INVOICE">
                    <span class="forex-amount" *ngIf="transaction.forexAmount" [innerHTML]="transaction.forexAmount | amount"></span>
                </app-display-amount-text>
            </mat-list-item>
        </red-list>
        <button *ngIf="!invoicesPending.complete" mat-button (click)="loadMore(invoicesPending)" i18n="Load more invoices">Load more...</button>


        <red-list *ngIf="invoicesPaid.hasResults">
            <span redListHeader i18n="List header for paid invoices">Paid</span>
            <a redListHeaderLink [routerLink]="['/history']" [queryParams]="{ type: 'invoice' }" i18n="see all button link to history">SEE ALL</a>
            <mat-list-item *ngFor="let transaction of invoicesPaid.items | async" [routerLink]="['/invoice', transaction.reference.id, 'edit']" redListLink>
                <red-list-icon mat-list-avatar [item]="transaction"></red-list-icon>
                <h4 mat-line>{{transaction.description}}</h4>
                <div redListLine mat-line>
                    <time>{{transaction.resolvedAt | moment }}</time>
                    <mat-icon *ngIf="isSentByPost(transaction)" svgIcon="list-invoice-envelope"></mat-icon>
                </div>
                <app-display-amount-text [amount]="transaction.total" [cancelled]="isCredited(transaction)" type="INVOICE">
                    <span class="forex-amount" *ngIf="transaction.forexAmount" [innerHTML]="transaction.forexAmount | amount"></span>
                </app-display-amount-text>
            </mat-list-item>
        </red-list>
    </div>
</div>
<router-outlet></router-outlet>
