import {QueryFilter, WebServiceClient} from '@red/browser';
import {HttpFetch} from '@red/common';
import {Shareholder, ShareholderListResponse, Stock, UpdateShareholdersRequest} from '@red/data';
import {Observable} from 'rxjs';
import {AppConfig} from '../../config/appConfig';
import {QueryParam} from '../../common/query-param';

export class ShareholderServiceClient extends WebServiceClient {
    static create(transport: HttpFetch, config: AppConfig) {
        return new ShareholderServiceClient(transport, config);
    }

    getShareholders(companyId: string): Observable<ShareholderListResponse> {
        return this._get<ShareholderListResponse>(`${this._getBaseUrl()}/api/shareholder/company/${companyId}`, {}, ShareholderListResponse);
    }

    getStock(companyId: string, filter: QueryFilter = new QueryFilter()): Observable<Stock> {
        const url = QueryParam.queryUrl(`${this._getBaseUrl()}/api/stock/company/${companyId}`, filter);

        return this._get<Stock>(url, {}, Stock);
    }

    addShareholder(shareholder: Shareholder): Observable<Shareholder> {
        return this._post<Shareholder>(`${this._getBaseUrl()}/api/shareholder`, shareholder, {}, Shareholder);
    }

    updateShareholders(updateShareholdersRequest: UpdateShareholdersRequest): Observable<ShareholderListResponse> {
        return this._put<ShareholderListResponse>(`${this._getBaseUrl()}/api/shareholders`, updateShareholdersRequest, {}, ShareholderListResponse);
    }

    addStock(stock: Stock): Observable<Stock> {
        return this._post<Stock>(`${this._getBaseUrl()}/api/stock`, stock, {}, Stock);
    }
}
