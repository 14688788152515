import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import {RedDialogModule, RedDisplayModule, RedFormModule, RedInfoBoxModule, RedLoaderModule} from '@red/components';
import {UserAdminFormComponent} from './user-admin-form/user-admin-form';
import {CreateUserComponent} from './create-user/create-user';
import {EditUserComponent} from './edit-user/edit-user';

@NgModule({
    declarations: [
        CreateUserComponent,
        EditUserComponent,
        UserAdminFormComponent
    ],
    exports: [
        CreateUserComponent,
        EditUserComponent,
        UserAdminFormComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDialogModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        RedDialogModule,
        RedInfoBoxModule,
        RedFormModule,
        RedLoaderModule,
        RedDisplayModule
    ]
})
export class UserAdminModule {}
