import {EventReader, OnEvent} from '../reader';
import {BaseEvent} from '../event';
import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {EventSource} from '../source';

export class InvoiceCreatedEvent extends BaseEvent {}
export class InvoiceUpdatedEvent extends BaseEvent {}

@Injectable()
export class InvoiceEventReader extends EventReader {
    private _onCreatedStream: Subject<InvoiceCreatedEvent>;
    private _onUpdateStream: Subject<InvoiceUpdatedEvent>;

    constructor (
        source: EventSource
    ) {
        super(source);

        this._onCreatedStream = new Subject<InvoiceCreatedEvent>();
        this._onUpdateStream = new Subject<InvoiceUpdatedEvent>();
    }

    onCreated(fn: (evt: InvoiceCreatedEvent) => void) {
        this._onCreatedStream.subscribe(fn);
    }

    onUpdate(fn: (evt: InvoiceUpdatedEvent) => void) {
        this._onUpdateStream.subscribe(fn);
    }

    @OnEvent(InvoiceCreatedEvent)
    private _handleInvoiceCreatedEvent(evt: InvoiceCreatedEvent) {
        this._onCreatedStream.next(evt);
    }

    @OnEvent(InvoiceUpdatedEvent)
    private _handleInvoiceUpdatedEvent(evt: InvoiceUpdatedEvent) {
        this._onUpdateStream.next(evt);
    }
}
