import {Routes} from '@angular/router';
import {ValidSessionGuard} from '../../security/route/session';
import {InvoiceViewComponent} from './invoice';
import {InvoiceListViewComponent} from './list/list';
import {InvoiceCreateViewComponent} from './create/create';
import {InvoiceRouteResolve} from './invoice.route.resolve';
import {InvoiceEditViewComponent} from './edit/edit';
import {ConsentGuard} from '../../security/route/consent';

export const INVOICE_ROUTES: Routes = [
    {
        path: 'invoice',
        component: InvoiceViewComponent,
        canActivate: [ValidSessionGuard, ConsentGuard],
        children: [
            {
                'path': '',
                'component': InvoiceListViewComponent,
                'children': [
                    { 'path': 'create', 'component': InvoiceCreateViewComponent },
                    { 'path': ':id/edit', 'component': InvoiceEditViewComponent, resolve: {'invoice': InvoiceRouteResolve} },
                ]
            }
        ]
    }
];
