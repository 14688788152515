import {AbstractControl, ValidationErrors} from '@angular/forms';

export class SvLlcNameValidator {
    static nameFormat(ctrl: AbstractControl): ValidationErrors | null {
        const trimmedVal = (ctrl.value || '').trim();
        const validNameRe = /(^|\s)(AB|[aA]ktiebolag(et)?|AKTIEBOLAG(ET)?)(\s|$)/;
        const aktiebolagetIsLast = /aktiebolaget$/i;
        const words = trimmedVal.split(' ');

        return (validNameRe).test(trimmedVal)
            && !(aktiebolagetIsLast).test(trimmedVal)
            && words.length >= 2
            ? null
            : {
                nameFormatError: true
            };
    }

    static duplicateNames(names: string[]): number[] {
        const noneEmptyValues = names.slice().filter(val => val);
        const duplicatesObject: {
            [key: string]: number[]
        } = {};

        noneEmptyValues.forEach((val: string, index: number) => {
            if (!duplicatesObject[val]) {
                duplicatesObject[val] = [];
            }

            duplicatesObject[val].push(index);
        });

        let duplicatesArrayIndex: number[] = [];
        Object.keys(duplicatesObject).forEach((key: string) => {
            if (duplicatesObject[key].length > 1) {
                duplicatesArrayIndex = duplicatesArrayIndex.concat(duplicatesObject[key]);
            }
        });
        duplicatesArrayIndex.sort((a, b) => {
            return a > b ? 1 : -1;
        });

        return duplicatesArrayIndex;
    }
}
