<mat-expansion-panel class="mat-expansion-panel {{statusClassName}}" [ngClass]="{'disabled': (enabled$ | async) === false}" [disabled]="(enabled$ | async) === false">
    <mat-expansion-panel-header>
        <mat-panel-title>
            Behandling hos Bolagsverket
        </mat-panel-title>
        <mat-panel-description></mat-panel-description>
    </mat-expansion-panel-header>
    <ng-container [ngSwitch]="application.status">
        <div class="help-text" *ngSwitchDefault>
            <p>
                Nu färdigställer vi blanketterna till Bolagsverket.
                Du får ett mail från oss så snart de är klara samt mer information om nästa steg.
            </p>
        </div>
        <div class="help-text" *ngSwitchCase="'SENT'">
            <p>Skriv ut de tre blanketterna som vi har skickat över till dig per mail, granska, signera (med blå bläck) och skicka till:</p>

            <p>
                Bolagsverket<br />
                851 81 Sundsvall
            </p>

            <p>
                När du har fått en bekräftelse från Bolagsverket att ärendet är mottaget vidarebefordrar du denna till oss,
                för att komma vidare med din ansökan. Vi kommer då att skicka in revisorsintyget till Bolagsverket.
            </p>

            <p>
                För att Bolagsverket ska behandla ditt ärende behöver du också betala in avgiften på 2 200 kr till dem.
                Betalningsuppgifterna finner du i mailet från Bolagsverket.
            </p>
        </div>
        <div class="help-text" *ngSwitchCase="'DONE'">
            <p>Vi har skickat in ditt revisorsintyg och inväntar nu att Bolagsverket ska godkänna din ansökan.</p>

            <ul>
                <li>
                    <p>
                        När du får besked om att ditt aktiebolag är registrerat är det dags för dig att
                        ansöka om F-skatt på verksamt.se och anmäla verklig huvudman.
                    </p>
                    <p>
                        Medan du väntar på att Skatteverket ska godkänna din ansökan kan du starta upp ditt Bokio-konto och aktivera ditt företagskonto.
                        Ibland kan det ta ett par dagar innan organisationsnumret blivit registrerat,
                        då behöver du invänta det innan du kan aktivera företagskontot.
                    </p>

                    <p>
                        <a target="_blank" href="https://www.bokio.se/hjalp/komma-igang/skapa-ett-konto/skapa-ditt-bokio-konto/">Läs om hur du skapar ditt Bokio konto</a>
                    </p>
                    <p>
                        <a target="_blank" href="https://www.bokio.se/hjalp/bokio-foretagskonto-bank/bokio-foretagskonto/aktivera-bokio-foretagskonto">Läs om hur du aktiverar ditt Bokio företagskonto</a>
                    </p>
                </li>
                <li>
                    När du har fått ditt bankgiro i Bokio mailar du det tillsammans med ditt företagsnamn och organisationsnummer
                    till oss så för vi över aktiekapitalet till ditt nya företagskonto.
                </li>
            </ul>

            <p>
                Det är viktigt att du inte börja fakturera innan din F-skatt ansökan är klar,
                det går dock bra att börja skriva på avtal för företaget och göra inköp.
            </p>
        </div>
    </ng-container>
</mat-expansion-panel>
