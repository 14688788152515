import {COMPANY_TYPE} from '@red/data';

export enum AUTH_LOGIN_STATUS {
    STARTED = 'STARTED',
    COLLECTING = 'COLLECTING',
    COMPLETE = 'COMPLETE',
    SIGN_UP_COMPLETE = 'SIGN_UP_COMPLETE',
    ONBOARDING = 'ONBOARDING',
    ERROR = 'ERROR'
}

export interface AuthStateModel {
    userId: string | null;
    userName: string | null;
    identifier: string | null;
    companyId: string | null;
    companyName: string | null;
    companyType: COMPANY_TYPE | null;
    autostartToken: string | null;
    sessionToken: string | null;
    consentsToSign: boolean | null;
    rememberMe: boolean;
}
