<div class="onboarding-select-track">
    <div class="onboarding-track">
        <div class="bokio-logo"></div>
        <h3 i18n="Onboarding|Select track|Onboarding new company">Onboarding new company</h3>

        <section class="onboarding-select-track-info">
            <p>Starta aktiebolag med Bokio. Vi hjälper dig registrera ditt eget företag.</p>
            <p>Börja med att berätta vad ditt företag ska heta och vad det ska ha för verksamhet.</p>
            <p>
                <a
                    href="https://www.bokio.se/starta-eget-foretag/aktiebolag/"
                    target="_blank"
                >Läs mer om att starta nytt företag med Bokio här</a>
            </p>
            <p><red-copy-value [text]="'startaforetag@bokio.se'"></red-copy-value></p>
        </section>

        <div class="onboarding-select-track-btns">
            <button mat-flat-button color="primary" (click)="selectNewLimitedCompanyTrack()" i18n="Onboarding|Select new llc track">Select new llc track</button>
        </div>
    </div>
</div>
