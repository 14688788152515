import {throwError as observableThrowError, Observable} from 'rxjs';
import {SessionTokenResponse, AuthStartResponse, AuthCredentials} from '@red/data';
import {AuthProvider, AuthResponse, AUTHORIZED_STATE} from './provider';
import {catchError, map} from 'rxjs/operators';
import {Transport, TransportOptions} from '../../transport/base';
import { UrlBuilder } from '../../transport/urlBuilder';

export enum BANKID_RETRY_STRATEGY {
    BACKOFF,
    SEQUENTIAL
}

export interface SetActiveCompanyRequest {
    companyId: string;
}

export class BankIdAuthResponseOld extends AuthResponse {
    result: AuthStartResponse;
}

export class BankIdSessionResponse extends AuthResponse {
    result: SessionTokenResponse;
}

export class BankIdAuthProvider extends AuthProvider {
    private _urlBuilder: UrlBuilder;
    private _transport: Transport<any>;

    constructor(transport: Transport<any>, config: any) {
        super();
        this._urlBuilder = new UrlBuilder(config['endpoints'], config['baseUrl']);
        this._transport = transport;
    }

    authenticate(credentials: AuthCredentials): Observable<BankIdAuthResponseOld> {
        const url = this._urlBuilder.compileUrl('authenticate');
        return this._transport.post(url, JSON.stringify(credentials))
            .pipe(
                map((response: AuthStartResponse) => this.onAuthSuccess(response)),
                catchError<BankIdAuthResponseOld, Observable<never>>((reason) => observableThrowError(this.onAuthError(reason)))
            );
    }

    exchangeAuthReferenceForSession(authReference: string): Observable<BankIdSessionResponse> {
        const url = this._urlBuilder.compileUrl('sessionToken', {'authReference': authReference});
        return this._transport.get(url)
            .pipe(
                map((response: SessionTokenResponse) => this.onSessionSuccess(response)),
                catchError<BankIdSessionResponse, Observable<never>>((reason) => observableThrowError(this.onAuthError(reason)))
            );
    }

    getCurrentActiveCompany(options?: TransportOptions): Observable<string> {
        const url = this._urlBuilder.compileUrl('authenticate');

        if (options) {
            options.url = options.url || url;
        }

        return this._transport.get(url, options)
            .pipe(map((response) => response));
    }

    setActiveCompany(request: SetActiveCompanyRequest): Observable<BankIdSessionResponse> {
        const url = this._urlBuilder.compileUrl('authenticate');
        return this._transport.put(url, JSON.stringify(request))
            .pipe(
                map((response: SessionTokenResponse) => this.onSessionSuccess(response)),
                catchError<BankIdSessionResponse, Observable<never>>((reason) => observableThrowError(this.onAuthError(reason)))
            );
    }

    protected onAuthSuccess(result?: string|{}): BankIdAuthResponseOld {
        this.state = AUTHORIZED_STATE.AUTHORIZED;
        return new BankIdAuthResponseOld(this.state, result);
    }

    protected onSessionSuccess(result?: string|{}): BankIdSessionResponse {
        this.state = AUTHORIZED_STATE.AUTHORIZED;
        return new BankIdSessionResponse(this.state, result);
    }
}
