import {AbstractControl, FormBuilder, ValidationErrors, Validators} from '@angular/forms';
import {SniCode} from '@red/validators';
import {
    OnboardingApplicationDataExistingCompanyInfo,
    OnboardingApplicationDataLlcNewSkvForm,
    OnboardingApplicationDataSoleNewSkvForm
} from '@red/onboarding-service-api';
import {Injectable} from '@angular/core';

@Injectable()
export class SniHelpers {
    private _formBuilder: FormBuilder;

    constructor(
        formBuilder: FormBuilder
    ) {
        this._formBuilder = formBuilder;
    }

    emptySniCode(showRevenueControl = true) {
        const config = {
            businessOperationCode: ['46110', [Validators.required, this.sniValidator]],
            revenue: ['666', Validators.required]
        };

        if (!showRevenueControl) {
            delete config.revenue;
        }

        return this._formBuilder.group(config);
    }

    sniValidator(control: AbstractControl): ValidationErrors | null {
        return !SniCode.validate(control.value)
            ? {
                notValidSniCode: {
                    message: `'${control.value}' is not a valid SNI code`
                }
            }
            : null;
    }

    formatSniCode(businessOperationCode: string) {
        return businessOperationCode.replace(/\./g, '');
    }
}
