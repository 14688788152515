<div class="red-dialog-title" matDialogTitle>
    <button mat-button (click)="close()" tabindex="-1">
        <mat-icon svgIcon="ui-close-white"></mat-icon>
    </button>
    <span class="red-dialog-title-text" i18n="Create new dividends payout|Header for dialog">Dividends</span>
</div>
<div class="red-dialog-content" matDialogContent>
    <div *ngIf="annualReport">
        <app-dividends-calculation redListDialog
           [annualReport]="annualReport"
           [limit]="limit"
        ></app-dividends-calculation>
        <div class="section-header section-header-dialog" i18n="Section header|Pay">Pay</div>
        <app-dividends-form
            [remainingDividends]="annualReport.remainingDividends"
            (emitDividendsFormChange)="onDividendsFormChange($event)"
            (emitDividendsFormSubmit)="calculatePayouts($event)">
        </app-dividends-form>
        <mat-progress-bar *ngIf="isCalculating" mode="indeterminate"></mat-progress-bar>
        <red-list redListDialog *ngIf="showCalculatedPayouts">
            <span redListHeader i18n="List header|Payment per shareholder">Payout per shareholder</span>
            <ng-container *ngFor="let payment of payments.payments">
                <mat-list-item >
                    <mat-icon mat-list-avatar svgIcon="list-employee"></mat-icon>
                    <h4 mat-line>{{payment.shareholder.details.name}}</h4>
                    <time mat-line>{{payment.shareholder.numberOfShares}} <span i18n="shares">shares</span></time>
                    <span align="end" [innerHTML]="payment.amount | amount"></span>
                </mat-list-item>
            </ng-container>
        </red-list>
    </div>
</div>
<mat-dialog-actions class="red-dialog-actions">
    <button type="button" mat-flat-button tabindex="-1" (click)="onDividendsPay()" i18n="Dividends|Dialog action|Pay" [disabled]="!canPayDividends">Pay</button>
</mat-dialog-actions>
