<div class="red-dialog-title" matDialogTitle>
    <button mat-button (click)="close()" tabindex="-1">
        <mat-icon svgIcon="ui-close-white"></mat-icon>
    </button>
    <span class="red-dialog-title-text">Allmänna villkor för bokio</span>
</div>
<div class="red-alert-content" mat-dialog-content>
    <app-terms-dialog-content></app-terms-dialog-content>
</div>
<mat-dialog-actions class="red-alert-actions red-dialog-actions">
    <button mat-flat-button (click)="close()">Stäng</button>
</mat-dialog-actions>
