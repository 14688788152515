import {Component, HostBinding, OnInit} from '@angular/core';

@Component({
    selector: 'app-card-transaction-view',
    templateUrl: './card-transaction.tpl.html'
})

export class CardTransactionViewComponent {
   @HostBinding('class.scroll-view') cssClass = true;
}
