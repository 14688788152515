import {BaseEvent} from '../event';
import {EventSource} from '../source';
import {Subject} from 'rxjs';
import {EventReader, OnEvent} from '../reader';
import {Injectable} from '@angular/core';

export class BenefitCreatedEvent extends BaseEvent {}
export class BenefitUpdatedEvent extends BaseEvent {}

@Injectable()
export class BenefitEventReader extends EventReader {
    private _onCreatedStream: Subject<BenefitCreatedEvent>;
    private _onUpdatedStream: Subject<BenefitUpdatedEvent>;

    constructor (
        source: EventSource
    ) {
        super(source);

        this._onCreatedStream = new Subject<BenefitCreatedEvent>();
        this._onUpdatedStream = new Subject<BenefitUpdatedEvent>();
    }

    onCreated(fn: (evt: BenefitCreatedEvent) => void) {
        this._onCreatedStream.subscribe(fn);
    }

    onUpdate(fn: (evt: BenefitUpdatedEvent) => void) {
        this._onUpdatedStream.subscribe(fn);
    }

    @OnEvent(BenefitCreatedEvent)
    private _handleBenefitCreatedEvent(evt: BenefitCreatedEvent) {
        this._onCreatedStream.next(evt);
    }

    @OnEvent(BenefitUpdatedEvent)
    private _handleBenefitUpdatedEvent(evt: BenefitUpdatedEvent) {
        this._onUpdatedStream.next(evt);
    }
}
