<app-view-card>
    <red-top-toolbar>
        <span redTopToolbarTitle i18n="Income streams|Main title for Income streams view">Income streams</span>
        <button mat-flat-button color="primary" redTopToolbarAction redAssetUploadTrigger (fileSelect)="createNewRoyaltyStatement($event)">
            <mat-icon svgIcon="ui-add"></mat-icon>
            <span i18n="Button|create new income stream">New</span>
        </button>
    </red-top-toolbar>
    <app-view-card-infobar class="view-card-infobar">
        <app-red-flag-shut-down-banner></app-red-flag-shut-down-banner>
    </app-view-card-infobar>
    <router-outlet></router-outlet>
</app-view-card>
