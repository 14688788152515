import {Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Amount} from '@red/data';
import {
    DIALOG_RESULT
} from '@red/components';

@Component ({
    templateUrl: './markAsDebt.tpl.html'
})

export class CardTransactionMarkAsDebtConfirmComponent {
    get amount(): Amount { return this._amount; }

    private _dialogRef: MatDialogRef<CardTransactionMarkAsDebtConfirmComponent>;
    private _amount: Amount;

    constructor (
        dialogRef: MatDialogRef<CardTransactionMarkAsDebtConfirmComponent>,
        @Inject(MAT_DIALOG_DATA) data: any
    ) {
        this._dialogRef = dialogRef;
        this._amount = data.amount;
    }

    cancel() {
        this._dialogRef.close(DIALOG_RESULT.CANCEL);
    }

    confirm() {
        this._dialogRef.close(DIALOG_RESULT.OK);
    }
}
