import {Component, HostBinding, HostListener, ViewEncapsulation} from '@angular/core';

export enum SHOW_TEXT_TYPE {
    LONG = 'LONG',
    SHORT = 'SHORT'
}

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'app-read-more-less',
    styleUrls: ['./read-more-less.sass'],
    templateUrl: 'read-more-less.tpl.html'
})

export class ReadMoreLessComponent {
    @HostBinding('class') cssClass = 'read-more-less show-short';

    get showShortText() { return this._showText === SHOW_TEXT_TYPE.SHORT; }
    get showLongText() { return this._showText === SHOW_TEXT_TYPE.LONG; }

    private _showText = SHOW_TEXT_TYPE.SHORT;

    @HostListener('click') showTextType () {
        this.cssClass = this._showText === SHOW_TEXT_TYPE.LONG
            ? 'read-more-less show-long'
            : 'read-more-less show-short';
    }

    readMore() {
        this._showText = SHOW_TEXT_TYPE.LONG;
    }

    readLess() {
        this._showText = SHOW_TEXT_TYPE.SHORT;
    }
}
