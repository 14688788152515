import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Store} from '@ngxs/store';
import {from, Observable, of} from 'rxjs';
import {AuthState} from '../../shared/state/auth/auth.state';

@Injectable()
export class CompanyGuard implements CanActivate {
    private _router: Router;
    private _store: Store;

    constructor (
        router: Router,
        store: Store
    ) {
        this._router = router;
        this._store = store;
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        if (this._store.selectSnapshot(AuthState.companyId)) {
            return of(true);
        } else {
            return from(this._router.navigate(['/onboarding']));
        }
    }
}
