import {ONBOARDING_APPLICATION_BOARD_MEMBER_ROLE, OnboardingApplicationBoardMember} from '@red/onboarding-service-api';
import {NationalIdentifierValidator} from '@red/validators';
import {COUNTRY} from '@red/data';
import * as moment from 'moment';

export enum BOARD_VALIDATION_ERROR_TYPE {
    MINIMUM_BOARD = `MINIMUM_BOARD`,
    MISSING_CHAIRMAN = `MISSING_CHAIRMAN`,
    SOME_MEMEBERS_UNDER_AGE = `SOME_MEMEBERS_UNDER_AGE`,
    THERE_CAN_BE_ONLY_ONE = `THERE_CAN_BE_ONLY_ONE`,
    CHAIRMAN_MUST_BE_MEMBER = `CHAIRMAN_MUST_BE_MEMBER`,
    MORE_THAN_ONE_CHAIRMAN = `MORE_THAN_ONE_CHAIRMAN`,
    MORE_THAN_ONE_CEO = `MORE_THAN_ONE_CEO`,
}

export class BoardValidationError {
    type: BOARD_VALIDATION_ERROR_TYPE;
    message: string;

    constructor(err: BoardValidationError) {
        this.type = err.type;
        this.message = err.message;
    }
}

export class BoardValidation {
    isValid: boolean;
    errorType?: BOARD_VALIDATION_ERROR_TYPE;
}

interface BoardSummary {
    board: OnboardingApplicationBoardMember[];
    boardMembers: OnboardingApplicationBoardMember[];
    alternateMembers: OnboardingApplicationBoardMember[];
    boardWithoutExternalCeo: OnboardingApplicationBoardMember[];
    chairman: OnboardingApplicationBoardMember[];
    ceo: OnboardingApplicationBoardMember[];
}

const validateBoardUtils = {
    onlyTruthyBoardMembers(board: OnboardingApplicationBoardMember[]): OnboardingApplicationBoardMember[] {
        return board.slice().filter(member => {
            return member;
        });
    },
    getBoardSummary(board = [] as OnboardingApplicationBoardMember[]): BoardSummary {
        const validBoardMembers = validateBoardUtils.onlyTruthyBoardMembers(board);

        return {
            board: validBoardMembers.slice(),
            boardMembers: validBoardMembers.slice().filter(member => member.role === ONBOARDING_APPLICATION_BOARD_MEMBER_ROLE.BOARD_MEMBER),
            alternateMembers: validBoardMembers.slice().filter(member => member.role === ONBOARDING_APPLICATION_BOARD_MEMBER_ROLE.ALTERNATE_MEMBER),
            boardWithoutExternalCeo: validBoardMembers.slice().filter(member => member.role !== ONBOARDING_APPLICATION_BOARD_MEMBER_ROLE.CEO),
            chairman: validBoardMembers.slice().filter(member => member.isChairman),
            ceo: validBoardMembers.slice().filter(member => member.isCeo || member.role === ONBOARDING_APPLICATION_BOARD_MEMBER_ROLE.CEO),
        }
    },
    setErrorType(error, errorType): BoardValidation {
        if (errorType) {
            error.errorType = errorType;
        }

        return error;
    },
    getBoardValidation(errorType: BOARD_VALIDATION_ERROR_TYPE): BoardValidation {
        const error = {
            isValid: !errorType
        } as BoardValidation;

        return validateBoardUtils.setErrorType(error, errorType);
    },
    getDateOfBirthFromSamordingsmummer(birthdate: string): string {
        const date = parseInt(birthdate.replace(/^\d{6}/, ''), 10) - 60;
        let twoDigitDate = (`00${date}`).slice(-2);

        return birthdate.replace(/(^\d{6})(\d{2})/, '$1' + twoDigitDate);
    },
    getDateOfBirth(birthdate: string): string {
        const isSamordningsNr = parseInt(birthdate.replace(/^\d{6}/, ''), 10) > 60;

        return isSamordningsNr
            ? validateBoardUtils.getDateOfBirthFromSamordingsmummer(birthdate)
            : birthdate;
    },
    isUnder18(identifier: string): boolean {
        const birthdate = NationalIdentifierValidator.format(identifier, COUNTRY.SWEDEN).replace(/\d{4}$/, '');
        const birthMoment = moment(validateBoardUtils.getDateOfBirth(birthdate));
        const eighteen = moment().subtract(18, 'years');

        return birthMoment.isAfter(eighteen);
    },
    chairmanIsNotMember(boardMembers: OnboardingApplicationBoardMember[]): boolean {
        return boardMembers.slice().filter(member => member.isChairman).length === 0;
    }
}

const validations = {
    underAge(summary: BoardSummary): BOARD_VALIDATION_ERROR_TYPE {
        const membersUnder18 = summary.board.slice().filter(member => validateBoardUtils.isUnder18(member.identifier));

        return membersUnder18.length
            ? BOARD_VALIDATION_ERROR_TYPE.SOME_MEMEBERS_UNDER_AGE
            : null;
    },
    minimumBoard: (summary: BoardSummary): BOARD_VALIDATION_ERROR_TYPE => {
        return summary.boardWithoutExternalCeo.length < 2
        || summary.boardMembers.length === 0
        || summary.boardMembers.length < 3 && summary.alternateMembers.length === 0
            ? BOARD_VALIDATION_ERROR_TYPE.MINIMUM_BOARD
            : null;
    },
    chairman: (summary: BoardSummary): BOARD_VALIDATION_ERROR_TYPE => {
        return summary.boardWithoutExternalCeo.length > 2 && summary.boardMembers.length >= 2 && !summary.chairman.length
            ? BOARD_VALIDATION_ERROR_TYPE.MISSING_CHAIRMAN
            : null;
    },
    chairmanMustBeMember: (summary: BoardSummary): BOARD_VALIDATION_ERROR_TYPE => {
        const shouldHaveChairman = (summary.boardWithoutExternalCeo.length > 2 && summary.boardMembers.length >= 3);

        return shouldHaveChairman && validateBoardUtils.chairmanIsNotMember(summary.boardMembers)
            ? BOARD_VALIDATION_ERROR_TYPE.CHAIRMAN_MUST_BE_MEMBER
            : null;
    },
    moreThanOneChairman: (summary: BoardSummary): BOARD_VALIDATION_ERROR_TYPE => {
        return summary.chairman.length > 1
            ? BOARD_VALIDATION_ERROR_TYPE.MORE_THAN_ONE_CHAIRMAN
            : null;
    },
    moreThanOneCeo: (summary: BoardSummary): BOARD_VALIDATION_ERROR_TYPE => {
        return summary.ceo.length > 1
            ? BOARD_VALIDATION_ERROR_TYPE.MORE_THAN_ONE_CEO
            : null;
    }
}

const validate = (summary: BoardSummary): BOARD_VALIDATION_ERROR_TYPE => {
    const checks = Object.keys(validations);
    let errorType;

    while (checks.length && !errorType) {
        errorType = validations[checks.shift()](summary);
    }

    return errorType as BOARD_VALIDATION_ERROR_TYPE;
}

export class ValidateBoard {
    static validate(board: OnboardingApplicationBoardMember[]): BoardValidation {
        const summary = validateBoardUtils.getBoardSummary(board);
        const errorType = validate(summary);

        return validateBoardUtils.getBoardValidation(errorType);
    }
}
