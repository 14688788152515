<div class="red-dialog-title" matDialogTitle>
    <button mat-button (click)="close()" tabindex="-1">
        <mat-icon svgIcon="ui-close-white"></mat-icon>
    </button>
    <span class="red-dialog-title-text" i18n="Upload edit receipt|Header for edit receipt">Edit receipt</span>
    <red-context-menu *ngIf="showContextMenu">
        <button *ngIf="canDelete" mat-menu-item redContextMenuItem tabindex="-1" (click)="onMenuDelete()" i18n="Delete|Delete in context menu for invoice">Delete</button>
        <button mat-menu-item *ngIf="assetId">
            <red-asset-download-button [assetId]="assetId" [isContextMenuItem]="true">
                <span i18n="Download receipt">Download receipt</span>
            </red-asset-download-button>
        </button>
    </red-context-menu>
</div>
<div class="red-dialog-content" matDialogContent [ngSwitch]="uploading">
    <red-loader-overlay *ngSwitchCase="true"><red-atom-loader></red-atom-loader></red-loader-overlay>
    <div *ngSwitchDefault>
        <red-info-box *ngIf="isRejected" redInfoBoxDialog>
            <red-markdown-content [markdownPath]="receiptRejectMarkdown"></red-markdown-content>
        </red-info-box>
        <red-info-box *ngIf="!isRejected && isPending" redInfoBoxDialog>
            <span i18n="Info text|Receipt|What to write in comment">Receipt is processing. Write what have you bought if it is not obvious. For representation write names.</span>
        </red-info-box>
        <red-list redListDialog>
            <mat-list-item *ngIf="!isPending && !isRejected">
                <red-list-icon mat-list-avatar [item]="receipt"></red-list-icon>
                <h4 *ngIf="receipt.description" mat-line> {{receipt.description}}</h4>
                <h4 *ngIf="!receipt.description" mat-line i18n="Receipt|receipt default description in edit view">Receipt</h4>
                <time mat-line>{{(receipt.receiptDate || receipt.created)| moment }}</time>
                <app-display-amount-text *ngIf="receipt.totalAmount" type="RECEIPT" [amount]="receipt.totalAmount"></app-display-amount-text>
            </mat-list-item>
        </red-list>
        <form *ngIf="showCommentForm" [formGroup]="commentForm" red-dialog red-form>
            <fieldset>
                <div class="red-row dialog-view">
                    <mat-form-field>
                        <input
                            matInput
                            i18n-placeholder="Comment to RedFlag"
                            placeholder="Comment to RedFlag"
                            formControlName="message"
                            autocomplete="off"
                        />
                    </mat-form-field>
                </div>
            </fieldset>
        </form>
        <div class="asset-container" *ngIf="assetId">
            <red-asset-view [assetId]="assetId"></red-asset-view>
        </div>
    </div>
</div>
<mat-dialog-actions class="red-dialog-actions" [ngSwitch]="isRejected">
    <button type="button" mat-flat-button *ngSwitchCase="false" (click)="close()" i18n="Button|Dialog close">OK</button>
    <button type="button" mat-flat-button *ngSwitchCase="true" redAssetUploadTrigger (fileSelect)="uploadNewImage($event)" i18n="Button|Upload a new image for rejected receipt">Upload new image</button>
</mat-dialog-actions>
