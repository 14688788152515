import {Component, HostBinding, OnInit} from '@angular/core';
import {AppConfig} from '../../config/appConfig';
import {EndOfYearManager} from '../../managers/end-of-year/end-of-year.manager';
import {
    COMPANY_TYPE,
    CompanyYearEndStep,
    CompanyYearEndStepListResponse,
    YEAR_END_REPORT_STATUS,
    YEAR_END_REPORT_TYPE,
    YEAR_END_STEP,
    YearEndReport
} from '@red/data';
import * as moment from 'moment';
import {Store} from '@ngxs/store';
import {AuthState} from '../../shared/state/auth/auth.state';
import {RedFlagShutDownManager} from '../../managers/red-flag-shut-down/red-flag-shut-down.manager';

@Component({
    selector: 'app-report-view',
    styleUrls: ['./report.sass'],
    templateUrl: './report.tpl.html'
})
export class ReportViewComponent implements OnInit {
    @HostBinding('class.scroll-view') cssClass = true;

    get endOfYearStep(): CompanyYearEndStep { return this._endOfYearManager.endOfYearStep; }
    get endOfYearStart(): string { return this._endOfYearManager.endOfYearStart; }
    get isInEndOfYearProcess(): boolean { return this.endOfYearStep && this._endOfYearManager.isInEndOfYearProcess(); }
    get isStepBooksClosed(): boolean { return this.endOfYearStep.step === YEAR_END_STEP.BOOKS_CLOSED; }
    get hasReports(): boolean { return this._hasReports; }
    get year(): string { return this._endOfYearManager.currentFiscalYear(); }
    get yearEndFlows(): CompanyYearEndStep[] { return this._yearEndFlows; }
    get neArticleSoleLink(): string { return this._endOfYearManager.neArticleSoleLink; }
    get isSoleTrader(): boolean { return this._isSoleTrader; }
    get notReadOnlyState(): boolean { return this._notReadOnlyState; }

    private _appConfig: AppConfig;
    private _endOfYearManager: EndOfYearManager;
    private _hasReports = false;
    private _yearEndFlows: CompanyYearEndStep[];
    private _store: Store;
    private _companyType: COMPANY_TYPE;
    private _isSoleTrader: boolean;

    private _noReportStepsForSOLE: YEAR_END_STEP[] = [
        YEAR_END_STEP.WARMUP,
        YEAR_END_STEP.STARTED,
        YEAR_END_STEP.CUSTOMER_DONE,
        YEAR_END_STEP.WAITING_FOR_CUSTOMER_TO_SIGN,
        YEAR_END_STEP.CREATING_DOCUMENTS,
        YEAR_END_STEP.WAITING_FOR_FINAL_SIGNATURE
    ];
    private _noReportStepsForLLC: YEAR_END_STEP[] = [
        YEAR_END_STEP.WARMUP,
        YEAR_END_STEP.STARTED,
        YEAR_END_STEP.CUSTOMER_DONE,
        YEAR_END_STEP.WAITING_FOR_CUSTOMER_TO_SIGN,
        YEAR_END_STEP.CREATING_DOCUMENTS,
        YEAR_END_STEP.WAITING_FOR_FINAL_SIGNATURE,
        YEAR_END_STEP.BOOKS_CLOSED,
        YEAR_END_STEP.DIVIDENDS,
        YEAR_END_STEP.SHAREHOLDER_INFORMATION,
        YEAR_END_STEP.ANNUAL_REPORT_STORY,
        YEAR_END_STEP.BOARD_MEMBER_INFORMATION,
        YEAR_END_STEP.GENERATING_ANNUAL_REPORT
    ];
    private _redFlagShutDownManager: RedFlagShutDownManager;
    private _notReadOnlyState: boolean;

    constructor (
        appConfig: AppConfig,
        endOfYearManager: EndOfYearManager,
        store: Store,
        redFlagShutDownManager: RedFlagShutDownManager
    ) {
        this._appConfig = appConfig;
        this._endOfYearManager = endOfYearManager;
        this._store = store;
        this._redFlagShutDownManager = redFlagShutDownManager;
    }

    ngOnInit(): void {
        const today = this._redFlagShutDownManager.today;
        this._notReadOnlyState = this._redFlagShutDownManager.notReadOnlyState(today);

        const companyId = this._store.selectSnapshot(AuthState.companyId);
        this._companyType = this._store.selectSnapshot(AuthState.companyType);

        this._isSoleTrader = (this._companyType === COMPANY_TYPE.SOLE_TRADER);

        if (!companyId) {
            this._hasReports = false;
        } else {
            this._endOfYearManager.intYearEndForCompany(companyId);
            this._endOfYearManager.getEndOfYearFlows(companyId)
                .subscribe((resp: CompanyYearEndStepListResponse) => {
                    const visibleFlow = resp.data.filter(step => this.showReports(step));

                    this._yearEndFlows = this._sortSteps(visibleFlow);

                    this._hasReports = this._yearEndFlows.length > 0;
                });
        }
    }

    showReports(yearEndStep: CompanyYearEndStep) {
        return (this._companyType === COMPANY_TYPE.SOLE_TRADER)
            ? (this._noReportStepsForSOLE.indexOf(yearEndStep.step) === -1)
            : (this._noReportStepsForLLC.indexOf(yearEndStep.step) === -1);
    }

    getYearEndDate(yearEndStep: CompanyYearEndStep) {
        const startDate = moment(yearEndStep.fiscalStartDate).year();
        const endDate = moment(yearEndStep.fiscalEndDate).year();

        return (startDate === endDate)
            ? endDate
            : startDate + ' ' + endDate;
    }

    getReportName(report: YearEndReport) {
        switch (report.reportType) {
            case YEAR_END_REPORT_TYPE.PROFITLOSS:
                return $localize`:End of year|Report name:Profitloss`;
            case YEAR_END_REPORT_TYPE.BALANCE:
                return $localize`:End of year|Report name:Balance`;
            case YEAR_END_REPORT_TYPE.NE:
                return $localize`:End of year|Report name:Ne`;
            case YEAR_END_REPORT_TYPE.SIMPLIFIED_ANNUAL_ACCOUNTS:
                return $localize`:End of year|Report name:Simplified annual account`;
            case YEAR_END_REPORT_TYPE.ANNUAL_REPORT:
                return $localize`:End of year|Report name:Annual`;
            case YEAR_END_REPORT_TYPE.AGM_PROTOCOL:
                return $localize`:End of year|Report name:Annual meeting`;
            case YEAR_END_REPORT_TYPE.INK_2:
                return $localize`:End of year|Report name:Ink2`;
            default:
                return;
        }
    }

    reports(step: CompanyYearEndStep): YearEndReport[] {
        return step.metadata.reports.filter(report => {
            return this._displayedReportsType(report)
                && report.reportStatus === YEAR_END_REPORT_STATUS.FINAL;
        });
    }

    showTotalTaxPaid(yearEndStep: CompanyYearEndStep): boolean {
        return (yearEndStep.companyType === COMPANY_TYPE.SOLE_TRADER)
            && !!(yearEndStep.metadata && yearEndStep.metadata.totalTaxPaid);
    }

    private _sortSteps(steps: CompanyYearEndStep[]): CompanyYearEndStep[] {
        const sortedSlice = steps.slice().sort((a, b) => {
            const aStart = moment(a.fiscalStartDate);
            const bStart = moment(b.fiscalStartDate);

            return aStart.isBefore(bStart)
                ? -1
                : 1;
        });

        return sortedSlice;
    }

    private _displayedReportsType(report: YearEndReport): boolean {
        return report.reportType === YEAR_END_REPORT_TYPE.AGM_PROTOCOL ||
            report.reportType === YEAR_END_REPORT_TYPE.ANNUAL_REPORT ||
            report.reportType === YEAR_END_REPORT_TYPE.BALANCE ||
            report.reportType === YEAR_END_REPORT_TYPE.INK_2 ||
            report.reportType === YEAR_END_REPORT_TYPE.NE ||
            report.reportType === YEAR_END_REPORT_TYPE.SIMPLIFIED_ANNUAL_ACCOUNTS ||
            report.reportType === YEAR_END_REPORT_TYPE.PROFITLOSS;
    }
}
