<app-view-card>
    <red-top-toolbar>
        <span redTopToolbarTitle i18n="Dividends|Main title for dividends view">Dividends</span>
        <button
            *ngIf="notReadOnly"
            mat-flat-button
            color="primary"
            redTopToolbarAction
            routerLink="create"
            [disabled]="!canPayDividends"
        ><mat-icon svgIcon="ui-add"></mat-icon>
            <span i18n="Pay dividends">Pay dividends</span>
        </button>
    </red-top-toolbar>
    <app-view-card-infobar class="view-card-infobar">
        <app-red-flag-shut-down-banner></app-red-flag-shut-down-banner>
    </app-view-card-infobar>
    <router-outlet></router-outlet>
</app-view-card>
