import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Store} from '@ngxs/store';
import {
    FinancialStatementLoadCompanyOwed
} from '../state/financial-statement/financial-statement.actions';
import {AuthState} from '../state/auth/auth.state';

@Injectable()
export class FinancialStatementCompanyOwedRouteResolve implements Resolve<any> {
    private _store: Store;
    constructor (
        store: Store
    ) {
        this._store = store;
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const companyId = this._store.selectSnapshot(AuthState.companyId);
        return this._store.dispatch(new FinancialStatementLoadCompanyOwed({companyId}));
    }
}
