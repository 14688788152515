import {Component, Inject, Input, OnInit, ViewChild} from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {DIALOG_RESULT} from '@red/components';
import {AbstractControl, FormControl, Validators} from '@angular/forms';
import {Address, INVOICE_DELIVERY_METHOD} from '@red/data';
import {InvoiceManager} from '../../manager';
import * as moment from 'moment';

export interface InvoiceSendConfirmationResult {
    deliveryMethod: INVOICE_DELIVERY_METHOD;
    result: DIALOG_RESULT;
    date?: any;
}

@Component ({
    styleUrls: ['./send.sass'],
    templateUrl: './send.tpl.html'
})
export class InvoiceSendConfirmComponent implements OnInit {
    @ViewChild('creditInvoiceDatepicker') creditInvoiceDatepicker: MatDatepicker<Date>;

    get customerName(): string { return this._customerName; }
    get address(): Address { return this._address; }
    get amount(): string { return this._amount; }
    get invoiceDate(): FormControl { return this._invoiceDate; }
    get isCreditInvoice(): boolean { return this._isCreditInvoice; }
    get isInternationalInvoice(): boolean { return this._isInternationalInvoice; }
    get label(): string { return this._label; }
    get minDate(): string { return this._minDate; }
    get netAmount(): string { return this._netAmount; }
    get postalChoice(): FormControl { return this._postalChoice; }
    get title(): string { return this._title; }
    get todayDate(): any { return moment().toDate(); }
    get vat(): string { return this._vat; }

    private _dialogRef: MatDialogRef<InvoiceSendConfirmComponent>;
    private _amount: string;
    private _address: Address;
    private _customerName: string;
    private _invoiceManager: InvoiceManager;
    private _invoiceDate: FormControl;
    private _isCreditInvoice = false;
    private _isInternationalInvoice: boolean;
    private _label: string;
    private _minDate: string;
    private _netAmount: string;
    private _postalChoice: FormControl;
    private _title: string;
    private _vat: string;
    private _data: any;

    constructor (
        dialogRef: MatDialogRef<InvoiceSendConfirmComponent>,
        @Inject(MAT_DIALOG_DATA) data: any
    ) {
        this._dialogRef = dialogRef;
        this._amount = data.amount;
        this._customerName = data.customerName;
        this._address = data.address;
        this._invoiceManager = data.invoiceManager;
        this._isCreditInvoice = data.isCreditInvoice;
        this._minDate = data.minDate;
        this._netAmount = data.netAmount;
        this._title = data.title;
        this._vat = data.vat;
    }

    ngOnInit() {
        this._isInternationalInvoice = this._invoiceManager.isInternationalInvoice;
        this._invoiceDate = new FormControl(this.todayDate);
        this._invoiceDate.setValidators([Validators.required, (ctrl: AbstractControl) => this._dateValidator(ctrl)]);
        this._postalChoice = new FormControl(INVOICE_DELIVERY_METHOD.DOWNLOAD);

        this._postalChoice
            .valueChanges
            .subscribe(() => {
                this._setLabel();
            });

        this._setLabel();
    }

    cancel() {
        this._dialogRef.close({'result': DIALOG_RESULT.CANCEL});
    }

    confirm() {
        const date = this.isCreditInvoice ? moment(this._invoiceDate.value).format('YYYY-MM-DD') : null;
        this._dialogRef.close({'result': DIALOG_RESULT.OK, 'deliveryMethod': this.postalChoice.value, 'date': date});
    }

    openDatepicker($event) {
        $event.preventDefault();
        this.creditInvoiceDatepicker.open();
    }

    private _dateValidator(ctrl: AbstractControl) {
        if (moment(ctrl.value).isBefore(this._minDate)) {
            return {date: true};
        }

        return null;
    }

    private _setLabel() {
        switch (this._postalChoice.value) {
            case INVOICE_DELIVERY_METHOD.DOWNLOAD.toString():
                this._label = $localize`:Send invoice confirm dialog|button label:Finish`;
                break;
            case INVOICE_DELIVERY_METHOD.LETTER.toString():
                this._label = $localize`:Sending invoice via post|button label:Send`;
                break;
            default:
                break;
        }
    }
}
