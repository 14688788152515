<ng-container *ngIf="isLLC">
    <h3 class="form-section-header" i18n="End of year|header|yearly salary">yearly salary</h3>
    <div class="info-text">
        <a
            href="http://support.redflag.se/foretag-med-red-flag/om-lon-och-utdelning-i-famansbolag"
            target="_blank"
            i18n="End of year|header|yearly salary|link"
        >yearly salary link</a>
    </div>

    <table class="calc-table salary-table">
        <tbody class="tbody">
            <tr class="tr sum-total">
                <th class="description" i18n="End of year|header|yearly owner salary">yearly owner salary</th>
                <td class="amount" [innerHTML]="ownerSalary | amount : 0 : 0"></td>
            </tr>
            <tr class="tr sum-total">
                <th class="description" i18n="End of year|header|yearly total salary">yearly total salary</th>
                <td class="amount" [innerHTML]="totalSalary | amount : 0 : 0"></td>
            </tr>
        </tbody>
    </table>

</ng-container>
