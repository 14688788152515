import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SniCodesComponent} from './sni-codes';
import {ReactiveFormsModule} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {SniHelpers} from './sni-helpers';

@NgModule({
    declarations: [
        SniCodesComponent
    ],
    exports: [
        SniCodesComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule
    ],
    providers: [
        SniHelpers
    ]
})
export class SniCodesModule {}
