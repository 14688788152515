import {Component, HostBinding, Inject} from '@angular/core';
import {
    ROYALTY_STATEMENT_ORIGIN,
    ROYALTY_STATEMENT_STATUS, RoyaltyStatement,
    UpdateRoyaltyStatementStatusRequest
} from '@red/data';
import {DIALOG_RESULT, RedNotification, RedDialog} from '@red/components';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {RoyaltyStatementDeleteConfirmComponent} from '../confirm/delete/delete';
import {RoyaltyStatementUpdatedEvent} from '../../../common/event/readers/royalty-statement';
import {EventSource} from '../../../common/event/source';
import {Store} from '@ngxs/store';
import {RoyaltyServiceClient} from '../../../lab/service-client/royalty-service-client';

@Component({
    styleUrls: ['./edit.sass'],
    templateUrl: './edit.tpl.html'
})
export class RoyaltyStatementEditComponent {
    @HostBinding('class.royalty-statement-edit-view') cssClass = true;

    get canDelete(): boolean { return this.isRejected || this._royaltyStatement.status === ROYALTY_STATEMENT_STATUS.PENDING || this._royaltyStatement.status === ROYALTY_STATEMENT_STATUS.IN_REVIEW; }
    get royaltyStatement(): RoyaltyStatement { return this._royaltyStatement; }
    get isRejected(): boolean { return this._royaltyStatement.status === ROYALTY_STATEMENT_STATUS.REJECTED; }
    get isWaitingForPayment(): boolean { return this._royaltyStatement.status === ROYALTY_STATEMENT_STATUS.PENDING || this._royaltyStatement.status === ROYALTY_STATEMENT_STATUS.IN_REVIEW; }
    get resourceUrl(): string { return this._royaltyStatement.resourceUrl; }
    get showContextMenu(): boolean { return this.canDelete || !!this.resourceUrl; }
    get uploading(): boolean { return this._uploading; }

    private _dialogRef: MatDialogRef<RoyaltyStatementEditComponent>;
    private _eventSource: EventSource;
    private _royaltyStatement: RoyaltyStatement;
    private _royaltyServiceClient: RoyaltyServiceClient;
    private _notification: RedNotification;
    private _redDialog: RedDialog;
    private _store: Store;
    private _uploading = false;

    constructor (
        dialogRef: MatDialogRef<RoyaltyStatementEditComponent>,
        eventSource: EventSource,
        notification: RedNotification,
        redDialog: RedDialog,
        royaltyServiceClient: RoyaltyServiceClient,
        store: Store,
        @Inject(MAT_DIALOG_DATA) data: any
    ) {
        this._dialogRef = dialogRef;
        this._eventSource = eventSource;
        this._notification = notification;
        this._redDialog = redDialog;
        this._royaltyServiceClient = royaltyServiceClient;
        this._store = store;

        this._royaltyStatement = data.royaltyStatement;
    }

    close() {
        this._dialogRef.close(DIALOG_RESULT.OK);
    }

    getDescription(royalty: RoyaltyStatement) {
        let origin;
        const isPredefinedOrigin = Object.keys(ROYALTY_STATEMENT_ORIGIN).indexOf(royalty.royaltyOrigin) !== -1;
        if (royalty.royaltyOrigin === ROYALTY_STATEMENT_ORIGIN.UNKNOWN) {
            origin = $localize`:Royalty|default description in the list:Income`;
        } else if (isPredefinedOrigin) {
            origin = royalty.royaltyOrigin.substr(0, 1).toUpperCase() + royalty.royaltyOrigin.substr(1).toLowerCase();
        } else {
            origin = royalty.royaltyOrigin;
        }

        if (royalty.description) {
            return `${royalty.description} (${origin})`;
        }

        return origin;
    }

    onMenuDelete() {
        this._redDialog.confirm(RoyaltyStatementDeleteConfirmComponent)
            .afterClosed()
            .subscribe((result: DIALOG_RESULT) => {
                if (result === DIALOG_RESULT.OK) {
                    this._deleteRoyaltyStatement();
                }
            });
    }

    uploadNewImage($event: File) {
        this._uploading = true;
        this._uploadRoyaltyStatement($event);
    }

    private _deleteRoyaltyStatement() {
        this._royaltyServiceClient.setStatus(this._royaltyStatement, new UpdateRoyaltyStatementStatusRequest({'statementStatus': ROYALTY_STATEMENT_STATUS.CANCELLED}))
            .subscribe(() => {
                this._eventSource.emit(new RoyaltyStatementUpdatedEvent());
                this.close();
                const msg = $localize`:RedNotification|Income stream was deleted by user:Specification was deleted`;
                this._notification.open(msg);
            }, (err) => {
                const msg = $localize`:RedNotification|failed to delete income stream:Failed to delete specification`;
                this._notification.error(msg);
            });
    }

    private _uploadRoyaltyStatement(statement: File) {
        const formData = new FormData();
        formData.append('file', statement);

        this._royaltyServiceClient.replaceAsset(this._royaltyStatement, formData)
            .subscribe(() => {
                this._eventSource.emit(new RoyaltyStatementUpdatedEvent());
                this._dialogRef.close(DIALOG_RESULT.OK);
            }, (err) => {
                this._uploading = false;

                const msg = $localize`:RedNotification|Failed to upload image.:Failed to upload a image as a specification`;

                this._notification.errorWithCode(err, msg);
            });
    }
}
