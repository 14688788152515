import {ReplaySubject} from 'rxjs';
import {Capabilities} from '../../../app/common/capabilities';

export class BankIdLinking {
    private _url: string;
    private _bankIdIsOpen: boolean;

    constructor (url: string) {
        this._url = url;
        this._bankIdIsOpen = false;
    }

    tryAndOpen(): ReplaySubject<boolean> {
        const subject = new ReplaySubject<boolean>();
        const ua = (window.navigator.userAgent || window.navigator.vendor || window['opera']);

        if (Capabilities.isMobileDevice(ua)) {
            this._listenFocusChange(subject);
            this._bankIdIsOpen = true;
            setTimeout(() => this._openApp(), 40);
        } else {
            subject.next(true);
            subject.complete();
        }

        return subject;
    }

    private _listenFocusChange(subject: ReplaySubject<boolean>) {
        let visibilityChange;
        const onFocusChange = () => this._onFocusChange(subject);

        if (typeof document.hidden !== 'undefined') {
            visibilityChange = 'visibilitychange';
        } else if (typeof document['mozHidden'] !== 'undefined') {
            visibilityChange = 'mozvisibilitychange';
        } else if (typeof document['msHidden'] !== 'undefined') {
            visibilityChange = 'msvisibilitychange';
        } else if (typeof document['webkitHidden'] !== 'undefined') {
            visibilityChange = 'webkitvisibilitychange';
        }

        document.addEventListener(visibilityChange, onFocusChange);
        window.addEventListener('focus', onFocusChange);
        window.addEventListener('blur', onFocusChange);

        subject.subscribe(() => {}, () => {}, () => {
            document.removeEventListener(visibilityChange, onFocusChange);
            window.removeEventListener('focus', onFocusChange);
            window.removeEventListener('blur', onFocusChange);
        });
    }

    private _onFocusChange(subject: ReplaySubject<boolean>) {
        if (this._hasFocus() && this._bankIdIsOpen && !subject.closed) {
            subject.next(true);
            subject.complete();
        } else if (!subject.closed) {
            subject.next(false);
        }
    }

    private _openApp() {
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window['MSStream'];

        if (isIOS) {
            window.location.href = this._url;
            return;
        }

        const iframe = document.createElement('iframe');
        iframe.style.visibility = 'hidden';
        iframe.style.width = '1px';
        iframe.style.height = '1px';

        iframe.src = this._url;

        document.body.appendChild(iframe);
        setTimeout(() => document.body.removeChild(iframe), 200);
    }

    private _hasFocus(): boolean {
        return document.hasFocus();
    }
}

