export class UrlBuilder {
    private _baseUrl: string;
    private _endpoints: {[name: string]: string};

    constructor(endpoints: {[name: string]: string}, baseUrl?: string) {
        this._endpoints = endpoints;
        this._baseUrl = baseUrl || '';
    }

    compileUrl(patternName: string, parts: any = {}, params?: any): string {
        let compiledUrl = this._baseUrl;
        const pattern = this._getUrlPattern(patternName);

        compiledUrl += pattern.replace(/{(\w+)}/g, (match, key) => {
            if (parts[key] && typeof parts[key] === 'string') {
                match = match.replace(`{${key}}`, parts[key]);
            } else {
                const debugMsg = `parts: ${JSON.stringify(parts)}, endpoints: ${JSON.stringify(this._endpoints)}, baseUrl: ${JSON.stringify(this._baseUrl)}`;
                throw new Error(`Cannot compile pattern "${patternName}" part "${key} (${typeof key})" is missing or of non-string type. DEBUG: ${debugMsg}`);
            }
            return match;

        });

        return `${compiledUrl}${(params && this._queryParamsFromObj(params)) || ''}`;
    }

    private _getUrlPattern(patternName): string {

        if (!this._endpoints.hasOwnProperty(patternName)) {
            throw new Error(`Unknown pattern "${patternName}".`);
        }

        return this._endpoints[patternName] ? this._endpoints[patternName] : '';
    }

    private _queryParamsFromObj(params: any): string {
        const query = '?',
            keys = Object.keys(params);

        if (keys.length === 0) {
            return '';
        }

        return query + (keys.map(p => {
            if (Array.isArray(params[p])) {
                return params[p].map((value) => `${p}=${value}`).join('&');
            }
            return `${p}=${params[p]}`;
        }).join('&'));
    }
}
