import {Component, forwardRef, OnInit} from '@angular/core';
import {Actions, Store} from '@ngxs/store';
import {OnboardingManager} from '../../../../managers/onboarding/onboarding-manager';
import {OnboardingApplicationBaseDirective} from '../onboarding-application-base';

@Component({
    selector: 'app-onboarding-application-llc-new-create-company',
    styleUrls: ['../onboarding-applications.sass'],
    templateUrl: 'onboarding-application-llc-new-create-company.tpl.html',
    providers: [
        {provide: OnboardingApplicationBaseDirective, useExisting: forwardRef(() => OnboardingApplicationLlcNewCreateCompanyComponent )}
    ]
})
export class OnboardingApplicationLlcNewCreateCompanyComponent extends OnboardingApplicationBaseDirective implements OnInit {
    constructor (
        actions: Actions,
        store: Store,
        onboardingManager: OnboardingManager
    ) {
        super(actions, store, onboardingManager);
    }

    ngOnInit() {
        super.ngOnInit();
    }
}
