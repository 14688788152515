import {RoaringServiceClient} from '../../lab/service-client/roaring-service-client';
import {Observable} from 'rxjs';
import {
    CustomerSearchResult,
    CustomerSearchResultListResponse,
} from '@red/data';

export class RoaringManager {
    private _roaringServiceClient: RoaringServiceClient;

    constructor(
        roaringServiceClient: RoaringServiceClient,
    ) {
        this._roaringServiceClient = roaringServiceClient;
    }

    static create(
        roaringServiceClient: RoaringServiceClient
    ) {
        return new RoaringManager(roaringServiceClient);
    }

    search(query: string): Observable<CustomerSearchResultListResponse> {
        return this._roaringServiceClient.search(query);
    }

    getCompanyDetails(roaringCompanyId: string): Observable<CustomerSearchResult> {
        return this._roaringServiceClient.getCompanyDetails(roaringCompanyId);
    }

    getIndividualDetails(personnummer: string): Observable<CustomerSearchResult> {
        return this._roaringServiceClient.getIndividualDetails(personnummer);
    }

}
