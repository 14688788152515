import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {OnboardingSelectTrack} from '../../../shared/state/onboarding/onboarding.actions';
import {Store} from '@ngxs/store';
import {ONBOARDING_TRACK} from '@red/onboarding-service-api';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'app-onboarding-select-track',
    styleUrls: ['./onboarding-select-track.sass'],
    templateUrl: 'onboarding-select-track.tpl.html'
})
export class OnboardingSelectTrackComponent {
    private _store: Store;

    constructor(
        store: Store
    ) {
        this._store = store;
    }

    selectNewSoleTraderTrack() {
        const track = ONBOARDING_TRACK.SOLE_TRADER_NEW;
        this._store.dispatch(new OnboardingSelectTrack({track}));
    }

    selectExistingSoleTraderTrack() {
        const track = ONBOARDING_TRACK.SOLE_TRADER_EXISTING;
        this._store.dispatch(new OnboardingSelectTrack({track}));
    }

    selectNewLimitedCompanyTrack() {
        const track = ONBOARDING_TRACK.LIMITED_COMPANY_NEW;
        this._store.dispatch(new OnboardingSelectTrack({track}));
    }

    selectExistingLimitedCompanyTrack() {
        const track = ONBOARDING_TRACK.LIMITED_COMPANY_EXISTING;
        this._store.dispatch(new OnboardingSelectTrack({track}));
    }
}
