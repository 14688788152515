import {BaseEvent} from '../event';
import {EventSource} from '../source';
import {Subject} from 'rxjs';
import {EventReader, OnEvent} from '../reader';
import {Injectable} from '@angular/core';

export class DividendsCreatedEvent extends BaseEvent {}

@Injectable()
export class DividendsEventReader extends EventReader {
    private _onCreatedStream: Subject<DividendsCreatedEvent>;

    constructor (
        source: EventSource
    ) {
        super(source);

        this._onCreatedStream = new Subject<DividendsCreatedEvent>();
    }

    onCreated(fn: (evt: DividendsCreatedEvent) => void) {
        this._onCreatedStream.subscribe(fn);
    }

    @OnEvent(DividendsCreatedEvent)
    private _handleReceiptCreatedEvent(evt: DividendsCreatedEvent) {
        this._onCreatedStream.next(evt);
    }
}
