import {throwError as observableThrowError, Observable} from 'rxjs';

export enum HTTP_METHODS {
    Get = 0,
    Post = 1,
    Put = 2,
    Delete = 3,
    Options = 4,
    Head = 5,
    Patch = 6,
}

// eslint-disable-next-line
export interface ITransport {
    request(options: TransportOptions): Observable<any>;
    get(url: string, options?: TransportOptions): Observable<any>;
    post(url: string, body?: any, options?: TransportOptions): Observable<any>;
    put?(url: string, body?: any, options?: TransportOptions): Observable<any>;
    delete?(url: string, options?: TransportOptions): Observable<any>;
    patch?(url: string, body: any, options?: TransportOptions): Observable<any>;
    head?(url: string, options?: TransportOptions): Observable<any>;
}

export class TransportOptions {
    body: any;
    headers: any;
    method: HTTP_METHODS = HTTP_METHODS.Get;
    url: string;

    constructor(method: HTTP_METHODS, url: string, body?: any, headers?: any) {
        this.method = method;
        this.url = url;
        this.body = body;
        this.headers = headers;
    }
}

export abstract class Transport<T> implements ITransport {
    request(options: TransportOptions): Observable<T> {
        return observableThrowError(new Error('Transport is abstract class. Extend it and implement own request method'));
    }

    delete(url: string, options?: TransportOptions): Observable<T> {
        const opt = options || new TransportOptions(HTTP_METHODS.Delete, url);
        opt.method = HTTP_METHODS.Delete;
        opt.url = url;

        return this.request(opt);
    }

    get(url: string, options?: TransportOptions): Observable<T> {
        const opt = options || new TransportOptions(HTTP_METHODS.Get, url);
        opt.method = HTTP_METHODS.Get;
        opt.url = url;

        return this.request(opt);
    }

    post(url: string, body?: any, options?: TransportOptions): Observable<T> {
        const opt = options || new TransportOptions(HTTP_METHODS.Post, url);
        opt.method = HTTP_METHODS.Post;
        opt.url = url;
        opt.body = body;

        return this.request(opt);
    }

    put(url: string, body?: any, options?: TransportOptions): Observable<T> {
        const opt = options || new TransportOptions(HTTP_METHODS.Put, url);
        opt.method = HTTP_METHODS.Put;
        opt.url = url;
        opt.body = body;

        return this.request(opt);
    }
}
