import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup} from '@angular/forms';
import {Amount, COMPANY_TYPE} from '@red/data';
import { clone } from '../../../../common/utils/clone';
import {EndOfYearManager} from '../../../../managers/end-of-year/end-of-year.manager';

enum READMORE_STATE {
    OPEN = <any>`OPEN`,
    CLOSED = <any>`CLOSED`,
}

@Component({
    selector: 'app-end-of-year-other-income-form',
    styleUrls: ['../../end-of-year.dialog.sass'],
    templateUrl: 'end-of-year-other-income-form.tpl.html'
})

export class EndOfYearOtherIncomeFormComponent implements OnInit {
    @Input() metadataForm: FormGroup;
    @Input() metadata: any;
    @Input() showForm = true;

    get hasCloseYearFlag(): boolean { return (!!this._endOfYearManager.endOfYearStep.closeYearFlag); }

    get otherTax(): AbstractControl { return this._otherTax; }
    get otherIncome(): AbstractControl { return this._otherIncome; }
    get primaryEmployer(): AbstractControl { return this._primaryEmployer; }
    get nonDeductibleIncome(): AbstractControl { return this._nonDeductibleIncome; }
    get insuranceIncome(): AbstractControl { return this._insuranceIncome; }

    get otherIncomeAmount(): Amount { return this._amounts.otherIncome; }
    get nonDeductibleIncomeAmount(): Amount { return this._amounts.nonDeductibleIncome; }
    get insuranceIncomeAmount(): Amount { return this._amounts.insuranceIncome; }
    get otherTaxAmount(): Amount { return this._amounts.otherTax; }

    get isSoleTrader(): boolean { return (this._endOfYearManager.currentCompanyType === COMPANY_TYPE.SOLE_TRADER); }

    private _formBuild: FormBuilder;
    private _primaryEmployer: AbstractControl;
    private _otherIncome: AbstractControl;
    private _otherTax: AbstractControl;
    private _nonDeductibleIncome: AbstractControl;
    private _insuranceIncome: AbstractControl;
    private _elementRef: ElementRef;
    private _amounts: any;
    private _endOfYearManager: EndOfYearManager;

    constructor(
        formBuild: FormBuilder,
        elementRef: ElementRef,
        endOfYearManager: EndOfYearManager
    ) {
        this._formBuild = formBuild;
        this._elementRef = elementRef;
        this._endOfYearManager = endOfYearManager;
    }

    ngOnInit() {
        this._primaryEmployer = this.metadataForm.get('primaryEmployer');
        this._otherIncome = this.metadataForm.get('otherIncome');
        this._otherTax = this.metadataForm.get('otherTax');
        this._nonDeductibleIncome = this.metadataForm.get('nonDeductibleIncome');
        this._insuranceIncome = this.metadataForm.get('insuranceIncome');

        this._amounts = clone(this.metadataForm.getRawValue());
    }

    showWarning(): boolean {
        return (this._otherIncome.get('amount').value > 0 || this._nonDeductibleIncome.get('amount').value > 0) && this._otherTax.get('amount').value === 0;
    }

    toggleReadMoreLess(readMoreIndex: number) {
        const parent = this._elementRef.nativeElement.querySelectorAll(`.info-text.read-more`).item(readMoreIndex);
        const parentState = this._isOpen(parent) ? READMORE_STATE.OPEN : READMORE_STATE.CLOSED;

        this._setReadMoreState(parent, parentState);
    }

    readMoreLess(readMoreIndex: number): string {
        const parent = this._elementRef.nativeElement.querySelectorAll(`.info-text.read-more`).item(readMoreIndex);
        const parentState = this._isOpen(parent) ? READMORE_STATE.OPEN : READMORE_STATE.CLOSED;

        return parentState === READMORE_STATE.CLOSED
            ? $localize`:Text Info|Label:Show more`
            : $localize`:Text Info|Label:Show less`;
    }

    getMetadataValue(metadataKey: string): any {
        return this.metadataForm.get(metadataKey).value;
    }

    private _isOpen(parent: Element): boolean {
        return parent && (/(^|\s)open(\s|$)/).test(parent.className);
    }

    private _setReadMoreState(parent: Element, parentState: READMORE_STATE) {
        const className = parentState === READMORE_STATE.CLOSED
            ? `${(parent.className || '')} open`.trim()
            : parent.className.replace(/(^|\s)(open)(\s|$)/, '');

        parent.className = className;
    }
}
