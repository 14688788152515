import {
    Company,
    CompanyData,
    CompanyListResponse,
    CompanyUserListResponse, CreateUserRequest, FirstEmployeePermitRequest, Relation,
    RelationListResponse,
    ROLE,
    UpdateUserRequest,
    User
} from '@red/data';
import {HttpFetch, HttpFetchResponse} from '@red/common';
import {Observable} from 'rxjs';
import {WebServiceClient} from '@red/browser';
import {AppConfig} from '../../config/appConfig';

export class DirectoryServiceClient extends WebServiceClient {
    static create(httpFetch: HttpFetch, config: AppConfig) {
        return new DirectoryServiceClient(httpFetch, config);
    }

    listCompanies(limit?: number, offset?: number) {
        return this._get<CompanyListResponse>(`${this._getBaseUrl()}/api/company`, {}, CompanyListResponse);
    }

    closeCompany(companyId: string): Observable<HttpFetchResponse> {
        return this._delete(`${this._getBaseUrl()}/api/company/${companyId}`, {});
    }

    reopenCompany(companyId: string): Observable<Company> {
        return this._put<Company>(`${this._getBaseUrl()}/api/company/${companyId}/reopen`, {}, {}, Company);
    }

    getCompanyById(companyId: string): Observable<Company> {
        return this._get<Company>(`${this._getBaseUrl()}/api/company/${companyId}`, {}, Company);
    }

    getUserById(userId: string): Observable<User> {
        return this._get<User>(`${this._getBaseUrl()}/api/user/${userId}`, {}, User);
    }

    getCompanyRelation(companyId: string): Observable<RelationListResponse> {
        return this._get<RelationListResponse>(`${this._getBaseUrl()}/api/company/${companyId}/relation`, {}, RelationListResponse);
    }

    getUserRelation(userId: string): Observable<RelationListResponse> {
        return this._get<RelationListResponse>(`${this._getBaseUrl()}/api/user/${userId}/relations`, {}, RelationListResponse);
    }

    updateCompanyDetails(companyId: string, details: CompanyData): Observable<Company> {
        return this._put<Company>(`${this._getBaseUrl()}/api/company/${companyId}`, details, {}, Company);
    }

    updateUser(userId: string, request: UpdateUserRequest) {
        return this._put<User>(`${this._getBaseUrl()}/api/user/${userId}`, request, {}, User);
    }

    getCompanyUsers(companyId: string): Observable<CompanyUserListResponse> {
        return this._get<CompanyUserListResponse>(`${this._getBaseUrl()}/api/company/${companyId}/user`, {}, CompanyUserListResponse);
    }

    getUser(userId: string): Observable<User> {
        return this._get<User>(`${this._getBaseUrl()}/api/user/${userId}`, {}, User);
    }

    getUserByIdentifier(identifier: string): Observable<User> {
        return this._get<User>(`${this._getBaseUrl()}/api/user/identifier/${identifier}`, {}, User);
    }

    createUser(createUserRequest: CreateUserRequest): Observable<User> {
        return this._post<User>(`${this._getBaseUrl()}/api/user`, createUserRequest, {}, User);
    }

    createRelation(companyId: string, userIdentifier: string, role: ROLE): Observable<Relation> {
        const url = `${this._getBaseUrl()}/api/company/${companyId}/relation/${userIdentifier}/${role}`;

        return this._put<Relation>(url, null, {}, Relation);
    }

    setFirstEmployeePermit(companyId: string, request: FirstEmployeePermitRequest): Observable<Company> {
        const url = `${this._getBaseUrl()}/api/company/${companyId}/setFirstEmployeePermit`;

        return this._put<Company>(url, request, {}, Company);
    }

    deleteCompanyRelation(companyId: string, relationId: string): Observable<User> {
        const url = `${this._getBaseUrl()}/api/company/${companyId}/relation/${relationId}`;

        return this._delete<User>(url, {});
    }
}
