
export function clone(input: any): any {
    if (!input || typeof input !== 'object') {
        return input;
    } else if (Array.isArray(input)) {
        const newArray = [];

        input.forEach(item => {
            newArray.push(clone(item));
        });

        return newArray;
    } else {
        const newObj = {};

        Object.keys(input).forEach(key => {
            newObj[key] = clone(input[key]);
        });

        return newObj;
    }
}
