<div class="onboarding-sign-up-view">
    <div class="onboarding-sign-up-container hooks-bottom">
        <mat-card class="onboarding-sign-up-view-card holes-bottom">
            <div class="bokio-logo-container">
                <div class="bokio-logo"></div>
            </div>
        </mat-card>
    </div>

    <div class="onboarding-sign-up-container">
        <div class="holes-top">
            <mat-card class="onboarding-sign-up-view-card" [ngSwitch]="state">
                <div>
                    <div class="onboarding-sign-up-view-info-box">
                        <p>Logga in med två enkla steg och se direkt hur enkelt det är att starta bolag med Bokio.</p>
                        <p>Du förbinder dig inte till någonting och väljer själv om och när du vill sätta igång.</p>
                    </div>
                </div>
                <ng-container *ngSwitchCase="'IDLE'">
                    <div class="onboarding-sign-up-view-progress">
                        <div class="onboarding-sign-up-view-progress-step">
                            <div class="onboarding-sign-up-view-progress-number">
                                1
                            </div>
                            <span class="onboarding-sign-up-view-progress-name" i18n="Onboarding SignUp|Step|BankID">Mobilt BankID</span>
                        </div>
                        <div class="onboarding-sign-up-view-progress-step onboarding-sign-up-view-progress-step-disabled">
                            <div class="onboarding-sign-up-view-progress-number">
                                2
                            </div>
                            <span class="onboarding-sign-up-view-progress-name" i18n="Onboarding SignUp|Step|Contanct info">Contanct info</span>
                        </div>
                    </div>
                    <form class="onboarding-sign-up-view-bankid-form" red-form [formGroup]="bankIdForm">
                        <fieldset>
                            <label class="red-row identity-label">
                                <mat-form-field>
                                    <input
                                        matInput
                                        i18n-placeholder="Onboarding SignUp|input placeholder|Identity placeholder"
                                        placeholder="Identity"
                                        formControlName="identifier"
                                        required
                                        autocomplete="off"
                                    />
                                </mat-form-field>
                            </label>
                        </fieldset>
                        <div class="form-actions">
                            <button
                                class="form-actions-submit-button"
                                i18n="Onboarding SignUp|button|Submit sign in"
                                [disabled]="bankIdForm.invalid"
                                mat-flat-button
                                color="primary"
                                type="button"
                                (click)="login($event)"
                            >Submit</button>
                        </div>
                    </form>
                </ng-container>
                <div class="waiting-for-bankid" *ngSwitchCase="'WAITING_FOR_BANKID'">
                    <red-atom-loader></red-atom-loader>
                    <p class="waiting-for-bankid-label" i18n="Login|Please open you bankId application">Please open you bankId application</p>
                    <a [href]="bankIdUrl" *ngIf="canOpenOnSameDevice && bankIdUrl" mat-flat-button color="primary" i18n="Login|Open BankId app">
                        Open BankId app
                    </a>
                </div>
                <div class="waiting-for-bankid" *ngSwitchCase="'USER_SIGN'">
                    <red-atom-loader></red-atom-loader>
                    <p class="waiting-for-bankid-label" i18n="Login|Please enter your code in the app">Please enter your code in the app</p>
                </div>
                <ng-container *ngSwitchCase="'ONBOARDING'">
                    <div class="onboarding-sign-up-view-progress">
                        <div class="onboarding-sign-up-view-progress-step">
                            <div class="onboarding-sign-up-view-progress-number">
                                1
                            </div>
                            <span class="onboarding-sign-up-view-progress-name" i18n="Onboarding SignUp|Step|BankID">Mobilt BankID</span>
                        </div>
                        <div class="onboarding-sign-up-view-progress-step">
                            <div class="onboarding-sign-up-view-progress-number">
                                2
                            </div>
                            <span class="onboarding-sign-up-view-progress-name" i18n="Onboarding SignUp|Step|Contanct info">Contanct info</span>
                        </div>
                    </div>
                    <form class="onboarding-sign-up-view-bankid-form" red-form [formGroup]="onboardingForm">
                        <fieldset>
                            <div class="red-row">
                                <mat-form-field>
                                    <input
                                        matInput
                                        i18n-placeholder="Onboarding SignUp|input placeholder|Email placeholder"
                                        placeholder="Email"
                                        formControlName="email"
                                        required
                                        autocomplete="off"
                                    />
                                </mat-form-field>
                            </div>
                            <div class="red-row">
                                <mat-form-field>
                                    <input
                                        matInput
                                        i18n-placeholder="Onboarding SignUp|input placeholder|Phone placeholder"
                                        placeholder="Phone"
                                        formControlName="phone"
                                        required
                                        autocomplete="off"
                                    />
                                </mat-form-field>
                            </div>
                        </fieldset>
                        <div class="form-actions">
                            <button
                                class="form-actions-submit-button"
                                i18n="Onboarding SignUp|button|Start onboarding"
                                [disabled]="bankIdForm.invalid"
                                mat-flat-button
                                color="primary"
                                type="button"
                                (click)="startOnboarding($event)"
                            >Start onboarding</button>
                        </div>
                    </form>
                </ng-container>
            </mat-card>
        </div>
    </div>
</div>
