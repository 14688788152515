import {Route, Routes } from '@angular/router';
import * as moment from 'moment';
import {interval, Observable, of} from 'rxjs';
import {AssetsUploadDetails, ASSETUPLOADSTATUS} from '@red/data';
import {OffboardingServiceClient} from '../../lab/service-client/offboarding-service-client';
import {catchError, map, switchMap, takeWhile} from 'rxjs/operators';

export enum READ_ONLY_TYPE {
    BILL= 'BILL',
}

export class RedFlagShutDownManager {
    get today(): string { return this._today; }

    get shutDownDate(): string { return this._shutDownDate; }
    get readOnlyDate(): string { return this._readOnlyDate; }

    get googleDriveLink(): string { return this._googleDriveLink; }

    private _today = moment().format('YYYY-MM-DD');

    private _readOnlyDate = '2021-11-29';
    private _shutDownDate = '2021-12-01';

    private _googleDriveLink: string;

    private _specialReadOnlyDates = {
        BILL: '2021-11-26'
    };
    private _offboardingServiceClient: OffboardingServiceClient;

    constructor (
        offboardingServiceClient: OffboardingServiceClient
    ) {
        this._offboardingServiceClient = offboardingServiceClient;
    }

    static create(
        offboardingServiceClient: OffboardingServiceClient
    ) {
        return new RedFlagShutDownManager(offboardingServiceClient);
    }

    notReadOnlyState(date: string, type?: READ_ONLY_TYPE): boolean {
        this._today = moment().format('YYYY-MM-DD');

        const givenDate = moment(date);
        const readOnlyDate = moment(this._getReadOnlyDate(type));

        return givenDate.isBefore(readOnlyDate);
    }

    readOnlyState(date: string, type?: READ_ONLY_TYPE): boolean {
        this._today = moment().format('YYYY-MM-DD');

        const givenDate = moment(date);
        const readOnlyDate = moment(this._getReadOnlyDate(type));

        return givenDate.isSameOrAfter(readOnlyDate);
    }

    shutDownState(date: string): boolean {
        this._today = moment().format('YYYY-MM-DD');

        const givenDate = moment(date);
        const shutDownDate = moment(this._shutDownDate);

        return givenDate.isSameOrAfter(shutDownDate);
    }

    setRoutes(routes: Routes): Routes {
        return routes.map((route) => {
            return this._handleRoute(route);
        });
    }

    checkUploadOfAssetsToGoogleDrive(companyId: string): Observable<AssetsUploadDetails> {
        return this._offboardingServiceClient.checkUploadOfAssetsToGoogleDrive(companyId)
            .pipe(
                catchError(err => of(err)),
                map((details: AssetsUploadDetails) => details)
            );
    }

    checkUploadOfAssetsToGoogleDrivePolling(companyId: string, delay: number = 10000): Observable<AssetsUploadDetails> {
        return interval(delay)
            .pipe(
                switchMap(() => {
                    return this._offboardingServiceClient.checkUploadOfAssetsToGoogleDrive(companyId);
                }),
                takeWhile((details: AssetsUploadDetails = {} as AssetsUploadDetails) => {
                    return details.assetUploadStatus === ASSETUPLOADSTATUS.UPLOAD_IN_PROGRESS;
                }, true)
            )
            .pipe(map((details: AssetsUploadDetails) => {
                return details;
            }));
    }

    private _handleRoute(route: Route): Route {
        const path = route.path;

        switch (path) {
            case 'invoice': {
                const newChildren = route.children[0].children.filter((childRoute: Route) => {
                    return childRoute.path !== 'create';
                });
                route.children[0].children = newChildren;
                break;
            }
            case 'benefit': {
                delete route.children[0].children;
                break;
            }
        }

        return route;
    }

    private _getReadOnlyDate(type: READ_ONLY_TYPE): string {
        switch (type) {
            case READ_ONLY_TYPE.BILL:
                return this._specialReadOnlyDates.BILL;
            default:
                return this._readOnlyDate;
        }
    }
}
