import {NgxsStoragePluginOptions, StorageEngine} from '@ngxs/storage-plugin';
import {CookieOptions, CookieOptionsArgs} from './cookie-storage-options';
import {StateToken} from '@ngxs/store';

export class CookieStorageEngine implements StorageEngine {
    private _defaultCookieOptions: CookieOptions;
    private _storageOptions: NgxsStoragePluginOptions;
    private _cookieOptionsForState: { [stateName: string]: CookieOptions };

    constructor(options: CookieOptions, storageOptions: NgxsStoragePluginOptions, cookieOptionsForState?: {[stateName: string]: CookieOptions}) {
        this._defaultCookieOptions = options;
        this._storageOptions = storageOptions;
        this._cookieOptionsForState = cookieOptionsForState;
    }

    static create(storageOptions: NgxsStoragePluginOptions, cookieOptionsForState?: {[stateName: string]: CookieOptions}) {
        const options = new CookieOptions({secure: window.location.protocol === 'https:', path: '/', sameSite: true});
        return new CookieStorageEngine(options, storageOptions, cookieOptionsForState);
    }

    get length(): number {
        const cookies = this._cookieReader();
        return typeof cookies === 'object' ? Object.values(this._cookieReader()).length : 0;
    }

    getItem(key: string|StateToken): any {
        const strKey = this._getStrKey(key);
        const cookies = this._cookieReader();

        return cookies.hasOwnProperty(strKey) ? this._deserialize(cookies[strKey]) : null;
    }

    setItem(key: string, val: any): void {
        const strKey = this._getStrKey(key);

        this._cookieWriter(strKey, this._serialize(val));
    }

    removeItem(key: string): void {
        const strKey = this._getStrKey(key);
        this._cookieWriter(strKey, undefined);
    }

    clear(): void {
        const cookies = this._cookieReader();
        Object.keys(cookies).forEach(key => {
            this.removeItem(key);
        });
    }

    key(val: number): string {
        const cookies = this._cookieReader();
        let key;
        let cookieKey;
        for (cookieKey in cookies) {
            if (this.getItem(cookieKey) === val) {
                key = cookieKey;
                break;
            }
        }

        return cookieKey;
    }

    private _deserialize(obj: any) {
        return atob(obj);
    }

    private _serialize(obj: any) {
        return this._isString(obj) ? btoa(obj) : btoa(JSON.stringify(obj));
    }

    private _getStrKey(key: string|StateToken): string {
        return key instanceof StateToken ? key.getName() : key;
    }

    private _cookieReader(): {[name: string]: any} {
        let lastCookies = {};
        let lastCookieString = '';

        let cookieArray: string[];
        let cookie: string;
        let i: number;
        let  index: number;
        let name: string;

        const currentCookieString = document.cookie || '';
        if (currentCookieString !== lastCookieString) {
            lastCookieString = currentCookieString;
            cookieArray = lastCookieString.split('; ');
            lastCookies = {};
            for (i = 0; i < cookieArray.length; i++) {
                cookie = cookieArray[i];
                index = cookie.indexOf('=');
                if (index > 0) {  // ignore nameless cookies
                    name = this._safeDecodeURIComponent(cookie.substring(0, index));
                    // the first value that is seen for a cookie is the most
                    // specific one.  values for the same cookie name that
                    // follow are for less specific paths.
                    if (this._isBlank(lastCookies[name])) {
                        lastCookies[name] = this._safeDecodeURIComponent(cookie.substring(index + 1));
                    }
                }
            }
        }

        return lastCookies;
    }

    private _cookieWriter(name: string, value: string) {
        document.cookie = this._buildCookieString(name, value);
    }

    private _safeDecodeURIComponent(str: string) {
        try {
            return decodeURIComponent(str);
        } catch (e) {
            return str;
        }
    }

    private _buildCookieString(name: string, value: string): string {
        const cookiePath = '/';
        let expires;

        const opts = this._cookieOptionsForState && this._cookieOptionsForState.hasOwnProperty(name) ? this._cookieOptionsForState[name] : this._defaultCookieOptions;
        expires = opts.expires;
        if (this._isBlank(value)) {
            expires = 'Thu, 01 Jan 1970 00:00:00 GMT';
            value = '';
        }

        if (this._isString(expires)) {
            expires = new Date(expires);
        }

        let str = encodeURIComponent(name) + '=' + encodeURIComponent(value);
        str += opts.path ? ';path=' + opts.path : '';
        str += opts.domain ? ';domain=' + opts.domain : '';
        str += expires ? ';expires=' + expires.toUTCString() : '';
        str += opts.secure ? ';secure' : '';
        str += opts.sameSite ? ';SameSite=Strict' : '';

        // per http://www.ietf.org/rfc/rfc2109.txt browser must allow at minimum:
        // - 300 cookies
        // - 20 cookies per unique domain
        // - 4096 bytes per cookie
        const cookieLength = str.length + 1;
        if (cookieLength > 4096) {
            console.log(`Cookie \'${name}\' possibly not set or overflowed because it was too large (${cookieLength} > 4096 bytes)!`);
        }

        return str;
    }

    private _isBlank(obj: any): boolean {
        return obj === undefined || obj === null;
    }

    private _isPresent(obj: any): boolean {
        return obj !== undefined && obj !== null;
    }

    private _isString(obj: any): obj is string {
        return typeof obj === 'string';
    }

}
