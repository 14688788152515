<div class="alert-content" mat-dialog-content>
    <div class="red-alert-title">{{title}}</div>
    <div class="send-line">
        <span class="label" i18n="Total net">Total</span>
        <span>{{netAmount}}</span>
    </div>
    <div class="send-line">
        <span class="label" i18n="Total vat">VAT</span>
        <span>{{vat}}</span>
    </div>
    <div class="send-line totals">
        <span class="label" i18n="Invoice total">Sum</span>
        <span>{{amount}}</span>
    </div>

    <div *ngIf="isCreditInvoice">
        <mat-form-field class="credit-date">
            <input
                matInput
                i18n-placeholder="Date"
                placeholder="Date"
                [formControl]="invoiceDate"
                [max]="todayDate"
                [matDatepicker]="creditInvoiceDatepicker"
                (click)="openDatepicker($event)"
            />
            <mat-hint *ngIf="invoiceDate.invalid" i18n="Invoice date earlier last invoice date">Invoice date can't be earlier than {{minDate}}</mat-hint>
        </mat-form-field>
        <mat-datepicker #creditInvoiceDatepicker></mat-datepicker>
    </div>
    <red-radio-group-block>
        <mat-radio-group class="send-radio-group" [formControl]="postalChoice">
            <mat-radio-button color="primary" value="DOWNLOAD">
                <p i18n="Download your invoice and send it yourself to your customer" class="multi-line">Download your invoice and send it yourself to your customer</p>
            </mat-radio-button>
            <mat-radio-button *ngIf="!isInternationalInvoice" color="primary" value="LETTER">
                <p i18n="Send invoice to address" class="multi-line">Send invoice to:</p>
                <div class="send-address-background">
                    <p>{{customerName}}</p>
                    <red-display-address
                        [address]="address"
                        [showHeader]="false"
                    ></red-display-address>
                </div>
            </mat-radio-button>
            <mat-radio-button *ngIf="isInternationalInvoice" class="send-disable-radio-btn" disabled>
                <p class="multi-line" i18n="Invoices to abroad could not be send via post"> Invoices to abroad could not be send via post</p>
            </mat-radio-button>
        </mat-radio-group>
    </red-radio-group-block>
</div>

<mat-dialog-actions class="red-alert-actions">
    <button mat-button class="mat-primary" tabindex="-1" (click)="cancel()" i18n="Cancel button label">Cancel</button>
    <button mat-button class="mat-primary" tabindex="-1" (click)="confirm()" [disabled]="invoiceDate.invalid">{{label}}</button>
</mat-dialog-actions>
