<div class="red-dialog-title" matDialogTitle>
    <button mat-button (click)="close()" tabindex="-1">
        <mat-icon svgIcon="ui-close-white"></mat-icon>
    </button>
    <span class="red-dialog-title-text" i18n="Create income stream dialog|header for new income stream">New income stream</span>
</div>
<div class="red-dialog-content" matDialogContent [ngSwitch]="uploading">
    <red-loader-overlay *ngSwitchCase="true"><red-atom-loader></red-atom-loader></red-loader-overlay>
    <div *ngSwitchDefault>
        <red-asset-preview [file]="royaltyStatementImage"></red-asset-preview>
    </div>
</div>
<mat-dialog-actions class="red-dialog-actions">
    <button mat-flat-button class="create-income-stream-button" [disabled]="uploading" (click)="save()" [ngSwitch]="uploading">
        <span *ngSwitchCase="true" i18n="Button|Uploading income stream">Uploading...</span>
        <span *ngSwitchDefault i18n="Button|Save income stream">Save income stream</span>
    </button>
</mat-dialog-actions>
