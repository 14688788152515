import {Component, OnInit} from '@angular/core';
import {DIALOG_RESULT, RedDialog} from '@red/components';
import {HAS_READ, RedFlagShutDownDialogComponent} from '../red-flag-shut-down-dialog/red-flag-shut-down-dialog';

const storage = window.localStorage;

@Component({
    selector: 'app-red-flag-shut-down-trigger',
    styleUrls: ['./red-flag-shut-down-trigger.sass'],
    templateUrl: 'red-flag-shut-down-trigger.tpl.html'
})

export class RedFlagShutDownTriggerComponent implements OnInit {
    private _dialog: RedDialog;

    constructor(
        dialog: RedDialog,
    ) {
        this._dialog = dialog;
    }

    ngOnInit(): void {
        const openDialog = !storage.getItem('haveReadRedFlagShutDownNote') || storage.getItem('haveReadRedFlagShutDownNote') === HAS_READ.FALSE;

        if (openDialog) {
            this._dialog
                .open(RedFlagShutDownDialogComponent)
                .afterClosed()
                .subscribe((result: DIALOG_RESULT) => {});
        }
    }
}
