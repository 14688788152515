<form [formGroup]="dividendsForm" class="create-dividends-form">
    <mat-form-field>
        <input
            matInput
            formControlName="dividends"
            i18n-placeholder="Placeholder|Dividends"
            placeholder="Dividends"
            autocomplete="off"
            type="number"
        />
        <span matSuffix>&nbsp;kr</span>
        <mat-hint *ngIf="!isValid()" i18n="Dividends form|Can only contain a valid number">Dividends can only contain numbers and , or .</mat-hint>
    </mat-form-field>
</form>
<red-table-row>
    <span i18n="Left dividends">Left dividends</span>
    <span redTableAmount [innerHTML]="leftDividends | amount"></span>
</red-table-row>
<div class="dividends-calculate-btn">
    <button mat-flat-button color="primary" [disabled]="canCalculate" (click)="submit($event)" i18n="Dividends|Calculate button">Calculate</button>
</div>
