<div class="topbar-wrapper red-flag-shut-down extra">
    <div class="view-topbar">
        <div class="text">
            <p>
                2020 gick Bokio och Red Flag ihop som bolag.
            </p>
            <p>
                1:a december 2021 stängdes Red Flag tjänsten.
            </p>
        </div>
    </div>
</div>
