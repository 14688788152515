import {CardServiceClient} from '../../lab/service-client/card-service-client';
import {map} from 'rxjs/operators';
import {
    ActivateCardRequestV2,
    ActivationSignaturePayloadRequest,
    CARD_STATUS,
    Signature,
    UpdateCardRequest
} from '@red/data';
import {Observable} from 'rxjs';
import {ActivationSignatureResponseModel} from '../../shared/state/card/card.model';

export class CardManager {
    private _cardServiceClient: CardServiceClient;

    constructor(
        cardServiceClient: CardServiceClient
    ) {
        this._cardServiceClient = cardServiceClient;
    }

    static create(cardServiceClient: CardServiceClient) {
        return new CardManager(cardServiceClient);
    }

    listCards(companyId: string) {
        return this._cardServiceClient.listCards(companyId)
            .pipe(
                map(resp => resp.body.data)
            );
    }

    setStatus(cardId: string, cardStatus: CARD_STATUS) {
        const request = new UpdateCardRequest({
            openForInternetPurchases: true,
            openForAbroadPurchases: true,
            allowCashWithdrawal: false,
            cardStatus: cardStatus
        });

        return this._cardServiceClient.updateCard(cardId, request)
            .pipe(
                map(resp => resp.body)
            );
    }

    getSignaturePayload(cardId: string, lastFourDigits: string): Observable<ActivationSignatureResponseModel> {
        const request = new ActivationSignaturePayloadRequest({lastFourDigits});
        return this._cardServiceClient.getActivateCardPayload(cardId, request).pipe(
            map(resp => resp.body)
        );
    }

    activateCard(cardId: string, signature: Signature) {
        const request = new ActivateCardRequestV2({signature});
        return this._cardServiceClient.activateCard(cardId, request).pipe(
            map(resp => resp.body)
        );
    }
}
