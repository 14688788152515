import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {AuthCredentials, COUNTRY, ROLE} from '@red/data';
import {CookieService} from '../../../cookie/cookieService';
import {NationalIdentifierValidator} from '@red/validators';
import {BankIdLinking} from '../../../../linking/bankIdLinking';
import {Session} from '../../../../../../tmp-red-client/security/session';
import {LoginWorkflow} from '../../../../../../tmp-red-client/workflow/login';
import { BankIdAuthResponse } from '@red/browser';
import { AuthErrorResponse } from '../../../../../../tmp-red-client/security/auth/provider';
import {BankIdAuthResponseOld} from '../../../../../../tmp-red-client/security/auth/bankIdProvider';

const REMEMBER_ME_KEY = 'remembermyname';

interface RememberMyName {
    name: string;
    identity: string;
}

@Component({
    selector: 'app-red-login-form',
    styleUrls: ['form.sass'],
    templateUrl: 'form.tpl.html'
})
export class RedLoginFormComponent implements OnInit {
    @Input() scope: ROLE;

    @Output() emitLoginError: EventEmitter<any> = new EventEmitter();
    @Output() emitLoginSuccess: EventEmitter<Session> = new EventEmitter();
    @Output() emitSubmit: EventEmitter<any> = new EventEmitter();

    public loginForm: FormGroup;

    private _cookieService: CookieService;
    private _formBuilder: FormBuilder;
    private _firstTry: boolean;
    private _loginWorkflow: LoginWorkflow;
    private _rememberMyName: RememberMyName;
    private _submitted: boolean;
    private _userSign: boolean;
    private _waitingForBankIdSubscription: Subscription;

    constructor (
        cookieService: CookieService,
        formBuilder: FormBuilder,
        loginWorkflow: LoginWorkflow
    ) {
        this._cookieService = cookieService;
        this._formBuilder = formBuilder;
        this._loginWorkflow = loginWorkflow;

        this._firstTry = true;
        this._submitted = false;
        this._userSign = false;
    }

    get hasErrors(): boolean {
        const identityError = this.loginForm.hasError('required', ['identity']) || this.loginForm.hasError('minlength', ['identity']) || this.loginForm.hasError('malformatted', ['identity']);
        return !this.loginForm.valid && identityError && !this._firstTry;
    }

    get name(): string { return this._rememberMyName.name; }
    get rememberMe(): boolean { return !!this._rememberMyName.identity; }
    get userSign(): boolean {return this._userSign; }
    get submitted(): boolean { return this._submitted; }

    ngOnInit() {
        const stored = this._cookieService.get(REMEMBER_ME_KEY);

        this._rememberMyName = stored ? JSON.parse(stored) : {};

        this.loginForm = this._formBuilder.group({
            'identity': [this._rememberMyName.identity],
            'rememberMe': [!!this._rememberMyName.identity]
        });

        this._setIdentityValidator();

        this._loginWorkflow.onResponse.subscribe((resp) => {
            this._userSign = this._userSign || resp.result.status === 'USER_SIGN';
        });
    }

    hasError(errorCode: string, path: string) {
        return errorCode.split(' ').some((err) => this.loginForm.hasError(err, [path])) && !this._firstTry;
    }

    switchUser($event) {
        $event.preventDefault();

        this._rememberMyName = {'name': null, 'identity': null};
        this.loginForm = this._formBuilder.group({
            'identity': [''],
            'rememberMe': [false]
        });

        this._setIdentityValidator();
    }

    submit($event) {
        $event.preventDefault();
        this._submitted = true;
        this.emitSubmit.emit($event);

        const formValue = this.loginForm.value,
            identifier = NationalIdentifierValidator.format(formValue.identity.toString(), COUNTRY.SWEDEN),
            credentials = new AuthCredentials({'identifier': identifier, 'strategy': 'bankid'});

        if (this.scope) {
            credentials.scope = this.scope;
        }

        this._loginWorkflow.start(credentials)
            .subscribe((response: BankIdAuthResponseOld) => {
                this._launchBankId(response);
                this._startPolling(response);
            }, (err: AuthErrorResponse) => this._onBankIdError(err));
    }

    validateForm($event) {
        this._firstTry = false;

        if (!this.loginForm.valid) {
            $event.preventDefault();
        }
    }

    private _onBankIdError(err: any) {
        this.emitLoginError.emit(err);
        this._submitted = false;
    }

    private _onBankIdSuccess(session: Session) {
        const formValue = this.loginForm.value,
            identity = formValue.identity.toString(),
            rememberMe = formValue.rememberMe;

        this._saveRememberMe(rememberMe, {'identity': identity, 'name': session.user.details.firstName});
        this.emitLoginSuccess.emit(session);
    }

    private _launchBankId(response: BankIdAuthResponseOld) {
        const autoStartToken: string = response.result.autostartToken;
        const bankIdLinking = new BankIdLinking(`bankid://autostarttoken=${autoStartToken}&redirect=null`);

        bankIdLinking.tryAndOpen()
            .subscribe((startPolling: boolean) => {
                if (startPolling) {
                    this._startPolling(response);
                }
            });
    }

    private _saveRememberMe(remember: boolean, me: RememberMyName) {
        if (remember) {
            const expires = window['moment'](new Date()).add(1, 'y').format();
            this._cookieService.put(REMEMBER_ME_KEY, JSON.stringify(me), {'expires': expires});
        } else {
            this._cookieService.remove(REMEMBER_ME_KEY);
        }
    }

    private _startPolling(response: BankIdAuthResponseOld) {
        if (this._waitingForBankIdSubscription instanceof Subscription) {
            this._waitingForBankIdSubscription.unsubscribe();
        }

        this._waitingForBankIdSubscription = this._loginWorkflow.waitForBankId(response)
            .subscribe(
                (session: Session) => this._onBankIdSuccess(session),
                (err: AuthErrorResponse) => this._onBankIdError(err)
            );
    }

    private _setIdentityValidator() {
        this.loginForm.get('identity').setValidators([
            Validators.required,
            Validators.minLength(10),
            (c: AbstractControl) => NationalIdentifierValidator.validate(c.value, COUNTRY.SWEDEN) ? null : { 'malformatted': true }
        ]);
    }
}

