<mat-expansion-panel class="mat-expansion-panel {{statusClassName}}">
    <mat-expansion-panel-header>
        <mat-panel-title i18n="Onboarding|Panel title for BLV Form">
            BLV Form
        </mat-panel-title>
        <mat-panel-description></mat-panel-description>
    </mat-expansion-panel-header>
    <form [formGroup]="form" red-form>
        <fieldset>
            <legend i18n="Onboarding|LLC New blv form|Company info">Company info</legend>

            <ng-template #companyNameInfo>
                <p>
                    För att göra uppstarten av ditt aktiebolag så smidigt som möjligt ska du lämna tre olika namnförslag som särskiljer sig.
                </p>

                <h4>Samtliga namnförslag ska:</h4>
                <ul>
                    <li>fyllas i</li>
                    <li>vara unika</li>
                    <li>innehålla orden "AB", "aktiebolag" eller "aktiebolaget"</li>
                </ul>
                <p>
                    "Aktiebolag" och "aktiebolaget" kan skrivas med inledande stor bokstav, endast stora bokstäver eller endast små bokstäver.
                </p>
                <p>"AB" ska skrivas med stora bokstäver.</p>
                <p>Om "aktiebolaget" används kan det inte vara sist i namnet</p>
                <p>
                    <a href="https://bolagsverket.se/ff/foretagsformer/namn/foretag-1.4896" target="_blank">
                        Läs mer om hur granskningen hos Bolagsverket kommer att gå till
                    </a>
                </p>
            </ng-template>

            <app-read-more-less>
                <span class="short-text">
                    För att göra uppstarten av ditt aktiebolag så smidigt som möjligt ska du lämna tre olika namnförslag som särskiljer sig.
                </span>
                <div class="long-text">
                    <ng-container *ngTemplateOutlet="companyNameInfo"></ng-container>
                </div>
            </app-read-more-less>

            <ng-container formGroupName="nameSuggestions">
                <div class="red-row" *ngFor="let alias of blvFormNameSuggestions.controls; let i=index">
                    <mat-form-field>
                        <input
                            matInput
                            i18n-placeholder="Onboarding|LLC New blv form|Name suggestion {{i + 1}}"
                            placeholder="Name suggestion {{i + 1}}"
                            [formControlName]="i"
                            autocomplete="off"
                        />
                    </mat-form-field>
                </div>
                <div class="full-form-error-message" *ngIf="showNameValidationInfo && !form.disabled">
                    <ng-container *ngTemplateOutlet="companyNameInfo"></ng-container>
                </div>
            </ng-container>
        </fieldset>
        <fieldset>
            <legend i18n="Onboarding|LLC New blv form|Company address">Company address</legend>
            <red-form-address
                [addressFormGroup]="blvFormAddress"
                [showCountryCode]="false"
                [addRemoveAddressLines]="false"
            >
            </red-form-address>
        </fieldset>
        <div class="actions" *ngIf="!form.disabled">
            <button mat-flat-button color="primary" [disabled]="form.invalid" type="button" (click)="save($event)" i18n="Onboarding|Save button">Save</button>
            <button mat-flat-button color="primary" [disabled]="form.invalid" type="button" (click)="saveAndSend($event)" i18n="Onboarding|Save & Send button">Save & Send</button>
        </div>
    </form>
</mat-expansion-panel>
