import {QueryFilter} from '@red/browser';

export class QueryParam {
    static asString(filter: QueryFilter): string {
        const queryParams = filter && filter.params();

        if (queryParams && Object.keys(queryParams).length > 0) {
            return Object.keys(queryParams).reduce((acc, key) => {
                if (queryParams[key] !== undefined) {

                    if (Array.isArray(queryParams[key])) {

                        ((queryParams[key] as unknown) as string[]).forEach((value) => {
                            acc.push(`${key}=${value}`);
                        });
                    } else {
                        acc.push(`${key}=${queryParams[key]}`);
                    }
                }
                return acc;
            }, []).join('&');
        }

        return '';
    }

    static asFullQueryString(filter: QueryFilter): string {
        const query = QueryParam.asString(filter);

        return query ? `?${query}` : '';
    }

    static queryUrl(url: string, filter: QueryFilter): string {
        const query = QueryParam.asString(filter);
        return query
            ? `${url}?${query}`
            : url;
    }
}
