
import {throwError as observableThrowError, Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {KafkaLogger} from '../../common/error/global';
import {RemoteErrorException} from '../../common/error/remote-error-exception';

@Injectable()
export class ServerErrorHook implements HttpInterceptor {
    private _logger: KafkaLogger;

    constructor (
        logger: KafkaLogger
    ) {
        this._logger = logger;
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(catchError((err: any) => {
            if (err instanceof HttpErrorResponse) {
                const remoteErrorException = RemoteErrorException.fromHttpErrorResponse(err);
                if ((typeof remoteErrorException.errorCode !== 'number' || (remoteErrorException.errorCode <= 1500 && remoteErrorException.errorCode > 0)) && remoteErrorException.httpCode !== 404) {
                    const responseHeaders = err.headers.keys().reduce((headers, key) => {
                        headers[key] = err.headers.get(key);
                        return headers;
                    }, {});

                    if (remoteErrorException.isCancelled) {
                        this._logger.debug(remoteErrorException, {'httpRequestUrl': err.url, 'httpStatus': err.status, 'httpResponseHeaders': responseHeaders});
                    } else {
                        this._logger.warn(remoteErrorException, {'httpRequestUrl': err.url, 'httpStatus': err.status, 'httpResponseHeaders': responseHeaders});
                    }
                }

                return observableThrowError(remoteErrorException);
            }

            return observableThrowError(err);
        }));
    }
}
