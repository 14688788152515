import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Store} from '@ngxs/store';
import {OnboardingCheckStatus} from '../../shared/state/onboarding/onboarding.actions';

@Injectable()
export class OnboardingRouteResolve implements Resolve<boolean> {
    private _router: Router;
    private _store: Store;

    constructor(
        router: Router,
        store: Store
    ) {
        this._store = store;
        this._router = router;
    }

    resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
        return this._store.dispatch(new OnboardingCheckStatus())
            .pipe(
                map(() => {
                    return true;
                })
            );
    }
}
