<div class="red-dialog-title" matDialogTitle>
    <button mat-button (click)="close()" tabindex="-1">
        <mat-icon svgIcon="ui-close-white"></mat-icon>
    </button>
    <span *ngIf="!isCreditCardTransaction" class="red-dialog-title-text" i18n="Card Transaction|Header for edit card transaction">Card transaction</span>
    <span *ngIf="isCreditCardTransaction" class="red-dialog-title-text" i18n="Card Transaction|Header for refund card transaction">Refund card transaction</span>
    <red-context-menu *ngIf="cardTransaction.assetId">
        <button mat-menu-item>
            <red-asset-download-button [assetId]="cardTransaction.assetId" [isContextMenuItem]="true">
                <span i18n="Download receipt">Download receipt</span>
            </red-asset-download-button>
        </button>
    </red-context-menu>
</div>
<div class="red-dialog-content" matDialogContent [ngSwitch]="uploading">
    <red-loader-overlay *ngSwitchCase="true"><red-atom-loader></red-atom-loader></red-loader-overlay>
    <div *ngSwitchDefault>
        <red-info-box *ngIf="isRejected" redInfoBoxDialog>
            <red-markdown-content [markdownPath]="cardTransactionRejectMarkdown"></red-markdown-content>
        </red-info-box>
        <red-info-box *ngIf="!isRejected && isPending" redInfoBoxDialog>
            <span i18n="Info text|Card transaction|What to write in comment">Receipt is processing. Write what you have bought if it is not obvious. For representation - write names.</span>
        </red-info-box>
        <red-list redListDialog>
            <mat-list-item>
                <card-transaction-icon mat-list-avatar [cardTransaction]="cardTransaction"></card-transaction-icon>
                <h4 mat-line>{{cardTransaction.merchantName}}</h4>
                <time mat-line>{{cardTransaction.transactionTime | moment}}</time>
                <app-display-amount-text type="CARD_TRANSACTION" [amount]="cardTransaction.amount" [direction]="cardTransaction.type">
                    <span *ngIf="showTransactionAmount" class="forex-amount" [innerHTML]="cardTransaction.transactionAmount | amount"></span>
                </app-display-amount-text>
            </mat-list-item>
        </red-list>
        <form *ngIf="showCommentForm" [formGroup]="commentForm" red-form>
            <fieldset>
                <div class="red-row dialog-view">
                    <mat-form-field>
                        <input
                            matInput
                            i18n-placeholder="Comment to RedFlag"
                            placeholder="Comment to RedFlag"
                            formControlName="message"
                            autocomplete="off"
                        />
                    </mat-form-field>
                </div>
            </fieldset>
        </form>
        <div class="asset-container" *ngIf="cardTransaction.assetId">
            <red-asset-view [assetId]="cardTransaction.assetId" [preview]="isCreditCardTransaction || hasCredit"></red-asset-view>
        </div>
    </div>
    <red-list redListDialog *ngIf="hasCredit">
        <span redListHeader i18n="List header| credited by card transactions">Refund</span>
        <mat-list-item *ngFor="let credit of creditedBy" redListLink (click)="navigateToCardTransaction(credit.transactionId)">
            <mat-icon mat-list-avatar class="icon-list" svgIcon="list-card"></mat-icon>
            <h4 mat-line>{{credit.merchantName}}</h4>
            <time mat-line>{{credit.transactionTime | moment}}</time>
            <app-display-amount-text type="CARD_TRANSACTION" [amount]="credit.creditedAmount" [direction]="'CREDIT'"></app-display-amount-text>
        </mat-list-item>
    </red-list>
    <red-list redListDialog *ngIf="creditingCardTransaction">
        <span redListHeader i18n="List header| original card transactions">Original</span>
        <mat-list-item redListLink (click)="navigateToCardTransaction(creditingCardTransaction.id)">
            <card-transaction-icon mat-list-avatar [cardTransaction]="creditingCardTransaction"></card-transaction-icon>
            <h4 mat-line>{{creditingCardTransaction.merchantName}}</h4>
            <time mat-line>{{creditingCardTransaction.transactionTime | moment}}</time>
            <app-display-amount-text type="CARD_TRANSACTION" [amount]="creditingCardTransaction.amount" [direction]="creditingCardTransaction.type"></app-display-amount-text>
        </mat-list-item>
    </red-list>
</div>

<ng-template #readOnlyActions>
    <mat-dialog-actions class="red-dialog-actions">
        <button type="button" mat-flat-button (click)="close()" i18n="Button|Dialog close">OK</button>
    </mat-dialog-actions>
</ng-template>
<ng-container *ngIf="notReadOnlyState; else readOnlyActions">
    <mat-dialog-actions class="red-dialog-actions" [ngSwitch]="isRejected">
        <button type="button" mat-flat-button *ngSwitchCase="false" (click)="close()" i18n="Button|Dialog close">OK</button>
        <button type="button" mat-button *ngSwitchCase="true" (click)="markAsDebt($event)" i18n="Button|Resolve rejected card transaction as debt">Mark as debt?</button>
        <button *ngSwitchCase="true"
                type="button"
                mat-flat-button
                redAssetUploadTrigger
                (fileSelect)="uploadNewImage($event)"
                i18n="Button|Upload a new image for rejected card transaction"
        >Upload new image</button>
    </mat-dialog-actions>
</ng-container>
