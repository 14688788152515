import {Observable, of} from 'rxjs';
import {ServerError} from '@red/common';

export enum AUTHORIZED_STATE {
    AUTHORIZED,
    UNAUTHORIZED
}

export class AuthResponse {
    state: AUTHORIZED_STATE;
    result: any;

    constructor(state: AUTHORIZED_STATE, result?: any) {
        this.state = state;
        this.result = result;
    }
}

export class AuthErrorResponse extends AuthResponse {
    error: ServerError;

    constructor(state: AUTHORIZED_STATE, err: any) {
        super(state);
        this.error = err instanceof ServerError ? err : new ServerError(err);
    }
}

export abstract class AuthProvider {
    state: AUTHORIZED_STATE;

    get isAuthorized(): boolean {
        return this.state === AUTHORIZED_STATE.AUTHORIZED;
    }

    authenticate(...args: any[]): Observable<AuthResponse> {
        return of(this.onAuthSuccess());
    }

    heartbeat(): Observable<AuthResponse> {
        return of(this.onAuthSuccess());
    }

    signOut(): Observable<AuthResponse> {
        return of(this.onSignOutSuccess());
    }

    protected onAuthError(err?: string | Error): AuthErrorResponse {
        this.state = AUTHORIZED_STATE.UNAUTHORIZED;
        return new AuthErrorResponse(this.state, err);
    }

    protected onAuthSuccess(result?: string | {}): AuthResponse {
        this.state = AUTHORIZED_STATE.AUTHORIZED;
        return new AuthResponse(this.state, result);
    }

    protected onSignOutError(err?: string | Error): AuthErrorResponse {
        return new AuthErrorResponse(this.state, err);
    }

    protected onSignOutSuccess(result?: string | {}): AuthResponse {
        this.state = AUTHORIZED_STATE.UNAUTHORIZED;
        return new AuthResponse(this.state, result);
    }
}
