import {QueryFilter, QueryResponse, WebServiceClient} from '@red/browser';
import {Observable} from 'rxjs';
import {Transaction, TransactionListResponse} from '@red/data';
import {HttpFetch} from '@red/common';
import {QueryParam} from '../../common/query-param';
import {AppConfig} from '../../config/appConfig';
import {map} from 'rxjs/operators';

export class TransactionServiceClient extends WebServiceClient {
    static create(transport: HttpFetch, config: AppConfig) {
        return new TransactionServiceClient(transport, config);
    }

    list(filter: QueryFilter): Observable<TransactionListResponse> {
        const query = QueryParam.asFullQueryString(filter);
        const url = `${this._getBaseUrl()}/api/transaction${query}`;

        return this._get<TransactionListResponse>(url, {}, TransactionListResponse);
    }

    query(companyId: string, filter?: QueryFilter): Observable<TransactionListResponse> {
        const query = QueryParam.asFullQueryString(filter);
        const url = `${this._getBaseUrl()}/api/transaction/${companyId}${query}`;

        return this._get<TransactionListResponse>(url, {}, TransactionListResponse);
    }
}
