import {CommentServiceClient} from '../../lab/service-client/comment-service-client';
import {
    COMMENT_CONTEXT,
    Content,
    CreateCommentRequest,
    Reference,
    REFERENCE_TYPE,
    CommentListResponse, EditCommentRequest
} from '@red/data';
import {Observable} from 'rxjs';
import {QueryFilter} from '@red/browser';
import {mergeMap} from 'rxjs/operators';
import {Store} from '@ngxs/store';
import {AuthState} from '../../shared/state/auth/auth.state';

export class CommentManager {
    private _commentServiceClient: CommentServiceClient;
    private _store: Store;

    constructor (
        commentServiceClient: CommentServiceClient,
        store: Store
    ) {
        this._commentServiceClient = commentServiceClient;
        this._store = store;
    }

    static create(commentServiceClient: CommentServiceClient, store: Store) {
        return new CommentManager(commentServiceClient, store);
    }

    createComment(type: REFERENCE_TYPE, id: string, message: string): Observable<CommentListResponse> {
        const request = this._createCommentRequest(message);
        return this._commentServiceClient.createComment(type, id, request)
            .pipe(mergeMap(() => this.getComments(type, id)));
    }

    editComment(type: REFERENCE_TYPE, entityId: string, commentId: string, message = ''): Observable<CommentListResponse> {
        const request = new EditCommentRequest({
            'content': new Content({
                'payload': message.trim()
            })
        });

        return this._commentServiceClient.editComment(type, entityId, commentId, request)
            .pipe(mergeMap(() => this.getComments(type, entityId)));
    }

    getComments(type: REFERENCE_TYPE, id: string): Observable<CommentListResponse> {
        const filter = new QueryFilter();
        filter.equal('context', COMMENT_CONTEXT.USERSPACE);

        return this._commentServiceClient.getComments(type, id, filter);
    }

    private _createCommentRequest(message: string) {
        return new CreateCommentRequest({
            'author': new Reference({
                'id': this._store.selectSnapshot(AuthState.userId),
                'type': REFERENCE_TYPE.USER
            }),
            'content': new Content({
                'payload': message
            }),
            'context': COMMENT_CONTEXT.USERSPACE
        });
    }
}
