import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {OnboardingSignUpViewComponent} from './onboarding-sign-up';
import {ONBOARDING_SIGN_UP_ROUTES} from './onboarding-sign-up.routes';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {RedFormModule, RedInfoBoxModule, RedLoaderModule} from '@red/components';
import {MatInputModule} from '@angular/material/input';
import {ReactiveFormsModule} from '@angular/forms';

@NgModule({
    declarations: [
        OnboardingSignUpViewComponent
    ],
    imports: [
        CommonModule,
        RouterModule.forRoot(ONBOARDING_SIGN_UP_ROUTES, { relativeLinkResolution: 'legacy' }),
        MatCardModule,
        MatButtonModule,
        RedFormModule,
        MatInputModule,
        ReactiveFormsModule,
        RedLoaderModule,
        RedInfoBoxModule
    ]
})
export class OnboardingSignUpModule {}
