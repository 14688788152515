import {
    Company,
    CompanyUser, CompanyUserListResponse,
    CreateUserRequest,
    FirstEmployeePermitRequest,
    Relation,
    RelationListResponse,
    ROLE,
    UpdateUserRequest,
    User, UserDetails
} from '@red/data';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {of} from 'rxjs';
import {forkJoin} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {Store} from '@ngxs/store';
import {AuthState} from '../../shared/state/auth/auth.state';
import {DirectoryServiceClient} from '../../lab/service-client/directory-service-client';

export class UserManager {
    private _directoryServiceClient: DirectoryServiceClient;
    private _store: Store;

    constructor (
        store: Store,
        directoryServiceClient: DirectoryServiceClient,
    ) {
        this._directoryServiceClient = directoryServiceClient;
        this._store = store;
    }

    static create(
        store: Store,
        directoryServiceClient: DirectoryServiceClient,
    ) {
        return new UserManager(store, directoryServiceClient);
    }

    getUser(userId: string): Observable<User> {
        return this._directoryServiceClient.getUser(userId)
            .pipe(map((user: User) => {
                return new User(user);
            }));
    }

    getCompanyAndCompanyUsers(companyId: string) {
        return forkJoin([
                this._directoryServiceClient.getCompanyById(companyId),
                this._directoryServiceClient.getCompanyUsers(companyId)
            ]
        ).pipe(map(([company, companyUserListResponse]: [Company, CompanyUserListResponse]) => {
            const companyUsers = companyUserListResponse.data || [];
            companyUsers.sort(this._ownerFirst);

            return [company, companyUsers];
        }));
    }

    getUserByIdentifier(identifier: string): Observable<User> {
        return this._directoryServiceClient.getUserByIdentifier(identifier)
            .pipe(map((user: User) => {
                if (user) {
                    const rawUser = this._getRaw(user);
                    user.details = this._getUserDetails(rawUser);
                }

                return user;
            }));
    }

    createUser(createUserRequest: CreateUserRequest): Observable<User> {
        return this._directoryServiceClient.createUser(createUserRequest)
            .pipe(map((user: User) => user));
    }

    updateUser(user: User, updateUserRequest: UpdateUserRequest): Observable<User> {
        return this._directoryServiceClient.updateUser(user.id, updateUserRequest);
    }

    getCreateUserRequest(details: UserDetails, role: ROLE): CreateUserRequest {
        return new CreateUserRequest({
            identification: details.identification,
            details: details,
            relations: [new Relation({
                role: role,
                company: {
                    id: this._store.selectSnapshot(AuthState.companyId)
                }
            })]
        });
    }

    createRelation(userRequest: CreateUserRequest): Observable<User> {
        const relation = userRequest.relations.shift();

        return this._directoryServiceClient
            .createRelation(relation.company.id, userRequest.identification, <ROLE>relation.role)
            .pipe(mergeMap(() => this.getUserByIdentifier(userRequest.identification)));
    }

    firstEmployeePermit(user: User, details: any): Observable<User> {
        if (!details.firstEmployeeSupport) {
            return of(user);
        }

        const firstEmployeePermitRequest = this._createFirstEmployeePermitRequest(user, details);

        return this._directoryServiceClient
            .setFirstEmployeePermit(this._store.selectSnapshot(AuthState.companyId), firstEmployeePermitRequest)
            .pipe(map(() => user));
    }

    getUserRelation(userId: string): Observable<RelationListResponse> {
        return this._directoryServiceClient.getUserRelation(userId)
            .pipe(map((relationListResponse: RelationListResponse) => {
                return relationListResponse;
            }));
    }

    deleteCompanyRelation(companyId: string, relationId: string) {
        return this._directoryServiceClient.deleteCompanyRelation(companyId, relationId);
    }

    getUserRelationsForCompany(companyId: string, companyUser: CompanyUser): Relation[] {
        const relations = <Relation[]>[];

        companyUser.relations.forEach(relation => {
            if (companyId === relation.company.id) {
                relations.push(relation);
            }
        });

        return relations.sort(a => a.role === ROLE.OWNER ? -1 : 1 );
    }

    private _getRaw(obj: any): any {
        return JSON.parse(JSON.stringify(obj));
    }

    private _getUserDetails(user: User): UserDetails {
        const defaultDetails = {
            mainAccount: {
                clearingNo: '',
                accountNo: ''
            }
        };

        return Object.assign(defaultDetails, user.details);
    }

    private _ownerFirst(a) {
        const owner = a.relations.filter(relation => {
            return relation.role === ROLE.OWNER;
        }).shift();

        return owner ? -1 : 1;
    }

    private _createFirstEmployeePermitRequest(user: User, details: any) {
        const firstEmployeePermitRequest = new FirstEmployeePermitRequest();
        firstEmployeePermitRequest.employeeId = user.id;

        if (details.previousSupport) {
            firstEmployeePermitRequest.notes = details.listOfSupports;
        } else {
            // This information goes in to the Employee Taxation report that goes to SKV.
            // Probably should be moved to backend.
            firstEmployeePermitRequest.notes = 'Företaget har inte fått några andra de minimis-stöd';
        }

        return firstEmployeePermitRequest;
    }
}
