import {Component, Input} from '@angular/core';
import {RedDialog} from '@red/components';
import {EndOfYearDialogComponent} from '../end-of-year.dialog';
import { MatDialogRef } from '@angular/material/dialog';
import {CompanyYearEndStep, YEAR_END_STEP} from '@red/data';
import {END_OF_YEAR_FLOW_STATE, EndOfYearManager} from '../../../managers/end-of-year/end-of-year.manager';

@Component({
    selector: 'app-end-of-year-dialog-trigger',
    styleUrls: ['../../../managers/end-of-year/end-of-year-general.sass'],
    templateUrl: './end-of-year-dialog-trigger.tpl.html'
})
export class EndOfYearDialogTriggerComponent {
    @Input() endOfYearStart: string;
    @Input() endOfYearStep: CompanyYearEndStep;

    get year(): string { return this._endOfYearManager.currentFiscalYear(); }

    get currentStep(): YEAR_END_STEP { return this.endOfYearStep.step; }

    get flowStateWarmup(): boolean { return this._endOfYearManager.endOfYearFlowState(this.endOfYearStep.step) === END_OF_YEAR_FLOW_STATE.WARMUP; }
    get flowStateInProcess(): boolean { return this._endOfYearManager.endOfYearFlowState(this.endOfYearStep.step) === END_OF_YEAR_FLOW_STATE.IN_PROCESS; }
    get flowStateSoleDone(): boolean { return this._endOfYearManager.endOfYearFlowState(this.endOfYearStep.step) === END_OF_YEAR_FLOW_STATE.SOLE_DONE; }
    get flowStateInDividendsProcess(): boolean { return this._endOfYearManager.endOfYearFlowState(this.endOfYearStep.step) === END_OF_YEAR_FLOW_STATE.IN_DIVIDENDS_PROCESS; }
    get flowStateLLCDone(): boolean { return this._endOfYearManager.endOfYearFlowState(this.endOfYearStep.step) === END_OF_YEAR_FLOW_STATE.LLC_DONE; }
    get flowStateExit(): boolean { return this._endOfYearManager.endOfYearFlowState(this.endOfYearStep.step) === END_OF_YEAR_FLOW_STATE.EXIT; }

    private _dialog: RedDialog;
    private _dialogRef: MatDialogRef<EndOfYearDialogComponent>;
    private _endOfYearManager: EndOfYearManager;

    constructor(
        dialog: RedDialog,
        endOfYearManager: EndOfYearManager
    ) {
        this._dialog = dialog;
        this._endOfYearManager = endOfYearManager;
    }

    openEndOfYearDialog() {
        this._dialogRef = this._dialog.open(EndOfYearDialogComponent);
    }
}
