import {
    ROYALTY_STATEMENT_STATUS,
    RoyaltyStatement,
    RoyaltyStatementListResponse, RoyaltyStatementUpdateRequest, UpdateRoyaltyStatementAmountRequest,
    UpdateRoyaltyStatementStatusRequest
} from '@red/data';
import {QueryFilter, WebServiceClient} from '@red/browser';
import {Observable} from 'rxjs';
import {HttpFetch} from '@red/common';
import {QueryParam} from '../../common/query-param';
import { AppConfig } from '../../config/appConfig';
import {map} from 'rxjs/operators';
import {HTTP_METHODS, TransportOptions} from '../../../tmp-red-client/transport/base';


export class RoyaltyServiceClient extends WebServiceClient {
    static create(transport: HttpFetch, config: AppConfig) {
        return new RoyaltyServiceClient(transport, config);
    }

    getRoyaltyStatement(royaltyId: string): Observable<RoyaltyStatement> {
        return this._get<RoyaltyStatement>(`${this._getBaseUrl()}/api/royalty/statement/${royaltyId}`, {}, RoyaltyStatement);
    }

    listRoyaltiesByCompany(companyId: string, filter?: QueryFilter): Observable<RoyaltyStatementListResponse> {
        const query = filter ? `?${QueryParam.asString(filter)}` : '';
        return this._get<RoyaltyStatementListResponse>(`${this._getBaseUrl()}/api/royalty/statement/company/${companyId}${query}`, {}, RoyaltyStatementListResponse);
    }

    listRoyaltiesByStatus(status: ROYALTY_STATEMENT_STATUS, filter?: QueryFilter): Observable<RoyaltyStatementListResponse> {
        const query = filter ? `?${QueryParam.asString(filter)}` : '';
        return this._get<RoyaltyStatementListResponse>(`${this._getBaseUrl()}/api/royalty/statement/status/${status}${query}`, {}, RoyaltyStatementListResponse);
    }

    updateRoyaltyAmount(royaltyId: string, request: UpdateRoyaltyStatementAmountRequest) {
        return this._put<RoyaltyStatement>(`${this._getBaseUrl()}/api/royalty/statement/${royaltyId}/amounts`, request, {}, RoyaltyStatement);
    }

    updateRoyaltyStatus(royaltyId: string, request: UpdateRoyaltyStatementStatusRequest) {
        return this._put<RoyaltyStatement>(`${this._getBaseUrl()}/api/royalty/statement/${royaltyId}/status`, request, {}, RoyaltyStatement);
    }

    updateRoyaltyOriginAndDate(royaltyId: string, request: RoyaltyStatementUpdateRequest): Observable<RoyaltyStatement> {
        return this._put<RoyaltyStatement>(`${this._getBaseUrl()}/api/royalty/statement/${royaltyId}`, request, {}, RoyaltyStatement);
    }

    setStatus(royaltyStatement: RoyaltyStatement, request: UpdateRoyaltyStatementStatusRequest): Observable<RoyaltyStatement> {
        return this._put<RoyaltyStatement>(`${this._getBaseUrl()}/api/royalty/statement/${royaltyStatement.id}/status`, request, {}, RoyaltyStatement);
    }

    replaceAsset(royaltyStatement: RoyaltyStatement, data: FormData): Observable<RoyaltyStatement> {
        const options = {
            headers: {'content-type': 'multipart/form-data'}
        };

        return this._put<RoyaltyStatement>(`${this._getBaseUrl()}/api/royalty/statement/${royaltyStatement.id}/asset`, data, options, RoyaltyStatement);
    }

    create(data: FormData): Observable<RoyaltyStatement> {
        const options = {
            headers: {'content-type': 'multipart/form-data'}
        };

        return this._post<RoyaltyStatement>(`${this._getBaseUrl()}/api/royalty/statement`, data, options, RoyaltyStatement);
    }
}
