import {Component, HostBinding, OnInit, ViewEncapsulation} from '@angular/core';
import {Store} from '@ngxs/store';
import {CompanyManager} from '../../managers/company/company.manager';
import {AuthState} from '../../shared/state/auth/auth.state';
import {AssetsUploadDetails, ASSETUPLOADSTATUS, Company, DownloadableReportResponse, REPORT_TYPE} from '@red/data';
import {ReportManager} from '../../managers/report/report.manager';
import {BlobLink} from '../../common/blobLink';
import * as moment from 'moment';
import {RedFlagShutDownManager} from '../../managers/red-flag-shut-down/red-flag-shut-down.manager';
import {RedAssetManager, RedNotification } from '@red/components';

@Component ({
    encapsulation: ViewEncapsulation.None,
    selector: 'app-downloadable-reports',
    styleUrls: ['downloadable-reports.sass'],
    templateUrl: 'downloadable-reports.tpl.html'
})
export class DownloadableReportsViewComponent implements OnInit {
    @HostBinding('class.scroll-view') cssClass = true;

    get company(): Company { return this._company; }
    get beingDownloaded(): string[] { return this._beingDownloaded; }
    get downloadingAccountStatement(): boolean {
        return this._downloadingAccountStatement;
    }
    get googleDriveLink(): string {
        return this._googleDriveLink;
    }

    private _store: Store;
    private _companyManager: CompanyManager;
    private _company: Company = null;
    private _beingDownloaded: string[] = [];
    private _notification: RedNotification;
    private _assetManager: RedAssetManager;
    private _reportManager: ReportManager;
    private _downloadingAccountStatement = false;
    private _shutDownManager: RedFlagShutDownManager;
    private _googleDriveLink: string;

    constructor (
        store: Store,
        companyManager: CompanyManager,
        notification: RedNotification,
        assetManager: RedAssetManager,
        reportManager: ReportManager,
        shutDownManager: RedFlagShutDownManager
    ) {
        this._store = store;
        this._companyManager = companyManager;
        this._notification = notification;
        this._assetManager = assetManager;
        this._reportManager = reportManager;
        this._shutDownManager = shutDownManager;
    }

    ngOnInit(): void {
        this._companyManager.getCompany(this._store.selectSnapshot(AuthState.companyId))
            .subscribe((company: Company) => {
                this._company = company;

                this._shutDownManager.checkUploadOfAssetsToGoogleDrive(this._company.id)
                    .subscribe((details: AssetsUploadDetails) => {
                        const failedAssets = details.failedAssets && details.failedAssets.length;

                        if (details.assetUploadStatus === ASSETUPLOADSTATUS.UPLOAD_DONE && !failedAssets) {
                            this._googleDriveLink = details.googleDriveFolderUrl;
                        }
                    });
            });
    }

    downloadReport($event): void {
        this._beingDownloaded.push($event.type);

        this._reportManager.downloadReport(
            $event.companyId,
            $event.type,
            $event.startDate,
            $event.endDate
        ).subscribe((resp: DownloadableReportResponse) => {
            this._getAsset(resp.assetId, $event.type);
        }, () => {
            const errorMessage = $localize`:Downloadable reports error|Download error message:Downloadable reports error`;

            this._beingDownloaded = this._beingDownloaded.slice().filter((type) =>  {
                return type !== $event.type;
            })

            this._notification.open(errorMessage);
        });
    }

    downloadAccountStatement(): void {
        this._downloadingAccountStatement = true;

        const startDate = this._companyManager.getFirstFiscalYearStartDate(this._company);
        const endDate = this._company.details.fiscalInformation.currentFiscalYear.end;

        this._reportManager
            .downloadExcel(this._company.id, startDate, endDate)
            .subscribe((respBlob: Blob) => {
                this._downloadExcel(respBlob, this._company.name, startDate, endDate);
            }, (err) => {
                this._notification.errorWithCode(err, 'Det gick inte att ladda ner kontoutdraget');
                this._downloadingAccountStatement = false;
            }, () => {
                this._downloadingAccountStatement = false;
            });
    }

    private _formatDateForFileName(date: string): string {
        return moment(date).format('YYYY-MM-DD');
    }

    private _downloadExcel(respBlob: Blob, companyName: string, startDate: string, endDate: string): void {
        const blob = new Blob([respBlob], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        const filename = `${companyName.replace(/\s/g, '-')}--account-statement--${this._formatDateForFileName(startDate)}--${this._formatDateForFileName(endDate)}.xls`;

        this._blobLink(blob, filename);
    }

    private _blobLink(blob: Blob, filename: string): void {
        BlobLink.download(blob, filename);
    }

    private _getAsset(assetId: string, type: string) {
        this._assetManager.downloadAsset(assetId);

        const iv = setInterval(() => {
            if (!this._assetManager.beeingDownloaded(assetId)) {
                clearInterval(iv);

                this._beingDownloaded = this._beingDownloaded.slice().filter((reportType) => {
                    return reportType !== type;
                });
            }
        }, 500);
    }
}
