import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import {COMPANY_TYPE} from '@red/data';
import {AuthState} from '../../shared/state/auth/auth.state';
import {Store} from '@ngxs/store';

@Injectable()
export class SoleTraderGuard implements CanActivate {
    private _router: Router;
    private _store: Store;

    constructor(
        router: Router,
        store: Store
    ) {
        this._router = router;
        this._store = store;
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const isSoleTrader = this._store.selectSnapshot(AuthState.companyType) === COMPANY_TYPE.SOLE_TRADER;
        if (isSoleTrader) {
            return of(true);
        } else {
            this._router.navigate(['/dashboard']);
            return of(false);
        }
    }
}
