import {NgModule} from '@angular/core';
import {ReportCardComponent} from './report-card/report-card';
import {CommonModule} from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import {ReactiveFormsModule} from '@angular/forms';
import {RedDialogModule} from '@red/components';

@NgModule({
    declarations: [
        ReportCardComponent
    ],
    exports: [
        ReportCardComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatDialogModule,
        MatSelectModule,
        RedDialogModule
    ],
    providers: []
})
export class CardModule {}
