<app-view-card>
    <red-top-toolbar>
        <span redTopToolbarTitle i18n="Reports|Main title for end of year reports view">End of year reports</span>
    </red-top-toolbar>
    <app-view-card-infobar class="view-card-infobar" *ngIf="isInEndOfYearProcess && !isStepBooksClosed && notReadOnlyState">
        <app-end-of-year-dialog-trigger
            [endOfYearStart]="endOfYearStart"
            [endOfYearStep]="endOfYearStep">
        </app-end-of-year-dialog-trigger>
    </app-view-card-infobar>
    <app-view-card-infobar class="view-card-infobar">
        <app-red-flag-shut-down-banner></app-red-flag-shut-down-banner>
    </app-view-card-infobar>
    <div class="reports-view" *ngIf="hasReports; else noReportsTpl">
        <ng-container *ngFor="let flow of yearEndFlows; let i = index">
            <red-list>
                <span redListHeader i18n="End of year|reports header">Documents {{getYearEndDate(flow)}}</span>
                <mat-list-item *ngFor="let report of reports(flow)">
                    <span mat-line>{{getReportName(report)}}</span>
                    <red-asset-download-button class="report-download-btn" align="end" [assetId]="report.assetId">
                        <span i18n="download report">Download</span>
                    </red-asset-download-button>
                </mat-list-item>
                <mat-list-item *ngIf="showTotalTaxPaid(flow)" data-type="total-tax-paid">
                    <span mat-line i18n="Paid tax">Paid tax</span>
                    <span align="end" [innerHTML]="flow.metadata.totalTaxPaid | amount"></span>
                </mat-list-item>
            </red-list>
        </ng-container>

        <div red-grid class="report-links" *ngIf="isSoleTrader">
            <div class="red-row">
                <a href="{{neArticleSoleLink}}" target="_blank" class="red-col ne-link" i18n="End of year|ne bilaga link description">About the NE bilaga</a>
            </div>
        </div>
    </div>
    <ng-template #noReportsTpl>
        <app-blank-slate-2020 [typeClass]="'report'">
            <header i18n="Report|blank slate|header">Report</header>
            <p i18n="No reports heading">No reports.</p>
        </app-blank-slate-2020>
    </ng-template>
</app-view-card>
