import {ERROR_CODE} from '@red/common';
import {HttpErrorResponse} from '@angular/common/http';
import {ErrorMessage, ErrorMessageMapper} from '@red/browser';

export class ErrorI18n extends ErrorMessageMapper {
    constructor () {
        super();
        this.compileErrorMessages();
    }


    translate(error: Error | ErrorMessage | HttpErrorResponse, fallbackMessage?: string): any {
        if (error instanceof HttpErrorResponse) {
            error = error.error;
        }
        const msg = super.translate(error, fallbackMessage);

        return msg || fallbackMessage;
    }

    compileErrorMessages() {
        this._messages = {};
        this._messages[`err_${ERROR_CODE.UNAUTHORIZED}`] = $localize`:Server Error|401:Unauthorized`;
        this._messages[`err_${ERROR_CODE.FORBIDDEN}`] = $localize`:Server Error|403:Forbidden`;
        this._messages[`err_${ERROR_CODE.NOT_FOUND}`] = $localize`:Server Error|404:Not Found`;
        this._messages[`err_${ERROR_CODE.INTERNAL_SERVER_ERROR}`] = $localize`:Server Error|500:Internal server error`;
        this._messages[`err_${ERROR_CODE.GENERAL_ERROR}`] = $localize`:Server Error|500:Unspecified error`;

        this._messages[`err_${ERROR_CODE.MISSING_BANK_DETAILS}`] = $localize`:Bank Details Error|User is missing correct bank details:You need to enter you bank details. Please enter these under settings.`;
        this._messages[`err_${ERROR_CODE.MISSING_BANK_DETAILS_SALARY}`] = $localize`:Bank Details Error|User is missing correct bank details:You need to enter you bank details. Please enter these under settings.`;

        this._messages[`err_${ERROR_CODE.INVALID_USER_IDENTIFIER}`] = $localize`:Logging in Error|incorrect identifier:Enter a correct identifier`;

        this._messages[`err_${ERROR_CODE.REGISTRATION_INVALID_FIELD}`] = $localize`:Validation Error|Email or phone:You have entered a invalid email or phone number`;

        this._messages[`err_${ERROR_CODE.INVOICE_CURRENCY_MISMATCH}`] = $localize`:Invoice currency mismatch Error|Invoice currency mismatch:Failed to save invoice due to currency mismatch`;

        this._messages[`err_${ERROR_CODE.SESSION_INVALID}`] = $localize`:Session Error|Users session has expired:Your session has expired`;

        this._messages[`err_${ERROR_CODE.ACCESS_DENIED}`] = $localize`:Auth Error|:Access Denied`;
    }
}
