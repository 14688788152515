import {Component, OnInit} from '@angular/core';
import {RedDialog, RedNotification} from '@red/components';
import {REFERENCE_TYPE, Transaction, TRANSACTION_STATUS} from '@red/data';
import {QueryFilter, QueryResponse, LIST_STATUS, ListDataSource} from '@red/browser';
import {forkJoin} from 'rxjs';
import {RoyaltyStatementEventReader} from '../../../common/event/readers/royalty-statement';
import {AuthState} from '../../../shared/state/auth/auth.state';
import {Store} from '@ngxs/store';
import { TransactionServiceClient } from '../../../lab/service-client/transaction-service-client';
import {TransactionManager} from '../../../managers/transaction/transaction.manager';
import {RedFlagShutDownManager} from '../../../managers/red-flag-shut-down/red-flag-shut-down.manager';

@Component({
    selector: 'app-royalty-statement-list',
    templateUrl: './list.tpl.html'
})
export class IncomeStreamListViewComponent implements OnInit {
    get royaltyStatementsPaid(): ListDataSource<Transaction> { return this._royaltyStatementsPaid; }
    get royaltyStatementsRejected(): ListDataSource<Transaction> { return this._royaltyStatementsRejected; }
    get royaltyStatementsWaitingForPayment(): ListDataSource<Transaction> { return this._royaltyStatementsWaitingForPayment; }
    get state(): LIST_STATUS { return this._state; }
    get stateDone(): LIST_STATUS { return LIST_STATUS.DONE; }
    get stateLoading(): LIST_STATUS { return LIST_STATUS.LOADING; }
    get stateNoResult(): LIST_STATUS { return LIST_STATUS.NO_RESULT; }
    get notReadOnlyState(): boolean { return this._notReadOnlyState; }

    private _royaltyStatementEventReader: RoyaltyStatementEventReader;
    private _royaltyStatementsPaid: ListDataSource<Transaction>;
    private _royaltyStatementsRejected: ListDataSource<Transaction>;
    private _royaltyStatementsWaitingForPayment: ListDataSource<Transaction>;
    private _notification: RedNotification;
    private _redDialog: RedDialog;
    private _store: Store;
    private _state: LIST_STATUS = LIST_STATUS.LOADING;
    private _transactionManager: TransactionManager;
    private _redFlagShutDownManager: RedFlagShutDownManager;
    private _notReadOnlyState: boolean;

    constructor (
        notification: RedNotification,
        redDialog: RedDialog,
        royaltyStatementEventReader: RoyaltyStatementEventReader,
        store: Store,
        transactionManager: TransactionManager,
        redFlagShutDownManager: RedFlagShutDownManager
    ) {
        this._notification = notification;
        this._redDialog = redDialog;
        this._royaltyStatementEventReader = royaltyStatementEventReader;
        this._store = store;
        this._transactionManager = transactionManager;
        this._redFlagShutDownManager = redFlagShutDownManager;
    }

    ngOnInit() {
        const today = this._redFlagShutDownManager.today;
        this._notReadOnlyState = this._redFlagShutDownManager.notReadOnlyState(today);

        this._royaltyStatementsPaid = new ListDataSource<Transaction>((filter: QueryFilter) => {
            return this._transactionManager.queryToQueryResponse(this._store.selectSnapshot(AuthState.companyId), filter);
        });

        this._royaltyStatementsRejected = new ListDataSource<Transaction>((filter: QueryFilter) => {
            return this._transactionManager.queryToQueryResponse(this._store.selectSnapshot(AuthState.companyId), filter);
        });

        this._royaltyStatementsWaitingForPayment = new ListDataSource<Transaction>((filter: QueryFilter) => {
            return this._transactionManager.queryToQueryResponse(this._store.selectSnapshot(AuthState.companyId), filter);
        });

        this._loadTransactions();

        this._royaltyStatementEventReader.onCreated(() => {
            this._loadTransactions();
        });

        this._royaltyStatementEventReader.onUpdated(() => {
            this._loadTransactions();
        });
    }

    getDescription(royalty: Transaction) {
        let description;
        if (royalty.description === 'Unknown') {
            description = $localize`:Royalty|List label:Income`;
        } else {
            description = royalty.description;
        }
        return description;
    }

    loadMore(dataSource: ListDataSource<Transaction>) {
        dataSource.next().subscribe(() => {});
    }


    private _loadTransactions() {
        this._state = LIST_STATUS.LOADING;

        const royaltyStatementsPaidFilter = new QueryFilter()
            .equal('status', [TRANSACTION_STATUS.COMPLETE])
            .equal('type', [REFERENCE_TYPE.ROYALTY_STATEMENT])
            .equal('orderBy', 'CREATED_AT')
            .equal('resolved', true)
            .offset(0)
            .length(14);

        const royaltyStatementsRejectedFilter = new QueryFilter()
            .equal('status', [TRANSACTION_STATUS.REJECTED])
            .equal('type', [REFERENCE_TYPE.ROYALTY_STATEMENT])
            .equal('orderBy', 'CREATED_AT')
            .offset(0)
            .length(14);

        const royaltyStatementsWaitingForPaymentFilter = new QueryFilter()
            .equal('status', [TRANSACTION_STATUS.PENDING, TRANSACTION_STATUS.IN_REVIEW])
            .equal('type', [REFERENCE_TYPE.ROYALTY_STATEMENT])
            .equal('orderBy', 'CREATED_AT')
            .offset(0)
            .length(14);

        this._royaltyStatementsPaid.flush();
        this._royaltyStatementsRejected.flush();
        this._royaltyStatementsWaitingForPayment.flush();

       forkJoin([
                this._royaltyStatementsPaid.next(royaltyStatementsPaidFilter),
                this._royaltyStatementsRejected.next(royaltyStatementsRejectedFilter),
                this._royaltyStatementsWaitingForPayment.next(royaltyStatementsWaitingForPaymentFilter)
           ]
        )
            .subscribe((responses: QueryResponse<Transaction>[]) => {
                const hasResults = responses.reduce((acc, resp) => acc + resp.total, 0);
                this._state = hasResults ? LIST_STATUS.DONE : LIST_STATUS.NO_RESULT;
            });
    }
}
