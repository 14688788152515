<mat-expansion-panel class="mat-expansion-panel {{statusClassName}}">
    <mat-expansion-panel-header>
        <mat-panel-title i18n="Onboarding|Panel title for Terms of service">
            Terms of service
        </mat-panel-title>
        <mat-panel-description></mat-panel-description>
    </mat-expansion-panel-header>

    <section class="onboarding-application-terms-of-service-info">
        <h3>Registrera aktiebolag 1 000 kr</h3>
        <p>
            Innan vi startar igång ditt aktiekapitalkonto hos oss och påbörjar processen kring registreringen av ditt aktiebolag behöver du godkänna att
            Bokio får fakturera dig 1000 kr för tjänsten genom att bekräfta villkoren.
        </p>
        <p>
            Fakturering sker i månaden efter att registreringen är slutförd och skickas ut via mail.
        </p>
        <p>
            Skicka sedan in dina uppgifter till oss så går vi igenom dem och förbereder din ansökan till Bolagsverket.
        </p>
    </section>

    <form [formGroup]="form" red-form>
        <fieldset>
            <legend i18n="Onboarding|Enter your voucher code">Enter your voucher code</legend>
            <div class="red-row">
                <mat-form-field>
                    <input
                        matInput
                        i18n-placeholder="Onboarding|Voucher code"
                        placeholder="Voucher code"
                        formControlName="voucherCode"
                        autocomplete="off"
                    />
                </mat-form-field>
            </div>
        </fieldset>
        <fieldset>
            <legend i18n="Onboarding|Terms of service">Terms of service</legend>

            <ng-template #oldTerms>
                <div class="red-row">
                    <mat-checkbox
                        formControlName="termsAndConditions"
                    >Jag godkänner de</mat-checkbox>
                    <a class="onboarding-application-terms-of-service-link" href="https://www.redflag.se/allmanna-villkor" target="_blank">allmänna villkoren</a>
                </div>
            </ng-template>
            <div class="red-row" *ngIf="useBokioTerms; else oldTerms">
                <mat-checkbox
                    formControlName="termsAndConditions"
                >Jag godkänner de</mat-checkbox>
                <a class="onboarding-application-terms-of-service-link" (click)="openTermsDialog()">allmänna villkoren</a>
            </div>

            <div class="red-row" *ngIf="hasAgreedToTheCardAgreement">
                <mat-checkbox
                    formControlName="creditCardAgreement"
                    i18n="Onboarding|Credit card agreement">
                    I agree to the Credit card agreement
                </mat-checkbox>
                <a class="onboarding-application-terms-of-service-link" href="https://www.redflag.se/allmanna-villkor/kortavtal" target="_blank" i18n="Onboarding|Credit card agreement link">Credit card agreement</a>
            </div>
        </fieldset>
        <div class="error-info-text-container">
            <p class="error-info-text" *ngIf="!allPreviousApplicationsAreSentOrDone" i18n="Onboarding|Terms of service|All prevoius steps has to be sent message">
                Terms of service: All prevoius steps has to be sent message
            </p>
        </div>
        <div class="actions" *ngIf="!form.disabled">
            <button mat-flat-button color="primary" [disabled]="form.invalid || !allPreviousApplicationsAreSentOrDone" type="button" (click)="saveAndSend($event)" i18n="Onboarding|Save & Send button">Save & Send</button>
        </div>
    </form>
</mat-expansion-panel>
