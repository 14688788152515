import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
    RedListModule,
    RedLoaderModule,
    RedMarkdownModule,
    RedDialogModule,
    RedInfoBoxModule, RedAssetModule, RedFormModule
} from '@red/components';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckbox, MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import {ReactiveFormsModule} from '@angular/forms';
import {EndOfYearDialogComponent} from './end-of-year.dialog';
import {EndOfYearStepWarmupComponent} from './end-of-year-steps/end-of-year-step-warmup/end-of-year.step.warmup';
import {EndOfYearStepStartedComponent} from './end-of-year-steps/end-of-year-step-started/end-of-year.step.started';
import {EndOfYearStepCustomerDoneComponent} from './end-of-year-steps/end-of-year-step-customer-done/end-of-year.step.customer-done';
import {EndOfYearStepWaitingForCustomerToSignComponent} from './end-of-year-steps/end-of-year-step-waiting-for-customer-to-sign/end-of-year.step.waiting-for-customer-to-sign';
import {EndOfYearStepCreatingDocumentsComponent} from './end-of-year-steps/end-of-year-step-creating-documents/end-of-year.step.creating-documents';
import {EndOfYearStepWaitingForFinalSignatureComponent} from './end-of-year-steps/end-of-year-step-waiting-for-final-signature/end-of-year.step.waiting-for-final-signature';
import {MetadataFormComponent} from './end-of-year-forms/end-of-year-metadata-form/end-of-year-metadata-form';
import {EndOfYearOtherIncomeFormComponent} from './end-of-year-forms/end-of-year-other-income-form/end-of-year-other-income-form';
import {EndOfYearConfirmDialogComponent} from './end-of-year-confirm-dialog/end-of-year-confirm-dialog';
import {EndOfYearAccumulatedSalaryComponent} from './end-of-year-accumulated-salary/end-of-year-accumulated-salary';
import {EndOfYearReportsComponent} from './end-of-year-reports/end-of-year-reports';
import {EndOfYearStepBooksClosedComponent} from './end-of-year-steps/end-of-year-step-books-closed/end-of-year-books-closed';
import {EndOfYearStepDividendsComponent} from './end-of-year-steps/end-of-year-step-dividends/end-of-year.step.dividends';
import {EndOfYearStepShareholderInformationComponent} from './end-of-year-steps/end-of-year-step-shareholder-information/end-of-year.step.shareholder-information';
import {EndOfYearStepAnnualReportStoryComponent} from './end-of-year-steps/end-of-year-step-annual-report-story/end-of-year.step.annual-report-story';
import {EndOfYearStepAnnualReportDocumentsComponent} from './end-of-year-steps/end-of-year-step-annual-report-documents/end-of-year.step.annual-report-documents';
import {EndOfYearStepWaitingForBolagsverketComponent} from './end-of-year-steps/end-of-year-step-waiting-for-bolagsverket/end-of-year.step.waiting-for-bolagsverket';
import {EndOfYearStepAnnualReportDoneComponent} from './end-of-year-steps/end-of-year-step-annual-report-done/end-of-year.step.annual-report-done';
import {EndOfYearStepGeneratingAnnualReportComponent} from './end-of-year-steps/end-of-year-step-generating-annual-report/end-of-year.step.generating-annual-report';
import {EndOfYearStepSubmittingAnnualReportComponent} from './end-of-year-steps/end-of-year-step-submitting-annual-report/end-of-year.step.submitting-annual-report';
import {EndOfYearStepExitComponent} from './end-of-year-steps/end-of-year-step-exit/end-of-year.step.exit';
import {EndOfYearAnnualReportSummationDialogContentComponent} from './end-of-year-annual-report-summation-dialog-content/end-of-year-annual-report-summation-dialog-content';
import {EndOfYearStepBoardMemberInformationComponent} from './end-of-year-steps/end-of-year-step-board-member-information/end-of-year.step.board-member-information';
import {EndOfYearDialogTriggerComponent} from './end-of-year-dialog-trigger/end-of-year-dialog-trigger';
import {AnnualReportSignComponent} from './annual-report-sign/annual-report-sign';
import {EndOfYearInfoTextsComponent} from './end-of-year-info-texts/end-of-year-info-texts';
import {EndOfYearAnnualReportSummaryComponent} from './end-of-year-annual-report-summary/end-of-year-annual-report-summary';
import {RedPipeModule} from '@red/ng';
import {MatRadioModule} from '@angular/material/radio';
import { YearEndMetaDataFormHelper } from './year-end-metadata-form-helper';


@NgModule({
    declarations: [
        AnnualReportSignComponent,
        EndOfYearDialogComponent,
        EndOfYearDialogTriggerComponent,
        EndOfYearStepStartedComponent,
        EndOfYearStepWarmupComponent,
        EndOfYearStepCustomerDoneComponent,
        EndOfYearStepWaitingForCustomerToSignComponent,
        EndOfYearStepCreatingDocumentsComponent,
        EndOfYearStepWaitingForFinalSignatureComponent,
        MetadataFormComponent,
        EndOfYearOtherIncomeFormComponent,
        EndOfYearConfirmDialogComponent,
        EndOfYearAccumulatedSalaryComponent,
        EndOfYearStepBooksClosedComponent,
        EndOfYearReportsComponent,
        EndOfYearStepDividendsComponent,
        EndOfYearStepShareholderInformationComponent,
        EndOfYearStepAnnualReportStoryComponent,
        EndOfYearStepAnnualReportDocumentsComponent,
        EndOfYearStepWaitingForBolagsverketComponent,
        EndOfYearStepAnnualReportDoneComponent,
        EndOfYearStepGeneratingAnnualReportComponent,
        EndOfYearStepSubmittingAnnualReportComponent,
        EndOfYearStepExitComponent,
        EndOfYearAnnualReportSummationDialogContentComponent,
        EndOfYearStepBoardMemberInformationComponent,
        EndOfYearInfoTextsComponent,
        EndOfYearAnnualReportSummaryComponent,
    ],
    exports: [
        AnnualReportSignComponent,
        EndOfYearDialogComponent,
        EndOfYearDialogTriggerComponent,
        EndOfYearStepStartedComponent,
        EndOfYearStepWarmupComponent,
        EndOfYearStepCustomerDoneComponent,
        EndOfYearStepWaitingForCustomerToSignComponent,
        EndOfYearStepCreatingDocumentsComponent,
        EndOfYearStepWaitingForFinalSignatureComponent,
        MetadataFormComponent,
        EndOfYearOtherIncomeFormComponent,
        EndOfYearConfirmDialogComponent,
        EndOfYearAccumulatedSalaryComponent,
        EndOfYearStepBooksClosedComponent,
        EndOfYearReportsComponent,
        EndOfYearStepDividendsComponent,
        EndOfYearStepShareholderInformationComponent,
        EndOfYearStepAnnualReportStoryComponent,
        EndOfYearStepAnnualReportDocumentsComponent,
        EndOfYearStepWaitingForBolagsverketComponent,
        EndOfYearStepAnnualReportDoneComponent,
        EndOfYearStepGeneratingAnnualReportComponent,
        EndOfYearStepSubmittingAnnualReportComponent,
        EndOfYearStepExitComponent,
        EndOfYearAnnualReportSummationDialogContentComponent,
        EndOfYearStepBoardMemberInformationComponent,
        EndOfYearInfoTextsComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        MatSelectModule,
        MatCheckboxModule,
        MatInputModule,
        MatListModule,
        MatProgressBarModule,
        RedAssetModule,
        RedDialogModule,
        RedInfoBoxModule,
        RedListModule,
        RedLoaderModule,
        RedMarkdownModule,
        RedPipeModule,
        RedFormModule,
        MatRadioModule
    ],
    providers: [
        YearEndMetaDataFormHelper
    ]
})
export class EndOfYearModule {}
