<div class="red-dialog-title" matDialogTitle>
    <button mat-button (click)="close()" tabindex="-1">
        <mat-icon svgIcon="ui-close-white"></mat-icon>
    </button>
    <span class="red-dialog-title-text">
        <span i18n="End of year|red-dialog-title|The closing of the books" *ngIf="!dividendsStep">The closing of the books</span>
        <span i18n="End of year|red-dialog-title|Annual report" *ngIf="dividendsStep">Annual report</span>
    </span>
    <a class="help" href="/end-of-year/help" (click)="help($event)">
        <mat-icon svgIcon="ui-help-flag"></mat-icon>
    </a>
</div>
<div class="red-dialog-content end-of-year-start" matDialogContent>
    <red-loader-overlay *ngIf="movingToNextStep || waitingForBankId">
        <red-atom-loader></red-atom-loader>
        <p class="loader-text" i18n="End of year|loader text|moving to the next step" *ngIf="movingToNextStep">Moving to the next step</p>
        <p class="loader-text" i18n="Waiting for bankid" *ngIf="waitingForBankId">Waiting for bankid</p>
    </red-loader-overlay>

    <ng-container *ngIf="!movingToNextStep && !waitingForBankId">
        <ng-container [ngSwitch]="currentEndOfYearStepType">
            <app-end-of-year-step-warmup
                *ngSwitchCase="warmup"
                [metadataForm]="metadataForm"
                (metadataFormOutput)="saveMetaData($event)"
            ></app-end-of-year-step-warmup>
            <app-end-of-year-step-started
                *ngSwitchCase="started"
                [metadataForm]="metadataForm"
                (metadataFormOutput)="saveMetaData($event)"
            ></app-end-of-year-step-started>
            <end-of-year-step-customer-done
                *ngSwitchCase="customerDone"
            ></end-of-year-step-customer-done>
            <end-of-year-waiting-for-customer-to-sign
                *ngSwitchCase="waitingForCustomerToSign"
                [metadataForm]="metadataForm"
                [taxCalcData]="taxCalcData"
                (metadataFormOutput)="updateTaxCalculationTable($event)"
            ></end-of-year-waiting-for-customer-to-sign>
            <end-of-year-step-creating-documents
                *ngSwitchCase="creatingDocuments"
            ></end-of-year-step-creating-documents>
            <end-of-year-step-waiting-for-final-signature
                *ngSwitchCase="waitingForFinalSignature"
            ></end-of-year-step-waiting-for-final-signature>
            <end-of-year-books-closed
                *ngSwitchCase="booksClosed"
            ></end-of-year-books-closed>

            <app-end-of-year-step-dividends
                *ngSwitchCase="dividends"
                (dividendsFormOutput)="handleDividendsForm($event)"
            ></app-end-of-year-step-dividends>
            <app-end-of-year-step-shareholder-information
                *ngSwitchCase="shareholderInformation"
                (allTheShareholderFormsAreValid)="handleShareholderForm($event)"
            ></app-end-of-year-step-shareholder-information>
            <end-of-year-step-annual-report-story
                *ngSwitchCase="annualReportStory"
                (annualReportStoryFromIsValid)="handleAnnualReportStoryForm($event)"
            ></end-of-year-step-annual-report-story>
            <end-of-year-step-board-member-information
                *ngSwitchCase="boardMemberInformation"
                (hasSignatories)="handleHasSignatories($event)"
            ></end-of-year-step-board-member-information>
            <end-of-year-step-generating-annual-report
                *ngSwitchCase="generatingAnnualReport"></end-of-year-step-generating-annual-report>
            <end-of-year-step-annual-report-documents
                *ngSwitchCase="annualReportDocuments"
                (signedByEverybody)="handleSignedByEverybody($event)"
            ></end-of-year-step-annual-report-documents>
            <end-of-year-step-submitting-annual-report
                *ngSwitchCase="submittingAnnualReport"></end-of-year-step-submitting-annual-report>
            <end-of-year-step-waiting-for-bolagsverket
                *ngSwitchCase="waitingForBolagsverket"></end-of-year-step-waiting-for-bolagsverket>
            <end-of-year-step-annual-report-done
                *ngSwitchCase="annualReportDone"></end-of-year-step-annual-report-done>
            <end-of-year-step-exit
                *ngSwitchCase="exit"></end-of-year-step-exit>

        </ng-container>
    </ng-container>
</div>
<mat-dialog-actions class="red-dialog-actions" matDialogActions>
    <ng-container *ngIf="!movingToNextStep && !waitingForBankId">
        <ng-container [ngSwitch]="currentEndOfYearStepType">
            <ng-container *ngSwitchCase="started">
                <button mat-button class="mat-flat-button" [disabled]="startedNotDone" (click)="confirmCustomerDone()" i18n="End of year|button|ready">
                    Ready
                </button>
            </ng-container>
            <ng-container *ngSwitchCase="waitingForCustomerToSign">
                <button mat-button class="mat-flat-button bank-id-button" [disabled]="!taxCalcData" (click)="bankIdAuthorizeForNextStep()" i18n="End of year|Accept preliminary calculation">
                    Accept preliminary calculation
                </button>
            </ng-container>
            <ng-container *ngSwitchCase="waitingForFinalSignature">
                <button mat-button class="mat-flat-button bank-id-button" (click)="bankIdAuthorizeForNextStep()" i18n="End of year|Close year">
                    Close {{year}}
                </button>
            </ng-container>
            <ng-container *ngSwitchCase="booksClosed">
                <!--<button *ngIf="isLLC" mat-button class="mat-flat-button" i18n="End of year|To dividends" (click)="moveToNextStep()">To dividends</button>-->
                <!--<button *ngIf="!isLLC" mat-button class="mat-flat-button" (click)="close()">Ok</button>-->
                <button mat-button class="mat-flat-button" (click)="close()">Ok</button>
            </ng-container>
            <ng-container *ngSwitchCase="dividends">
                <button mat-button class="mat-flat-button" [disabled]="!dividendsFormIsValid" (click)="moveToNextStep()" i18n="End of year|dialog button|Next step">Next step</button>
            </ng-container>
            <ng-container *ngSwitchCase="shareholderInformation">
                <button mat-button class="mat-flat-button" [disabled]="!allTheShareholderFormsAreValid" (click)="moveToNextStep()" i18n="End of year|dialog button|Next step">Next step</button>
            </ng-container>
            <ng-container *ngSwitchCase="annualReportStory">
                <button mat-button class="mat-flat-button" [disabled]="!annualReportStoryFromIsValid" (click)="moveToNextStep()" i18n="End of year|dialog button|Next step">Next step</button>
            </ng-container>
            <ng-container *ngSwitchCase="annualReportDocuments">
                <button mat-button class="mat-flat-button" [disabled]="!signedByEverybody" (click)="moveToNextStep()" i18n="End of year|dialog button|Next step">Next step</button>
            </ng-container>
            <ng-container *ngSwitchCase="submittingAnnualReport">
                <button mat-button class="mat-flat-button" [disabled]="annualReportSubmitStatusFailed" (click)="moveToNextStep()"i18n="End of year|dialog button|Send to bolagsverket">Send to bolagsverket</button>
            </ng-container>
            <ng-container *ngSwitchCase="boardMemberInformation">
                <button mat-button class="mat-flat-button" [disabled]="!hasSignatories" (click)="moveToNextStep()" i18n="End of year|dialog button|Next step">Next step</button>
            </ng-container>
            <ng-container *ngSwitchDefault>
                <button mat-button class="mat-flat-button" (click)="close()">Ok</button>
            </ng-container>
        </ng-container>
    </ng-container>
</mat-dialog-actions>
