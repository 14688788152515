import {AppConfig} from '../../config/appConfig';
import {QueryFilter} from '@red/browser';
import {QueryParam} from '../../common/query-param';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {
    Onboarding, ONBOARDING_APPLICATION_STATUS, ONBOARDING_STATUS,
    ONBOARDING_TRACK, OnboardingApplication,
    OnboardingApplicationListResponse, OnboardingApplicationUpdateRequest,
    OnboardingCreateRequest, OnboardingListResponse
} from '@red/onboarding-service-api';
import {WebServiceClient} from './web-service-client';
import {HttpFetch, HttpFetchResponse} from '@red/common';

export class OnboardingServiceClient extends WebServiceClient {
    static create(transport: HttpFetch, config: AppConfig) {
        return new OnboardingServiceClient(transport, config);
    }

    create(request: OnboardingCreateRequest, headers?: object) {
        const opt = headers ? {headers} : {};
        return this._post<Onboarding>(`${this._getBaseUrl()}/api/onboarding`, request, opt, Onboarding);
    }

    list(filter?: QueryFilter, headers?: object): Observable<OnboardingListResponse> {
        const qry = filter ? `?${QueryParam.asString(filter)}` : '';
        const opt = headers ? {headers} : {};
        return this._get<OnboardingListResponse>(`${this._getBaseUrl()}/api/onboarding${qry}`, opt, OnboardingListResponse);
    }

    listOnboardingApplications(filter?: QueryFilter, headers?: object): Observable<OnboardingApplicationListResponse> {
        const qry = filter ? `?${QueryParam.asString(filter)}` : '';
        const opt = headers ? {headers} : {};
        return this._get(`${this._getBaseUrl()}/api/onboarding-application${qry}`, opt)
            .pipe(
                map((response: HttpFetchResponse) => response.body)
            );
    }

    updateOnboardingApplication(onboardingApplicationId: string, request: OnboardingApplicationUpdateRequest, headers?: object): Observable<OnboardingApplication> {
        const opt = headers ? {headers} : {};
        return this._put(`${this._getBaseUrl()}/api/onboarding-application/${onboardingApplicationId}`, request, opt)
            .pipe(
                map((response: HttpFetchResponse) => response.body)
            );
    }

    updateOnboardingApplicationStatus(onboardingApplicationId: string, status: ONBOARDING_APPLICATION_STATUS, headers?: object): Observable<OnboardingApplication> {
        const opt = headers ? {headers} : {};
        return this._put(`${this._getBaseUrl()}/api/onboarding-application/${onboardingApplicationId}/${status}`, {}, opt)
            .pipe(
                map((response: HttpFetchResponse) => response.body)
            );
    }

    getById(onboardingId: string, headers?: object): Observable<Onboarding> {
        const opt = headers ? {headers} : {};
        return this._get<Onboarding>(`${this._getBaseUrl()}/api/onboarding/${onboardingId}`, opt, Onboarding);
    }

    deleteById(onboardingId: string, headers?: object) {
        const opt = headers ? {headers} : {};
        return this._delete<Onboarding>(`${this._getBaseUrl()}/api/onboarding/${onboardingId}`, opt, Onboarding);
    }

    setTrack(onboardingId: string, track: ONBOARDING_TRACK, headers?: object) {
        const opt = headers ? {headers} : {};
        return this._put<Onboarding>(`${this._getBaseUrl()}/api/onboarding/${onboardingId}/${track}`, {}, opt, Onboarding);
    }

    setStatus(onboardingId: string, status: ONBOARDING_STATUS, headers?: object) {
        const opt = headers ? {headers} : {};
        return this._put<Onboarding>(`${this._getBaseUrl()}/api/onboarding/${onboardingId}/status/${status}`, {}, opt, Onboarding);
    }

    cancelTrack(onboardingId: string, headers?: object) {
        const opt = headers ? {headers} : {};
        return this._delete<Onboarding>(`${this._getBaseUrl()}/api/onboarding/${onboardingId}/track`, opt, Onboarding);
    }
}
