import {Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {
    OnboardingApplication,
    OnboardingApplicationDataLlcNewBlvForm
} from '@red/onboarding-service-api';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Actions, Store} from '@ngxs/store';
import {Me} from '@red/data';
import {OnboardingApplicationBaseDirective} from '../onboarding-application-base';
import {OnboardingManager} from '../../../../managers/onboarding/onboarding-manager';
import { SvLlcNameValidator } from './sv-llc-name/sv-llc-name-validator';

@Component({
    selector: 'app-onboarding-application-llc-new-blv-form',
    styleUrls: ['../onboarding-applications.sass'],
    templateUrl: 'onboarding-application-llc-new-blv-form.tpl.html',
    providers: [
        {provide: OnboardingApplicationBaseDirective, useExisting: forwardRef(() => OnboardingApplicationLlcNewBlvFormComponent )}
    ]
})
export class OnboardingApplicationLlcNewBlvFormComponent extends OnboardingApplicationBaseDirective implements OnInit, OnChanges {
    @Input() me: Me;

    get form(): FormGroup { return this._form; }
    get blvFormAddress(): FormGroup { return this._blvFormAddress; }
    get blvFormNameSuggestions(): FormArray { return (this._form.get('nameSuggestions') as FormArray); }
    get showNameValidationInfo(): boolean {
        const noEmptyNames = this._nameSuggestionsFormArray.at(0).value
            && this._nameSuggestionsFormArray.at(1).value
            && this._nameSuggestionsFormArray.at(2).value;

        return noEmptyNames && !this._nameSuggestionsFormArray.valid
            ? true
            : false;
    }

    private _formBuilder: FormBuilder;
    private _blvFormAddress: FormGroup;
    private _nameSuggestionsFormArray: FormArray;

    constructor(
        actions: Actions,
        store: Store,
        formBuilder: FormBuilder,
        onboardingManager: OnboardingManager
    ) {
        super(actions, store, onboardingManager);
        this._formBuilder = formBuilder;
    }

    ngOnInit() {
        super.ngOnInit();
        this._createForm();
        this._populateForm();
        this._toggleFormDisabled();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this._form) {
            this._toggleFormDisabled();
        }

        if (this.me) {
            this._setDefaultFormValues();
        }
    }

    save($event: Event) {
        $event.stopPropagation();
        const application = this._applicationFromForm();
        this._updateApplication(application);
    }

    saveAndSend($event: Event) {
        $event.stopPropagation();
        const application = this._applicationFromForm();
        this._updateApplicationAndSend(application);
    }

    private _applicationFromForm() {
        const application = new OnboardingApplication(this.application);
        application.data = new OnboardingApplicationDataLlcNewBlvForm(this._form.getRawValue());
        return application;
    }

    private _createForm() {
        this._nameSuggestionsFormArray = this._formBuilder.array([
            ['', [Validators.required, SvLlcNameValidator.nameFormat]],
            ['', [Validators.required, SvLlcNameValidator.nameFormat]],
            ['', [Validators.required, SvLlcNameValidator.nameFormat]]
        ]);
        this._setUniqueNameValidator();

        this._form = this._formBuilder.group({
            nameSuggestions: this._nameSuggestionsFormArray,
            isCEO: [''],
            isBeneficialOwner: [''],
            address: this._formBuilder.group({
                careOf: [''],
                lines: this._formBuilder.array([['', Validators.required]]),
                postalCode: ['', Validators.required],
                postalTown: ['', Validators.required],
                countryCode: ''
            }),
        });

        this._blvFormAddress = this._form.get(`address`) as FormGroup;
    }

    private _populateForm() {
        const data = (this.application.data as OnboardingApplicationDataLlcNewBlvForm);

        if (Object.keys(data).length > 0) {
            this._setFormValues(data);
        }
    }

    private _setFormValues(data) {
        const addressLines = data.address && data.address.lines ? data.address.lines : [];

        if (addressLines.length > 1) {
            const formArray = this._formBuilder.array(addressLines);
            formArray.at(0).setValidators(Validators.required);
            (this._form.get('address') as FormGroup).setControl('lines', formArray);
        }

        this._form.patchValue(data);
    }

    private _setDefaultFormValues() {
        const data = (this.application.data as OnboardingApplicationDataLlcNewBlvForm);

        if (Object.keys(data).length === 0) {
            this._setFormValues({address: this.me.user.details.addresses.current()});
        }
    }

    private _setUniqueNameValidator() {
        this._nameSuggestionsFormArray.valueChanges
            .subscribe((changes) => {
                const checkUniqueCompanyNames = SvLlcNameValidator.duplicateNames(changes);

                checkUniqueCompanyNames.forEach((index: number) => {
                    const errors = this._nameSuggestionsFormArray.get(index.toString()).errors || {};
                    errors['duplicateNameError'] = true;

                    this._nameSuggestionsFormArray.get(index.toString()).setErrors(errors);
                });

                if (checkUniqueCompanyNames.length === 0) {
                    this._nameSuggestionsFormArray.controls.forEach((ctrl: AbstractControl, index: number) => {
                        let errors = this._nameSuggestionsFormArray.get(index.toString()).errors || {};
                        delete errors['duplicateNameError'];

                        const hasErrors = Object.keys(errors).length;
                        errors = hasErrors ? errors : null;

                        this._nameSuggestionsFormArray.get(index.toString()).setErrors(errors);
                    });
                }
            });
    }
}
