<app-view-card>
    <red-top-toolbar>
        <span redTopToolbarTitle i18n="Receipt|Main title for Receipt View">Receipt</span>
        <button
            *ngIf="notReadOnlyState"
            mat-flat-button
            color="primary"
            redTopToolbarAction
            redAssetUploadTrigger
            (fileSelect)="createNewExpense($event)"
            [disabled]="!hasCompany"
        >
            <mat-icon svgIcon="ui-add"></mat-icon>
            <span i18n="Create new receipt">New receipt</span>
        </button>
    </red-top-toolbar>
    <app-view-card-infobar class="view-card-infobar">
        <app-red-flag-shut-down-banner></app-red-flag-shut-down-banner>
    </app-view-card-infobar>
    <router-outlet></router-outlet>
</app-view-card>
