import {Component, HostBinding, OnInit} from '@angular/core';
import {AnnualReport} from '@red/data';
import {ActivatedRoute} from '@angular/router';
import {RedFlagShutDownManager} from '../../managers/red-flag-shut-down/red-flag-shut-down.manager';

@Component({
    selector: 'app-dividends-view',
    templateUrl: 'dividends.tpl.html'
})
export class DividendsViewComponent implements OnInit {
    @HostBinding('class.scroll-view') cssClass = true;

    get canPayDividends(): boolean { return this._canPayDividends; }
    get notReadOnly(): boolean { return this._notReadOnly; }

    private _canPayDividends = true;
    private _route: ActivatedRoute;
    private _redFlagShutDownManager: RedFlagShutDownManager;
    private _notReadOnly: boolean;

    constructor(
        route: ActivatedRoute,
        redFlagShutDownManager: RedFlagShutDownManager
    ) {
        this._route = route;
        this._redFlagShutDownManager = redFlagShutDownManager;
    }

    ngOnInit(): void {
        const today = this._redFlagShutDownManager.today;
        this._notReadOnly = this._redFlagShutDownManager.notReadOnlyState(today);

        this._route.data.subscribe((data: { annualReport: AnnualReport}) => {
           this._canPayDividends = (data.annualReport && data.annualReport.remainingDividends && data.annualReport.remainingDividends.amount > 0);
        });
    }
}
