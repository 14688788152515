import {Component, HostBinding, OnInit} from '@angular/core';
import {COMPANY_FEATURES} from '@red/data';
import {Store} from '@ngxs/store';
import {CompanyConfigurationManager} from '../../managers/company-configuration/company-configuration-manager';
import {AuthState} from '../../shared/state/auth/auth.state';
import {RedFlagShutDownManager} from '../../managers/red-flag-shut-down/red-flag-shut-down.manager';

@Component({
    selector: 'app-settings-view',
    templateUrl: './settings.tpl.html'
})
export class SettingsViewComponent implements OnInit {
    @HostBinding('class.scroll-view') cssClass = true;

    get showCardTab(): boolean { return this._showCardTab; }
    get readOnly(): boolean { return this._readOnly; }
    get notReadOnly(): boolean { return this._notReadOnly; }

    private _showCardTab = false;
    private _store: Store;
    private _companyConfigurationManager: CompanyConfigurationManager;
    private _redFlagShutDownManager: RedFlagShutDownManager;
    private _notReadOnly: boolean;
    private _readOnly: boolean;

    constructor (
        store: Store,
        companyConfigurationManager: CompanyConfigurationManager,
        redFlagShutDownManager: RedFlagShutDownManager
    ) {
        this._store = store;
        this._companyConfigurationManager = companyConfigurationManager;
        this._redFlagShutDownManager = redFlagShutDownManager;
    }

    ngOnInit(): void {
        const today = this._redFlagShutDownManager.today;
        this._notReadOnly = this._redFlagShutDownManager.notReadOnlyState(today);
        this._readOnly = this._redFlagShutDownManager.readOnlyState(today);

        const companyId = this._store.selectSnapshot(AuthState.companyId);

        if (companyId) {
            const configuration = this._companyConfigurationManager.getConfigurationByCompanyId(this._store.selectSnapshot(AuthState.companyId));
            const cardFeature = configuration.getFeature(COMPANY_FEATURES.CARDS);
            this._showCardTab = cardFeature.enabled;
        }
    }
}
