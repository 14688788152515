import {HttpErrorResponse} from '@angular/common/http';
import {ErrorResponse} from './error-response';

export enum REMOTE_ERROR_EXCEPTION_TYPE {
    RESPONSE,
    CANCEL
}

export class RemoteErrorException extends Error  {
    get errorCode(): number { return this._errorResponse.errorCode; }
    get httpCode(): number { return this._errorResponse.httpCode; }
    get traceId(): string { return this._errorResponse.traceId; }
    get isCancelled(): boolean { return this._type === REMOTE_ERROR_EXCEPTION_TYPE.CANCEL; }

    private readonly _errorResponse: ErrorResponse;
    private _type: REMOTE_ERROR_EXCEPTION_TYPE;

    constructor (
        message: string,
        errorResponse: ErrorResponse
    ) {
        super(message);

        this._errorResponse = errorResponse;
        this._type = REMOTE_ERROR_EXCEPTION_TYPE.RESPONSE;

        Object.setPrototypeOf(this, RemoteErrorException.prototype);
    }

    static fromErrorResponse(err: ErrorResponse) {
        return new RemoteErrorException(err.message, err);
    }

    static fromHttpErrorResponse(err: HttpErrorResponse) {
        let message = err.message;
        let errorCode;
        let traceId;

        if (typeof err.error === 'object') {
            message = err.error.message || message;
            errorCode = err.error.errorCode || null;
            traceId = err.error.traceId || null;
        }

        const errorResponse = new ErrorResponse(message, err.status, errorCode, traceId);
        const error = RemoteErrorException.fromErrorResponse(errorResponse);

        /*
         This is a best guess effort to detect if an request was cancelled by either
         losing connection or when you leave Safari on a iOS device.
        */
        if (error.httpCode === 0 && err.error instanceof ProgressEvent) {
            error.setErrorType(REMOTE_ERROR_EXCEPTION_TYPE.CANCEL);
        }

        return error;
    }

    setErrorType(type: REMOTE_ERROR_EXCEPTION_TYPE) {
        this._type = type;
    }
}
