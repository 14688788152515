import {Announcement, AppVersion} from './app-info.model';

export class AppInfoUpdate {
    static readonly type = '[AppInfo] Update';
    constructor(public payload: {appVersion: AppVersion, announcements: Announcement[]}) {}
}

export class AppInfoNewVersionAvailable {
    static readonly type = '[AppInfo] NewVersionAvailable';
    constructor() {}
}

export class AppInfoNewAnnouncementAvailable {
    static readonly type = '[AppInfo] NewAnnouncementAvailable';
    constructor(public payload: {announcement: Announcement}) {}
}

export class AppInfoNoAnnouncementAvailable {
    static readonly type = '[AppInfo] NoAnnouncementAvailable';
    constructor() {}
}

export class AppInfoDismissAnnouncement {
    static readonly type = '[AppInfo] DismissAnnouncement';
    constructor(public payload: {announcement: Announcement}) {}
}
