import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CreateShareholderComponent} from './create-shareholder/create-shareholder';
import {EditShareholderComponent} from './edit-shareholder/edit-shareholder';
import {ReactiveFormsModule} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import {RedDialogModule, RedDisplayModule, RedFormModule, RedInfoBoxModule, RedLoaderModule} from '@red/components';
import {UserAdminModule} from '../user-admin/user-admin.module';

@NgModule({
    declarations: [
        CreateShareholderComponent,
        EditShareholderComponent
    ],
    exports: [
        CreateShareholderComponent,
        EditShareholderComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDialogModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        RedDialogModule,
        RedInfoBoxModule,
        RedFormModule,
        RedLoaderModule,
        UserAdminModule,
        RedDisplayModule
    ]
})
export class ShareholderModule {}
