<div class="red-dialog-title" matDialogTitle>
    <button mat-button (click)="close()" tabindex="-1">
        <mat-icon svgIcon="ui-close-white"></mat-icon>
    </button>
    <span *ngIf="!isCredit" class="red-dialog-title-text" i18n="Upload new receipt|Header for new receipt">New receipt</span>
    <span *ngIf="isCredit && !showRefundView" class="red-dialog-title-text" i18n="Choose original transaction|Header for refund card transaction">Choose original transaction</span>
    <span *ngIf="showRefundView" class="red-dialog-title-text" i18n="Header for confirming card transactrion refund| Confirm refund">Confirm original receipt</span>
</div>
<div class="red-dialog-content" matDialogContent [ngSwitch]="uploading">
    <red-loader-overlay *ngSwitchCase="true"><red-atom-loader></red-atom-loader></red-loader-overlay>
    <div *ngSwitchDefault>
        <div [ngSwitch]="showRefundView">
            <card-transaction-refund *ngSwitchCase="true" [file]="receiptImage" [refundCardTransaction]="refundCardTransaction" [originalCardTransaction]="cardTransaction"></card-transaction-refund>
            <div *ngSwitchDefault>
                <div [ngSwitch]="isCredit">
                    <div *ngSwitchCase="true">
                        <red-info-box>
                            <red-markdown-content [markdownPath]="cardTransactionRefundMarkdown"></red-markdown-content>
                        </red-info-box>
                        <red-list redListDialog>
                            <mat-list-item>
                                <red-list-icon mat-list-avatar [item]="refundCardTransaction"></red-list-icon>
                                <h4 mat-line>{{refundCardTransaction.description}}</h4>
                                <time mat-line>{{refundCardTransaction.createdAt | moment}}</time>
                                <app-display-amount-text type="CARD_TRANSACTION" [direction]="refundCardTransaction.direction" [amount]="refundCardTransaction.total"></app-display-amount-text>
                            </mat-list-item>
                        </red-list>
                        <red-asset-preview [file]="receiptImage"></red-asset-preview>
                    </div>
                    <div *ngSwitchDefault>
                        <red-info-box>
                            <red-markdown-content [markdownPath]="receiptUploadMarkdown"></red-markdown-content>
                        </red-info-box>
                        <red-asset-preview [file]="receiptImage"></red-asset-preview>
                        <red-list redListDialog class="create-receipt-list">
                            <mat-list-item (click)="selectReceipt()" [ngClass]="{'selected': isReceipt}" redListLink>
                                <mat-icon mat-list-avatar class="icon-list" svgIcon="list-receipt-add"></mat-icon>
                                <h4 mat-line class="receipt-choice-description" i18n="New receipt as private expense label">Mark as private expense</h4>
                                <time mat-line></time>
                                <span align="end" class="amount"></span>
                            </mat-list-item>
                        </red-list>
                    </div>
                </div>
                <red-list redListDialog *ngIf="cardTransactions.hasResults" class="create-receipt-list">
                    <span *ngIf="isCredit" redListHeader i18n="List header|Credit card transaction| header for list of card transactions to choose from the original">Choose the original one</span>
                    <mat-list-item *ngFor="let transaction of cardTransactions.items | async" (click)="selectTransaction(transaction)" [ngClass]="{'selected': isSelected(transaction), 'hide': hideCardTransaction(transaction)}" redListLink>
                        <red-list-icon mat-list-avatar [item]="transaction"></red-list-icon>
                        <h4 mat-line>{{transaction.description}}</h4>
                        <time mat-line>{{transaction.createdAt | moment}}</time>
                        <app-display-amount-text [amount]="transaction.total" type="CARD_TRANSACTION" [direction]="transaction.direction"></app-display-amount-text>
                    </mat-list-item>
                </red-list>
                <button *ngIf="!cardTransactions.complete" mat-button (click)="loadMore(cardTransactions)" class="invoice-load-more-btn" i18n="Load more card transactions">Load more...</button>
            </div>
        </div>
    </div>
</div>
<mat-dialog-actions *ngIf="!isCredit" class="red-dialog-actions">
    <button mat-flat-button color="" class="create-receipt-button" [disabled]="!canSave" (click)="save()" [ngSwitch]="uploading">
        <span *ngSwitchCase="true" i18n="Uploading receipt...">Uploading...</span>
        <span *ngSwitchDefault i18n="Button label|Save receipt">Save</span>
    </button>
</mat-dialog-actions>
<mat-dialog-actions *ngIf="showRefundView" class="red-dialog-actions">
   <button mat-button i18n="Button label| go back" (click)="goBack()">Back</button>
    <button mat-flat-button color="" i18n="Confirm credit card transaction|confirm" (click)="credit()">Confirm</button>
</mat-dialog-actions>
