import {NgModule} from '@angular/core';
import {RoyaltyStatementCreateComponent} from './create/create';
import {CommonModule} from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import {RedAssetModule, RedDialogModule, RedListModule, RedLoaderModule, RedInfoBoxModule} from '@red/components';
import {AmountModule} from '../amount/amount.module';
import {RoyaltyStatementEditComponent} from './edit/edit';
import {RoyaltyStatementDeleteConfirmComponent} from './confirm/delete/delete';
import {RedPipeModule} from '@red/ng';

@NgModule({
    declarations: [
        RoyaltyStatementCreateComponent,
        RoyaltyStatementDeleteConfirmComponent,
        RoyaltyStatementEditComponent
    ],
    exports: [],
    imports: [
        CommonModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        MatListModule,
        MatMenuModule,
        RedAssetModule,
        RedDialogModule,
        RedInfoBoxModule,
        RedListModule,
        RedLoaderModule,
        RedPipeModule,
        AmountModule
    ]
})
export class RoyaltyStatementModule {}
