import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {
    COMPANY_FEATURES,
    COMPANY_STATUS,
    COMPANY_TYPE,
    Relation,
    ROLE
} from '@red/data';
import {AppConfig} from './config/appConfig';
import {AppCapabilities, CAPABILITIES} from './common/appCapabilities';
import {Analytics, ANALYTICS_FIELD} from './common/analytics/analytics';
import {VersionChecker} from './common/client/version';
import {Actions, Store} from '@ngxs/store';
import {AuthLogout, AuthSetActiveCompany} from './shared/state/auth/auth.actions';
import {AuthState} from './shared/state/auth/auth.state';
import {CompanyConfigurationManager} from './managers/company-configuration/company-configuration-manager';
import {AuthManager} from './managers/auth/auth-manager';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AuthStateRouteDispatcher} from './shared/dispatcher/auth-state-route-dispatcher';
import {OnboardingState} from './shared/state/onboarding/onboarding.state';
import {OnboardingCheckStatus} from './shared/state/onboarding/onboarding.actions';
import {ITopLevelMenuSectionConfig, RedNotification} from '@red/components';
import {AuthErrorResponse} from '../tmp-red-client/security/auth/provider';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    get canSwitchCompanies(): Observable<boolean> { return this._canSwitchCompanies; }
    get companyName(): string { return this._companyName; }
    get relations(): Observable<Relation[]> { return  this._relations$; }
    get showMenu(): boolean { return this._showMenu; }
    get sections(): any[] { return this._sections; }
    get hasOnboarding(): boolean { return this._hasOnboarding; }
    get isOnboarding(): boolean { return this._isOnboarding; }

    private _appConfig: AppConfig;
    private _authManager: AuthManager;
    private _companyConfigurationManager: CompanyConfigurationManager;
    private _canSwitchCompanies: Observable<boolean>;
    private _companyName: string;
    private _capabilities: AppCapabilities;
    private _currentCompanyId: string;
    private _googleAnalytics: Analytics;
    private _notification: RedNotification;
    private _router: Router;
    private _sections: ITopLevelMenuSectionConfig[];
    private _sectionsConfig: ITopLevelMenuSectionConfig[];
    private _showMenu = false;
    private _versionChecker: VersionChecker;
    private _relations$: Observable<Relation[]>;
    private _actions: Actions;
    private _store: Store;
    private _authStateRouteDispatcher: AuthStateRouteDispatcher;
    private _hasOnboarding: boolean;
    private _isOnboarding: boolean;

    constructor(
        appConfig: AppConfig,
        authManager: AuthManager,
        companyConfigurationManager: CompanyConfigurationManager,
        capabilities: AppCapabilities,
        googleAnalytics: Analytics,
        notification: RedNotification,
        router: Router,
        versionChecker: VersionChecker,
        actions: Actions,
        store: Store,
        authStateRouteDispatcher: AuthStateRouteDispatcher
    ) {
        this._appConfig = appConfig;
        this._authManager = authManager;
        this._companyConfigurationManager = companyConfigurationManager;
        this._capabilities = capabilities;
        this._googleAnalytics = googleAnalytics;
        this._notification = notification;
        this._router = router;
        this._versionChecker = versionChecker;
        this._actions = actions;
        this._store = store;
        this._authStateRouteDispatcher = authStateRouteDispatcher;

        this._trackRouterEvents();

        this._sectionsConfig = this._appConfig.get('topLevelMenu.sections');
    }

    ngOnInit(): void {
        this._authStateRouteDispatcher.init();

        this._store.select(OnboardingState.hasOnboarding)
            .subscribe((hasOnboarding) => {
                if (hasOnboarding) {
                    this._setMenuSections();
                }

                this._hasOnboarding = hasOnboarding;
            });

        this._store.select(AuthState.isAuthenticated)
            .subscribe((isAuthenticated) => {
                this._versionChecker.start();
                if (isAuthenticated) {
                    this._setMenuSections();
                    this._store.dispatch(new OnboardingCheckStatus());
                }
            });

        this._store.select(AuthState.companyId)
            .subscribe((companyId) => {
                if (companyId && this._store.selectSnapshot(AuthState.isAuthenticated)) {
                    this._init();
                }
            });
    }

    isCurrentCompany(relation: Relation): boolean {
        return this._currentCompanyId && relation.company.id === this._currentCompanyId;
    }

    selectRelation(relation: Relation) {
        this._store.dispatch(new AuthSetActiveCompany({companyType: relation.company.type, companyId: relation.company.id, companyName: relation.company.name}))
            .subscribe(() => {
                window.location.href = '/';
            }, (err: AuthErrorResponse) => {
                const error = err.error;

                if (error.errorCode > 40000 && error.errorCode < 40400) {
                    this.logout();
                }

                const msg = $localize`:RedNotification|Error message presented to user when failed to switching company:An error occurred while switching companies. Please try again.`;

                this._notification.errorWithCode(error, msg);
            });
    }

    logout() {
        this._store.dispatch(new AuthLogout());
    }

    private _init() {
        this._companyName = this._store.selectSnapshot(AuthState.companyName) || this._store.selectSnapshot(AuthState.userName);
        this._currentCompanyId = this._store.selectSnapshot(AuthState.companyId);
        this._setRelations();
        this._setMenuSections();
    }

    private _hasCardFeature() {
        const configuration = this._companyConfigurationManager.getConfigurationByCompanyId(this._currentCompanyId);
        const cardFeature = configuration && configuration.getFeature(COMPANY_FEATURES.CARDS);
        return cardFeature && cardFeature.enabled;
    }

    private _hasCarBenefitFeature() {
        const configuration = this._companyConfigurationManager.getConfigurationByCompanyId(this._currentCompanyId);
        const carBenefit = configuration && configuration.getFeature(COMPANY_FEATURES.CAR_BENEFIT);
        return carBenefit && carBenefit.enabled;
    }

    private _hasEmployeeFeature() {
        const configuration = this._companyConfigurationManager.getConfigurationByCompanyId(this._currentCompanyId);
        const employeeFeature = configuration && configuration.getFeature(COMPANY_FEATURES.EMPLOYEES);
        return employeeFeature && employeeFeature.enabled;
    }

    private _isLimitedCompany() {
        return this._store.selectSnapshot(AuthState.companyType) === COMPANY_TYPE.LIMITED_COMPANY;
    }

    private _hasEmployeeSalaryFeature() {
        return this._isLimitedCompany() || this._hasEmployeeFeature();
    }

    private _hasRoyaltyStatementFeature() {
        const configuration = this._companyConfigurationManager.getConfigurationByCompanyId(this._currentCompanyId);
        const royaltyFeature = configuration && configuration.getFeature(COMPANY_FEATURES.ROYALTIES);
        return this._capabilities.hasAppCapability(CAPABILITIES.ROYALTY_STATEMENT.toString()) && royaltyFeature && royaltyFeature.enabled;
    }

    private _hasWithdrawalFeature() {
        return this._store.selectSnapshot(AuthState.companyType) === COMPANY_TYPE.SOLE_TRADER;
    }

    private _setRelations() {
        this._relations$ = this._authManager.me()
            .pipe(
                map(me => {
                    return me.relations
                        .filter(relation => relation.role === ROLE.OWNER)
                        .filter(relation => relation.company.status === COMPANY_STATUS.ACTIVE);
                })
            );

        this._canSwitchCompanies = this._relations$
            .pipe(
                map((relations) => relations.length > 1)
            );
    }

    private _trackRouterEvents() {
        this._router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this._setMenuState();
                this._googleAnalytics.set(ANALYTICS_FIELD.PAGE, event.urlAfterRedirects);
                this._googleAnalytics.send('pageview');
            }
        });
    }

    private _checkRoute(route: string): boolean | string {
        switch (route) {
            case 'dashboard': {
                return this._store.selectSnapshot(AuthState.companyId);
            }
            case 'onboarding': {
                return this._store.selectSnapshot(OnboardingState.hasOnboarding);
            }
            case 'card-transaction': {
                return this._hasCardFeature();
            }
            case 'employees': {
                return this._hasEmployeeFeature();
            }
            case 'salary': {
                return this._hasEmployeeSalaryFeature();
            }
            case 'withdrawal': {
                return this._hasWithdrawalFeature();
            }
            case 'income-stream': {
                return this._hasRoyaltyStatementFeature();
            }
            case 'dividends': {
                return this._isLimitedCompany();
            }
            case 'benefit': {
                return this._hasCarBenefitFeature();
            }
            default: {
                return true;
            }
        }
    }

    private _setMenuSections() {
        this._sections = this._appConfig.get('topLevelMenu.sections').map((section) => {
            const sec = Object.assign({}, section);
            const items = section.items.filter(item => this._checkRoute(item.route));

            sec.items = items;
            return items.length > 0 ? sec : null;
        }).filter(section => section !== null);
    }

    private _setMenuState() {
        const validRoute = !(this._router.url.startsWith('/login')
            || this._router.url.startsWith('/sign-up')
            || this._router.url.startsWith('/onboarding'));
        const hasConsentToSign = this._store.selectSnapshot(AuthState.consentsToSign);

        this._isOnboarding = !!this._router.url.startsWith('/onboarding');

        this._showMenu = validRoute && this._store.selectSnapshot(AuthState.userId) && !hasConsentToSign;
    }
}
