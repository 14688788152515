import {NgModule} from '@angular/core';
import {RedLoginFormComponent} from './form/form';
import {ReactiveFormsModule} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {CommonModule} from '@angular/common';
import {RedLoginDialogComponent} from './dialog/loginDialog';
import { RedLoaderModule } from '@red/components';

@NgModule({
    declarations: [
        RedLoginDialogComponent,
        RedLoginFormComponent
    ],
    exports: [
        RedLoginDialogComponent,
        RedLoginFormComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSlideToggleModule,
        ReactiveFormsModule,
        RedLoaderModule
    ]
})
export class RedLoginModule {

}
