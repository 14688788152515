import {Component, HostBinding, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {RedDialog, DIALOG_RESULT} from '@red/components';
import {ReceiptCreateComponent} from '../../components/receipt/create/create';
import {first} from 'rxjs/operators';
import {AuthState} from '../../shared/state/auth/auth.state';
import {Store} from '@ngxs/store';
import {RedFlagShutDownManager} from '../../managers/red-flag-shut-down/red-flag-shut-down.manager';

@Component({
    selector: 'app-receipt-view',
    templateUrl: './receipt.tpl.html'
})
export class ReceiptViewComponent implements OnInit {
    @HostBinding('class.scroll-view') cssClass = true;

    get hasCompany(): boolean { return this._hasCompany; }
    get notReadOnlyState(): boolean { return this._notReadOnlyState; }

    private _router: Router;
    private _redDialog: RedDialog;
    private _store: Store;
    private _hasCompany: boolean;
    private _redFlagShutDownManager: RedFlagShutDownManager;
    private _notReadOnlyState: boolean;

    constructor (
        redDialog: RedDialog,
        router: Router,
        store: Store,
        redFlagShutDownManager: RedFlagShutDownManager
    ) {
        this._redDialog = redDialog;
        this._router = router;
        this._store = store;
        this._redFlagShutDownManager = redFlagShutDownManager;
    }

    ngOnInit() {
        const companyId = this._store.selectSnapshot(AuthState.companyId);
        this._hasCompany = !!companyId;

        const today = this._redFlagShutDownManager.today;
        this._notReadOnlyState = this._redFlagShutDownManager.notReadOnlyState(today);
    }

    createNewExpense($event: File) {
        this._redDialog
            .open(ReceiptCreateComponent, {'data': {'file': $event}})
            .afterClosed().pipe(first())
            .subscribe((result: DIALOG_RESULT) => {
                if (result === DIALOG_RESULT.OK) {
                    this._router.navigate(['receipt']);
                }
            });
    }
}
