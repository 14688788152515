import {Injectable} from '@angular/core';
import { FormBuilder, Validators} from '@angular/forms';
import {Comment} from '@red/data';

@Injectable()
export class CommentFormHelper {
    private _formBuilder: FormBuilder;

    constructor (formBuilder: FormBuilder) {
        this._formBuilder = formBuilder;
    }

    createCommentForm() {
        return this._formBuilder.group({
            'message': ['']
        });
    }

    mapCommentToForm(comment: Comment) {
       return {
            'message': comment.content.payload
        };
    }
}
