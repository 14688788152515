import {AfterViewInit, Component, OnDestroy} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {RedDialog, DIALOG_RESULT} from '@red/components';
import {InvoiceEditComponent} from '../../../components/invoice/edit/edit';
import {Router} from '@angular/router';

@Component({
    selector: 'app-invoice-create-view',
    templateUrl: './create.tpl.html'
})
export class InvoiceCreateViewComponent implements OnDestroy, AfterViewInit {
    private _dialogRef: MatDialogRef<InvoiceEditComponent, DIALOG_RESULT>;
    private _redDialog: RedDialog;
    private _router: Router;

    constructor (
        redDialog: RedDialog,
        router: Router
    ) {
        this._redDialog = redDialog;
        this._router = router;
    }

    ngOnDestroy(): void {
        this._dialogRef.close(DIALOG_RESULT.CANCEL);
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this._dialogRef = this._redDialog.open(InvoiceEditComponent);

            this._dialogRef
                .afterClosed()
                .subscribe((result: DIALOG_RESULT) => {
                    if (result === DIALOG_RESULT.OK) {
                        this._router.navigate(['invoice']);
                    }
                });
        });
    }
}
