import * as moment from 'moment';
import {Component, HostBinding, Inject, LOCALE_ID, OnInit} from '@angular/core';
import {
    Amount,
    Company,
    COMPANY_TYPE,
    CompanyFiscalData,
    CompanyYearEndStep,
    CURRENCY,
    DatedPermit,
    FutureEvent,
    REFERENCE_TYPE, User
} from '@red/data';
import {AppConfig} from '../../config/appConfig';
import {GraphDataPoint} from '../../components/graph/graph-data-source';
import {END_OF_YEAR_STATE, EndOfYearManager} from '../../managers/end-of-year/end-of-year.manager';
import {DIALOG_RESULT, RedDialog} from '@red/components';
import {CompanyManager} from '../../managers/company/company.manager';
import {Actions, Store} from '@ngxs/store';
import {AuthState} from '../../shared/state/auth/auth.state';
import {map} from 'rxjs/operators';
import {FinancialStatementState} from '../../shared/state/financial-statement/financial-statement.state';
import {Observable} from 'rxjs';
import {FINANCIAL_STATEMENT_STATUS} from '../../shared/state/financial-statement/financial-statement.model';
import {RedFlagShutDownManager} from '../../managers/red-flag-shut-down/red-flag-shut-down.manager';

export enum DASHBOARD_COMPONENT_STATE {
    ERROR = 'ERROR',
    LOADING = 'LOADING',
    IDLE = 'IDLE',
}

@Component({
    selector: 'app-dashboard-view',
    templateUrl: 'dashboard.tpl.html'
})
export class DashboardViewComponent implements OnInit {
    @HostBinding('class.dashboard-view') cssClassDashboard = true;
    @HostBinding('class.scroll-view') cssClass = true;

    get isLLC(): boolean { return this._companyType === COMPANY_TYPE.LIMITED_COMPANY; }
    get endOfYearStep(): CompanyYearEndStep { return this._endOfYearStep; }
    get endOfYearStart(): string { return this._endOfYearManager.endOfYearStart; }
    get isInEndOfYearProcess(): boolean { return this._isInEndOfYearProcess; }
    get selectedDataPoint(): GraphDataPoint { return this._selectedDataPoint; }
    get selectedDataPointAmount(): Amount { return this._selectedDataPointAmount; }
    get shareholderCapital(): Amount { return this._shareholderCapital; }
    get showTotals(): boolean { return this._showTotals; }
    get today(): moment.Moment { return this._today; }
    get nextThirtyDays(): string {
        const now = moment();
        const year = now.format('YYYY');
        const today = now.format('ll').replace(year, '').trim();
        const plusThirty = now.add(30, 'days').format('ll').replace(year, '').trim();

        return today + ' \u2013 ' + plusThirty;
    }
    get vatBalanceMarkdown(): string { return this._vatBalanceMarkdown; }
    get company(): Company { return this._company; }
    get companyDetailSupportLinks(): { internationalInvoice: string; deposit: string } {
        return this._companyDetailSupportLinks;
    }
    get state$(): Observable<DASHBOARD_COMPONENT_STATE> { return this._state$; }
    get vatReceivable$(): Observable<Amount> { return this._vatReceivable$; }
    get hasVatReceivable$(): Observable<boolean> { return this._hasVatReceivable$; }
    get openingBalance$(): Observable<Amount | null> { return this._openingBalance$; }
    get vatBalance$(): Observable<Amount | null> { return this._vatBalance$; }
    get shareholderCapital$(): Observable<Amount | null> { return this._shareholderCapital$; }
    get accruedTax$(): Observable<Amount | null> { return this._accruedTax$; }
    get limit$(): Observable<Amount | null> { return this._limit$; }
    get balance$(): Observable<Amount | null> { return this._balance$; }
    get notReadOnlyState(): boolean { return this._notReadOnlyState; }

    private _endOfYearManager: EndOfYearManager;
    private _endOfYearStep: CompanyYearEndStep;
    private _companyType: COMPANY_TYPE;
    private _dialog: RedDialog;
    private _balance$: Observable<Amount | null>;
    private _limit$: Observable<Amount | null>;
    private _accruedTax$: Observable<Amount | null>;
    private _shareholderCapital$: Observable<Amount | null>;
    private _vatBalance$: Observable<Amount | null>;
    private _openingBalance$: Observable<Amount | null>;
    private _hasVatReceivable$: Observable<boolean>;
    private _vatReceivable$: Observable<Amount>;
    private _isInEndOfYearProcess: boolean;
    private _selectedDataPoint: GraphDataPoint;
    private _selectedDataPointAmount: Amount;
    private _shareholderCapital: Amount;
    private _showTotals: boolean;
    private _today: moment.Moment;
    private _vatBalanceMarkdown: string;
    private _companyManager: CompanyManager;
    private _company: Company;
    private _store: Store;
    private _companyDetailSupportLinks = {
        deposit: 'http://support.redflag.se/sv/articles/2479984-insattning-pa-ditt-foretagskonto',
        internationalInvoice: 'http://support.redflag.se/sv/articles/2771394-fakturera-utomlands'
    };
    private _state$: Observable<DASHBOARD_COMPONENT_STATE>;
    private _actions: Actions;
    private _redFlagShutDownManager: RedFlagShutDownManager;
    private _notReadOnlyState: boolean;

    constructor (
        appConfig: AppConfig,
        dialog: RedDialog,
        endOfYearManager: EndOfYearManager,
        companyManager: CompanyManager,
        store: Store,
        actions: Actions,
        redFlagShutDownManager: RedFlagShutDownManager,
        @Inject(LOCALE_ID) localeId: string
    ) {
        this._dialog = dialog;

        this._today = moment(new Date());
        this._vatBalanceMarkdown = appConfig.get(`content.withdrawal.vatBalance.${localeId}`);

        this._endOfYearManager = endOfYearManager;
        this._companyManager = companyManager;
        this._store = store;
        this._actions = actions;
        this._redFlagShutDownManager = redFlagShutDownManager;

        // TODO: This is just a hack for tests.... Kill it.
        window['moment'] = moment;
    }

    ngOnInit(): void {
        const today = this._redFlagShutDownManager.today;
        this._notReadOnlyState = this._redFlagShutDownManager.notReadOnlyState(today);

        this._setState();

        this._setEmptyCompany();

        this._companyType =  this._store.selectSnapshot(AuthState.companyType);

        this._companyManager
            .getCompany(this._store.selectSnapshot(AuthState.companyId))
            .subscribe(company => {
                this._company = company || {} as Company;

                if (!this._company.fiscalData) {
                    this._company.fiscalData = new CompanyFiscalData();
                }

                this._handleEmptyPermits();
            });
    }

    getDescription(item: FutureEvent): string {
        const referenceType = this._getReferenceType(item);

        switch (referenceType) {
            case REFERENCE_TYPE.OWNER_WITHDRAWAL:
                return $localize`:Dashboard|Reference type OWNER_WITHDRAWAL:Owner withdrawal`;
            case REFERENCE_TYPE.BILL:
                return $localize`:Dashboard|Reference type BILL:Bill`;
            default:
                return item.description;
        }
    }

    getRouterLink(item: FutureEvent): string[] {
        const referenceType = this._getReferenceType(item);

        switch (referenceType) {
            case REFERENCE_TYPE.INVOICE:
                return ['/invoice', item.reference.id, 'edit'];
            case REFERENCE_TYPE.SALARY:
                return ['/salary', item.reference.id, 'edit'];
            case REFERENCE_TYPE.OWNER_WITHDRAWAL:
                return ['/loan'];
            case REFERENCE_TYPE.BILL:
                return ['/bill', item.reference.id, 'edit'];
            default:
                return [];
        }
    }

    onDataPointSelected(dataPoint: GraphDataPoint) {
        this._selectedDataPoint = dataPoint;
        this._selectedDataPointAmount = new Amount({'amount': dataPoint.sum, 'currency': CURRENCY.SEK});
    }

    toggleTotals() {
        this._showTotals = !this._showTotals;
    }

    vatBalancePrefix(vatBalance: Amount): string {
        return vatBalance && vatBalance.amount <= 0 ? '' : '&#8211;';
    }

    vatBalanceSuffix(hasVatReceivable: boolean): string {
        return hasVatReceivable ? '*' : '';
    }

    private _handleEmptyPermits() {
        if (!this._company.fiscalData.vatPermit) {
            this._company.fiscalData.vatPermit = new DatedPermit();
        }

        if (!this._company.fiscalData.taxationPermit) {
            this._company.fiscalData.taxationPermit = new DatedPermit();
        }

        if (!this._company.fiscalData.employerPermit) {
            this._company.fiscalData.employerPermit = new DatedPermit();
        }
    }

    private _setEmptyCompany() {
        this._company = new Company({
            details: {
                fiscalInformation: {
                    currentFiscalYear: {}
                }
            },
            fiscalData: {
                vatPermit: {},
                taxationPermit: {},
                employerPermit: {}
            }
        });
    }

    private _getReferenceType(item: FutureEvent): REFERENCE_TYPE | '' {
        return (item && item.reference && item.reference.type) || '';
    }

    private _setState() {
        this._state$ = this._store.select(FinancialStatementState.projectionStatus)
            .pipe(
                map((projectionStatus: FINANCIAL_STATEMENT_STATUS) => {
                    if (projectionStatus === FINANCIAL_STATEMENT_STATUS.INIT || projectionStatus === FINANCIAL_STATEMENT_STATUS.STARTED) {
                        return DASHBOARD_COMPONENT_STATE.LOADING;
                    } else if (projectionStatus === FINANCIAL_STATEMENT_STATUS.ERROR) {
                        return DASHBOARD_COMPONENT_STATE.ERROR;
                    }

                    return DASHBOARD_COMPONENT_STATE.IDLE;
                })
            );
    }
}

