import {Component, Input} from '@angular/core';
import {Amount, AnnualReport} from '@red/data';

@Component({
    selector: 'app-dividends-calculation',
    styleUrls: ['./calculation.sass'],
    templateUrl: './calculation.tpl.html'
})
export class DividendsCalculationComponent {
    @Input() annualReport: AnnualReport;
    @Input() limit: Amount;

    get remainingDividends(): Amount { return this.annualReport.remainingDividends; }
}
