import * as moment from 'moment';
import {Component, Inject, LOCALE_ID, OnDestroy, OnInit} from '@angular/core';
import {Card, CARD_STATUS, REFERENCE_TYPE, Transaction, TRANSACTION_STATUS} from '@red/data';
import {LIST_STATUS, ListDataSource, QueryFilter, QueryResponse} from '@red/browser';
import {NavigationEnd, Router, RouterEvent} from '@angular/router';
import {BehaviorSubject, forkJoin, Observable, Subscription} from 'rxjs';
import {TransactionsByMonth} from '../../../common/transaction-by-month';
import {AppConfig} from '../../../config/appConfig';
import {AuthState} from '../../../shared/state/auth/auth.state';
import {Store} from '@ngxs/store';
import {CardState} from '../../../shared/state/card/card.state';
import {map} from 'rxjs/operators';
import {TransactionManager} from '../../../managers/transaction/transaction.manager';

@Component({
    selector: 'app-card-transaction-list',
    templateUrl: './list.tpl.html'
})

export class CardTransactionListViewComponent implements OnDestroy, OnInit {
    get isActivatedCard$(): Observable<boolean> { return this._isActivatedCard$; }
    get cardTransactions(): ListDataSource<Transaction> { return this._cardTransactions; }
    get cardTransactionsByMonth(): BehaviorSubject<TransactionsByMonth[]> { return this._cardTransactionsByMonth; }
    get cardStolenInfoMarkdown(): string { return this._cardStolenInfoMarkdown; }
    get reservedCardTransactions(): ListDataSource<Transaction> { return this._reservedCardTransactions; }
    get state(): LIST_STATUS { return this._state; }
    get stateDone(): LIST_STATUS { return LIST_STATUS.DONE; }
    get stateLoading(): LIST_STATUS { return LIST_STATUS.LOADING; }
    get stateNoResult(): LIST_STATUS { return LIST_STATUS.NO_RESULT; }

    private _appConfig: AppConfig;
    private _cardTransactions: ListDataSource<Transaction>;
    private _cardTransactionsByMonth: BehaviorSubject<TransactionsByMonth[]>;
    private _isActivatedCard$: Observable<boolean>;
    private _router: Router;
    private _routerSubscription: Subscription;
    private _store: Store;
    private _state: LIST_STATUS;
    private _reservedCardTransactions: ListDataSource<Transaction>;
    private _cardStolenInfoMarkdown: string;
    private _transactionManager: TransactionManager;

    constructor(
        appConfig: AppConfig,
        router: Router,
        store: Store,
        transactionManager: TransactionManager,
        @Inject(LOCALE_ID) localeId: string
    ) {
        this._appConfig = appConfig;
        this._router = router;
        this._store = store;
        this._transactionManager = transactionManager;

        this._cardStolenInfoMarkdown = this._appConfig.get(`content.card.stolen_info.${localeId}`);
    }

    ngOnDestroy(): void {
        this._routerSubscription.unsubscribe();
    }

    ngOnInit(): void {
        this._cardTransactions = new ListDataSource<Transaction>((filter: QueryFilter) => {
            return this._transactionManager.queryToQueryResponse(this._store.selectSnapshot(AuthState.companyId), filter);
        });

        this._reservedCardTransactions = new ListDataSource<Transaction>((filter: QueryFilter) => {
            return this._transactionManager.queryToQueryResponse(this._store.selectSnapshot(AuthState.companyId), filter);
        });

        this._cardTransactionsByMonth = new BehaviorSubject<TransactionsByMonth[]>([]);
        this._cardTransactions.items
            .subscribe((transactions: Transaction[]) => {
                this._cardTransactionsByMonth.next(this._groupByMonth(transactions));
            });

        this._loadCardTransactions();
        this._routerSubscription = this._router.events.subscribe((event: RouterEvent) => {
            if (event instanceof NavigationEnd && event.url === '/card-transaction') {
                this._loadCardTransactions();
            }
        });

        this._checkCards();
    }

    loadMore(dataSource: ListDataSource<Transaction>) {
        dataSource.next().subscribe(() => {});
    }

    showTransactionAmount(card: Transaction) {
        return card.total.amount !== card.forexAmount.amount || card.total.currency !== card.forexAmount.currency;
    }

    private _groupByMonth(cardTransactions: Transaction[]): TransactionsByMonth[] {
        const cardTransactionsByMonth = {};

        cardTransactions.forEach((cardTransaction: Transaction) => {
            const month = `${moment(cardTransaction.createdAt).format('YYYY-MM')}-01`;
            if (!cardTransactionsByMonth.hasOwnProperty(month)) {
                cardTransactionsByMonth[month] = [];
            }

            cardTransactionsByMonth[month].push(cardTransaction);
        });

        return Object.keys(cardTransactionsByMonth)
            .map((key) => {
                const trans = cardTransactionsByMonth[key];
                return {'date': key, 'transactions': trans};
            });
    }

    private _checkCards() {
        this._isActivatedCard$ = this._store.select(CardState.cardsForCompanyId(this._store.selectSnapshot(AuthState.companyId)))
            .pipe(
                map((cards) => {
                    return !!this._filterCurrentCard(cards);
                })
            );
    }

    private _filterCurrentCard(cards: Card[]): Card {
        return cards.filter(card => card.activated && card.status === CARD_STATUS.OK).shift();
    }

    private _loadCardTransactions() {
        this._state = LIST_STATUS.LOADING;

        const filter = new QueryFilter()
            .equal('status', [TRANSACTION_STATUS.IN_REVIEW, TRANSACTION_STATUS.BOOKED, TRANSACTION_STATUS.PENDING])
            .equal('type', [REFERENCE_TYPE.CARD_TRANSACTION])
            .equal('orderBy', 'CREATED_AT')
            .equal('resolved', true)
            .offset(0)
            .length(100);

        const reservedFilter = new QueryFilter()
            .equal('type', [REFERENCE_TYPE.CARD_TRANSACTION])
            .equal('status', [TRANSACTION_STATUS.PENDING])
            .equal('orderBy', 'CREATED_AT')
            .equal('resolved', false)
            .offset(0)
            .length(2000);

        this._cardTransactions.flush();
        this._reservedCardTransactions.flush();

        forkJoin([
                this._cardTransactions.next(filter),
                this._reservedCardTransactions.next(reservedFilter)
            ]
        )
            .subscribe(([cardTransactionsResp, reservedCardTransactionsResp]: [QueryResponse<Transaction>, QueryResponse<Transaction>]) => {
                const hasResults = [cardTransactionsResp, reservedCardTransactionsResp].reduce((acc, resp) => acc + (resp.total || 0), 0);
                this._state = hasResults ? LIST_STATUS.DONE : LIST_STATUS.NO_RESULT;
            });
    }

}
