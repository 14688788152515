import {YearEndServiceClient} from '../../lab/service-client/year-end-service-client';
import {
    Amount,
    AuthCredentials,
    Company,
    COMPANY_TYPE,
    CompanyYearEndStep,
    CompanyYearEndStepListResponse,
    LLCTaxCalculation,
    REFERENCE_TYPE,
    SoleTraderTaxCalculation,
    Transaction,
    TRANSACTION_STATUS,
    TRANSACTION_SUB_TYPE,
    YEAR_END_REPORT_STATUS,
    YEAR_END_REPORT_TYPE,
    YEAR_END_STEP,
    YearEndMetaData,
    YearEndMetaDataUpdateRequest,
    YearEndReport
} from '@red/data';
import * as moment from 'moment';
import {BankIdAuthResponse, QueryFilter, QueryResponse} from '@red/browser';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {BehaviorSubject, forkJoin, Observable, of, ReplaySubject, Subscription, throwError} from 'rxjs';
import {Store} from '@ngxs/store';
import {AuthState} from '../../shared/state/auth/auth.state';
import {BankIdLinking} from '../../../tmp-red-web/src/linking/bankIdLinking';
import {SignWorkflow} from '../../../tmp-red-client/workflow/sign';
import {AuthErrorResponse} from '../../../tmp-red-client/security/auth/provider';
import {DirectoryServiceClient} from '../../lab/service-client/directory-service-client';
import {TransactionServiceClient} from '../../lab/service-client/transaction-service-client';
import {TransactionManager} from '../transaction/transaction.manager';

export enum END_OF_YEAR_FLOW_STATE {
    WARMUP = 'WARMUP',
    IN_PROCESS = 'IN_PROCESS',
    SOLE_DONE = 'SOLE_DONE',
    IN_DIVIDENDS_PROCESS = 'IN_DIVIDENDS_PROCESS',
    LLC_DONE = 'LLC_DONE',
    EXIT = 'EXIT'
}

export enum END_OF_YEAR_STATE {
    LOADING,
    MOVING_TO_NEXT_STEP,
    WAITING_FOR_BANKID_AUTHORIZED,
    DONE
}

export enum TAX_CALCULATION_STATE {
    INIT,
    LOADING,
    DONE
}

interface FlowStateMapListItem {
    flowState: END_OF_YEAR_FLOW_STATE;
    steps: YEAR_END_STEP[];
}

type FlowStateMapList = FlowStateMapListItem[];

const stepNameArray = Object.keys(YEAR_END_STEP).slice();

export enum RELATIVE_STEP_POSITION {
    SAME = 'SAME',
    BEFORE = 'BEFORE',
    AFTER = 'AFTER'
}

export enum LINK_TYPE {
    BLOGG = 'BLOGG',
    RESULT_N_TAX = 'RESULT_N_TAX'
}

export interface ArticleLinkOptions {
    step: YEAR_END_STEP;
    companyType: COMPANY_TYPE;
    linkType: LINK_TYPE;
}

export interface ArticleLink {
    step: {
        start: YEAR_END_STEP,
        end: YEAR_END_STEP
    };
    companyType: COMPANY_TYPE;
    linkType: LINK_TYPE;
    link: string;
}

const linkList: ArticleLink[] = [{
    step: {
        start: YEAR_END_STEP.WARMUP,
        end: YEAR_END_STEP.WARMUP
    },
    companyType: COMPANY_TYPE.SOLE_TRADER,
    linkType: LINK_TYPE.BLOGG,
    link: 'http://support.redflag.se/sv/articles/2532765-infor-bokslut-enskild-firma'
}, {
    step: {
        start: YEAR_END_STEP.STARTED,
        end: YEAR_END_STEP.WAITING_FOR_FINAL_SIGNATURE
    },
    companyType: COMPANY_TYPE.SOLE_TRADER,
    linkType: LINK_TYPE.BLOGG,
    link: 'http://support.redflag.se/sv/articles/3577015-under-bokslut-enskild-firma'
}, {
    step: {
        start: YEAR_END_STEP.BOOKS_CLOSED,
        end: YEAR_END_STEP.BOOKS_CLOSED
    },
    companyType: COMPANY_TYPE.SOLE_TRADER,
    linkType: LINK_TYPE.RESULT_N_TAX,
    link: 'http://support.redflag.se/sv/articles/2588789-skatt-och-vinst-for-enskild-firma-i-red-flag'
}, {
    step: {
        start: YEAR_END_STEP.WARMUP,
        end: YEAR_END_STEP.WARMUP
    },
    companyType: COMPANY_TYPE.LIMITED_COMPANY,
    linkType: LINK_TYPE.BLOGG,
    link: 'http://support.redflag.se/sv/articles/2551097-infor-bokslut-aktiebolag'
}, {
    step: {
        start: YEAR_END_STEP.STARTED,
        end: YEAR_END_STEP.WAITING_FOR_FINAL_SIGNATURE
    },
    companyType: COMPANY_TYPE.LIMITED_COMPANY,
    linkType: LINK_TYPE.BLOGG,
    link: 'http://support.redflag.se/sv/articles/3574341-under-bokslut-aktiebolag'
}, {
    step: {
        start: YEAR_END_STEP.BOOKS_CLOSED,
        end: YEAR_END_STEP.EXIT
    },
    companyType: COMPANY_TYPE.LIMITED_COMPANY,
    linkType: LINK_TYPE.RESULT_N_TAX,
    link: 'http://support.redflag.se/sv/articles/2987925-utdelning-och-arsredovisning-genom-red-flag'
}];

export class EndOfYearManager {
    get endOfYearStart(): string {
        const currentyFiscalYearEnd = this._company.details.fiscalInformation.currentFiscalYear.end;
        const nextFiscalYearStart = moment(currentyFiscalYearEnd).add(1, 'month').startOf('month').format('MMMM');

        return nextFiscalYearStart;
    }
    get endOfYearStep(): CompanyYearEndStep { return this._endOfYearStep; }
    get onStateChange(): BehaviorSubject<END_OF_YEAR_STATE> { return this._endOfYearState; }
    get onStepChange(): BehaviorSubject<CompanyYearEndStep> { return this._stepUpdated; }
    get taxCalcState(): BehaviorSubject<TAX_CALCULATION_STATE> { return this._taxCalcState; }
    get stepMetadata(): YearEndMetaData { return this._endOfYearStep.metadata; }
    get currentCompanyType(): COMPANY_TYPE { return this._company.type; }
    get currentCompany(): Company { return Object.assign(this._company, {}); }

    get endOfYearBloggLink(): string {
        return this._bloggLink;
    }
    get endOfYearResultAndTaxLink(): string {
        return (this.currentCompanyType === COMPANY_TYPE.SOLE_TRADER)
            ? 'http://support.redflag.se/foretag-med-red-flag/skatt-och-vinst-for-enskild-firma-i-red-flag'
            : this.endOfYearBloggLink;
    }

    get endOfYearTaxPaymentLink(): string {
        return 'https://skatteverket.se/privat/deklaration/betalakvarskatt.4.12815e4f14a62bc048f106ca.html';
    }

    get endOfYearDividendsAndAnnualReportLink(): string {
        return 'http://support.redflag.se/foretag-med-red-flag/utdelning-och-arsredovisning-genom-red-flag';
    }
    get yearEndBooksClosedSoleLink(): string {
        return `http://support.redflag.se/sv/articles/2588789-skatt-och-vinst-for-enskild-firma-i-red-flag`;
    }
    get neArticleSoleLink(): string {
        return 'http://support.redflag.se/sv/articles/3781590-deklarera-elektroniskt-for-dig-med-enskild-firma';
    }

    get simplificationRuleAmount(): Amount { return this._simplificationRuleAmount; }

    private _currentStep: YEAR_END_STEP;
    private _companyId: string;
    private _company: Company;
    private _directoryServiceClient: DirectoryServiceClient;
    private _endOfYearStep: CompanyYearEndStep;
    private _filter: QueryFilter;
    private _store: Store;
    private _signWorkflow: SignWorkflow;
    private _stepUpdated: BehaviorSubject<CompanyYearEndStep>;
    private _endOfYearState: BehaviorSubject<END_OF_YEAR_STATE>;
    private _waitingForBankIdSubscription: Subscription;
    private _taxCalcState: BehaviorSubject<TAX_CALCULATION_STATE>;
    private _flowStateMapList: FlowStateMapList = [{
        flowState: END_OF_YEAR_FLOW_STATE.WARMUP,
        steps: [YEAR_END_STEP.WARMUP]
    }, {
        flowState: END_OF_YEAR_FLOW_STATE.IN_PROCESS,
        steps: [
            YEAR_END_STEP.STARTED,
            YEAR_END_STEP.CUSTOMER_DONE,
            YEAR_END_STEP.WAITING_FOR_CUSTOMER_TO_SIGN,
            YEAR_END_STEP.CREATING_DOCUMENTS,
            YEAR_END_STEP.WAITING_FOR_FINAL_SIGNATURE
        ]
    }, {
        flowState: END_OF_YEAR_FLOW_STATE.SOLE_DONE,
        steps: [YEAR_END_STEP.BOOKS_CLOSED]
    }, {
        flowState: END_OF_YEAR_FLOW_STATE.IN_DIVIDENDS_PROCESS,
        steps: [
            YEAR_END_STEP.DIVIDENDS,
            YEAR_END_STEP.SHAREHOLDER_INFORMATION,
            YEAR_END_STEP.ANNUAL_REPORT_STORY,
            YEAR_END_STEP.BOARD_MEMBER_INFORMATION,
            YEAR_END_STEP.GENERATING_ANNUAL_REPORT,
            YEAR_END_STEP.ANNUAL_REPORT_DOCUMENTS,
            YEAR_END_STEP.SUBMITTING_ANNUAL_REPORT,
            YEAR_END_STEP.WAITING_FOR_BOLAGSVERKET
        ]
    }, {
        flowState: END_OF_YEAR_FLOW_STATE.LLC_DONE,
        steps: [YEAR_END_STEP.ANNUAL_REPORT_DONE]
    }, {
        flowState: END_OF_YEAR_FLOW_STATE.EXIT,
        steps: [YEAR_END_STEP.EXIT]
    }];
    private _yearEndServiceClient: YearEndServiceClient;
    private _bloggLink = '';
    private _simplificationRuleAmountForYear = {
        '2018': Amount.SEK(169125),
        '2019': Amount.SEK(171875),
        '2020': Amount.SEK(177100),
        '2021': Amount.SEK(183700)
    };
    private _simplificationRuleAmount: Amount;
    private _transactionManager: TransactionManager;

    constructor(
        directoryServiceClient: DirectoryServiceClient,
        store: Store,
        signWorkflow: SignWorkflow,
        yearEndServiceClient: YearEndServiceClient,
        transactionManager: TransactionManager
    ) {
        this._directoryServiceClient = directoryServiceClient;
        this._store = store;
        this._signWorkflow = signWorkflow;
        this._yearEndServiceClient = yearEndServiceClient;
        this._transactionManager = transactionManager;

        this._taxCalcState = new BehaviorSubject(TAX_CALCULATION_STATE.DONE);
        this._endOfYearState = new BehaviorSubject<END_OF_YEAR_STATE>(END_OF_YEAR_STATE.LOADING);
        this._stepUpdated = new BehaviorSubject<CompanyYearEndStep>(this._endOfYearStep);

        this._filter = new QueryFilter();
    }

    static create(
        directoryServiceClient: DirectoryServiceClient,
        store: Store,
        signWorkflow: SignWorkflow,
        yearEndServiceClient: YearEndServiceClient,
        transactionManager: TransactionManager
    ) {
        return new EndOfYearManager(directoryServiceClient, store, signWorkflow, yearEndServiceClient, transactionManager);
    }

    intYearEndForCompany(companyId: string): void {
        this._companyId = companyId;

        this._getCompany(companyId)
            .pipe(mergeMap((company: Company) => {
                this._company = company;

                return this.getEndOfYearFlows(companyId);
            }))
            .subscribe((companyYearEndStepListResponse: CompanyYearEndStepListResponse) => {
                if (companyYearEndStepListResponse.data && companyYearEndStepListResponse.data.length) {
                    const latestFlowFirst = companyYearEndStepListResponse.data.sort((a: CompanyYearEndStep, b: CompanyYearEndStep) => {
                        return (a.fiscalEndDate < b.fiscalEndDate) ? 1 : (a.fiscalEndDate > b.fiscalEndDate ? -1 : 0);
                    });

                    const endOfYearStep = latestFlowFirst[0];

                    this._simplificationRuleAmount = this._getSimplificationRuleAmountForCurrentYear();

                    this._updateStep(endOfYearStep);
                }
            });
    }

    isInEndOfYearProcess(): boolean {
        return !!this._endOfYearStep && !!this._endOfYearStep.step && this._endOfYearStep.step !== YEAR_END_STEP.EXIT;
    }

    isInEndOfYearWarmUp(): boolean {
        return this.isInEndOfYearProcess() && this._endOfYearStep.step === YEAR_END_STEP.WARMUP;
    }

    yearEndProperty(property: string) {
        return this._endOfYearStep && this._endOfYearStep[property];
    }

    currentFiscalYear() {
        const startYear = moment(this._endOfYearStep.fiscalStartDate).format('YYYY');
        const endYear = moment(this._endOfYearStep.fiscalEndDate).format('YYYY');

        return (endYear !== startYear)
            ? startYear + '-' + endYear
            : endYear;
    }

    updateMetaData(yearEndMetadata: YearEndMetaData, filter: QueryFilter) {
        return this._updateStepMetadata(this._companyId, yearEndMetadata, filter);
    }

    startBankIdAuthForNextStep(): Observable<any> {
        this._endOfYearState.next(END_OF_YEAR_STATE.WAITING_FOR_BANKID_AUTHORIZED);

        const credentials = this._getCredentials();

        return this._signWorkflow.start(credentials);
    }

    tryAndOpenBankId(bankIdAuthResponse: BankIdAuthResponse): ReplaySubject<boolean> {
        const autoStartToken: string = bankIdAuthResponse.autostartToken;
        const bankIdLinking = new BankIdLinking(`bankid://autostarttoken=${autoStartToken}&redirect=null`);

        return bankIdLinking.tryAndOpen();
    }

    startPolling(bankIdAuthResponse: any, errorHandler: (err) => void): void {
        this._unsubscribeToWaitingForBankId();

        this._waitingForBankIdSubscription = this._signWorkflow.waitForBankId(bankIdAuthResponse)
            .pipe(mergeMap(() => this._nextStep()))
            .subscribe(
                () => {},
                (err: AuthErrorResponse) => errorHandler(err)
            );
    }

    bankIdErrorHandled(): void {
        this._unsubscribeToWaitingForBankId();
        this._endOfYearState.next(END_OF_YEAR_STATE.DONE);
    }

    cancelOpenBankId(): void {
        this._unsubscribeToWaitingForBankId();
        this._endOfYearState.next(END_OF_YEAR_STATE.DONE);
    }

    getEndOfYearFlows(companyId: string): Observable<CompanyYearEndStepListResponse> {
        return this._yearEndServiceClient.listYearEnds(companyId);
    }

    getEndOfYearStep(filter = this._filter) {
        return this._yearEndServiceClient.getCurrentStep(this._companyId, filter)
            .pipe(
                catchError(() => of(null))
            );
    }

    getNumberOfReceiptsToUpload(companyId: string) {
        const cardTransactionFilter = this._receiptsToUploadFilter();

        return this._transactionManager.queryToQueryResponse(companyId, cardTransactionFilter)
            .pipe(map((resp: QueryResponse<Transaction>) => this._filterReceiptsToUploadForCurrentFiscalYear(resp)));
    }

    moveToNextStep(): Observable<CompanyYearEndStep> {
        return this._nextStep();
    }

    getReportByType(yearEndReportType: YEAR_END_REPORT_TYPE) {
        const status = this._getYearEndReportStatusFilter();

        return this._getReportBasedOnStatus(yearEndReportType, status);
    }

    getTaxCalculation(companyId: string, filter = this._filter): Observable<LLCTaxCalculation | SoleTraderTaxCalculation> {
        const isSole = (this._company.type === COMPANY_TYPE.SOLE_TRADER);
        const isLLC = (this._company.type === COMPANY_TYPE.LIMITED_COMPANY);

        if (isSole) {
            return this._yearEndServiceClient.taxCalculationSole(companyId, filter);
        } else if (isLLC) {
            return this._yearEndServiceClient.taxCalculationLLC(companyId, filter);
        } else {
            return throwError('Company is not a LLC or a sole trader');
        }
    }

    shouldGetTaxReturn(taxCalculation: LLCTaxCalculation | SoleTraderTaxCalculation) {
        return taxCalculation && taxCalculation.taxDue.amount < 0;
    }

    shouldPayTax(taxCalculation: LLCTaxCalculation | SoleTraderTaxCalculation) {
        return taxCalculation && taxCalculation.taxDue.amount >= 0;
    }

    shouldMakePrivateTaxPayment() {
        return this._endOfYearStep.metadata.taxAdjustment && this._endOfYearStep.metadata.taxAdjustment.amount > 0;
    }

    endOfYearFlowState(step: YEAR_END_STEP): END_OF_YEAR_FLOW_STATE {
        return this._flowStateMapList
            .filter((flowStateMapItem) => (flowStateMapItem.steps.indexOf(step) > -1))
            .map((flowStateMapItem) => flowStateMapItem.flowState)
            .shift();
    }

    getArticleLink(opts?: ArticleLinkOptions): string {
        const linkObj = opts && linkList.slice()
            .filter(articleLink => articleLink.companyType === opts.companyType)
            .filter(articleLink => articleLink.linkType === opts.linkType)
            .filter(articleLink => {
                const isSameStart = (this.checkRelativeStepPosition(opts.step, articleLink.step.start) === RELATIVE_STEP_POSITION.SAME);
                const isAfterStart = (this.checkRelativeStepPosition(opts.step, articleLink.step.start) === RELATIVE_STEP_POSITION.AFTER);

                const isBeforeEnd = (this.checkRelativeStepPosition(opts.step, articleLink.step.end) === RELATIVE_STEP_POSITION.BEFORE);
                const isSameEnd = (this.checkRelativeStepPosition(opts.step, articleLink.step.end) === RELATIVE_STEP_POSITION.SAME);

                return  isSameStart || (isAfterStart && isBeforeEnd) || isSameEnd;
            }).shift();

        return linkObj
            ? linkObj.link
            : '';
    }

    checkRelativeStepPosition(thisStepsRelativePosition: YEAR_END_STEP, toThisStep: YEAR_END_STEP): RELATIVE_STEP_POSITION {
        const thisStepIndexRelativeTo = stepNameArray.indexOf(thisStepsRelativePosition.toString());
        const thisStepIndex = stepNameArray.indexOf(toThisStep.toString());

        return this._checkRelativeStepPosition(thisStepIndexRelativeTo, thisStepIndex);
    }

    dateIsInClosedPeriod(companyId: string, date: string, filter: QueryFilter): Observable<boolean> {
        const checkDate = moment(date);

        if (!filter.get('fiscalEndDate')) {
            console.warn(`EndOfYearManager.dateIsInClosedPeriod: fiscalEndDate missing in filter.
companyId: ${companyId},
date: ${date},
filter params: ${JSON.stringify(filter.params(), null, 4)}`);
        }

        return forkJoin([
            this._directoryServiceClient.getCompanyById(companyId),
            this._yearEndServiceClient.getCurrentStep(companyId, filter)
        ])
            .pipe(
                catchError(() => forkJoin([this._directoryServiceClient.getCompanyById(companyId), of(null)])),
                map(([company, step]: [Company, CompanyYearEndStep]) => {
                    const fiscalInfo = company.details.fiscalInformation;

                    const currentFiscalYearStart = moment(fiscalInfo.currentFiscalYear.start);
                    const currentFiscalYearEnd = moment(fiscalInfo.currentFiscalYear.end);
                    const isWithinCurrentFiscalYear = checkDate.isSameOrAfter(currentFiscalYearStart) && checkDate.isSameOrBefore(currentFiscalYearEnd);

                    if (step && !isWithinCurrentFiscalYear) {
                        const stepStartDate = moment(step.fiscalStartDate);
                        const stepEndDate = moment(step.fiscalEndDate);
                        const isInYearEnd = checkDate.isSameOrAfter(stepStartDate) && checkDate.isSameOrBefore(stepEndDate);
                        const stepIsBeforeCustomerDone = (this.checkRelativeStepPosition(step.step, YEAR_END_STEP.CUSTOMER_DONE) === RELATIVE_STEP_POSITION.BEFORE);

                        return !(isInYearEnd && stepIsBeforeCustomerDone);
                    }

                    return !isWithinCurrentFiscalYear;
                }));
    }

    private _checkRelativeStepPosition(checkStepIndex: number, currentCompanyStepIndex: number): RELATIVE_STEP_POSITION {
        return (checkStepIndex === currentCompanyStepIndex)
            ? RELATIVE_STEP_POSITION.SAME
            : this._checkBeforeOrAfter(checkStepIndex, currentCompanyStepIndex);
    }

    private _checkBeforeOrAfter(checkStepIndex: number, currentCompanyStepIndex: number): RELATIVE_STEP_POSITION {
        return checkStepIndex < currentCompanyStepIndex
            ? RELATIVE_STEP_POSITION.BEFORE
            : RELATIVE_STEP_POSITION.AFTER;
    }

    private _filterReceiptsToUploadForCurrentFiscalYear(resp: QueryResponse<Transaction>): number {
        const currentFiscalYearStart = moment(this._endOfYearStep.fiscalStartDate);
        const currentFiscalYearEnd = moment(this._endOfYearStep.fiscalEndDate);
        const currentFiscalYearReceipts = resp.results.filter(receipt => {
            const createdAt = moment(receipt.createdAt);

            return createdAt.isSameOrAfter(currentFiscalYearStart) && createdAt.isSameOrBefore(currentFiscalYearEnd);
        });

        return currentFiscalYearReceipts.length;
    }

    private _getCredentials(): AuthCredentials {
        const identifier = this._store.selectSnapshot(AuthState.identifier);
        return new AuthCredentials({'identifier': identifier, 'strategy': 'bankid'});
    }

    private _receiptsToUploadFilter(): QueryFilter {
        return new QueryFilter()
            .equal('type', [REFERENCE_TYPE.CARD_TRANSACTION])
            .equal('subType', [TRANSACTION_SUB_TYPE.PENDING_RESOLUTION])
            .equal('status', [TRANSACTION_STATUS.PENDING, TRANSACTION_STATUS.REJECTED])
            .equal('orderBy', 'CREATED_AT')
            .offset(0)
            .length(10000);
    }

    private _getCompany(companyId: string): Observable<Company> {
        return this._directoryServiceClient.getCompanyById(companyId);
    }

    private _updateStep(endOfYearStep: CompanyYearEndStep) {
        this._endOfYearStep = endOfYearStep;

        this._currentStep = this._endOfYearStep && this._endOfYearStep.step;
        this._filter = new QueryFilter().equal('fiscalEndDate', this._endOfYearStep.fiscalEndDate);

        this._bloggLink = this._updateBloggLink(this._endOfYearStep);

        this._endOfYearState.next(END_OF_YEAR_STATE.DONE);
        this._stepUpdated.next(this._endOfYearStep);
    }

    private _updateBloggLink(step: CompanyYearEndStep): string {
        return this.getArticleLink({
            step: step.step,
            companyType: step.companyType,
            linkType: this._getLinkType(step.step)
        });
    }

    private _getLinkType(currentStep: YEAR_END_STEP): LINK_TYPE {
        const relativeStepPosition = this.checkRelativeStepPosition(currentStep, YEAR_END_STEP.BOOKS_CLOSED);

        return relativeStepPosition === RELATIVE_STEP_POSITION.SAME || relativeStepPosition === RELATIVE_STEP_POSITION.AFTER
            ? LINK_TYPE.RESULT_N_TAX
            : LINK_TYPE.BLOGG;
    }

    private _unsubscribeToWaitingForBankId() {
        if (this._waitingForBankIdSubscription instanceof Subscription) {
            this._waitingForBankIdSubscription.unsubscribe();
        }
    }

    private _nextStep(filter = this._filter): Observable<CompanyYearEndStep> {
        this._endOfYearState.next(END_OF_YEAR_STATE.MOVING_TO_NEXT_STEP);

        return this._yearEndServiceClient.moveToNextStep(this._companyId, filter)
            .pipe(
                map((endOfYearStep: CompanyYearEndStep) => {
                    this._updateStep(endOfYearStep);
                    return this._endOfYearStep;
                }),
                catchError((err) => {
                    this._endOfYearState.next(END_OF_YEAR_STATE.DONE);
                    return throwError(err);
                })
            );
    }

    private _nullToZero(value: number | null) {
        return (value === null)
            ? 0
            : value;
    }

    private _updateStepMetadata(companyId: string, yearEndMetaData: YearEndMetaData, filter: QueryFilter): Observable<CompanyYearEndStep> {
        yearEndMetaData.otherIncome.amount = this._nullToZero(yearEndMetaData.otherIncome.amount);
        yearEndMetaData.nonDeductibleIncome.amount = this._nullToZero(yearEndMetaData.nonDeductibleIncome.amount);
        yearEndMetaData.otherTax.amount = this._nullToZero(yearEndMetaData.otherTax.amount);
        yearEndMetaData.insuranceIncome.amount = this._nullToZero(yearEndMetaData.insuranceIncome.amount);

        if (yearEndMetaData.accruedIncomeInvoiceServiceNotRendered === false) {
            yearEndMetaData.accruedIncomeInvoiceServiceNotRenderedAmount = null;
            yearEndMetaData.accruedIncomeInvoiceServiceNotRenderedInvoices = null;
        }

        if (yearEndMetaData.accruedIncomeServiceRenderedNotInvoiced === false) {
            yearEndMetaData.accruedIncomeServiceRenderedNotInvoicedAmount = null;
            yearEndMetaData.accruedIncomeServiceRenderedNotInvoicedInvoices = null;
        }

        if (yearEndMetaData.accruedIncomeSupplierServiceNotRendered === false) {
            yearEndMetaData.accruedIncomeSupplierServiceNotRenderedAmount = null;
            yearEndMetaData.accruedIncomeSupplierServiceNotRenderedSupplier = null;
        }

        if (yearEndMetaData.accruedIncomeSupplierServiceRenderedNotInvoiced === false) {
            yearEndMetaData.accruedIncomeSupplierServiceRenderedNotInvoicedAmount = null;
            yearEndMetaData.accruedIncomeSupplierServiceRenderedNotInvoicedSupplier = null;
        }

        return this._yearEndServiceClient.getCurrentStep(companyId, filter)
            .pipe(mergeMap((step: CompanyYearEndStep) => {
                const m = step.metadata;

                m.populate(yearEndMetaData);

                const request = new YearEndMetaDataUpdateRequest(m);

                return this._yearEndServiceClient.updateYearEndMetadata(companyId, request, filter);
        }));
    }

    private _getYearEndReportStatusFilter() {
        const isFinal = this._endOfYearStep.step === YEAR_END_STEP.BOOKS_CLOSED ||
            this._endOfYearStep.step === YEAR_END_STEP.DIVIDENDS ||
            this._endOfYearStep.step === YEAR_END_STEP.ANNUAL_REPORT_DOCUMENTS ||
            this._endOfYearStep.step === YEAR_END_STEP.ANNUAL_REPORT_DONE ||
            this._endOfYearStep.step === YEAR_END_STEP.ANNUAL_REPORT_STORY ||
            this._endOfYearStep.step === YEAR_END_STEP.BOARD_MEMBER_INFORMATION ||
            this._endOfYearStep.step === YEAR_END_STEP.SHAREHOLDER_INFORMATION ||
            this._endOfYearStep.step === YEAR_END_STEP.WAITING_FOR_BOLAGSVERKET ||
            this._endOfYearStep.step === YEAR_END_STEP.GENERATING_ANNUAL_REPORT ||
            this._endOfYearStep.step === YEAR_END_STEP.SUBMITTING_ANNUAL_REPORT ||
            this._endOfYearStep.step === YEAR_END_STEP.EXIT;

        return isFinal
            ? YEAR_END_REPORT_STATUS.FINAL
            : YEAR_END_REPORT_STATUS.DRAFT;
    }

    private _getReportBasedOnStatus(type: YEAR_END_REPORT_TYPE, status: YEAR_END_REPORT_STATUS): YearEndReport {
        return this._endOfYearStep.metadata.reports.find((yearEndReport: YearEndReport) => {
            return (yearEndReport.reportType === type
                && yearEndReport.reportStatus === status);
        });
    }

    private _getSimplificationRuleAmountForCurrentYear() {
        const currentYear = moment().format(`YYYY`);
        const latestPossibleYear = Object.keys(this._simplificationRuleAmountForYear).reverse().shift();

        return this._simplificationRuleAmountForYear[currentYear] || this._simplificationRuleAmountForYear[latestPossibleYear];
    }
}
