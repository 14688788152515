import {Component, OnInit} from '@angular/core';
import {
    Company,
    CompanyData,
    UpdateUserRequest,
    User,
} from '@red/data';
import {AbstractControl, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {BankAccountValidator} from '@red/validators';
import {FormUtils} from '../../../common/form-utils/form-utils';
import {UserManager} from '../../../managers/user/user.manager';
import {AuthState} from '../../../shared/state/auth/auth.state';
import {Store} from '@ngxs/store';
import {RedNotification} from '@red/components';
import {DirectoryServiceClient} from '../../../lab/service-client/directory-service-client';
import {RedFlagShutDownManager} from '../../../managers/red-flag-shut-down/red-flag-shut-down.manager';

@Component({
    selector: 'app-user-view',
    templateUrl: 'user.tpl.html'
})

export class UserViewComponent implements OnInit {
    get notReadOnly(): boolean { return this._notReadOnly; }
    get readOnly(): boolean { return this._readOnly; }
    get hasCompany(): boolean { return this._hasCompany; }
    get user(): User { return this._user; }
    get companyDetailsForm(): FormGroup { return this._companyDetailsForm; }
    get company(): Company { return this._company; }
    get userSettingsForm(): FormGroup { return this._userSettingsForm; }

    private _company: Company;
    private _companyId: string;
    private _hasCompany: boolean;
    private _companyDetailsForm: FormGroup;
    private _directoryServiceClient: DirectoryServiceClient;
    private _formBuilder: FormBuilder;
    private _notification: RedNotification;
    private _store: Store;
    private _userSettingsForm: FormGroup;
    private _user: User;
    private _userManager: UserManager;
    private _redFlagShutDownManager: RedFlagShutDownManager;
    private _notReadOnly: boolean;
    private _readOnly: boolean;

    constructor(
        directoryServiceClient: DirectoryServiceClient,
        formBuilder: FormBuilder,
        notification: RedNotification,
        store: Store,
        userManager: UserManager,
        redFlagShutDownManager: RedFlagShutDownManager
    ) {
        this._directoryServiceClient = directoryServiceClient;
        this._formBuilder = formBuilder;
        this._notification = notification;
        this._store = store;
        this._userManager = userManager;
        this._redFlagShutDownManager = redFlagShutDownManager;
    }

    ngOnInit(): void {
        const today = this._redFlagShutDownManager.today;
        this._notReadOnly = this._redFlagShutDownManager.notReadOnlyState(today);
        this._readOnly = this._redFlagShutDownManager.readOnlyState(today);

        this._companyId = this._store.selectSnapshot(AuthState.companyId);

        this._hasCompany = !!this._companyId;

        this._setUserForm();

        if (this._hasCompany) {
            this._loadCompany();
        }

        this._userManager.getUserByIdentifier(this._store.selectSnapshot(AuthState.identifier))
            .subscribe((user: User) => {
                this._user = user;

                // Here's the root cause of our problem.
                // `this._user` is a `BaseModel` and has all properties that the model define.
                // In this case it's `mainAccount` that's causing problems.
                // In `patchValue` it iterates over all keys of what we pass. So when it tries to iterate over
                // `mainAccount` it throws an error. Basically what it ends up doing is
                // Object.keys(undefined) since value of mainAccount is undefined.

                // A simple work around for this can be to filter out all undefined properties by simply doing
                // JSON.parse(JSON.stringify(this._user))

                this._userSettingsForm.patchValue(JSON.parse(JSON.stringify(this._user)));
            });
    }

    clearingNumberPlaceholder(): string {
        const clearingNo = this._userSettingsForm.get('details.mainAccount.clearingNo').value;
        let bankName;

        if (clearingNo) {
            bankName = BankAccountValidator.validateClearingNumber(clearingNo);
        }

        return bankName || $localize`:Bank account|:Clearing number`;
    }

    enableSaveButton(): boolean {
        return this._userSettingsForm.dirty && this._userSettingsForm.valid;
    }

    onlyNumbersInput(event: KeyboardEvent) {
        FormUtils.onlyNumbersInput(event);
    }

    onlyNumbersPaste(event: ClipboardEvent) {
        FormUtils.onlyNumbersPaste(event);
    }

    save() {
        this._user.populate(this._userSettingsForm.value);
        const request = new UpdateUserRequest({
            'details': this._user.details
        });

        this._directoryServiceClient.updateUser(this._user.id, request)
            .subscribe(() => {
                const msg = $localize`:User settings|Settings were saved.:Your settings has been updated`;

                this._notification.open(msg);
                this._userSettingsForm.markAsPristine();
            }, (err) => {
                const msg = $localize`:Update Error|Settings failed to update.:Your settings failed to be updated`;

                this._notification.errorWithCode(err, msg);
            });
    }

    saveCompanyDetails() {
        this._directoryServiceClient.updateCompanyDetails(this._companyId, this._getUpdateCompanyRequest())
            .subscribe((resp: Company) => {
                this._company = resp;
                const msg = $localize`:Company details|Company details were saved:Your company details were updated`;
                this._notification.open(msg);
                this._companyDetailsForm.markAsPristine();
            }, (err) => {
                const msg = $localize`:Update Error|Settings failed to update.:Your settings failed to be updated`;
                this._notification.errorWithCode(err, msg);
            });
    }

    private _loadCompany() {
        this._directoryServiceClient.getCompanyById(this._companyId)
            .subscribe((company: Company) => {
                this._company = company;

                this._createCompanyDetailsForm(company);
            });
    }

    private _setUserForm() {
        this._userSettingsForm = this._formBuilder.group({
            details: this._formBuilder.group({
                email: [''],
                phone: [''],
                mainAccount: this._formBuilder.group({
                    clearingNo: [''],
                    accountNo: ['']
                })
            })
        });
        this._userSettingsForm.addControl('income', new FormControl(null));
        this._userSettingsForm.addControl('emailNotifications', new FormControl(null));
        this._userSettingsForm.addControl('SMSNotification', new FormControl(null));

        if (this._readOnly) {
            this._userSettingsForm.disable({
                emitEvent: false
            });

            this._userSettingsForm.get('details.mainAccount').enable({
                emitEvent: false
            });
        }

        this._setBankAccountValidators();
    }

    private _getUpdateCompanyRequest(): CompanyData {
        const data = new CompanyData(JSON.parse(JSON.stringify(this._company.details)));
        data.email = this._companyDetailsForm.get('email').value;

        return data;
    }

    private _createCompanyDetailsForm(company: Company): FormGroup {
        this._companyDetailsForm = this._formBuilder.group({
            'email': company.details.email || ''
        });

        if (this._readOnly) {
            this._companyDetailsForm.disable({
                emitEvent: false
            });
        }

        return this._companyDetailsForm;
    }

    private _setBankAccountValidators() {
        const clearingNoField = this._userSettingsForm.get('details.mainAccount.clearingNo');
        const accountNoField = this._userSettingsForm.get('details.mainAccount.accountNo');

        if (clearingNoField) {
            clearingNoField.setValidators([(c: AbstractControl) => {
                const accountNbr = accountNoField.value;
                const clearingAndAccountNbr = c.value + accountNbr;

                const isAccountNbrValid = (BankAccountValidator.validate(clearingAndAccountNbr) || clearingAndAccountNbr === '')
                    ? null
                    : { 'inValidClearingAndAccountNumber': true };
                accountNoField.setErrors(isAccountNbrValid);

                return (BankAccountValidator.validateClearingNumber(c.value) || c.value === '') ? null : { 'inValidClearingNumber': true };
            }]);
        }

        if (accountNoField) {
            accountNoField.setValidators([(c: AbstractControl) => {
                const clearingNbr = clearingNoField.value;
                const clearingAndAccountNbr = clearingNbr + c.value;

                return (BankAccountValidator.validate(clearingAndAccountNbr) || clearingAndAccountNbr === '')
                    ? null
                    : { 'inValidClearingAndAccountNumber': true };
            }]);
        }
    }
}
