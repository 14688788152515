import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router} from '@angular/router';
import {CardTransaction} from '@red/data';
import {Observable, of} from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import {CardTransactionServiceClient} from '../../lab/service-client/card-transaction-service-client';

@Injectable()
export class CardTransactionRouteResolve implements Resolve<CardTransaction> {
    private _cardTransactionServiceClient: CardTransactionServiceClient;
    private _router: Router;

    constructor(
        cardTransactionServiceClient: CardTransactionServiceClient,
        router: Router
    ) {
        this._cardTransactionServiceClient = cardTransactionServiceClient;
        this._router = router;
    }

    resolve(route: ActivatedRouteSnapshot): Observable<CardTransaction> {
        return this._cardTransactionServiceClient.getCardTransaction(route.params['id']).pipe(map(cardTransaction => {
                return cardTransaction;
            })
            , catchError(() => {
                this._router.navigate(['/not-found']);
                return of(null);
            }));
    }
}
