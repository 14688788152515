import {WebServiceClient} from '@red/browser';
import {HttpFetch} from '@red/common';
import {AppConfig} from '../../config/appConfig';
import {PaymentAllowedCountriesResponse} from '@red/data';
import { Observable } from 'rxjs';

export class PaymentServiceClient extends WebServiceClient {
    static create(
        transport: HttpFetch,
        config: AppConfig
    ) {
        return new PaymentServiceClient(transport, config);
    }

    getAllowedCountries(): Observable<PaymentAllowedCountriesResponse> {
        return this._get<PaymentAllowedCountriesResponse>(`${this._getBaseUrl()}/api/payment/allowed-countries`, {}, PaymentAllowedCountriesResponse);
    }
}
