<app-view-card>
    <red-top-toolbar>
        <span redTopToolbarTitle i18n="Settings|Main title for Settings View">Settings</span>
    </red-top-toolbar>
    <app-view-card-infobar class="view-card-infobar">
        <app-red-flag-shut-down-banner></app-red-flag-shut-down-banner>
    </app-view-card-infobar>
    <nav mat-tab-nav-bar>
        <a mat-tab-link [routerLink]="['user']" routerLinkActive #user="routerLinkActive" [active]="user.isActive" i18n="User settings tab name">SETTINGS</a>
    </nav>
    <router-outlet></router-outlet>
</app-view-card>
