<app-view-card>
    <red-top-toolbar>
        <span redTopToolbarTitle i18n="Invoices|Main title for Invoices View">Invoices</span>
        <button
            *ngIf="notReadOnly"
            mat-flat-button color="primary" redTopToolbarAction routerLink="/invoice/create" [disabled]="!hasCompany">
            <mat-icon svgIcon="ui-add"></mat-icon>
            <span i18n="Create new invoice button">New invoice</span>
        </button>
    </red-top-toolbar>
    <app-view-card-infobar class="view-card-infobar">
        <app-red-flag-shut-down-banner></app-red-flag-shut-down-banner>
    </app-view-card-infobar>
    <router-outlet></router-outlet>
</app-view-card>
