import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {AppConfig, AppInfo} from '../../config/appConfig';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {mapStackTrace} from 'sourcemapped-stacktrace';
import {Store} from '@ngxs/store';
import {AuthState} from '../../shared/state/auth/auth.state';
import {RemoteErrorException} from './remote-error-exception';

export enum ErrorLevel {
    ERROR = <any>'ERROR',
    WARN = <any>'WARN',
    INFO = <any>'INFO',
    DEBUG = <any>'DEBUG'
}

export class BaseErrorHandler {
    protected _appInfo: AppInfo;
    protected _http: HttpClient;
    protected _injector: Injector;
    private _baseUrl: string;
    private _companyId: string;
    private _isDebug: boolean;
    private _config: AppConfig;

    constructor(
        http: HttpClient,
        injector: Injector,
        config: AppConfig
    ) {
        this._http = http;
        this._injector = injector;
        this._config = config;
        this._baseUrl = config.get('baseApiUrl');
    }
    async debug(error: Error|RemoteErrorException, context?: object) {
        await this._log(error, ErrorLevel.DEBUG, context);
    }

    async error(error: Error|RemoteErrorException) {
        await this._log(error, ErrorLevel.ERROR);
    }

    async warn(error: Error|RemoteErrorException, context?: object) {
        await this._log(error, ErrorLevel.WARN, context);
    }

    private async _mapStackTrace(stack): Promise<{}> {
        return new Promise((resolve) => {
            if (!stack) {
                resolve(null as unknown);
                return;
            }

            try {
                mapStackTrace(stack, mapped => resolve(mapped.join('\n')), {
                    filter: function (line) {
                        return !/ng:\/\/\/.*\.js/.test(line);
                    }
                });
            } catch (err) {
                resolve(stack);
            }
        });
    }

    protected async _log(error: Error|RemoteErrorException, level: ErrorLevel, context?: object) {
        this._setAppInfo();
        this._setCurrentCompanyId();

        const err = {'level': level, 'message': error.message};
        const stack = error['originalStack'] || error.stack;
        const traceId = error instanceof RemoteErrorException ? error.traceId : null;

        await this._mapStackTrace(stack)
            .then((mappedStack) => {
                if (mappedStack) {
                    err['stack'] = mappedStack;
                } else if (!err.message) {
                    err.message = JSON.stringify(error);
                }

                if (this._isDebug) {
                    console.log(level, context, traceId);
                    // throw err;
                    return Promise.reject(error);
                }

                return this._sendErrorToKafka(err, traceId, context)
                    .then(
                        () => {
                            if (level === ErrorLevel.ERROR) {
                                console.error(error);
                            }
                        },
                        () => console.error(error)
                    );
            });

    }

    protected _setAppInfo() {
        const conf = this._injector.get(AppConfig);
        this._appInfo = conf.get('app');
        this._isDebug = conf.get('debug');
    }

    private _setCurrentCompanyId() {
        const store: Store = this._injector.get(Store);
        this._companyId = store.selectSnapshot(AuthState.companyId);
    }

    private _sendErrorToKafka(err, traceId?: string, context?: object) {
        const navigator = window.navigator.userAgent;
        const headerStr = this._appInfo && `${this._appInfo.name};${this._appInfo.version}(${this._appInfo.build});${this._appInfo.platform};${navigator}`;
        let headers = new HttpHeaders({'content-type': 'application/json', 'X-Redflag-Client': headerStr});

        if (traceId) {
            headers = headers.append('X-Redflag-Trace-Id', traceId);
        }

        err['context'] = {
            'X-Redflag-Client': headerStr,
            'companyId': this._companyId,
            'url': window.location.href
        };

        if (typeof context === 'object') {
            Object.assign(err.context, context);
        }

        const baseUrl = this._config.get('baseApiUrl');

        return this._http.post(`${baseUrl}/api/log`, JSON.stringify(err), {'headers': headers})
            .toPromise();
    }
}

export class GlobalErrorHandler extends BaseErrorHandler implements ErrorHandler {
    async handleError(error: Error) {
        await super.error(error);
    }
}

@Injectable()
export class KafkaLogger extends BaseErrorHandler {
    constructor(
        http: HttpClient,
        injector: Injector,
        config: AppConfig
    ) {
        super(http, injector, config);
    }
}
