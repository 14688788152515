<div class="red-dialog-title" matDialogTitle>
    <button mat-button (click)="close()" tabindex="-1">
        <mat-icon svgIcon="ui-close-white"></mat-icon>
    </button>
    <span class="red-dialog-title-text" i18n="Edit income stream dialog|header">Edit income stream</span>
    <red-context-menu *ngIf="showContextMenu">
        <button *ngIf="canDelete" mat-menu-item redContextMenuItem tabindex="-1" (click)="onMenuDelete()" i18n="Royalty|Context menu| delete option">Delete</button>
        <button mat-menu-item *ngIf="resourceUrl">
            <red-asset-download-button [assetId]="royaltyStatement.assetId" [isContextMenuItem]="true">
                <span i18n="Button|download income stream">Download income stream</span>
            </red-asset-download-button>
        </button>
    </red-context-menu>
</div>
<div class="red-dialog-content" matDialogContent [ngSwitch]="uploading">
    <red-loader-overlay *ngSwitchCase="true"><red-atom-loader></red-atom-loader></red-loader-overlay>
    <div *ngSwitchDefault>
        <red-info-box *ngIf="isRejected">
            <span i18n="Royalty|Edit view|info box|reject message">Wi will contact you about this specification</span>
        </red-info-box>
        <red-list redListDialog>
            <mat-list-item *ngIf="!isWaitingForPayment">
                <red-list-icon mat-list-avatar [item]="royaltyStatement"></red-list-icon>
                <h4 mat-line>{{getDescription(royaltyStatement)}}</h4>
                <time mat-line>{{royaltyStatement.createdDate | moment }}</time>
                <app-display-amount-text type="ROYALTY_STATEMENT" [amount]="royaltyStatement.totalAmount" [preliminaryAmount]="!!isRejected"></app-display-amount-text>
            </mat-list-item>
            <mat-list-item *ngIf="isWaitingForPayment">
                <red-list-icon mat-list-avatar [item]="royaltyStatement"></red-list-icon>
                <h4 mat-line>{{getDescription(royaltyStatement)}}</h4>
                <time mat-line>{{royaltyStatement.createdDate | moment }}</time>
                <app-display-amount-text *ngIf="royaltyStatement.totalAmount" type="ROYALTY_STATEMENT" [amount]="royaltyStatement.totalAmount" [preliminaryAmount]="true">
                </app-display-amount-text>
            </mat-list-item>
        </red-list>

        <div class="asset-container" *ngIf="resourceUrl">
            <red-asset-view [assetId]="royaltyStatement.assetId"></red-asset-view>
        </div>
    </div>
</div>
<mat-dialog-actions class="red-dialog-actions" [ngSwitch]="isRejected">
    <button type="button" mat-flat-button *ngSwitchCase="false" tabindex="-1" (click)="close()">OK</button>
    <button type="button" mat-flat-button *ngSwitchCase="true" redAssetUploadTrigger (fileSelect)="uploadNewImage($event)" i18n="Button|Upload a new image for rejected royalty">Upload new image</button>
</mat-dialog-actions>

