import {
    ONBOARDING_APPLICATION_STATUS,
    ONBOARDING_STATUS,
    ONBOARDING_TRACK,
    OnboardingApplication
} from '@red/onboarding-service-api';
import {RemoteErrorException} from '../../../common/error/remote-error-exception';

export class OnboardingCheckStatus {
    static readonly type = '[Onboarding] CheckStatus';
}

export class OnboardingStart {
    static readonly type = '[Onboarding] start';
    constructor(public payload: {email: string, phone: string }) {}
}

export class OnboardingListApplications {
    static readonly type = '[Onboarding] ListApplications';
}

export class OnboardingListApplicationsSuccessful {
    static readonly type = '[Onboarding] ListApplicationsSuccessful';

    constructor(public payload: {applications: OnboardingApplication[] }) {}
}

export class OnboardingSelectTrack {
    static readonly type = '[Onboarding] SelectTrack';

    constructor(public payload: {track: ONBOARDING_TRACK }) {}
}

export class OnboardingCancelTrack {
    static readonly type = '[Onboarding] OnboardingCancelTrack';
}

export class OnboardingSetStatus {
    static readonly type = '[Onboarding] SetStatus';

    constructor(public payload: {status: ONBOARDING_STATUS }) {}
}

export class OnboardingUpdateApplication {
    static readonly type = '[Onboarding] UpdateApplication';

    constructor(public payload: {application: OnboardingApplication }) {}
}

export class OnboardingUpdateApplicationSuccessful {
    static readonly type = '[Onboarding] UpdateApplicationSuccessful';

    constructor(public payload: {application: OnboardingApplication }) {}
}

export class OnboardingUpdateApplicationError {
    static readonly type = '[Onboarding] UpdateApplicationError';

    constructor(public payload: {error: RemoteErrorException }) {}
}

export class OnboardingUpdateApplicationAndStatus {
    static readonly type = '[Onboarding] UpdateApplicationAndStatus';

    constructor(public payload: {application: OnboardingApplication, status: ONBOARDING_APPLICATION_STATUS }) {}
}

export class OnboardingUpdateApplicationAndStatusSuccessful {
    static readonly type = '[Onboarding] OnboardingUpdateApplicationAndStatusSuccessful';

    constructor(public payload: {application: OnboardingApplication }) {}
}
