import {Component, Inject, OnInit} from '@angular/core';
import {Analytics} from '../../../common/analytics/analytics';
import {AppConfig} from '../../../config/appConfig';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {EndOfYearManager} from '../../../managers/end-of-year/end-of-year.manager';
import {COMPANY_TYPE, Me, User, YEAR_END_STEP} from '@red/data';
import {EndOfYearDialogComponent} from '../end-of-year.dialog';
import {RedNotification} from '@red/components';
import {Store} from '@ngxs/store';
import {AuthManager} from '../../../managers/auth/auth-manager';
import {BankIdAuthResponse} from '@red/browser';
import { AuthErrorResponse } from '../../../../tmp-red-client/security/auth/provider';

export enum CONFIRM_DIALOG_TYPE {
    USER_HELP = 'USER_HELP',
    BANKID_CONFIRM = 'BANKID_CONFIRM',
    MOVE_TO_CUSTOMER_DONE = 'MOVE_TO_CUSTOMER_DONE'
}

@Component({
    selector: 'app-end-of-year-confirm-dialog',
    styleUrls: ['./end-of-year-confirm-dialog.sass', '../end-of-year.dialog.sass'],
    templateUrl: 'end-of-year-confirm-dialog.tpl.html'
})

export class EndOfYearConfirmDialogComponent implements OnInit {
    get year(): string { return this._endOfYearDialog.year; }
    get isUserHelp(): boolean { return (this._confirmDialogType === CONFIRM_DIALOG_TYPE.USER_HELP); }
    get isBankIdConfirm(): boolean { return (this._confirmDialogType === CONFIRM_DIALOG_TYPE.BANKID_CONFIRM); }
    get isMoveToCustomerDone(): boolean { return (this._confirmDialogType === CONFIRM_DIALOG_TYPE.MOVE_TO_CUSTOMER_DONE); }
    get showWarning(): boolean {
        return this._showWarning();
    }

    get isSoleTrader(): boolean { return (this._endOfYearManager.yearEndProperty('companyType') === COMPANY_TYPE.SOLE_TRADER); }
    get isLLC(): boolean { return (this._endOfYearManager.yearEndProperty('companyType') === COMPANY_TYPE.LIMITED_COMPANY); }

    get isSignPreliminary(): boolean { return (this._endOfYearManager.endOfYearStep.step === YEAR_END_STEP.WAITING_FOR_CUSTOMER_TO_SIGN); }
    get isSignFinal(): boolean { return (this._endOfYearManager.endOfYearStep.step === YEAR_END_STEP.WAITING_FOR_FINAL_SIGNATURE); }
    get isGoToSendToBolagsverket(): boolean { return (this._endOfYearManager.endOfYearStep.step === YEAR_END_STEP.ANNUAL_REPORT_DOCUMENTS); }

    private _appConfig: AppConfig;
    private _authManager: AuthManager;
    private _endOfYearDialog: EndOfYearDialogComponent;
    private _endOfYearManager: EndOfYearManager;
    private _dialogRef: MatDialogRef<EndOfYearConfirmDialogComponent>;
    private _confirmDialogType: CONFIRM_DIALOG_TYPE;
    private _store: Store;
    private _notification: RedNotification;

    constructor(
        analytics: Analytics,
        authManager: AuthManager,
        appConfig: AppConfig,
        dialogRef: MatDialogRef<EndOfYearConfirmDialogComponent>,
        endOfYearManager: EndOfYearManager,
        store: Store,
        notification: RedNotification,
        @Inject(MAT_DIALOG_DATA) data: any
    ) {
        this._authManager = authManager;
        this._appConfig = appConfig;
        this._dialogRef = dialogRef;
        this._endOfYearManager = endOfYearManager;
        this._store = store;
        this._notification = notification;

        this._confirmDialogType = data.confirmDialogType;
        this._endOfYearDialog = data.endOfYearDialog;
    }

    ngOnInit() {
        if (this._confirmDialogType === CONFIRM_DIALOG_TYPE.USER_HELP) {
            this._authManager.me()
                .subscribe((me: Me) => this._initIntercomDialog(me.user));
        }
    }

    close() {
        this._dialogRef.close();
    }

    moveToCustomerDone() {
        this.close();
        this._endOfYearDialog.saveMetadataAndMoveToNextStep();
    }

    cancelBankId() {
        this.close();
        this._endOfYearManager.cancelOpenBankId();
    }

    openBankId() {
        this.close();

        this._endOfYearManager.startBankIdAuthForNextStep()
            .subscribe(
                (bankIdAuthResponse: BankIdAuthResponse) => {
                    this._tryAndOpenBankId(bankIdAuthResponse);
                },
                (err: AuthErrorResponse) => this._handleBankIdError(err));
    }

    private _tryAndOpenBankId(bankIdAuthResponse: BankIdAuthResponse): void {
        this._endOfYearManager.tryAndOpenBankId(bankIdAuthResponse)
            .subscribe((startPolling: boolean) => {
                if (startPolling) {
                    this._endOfYearManager.startPolling(bankIdAuthResponse, err => {
                        this._handleBankIdError(err);
                    });
                }
            },
                (err: AuthErrorResponse) => this._handleBankIdError(err));
    }

    private _handleBankIdError(err: AuthErrorResponse) {
        const msg = $localize`:Error code returned from server|Failed to move step in year end flow:Ops, something went wrong.`;
        this._notification.errorWithCode(err.error, msg);

        this._endOfYearManager.bankIdErrorHandled();
    }

    private _showWarning() {
        return this.isSoleTrader && (this.isMoveToCustomerDone || this.isSignPreliminary) &&
            (
                (this._endOfYearManager.endOfYearStep.metadata.otherIncome && this._endOfYearManager.endOfYearStep.metadata.otherIncome.amount > 0)
                || (this._endOfYearManager.endOfYearStep.metadata.nonDeductibleIncome && this._endOfYearManager.endOfYearStep.metadata.nonDeductibleIncome.amount > 0)
            )
            && (this._endOfYearManager.endOfYearStep.metadata.otherTax && this._endOfYearManager.endOfYearStep.metadata.otherTax.amount === 0);
    }

    private _initIntercomDialog(user: User) {
        const appId = this._appConfig.get(`intercom.appId`);
        if (appId) {
            window['Intercom']('boot', {
                'app_id': appId,
                'name': `${user.details.firstName} ${user.details.lastName}`,
                'email': user.details.email,
                'created_at': Math.floor(Date.now() / 1000),
                'custom_launcher_selector': '.intercom',
                'hide_default_launcher': true
            });
        }
    }
}
