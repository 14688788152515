import {COLLECT_RESPONSE_STATUS} from '@red/data';

export class SignPayloadWithBankIdSignature {
    static readonly type = '[Signature] Sign payload';
    constructor(public payload: { payloadBase64: string, userIdentification: string, summary: string }) {}
}

export class CollectBankIdSignature {
    static readonly type = '[Signature] collect';
    constructor(public payload: {collectId: string }) {}
}

export class SignatureStatusUpdate {
    static readonly type = '[Signature] signature status update';
    constructor(public payload: {status: COLLECT_RESPONSE_STATUS}) {}
}
