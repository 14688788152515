import {Component, forwardRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {OnboardingApplication, OnboardingApplicationDataLlcNewBlvSharesForm} from '@red/onboarding-service-api';
import {AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {Actions, Store} from '@ngxs/store';
import {Amount, COUNTRY, CUSTOMER_TYPE, CustomerSearchResult, Me, Stock} from '@red/data';
import {OnboardingApplicationBaseDirective} from '../onboarding-application-base';
import {NationalIdentifierValidator} from '@red/validators';
import {CustomerServiceClient} from '../../../../lab/service-client/customer-service-client';
import {RedNotification} from '@red/components';
import {OnboardingManager} from '../../../../managers/onboarding/onboarding-manager';
import {Subscription} from 'rxjs';
import {ShareholderUtils} from './shares/shares';
import {RoaringManager} from '../../../../managers/roaring/roaring-manager';

export interface ShareholderInfo {
    description: string;
    shares: string;
}

@Component({
    selector: 'app-onboarding-application-llc-new-blc-shares-form',
    styleUrls: ['../onboarding-applications.sass', 'onboarding-application-llc-new-blv-shares-form.sass'],
    templateUrl: 'onboarding-application-llc-new-blv-shares-form.tpl.html',
    providers: [
        {provide: OnboardingApplicationBaseDirective, useExisting: forwardRef(() => OnboardingApplicationLlcNewBlvSharesFormComponent )}
    ]
})
export class OnboardingApplicationLlcNewBlvSharesFormComponent extends OnboardingApplicationBaseDirective implements OnInit, OnChanges, OnDestroy {
    @Input() me: Me;

    get form(): FormGroup { return this._form; }
    get shareholdersFormArray(): FormArray { return (this._form.get('shareholders') as FormArray); }
    get shareholdersList(): ShareholderInfo[] { return this._shareholdersList; }
    get totalNumberOfShares(): number { return this._totalNumberOfShares; }
    get shareDistribution(): string { return this._shareDistribution; }
    get invalidShareDistribution(): boolean { return this._invalidShareDistribution; }

    private _formBuilder: FormBuilder;
    private _customerServiceClient: CustomerServiceClient;
    private _notification: RedNotification;
    private _totalNumberOfSharesSubscribe: Subscription;
    private _totalNumberOfShares = 0;
    private _shareholdersList: ShareholderInfo[] = [];
    private _formSubscribe: Subscription;
    private _shareDistribution: string;
    private _roaringManager: RoaringManager;
    private _invalidShareDistribution = false;

    constructor(
        actions: Actions,
        store: Store,
        formBuilder: FormBuilder,
        customerServiceClient: CustomerServiceClient,
        notification: RedNotification,
        onboardingManager: OnboardingManager,
        roaringManager: RoaringManager
    ) {
        super(actions, store, onboardingManager);
        this._formBuilder = formBuilder;
        this._customerServiceClient = customerServiceClient;
        this._notification = notification;
        this._roaringManager = roaringManager;
    }

    ngOnInit() {
        super.ngOnInit();
        const data = (this.application.data as OnboardingApplicationDataLlcNewBlvSharesForm);

        this._createForm(data);

        this._setFormValues(data);
        this._toggleFormDisabled();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this._form) {
            this._toggleFormDisabled();
        }

        if (this._form && this.me) {
            this._setDefaultFormValues();
            this._setShareholdersList();
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();

        this._totalNumberOfSharesSubscribe.unsubscribe();
        this._formSubscribe.unsubscribe();
    }

    save($event: Event) {
        $event.stopPropagation();
        const application = this._applicationFromForm();
        this._updateApplication(application);
    }

    saveAndSend($event: Event) {
        $event.stopPropagation();
        const application = this._applicationFromForm();
        this._updateApplicationAndSend(application);
    }

    shareholderAddress(i: number): FormGroup {
        return this.shareholdersFormArray.at(i).get('details.address') as FormGroup;
    }

    getUserInformation(index: number) {
        const shareholder = this.shareholdersFormArray.at(index);
        const identifier = shareholder.get('details.identifier').value;
        const formattedIdentification = NationalIdentifierValidator.format(identifier, COUNTRY.SWEDEN);

        this._roaringManager.getIndividualDetails(formattedIdentification)
            .subscribe(
                (response: CustomerSearchResult) => {
                    if (response.data && response.type === CUSTOMER_TYPE.INDIVIDUAL) {
                        shareholder.patchValue({
                            details: {
                                identifier: response.data.identifier,
                                name: response.data.name,
                                address: response.data.address
                            }
                        });
                    }
                },
                () => {
                    const msg = $localize`:RedNotification|err message when search for person:Person not found`;
                    this._notification.open(msg);
                }
            );
    }

    addShareholder() {
        this.shareholdersFormArray.push(this._emptyShareHolderForm());
    }

    removeShareholder(index: number) {
        this.shareholdersFormArray.removeAt(index);

        if (this.shareholdersFormArray.length === 1 && this._totalNumberOfShares) {
            this.shareholdersFormArray.at(0).get('numberOfShares').setValue(this._totalNumberOfShares);
        } else if (this.shareholdersFormArray.length === 0) {
            this.addShareholder();
        }
    }

    distributeShares() {
        const shareholdersFormArray = (this._form.get('shareholders') as FormArray);
        const suggestedShareDistribution = ShareholderUtils.distributeShares(this._totalNumberOfShares, shareholdersFormArray.length);

        suggestedShareDistribution.forEach((numberOfShares, index) => {
            shareholdersFormArray.at(index).get('numberOfShares').setValue(numberOfShares);
        });
    }

    private _applicationFromForm() {
        const formValue = this._form.getRawValue();
        const totalNumberOfShares = formValue.stock.totalNumberOfShares;
        const totalShareholderCapital = Amount.SEK(totalNumberOfShares * 100);

        const application = new OnboardingApplication(this.application);
        application.data = new OnboardingApplicationDataLlcNewBlvSharesForm({shareholders: formValue.shareholders});
        (application.data as OnboardingApplicationDataLlcNewBlvSharesForm).stock = new Stock();
        (application.data as OnboardingApplicationDataLlcNewBlvSharesForm).stock.totalShareholderCapital = totalShareholderCapital;
        (application.data as OnboardingApplicationDataLlcNewBlvSharesForm).stock.totalNumberOfShares = totalNumberOfShares;

        return application;
    }

    private _createForm(data: OnboardingApplicationDataLlcNewBlvSharesForm) {
        let shareholders = [this._emptyShareHolderForm()];

        if (data && data.shareholders && data.shareholders.length > 0) {
            shareholders = data.shareholders.map(() => this._emptyShareHolderForm());
        }

        this._form = this._formBuilder.group({
            stock: this._formBuilder.group({
                totalNumberOfShares: ['', Validators.required]
            }),
            shareholders: this._formBuilder.array(shareholders)
        });

        this._formSubscribe = this._form.valueChanges
            .subscribe(() => {
                if (this._totalNumberOfShares) {
                    this._validateShareDistribution();
                }

                this._setShareholdersList();
            });

        this._totalNumberOfSharesSubscribe = this._form.get('stock.totalNumberOfShares').valueChanges
            .subscribe((totalNumberOfShares) => {
                this._totalNumberOfShares = totalNumberOfShares || 0;
                const shareholdersFormArray = (this._form.get('shareholders') as FormArray);

                if (totalNumberOfShares && shareholdersFormArray.length === 1) {
                    shareholdersFormArray.at(0).get('numberOfShares').enable({
                        emitEvent: false
                    });
                    shareholdersFormArray.at(0).get('numberOfShares').setValue(totalNumberOfShares);
                }

                if (totalNumberOfShares > 0) {
                    this._validateShareDistribution();
                }
            });
    }

    private _setFormValues(data) {
        this._form.patchValue(data);
        this._setShareholdersList();
    }

    private _setShareholdersList() {
        if (this._form) {
            this._shareholdersList = this._form.getRawValue().shareholders.slice()
                .map((shareholder) => {
                    const numberOfShares = shareholder.numberOfShares || '0';
                    const nameId = `${shareholder.details.name} ${shareholder.details.identifier}`.trim();

                    return {
                        description: `${nameId || '?'}`,
                        shares: `${numberOfShares} ${numberOfShares === '1' ? 'aktie' : 'aktier'}`
                    };
                });
        }
    }

    private _numberOfSharesValidator(ctrl: AbstractControl): ValidationErrors | null {
        const onlyDigitsRE = /^[1-9](\d+)?$/;

        return !(onlyDigitsRE).test(ctrl.value)
            ? {
                'numberOfShares': {
                    message: 'Antal aktier måste vara en siffra större än 0'
                }
            }
            : null;
    }

    private _nationalIdentifierValidator(ctrl) {
        const val = ctrl && ctrl.value || '';

        return NationalIdentifierValidator.validate(val, COUNTRY.SWEDEN)
            ? null
            : {
                error: `${val} is not a valid personnummer`
            };
    }

    private _emptyShareHolderForm() {
        return this._formBuilder.group({
            numberOfShares: [0, [Validators.required, this._numberOfSharesValidator]],
            details: this._formBuilder.group({
                name: ['', Validators.required],
                identifier: ['', [Validators.required, this._nationalIdentifierValidator]],
                address: this._formBuilder.group({
                    careOf: [''],
                    lines: this._formBuilder.array([this._formBuilder.control('')]),
                    postalCode: ['', Validators.required],
                    postalTown: ['', Validators.required],
                    countryCode: ''
                })
            })
        });
    }

    private _setDefaultFormValues() {
        const data = (this.application.data as OnboardingApplicationDataLlcNewBlvSharesForm);

        if (Object.keys(data).length === 0) {
            this._setFormValues({
                shareholders: [
                    {
                        numberOfShares: 0,
                        details: {
                            identifier: this.me.user.details.identification,
                            name: this.me.user.details.firstName + ' ' + this.me.user.details.lastName,
                            address: this.me.user.details.addresses.current()
                        }
                    }
                ]
            });
        }
    }

    private _validateShareDistribution() {
        const shareholdersFormArray = (this._form.get('shareholders') as FormArray);
        let distributedShares = 0;
        const zeroShares = [];

        for (let i = 0; i < shareholdersFormArray.length; i += 1) {
            const val = parseInt(shareholdersFormArray.at(i).get('numberOfShares').value || 0, 10);

            if (val === 0) {
                zeroShares.push(val);
            }

            distributedShares += val;
        }

        const distributedSharesText = `${distributedShares} av ${this._totalNumberOfShares} aktier har delats ut.`;

        if (distributedShares === this._totalNumberOfShares && zeroShares.length > 0) {
            shareholdersFormArray.setErrors({
                'ShareDistributionError': {
                    message:  `En eller flera av aktieägarna har tilldelats 0 aktier. ${distributedSharesText}`
                }
            });
        } else if (distributedShares < this._totalNumberOfShares) {
            shareholdersFormArray.setErrors({
                'ShareDistributionError': {
                    message:  `Alla aktier ska delas mellan aktieägarna. ${distributedSharesText}`
                }
            });
        } else if (distributedShares > this._totalNumberOfShares) {
            shareholdersFormArray.setErrors({
                'ShareDistributionError': {
                    message:  `Aktieägarna har tilldelats fler aktier än bolaget kommer inneha. ${distributedSharesText}`
                }
            });
        }

        this._invalidShareDistribution = !!(shareholdersFormArray.errors && shareholdersFormArray.errors['ShareDistributionError']);

        this._shareDistribution = (shareholdersFormArray.errors && shareholdersFormArray.errors['ShareDistributionError'])
            ? shareholdersFormArray.errors['ShareDistributionError'].message
            : distributedSharesText;
    }
}
