import {Amount} from '@red/data';

export enum FINANCIAL_STATEMENT_STATUS {
    INIT,
    STARTED,
    COMPLETE,
    ERROR
}

export interface FinancialStatementModel {
    balance: Amount;
    startingBalance: Amount;
    reservedBalance: Amount;
    vatBalance: Amount;
    shareholderCapital: Amount;
    accruedTax: Amount;
    limit: Amount;
    companyOwed: Amount;
    projectionStatus: FINANCIAL_STATEMENT_STATUS;
    companyOwedStatus: FINANCIAL_STATEMENT_STATUS;
}
