import {Injectable} from '@angular/core';
import {Actions, ofActionSuccessful} from '@ngxs/store';
import {Router} from '@angular/router';
import {AuthLoginStatus, AuthLogout} from '../state/auth/auth.actions';
import {AUTH_LOGIN_STATUS} from '../state/auth/auth.model';
import {ReauthorizeWorkflow} from '../../components/reauthorize/reauthorize-workflow';

@Injectable()
export class AuthStateRouteDispatcher {
    private _actions: Actions;
    private _router: Router;
    private _reauthorizeWorkflow: ReauthorizeWorkflow;

    constructor(
        actions: Actions,
        router: Router,
        reauthorizeWorkflow: ReauthorizeWorkflow
    ) {
        this._actions = actions;
        this._router = router;
        this._reauthorizeWorkflow = reauthorizeWorkflow;
    }

    init() {
        this._handleLogin();
        this._handleLogout();
    }

    private _handleLogin() {
        this._actions
            .pipe(
                ofActionSuccessful(AuthLoginStatus)
            )
            .subscribe((action: AuthLoginStatus) => {
                if (this._reauthorizeWorkflow.isOpen) {
                    return;
                }

                if (action.status === AUTH_LOGIN_STATUS.COMPLETE) {
                    this._router.navigate(['']);
                } else if (action.status === AUTH_LOGIN_STATUS.ONBOARDING) {
                    this._router.navigate(['/onboarding']);
                }
            });
    }

    private _handleLogout() {
        this._actions
            .pipe(
                ofActionSuccessful(AuthLogout)
            )
            .subscribe(value => {
                this._router.navigate(['/login']);
            });
    }
}
