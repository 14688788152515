export enum ANNOUNCEMENT_TYPE {
    WARN = 'WARN',
    INFO = 'INFO'
}

export interface Announcement {
    id: string;
    title: string;
    message: string;
    dismissed?: boolean;
    type?: ANNOUNCEMENT_TYPE;
    expires?: string;
}

export interface AppVersion {
    build: number | null;
    version: string | null;
    newVersionAvailable: boolean | null;
}

export interface AppInfoModel {
    appVersion: AppVersion;
    announcements: Announcement[];
}
