import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Amount, CURRENCY, YearEndMetaData} from '@red/data';
import {Injectable} from '@angular/core';

@Injectable()
export class YearEndMetaDataFormHelper {
    private _formBuilder: FormBuilder;

    constructor(
        formBuilder: FormBuilder
    ) {
        this._formBuilder = formBuilder;
    }

    setupMetadataForm(meta: YearEndMetaData): FormGroup {
        const metaDataFormConfig = this._getMetaDataFormConfig(meta);
        return this._formBuilder.group(metaDataFormConfig);
    }

    private _getMetaDataFormConfig(meta: YearEndMetaData) {
        return  {
            doneUploadingReceipts: [meta.doneUploadingReceipts, Validators.requiredTrue],
            primaryEmployer: [meta.primaryEmployer || false],
            deductibleMileage: [meta.deductibleMileage || 0],
            otherIncome: this._currencyAmountGroup(meta.otherIncome),
            otherTax: this._currencyAmountGroup(meta.otherTax),
            nonDeductibleIncome: this._currencyAmountGroup(meta.nonDeductibleIncome),
            insuranceIncome: this._currencyAmountGroup(meta.insuranceIncome),
            accruedIncomeInvoiceServiceNotRendered: [meta.accruedIncomeInvoiceServiceNotRendered, Validators.required],
            accruedIncomeInvoiceServiceNotRenderedAmount: this._currencyAmountGroup(meta.accruedIncomeInvoiceServiceNotRenderedAmount),
            accruedIncomeInvoiceServiceNotRenderedInvoices: [meta.accruedIncomeInvoiceServiceNotRenderedInvoices || ''],
            accruedIncomeServiceRenderedNotInvoiced: [meta.accruedIncomeServiceRenderedNotInvoiced, Validators.required],
            accruedIncomeServiceRenderedNotInvoicedAmount: this._currencyAmountGroup(meta.accruedIncomeServiceRenderedNotInvoicedAmount),
            accruedIncomeServiceRenderedNotInvoicedInvoices: [meta.accruedIncomeServiceRenderedNotInvoicedInvoices || ''],
            accruedIncomeSupplierServiceNotRendered: [meta.accruedIncomeSupplierServiceNotRendered, Validators.required],
            accruedIncomeSupplierServiceNotRenderedRecurring: [meta.accruedIncomeSupplierServiceNotRenderedRecurring],
            accruedIncomeSupplierServiceNotRenderedAmount: this._currencyAmountGroup(meta.accruedIncomeSupplierServiceNotRenderedAmount),
            accruedIncomeSupplierServiceNotRenderedSupplier: [meta.accruedIncomeSupplierServiceNotRenderedSupplier],
            accruedIncomeSupplierServiceRenderedNotInvoiced: [meta.accruedIncomeSupplierServiceRenderedNotInvoiced, Validators.required],
            accruedIncomeSupplierServiceRenderedNotInvoicedAmount: this._currencyAmountGroup(meta.accruedIncomeSupplierServiceRenderedNotInvoicedAmount),
            accruedIncomeSupplierServiceRenderedNotInvoicedSupplier: [meta.accruedIncomeSupplierServiceRenderedNotInvoicedSupplier],
        };
    }

    private _currencyAmountGroup(amount: Amount = Amount.ZERO(CURRENCY.SEK)): FormGroup {
        return this._formBuilder.group({
            amount: [amount.amount, Validators.required],
            currency: amount.currency
        });
    }
}
