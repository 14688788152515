import {Routes} from '@angular/router';
import {ValidSessionGuard} from '../../security/route/session';
import {ReceiptViewComponent} from './receipt';
import {ReceiptListViewComponent} from './list/list';
import {ReceiptRouteResolve} from './receipt.route.resolve';
import {ReceiptEditViewComponent} from './edit/edit';
import {CardTransactionEditViewComponent} from '../card-transaction/edit/edit';
import {CardTransactionRouteResolve} from '../card-transaction/card-transaction.route.resolve';
import {ConsentGuard} from '../../security/route/consent';
import {FinancialStatementCompanyOwedRouteResolve} from '../../shared/route/financial-statement-company-owed-route-resolve';

export const RECEIPT_ROUTES: Routes = [
    {
        'path':        'receipt',
        'component':   ReceiptViewComponent,
        'canActivate': [ValidSessionGuard, ConsentGuard],
        resolve: {owed: FinancialStatementCompanyOwedRouteResolve},
        'children': [
            {
                'path': '',
                'component': ReceiptListViewComponent,
                'children': [
                    {'path': ':id/edit', 'component': ReceiptEditViewComponent, resolve: {'receipt': ReceiptRouteResolve}},
                    {'path': ':id/transaction', 'component': CardTransactionEditViewComponent, resolve: {'cardTransaction': CardTransactionRouteResolve}},
                ],
            }
        ]
    }
];
