import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Amount, FutureEvent, OBJECT_SUB_TYPE, PAYMENT_TYPE, REFERENCE_TYPE} from '@red/data';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'app-display-amount-text',
    styleUrls: ['./amount-text.sass'],
    templateUrl: './amount-text.tpl.html'
})

export class DisplayAmountTextComponent implements OnInit {
    @Input() amount: Amount;
    @Input() cancelled: boolean;
    @Input() type: REFERENCE_TYPE;
    @Input() direction: PAYMENT_TYPE;
    @Input() preliminaryAmount: boolean;
    @Input() futureEvent: FutureEvent;

    get isNegative(): boolean { return this._isNegative; }
    get hasAmount(): boolean { return this._hasAmount; }

    private _isNegative = false;
    private _hasAmount: boolean;

    ngOnInit() {
        this._hasAmount = !!this.amount || !!(this.futureEvent && this.futureEvent.amount);

        if (this._hasAmount) {
            if (!this.type && this.futureEvent && this.futureEvent.reference && this.futureEvent.reference.type) {
                this.type = this.futureEvent.reference.type;
            }

            this._typeCheck();

            if (!this.amount && this.futureEvent && this.futureEvent.amount) {
                this.amount = this.futureEvent.amount;

                this._futureEventCheck();
            }

            if (this._isNegative && this.amount.amount) {
                const amount = -Math.abs(this.amount.amount);
                this.amount = new Amount({'amount': amount, 'currency': this.amount.currency});
            }
        }
    }

    private _typeCheck() {
        switch (this.type) {
            case REFERENCE_TYPE.BILL:
            case REFERENCE_TYPE.OWNER_WITHDRAWAL:
            case REFERENCE_TYPE.PAYMENT:
            case REFERENCE_TYPE.RECEIPT:
            case REFERENCE_TYPE.SALARY:
            case REFERENCE_TYPE.FINANCIAL_PAYMENT:
                this._isNegative = true;
                break;
            case REFERENCE_TYPE.CARD_TRANSACTION:
               this._isNegative = this.direction === PAYMENT_TYPE.DEBIT;
                break;
            case REFERENCE_TYPE.INVOICE:
                this._isNegative = this.direction === PAYMENT_TYPE.CREDIT;
                break;
            default:
                this._isNegative = false;
                break;
        }
    }

    private _futureEventCheck() {
        switch (this.futureEvent.subType) {
            case OBJECT_SUB_TYPE.PRELIMINARY_TAXES:
                this._isNegative = true;
                break;
        }
    }
}
