
const utils = {
    getDistributedShares(totalNumberOfShares, floorSplit, numberOfShareholders): number[] {
        const diffArray = new Array(totalNumberOfShares - (floorSplit * numberOfShareholders)).fill(1);

        return new Array(numberOfShareholders).fill(floorSplit).map(() => {
            return floorSplit + (diffArray.shift() || 0);
        });
    }
};

export class ShareholderUtils {

    static distributeShares(totalNumberOfShares, numberOfShareholders): number[] {
        const sharesPerShareholder = totalNumberOfShares / numberOfShareholders;
        const floorSplit = Math.floor(sharesPerShareholder);

        return utils.getDistributedShares(totalNumberOfShares, floorSplit, numberOfShareholders);
    }

}
