<red-info-box>
    <span i18n="End of year|dialog banner||warmup|sole trader" *ngIf="isSoleTrader">
        Year end starts 1 {{endOfYearStart}}. Other info about {{year}} <a href="{{endOfYearBloggLink}}" target="_blank">read more</a>
    </span>
    <span i18n="End of year|dialog banner|warmup|llc" *ngIf="isLLC">
        LLC TEXT: Year end starts 1 {{endOfYearStart}} <a href="{{endOfYearBloggLink}}" target="_blank">read more</a>
    </span>
</red-info-box>
<div class="end-of-year-dialog-content">
    <app-end-of-year-metadata-form
        [metadataFormInput]="metadataForm"
        (metadataFormOutput)="saveMetaData($event)"
    ></app-end-of-year-metadata-form>

    <app-end-of-year-accumulated-salary></app-end-of-year-accumulated-salary>

</div>
