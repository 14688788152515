<app-view-card>
    <red-top-toolbar>
        <span redTopToolbarTitle i18n="Dashboard|Main title for dashboard">Dashboard</span>
    </red-top-toolbar>

    <app-view-card-infobar class="view-card-infobar">
        <app-red-flag-shut-down-banner></app-red-flag-shut-down-banner>
    </app-view-card-infobar>

    <app-red-flag-shut-down-trigger></app-red-flag-shut-down-trigger>

    <app-onboarding-alert>
        <p class="dashboard-onboarding" i18n="Onboarding|dashboard|info text">About on boarding</p>
    </app-onboarding-alert>

    <div class="company-details" [ngClass]="{'read-only': !notReadOnlyState}">
        <div class="company-details-section">
            <h3 class="company-details-section-header" i18n="Dashboard|Company details|header 1">Company details for {{company.name}}</h3>

            <ul class="company-details-section-list">
                <li class="company-details-section-list-item">
                    <h4 class="description" i18n="Dashboard|Company details|identifier">Identifier</h4>
                    <span class="info">{{company.identifier}}</span>
                </li>
            </ul>
        </div>
        <div class="company-details-section">
            <h3 class="company-details-section-header" i18n="Dashboard|Company details|header 2">Bank details</h3>

            <ul class="company-details-section-list">
                <li class="company-details-section-list-item">
                    <h4 class="description" i18n="ashboard|Company details|account nr">Account nr</h4>
                    <span class="info">649-5634</span>
                </li>
            </ul>
        </div>
    </div>

</app-view-card>


