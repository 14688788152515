<app-view-card>
    <red-top-toolbar>
        <span redTopToolbarTitle i18n="Downloadable reports|View header">Downloadable reports</span>
    </red-top-toolbar>
    <app-view-card-infobar class="view-card-infobar">
        <app-red-flag-shut-down-banner></app-red-flag-shut-down-banner>
    </app-view-card-infobar>

    <div red-grid>
        <red-downloadable-reports
            [company]="company"
            [beingDownloaded]="beingDownloaded"
            (downloadReportRequest)="downloadReport($event)"
        ><div class="profit-loss-report-description info-text" i18n="Downloadable reports|Profit loss report description">About the profit loss report</div></red-downloadable-reports>

        <h3 class="section-header">Kontoutdrag</h3>
        <div class="red-row account-statement-row">
            <div class="red-col right">
                <button
                    mat-flat-button
                    color="primary"
                    [ngClass]="{'downloading downloadingDocument report-being-downloaded': downloadingAccountStatement}"
                    [disabled]="downloadingAccountStatement"
                    (click)="downloadAccountStatement()"
                >Ladda ner kontoutdrag <mat-spinner *ngIf="downloadingAccountStatement" [diameter]="14"></mat-spinner></button>
            </div>
        </div>

        <ng-container *ngIf="googleDriveLink">
            <h3 class="section-header">Google drive</h3>
            <div class="info-text google-drive-info">
                Din verifikationslista, dina verifikationer och sie-filer finns på google drive. Klicka på länken nedan för att se/ladda ner dem.
            </div>
            <div class="red-row google-drive-row">
                <div class="red-col right">
                    <p><a href="{{googleDriveLink}}" target="_blank">{{googleDriveLink}}</a></p>
                </div>
            </div>
        </ng-container>
    </div>
</app-view-card>
