<mat-expansion-panel class="mat-expansion-panel" [ngClass]="statusClassName">
    <mat-expansion-panel-header>
        <mat-panel-title i18n="Onboarding|Panel title for BLV Share Form">
            BLV Shares Form
        </mat-panel-title>
        <mat-panel-description></mat-panel-description>
    </mat-expansion-panel-header>
    <form [formGroup]="form" red-form>
        <fieldset formGroupName="stock" class="no-bottom-margin">
            <legend i18n="Onboarding|LLC New blv shares form|Select sharecapital">Select sharecapital</legend>

            <div class="info-text margin-bottom" i18n="Onboarding|LLC New blv shares form|info about shares">About shares...</div>

            <div class="red-row">
                <mat-radio-group formControlName="totalNumberOfShares" class="total-number-of-shares-group">
                    <mat-radio-button [value]="250" i18n="Onboarding|LLC New blv shares form|250 shares at 1000kr">25 000kr (250 shares at 100kr)</mat-radio-button>
                    <mat-radio-button [value]="500" i18n="Onboarding|LLC New blv shares form|250 shares at 1000kr">50 000kr (500 shares at 100kr)</mat-radio-button>
                    <mat-radio-button [value]="1000" i18n="Onboarding|LLC New blv shares form|250 shares at 1000kr">100 000kr (1000 shares at 100kr)</mat-radio-button>
                </mat-radio-group>
            </div>
        </fieldset>

        <ng-container formGroupName="shareholders">
            <fieldset *ngFor="let alias of shareholdersFormArray.controls; let i=index">
                <ng-container [formGroupName]="i">
                    <legend>
                        <span>Aktieägare {{i + 1}}</span>
                        <span
                            class="remove-shareholder"
                            *ngIf="!form.disabled"
                            (click)="removeShareholder(i)"
                        >Ta bort</span>
                    </legend>
                    <div class="red-row identifier-row" formGroupName="details">
                        <mat-form-field>
                            <input
                                matInput
                                i18n-placeholder="Onboarding|LLC New blv board form|Personal identifier"
                                placeholder="Personal identifier"
                                formControlName="identifier"
                                autocomplete="off"
                            />
                        </mat-form-field>
                        <div class="red-col button-col" *ngIf="!form.disabled">
                            <button
                                mat-flat-button
                                color="primary"
                                [disabled]="form.disabled"
                                (click)="getUserInformation(i)"
                                i18n="Onboarding|LLC New blv board form|Get user information"
                            >Get user information</button>
                        </div>
                    </div>
                    <div class="red-row" formGroupName="details">
                        <mat-form-field>
                            <input
                                matInput
                                i18n-placeholder="Onboarding|LLC New blv shares form|Shareholder name"
                                placeholder="Name"
                                formControlName="name"
                                autocomplete="off"
                            />
                        </mat-form-field>
                    </div>
                    <div class="red-row">
                        <div class="red-row distribute-shares-row">
                            <mat-form-field>
                                <input
                                    matInput
                                    i18n-placeholder="Onboarding|LLC New blv shares form|Number of shares"
                                    placeholder="Number of shares"
                                    formControlName="numberOfShares"
                                    autocomplete="off"
                                />
                                <mat-hint>Antal aktier måste vara en siffra större än 0</mat-hint>
                            </mat-form-field>
                            <div class="red-col distribute-shares-button-col" *ngIf="shareholdersFormArray.length > 1 && !form.disabled">
                                <button mat-flat-button type="button" (click)="distributeShares()">Fördela aktierna jämnt</button>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <red-form-address
                    [addressFormGroup]="shareholderAddress(i)"
                    [showCountryCode]="false"
                    [addRemoveAddressLines]="false"
                >
                </red-form-address>
            </fieldset>
            <div class="add-shareholder-button-container">
                <button mat-flat-button *ngIf="!form.disabled" class="add-button" type="button" (click)="addShareholder()">Lägg till aktieägare</button>
            </div>
        </ng-container>

        <ng-container *ngIf="totalNumberOfShares">
            <h3 class="section-header">Aktiefördelning</h3>
            <ol>
                <li *ngFor="let shareholder of shareholdersList; let i=index">
                    <div>
                        <span>{{shareholder.description}}</span>
                        <span>{{shareholder.shares}}</span>
                    </div>
                </li>
            </ol>
            <div class="share-distribution-info" [ngClass]="{'warning-text': invalidShareDistribution}">
                {{shareDistribution}}
            </div>
            <div class="distribute-shares-button-container" *ngIf="!form.disabled && shareholdersFormArray.length > 1">
                <button mat-flat-button type="button" (click)="distributeShares()">Fördela aktierna jämnt</button>
            </div>
        </ng-container>

        <div class="actions" *ngIf="!form.disabled">
            <button mat-flat-button color="primary" [disabled]="form.invalid" type="button" (click)="save($event)" i18n="Onboarding|Save button">Save</button>
            <button mat-flat-button color="primary" [disabled]="form.invalid" type="button" (click)="saveAndSend($event)" i18n="Onboarding|Save & Send button">Save & Send</button>
        </div>
    </form>
</mat-expansion-panel>
