import {HttpFetch} from '@red/common';
import {AppConfig} from '../../config/appConfig';
import {Observable} from 'rxjs';
import {
    CompanyYearEndStep,
    CompanyYearEndStepListResponse, LLCTaxCalculation,
    SoleTraderTaxCalculation,
    YearEndMetaDataUpdateRequest
} from '@red/data';
import {QueryFilter} from '@red/browser';
import {QueryParam} from '../../common/query-param';
import {WebServiceClient} from './web-service-client';

export class YearEndServiceClient extends WebServiceClient {
    static create(transport: HttpFetch, config: AppConfig) {
        return new YearEndServiceClient(transport, config);
    }

    getCurrentStep(companyId: string, filter?: QueryFilter): Observable<CompanyYearEndStep> {
        const query = QueryParam.asFullQueryString(filter);
        const url = `${this._getBaseUrl()}/api/end-of-year/${companyId}/step${query}`;

        return this._get<CompanyYearEndStep>(url, {}, CompanyYearEndStep);
    }

    listYearEnds(companyId: string): Observable<CompanyYearEndStepListResponse> {
        const url = `${this._getBaseUrl()}/api/end-of-year/company/${companyId}`;

        return this._get<CompanyYearEndStepListResponse>(url, {}, CompanyYearEndStepListResponse);
    }

    moveToNextStep(companyId: string, filter?: QueryFilter): Observable<CompanyYearEndStep> {
        const query = QueryParam.asFullQueryString(filter);
        const url = `${this._getBaseUrl()}/api/end-of-year/${companyId}/next${query}`;

        return this._put<CompanyYearEndStep>(url, null, {}, CompanyYearEndStep);
    }

    taxCalculationLLC(companyId: string, filter?: QueryFilter): Observable<LLCTaxCalculation> {
        const query = QueryParam.asFullQueryString(filter);
        const url = `${this._getBaseUrl()}/api/end-of-year/${companyId}/tax/calculation/llc${query}`;

        return this._post<LLCTaxCalculation>(url, null, {}, LLCTaxCalculation);
    }

    taxCalculationSole(companyId: string, filter?: QueryFilter): Observable<SoleTraderTaxCalculation> {
        const query = QueryParam.asFullQueryString(filter);
        const url = `${this._getBaseUrl()}/api/end-of-year/${companyId}/tax/calculation/soleTrader${query}`;

        return this._post<SoleTraderTaxCalculation>(url, null, {}, SoleTraderTaxCalculation);
    }

    updateYearEndMetadata(companyId: string, request: YearEndMetaDataUpdateRequest, filter?: QueryFilter): Observable<CompanyYearEndStep> {
        const query = QueryParam.asFullQueryString(filter);
        const url = `${this._getBaseUrl()}/api/end-of-year/${companyId}/metadata${query}`;

        return this._put<CompanyYearEndStep>(url, request, {}, CompanyYearEndStep);
    }
}
