import {NgModule} from '@angular/core';
import {SignatureBankIdCollectDialogComponent} from './signature-bankid-collect/signature-bankid-collect-dialog';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {RedLoaderModule} from '@red/components';
import {MatIconModule} from '@angular/material/icon';
import {CommonModule} from '@angular/common';

@NgModule({
    imports: [
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        RedLoaderModule,
        CommonModule
    ],
    exports: [],
    declarations: [SignatureBankIdCollectDialogComponent],
    providers: [],
})
export class SignatureModule {
}
