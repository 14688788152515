import {WebServiceClient} from './web-service-client';
import {HttpFetch, HttpFetchResponse} from '@red/common';
import {AppConfig} from '../../config/appConfig';
import {SignPayloadRequest} from '@red/data';
import {Observable} from 'rxjs';

export class SignatureServiceClient extends WebServiceClient {
    static create(transport: HttpFetch, config: AppConfig) {
        return new SignatureServiceClient(transport, config);
    }

    signPayload(request: SignPayloadRequest): Observable<HttpFetchResponse> {
        const url = `${this._getBaseUrl()}/api/signature/sign/bankid`;
        return this._post(url, request, {});
    }

    collect(collectId: string): Observable<HttpFetchResponse> {
        const url = `${this._getBaseUrl()}/api/signature/collect/bankid/${collectId}`;
        return this._get(url, {});
    }
}
