import {Component, HostBinding, OnInit} from '@angular/core';
import {AuthState} from '../../shared/state/auth/auth.state';
import {Store} from '@ngxs/store';
import {RedFlagShutDownManager} from '../../managers/red-flag-shut-down/red-flag-shut-down.manager';

@Component({
    selector: 'app-invoice-view',
    templateUrl: 'invoice.tpl.html'
})
export class InvoiceViewComponent implements OnInit {
    @HostBinding('class.scroll-view') cssClass = true;

    get hasCompany(): boolean { return this._hasCompany; }
    get notReadOnly(): boolean { return this._notReadOnly; }

    private _store: Store;
    private _hasCompany: boolean;
    private _redFlagShutDownManager: RedFlagShutDownManager;
    private _notReadOnly: boolean;

    constructor(
        store: Store,
        redFlagShutDownManager: RedFlagShutDownManager
    ) {
        this._store = store;
        this._redFlagShutDownManager = redFlagShutDownManager;
    }

    ngOnInit(): void {
        const companyId = this._store.selectSnapshot(AuthState.companyId);
        this._hasCompany = !!companyId;

        const today = this._redFlagShutDownManager.today;
        this._notReadOnly = this._redFlagShutDownManager.notReadOnlyState(today);
    }
}
