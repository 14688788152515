import {
    AddSignatoryRequest,
    ANNUAL_REPORT_COLLECT_STATUS,
    AnnualReport, AnnualReportCollectResponse, AnnualReportListResponse, AnnualReportSignRequest,
    AnnualReportUpdateRequest,
    DividendsDecisionRequest, DividendsPaymentRequest, DividendsPaymentResponse, PossibleDividends
} from '@red/data';
import {Observable, of, throwError} from 'rxjs';
import {AnnualReportServiceClient} from '../../lab/service-client/annual-report-service-client';
import {delay, retryWhen} from 'rxjs/operators';
import {mergeMap} from 'rxjs/operators';
import {QueryFilter} from '@red/browser';

export class AnnualReportManager {
    private _annualReportServiceClient: AnnualReportServiceClient;

    constructor (
        annualReportServiceClient: AnnualReportServiceClient
    ) {
        this._annualReportServiceClient = annualReportServiceClient;
    }

    static create(annualReportServiceClient: AnnualReportServiceClient) {
        return new AnnualReportManager(annualReportServiceClient);
    }

    getAnnualReport(annualReportId: string): Observable<AnnualReport> {
        return this._annualReportServiceClient.getAnnualReport(annualReportId);
    }

    getPossibleDividends(annualReportId: string): Observable<PossibleDividends> {
        return this._annualReportServiceClient.getPossibleDividends(annualReportId);
    }

    makeDividendsDecision(dividendsDecisionRequest: DividendsDecisionRequest): Observable<AnnualReport> {
        return this._annualReportServiceClient.makeDividendsDecision(dividendsDecisionRequest);
    }

    dividendsPayout(annualReportId: string, request: DividendsPaymentRequest, query?: QueryFilter): Observable<DividendsPaymentResponse> {
        return this._annualReportServiceClient.dividendsPayout(annualReportId, request, query);
    }

    updateAnnualReport(annualReportId: string, annualReportUpdateRequest: AnnualReportUpdateRequest): Observable<AnnualReport> {
        return this._annualReportServiceClient.updateAnnualReport(annualReportId, annualReportUpdateRequest);
    }

    list(companyId: string): Observable<AnnualReportListResponse> {
        return this._annualReportServiceClient.list(companyId);
    }

    addSignatory(annualReportId: string, addSignatoryRequest: AddSignatoryRequest): Observable<AnnualReport> {
        return this._annualReportServiceClient.addSignatory(annualReportId, addSignatoryRequest);
    }

    removeSignatory(annualReportId: string, identifier: string): Observable<AnnualReport> {
        return this._annualReportServiceClient.removeSignatory(annualReportId, identifier);
    }

    startSign(request: AnnualReportSignRequest) {
        return this._annualReportServiceClient.sign(request);
    }

    waitForBankId(annualReportId: string, collectToken: string): Observable<AnnualReportCollectResponse> {
        return this._poll(annualReportId, collectToken);
    }

    private _poll(annualReportId: string, collectToken: string): Observable<AnnualReportCollectResponse> {
        let retries = 0;
        const maxRetries = 40;
        return this._annualReportServiceClient.collect(annualReportId, collectToken)
            .pipe(
                mergeMap((response: AnnualReportCollectResponse) => {
                    if (this._shouldRetry(response.status)) {
                        return throwError(response);
                    }

                    return of(response);
                }),
                retryWhen<AnnualReportCollectResponse>(errors => {
                    retries += 1;
                    return errors.pipe(
                        mergeMap((err) => {
                            if (maxRetries === retries) {
                                return throwError(new Error(`Polling failed after ${maxRetries} attempts`));
                            }

                            if (this._shouldThrowError(err.status)) {
                                return throwError(err);
                            }

                            return of(retries);
                        }),
                        delay(2000)
                    );
                })
            );
    }

    private _shouldRetry(status: ANNUAL_REPORT_COLLECT_STATUS): boolean {
        return status === ANNUAL_REPORT_COLLECT_STATUS.PENDING;
    }

    private _shouldThrowError(status: ANNUAL_REPORT_COLLECT_STATUS): boolean {
        return status === ANNUAL_REPORT_COLLECT_STATUS.FAILED;
    }
}
