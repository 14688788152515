import {Component, HostBinding, Inject, LOCALE_ID, OnInit} from '@angular/core';
import {AppConfig} from '../../config/appConfig';
import {ActivatedRoute} from '@angular/router';
import {AuthManager} from '../../managers/auth/auth-manager';
import {Me, User} from '@red/data';

@Component({
    selector: 'app-support-view',
    templateUrl: './support.tpl.html'
})

export class SupportViewComponent implements OnInit {
    @HostBinding('class.scroll-view') cssClass = true;

    private _appConfig: AppConfig;
    private _localeId: string;
    private _route: ActivatedRoute;
    private _authManager: AuthManager;

    constructor(
        appConfig: AppConfig,
        route: ActivatedRoute,
        authManager: AuthManager,
        @Inject(LOCALE_ID) localeId: string
    ) {
        this._appConfig = appConfig;
        this._localeId = localeId;
        this._route = route;
        this._authManager = authManager;
    }

    ngOnInit(): void {
        this._authManager.me()
            .subscribe((me: Me) => {
                this._startIntercom(me.user);
            });
    }

    private _startIntercom(user: User) {
        const appId = this._appConfig.get(`intercom.appId`);
        if (appId) {
            window['Intercom']('boot', {
                'app_id': appId,
                'name': `${user.details.firstName} ${user.details.lastName}`,
                'email': user.details.email,
                'created_at': Math.floor(Date.now() / 1000),
                'custom_launcher_selector': '.intercom',
                'hide_default_launcher': true
            });
        }
    }
}
