import {Component, forwardRef, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {
    ONBOARDING_APPLICATION_STATUS,
    OnboardingApplication,
    OnboardingApplicationDataTermsOfService
} from '@red/onboarding-service-api';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Actions, Store} from '@ngxs/store';
import {OnboardingApplicationBaseDirective} from '../onboarding-application-base';
import {OnboardingManager} from '../../../../managers/onboarding/onboarding-manager';
import {TimeManager} from '../../../../managers/time/time.manager';
import {RedDialog} from '@red/components';
import {TermsDialogComponent} from './terms-dialog/terms-dialog.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-onboarding-application-terms-of-service',
    styleUrls: ['../onboarding-applications.sass', 'onboarding-application-terms-of-service.sass'],
    templateUrl: 'onboarding-application-terms-of-service.tpl.html',
    providers: [
        {
            provide: OnboardingApplicationBaseDirective,
            useExisting: forwardRef(() => OnboardingApplicationTermsOfServiceComponent)
        }
    ]
})
export class OnboardingApplicationTermsOfServiceComponent extends OnboardingApplicationBaseDirective implements OnInit, OnChanges {
    get useBokioTerms(): boolean {
        return this._useBokioTerms;
    }

    get useBokioTermsDate(): string {
        return this._useBokioTermsDate;
    }

    get allPreviousApplicationsAreSentOrDone(): boolean {
        return this._allPreviousApplicationsAreSentOrDone;
    }

    get form(): FormGroup {
        return this._form;
    }

    get hasAgreedToTheCardAgreement(): boolean {
        return this._hasAgreedToTheCardAgreement;
    }

    private _formBuilder: FormBuilder;
    private _allPreviousApplicationsAreSentOrDone = false;
    private _hasAgreedToTheCardAgreement = false;

    private _useBokioTermsDate: string = '2022-04-01';
    private _useBokioTerms: boolean = false;

    private _dialog: RedDialog;

    constructor(
        formBuilder: FormBuilder,
        actions: Actions,
        store: Store,
        onboardingManager: OnboardingManager,
        dialog: RedDialog,
    ) {
        super(actions, store, onboardingManager);

        this._formBuilder = formBuilder;
        this._dialog = dialog;
    }

    ngOnInit() {
        super.ngOnInit();
        const data = (this.application.data as OnboardingApplicationDataTermsOfService);

        this._hasAgreedToTheCardAgreement = !!data.creditCardAgreement;
        this._useBokioTerms = TimeManager.isSameOrAfter(this.application.created, this._useBokioTermsDate);

        this._createForm();
        this._form.patchValue(data);
        this._toggleFormDisabled();
        this._checkPreviousSentOrDone();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this._form) {
            this._toggleFormDisabled();
        }
        this._checkPreviousSentOrDone();
    }

    saveAndSend($event: Event) {
        $event.stopPropagation();
        const application = this._applicationFromForm();
        this._updateApplicationAndSend(application);
    }

    openTermsDialog() {
        this._dialog.open(TermsDialogComponent);
    }

    private _checkPreviousSentOrDone() {
        this._onboardingManager.listApplications(this.application.onboardingId)
            .subscribe((apps) => {
                const prevs = this._onboardingManager.getPreviousSteps(this.application.type, apps);
                const sentOrDone = prevs.slice().filter((appl: OnboardingApplication) => {
                    return appl.status !== ONBOARDING_APPLICATION_STATUS.DRAFT;
                });

                this._allPreviousApplicationsAreSentOrDone = !!(sentOrDone.length === prevs.length);
            });
    }

    private _createForm() {
        this._form = this._formBuilder.group({
            termsAndConditions: [false, Validators.requiredTrue],
            creditCardAgreement: [false],
            voucherCode: [''],
        });
    }

    private _applicationFromForm() {
        const application = new OnboardingApplication(this.application);
        application.data = new OnboardingApplicationDataTermsOfService(this._form.getRawValue());
        return application;
    }
}
