import {Routes} from '@angular/router';
import {SupportViewComponent} from './support';
import {ValidSessionGuard} from '../../security/route/session';
import {ConsentGuard} from '../../security/route/consent';

export const SUPPORT_ROUTES: Routes = [
    {
        path: 'support',
        component: SupportViewComponent,
        canActivate: [ValidSessionGuard, ConsentGuard],
    }
];
