import {FormGroup, FormArray, FormControl, FormBuilder} from '@angular/forms';
import {Injectable} from '@angular/core';
import {BaseModel} from '@red/core';

@Injectable()
export class FormMapper {
    private _formBuilder: FormBuilder;

    constructor(
        formBuilder: FormBuilder
    ) {
        this._formBuilder = formBuilder;
    }

    createFormControl(value: any): FormControl {
        return this._formBuilder.control(value);
    }

    createFormArray(arr: any[], mapUndefined?: boolean): FormArray {
        const formArray = arr.map(item => {
            if (item instanceof BaseModel) {
                return this.createFormGroup(item, mapUndefined);
            } else  if (item !== undefined) {
                return this.createFormControl(item);
            }
        }).filter(item => item !== undefined);

        return this._formBuilder.array(formArray);
    }

    createFormGroup(model: BaseModel, mapUndefined?: boolean): FormGroup {
        const controls = {};

        Object.keys(model).forEach(prop => {
            let value = model[prop],
                property,
                propertyInstance;

            if (model instanceof BaseModel) {
                property = model['_properties'][prop];
                if (!value && !(typeof value === 'string' || typeof value === 'number' )) {
                    propertyInstance = new (property._type.bind.apply(property._type, [property._type]))();
                    value = propertyInstance instanceof BaseModel ? (property.isRepeated ? [propertyInstance] : propertyInstance) : value;
                }

                if (property.isRepeated) {
                    controls[prop] = this.createFormArray(value || [], mapUndefined);
                } else if (value instanceof BaseModel) {
                    controls[prop] = this.createFormGroup(value, mapUndefined);
                } else if (mapUndefined || value !== undefined) {
                    controls[prop] = this.createFormControl(value);
                }
            } else if (mapUndefined || value !== undefined) {
                controls[prop] = this.createFormControl(value);
            }
        });
        return this._formBuilder.group(controls);
    }

}
