import {Routes} from '@angular/router';
import {ValidSessionGuard} from '../../security/route/session';
import {DownloadableReportsViewComponent} from './downloadable-reports';

export const DOWNLOADABLE_REPORTS_ROUTES: Routes = [
    {
        path: 'downloadable-reports',
        component: DownloadableReportsViewComponent,
        canActivate: [ValidSessionGuard]
    }
];
