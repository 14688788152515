import {AppConfig} from '../../config/appConfig';
import {Observable} from 'rxjs';
import {Amount, Loan, CreateOwnerWithdrawalRequest} from '@red/data';
import {WebServiceClient} from './web-service-client';
import {HttpFetch} from '@red/common';

export class WithdrawalServiceClient extends WebServiceClient {
    static create(transport: HttpFetch, config: AppConfig) {
        return new WithdrawalServiceClient(transport, config);
    }

    createWithdrawal(companyId: string, request: CreateOwnerWithdrawalRequest): Observable<Loan> {
        const url = `${this._getBaseUrl()}/api/financial-movement/company/${companyId}/owner/withdrawal`;
        return this._post<Loan>(url, request, {}, Loan);
    }

    available(companyId: string): Observable<Amount> {
        const url = `${this._getBaseUrl()}/api/financial-movement/company/${companyId}/owner/withdrawal/available`;
        return this._get<Amount>(url, {}, Amount);
    }
}
