import {CARD_STATUS} from '@red/data';

export class ActivateCard {
    static readonly type = '[Card] activate';
    constructor(public payload: { cardId: string; lastFourDigits: string; userIdentification: string; summary: string}) {}
}

export class ListCardForCompany {
    static readonly type = '[Card] List card for company';
    constructor(public payload: { companyId: string; }) {}
}

export class UpdateCardStatus {
    static readonly type = '[Card] Update card';
    constructor(public payload: { cardId: string; status: CARD_STATUS; }) {}
}
