import {TransactionServiceClient} from '../../lab/service-client/transaction-service-client';
import {Observable} from 'rxjs/index';
import {Transaction, TransactionListResponse} from '@red/data';
import {QueryFilter, QueryResponse} from '@red/browser';
import {map} from 'rxjs/operators';
import { clone } from '../../common/utils/clone';

export class TransactionManager {
    private _transactionServiceClient: TransactionServiceClient;

    constructor (
        transactionServiceClient: TransactionServiceClient
    ) {
        this._transactionServiceClient = transactionServiceClient; }

    static create(transactionServiceClient: TransactionServiceClient) {
        return new TransactionManager(transactionServiceClient);
    }

    list(filter: QueryFilter): Observable<TransactionListResponse> {
        return this._transactionServiceClient.list(filter);
    }

    query(companyId: string, filter: QueryFilter): Observable<TransactionListResponse> {
        return this._transactionServiceClient.query(companyId, filter);
    }

    queryToQueryResponse(companyId: string, filter?: QueryFilter): Observable<QueryResponse<Transaction>> {
        return this._transactionServiceClient.query(companyId, filter)
            .pipe(
                map((response: TransactionListResponse) => {
                    const transactions = response.data.slice().map(transaction => new Transaction(transaction));

                    return new QueryResponse<Transaction>(transactions, filter, response.total);
                })
            );
    }
}
